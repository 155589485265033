import React, { Component } from 'react';
import { Accordion, Toggle, Card, Button } from "react-bootstrap"
import ContextAwareToggle from './ContextAwareToggle'
import dateFormat from "dateformat"
import ReactHtmlParser from "react-html-parser"

import classes from './AccordionMobile.module.scss'

class Custom_Accordion extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let cards, readMoreTxt;

        if (this.props.articles) {
            cards = this.props.articles.map((article, index) => {
                // let urlTo = `${article.article_language.url}/${article.id}`
                let dateFull = dateFormat(article.date, "dd-mm-yyyy")
                return (
                    <Card className={classes.customCard}>
                        <ContextAwareToggle as={Card.Header} eventKey={index + 1} className={classes.cardHeader}>
                            <div className={classes.accParent}>
                                <div className={classes.accFirst}>
                                    <p className={classes.dateArcticle}>{dateFull}</p>
                                    <p className={classes.titleArticle}>{article.title}</p>
                                </div>
                            </div>
                        </ContextAwareToggle>
                        <Accordion.Collapse eventKey={index + 1}>
                            <Card.Body className={classes.textAccor}>
                                <Card.Title className={classes.titleAccorContent}>{/* {article.content} */}</Card.Title>
                                {ReactHtmlParser(article.content)}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                )
            })
        }

        if (this.props.readMore) {
            readMoreTxt = (
                <div className={classes.readMoreBox}>
                    <div className={classes.rightBox}>
                        <a onClick={() => this.setCurrentLink()} className={classes.customLink} href={this.props.readMoreLink} rel='noopener noreferrer'>
                            <div className={classes.outerCircle}>
                                <div className={classes.innerCircle}></div>
                            </div>
                            <span className={classes.readMore}>{this.props.readMore}</span>
                        </a>
                    </div>
                </div>
            )
        }

        return (
            <>
                <Accordion>
                    {cards}
                </Accordion>
                {readMoreTxt}
            </>
        );
    }
}

export default Custom_Accordion;