import React, { Component, Fragment } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import ReactNotification, { store } from "react-notifications-component";

import Api from "../Api";
import Views from "./MobileView";
import Core from "../Core";

import "react-notifications-component/dist/theme.css";
import './Mobile.css'
import classes from "./Mobile.module.css";

export default class Mobile extends Component {
	constructor() {
		super();
		this.state = {
			currentPage: null,
			sections: [],
			isLoaded: false,
			isApiLoaded: false,
			views: null,
			activePage: <div></div>,
			valueQuery: null,
			articleID: null,
			sectionName: "",
			idArticle: null,
		};
		this.getSectionDetail = this.getSectionDetail.bind(this);
		this.handleOverride = this.handleOverride.bind(this);
	}

	componentDidMount() {
		let id = this.props.id;
		this.getSectionDetail(id);
		this.handleOverride();
	}

	handleOverride() {
		this.props.override(false);
	}

	getSectionDetail(id) {
		const apiData = new Api();

		apiData
			.getSectionDetail(id)
			.then((result) => {
				const sections = result.data;
				const widget = [];
				result.data.widgets.forEach((item) => {
					widget.push({
						id: item.id,
						index: item.index,
						category: item.category,
						name: item.name,
						sections: item,
					});
				});

				this.setState({
					sectionName: result.data.name,
					sections: widget,
					isLoaded: true,
					idArticle: result.data.id,
					articleID: widget[0].id,
				});
			})

			.catch((error) => {
				this.setState({ isApiLoaded: true });
			});
	}

	onCopy = () => {
		this.setState(
			{
				isCopied: true,
			},
			() => {
				this.showNotification("Link has copied");
			}
		);
	};

	showNotification(message) {
		store.addNotification(
			{
				title: "Success!",
				message: message,
				type: "phenikaa",
				insert: "bottom",
				container: "bottom-right",
				animationIn: ["animate__animated", "animate__fadeIn"],
				animationOut: ["animate__animated", "animate__fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
				},
			},
			() => {
				this.setState({
					isCopied: false,
				});
			}
		);
	}

	render() {
		const { sections } = this.state;
		let readMore, share, searchHere, newsButton, textSearch, url, filterMagazine;
		let imageMagazine = [],
			fileMagazine = [];

		let viewOnchange;

		if (this.state.isLoaded) {
			var articleIds = [];
			var article_categories = [];
			sections.forEach((item) => {
				switch (item.index) {
					case 1:
						readMore = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Read Less";
						break;
					case 2:
						share = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Don't share";
						break;
					case 3:
						searchHere = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Don't search";
						break;
					case 4:
						newsButton = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Less news";
						break;
					case 5:
						textSearch = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Search here. . .";
						break;
					case 6:
						url = item.sections.url ? item.sections.url : "/news/detail/";
						articleIds.push(item.id);
						item.sections.article_categories.forEach((category) => {
							if (!article_categories.includes(category)) {
								article_categories.push(category);
							}
						});
						break;
					case 7:
						url = item.sections.url ? item.sections.url : "/news/detail/";
						articleIds.push(item.id);
						item.sections.article_categories.forEach((category) => {
							if (!article_categories.includes(category)) {
								article_categories.push(category);
							}
						});

						break;
					case 8:
						filterMagazine = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Phenikaa Magazine";

						break;
					case 9:
						item.sections.widget_contents.forEach((image) => {
							let imgFile;
							let textDesc = null;

							if (image.file_small !== null) {
								imgFile = image.file_small;
								textDesc = image.description;
							} else {
								imgFile = "";
								return textDesc;
							}

							imageMagazine.push([imgFile, textDesc]);
						});
						break;
					case 10:
						item.sections.widget_contents.forEach((file) => {
							let pdfFile, textDescFile;
							if (file.file !== null) {
								pdfFile = file.file;
								textDescFile = file.description;
							} else {
								pdfFile = "";
								textDescFile = "";
							}

							fileMagazine.push([pdfFile, textDescFile]);
						});

						let filePackage = [];

						imageMagazine.forEach((item, index) => {
							var image = item[0];
							var file = fileMagazine[index] ? fileMagazine[index][0] : "";
							var desc = fileMagazine[index] ? fileMagazine[index][1] : "";

							filePackage.push([image, file, desc]);
						});

						viewOnchange = (
							<Views
								id={articleIds}
								idArt={this.state.idArticle}
								url={url}
								category={article_categories.sort()}
								view={"grid"}
								filterMagazine={filterMagazine}
								fileMagazine={filePackage}
								readMore={readMore}
								share={share}
								title={this.state.sectionName}
								searchHere={searchHere}
								newsButton={newsButton}
								textSearch={textSearch}
								onCopy={this.onCopy}
							/>
						);
						break;
					default:
				}
			});
		}

    return (
      <>
        <ReactNotification
					types={[
						{
							htmlClasses: [classes.phenikaaNotif],
							name: "phenikaa",
						},
					]}
				/>
			<div className="page-news">
				<div className={classes.main}>
					<ParallaxProvider>
						<Fragment>
							<div className={classes.content}>
								<div className={["container", classes.boxC].join(" ")}>{viewOnchange}</div>
							</div>
							<div className={classes.coreCon}>
								<div className={classes.Core}>
									<Core />
								</div>
							</div>
						</Fragment>
					</ParallaxProvider>
				</div>
        </div>
        </>
		);
	}
}
