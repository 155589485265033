import React, { Component } from 'react';
import Api from '../../Api'
import { Link } from "react-router-dom";
import classes from './BlogViewMobile.module.css'
import Swiper from 'react-id-swiper';
import './swiper.css'
import Slider from '../Slider/Slider'



class BlogView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentArticle: [],
            allAuthors: [],
            isLoaded: false,
            isApiLoaded: false,
            selectedAuthorIdx: 0,
            pageSize: 5,
            isAuthorLoaded: false
        }

        this.defaultParam = {
            order: "-date"
        }

        this.getArticleDetailByAuthor = this.getArticleDetailByAuthor.bind(this);
        this.getAuthorsByWidget = this.getAuthorsByWidget.bind(this)
    }

    componentDidMount() {
        this.getArticleDetailByAuthor(this.props.widget.id, '', this.state.pageSize);
        this.getAuthorsByWidget(this.props.widget.id)
    }


    getArticleDetailByAuthor(param, authorName, size, customParam) {
        customParam = {
            ...this.defaultParam,
            ...customParam
        }

        const apiData = new Api();
        apiData.getArticleDetailByAuthor(param, authorName, size, customParam)
            .then(result => {
                const articles = result.data
                const art = []
                articles.results.forEach((a) => {
                    const assets = []
                    a.article_assets.forEach((ass, i) => {
                        if (i === 0) {
                            assets.push({
                                asset: ass.asset_file
                            })
                        }
                    })

                    art.push({
                        id: a.id,
                        category: a.category_name,
                        name: a.name,
                        author_name: a.author_name,
                        author_avatar: a.author_avatar,
                        title: a.article_languages.title,
                        content: a.article_languages.content,
                        summary: a.article_languages.summary,
                        assets: assets,
                        date: a.date,
                        slug_title: a.article_languages.slug_title
                    })
                })

                this.setState({
                    contentArticle: art,
                    isLoaded: true
                })
            })
            .catch(error => {
                console.log(error);
                this.setState({ isApiLoaded: true })
            });
    }

    getAuthorsByWidget(param) {
        const apiData = new Api();
        apiData.getAuthorsByWidget(param)
            .then(result => {
                const arrAuthors = result.data.results

                this.setState({ allAuthors: arrAuthors, isAuthorLoaded: true })
            })
            .catch(error => {
                console.log(error);
                this.setState({ isApiLoaded: true })
            });
    }

    render() {
        let articles, authors, swiper;
        let filters = [
            (<div onClick={() => { this.getArticleDetailByAuthor(this.props.widget.id, '', this.state.pageSize) }} className={[classes.authorItem, classes.selected].join(' ')}>{this.props.sidebarText[0].content}</div>),
            (<Link to={this.props.readMore.url} className={classes.authorItem}>{this.props.sidebarText[1].content}</Link>)
        ]

        if (this.state.isLoaded && this.state.isAuthorLoaded) {
            articles = this.state.contentArticle.map(article => {
                const slug_title = article.slug_title ? article.slug_title : "null"
                return (
                    <>
                        <Link className={classes.link} to={this.props.widget.url + "/" + slug_title + "/" + article.id}>
                            <div className={classes.contentC}>
                                <div className={classes.contentCLeft}>
                                    <div className={classes.contentArtTitle}>{article.title}</div>
                                    <div className={classes.contentSummary}>{article.summary}</div>
                                    <div className={classes.contentAuthorName}>{article.author_name}</div>
                                </div>
                                <div>
                                    <div className={classes.authorPhoto}>
                                        <img
                                            className={classes.authorImg}
                                            src={article.author_avatar ? article.author_avatar : require('../../assets/dummy-profile.jpg')}
                                            alt={article.author_name}
                                        ></img>
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <hr className={classes.hrBottom}></hr>
                    </>
                )
            })

            authors = this.state.allAuthors.map(author => {
                return (<div onClick={() => { this.getArticleDetailByAuthor(this.props.widget.id, author.id, this.state.pageSize) }} className={classes.authorItem}>{author.name}</div>)
            })

            filters = filters.concat(authors)

            // swiper = <Swiper className="subs-m-slider">{filters}</Swiper>
            swiper = <Slider>{filters}</Slider>
        }

        return (
            <div className={classes.gocNhin}>
                <div className={classes.leftCol}>
                    {swiper}
                </div>
                <div className={classes.rightCol}>
                    {articles}
                </div>
            </div>
        );
    }
}

export default BlogView;