import React, { Component } from "react";
import Slider from "react-slick";
import Api from "../Api";
import classes from "./Mobile.module.css";
import "swiper/swiper.scss";
import ReactHtmlParser from "react-html-parser";
import "./Mobile.css";

export default class Mobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: null,
      sections: [],
      isLoaded: false,
      isApiLoaded: false,
      nav1: null,
      nav2: null,
      slideIndex: 0,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
    this.next = this.next.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    apiData
      .getSectionDetail()
      .then((result) => {
        this.setState({
          isApiLoaded: true,
        });
        const sections = result.data;
        const widget = [];
        result.data.widgets.forEach((item) => {
          widget.push({
            id: item.id,
            index: item.index,
            category: item.category,
            name: item.name,
            sections: item,
          });
        });
        sections.page = widget;
        this.setState({
          sections: widget,
          isLoaded: true,
          nav1: this.slider1,
          nav2: this.slider2,
        });
      })
      .catch((error) => {
        // console.log(error);
        this.setState({
          isApiLoaded: true,
        });
      });
  }

  next() {
    this.slider2.slickNext();
  }
  render() {
    const { sections } = this.state;
    const settings = {
      className: "",
      dots: true,
      slidesToShow: 1,
      autoplay: true,
      swipeToSlide: true,
      autoplaySpeed: 5000,
      afterChange: () =>
        this.setState((state) => ({ updateValue: state.updateValue + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
      appendDots: (dots) => (
        <div
          style={{
            backgroundColor: "transparent",
            borderRadius: "10px",
          }}
        >
          <ul> {dots} </ul>
        </div>
      ),
      customPaging: (i) => (
        <div
          style={{
            width: "10px",
            height: "10px",
            color: "#d6d6d6",
            border: "1px solid #d6d6d6",
            borderRadius: "50%",
          }}
        ></div>
      ),
    };

    let contentDesc,
      contentSlider,
      imageSlider = null;

    if (this.state.isLoaded) {
      let count = 0;
      sections.forEach((item) => {
        count = count + 1;
        if (count === 1 && item.index === 1 && item.category === "Text") {
        }

        if (count === 2 && item.index === 2 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              contentDesc = <p className={classes.content}>{s.content}</p>;
            }
          });
        }

        if (count === 3 && item.index === 3 && item.category === "Article") {
          contentSlider = item.sections.widget_contents.map((s) => {
            return (
              <div
                className={classes.bxc01h}
                onChange={(e) => this.slider.slickGoTo(e.target.value)}
                value={this.state.slideIndex}
              >
                <p className={classes.titleContent}>{s.article_language.title}</p>
                <span className={classes.contentD}>
                  {ReactHtmlParser(s.article_language.content)}
                </span>
              </div>
            );
          });

          imageSlider = item.sections.widget_contents.map((s) => {
            let isImage = s.assets[0] ? (
              <img
                className={classes.image}
                src={s.assets[0].file_small}
                alt={s.assets[0].description}
              />
            ) : (
                <img
                  className={classes.imageDefault}
                  src={require("../assets/default-image.svg")}
                  alt="default image"
                />
              );
            return <div className={classes.imgBasic}>{isImage}</div>;
          });
        }
      });
    } else if (this.state.isApiLoaded) {

    }

    return (
      <div className="section-mobile">
        <div className={classes.contentWraping}>
          <div className={[classes.contentMobile, classes.customBox].join(" ")}>
            <div className={classes.contentCenter}>{contentDesc}</div>
          </div>
          <div className={classes.contentMobile2}>
            <div id="slider1">
              <Slider
                asNavFor={this.state.nav2}
                ref={(slider) => (this.slider1 = slider)}
              >
                {imageSlider}
              </Slider>
            </div>

            <div className={classes.ctxBsc} id="slider2">
              <div className={classes.ctxAbv}>
                <div className={classes.ctxAbv02}>
                  <Slider
                    asNavFor={this.state.nav1}
                    ref={(slider) => (this.slider2 = slider)}
                    slidesToShow={1}
                    swipeToSlide={false}
                    focusOnSelect={this.next}
                    {...settings}
                  >
                    {contentSlider}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
