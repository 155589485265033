import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Api from "./Api";
import { Col } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import ResponsiveSlider from "./Slider";
import "./sec2.css";
import classes from "./sec2.module.css";

class sec2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentSection: [],
      isLoaded: false,
      isApiLoaded: false,
      isBoxHover: false,
      boxCount: 0,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const widget = [];
          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            });
          });

          sections.page = widget;
          this.setState({
            contentSection: widget,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
          window.location.assign(
            `/error/${error.response.status ? error.response.status : 404}`
          );
        });
    });
  }

  toggleBoxHandlerEnter = () => {
    this.setState((prevState) => {
      return { isBoxHover: !prevState.isBoxHover };
    });
  };

  render() {
    const { contentSection } = this.state;
    let titleContent,
      contentDesc,
      contentSliderTop,
      contentSliderBot,
      logo,
      readMore,
      startBox,
      viewDetail = null;

    if (this.state.isLoaded) {
      let count = 0;
      contentSection.forEach((item) => {
        count = count + 1;

        if (count === 1 && item.index === 1 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              titleContent = <p className={classes.title}>{s.content}</p>;
            }
          });
        } else if (
          count === 1 &&
          item.index === 1 &&
          item.category !== "Text"
        ) {
          titleContent = "";
        }

        if (count === 2 && item.index === 2 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              contentDesc = <p className={classes.subTitle}>{s.content}</p>;
            } else {
              contentDesc = <></>;
            }
          });
        } else if (
          count === 2 &&
          item.index === 2 &&
          item.category !== "Text"
        ) {
          contentDesc = <></>;
        }

        if (count === 3 && item.index === 3 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              readMore = (
                <div className={classes.boxLinks}>
                  <div className={classes.outerCircleBot}>
                    <div className={classes.innerCircleBot}></div>
                  </div>

                  <span className={classes.readMore}>{s.content}</span>
                </div>
              );
            } else {
              readMore = "";
            }
          });
        } else if (
          count === 3 &&
          item.index === 3 &&
          item.category !== "Text"
        ) {
          readMore = "";
        }

        if (count === 4 && item.index === 4 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              viewDetail = <p className={classes.viewMore}>{s.content}</p>;
            } else {
              viewDetail = "";
            }
          });
        } else if (
          count === 4 &&
          item.index === 4 &&
          item.category !== "Text"
        ) {
          viewDetail = "";
        }

        if (count === 5 && item.index === 5 && item.category === "Article") {
          item.sections.widget_contents
            ? (contentSliderTop = item.sections.widget_contents.map((s, i) => {
                let url = s.article_language.url ? s.article_language.url : "/";

                if (i === 0) {
                  startBox = classes.leftMargin;
                } else if (i === 1 || i === 3 || i === 5) {
                  startBox = classes.havingMargin;
                } else {
                  startBox = "";
                }

                let contentBack = this.state.isBoxHover
                  ? classes.isBoxHover
                  : "";
                let findImage = null;
                var logoIcon;
                s.assets.forEach((assetItems) => {
                  if (assetItems.description === "systemicon") {
                  } else {
                    if (findImage === null) {
                      findImage = (
                        <img
                          className={classes.boxImg}
                          src={assetItems.file_small || assetItems.file}
                          alt={assetItems.description}
                        ></img>
                      );
                    }
                  }
                });

                var isImage = findImage ? (
                  findImage
                ) : (
                  <img
                    className={classes.boxImgDefault}
                    src={require("./assets/default-image.svg")}
                    alt="Default image"
                  />
                );

                switch (i) {
                  case 0:
                    logoIcon = require("./assets/1.svg");
                    break;
                  case 1:
                    logoIcon = require("./assets/2.svg");

                    break;
                  case 2:
                    logoIcon = require("./assets/3.svg");

                    break;
                  case 3:
                    logoIcon = require("./assets/4.svg");

                    break;
                  case 4:
                    logoIcon = require("./assets/5.svg");

                    break;
                  default:
                    break;
                }

                logo = (
                  <object
                    type="image/svg+xml"
                    data={logoIcon ? logoIcon : require("./assets/2.svg")}
                    className={classes.innerCircle}
                    aria-label="icon"
                  />
                );

                return (
                  <Card
                    key={s.article_language.title}
                    className={[classes.box, startBox].join(" ")}
                  >
                    <div className={classes.contentFront}>
                      <figure className={classes.contentFigure}>
                        {isImage}
                      </figure>
                      <div className={classes.boxVertical}>
                        <div className={classes.contentBoxTitle}>
                          {logo}
                          <Link to={url}>
                            <h2 className={classes.boxTitle}>
                              {s.article_language.title}
                            </h2>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div
                      className={[classes.contentBack, contentBack].join(" ")}
                    >
                      <div className="justify-content-center align-self-center">
                        <p className={classes.contentTitle}>
                          {s.article_language.title}
                        </p>
                        <span className={classes.content}>
                          {ReactHtmlParser(s.article_language.content)}
                        </span>

                        <Link to={url}>{readMore}</Link>
                      </div>
                    </div>
                  </Card>
                );
              }))
            : (contentSliderTop = null);
        }

        if (count === 6 && item.index === 6 && item.category === "Article") {
          item.sections.widget_contents
            ? (contentSliderBot = item.sections.widget_contents.map((s, i) => {
                let contentUrl = null;
                let bgColor =
                  (i + 1) % 2 === 0
                    ? "cv-color-sec2-blue"
                    : "cv-color-orange-sec2";
                let isImage = s.assets[0] ? (
                  <Card.Img
                    className="img-thumb-sec2"
                    src={s.assets[0].file_small || s.assets[0].file}
                    alt=""
                  />
                ) : (
                  <Card.Img
                    className="img-thumb-sec2"
                    src={require("./assets/default-image.svg")}
                    alt=""
                  />
                );
                if (s.article_language.url) {
                  contentUrl =
                    s.article_language.url.substring(0, 4) === "http" ? (
                      <a
                        href={s.article_language.url}
                        className={classes.customLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {viewDetail}
                      </a>
                    ) : (
                      <Link
                        className={classes.customLink}
                        to={s.article_language.url}
                      >
                        {viewDetail}
                      </Link>
                    );
                } else {
                  contentUrl = (
                    <Link
                      className={classes.customLink}
                      to={'/'}
                    >
                      {viewDetail}
                    </Link>
                  );
                }
                return (
                  <Col
                    key={s.article_language.title}
                    className={["image-zoom-sec2", classes.boxSmall].join(" ")}
                  >
                    <Card className="card-image-zoom">
                      {isImage}
                      <Card.ImgOverlay
                        className={[bgColor, classes.contentAbsolute].join(" ")}
                      ></Card.ImgOverlay>
                      <div className={classes.contentAbsolute}>
                        <div className={classes.contentInBox}>
                          <div className={classes.txtMid}>
                            {s.article_language.title}
                            <div className={classes.boxLinks}>
                              <div className={classes.outerCircleBot}>
                                <div className={classes.innerCircleBot}></div>
                              </div>
                              {contentUrl}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                );
              }))
            : (contentSliderBot = null);
        }
      });
    } else if (this.state.isApiLoaded) {

    }

    return (
      <div className={classes.contentSection2}>
        <div className={["container", classes.customContainer].join(" ")}>
          {titleContent}
          {contentDesc}
        </div>
        <div className={["container", classes.customContainer2].join(" ")}>
          <ResponsiveSlider setting={"top"} content={contentSliderTop} />
        </div>
        <div className={["container", classes.customContainer3].join(" ")}>
          <div className={classes.ctBtmHalf}>
            <ResponsiveSlider setting={"bottom"} content={contentSliderBot} />
          </div>
        </div>
      </div>
    );
  }
}

export default sec2;
