import React, { Component } from "react";
import classes from "./Section1.module.css";
import Api from "./Api";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import NavBack from "./components/NavBack/NavBack";

export default class Box extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: {
        widgets: [],
      },
      url: "",
      readMore: "Read More",
      isLoaded: false,
      isApiLoaded: true,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }
  componentDidMount() {
    if (this.props.id) {
      let param = this.props.id;
      this.getSectionDetail(param);
      this.setState({
        url: this.props.url,
      });
    }
  }

  getSectionDetail(param) {
    const apiData = new Api();
    this.setState({ contentArticle: [] }, () => {
      apiData
        .getSectionDetail(param)
        .then((result) => {
          const articles = result.data;

          this.setState({
            article: articles.widgets,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  deletePrevLink() {
    localStorage.removeItem("isFromIN")
  }

  render() {
    const { article } = this.state;

    let articleContent,
      returnBackElement,
      shareText = null;

    if (this.state.isLoaded) {
      const isFromIN = localStorage.getItem("isFromIN");
      let backUrl = isFromIN && isFromIN === "true" ? article[2].url : article[0].url
      let backText = article[0].widget_contents[0]?.content;
      shareText = article[1].widget_contents[0]?.content

      returnBackElement = (
        <NavBack
          className={classes.backLink}
          url={backUrl}
          onClick={this.deletePrevLink}
        >
          {backText}
        </NavBack>
      );
    }
    return <div className={classes.wrappbtnback}>{returnBackElement}</div>;
  }
}
