import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Slide } from "react-awesome-reveal";
import dateFormat from "dateformat";
// import ReactHtmlParser from "react-html-parser";
import Navbar from "./Navbar";
import Api from "./../Api";
import classes from "./Mobile.module.css";

class MobileViews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentArticle: [],
      moreNews: "4",
      isLoaded: false,
      filtered: "",
      articleCategories: null,
      pagination: 6,
      articleID: null,
      displayStyle: "grid",
      loadedArticle: 6,
      idLoaded: 0,
      searchTextKey: "",
      page: 1,
      isLast: false,
    };
    this.getArticleDetail = this.getArticleDetail.bind(this);

    this.onFilterChange = this.onFilterChange.bind(this);
    this.getSearchedData = this.getSearchedData.bind(this);
    this.changeDisplay = this.changeDisplay.bind(this);
    this.loadMoreNews = this.loadMoreNews.bind(this);
    this.setDefaultLoadedArticle = this.setDefaultLoadedArticle.bind(this);
    this.insertArticleInArray = this.insertArticleInArray.bind(this);
    this.toggleHandlerEnter = this.toggleHandlerEnter.bind(this);
  }

  componentDidMount() {
    this.setState({
      articleID: this.props.id ? this.props.id : "",
      articleCategories: this.props.category ? this.props.category : "",
    });

    if (this.props.id) {
      this.getArticleDetail(this.props.id, "", this.state.loadedArticle, this.props.valueSearch ? this.props.valueSearch : "", this.state.page)
    }

    this.setDefaultLoadedArticle();
  }

  setDefaultLoadedArticle(multiply = 1) {
    if (window.screen.width >= 2560) {
      this.setState({
        loadedArticle: 12 * multiply,
      })
    } else {
      this.setState({
        loadedArticle: 6 * multiply,
      })
    }
  }

  insertArticleInArray(array, articleToInsert) {
    var inserted = false;
    for (var i = 0; i < array.length; i++) {
      if (articleToInsert.date > array[i].date) {
        let startArray = array.slice(0, i);
        let endArray = array.slice(i, array.length);
        startArray.push(articleToInsert);
        array = startArray.concat(endArray);
        inserted = true;
        break;
      }
    }
    if (!inserted) {
      array.push(articleToInsert);
    }
    return array;
  }

  getArticleDetail(param, filter, size, search, page) {
    let customParam = {
      search: search,
      order: "-date",
    }

    if (filter === "Phenikaa's News") {
      customParam.widget = this.props.idNewsDetails
      customParam.widgets_intersection = true
    } else if (filter === "Other News") {
      customParam.widget_exclusive = true
    }

    let count = 0
    if (count === 0) {
      const apiData = new Api()

      var art = [...this.state.contentArticle]
      
      apiData
        .getArticleDetailPagination(param, size, page, customParam)
        .then((result) => {
          const articles = result.data

          if (!articles.next) {
            this.setState({ isLast: true })
          }
          let newArts = []

          articles.results
            .forEach((a) => {
              const assets = []

              let typeVid = ["webm", "mkv", "flv", "gif", "avi", "mov", "wmv", "mp4", "3gp"]
              let typeImg = ["jpg", "jpeg", "png", "svg", "webp", "bmp", "tiff", "exif"]

              a.article_assets.forEach((ass, i) => {
                if (i === 0) {
                  if (ass.file_small) {
                    let extension = ass.file_small.substring(ass.file_small.lastIndexOf(".") + 1)

                    if (typeVid.includes(extension)) {
                      assets.push({ assetV: ass.file_small })
                    } else if (typeImg.includes(extension)) {
                      assets.push({ asset: ass.file_small, description: ass.description })
                    }
                  } else {
                    const vidId = this.getVidId(ass.asset_link)
                    const ytThumb = vidId ? `https://img.youtube.com/vi/${vidId}/hqdefault.jpg` : null
                    const asset = ass.asset_thumbnail || ytThumb
                    if(asset) {
                      assets.push({ asset: asset })
                    } else {
                      assets.push({ assetV: ass.asset_link })
                    }
                  }
                }
              })

              let newArticle = {
                id: a.id,
                category: a.category_name,
                name: a.name,
                title: a.article_languages.title,
                slug_title: a.article_languages.slug_title,
                summary: a.article_languages.summary,
                content: a.article_languages.content,
                assets: assets,
                date: a.date,
              }

              newArts.push(newArticle)
            })

          art = newArts
          return art
        })
        .then((art) => {
          this.setState({
            contentArticle: this.state.contentArticle.concat(art),
            isLoaded: true,
          })
        })
        .catch((error) => {
          console.log(error)
        })
      count = 1

    }
  }

  getVidId(ytEmbedUrl) {
    if(!ytEmbedUrl) {
      return ytEmbedUrl
    }

    const url = new URL(ytEmbedUrl)
    const path = url.pathname.split("/")
    const vidId = path[path.length-1] === "" ? path[path.length-2] : path[path.length-1]
    return vidId
  }

  onFilterChange(e) {
    this.setState({
      filtered: e.target.value,
      contentArticle: [],
      page: 1,
      isLast: false
    }, () => {
      this.getArticleDetail(this.state.articleID, this.state.filtered, this.state.loadedArticle, this.props.valueSearch ? this.props.valueSearch : "", this.state.page);
    });
  }

  loadMoreNews() {
    this.setState((prevState) => {
      return { pagination: prevState.pagination + this.state.loadedArticle, page: prevState.page + 1 }
    }, () => {
      this.getArticleDetail(this.props.id, this.state.filtered, this.state.loadedArticle, this.props.valueSearch ? this.props.valueSearch : "", this.state.page)
    })
  }

  toggleHandlerEnter(key) {
    this.setState({
      searchTextKey: key,
    });
  }

  getSearchedData(e) {
    if (e.keyCode === 13) {
      if (e.target.value !== "") {
        const value = e.target.value;
        this.setState({
          contentArticle: [],
          page: 1,
          isLast: false
        }, () => {
          this.getArticleDetail(this.state.articleID, '', this.state.loadedArticle, value, this.state.page);
        });
      }
    }
  }

  changeDisplay = (params) => {
    this.setState({ displayStyle: params });
  };

  imageOrVideo = (url, param, display, imageDescription) => {
    let content, imgStyle, vidStyle;
    if (display === "grid") {
      imgStyle = classes.imgGrid;
      vidStyle = classes.vidStyle;
    } else {
      imgStyle = classes.box_image;
      vidStyle = classes.boxVideo;
    }
    if (url !== "") {
      switch (param) {
        case "img":
          content = (
            <img
              className={imgStyle}
              src={url ? url : require("../assets/default-image.svg")}
              alt={imageDescription}
            />
          );
          break;
        case "vid":
          if (url) {
            if (url.includes("phenikaa-assets.s3")) {
              content = (
                <video className={vidStyle} controls loop={false}>
                  <source src={url} type='video/mp4' />
                </video>
              )
            } else {
              content = <iframe src={url} className={vidStyle} title='Iframe Example'></iframe>
            }
          } else {
            content = <img className={imgStyle} src={require("../assets/default-image.svg")} alt='description' />
          }
          break;
        default:
          break;
      }
    } else {
      content = (
        <img
          className={imgStyle}
          src={require("../assets/default-image.svg")}
          alt="description"
        />
      );
    }
    return content;
  };

  render() {
    const { contentArticle, pagination } = this.state;

    // SET CONTENT NEWS GRID MODE
    let apiContentGrid = null;
    if (
      this.state.isLoaded
    ) {
      apiContentGrid = contentArticle.map((s, i) => {

        const NewDate = dateFormat(s.date, "dd-mm-yyyy");

        let param = s.assets[0] ? (s.assets[0].asset ? "img" : "vid") : "";

        let newAsset = this.imageOrVideo(
          s.assets[0]
            ? s.assets[0].asset
              ? s.assets[0].asset
              : s.assets[0].assetV
            : "",
          param,
          "grid",
          s.assets[0]?.description
        );

        return (
          <Slide triggerOnce direction={"top"}>
            <div
              className={[classes.contentWrap, classes.fadeInBottomm].join(" ")}
            >
              <div className={classes.boxContent1}>
                <div className={classes.box_behind}>
                  <div className={classes.box1a}>
                    {newAsset}
                    {/* <img src={s.assets[0].asset} alt="" /> */}
                  </div>
                  <div className={classes.box2b}>
                    <div className={classes.box2b01}>
                      <div className={classes.box2b02}>
                        <p className={classes.title_content1}>{s.title}</p>
                      </div>
                      <div className={classes.box2b0203}>
                        <Link to={`${this.props.url}/${s.slug_title ? s.slug_title : "null"}/${s.id}`}>
                          <div className={classes.outerCircle2}>
                            <div className={classes.innerCircle2}></div>
                          </div>
                          <p className={classes.readMore}>
                            {this.props.readMore}
                          </p>
                        </Link>
                        <p className={classes.date_right1}>{NewDate}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slide>
        );
      });
    }

    // SET NEWS CATEGORY
    const { articleCategories } = this.state;

    // CHANGE LIST/GRID VIEW
    let viewOnchange,
      apiContentlist,
      nav = null;
    if (this.state.searchTextKey === "search") {
      viewOnchange = <div className={classes.component}>{apiContentlist}</div>;
      nav = (
        <Navbar
          sText={this.state.searchTextKey}
          onClick={() => this.toggleHandlerEnter("search")}
          onKeyDown={this.getSearchedData}
          onChange={this.onFilterChange}
          category={articleCategories}
          title={this.props.title}
          searchHere={this.props.textSearch}
          selectedFilter={this.state.filtered}
        />
      );
    } else {
      viewOnchange = (
        <div className={classes.componentgrid}>
          <div className={["row", classes.box_grid].join(" ")}>
            {apiContentGrid}
          </div>
        </div>
      );
      nav = (
        <Navbar
          sText={this.state.searchTextKey}
          onKeyDown={this.getSearchedData}
          onChange={this.onFilterChange}
          onClick={() => this.toggleHandlerEnter("search")}
          category={articleCategories}
          title={this.props.title}
          searchHere={this.props.textSearch}
          selectedFilter={this.state.filtered}
        />
      );
    }

    let loadMore;
    if (!this.state.isLast) {
      loadMore = (
        <div className={classes.older} onClick={this.loadMoreNews}>
          <p>{this.props.newsButton}</p>
        </div>
      )
    }

    return (
      <>
        {nav}
        {viewOnchange}
        {loadMore}
      </>
    );
  }
}

export default MobileViews;
