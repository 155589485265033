import React, { Component } from 'react';
import ReactHtmlParser from "react-html-parser";

import classes from './Article.module.css'

class Article extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: ''
        }
    }

    componentDidMount() {
        const lang = localStorage.getItem('language') === 'English' ? 'en-US' : 'vi-VN';
        this.setState({lang: lang})
    }
    
    
    render() {
        const locales = this.state.lang ? this.state.lang : 'vi-VN';
        return (
            <div className={classes.container}>
                <h1 className={classes.title}>{this.props.article.title}</h1>
                <p className={classes.date}>{(new Date(this.props.date)).toLocaleString(locales, {dateStyle: 'full'})}</p>
                <div className={classes.content}>
                    {ReactHtmlParser(this.props.article.content)}
                </div>
            </div>
        );
    }
}

export default Article;