import React, { Component } from "react"

import ReactHtmlParser from "react-html-parser"

import classes from "./Modal.module.css"
import "./Modal.css"

class CustomModal extends Component {
  constructor(props) {
    super(props)
    // console.log(this.props)
    this.state = {
      isShowModal: false,
      data: this.props.data[this.props.idx],
      maxIdx: this.props.data.length,
      currentIdx: this.props.idx,
    }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  toggleModal = () => {
    this.setState((prevState) => {
      return {
        isShowModal: !prevState.isShowModal,
        currentIdx: this.props.idx,
        data: this.props.data[this.props.idx],
      }
    })
  }

  next() {
    if (this.state.currentIdx < this.state.maxIdx - 1) {
      this.setState(
        (state) => ({
          currentIdx: state.currentIdx + 1,
        }),
        function () {
          this.setState({
            data: this.props.data[this.state.currentIdx],
          })
        }
      )
    }
  }

  previous() {
    if (this.state.currentIdx > 0) {
      this.setState(
        {
          currentIdx: this.state.currentIdx - 1,
        },
        function () {
          this.setState({
            data: this.props.data[this.state.currentIdx],
          })
        }
      )
    }
  }

  render() {
    const immutableArticle = this.props.data[this.props.idx]
    const article = this.state.data
    // const image = immutableArticle.assets[0] ? immutableArticle.assets[0].asset : null;
    let activeClass = this.props.onStyle ? (this.props.onStyle === "inactive" ? classes.inActive : "") : ""
    // let backgroundActive = this.props.onStyle !== "normal" ? (this.props.onStyle === 'inactive' ? classes.backgroundInActive : classes.backgroundActive) : ''
    let backgroundActive = this.props.onStyle ? (this.props.onStyle === "inactive" ? classes.backgroundInActive : classes.backgroundActive) : ""
    let dummyCard = !immutableArticle ? classes.dummyCard : ""
    console.log(immutableArticle)
    return (
      <>
        {/* <a className={classes.aCard} onClick={this.toggleModal}> */}
        <div id={"box" + (immutableArticle ? immutableArticle.id : "")} className={[classes.aCard, dummyCard].join(" ")} onClick={this.props.onClick}>
          <div className={classes.card}>
            <figure className={[classes.figure, activeClass].join(" ")}>
              <img src={immutableArticle ? immutableArticle.assets[0].asset : ""} className={classes.cardImageFront} alt={immutableArticle?.assets[0]?.description} />
            </figure>
            <div className={[classes.boxContent, backgroundActive].join(" ")}>
              {immutableArticle ? (
                immutableArticle.title.indexOf("–") > -1 || immutableArticle.title.indexOf("-") > -1 ? (
                  <>
                    {/* <h4 className={classes.cardTitle}>{ReactHtmlParser(immutableArticle.title.split("–")[0])}</h4> */}
                    <h4 className={classes.cardTitle}>{immutableArticle.title.split("–").length > 1 ? ReactHtmlParser(immutableArticle.title.split("–")[0]) : ReactHtmlParser(immutableArticle.title.split("-")[0])}</h4>
                    <p className={classes.cardSubTitle}>{immutableArticle.title.split("–").length > 1 ? ReactHtmlParser(immutableArticle.title.split("–")[1]) : ReactHtmlParser(immutableArticle.title.split("-")[1])}</p>
                  </>
                ) : (
                  <>
                    <h4 className={classes.cardTitle}>{ReactHtmlParser(immutableArticle.title)}</h4>
                    <p className={classes.cardSubTitle}>&nbsp;</p>
                  </>
                )
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        {/* <Modal id="bod-modal" show={this.state.isShowModal} onHide={this.toggleModal}>
                    <div className={classes.modalImg}>
                        <img className={classes.profileImg} src={article.assets[0].asset} />
                    </div>
                    <div className={classes.modalDetail}>
                        <div className={classes.modalContainer}>
                            <span className={classes.pageNum}>{this.state.currentIdx + 1} <hr className={classes.hrPageNum} /> {this.state.maxIdx}</span>
                            <div className={classes.closeBtn} onClick={this.toggleModal}><FontAwesomeIcon icon={faTimes} /></div>
                            <div className={classes.navigatorBtnTop} onClick={this.next}>
                            { this.state.currentIdx < this.state.maxIdx-1 ? <p className={classes.navigatorText}>{ ReactHtmlParser(this.props.data[this.state.currentIdx + 1].title) }</p> : <></>}
                            <FontAwesomeIcon icon={faArrowRight} />
                        </div>
                            <div className={classes.navigatorBtnTop} onClick={this.previous}>
                                {this.state.currentIdx > 0 ? <><p className={classes.navigatorText}>{ReactHtmlParser(this.props.data[this.state.currentIdx - 1].title)}</p><FontAwesomeIcon icon={faArrowLeft} /></> : <></>}

                            </div>
                            <div className={classes.separator}></div>
                            <div className={classes.modalContent}>
                                {article.title.indexOf("–") > -1 ? (
                                    <>
                                        <h1 className={classes.contentProfileName}>{ReactHtmlParser(article.title.split("–")[0])}</h1>
                                        <p className={classes.contentProfilePosition}>{ReactHtmlParser(article.title.split("–")[1])}</p>
                                    </>
                                ) :
                                    (
                                        <h1 className={classes.contentProfileName}>{ReactHtmlParser(article.title)}</h1>
                                    )}

                                <p className={classes.contentQualification}>{ReactHtmlParser(article.content)}</p>

                                <div className={classes.separatorBottom}></div>
                                <div className={classes.footerContent}>
                                    {this.state.currentIdx < this.state.maxIdx - 1 ? (
                                        <>
                                            <span className={classes.footerNextBtn}>Next</span>
                                            <span className={classes.footerNextProfile}>{ReactHtmlParser(this.props.data[this.state.currentIdx + 1].title)}</span>
                                            <FontAwesomeIcon onClick={this.next} className={classes.footerRightArrow} icon={faArrowRight} />
                                        </>) : <></>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </Modal> */}
      </>
    )
  }
}

export default CustomModal
