import React from "react";
import { useMediaQuery } from "react-responsive";
import classes from "./Desktop.module.css";
import { Link } from "react-router-dom";

export default function ContentResponsive(props) {
  const isTablet = useMediaQuery({
    query: "(max-device-width: 1024px)",
  });

  let dataContent, isImage, isFront, isTitle, isDesk, isTabs;

  isDesk = props.data.map((f, i) => {
    isTitle = f.article_language.title;
    isImage = (
      <img
        className={classes.contentImage}
        src={f.assets[0].file_big}
        alt={f.assets[0].description}
      ></img>
    );
    isFront = (
      <img className={classes.imgFront} src={f.assets[1].file} alt=" " />
    );

    return (
      <>
        <div className={classes.boxContentDetail}>
          <Link to={f.article_language.url} className={classes.link1}>
            <div className={classes.boxAbsoluteOne}>
              <div className={classes.boxIntoAbsolute}>
                <div className={classes.boxImageInside}>{isFront}</div>
                <div className={classes.boxTextInside}>
                  <p>{isTitle}</p>
                </div>
              </div>
            </div>
          </Link>

          {isImage}
        </div>
      </>
    );
  });

  isTabs = props.data.map((f, i) => {
    isTitle = f.article_language.title;
    isImage = (
      <img
        className={classes.contentImage}
        src={f.assets[0].file_big}
        alt={f.assets[0].description}
      ></img>
    );
    isFront = (
      <img className={classes.imgFront} src={f.assets[1].file} alt={f.assets[1].description} />
    );

    return (
      <>
        <div className={classes.boxContentDetail}>
          <div className={classes.bxprt01_1}>
            <Link to={f.article_language.url}>
              <div className={classes.urgent}>
                <img
                  className={classes.isImgSmall}
                  src={f.assets[1].file}
                  alt={f.assets[1].description}
                />
                <div className={classes.insideText}>
                  <p className={classes.isText}>{isTitle}</p>
                </div>
              </div>
            </Link>
          </div>
          {isImage}
        </div>
      </>
    );
  });

  dataContent = !isTablet ? isDesk : isTabs;

  return <div className={classes.css}>{dataContent}</div>;
}
