import React, { Component } from "react"
import HistoryList from "./MobileView"
import Api from "../Api"

class Mobile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sections: [],
      isLoaded: false,
      isApiLoaded: false,
    }
    this.getSectionDetail = this.getSectionDetail.bind(this)
  }

  componentDidMount() {
    this.getSectionDetail()
  }

  getSectionDetail() {
    const apiData = new Api()
    apiData
      .getSectionDetail()
      .then((result) => {
        this.setState({
          isApiLoaded: true,
        })
        const sections = result.data
        const widget = []

        result.data.widgets.forEach((item) => {
          widget.push({
            id: item.id,
            index: item.index,
            category: item.category,
            internal: item.is_internal,
            name: item.name,
            years: item.article_years,
            sections: item,
          })
        })
        sections.page = widget
        this.setState({
          sections: widget,
          isLoaded: true,
        })
      })
      .catch((error) => {
        this.setState({
          isApiLoaded: true,
        })
      })
  }

  render() {
    const { sections, isLoaded } = this.state

    let historyList, backBtn, backUrl

    if (isLoaded) {
      let count = 0
      sections.forEach((item, index) => {
        count += 1
        if (count === 1 && item.index === 1 && item.category === "Text") {
          backBtn = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Back"
          backUrl = item.sections.url ? item.sections.url : "/about-us"
        }
        if (count === 2 && item.index === 2 && item.category === "Article") {
          historyList = <HistoryList id={item.id} years={item.years} backBtn={backBtn} backUrl={backUrl} />
        }
      })
    }

    return <>{historyList}</>
  }
}

export default Mobile
