import React, { Component } from 'react'

import classes from './LanguageItems.module.css'

export default class LanguageItems extends Component {
    constructor(props) {
        super(props)
        this.state = {
            items: [
                { id: 1, title: "Vietnamese", type: "inactive", img: require("./assets/iconVn.svg") },
                { id: 2, title: "English", type: "inactive", img: require("./assets/iconUk.svg") },
              ],
              selectLanguage: this.props.selectLanguage,
              language: null,
              activeLang: 0,
        }
        
    }

    componentDidMount() {
        let defaultLang = localStorage.getItem("language")
        let active = 1
        if (defaultLang && defaultLang.toLowerCase() === "vietnamese") {
            active = 1
        } else if (defaultLang && defaultLang.toLowerCase() === "english") {
            active = 0
        } else {
            active = 1
            localStorage.setItem("language", "Vietnamese")
        }

        this.setState({
            activeLang: active,
        })
    }

    toggleSwitchLanguage = (lang) => {
        let active = null
        if (lang.toLowerCase() === "vietnamese") {
            active = 1
        } else {
            active = 0
        }
        this.setState({
            language: lang,
            activeLang: active,
        })
        localStorage.setItem("language", lang)
        window.location.reload(true)
    }
    render() {
        let lang1, lang2
        if (this.props.lang) {
            const lang = this.props.lang
            lang.sections.widget_contents.forEach((l, i) => {
                let type = this.state.activeLang === i ? classes.active : ""
                switch (i) {
                    case 0:
                        lang1 = <img className={type} onClick={() => this.toggleSwitchLanguage(l.name)} src={require('./assets/iconUk.svg')} alt="" />
                        break;
                    case 1:
                        lang2 = <img className={type} onClick={() => this.toggleSwitchLanguage(l.name)} src={require('./assets/iconVn.svg')} alt="" />
                        break;
                    default:
                        break;
                }
            })
        }
        return (
            <div className={classes.contentLang}>
                {lang2}
                {lang1}
            </div>
        )
    }
}
