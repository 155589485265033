import React, { Component } from 'react'
import { Link } from 'react-router-dom'

// import ReactHtmlParser from "react-html-parser";
import Api from '../../Api'

import classes from './BoxMobile.module.scss'

export default class Box extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentArticle: [],
            isLoaded: false,
            isApiLoaded: false,
            customParam: {
                order: `-date`
            }
        }
        this.getArticleDetailPagination = this.getArticleDetailPagination.bind(this);
    }

    componentDidMount() {
        if (this.props.id) {
            this.getArticleDetailPagination(this.props.id, 3, null, { ...this.state.customParam })
        }
    }

    getArticleDetailPagination(param, size, page, customParam) {
        const apiData = new Api();
        apiData.getArticleDetailPagination(param, size, page, customParam)
            .then(result => {
                const articles = result.data
                const art = []
                articles.results.forEach((a) => {
                    const assets = []
                    a.article_assets.forEach((ass, i) => {
                        if (i === 0) {
                            const vidId = this.getVidId(ass.asset_link)
                            const ytThumb = vidId ? `https://img.youtube.com/vi/${vidId}/hqdefault.jpg` : null
                            assets.push({
                                asset: ass.file_medium || ass.asset_thumbnail || ytThumb,
                                description: ass.description
                            })
                        }
                    })

                    art.push({
                        id: a.id,
                        category: a.category_name,
                        name: a.name,
                        author_name: a.author_name,
                        author_avatar: a.author_avatar,
                        title: a.article_languages.title,
                        summary: a.article_languages.summary,
                        assets: assets,
                        date: a.date,
                        slug_title: a.article_languages.slug_title
                    })
                })

                this.setState({
                    contentArticle: art,
                    isLoaded: true
                })
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    isApiLoaded: true
                })
            });
    }

    setPrevLink() {
        localStorage.setItem("isFromIN", "true")
    }

    getVidId(ytEmbedUrl) {
        if (!ytEmbedUrl) {
            return ytEmbedUrl
        }

        const url = new URL(ytEmbedUrl)
        const path = url.pathname.split("/")
        const vidId = path[path.length - 1] === "" ? path[path.length - 2] : path[path.length - 1]
        return vidId
    }

    render() {
        const { contentArticle } = this.state
        let boxContent = [];
        if (this.state.isLoaded) {
            contentArticle.forEach((s, i) => {
                const slug_title = s.slug_title ? s.slug_title : "null"
                let content = (
                    <>
                        <div className={classes.thumbC}>
                            <img className={classes.thumbImg} src={s.assets[0].asset} alt={s.assets[0].description}></img>
                        </div>
                        <div className={classes.title}>{s.title}</div>
                        <div className={classes.summary}>{s.summary}</div>
                        <div className={classes.customLink}>
                            <div className={classes.outerCircle}>
                                <div className={classes.innerCircle}></div>
                            </div>
                            <Link
                                to={this.props.urlDetail + "/" + slug_title + "/" + s.id}
                                className={classes.readMore}
                                onClick={this.setPrevLink}
                            >
                                {this.props.readMore}
                            </Link>
                        </div>
                    </>
                )
                boxContent.push(content)
            })
        }
        return (
            <>
                <div className={classes.box}>
                    {boxContent}
                </div>
            </>
        )
    }
}
