import React, { Component } from 'react'
import MegaMenuItem from './MegaMenuItem/MegaMenuItem'

export default class Mobile extends Component {
    state = {
        items: [
            { id: 1, title: 'ABOUT US', action: 'hover', type: 'expand', link: '/about-us' },
            { id: 2, title: 'BUSINESS AREA', action: 'hover', type: 'expand', link: '/business' },
            { id: 3, title: 'SUSTAINABLE DEVELOPMENT', action: 'hover', type: 'expand', link: '/sustainable' },
            { id: 4, title: 'INNOVATION', action: 'hover', type: 'expand', link: '/innovation' },
            { id: 5, title: 'NEWS', action: 'hover', type: 'expand', link: '/news' },
            { id: 6, title: 'CAREER', action: 'hover', type: 'disable', link: '/' },
            { id: 7, title: 'CONTACT', action: 'click', type: 'link', link: '/' },
        ],

        content: <div></div>
    }
    render() {
        let menusApi, menuDummy = null
        menuDummy = this.state.items.map(i => {
            let id = 'preview' + i.id
            return (
                <MegaMenuItem
                    key={i.id}
                    id={id}
                    title={i.title}
                    type={i.type}
                    link={i.link}
                />
            );
        });
        if (this.props.items) {
            const items = this.props.items
            const groupItem = []
            const item = []

            let listItems = null
            items.items.forEach((x) => {
                if (x.children) {
                    x.children.forEach(i => {
                        const child = []
                        if (i.children) {
                            i.children.forEach(y => {
                                child.push(
                                    y
                                )
                            })
                        } else {
                            child.push('none')
                        }

                        item.push({
                            index: i.index,
                            id: i.id,
                            name: i.title,
                            children: child
                        })
                    })
                    groupItem.push({
                        name: x.name,
                        items: item
                    })
                } else {
                    groupItem.push({
                        name: x.name,
                        items: 'null'
                    })
                }

                items.item = groupItem
                listItems = items
            })
            
            if (listItems) {
                menusApi = listItems.items.map((x, i) => {
                    console.log(x)
                    let type = null
                    x.children ? type = 'expand' : type = 'link'
                    return (
                        <MegaMenuItem
                            key={x.name}
                            title={x.name}
                            type={type}
                            link={x.url}
                            content={this.state.content}
                        />
                    )
                })
            } else {
                menusApi = menuDummy
            }
        } else {
            menusApi = menuDummy
        }
        return (
            <div>
                {menusApi}
            </div>
        )
    }
}
