import React, { Component } from "react";
import styled from "styled-components";
import { isFirefox, browserName } from "react-device-detect";
import { AiFillLinkedin, AiFillYoutube, AiFillFacebook } from "react-icons/ai";

import MegaMenuItems from "./MegaMenuItems/MegaMenuItems";
import LanguageItems from "./MegaMenuItems/LanguageItems/LanguageItems";

import Api from "../Api";

import classes from "./MegaMenu.module.css";
import { tickStep } from "d3";

const StyledMenu = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: black;

	background-color: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(50px);
	// clip-path: ${({ open }) => (open ? "circle(100% at 50% 0%)" : "circle(0% at 110% 45px)")};
	// transition: clip-path 1s, opacity 0.5s;

	// height: ${({ open }) => (open ? "100vh" : "0")};
	// width: ${({ open }) => (open ? "100vw" : "0")};

	${({ open }) => {
		if (open) {
			return `
        clip-path: circle(100%);
        height: 100vh;
        width: 100vw;
        transition: clip-path 1s, opacity 0.5s;
      `;
		} else {
			return `
        clip-path: circle(0% at calc(100vw - 45px) 45px);
        height: 0;
        width: 0;
        transition: clip-path 1s, opacity 0.5s, width 0s 1s, height 0s 1s;
      `;
		}
	}}

	text-align: left;
	z-index: ${({ open }) => (open ? "1" : "-1")};
	padding: 2rem;
	position: absolute;
	top: 0;
	right: 0;

	a {
		font-size: 2rem;
		text-transform: uppercase;
		padding: 0rem 0;
		font-weight: bold;
		letter-spacing: 0.5rem;
		color: #0d0c1d;
		text-decoration: none;
		transition: color 0.3s linear;

		@media (max-width: 576px) {
			font-size: 1.5rem;
			text-align: center;
		}

		&:hover {
			color: #343078;
		}
	}
	@media only screen and (max-width: 1024px) {
		// clip-path: ${({ open }) => (open ? "circle(100% at 50% 20%)" : "circle(0% at 95.2% 2.7rem)")};
		background-color: rgba(0, 0, 0, 0.1);
	}

	// @media only screen and (max-width: 768px) {
	//   clip-path: ${({ open }) => (open ? "circle(100% at 50% 30%)" : "circle(0% at 92% 4%)")};
	// }
	// @media only screen and (max-width: 420px) {
	//   clip-path: ${({ open }) => (open ? "circle(100% at 50% 30%)" : "circle(0% at 76% 6%)")};
	// }
	// @media only screen and (max-width: 360px) {
	//   clip-path: ${({ open }) => (open ? "circle(100% at 50% 30%)" : "circle(0% at 88% 6%)")};
	// }
`;
export default class MegaMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			contentSection: {
				widgets: [],
			},
			isLoaded: false,
			isApiLoaded: false,
		};
		this.keyPress = this.keyPress.bind(this);
		this.getSectionDetail = this.getSectionDetail.bind(this);
	}

	componentDidMount() {
		this.getSectionDetail();
	}

	getSectionDetail() {
		const apiData = new Api();
		this.setState({ contentSection: [] }, () => {
			apiData
				.getSectionDetail()
				.then((result) => {
					const sections = result.data;
					const widget = [];

					result.data.widgets.forEach((item) => {
						widget.push({
							id: item.id,
							index: item.index,
							category: item.category,
							name: item.name,
							sections: item,
						});
					});

					sections.page = widget;
					this.setState({
						contentSection: widget,
						isLoaded: true,
					});
				})
				.catch((error) => {
					this.setState({
						isApiLoaded: true,
					});
				});
		});
	}

	keyPress(e) {
		const parsedUrl = new URL(window.location.href);
		const origin = parsedUrl.origin;
		if (e.keyCode === 13) {
			window.location.href = `${origin}/search/?search=${e.target.value}`;
		}
	}

	render() {
		const { contentSection } = this.state;

		let placeholder,
			selectLanguage,
			lang,
			icon = null;
		let careerList = [];
		let newsList = [];

		if (this.state.isLoaded) {
			contentSection.forEach((item, i) => {
				switch (i) {
					case 0:
						item.sections.widget_contents.forEach((s) => {
							placeholder = s.content ? s.content : "";
						});
						break;
					case 1:
						item.sections.widget_contents.forEach((s) => {
							selectLanguage = s.content ? s.content : "";
						});
						break;
					case 2:
						lang = item;
						break;
					case 3:
						icon = item;
						break;
					case 4:
						careerList.push({ url: item.sections.url });
						item.sections.widget_contents.forEach((s) => {
							careerList.push({ content: s.content ? s.content : "" });
						});
						break;
					case 5:
						newsList.push({ url: item.sections.url });
						item.sections.widget_contents.forEach((s) => {
							newsList.push({ content: s.content ? s.content : "" });
						});
						break;
					default:
						break;
				}
			});
		} else if (this.state.isApiLoaded) {
			placeholder = "";
			lang = false;
		}

		let menuBrowser = isFirefox || browserName === "Firefox" ? classes.frCkstm : "";
		return (
			<StyledMenu className={menuBrowser} open={this.props.open}>
				<div style={{ flex: "none" }} className="col-md-3"></div>
				<div className="row">
					<div style={{ left: "8vw" }} className={["col-md-6", classes.itemList].join(" ")}>
						<div className={["input-group", classes.customSearch].join(" ")}>
							<input onKeyDown={this.keyPress} type="text" autoComplete="off" id="btnSearch" placeholder={placeholder} />
							<i className={["fa fa-search fa-sm", classes.faCustom].join(" ")} aria-hidden="true"></i>
						</div>
						<MegaMenuItems items={this.props.items} careerList={careerList} newsList={newsList} />
						<div className="d-flex">
							<LanguageItems lang={lang} icon={icon} selectLanguage={selectLanguage} />
							<div className={classes.footerSocialMedia}>
								<a href="https://www.facebook.com/phenikaagroup" target="_blank" rel="noopener noreferrer">
									<AiFillFacebook color="#0674E8" />
								</a>
								<a href="https://www.youtube.com/c/PHENIKAAGROUP" target="_blank" rel="noopener noreferrer">
									<AiFillYoutube color="#ff0000" />
								</a>
								<a href="https://www.linkedin.com/company/71278835/admin/" target="_blank" rel="noopener noreferrer">
									<AiFillLinkedin color="#0A66C2" />
								</a>
							</div>
						</div>
					</div>
				</div>
			</StyledMenu>
		);
	}
}
