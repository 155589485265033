import React, { Component } from "react";
import Sliders from "react-slick";
import Article from "../Article";
import Api from "../Api";
import classes from "../Action.module.css";

import './Mobile.css'
class SD_Section6 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [
                {
                    id: 1,
                    name: "Article",
                    title: "Lorem ipsum dummy title",
                    content: "Lorem ipsum dummy data...",
                },
            ],
            contentSection: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: true,
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState(
            {
                contentSection: [],
            },
            () => {
                apiData
                    .getSectionDetail()
                    .then((result) => {
                        const sections = result.data;
                        const widget = [];

                        result.data.widgets.forEach((item) => {
                            widget.push({
                                id: item.id,
                                name: item.name,
                                index: item.index,
                                type: item.is_internal,
                                category: item.category,
                                url: item.url,
                                click: sections.widgets[5].widget_contents,
                                sections: item,
                            });
                        });

                        sections.page = widget;
                        this.setState({ contentSection: widget, isLoaded: true });
                    })
                    .catch((error) => {
                        this.setState({ isApiLoaded: true });
                    });
            }
        );
    }
    render() {
        const { contentSection, isLoaded } = this.state;

        let title_content,
            data_content,
            article_box1,
            article_box2,
            article_box3,
            dummy_content_box,
            dummy_title_content,
            dummy_data_content = null;

        if (isLoaded) {
            let count = 0;
            contentSection.forEach((item) => {
                count = count + 1;
                if (count === 1 && item.index === 1 && item.category === "Text") {
                    item.sections.widget_contents.forEach((ele) => {
                        if (ele.content !== "") {
                            title_content = <h2 className={classes.title}>{ele.content}</h2>;
                        } else {
                            title_content = dummy_title_content;
                        }
                    });
                } else if (
                    count === 1 &&
                    item.index === 1 &&
                    item.category !== "Text"
                ) {
                    title_content = dummy_title_content;
                }

                if (count === 2 && item.index === 2 && item.category === "Text") {
                    item.sections.widget_contents.forEach((ele) => {
                        if (ele.content !== "") {
                            data_content = (
                                <p className={classes.contentTextBottom}>{ele.content}</p>
                            );
                        }
                    });
                } else if (
                    count === 2 &&
                    item.index === 2 &&
                    item.category !== "Text"
                ) {
                    data_content = dummy_data_content;
                }

                if (count === 3 && item.index === 3 && item.category === "Article") {
                    if (item.type === false) {
                        article_box1 = (
                            <Article id={item.id} url={item.url} click={item.click} />
                        );
                    }
                } else if (
                    count === 3 &&
                    item.index === 3 &&
                    item.category !== "Article"
                ) {
                    article_box2 = dummy_content_box;
                }
                if (count === 4 && item.index === 4 && item.category === "Article") {
                    if (item.type === false) {
                        article_box2 = (
                            <Article id={item.id} url={item.url} click={item.click} />
                        );
                    }
                }
                if (count === 5 && item.index === 5 && item.category === "Article") {
                    if (item.type === false) {
                        article_box3 = (
                            <Article id={item.id} url={item.url} click={item.click} />
                        );
                    }
                }
                if (count === 6 && item.index === 6 && item.category === "Text") {
                }
            });
        } else if (this.state.isApiLoaded) {
            title_content = dummy_title_content;
            data_content = dummy_data_content;
            article_box1 = dummy_content_box;
            article_box2 = dummy_content_box;
            article_box3 = dummy_content_box;
        }

        const settings = {
            dots: true,
            centerMode: false,
            infinite: false,
            centerPadding: "",
            variableWidth: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500,
            swipeToSlide: true,
            appendDots: dots => (
                <div className={classes.customDots}>
                    <ul style={{ margin: "0px" }}> {dots} </ul>
                </div>
            ),
            customPaging: i => (
                <div className={classes.customPaggination} >
                </div>
            )
        };
        return (
            <div className={classes.Section6}>
                <div className={["container", classes.boxC, classes.customContainer].join(" ")}>
                    <div>
                        {title_content}
                        {data_content}
                        <div id="mobile-sd-section6">
                            <Sliders className={classes.customSlider} {...settings}>
                                {article_box1}
                                {article_box2}
                                {article_box3}
                            </Sliders>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SD_Section6;
