import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Collapse from 'react-bootstrap/Collapse';

import AllIcon from '../Icon/Icon'
import Icon from '../assets/icon.svg'

import Api from '../Api'

import classes from './Mobile.module.css'
export default class Mobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sections: {
                widgets: []
            },
            section: {
                widgets: []
            },
            menus: {
                items: []
            },
            backButton: "",
            logoIcon: Icon,
            isMenuLoaded: false,
            isSectionLoaded: false,
            test: false
        };
        this.getMenu = this.getMenu.bind(this);
        this.getSectionDetail = this.getSectionDetail.bind(this)
    }

    componentDidMount() {
        this.getMenu();
        this.getSectionDetail()
    }

    getMenu() {
        const apiData = new Api();
        this.setState({
            menus: []
        }, () => {
            apiData
                .getMenuDetail()
                .then(result => {

                    const menu = result.data
                    const item = []

                    menu
                        .items
                        .forEach((i) => {
                            item.push({ index: i.index, id: i.id, name: i.title, url: i.url, children: i.children })
                        })
                    menu.items = item
                    this.setState({ menus: menu, isMenuLoaded: true })
                })
                .catch(error => {

                });
        })
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({
            section: []
        }, () => {
            apiData
                .getSectionDetail()
                .then(result => {
                    const sections = result.data
                    const widget = []

                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            index: item.index,
                            category: item.category,
                            name: item.name,
                            sections: item
                        })
                    })
                    sections.page = widget

                    this.setState({
                        sections: sections,
                        section: widget,
                        isSectionLoaded: true,
                    }, () => {
                        this.setState({
                            logoIcon: this.state.section[0].sections.widget_contents[0]?.file ? this.state.section[0].sections.widget_contents[0]?.file : Icon,
                            backButton: this.state.sections.widgets[1]?.widget_contents[0]?.content ? this.state.sections.widgets[1]?.widget_contents[0]?.content : "Quay lại trang trước"
                        })
                    })

                })
                .catch(error => {
                    // this.setState({ isSectionLoaded: false })
                });
        })
    }

    parseItemToChart = (item) => {
        let childs, items

        if (item.length > 0) {
            items = item.map((itm, i) => {
                if (itm.children?.length > 0) {
                    childs = itm.children.map((itmX, i) => {
                        return this.parseItemToChart(itmX)
                    });
                }

                let styleCh = itm?.children?.length > 0 ? classes.liHvItms : classes.liNotHvItms

                return (
                    <li className={styleCh}>
                        {itm.title}
                        <ul>
                            {childs}
                        </ul>
                    </li>
                )
            })
        } else {
            items = <li>{item.title}</li>
        }

        return items
    }

    openCollapse = (item) => {
        this.setState({
            [item]: !this.state[item]
        })
    }

    render() {
        const { isMenuLoaded, isSectionLoaded, menus } = this.state

        let chartItems, chartItemsChild, linkContent
        if (isMenuLoaded && isSectionLoaded) {
            if (menus?.items[0]) {
                chartItems = menus.items[0].children.map((itm, i) => {
                    if (itm.children) {
                        chartItemsChild = this.parseItemToChart(itm.children)
                    } else {
                        chartItemsChild = <></>
                    }

                    let active = this.state[itm.title] ? classes.active : ''

                    return (
                        <div>
                            <button className={classes.btnCollapse} onClick={() => { this.openCollapse(itm.title) }}>
                                <AllIcon data={this.state.section} idx={i + 1} />
                                {itm.title}
                                <i className={["fa fa-arrow-right", active].join(' ')} aria-hidden="true"></i>
                            </button>
                            <Collapse in={this.state[itm.title]}>
                                <div className={classes.chartItems}>
                                    {chartItemsChild}
                                </div>
                            </Collapse>
                        </div>
                    )
                })
            }

            let prevLink = localStorage.getItem('prevLink')

            linkContent =
                <a className={classes.customLink} href={prevLink && prevLink !== "" ? prevLink : '/about-us'}>
                    <div className={'d-flex'}>
                        <div className={'d-flex'}>
                            <i className={[classes.arrow, classes.left, classes.customMargin].join(" ")}></i>
                        </div>
                        <span className={classes.textBack}>{this.state.backButton}</span>
                    </div>
                </a>
        }


        return (
            <div className={classes.contentCharts}>
                <img src={Icon} alt='logo' style={{ width: '20%' }} />
                <div className={classes.charts}>
                    {chartItems}
                </div>

                <div className={classes.linkBack}>
                    <div className={classes.navBack}>
                        {linkContent}
                    </div>
                </div>
            </div>
        )
    }
}
