import React, { Component } from 'react'
import { Link } from 'react-router-dom'

// import ReactHtmlParser from "react-html-parser";
import Api from '../../Api'
import Slider from '../Slider/Slider'

import classes from './Box.module.scss'

export default class Box extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inNewsGlobal: [],
            inNewsPhenikaa: [],
            inNewsLatest: [],
            isLoaded: false,
            isApiLoaded: false,
            box2Height: 0,
            customParam: {
                order: `-date`
            }
        }
        this.getArticleDetailPagination = this.getArticleDetailPagination.bind(this);
    }

    componentDidMount() {
        if (this.props.id && this.props.idNews) {
            // set page size for articles
            const size = {
                latest: 1,
                global: 3,
                phenikaa: 6
            }
            const stateName = {
                latest: 'inNewsLatest',
                global: 'inNewsGlobal',
                phenikaa: 'inNewsPhenikaa'
            }

            // Get innovation news latest
            this.getArticleDetailPagination(
                this.props.id,
                size.latest,
                null,
                { ...this.state.customParam },
                stateName.latest
            )

            // Get innovation news global
            this.getArticleDetailPagination(
                this.props.id,
                size.global,
                null,
                { ...this.state.customParam, widget_exclusive: true },
                stateName.global
            )

            // Get phennika innovation news
            this.getArticleDetailPagination(
                this.props.id,
                size.phenikaa,
                null,
                { ...this.state.customParam, widget: this.props.idNews, widgets_intersection: true },
                stateName.phenikaa
            )
        }
    }

    getArticleDetailPagination(param, size, page, customParam, stateName, callback) {
        const apiData = new Api();
        apiData.getArticleDetailPagination(param, size, page, customParam)
            .then(result => {
                const articles = result.data
                let art = []
                articles.results.forEach((a) => {
                    let assets = []
                    a.article_assets.forEach((ass, i) => {
                        if (i === 0) {
                            const vidId = this.getVidId(ass.asset_link)
                            const ytThumb = vidId ? `https://img.youtube.com/vi/${vidId}/hqdefault.jpg` : null
                            assets.push({
                                asset: ass.file_medium || ass.asset_thumbnail || ytThumb,
                                description: ass.description
                            })
                        }
                    })

                    art.push({
                        id: a.id,
                        category: a.category_name,
                        name: a.name,
                        author_name: a.author_name,
                        author_avatar: a.author_avatar,
                        title: a.article_languages.title,
                        summary: a.article_languages.summary,
                        assets: assets,
                        date: a.date,
                        slug_title: a.article_languages.slug_title
                    })
                })

                // set new state
                let newState = {}
                newState[stateName] = art

                this.setState(newState, callback)
            })
            .catch(error => {
                console.log(error);
                this.setState({
                    isApiLoaded: true
                })
            });
    }

    updateThumbnailHeight() {
        setTimeout(() => {
            let box1 = document.getElementById("box1")
            let box2SlickList = document.querySelector("#IN_Section2_Slider .slick-list")
            this.setState({
                box2Height: box1 ? box1.offsetHeight : "100%"
            }, () => {
                box2SlickList.style.height = this.state.box2Height + "px"
            })
        }, 500)
    }

    setPrevLink() {
        localStorage.setItem("isFromIN", "true")
    }

    getVidId(ytEmbedUrl) {
        if (!ytEmbedUrl) {
            return ytEmbedUrl
        }

        const url = new URL(ytEmbedUrl)
        const path = url.pathname.split("/")
        const vidId = path[path.length - 1] === "" ? path[path.length - 2] : path[path.length - 1]
        return vidId
    }

    render() {
        let box1Content = [], box2Content = [], box3Content = [];

        if (this.state.inNewsLatest.length && this.state.inNewsGlobal.length && this.state.inNewsPhenikaa.length && !this.state.isLoaded) {
            const latestNews = this.state.inNewsLatest[0]
            const filteredInNewsGlobal = this.state.inNewsGlobal.filter(e => latestNews.id !== e.id).slice(0, 2)
            const filteredInNewsPhenikaa = this.state.inNewsPhenikaa.filter(e => latestNews.id !== e.id).slice(0, 5)
            this.setState({
                isLoaded: true,
                inNewsGlobal: filteredInNewsGlobal,
                inNewsPhenikaa: filteredInNewsPhenikaa
            }, this.updateThumbnailHeight)
        }

        if (this.state.isLoaded) {
            const contentArticle = [...this.state.inNewsLatest, ...this.state.inNewsGlobal, ...this.state.inNewsPhenikaa]
            contentArticle.forEach((s, i) => {
                const slug_title = s.slug_title ? s.slug_title : "null"
                const idProps = i === this.state.inNewsGlobal.length ? { id: "image2" } : {}
                const styleProps = i === 0 ? { style: { height: this.state.image2Height } } : {}
                let content = (
                    <Link
                        // {...idProps}
                        className={classes.link}
                        to={this.props.urlDetail + "/" + slug_title + "/" + s.id}
                        onClick={this.setPrevLink}
                    >
                        <div className={classes.thumbC} >
                            <img
                                // {...styleProps}
                                className={classes.thumbImg}
                                src={s.assets[0]?.asset ? s.assets[0]?.asset : require('../../assets/default-image.svg')}
                                alt={s.assets[0]?.description ? s.assets[0]?.description : ''}
                            ></img>
                        </div>
                        <p className={classes.title}>{s.title}</p>
                    </Link>
                )

                let contentSmall = (
                    <Link
                        className={[classes.link, classes.smallContentBox].join(" ")}
                        to={this.props.urlDetail + "/" + s.slug_title + "/" + s.id}
                        style={{ padding: "0 0.5rem" }}
                        onClick={this.setPrevLink}
                    >
                        <div>
                            <p className={classes.title} style={{ marginTop: "unset" }}>{s.title}</p>
                            <p className={classes.summary}>{s.summary}</p>
                        </div>
                    </Link>
                )

                if (i === 0) {
                    box1Content.push(content)
                } else if (i > 0 && i < this.state.inNewsGlobal.length + this.state.inNewsLatest.length) {
                    box3Content.push(contentSmall)
                } else {
                    box2Content.push(content)
                }
            })
        }

        return (
            <>
                <div id="box1" className={classes.box1}>
                    {box1Content}
                    <div className={classes.box3}>
                        {box3Content}
                    </div>
                </div>
                <div style={{ height: this.state.box2Height }} className={classes.box2}>
                    <Slider>
                        {box2Content}
                    </Slider>
                </div>
            </>
        )
    }
}
