import React from "react"
import Desktop from "./Desktop"
import { isMobile, isTablet } from "react-device-detect"
import { useMediaQuery } from "react-responsive"

export default function H_Section1() {
  let content

  const isM = useMediaQuery({
    query: "(min-device-width: 411px) and (max-device-width: 479px)",
  })

  if (isTablet) {
    content = <Desktop />
  } else if (isMobile || isM) {
    content = <Desktop />
  } else {
    content = <Desktop />
  }

  return <>{content}</>
}
