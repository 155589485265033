import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import { Accordion } from "react-bootstrap";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Api from '../../Api'
import classes from './Accordion.module.css'
import './Accordion.css'

export default class Mobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sections: {
                widgets: []
            },
            menus: {
                items: []
            },
            backButton: "",
            isLoaded: false
        };
        this.getMenu = this.getMenu.bind(this);
        this.getSectionDetail = this.getSectionDetail.bind(this)
        this.recursiveTreeParserFunction = this.recursiveTreeParserFunction.bind(this);

        this.checkUrlTarget = this.checkUrlTarget.bind(this);
        this.getUrlIfThereIsURL = this.getUrlIfThereIsURL.bind(this);
    }

    componentDidMount() {
        this.getMenu();
        this.getSectionDetail()
    }

    getMenu() {
        const apiData = new Api();
        this.setState({
            menus: []
        }, () => {
            apiData
                .getMenuDetail()
                .then(result => {

                    const menu = result.data
                    const item = []

                    menu
                        .items
                        .forEach((i) => {
                            item.push({ index: i.index, id: i.id, name: i.title, url: i.url, children: i.children })
                        })
                    menu.items = item
                    this.setState({ menus: menu, isLoaded: true })
                })
                .catch(error => {

                });
        })
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({
            sections: []
        }, () => {
            apiData
                .getSectionDetail()
                .then(result => {

                    this.setState({
                        sections: result.data
                    }, () => {
                        this.setState({
                            backButton: this.state.sections.widgets[1]
                                ?.widget_contents[0]
                                ?.content
                                ? this.state.sections.widgets[1]
                                    ?.widget_contents[0]
                                    ?.content
                                : "Quay lại trang trước"
                        })
                    })

                })
                .catch(error => {
                    this.setState({ isApiLoaded: true })
                });
        })
    }

    checkUrlTarget(url) {
        if (url === "") {
            return ""
        }
        var substring = url.substr(0, 4);
        if (substring === "http") {
            return "external";
        } else {
            return "internal";
        }
    }

    recursiveTreeParserFunction(items, elements) {

        items.forEach((item) => {
            if (item.children) {
                this.recursiveTreeParserFunction(item.children, elements)
                var name = item.name
                    ? item.name
                    : item.title;
                elements.push([name, item.url])
            } else {
                elements.push([item.title, item.url])
            }

        })

    }

    getUrlIfThereIsURL(elementArray) {
        switch (this.checkUrlTarget(elementArray[1])) {
            case "internal":
                return (
                    <Link className={classes.customLink} to={elementArray[1]}>{(elementArray[0])}</Link>
                )
            case "external":
                return (
                    <a
                        className={classes.customLink}
                        href={elementArray[1]}
                        target="_blank"
                        rel="noopener noreferrer">{elementArray[0]}</a>
                )
            default:
                return (<> {
                    (elementArray[0])
                } </>)
        }
    }

    getUrlIfThereIsURLIcon(elementArray) {
        switch (this.checkUrlTarget(elementArray[1])) {
            case "internal":
                return (
                    <Link className={[classes.customLink, classes.customLinkIcon].join(' ')} to={elementArray[1]}>
                        <FontAwesomeIcon color="#000000" icon={faArrowRight} size="sm" />
                    </Link>
                )
            case "external":
                return (
                    <a className={[classes.customLink, classes.customLinkIcon].join(' ')} href={elementArray[1]} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon color="#000000" icon={faArrowRight} size="sm" />
                    </a>
                )
            default:
                return (
                    <div className={[classes.customLink, classes.customLinkIcon].join(' ')}>
                        <FontAwesomeIcon color="#b0b0b0" icon={faArrowRight} size="sm" />
                    </div>
                )
        }
    }

    toTitleCase(str) {
        return str.replace(
            /\w\S * /g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }
    render() {
        let elements = []
        let icons = []
        let content
        let prevLink = localStorage.getItem('prevLink')

        if (this.state.isLoaded) {
            this.recursiveTreeParserFunction(this.state.menus.items, elements)
            icons = this.props.icon
            content =
                <>
                    <div className={classes.mainLogo}>
                        <img className={classes.imgCenter} src={icons[0].file} alt="" />
                    </div>
                    <Accordion defaultActiveKey="0">
                        <div>
                            <Accordion.Toggle className={classes.boxAccordion} eventKey="0">
                                <img className={classes.imgLogo} src={icons[1].file_small} alt="" />
                                {/* <span>{elements[10] ? this.getUrlIfThereIsURL(elements[10]) : "SAN XUAT CONG NGHIEP"}</span> */}
                                <span>{elements[10] ? elements[10][0] : "SAN XUAT CONG NGHIEP"}</span>
                                {elements[10] ? this.getUrlIfThereIsURLIcon(elements[10]) : <></>}
                            </Accordion.Toggle>
                            <Accordion.Collapse className={classes.detailAccordion} eventKey="0">
                                <>
                                    {/* 1 */}
                                    <div className='m-1-1 v-line'></div>
                                    <div className='m-1-2 h-line'></div>
                                    <div className='m-1-x1-title --m-subtitle-chart'>
                                        {elements[4] ? this.getUrlIfThereIsURL(elements[4]) : "Vat lieu moi cao cap"}
                                    </div>
                                    <div className='m-1-3 v-line'></div>

                                    <div className='m-1-4 h-line'></div>
                                    <div className='m-1-x1-content --m-chart-content chart-content-animate'>
                                        {elements[0] ? this.getUrlIfThereIsURL(elements[0]) : "Hoa chat"}
                                    </div>
                                    <div className='m-1-5 h-line'></div>
                                    <div className='m-1-x2-content --chart-content chart-content-animate'>
                                        {elements[1] ? this.getUrlIfThereIsURL(elements[1]) : "Da Thach anh"}
                                    </div>
                                    <div className='m-1-6 h-line'></div>
                                    <div className='m-1-x3-content --chart-content chart-content-animate'>
                                        {elements[2] ? this.getUrlIfThereIsURL(elements[2]) : "Cristobalite"}
                                    </div>
                                    <div className='m-1-7 h-line'></div>
                                    <div className='m-1-x4-content --chart-content chart-content-animate'>
                                        {elements[3] ? this.getUrlIfThereIsURL(elements[3]) : "Vat lieu tien tien"}
                                    </div>
                                    {/* 2 */}
                                    <div className='m-1-8 h-line'></div>
                                    <div className='m-1-x2-title --m-subtitle-chart'>
                                        {elements[9] ? this.getUrlIfThereIsURL(elements[9]) : "Dien - Dien tu Thiet bi thong minh"}
                                    </div>
                                    <div className='m-1-9 v-line'></div>

                                    <div className='m-1-10 h-line'></div>
                                    <div className='m-1-x5-content --m-chart-content chart-content-animate'>
                                        {elements[5] ? this.getUrlIfThereIsURL(elements[5]) : "San pham chieu sang thong minh"}
                                    </div>
                                    <div className='m-1-11 h-line'></div>
                                    <div className='m-1-x6-content --chart-content chart-content-animate'>
                                        {elements[6] ? this.getUrlIfThereIsURL(elements[6]) : "Nha thong minh"}
                                    </div>
                                    <div className='m-1-12 h-line'></div>
                                    <div className='m-1-x7-content --chart-content chart-content-animate'>
                                        {elements[7] ? this.getUrlIfThereIsURL(elements[7]) : "Thiet bi thong minh"}
                                    </div>
                                    <div className='m-1-13 h-line'></div>
                                    <div className='m-1-x8-content --chart-content chart-content-animate'>
                                        {elements[8] ? this.getUrlIfThereIsURL(elements[8]) : "Robot thong minh"}
                                    </div>
                                </>
                            </Accordion.Collapse>
                        </div>
                        <div>
                            <div>
                                <Accordion.Toggle className={classes.boxAccordion} eventKey="1">
                                    <img className={classes.imgLogo} src={icons[2].file_small} alt="" />
                                    <span>{elements[21] ? elements[21][0] : "CHONG NGHE"}</span>
                                    {elements[21] ? this.getUrlIfThereIsURLIcon(elements[21]) : <></>}
                                </Accordion.Toggle>
                            </div>
                            <Accordion.Collapse className={classes.detailAccordion1} eventKey="1">
                                <>
                                    {/* 2 */}
                                    <div className='m-2-1 v-line'></div>
                                    <div className='m-2-2 h-line'></div>
                                    <div className='m-2-x1-content --m-subtitle-chart'>
                                        {elements[11] ? this.getUrlIfThereIsURL(elements[11]) : "Giải pháp ứng dụng cho thành phố thông minh"}
                                    </div>
                                    <div className='m-2-3 h-line'></div>
                                    <div className='m-2-x2-content --m-subtitle-chart'>
                                        {elements[12] ? this.getUrlIfThereIsURL(elements[12]) : "Hệ sinh thái mở Phenikaa iCloud"}
                                    </div>
                                    <div className='m-2-4 h-line'></div>
                                    <div className='m-2-x3-content --m-subtitle-chart'>
                                        {elements[13] ? this.getUrlIfThereIsURL(elements[13]) : "Thiết bị IoT thông minh"}
                                    </div>
                                    <div className='m-2-5 h-line'></div>
                                    <div className='m-2-x4-content --m-subtitle-chart'>
                                        {elements[14] ? this.getUrlIfThereIsURL(elements[14]) : "Công nghệ tự hành"}
                                    </div>
                                    <div className='m-2-6 h-line'></div>
                                    <div className='m-2-x5-content --m-subtitle-chart'>
                                        {elements[15] ? this.getUrlIfThereIsURL(elements[15]) : "Trí tuệ nhân tạo"}
                                    </div>
                                    <div className='m-2-7 h-line'></div>
                                    <div className='m-2-x6-content --m-subtitle-chart'>
                                        {elements[16] ? this.getUrlIfThereIsURL(elements[16]) : "Xử lý hình ảnh"}
                                    </div>
                                    <div className='m-2-8 h-line'></div>
                                    <div className='m-2-x7-content --m-subtitle-chart'>
                                        {elements[17] ? this.getUrlIfThereIsURL(elements[17]) : "Xử lý giọng nói"}
                                    </div>
                                    <div className='m-2-9 h-line'></div>
                                    <div className='m-2-x8-content --m-subtitle-chart'>
                                        {elements[18] ? this.getUrlIfThereIsURL(elements[18]) : "Công nghệ Giáo dục"}
                                    </div>
                                    <div className='m-2-10 h-line'></div>
                                    <div className='m-2-x9-content --m-subtitle-chart'>
                                        {elements[19] ? this.getUrlIfThereIsURL(elements[19]) : "Phần mềm, giải pháp phần mềm mô phỏng kỹ thuật, công nghệ"}
                                    </div>
                                    <div className='m-2-11 h-line'></div>
                                    <div className='m-2-x10-content --m-subtitle-chart'>
                                        {elements[20] ? this.getUrlIfThereIsURL(elements[20]) : "Chuyển giao Công nghệ"}
                                    </div>
                                </>
                            </Accordion.Collapse>
                        </div>
                        <div>
                            <Accordion.Toggle className={classes.boxAccordion} eventKey="2">
                                <img className={classes.imgLogo} src={icons[3].file_small} alt="" />
                                <span>{elements[26] ? elements[26][0] : "GIAO DUC"}</span>
                                {elements[26] ? this.getUrlIfThereIsURLIcon(elements[26]) : <></>}
                            </Accordion.Toggle>
                            <Accordion.Collapse className={classes.detailAccordion2} eventKey="2">
                                <>
                                    {/* 4 */}
                                    <div className='m-4-1 v-line'></div>
                                    <div className='m-4-2 h-line'></div>
                                    <div className='m-4-x1-title --m-subtitle-chart'>
                                        {elements[25] ? this.getUrlIfThereIsURL(elements[25]) : "Pho thong"}
                                    </div>
                                    <div className='m-4-3 v-line'></div>

                                    <div className='m-4-4 h-line'></div>
                                    <div className='m-4-x1-content --m-chart-content chart-content-animate'>
                                        {elements[23] ? this.getUrlIfThereIsURL(elements[23]) : "Hoa chat"}
                                    </div>
                                    <div className='m-4-5 h-line'></div>
                                    <div className='m-4-x2-content --chart-content chart-content-animate'>
                                        {elements[24] ? this.getUrlIfThereIsURL(elements[24]) : "Da Thach anh"}
                                    </div>
                                    {/* 2 */}
                                    <div className='m-4-6 h-line'></div>
                                    <div className='m-4-x2-title --m-subtitle-chart'>
                                        {elements[22] ? this.getUrlIfThereIsURL(elements[22]) : "Dien - Dien tu Thiet bi thong minh"}
                                    </div>
                                </>
                            </Accordion.Collapse>
                        </div>
                        <div>
                            <div>
                                <Accordion.Toggle className={classes.boxAccordion} eventKey="3">
                                    <img className={classes.imgLogo} src={icons[4].file_small} alt="" />
                                    <span>{elements[37] ? elements[37][0] : "NGHIEN CU U"}</span>
                                    {elements[37] ? this.getUrlIfThereIsURLIcon(elements[37]) : <></>}

                                </Accordion.Toggle>
                            </div>
                            <Accordion.Collapse className={classes.detailAccordion3} eventKey="3">
                                <>
                                    {/* 5 */}
                                    <div className='m-5-1 v-line'></div>
                                    {/*<div className='m-5-2 h-line'></div>
                                    <div className='m-5-x1-content --m-subtitle-chart'>
                                        {elements[27] ? this.getUrlIfThereIsURL(elements[27]) : "Trung tâm chiếu sáng"}
                                    </div> */}
                                    {/* <div className='m-5-3 h-line'></div>
                                    <div className='m-5-x2-content --m-subtitle-chart'>
                                        {elements[26] ? this.getUrlIfThereIsURL(elements[26]) : "TT thiết kế kiểu dáng công nghiệp"}
                                    </div>*/}
                                    <div className='m-5-2 h-line'></div>
                                    <div className='m-5-x1-content --m-subtitle-chart'>
                                        {elements[27] ? this.getUrlIfThereIsURL(elements[27]) : "TT IoT & AI"}
                                    </div>
                                    <div className='m-5-3 h-line'></div>
                                    <div className='m-5-x2-content --m-subtitle-chart'>
                                        {elements[28] ? this.getUrlIfThereIsURL(elements[28]) : "Công nghệ vật liệu (Polymer, Ceramic, Nano)"}
                                    </div>
                                    <div className='m-5-4 h-line'></div>
                                    <div className='m-5-x3-content --m-subtitle-chart'>
                                        {elements[29] ? this.getUrlIfThereIsURL(elements[29]) : "TT thiết kế vi mạch"}
                                    </div>
                                    <div className='m-5-5 h-line'></div>
                                    <div className='m-5-x4-content --m-subtitle-chart'>
                                        {elements[30] ? this.getUrlIfThereIsURL(elements[30]) : "Công nghệ in 3D"}
                                    </div>
                                    <div className='m-5-6 h-line'></div>
                                    <div className='m-5-x5-content --m-subtitle-chart'>
                                        {elements[31] ? this.getUrlIfThereIsURL(elements[31]) : "Khoa học Y – Dược"}
                                    </div>
                                    <div className='m-5-7 h-line'></div>
                                    <div className='m-5-x6-content --m-subtitle-chart'>
                                        {elements[32] ? this.getUrlIfThereIsURL(elements[32]) : "Công nghệ in 3D"}
                                    </div>
                                    <div className='m-5-8 h-line'></div>
                                    <div className='m-5-x7-content --m-subtitle-chart'>
                                        {elements[33] ? this.getUrlIfThereIsURL(elements[33]) : "Khoa học Y – Dược"}
                                    </div>
                                    <div className='m-5-9 h-line'></div>
                                    <div className='m-5-x8-content --m-subtitle-chart'>
                                        {elements[34] ? this.getUrlIfThereIsURL(elements[34]) : "Nông nghiệp công nghệ cao"}
                                    </div>
                                    <div className='m-5-10 h-line'></div>
                                    <div className='m-5-x9-content --m-subtitle-chart'>
                                        {elements[35] ? this.getUrlIfThereIsURL(elements[35]) : "ĐIện tử, Điện tử hữu cơ"}
                                    </div>
                                    <div className='m-5-11 h-line'></div>
                                    <div className='m-5-x10-content --m-subtitle-chart'>
                                        {elements[36] ? this.getUrlIfThereIsURL(elements[36]) : "Cơ Điện tử, Tự động hóa"}
                                    </div>
                                    {/* <div className='m-5-12 h-line'></div>
                                    <div className='m-5-x11-content --m-subtitle-chart'>
                                        {elements[37] ? this.getUrlIfThereIsURL(elements[37]) : "TT nghiên cứu xe tự hành"}
                                    </div>
                                    <div className='m-5-13 h-line'></div>
                                    <div className='m-5-x12-content --m-subtitle-chart'>
                                        {elements[38] ? this.getUrlIfThereIsURL(elements[38]) : "TT nghiên cứu trí tuệ nhân tạo"}
                                    </div> */}
                                </>
                            </Accordion.Collapse>
                        </div>
                        <div>
                            <Accordion.Toggle className={classes.boxAccordion} eventKey="4">
                                <img className={classes.imgLogo} src={icons[5].file_small} alt="" />
                                <span>{elements[38] ? elements[38] : "QUỸ ĐỔI MỚI SÁNG TẠO"}</span>
                                {elements[38] ? this.getUrlIfThereIsURLIcon(elements[38]) : <></>}

                            </Accordion.Toggle>
                            <Accordion.Collapse className={classes.detailAccordion4} eventKey="4">
                                <>
                                    {/* 6 */}
                                    {/* <div className='m-4-1 v-line'></div>
                                    <div className='m-4-2 h-line'></div>
                                    <div className='m-4-x1-title --m-subtitle-chart'>
                                        {elements[22] ? this.getUrlIfThereIsURL(elements[22]) : "Pho thong"}
                                    </div> */}
                                </>
                            </Accordion.Collapse>
                        </div>
                        <div>
                            <Accordion.Toggle className={classes.boxAccordion} eventKey="5">
                                <img className={classes.imgLogo} src={icons[6].file_small} alt="" />
                                <span>{elements[45] ? elements[45][0] : "THƯƠNG MẠI"}</span>
                                {elements[45] ? this.getUrlIfThereIsURLIcon(elements[45]) : <></>}

                            </Accordion.Toggle>
                            <Accordion.Collapse className={classes.detailAccordion5} eventKey="5">
                                <>
                                    {/* 7 */}
                                    <div className='m-7-1 v-line'></div>
                                    <div className='m-7-2 h-line'></div>
                                    <div className='m-7-x1-title --m-subtitle-chart'>
                                        {elements[44] ? this.getUrlIfThereIsURL(elements[44]) : "Quốc tế"}
                                    </div>
                                    <div className='m-7-3 v-line'></div>

                                    <div className='m-7-4 h-line'></div>
                                    <div className='m-7-x1-content --m-chart-content chart-content-animate'>
                                        {elements[40] ? this.getUrlIfThereIsURL(elements[40]) : "Công ty Vicostone US (Mỹ)"}
                                    </div>
                                    <div className='m-7-5 h-line'></div>
                                    <div className='m-7-x2-content --chart-content chart-content-animate'>
                                        {elements[41] ? this.getUrlIfThereIsURL(elements[41]) : "Công ty Vicostone Canada"}
                                    </div>
                                    <div className='m-7-6 h-line'></div>
                                    <div className='m-7-x3-content --m-chart-content chart-content-animate'>
                                        {elements[42] ? this.getUrlIfThereIsURL(elements[42]) : "Hệ thống phân phối tại Châu Âu"}
                                    </div>
                                    <div className='m-7-7 h-line'></div>
                                    <div className='m-7-x4-content --chart-content chart-content-animate'>
                                        {elements[43] ? this.getUrlIfThereIsURL(elements[43]) : "Hệ thống phân phối tại Úc"}
                                    </div>

                                    <div className='m-7-8 h-line'></div>
                                    <div className='m-7-x2-title --m-subtitle-chart'>
                                        {elements[39] ? this.getUrlIfThereIsURL(elements[39]) : "Việt Nam"}
                                    </div>
                                </>
                            </Accordion.Collapse>
                        </div>
                    </Accordion>
                    <div className={classes.linkBack}>
                        <div className={classes.navBack}>
                            <a className={classes.customLink} href={prevLink && prevLink !== "" ? prevLink : '/about-us'}>
                                <div className={'d-flex'}>
                                    <div className={'d-flex'}>
                                        <i className={[classes.arrow, classes.left, classes.customMargin].join(" ")}></i>
                                    </div>

                                    <span className={classes.textBack}>{this.state.backButton}</span>
                                </div>
                            </a>
                        </div>
                    </div>
                </>
        }
        return (
            <div className={['container', classes.customContainer].join(' ')}>
                {content}
            </div>
        )
    }
}
