import React, { Component } from "react"

import { isMobile, isTablet } from "react-device-detect"

import { Navbar } from "react-bootstrap"
import Styled from "styled-components"
import Api from "./Api"

import classes from "./Logo.module.css"

const CustomDiv = Styled.div`
position: fixed;

//long icon
// top: 0.5rem;
// z-index: 99;
// width: 9%;

//box icon
top: 0rem;
z-index: 99;
width: 7%;
`
export default class Logo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dummy: [
        {
          assets: require("./assets/logo-en.png"),
        },
      ],
      currentLogo: this.props.logoColor,
      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,

      prevScrollpos: window.pageYOffset,
      visible: true,
      isHeader: 1,
    }

    this.listeners = {}

    this.getSectionDetail = this.getSectionDetail.bind(this)
    this.updateColor = this.updateColor.bind(this)
  }
  componentDidMount() {
    this.getSectionDetail()
    // this.handleScroll(this, this.listeners)
    if (isTablet) {
      this.setState({
        isHeader: 2,
      })
      window.addEventListener("scroll", this.handleScroll)
      // window.addEventListener('scroll', this.listeners.scrolling)
    } else if (isMobile) {
      this.setState({
        isHeader: 3,
      })
      window.addEventListener("scroll", this.handleScroll)
      // window.addEventListener('scroll', this.listeners.scrolling)
    } else {
      this.setState({
        isHeader: 1,
      })
    }
  }

  componentWillUnmount() {
    if (isTablet || isMobile) {
      window.removeEventListener("scroll", this.handleScroll)
      // window.addEventListener('scroll', this.listeners.scrolling)
    }
  }

  handleScroll = () => {
    const { prevScrollpos } = this.state

    const currentScrollPos = window.pageYOffset
    let visible
    if (currentScrollPos > 0 && prevScrollpos > currentScrollPos) {
      visible = true
    } else if (currentScrollPos === 0){
      visible = true
    } else {
      visible = false
    }

    this.setState({
      prevScrollpos: currentScrollPos,
      visible: visible,
    })
  }

  // handleScroll = (self, listeners) => {
  //   let oldScroll = 0;
  //   function scrolling() {
  //     // print "false" if direction is down and "true" if up
  //     let isScrollingUp = oldScroll > this.pageYOffset
  //     if (isScrollingUp !== self.state.visible) {
  //       self.setState({ visible: isScrollingUp });
  //     }
  //     oldScroll = this.scrollY;
  //   }
  //   listeners.scrolling = scrolling
  //   return scrolling
  // }

  updateColor() {
    this.setState({
      currentLogo: this.props.logoColor,
    })
  }

  getSectionDetail() {
    const apiData = new Api()
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data
          const widget = []

          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            })
          })

          sections.page = widget
          this.setState({
            contentSection: widget,
            isLoaded: true,
          })
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          })

          // window.location.assign(`/error/${error.response.status ? error.response.status : 404}`);
        })
    })
  }

  render() {
    if (this.state.currentLogo !== this.props.logoColor) {
      this.updateColor()
    }
    const { dummy, contentSection } = this.state
    let logo,
      imgSource,
      whiteIcon = null
    if (this.state.isLoaded) {
      let count = 0
      let lang = localStorage.getItem("language")
      let activeLang, idx
      if (this.state.isHeader !== 3) {
        activeLang = lang === "English" ? 0 : 2
        idx = lang === "English" ? 1 : 3
      } else {
        activeLang = lang === "Vietnamese" ? 4 : 4
        idx = 5
      }
      // let activeLang = lang === 'English' ? 0 : 1
      contentSection.forEach((item) => {
        count = count + 1
        if (count === 1 && item.index === 1 && item.category === "Media") {
          // let idx = this.state.isHeader === 3 ? 4 : 2

          item.sections.widget_contents.forEach((s, index) => {
            if (activeLang === index) {
              imgSource = s.file
            }

            if (index === idx) {
              whiteIcon = s.file
            }
            // if (index === 2) {
            //     whiteIcon = s.file;
            // }
            // imgSource = (s.file ? (s.file[activeLang].file ? s.file[activeLang].file : s.file[0].file) : require('./assets/logo-vn.png'))
          })
        } else if (count === 1 && item.index === 1 && item.category !== "Media") {
          imgSource = dummy[0].assets
        }
      })
      logo = (
        <Navbar.Brand href='/' className={classes.navLogo}>
          <img className={classes.imgLogo} src={this.state.currentLogo ? whiteIcon : imgSource} alt='logo' />
        </Navbar.Brand>
      )
    } else if (this.state.isApiLoaded) {
      imgSource = dummy[0].assets
      logo = (
        <Navbar.Brand href='/' className={classes.navLogo}>
          <img className={classes.imgLogo_2} src={imgSource} alt='logo' />
        </Navbar.Brand>
      )
    }

    let hidden
    if (isTablet) {
      hidden = !this.state.visible ? classes.navbarHidden : ""
    } else if (isMobile) {
      hidden = !this.state.visible ? classes.navbarHidden : ""
    } else {
      hidden = ""
    }

    return <CustomDiv className={[classes.customLogo, hidden].join(" ")}>{logo}</CustomDiv>
  }
}
