import React from 'react'

import { isMobile, isTablet } from "react-device-detect"
import Slider from "react-slick";


import classes from '../IN_PP.module.scss'

export default function YearFilter(props) {
    const settings = {
        infinite: false,
        variableWidth: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        swipe: true
    };

    let highlightYear = props.article.map((item, i) => {
        // console.log(`${props.active} ++++ ${i} +++ ${props.active.toString() === i.toString()}`)
        return (
            <div className={props.active.toString() === i.toString() ? classes.yearActive : classes.year}>
                <p onClick={props.onClick} data-id={i}>{item}</p>
            </div>
        );
    });

    let content
    if (isTablet) {
        content = highlightYear
    } else if (isMobile) {
        content = <Slider {...settings}>{highlightYear}</Slider>
    } else {
        content = highlightYear
    }

    return (
        <>
            {content}
        </>
    )
}
