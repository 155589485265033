import React, { Component } from "react";
// import { Link } from "react-router-dom";

import { Accordion, Card } from "react-bootstrap";
// import ReactHtmlParser from "react-html-parser";
import dateFormat from "dateformat";

import ContextAwareToggle from "./Accordion";
import YearFilter from "./YearFilter";
import Api from "../Api";

import classes from "./../AO_Section1.module.scss";

class Views extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yearItem: ["2020"],

      active: false,
      activeYear: false,
      years: [],
      isLoaded: false,
      articleList: [],
      articlesFiltered: [],
      isApiloaded: false,
      views: null,
      idNews: "",
      filtered: "",
      articleCategories: null,
      pagination: 6,
      articleID: null,
      displayStyle: "grid",
      articleByYearCollection: [],
      filterIndex: 0,
      isFiltered: false,
      loadedArticles: false,
    };
    this.getArticleDetail = this.getArticleDetail.bind(this);

    this.onFilterChange = this.onFilterChange.bind(this);
    this.getSearchedData = this.getSearchedData.bind(this);
    this.toggleClass = this.toggleClass.bind(this);
    this.loadArticlesToState = this.loadArticlesToState.bind(this);
    this.sortAndFilterArray = this.sortAndFilterArray.bind(this);
    this.sortAllArray = this.sortAllArray.bind(this);
    this.setCurrentLink = this.setCurrentLink.bind(this);
  }

  componentDidMount() {
    this.setState({
      articleID: this.props.id ? this.props.id : "",
      articleCategories: this.props.category ? this.props.category : "",
      displayStyle: this.props.view ? this.props.view : "grid",
    });
    if (this.props.idOther) {
      this.getArticleDetail(
        this.props.idOther,
        "",
        "",
        this.props.valueSearch ? this.props.valueSearch : "",
        "1"
      );
      if (this.props.idGovernement) {
        this.getArticleDetail(
          this.props.idGovernement,
          "",
          "",
          this.props.valueSearch ? this.props.valueSearch : "",
          "2"
        );
        if (this.props.idTypical) {
          this.getArticleDetail(
            this.props.idTypical,
            "",
            "",
            this.props.valueSearch ? this.props.valueSearch : "",
            "3"
          );
        }
      }
    }
  }

  loadArticlesToState() {
    this.setState(
      {
        loadedArticles: true,
        articleList: this.props.articleList,
        articlesFiltered: this.props.articleList,
      },
      function () {
        this.sortAndFilterArray(null);
      }
    );
  }

  sortAndFilterArray(yearToFilter) {
    let outputArticle = [...this.state.articleList];
    var finalOutputArray = [];

    if (yearToFilter) {
      outputArticle.forEach((item) => {
        if (
          item.date &&
          item.date.substring(0, 4) === yearToFilter.toString()
        ) {
          finalOutputArray.push(item);
        }
      });
    } else {
      finalOutputArray = outputArticle;
    }

    // Sorting the array by date
    finalOutputArray = this.sortAllArray(finalOutputArray);

    this.setState({
      articlesFiltered: finalOutputArray,
    });
  }

  sortAllArray(arr) {
    var listToSort = arr;

    if (listToSort.length > 0) {
      listToSort.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
      return listToSort;
    }
  }

  getArticleDetail(param, filter, size, search, counted) {
    let count = 0;
    if (count === 0) {
      const apiData = new Api();

      this.setState(
        {
          contentArticle: [],
        },
        () => {
          apiData
            .getArticleGroupbyYear(param) //, filter, size, search)
            .then((result) => {
              const results = result.data;
              const yearArray = [];
              console.log(result.data)

              results.forEach((yearElement) => {
                const articleDetails = [];
                yearElement.articles.forEach((articleElement) => {
                  articleDetails.push({ articleDetails: articleElement });
                });

                yearArray.push(yearElement.year);
              });

              // Merge Years for filtering
              let yearSet = new Set(yearArray);
              let yearStateSet = new Set(this.state.articleByYearCollection);
              let mergeYearSet = new Set([...yearSet, ...yearStateSet]);
              let mergeYearArray = [...mergeYearSet];

              // Sorting merged array
              mergeYearArray.sort((a, b) => b - a);
              let isCounted = counted === "3" ? true : false;
              this.setState({
                articleByYearCollection: mergeYearArray,
                isLoaded: isCounted,
              });
            })
            .catch((error) => {
              console.log(error);
              this.setState({ isApiLoaded: true });
            });
          count = 1;
        }
      );
    }
  }

  onFilterChange(e) {
    this.setState({ filtered: e.target.value });
    this.getArticleDetail(this.state.articleID, e.target.value, "", "", "3");
  }

  getSearchedData(e) {
    if (e.keyCode === 13) {
      if (e.target.value !== "") {
        this.getArticleDetail(
          this.state.articleID,
          "",
          "",
          e.target.value,
          "3"
        );
      }
    }
  }

  toggleClass(e) {
    let i = e.target.getAttribute("data-id");
    let item = e.target.innerText;
    if (this.state.active === i) {
      this.setState({ active: false, activeYear: false });
      this.sortAndFilterArray(false);
    } else {
      this.setState({ active: i, activeYear: item });
      this.sortAndFilterArray(item);
    }
  }

  toggleTabActive = (event) => {
    let val = event.target.value;
    // const currentState = this.state.active;
    if (val === "0") {
      this.setState({ isFiltered: false, filterIndex: 0 });
    } else {
      this.setState({ isFiltered: true, filterIndex: val });
    }
  };

  setCurrentLink() {
    localStorage.setItem(
      "prevLink",
      `${window.location.href}${
        this.props.sectionNameN ? `/#${this.props.sectionNameN}` : ""
      }`
    );
  }

  render() {
    let dummyAccordion,
      dummyAccordion2,
      dummyAccordion3,
      highlightYear,
      filterAll,
      apiTitle,
      dummytitlehighlight1,
      dummytitlehighlight2,
      dummytextTitle,
      dummytextContennt,
      dummyTitle,
      dummytextContent = null;

    let listOfArticles;

    if (this.state.isLoaded && !this.state.loadedArticles) {
      this.loadArticlesToState();
      //this.props.articleList;
    }

    listOfArticles = this.state.articlesFiltered;
    let awardCards = null;

    const { articleByYearCollection } = this.state;

    if (listOfArticles) {
      awardCards = listOfArticles.map((article, index) => {
        let urlTo = `${this.props.readmoreUrl}/${article.url_title}/${article.id}`;
        let strYear = article.date;
        let dateFull = dateFormat(strYear, "dd-mm-yyyy");

        let contentReadmore;
        if (article.category) {
          contentReadmore =
            article.category.includes(
              "System Category / Sliders / Overview"
            ) !== true ? (
              <a
                onClick={() => this.setCurrentLink()}
                className={classes.customLink}
                href={urlTo}
                rel="noopener noreferrer"
              >
                <div className={classes.outerCircle}>
                  <div className={classes.innerCircle}></div>
                </div>
                <span className={classes.viewMore}>{this.props.readmore}</span>
              </a>
            ) : (
              <></>
            );
        }

        if (
          this.state.isFiltered &&
          parseInt(this.state.filterIndex) === article.index
        ) {
          switch (article.index) {
            case 5:
              return (
                <Card className={classes.customCard}>
                  <ContextAwareToggle
                    as={Card.Header}
                    eventKey={index + 1}
                    className={classes.cardHeader}
                  >
                    <div className={classes.accParent}>
                      <p className={classes.dateArcticle}>{dateFull}</p>
                      <div className={classes.accFirst}>
                        <img
                          className={classes.imgLogo}
                          src={require("./assets/Group3412.svg")}
                          alt="img"
                        />
                        <h1 className={classes.titleArticle}>
                          {article.content}
                        </h1>
                      </div>
                    </div>
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey={index + 1}>
                    <Card.Body className={classes.textAccor}>
                      {/* <p className={classes.catAwards}>Governement Awards</p> */}
                      <Card.Title className={classes.titleAccorContent}>
                        {/* {article.content} */}
                      </Card.Title>
                      {/* {ReactHtmlParser(article.title)} */}
                      {contentReadmore}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            case 6:
              return (
                <Card className={classes.customCard}>
                  <ContextAwareToggle
                    as={Card.Header}
                    eventKey={index + 1}
                    className={classes.cardHeader}
                  >
                    <div className={classes.accParent}>
                      <p className={classes.dateArcticle}>{dateFull}</p>
                      <div className={classes.accFirst}>
                        <img
                          className={classes.imgLogo}
                          src={require("./assets/icon.svg")}
                          alt="img"
                        />
                        <h1 className={classes.titleArticle}>
                          {article.content}
                        </h1>
                      </div>
                    </div>
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey={index + 1}>
                    <Card.Body className={classes.textAccor}>
                      {/* <p className={classes.catAwards}>International Awards</p> */}
                      <Card.Title className={classes.titleAccorContent}>
                        {/* {article.content} */}
                      </Card.Title>
                      {/* {ReactHtmlParser(article.title)} */}
                      {contentReadmore}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            case 7:
              return (
                <Card className={classes.customCard}>
                  <ContextAwareToggle
                    as={Card.Header}
                    eventKey={index + 1}
                    className={classes.cardHeader}
                  >
                    <div className={classes.accParent}>
                      <p className={classes.dateArcticle}>{dateFull}</p>
                      <div className={classes.accFirst}>
                        <img
                          className={classes.imgLogo}
                          src={require("./assets/Group3411.svg")}
                          alt="img"
                        />
                        <h1 className={classes.titleArticle}>
                          {article.content}
                        </h1>
                      </div>
                    </div>
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey={index + 1}>
                    <Card.Body className={classes.textAccor}>
                      {/* <p className={classes.catAwards}>International Awards</p> */}
                      <Card.Title className={classes.titleAccorContent}>
                        {/* {article.content} */}
                      </Card.Title>
                      {/* {ReactHtmlParser(article.title)} */}
                      {contentReadmore}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              );

            default:
          }
        } else if (!this.state.isFiltered) {
          switch (article.index) {
            case 5:
              return (
                <Card className={classes.customCard}>
                  <ContextAwareToggle
                    as={Card.Header}
                    eventKey={index + 1}
                    className={classes.cardHeader}
                  >
                    <div className={classes.accParent}>
                      <p className={classes.dateArcticle}>{dateFull}</p>
                      <div className={classes.accFirst}>
                        <img
                          className={classes.imgLogo}
                          src={require("./assets/Group3412.svg")}
                          alt="img"
                        />
                        <h1 className={classes.titleArticle}>
                          {article.content}
                        </h1>
                      </div>
                    </div>
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey={index + 1}>
                    <Card.Body className={classes.textAccor}>
                      <Card.Title className={classes.titleAccorContent}>
                        {/* {article.content} */}
                      </Card.Title>
                      {/* {ReactHtmlParser(article.title)} */}
                      {contentReadmore}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            case 6:
              return (
                <Card className={classes.customCard}>
                  <ContextAwareToggle
                    as={Card.Header}
                    eventKey={index + 1}
                    className={classes.cardHeader}
                  >
                    <div className={classes.accParent}>
                      <p className={classes.dateArcticle}>{dateFull}</p>
                      <div className={classes.accFirst}>
                        <img
                          className={classes.imgLogo}
                          src={require("./assets/icon.svg")}
                          alt="img"
                        />
                        <h1 className={classes.titleArticle}>
                          {article.content}
                        </h1>
                      </div>
                    </div>
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey={index + 1}>
                    <Card.Body className={classes.textAccor}>
                      {/* <p className={classes.catAwards}>International Awards</p> */}
                      <Card.Title className={classes.titleAccorContent}>
                        {/* {article.content} */}
                      </Card.Title>
                      {/* {ReactHtmlParser(article.title)} */}
                      {contentReadmore}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            case 7:
              return (
                <Card className={classes.customCard}>
                  <ContextAwareToggle
                    as={Card.Header}
                    eventKey={index + 1}
                    className={classes.cardHeader}
                  >
                    <div className={classes.accParent}>
                      <p className={classes.dateArcticle}>{dateFull}</p>
                      <div className={classes.accFirst}>
                        <img
                          className={classes.imgLogo}
                          src={require("./assets/Group3411.svg")}
                          alt="img"
                        />
                        <h1 className={classes.titleArticle}>
                          {article.content}
                        </h1>
                      </div>
                    </div>
                  </ContextAwareToggle>
                  <Accordion.Collapse eventKey={index + 1}>
                    <Card.Body className={classes.textAccor}>
                      <Card.Title className={classes.titleAccorContent}>
                        {/* {article.content} */}
                      </Card.Title>
                      {/* {ReactHtmlParser(article.title)} */}
                      {contentReadmore}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            default:
          }
        }
      });
    }

    filterAll = <p className={classes.yearActive}>{this.props.filterAll}</p>;
    highlightYear = (
      <YearFilter
        article={articleByYearCollection}
        active={this.state.active}
        onClick={this.toggleClass}
      />
    );
    // highlightYear = articleByYearCollection.map((item, i) => {
    //     return (
    //         <div className={this.state.active === i ? classes.yearActive : classes.year}>
    //             <p onClick={() => this.toggleClass(i, item)}>{item}</p>
    //         </div>
    //     );
    // });

    dummytitlehighlight1 = "Lorem ipsum lorem ipsum lorem";
    dummytitlehighlight2 = "Lorem ipsum lorem ipsum lorem";

    dummytextTitle = <p className={classes.titleTxt}>{this.props.topTitle}</p>;
    dummytextContent = (
      <p className={classes.contentArtTxt}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages, and more recently
        with desktop publishing software like Aldus PageMaker including versions
        of Lorem Ipsum.
      </p>
    );

    dummyTitle = (
      <div className={classes.title1}>
        {/* <p>{this.props.title}</p>  */}
        <p>AWARDS</p>
      </div>
    );
    apiTitle = (
      <div className={classes.title1}>
        <p>{this.props.title}</p>
        {/* <p>AWARDS</p> */}
      </div>
    );

    let dummyFilterCategory = (
      <select
        className={classes.selectStyle}
        onChange={this.toggleTabActive}
        value={this.state.filterIndex}
      >
        <option value="0">{this.props.filterAll}</option>
        <option value="5">{this.props.titleFilter1}</option>
        <option value="7">{this.props.titleFilter2}</option>
        {/* <option value='6'>{this.props.titleFilter3}</option> */}
      </select>
    );

    dummytextContent = `
                            Lorem Ipsum is simply dummy text of the printing
                            and typesetting industry. Lorem Ipsum has been
                            the industry's standard dummy text ever since the
                            1500s, when an unknown printer took a galley of
                            type and scrambled it to make a type specimen book.
                            It has survived not only five centuries, but also
                            the leap into electronic typesetting, remaining
                            essentially unchanged. It was popularised in
                            the 1960s with the release of Letraset sheets
                            containing Lorem Ipsum passages, and more
                            recently with desktop publishing software like
                            Aldus PageMaker including versions of Lorem Ipsum.`;

    dummyAccordion = (
      <Accordion defaultActiveKey="0">
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            className={classes.cardHeader}
          >
            12/01/2020
            <img
              className={classes.imgLogo}
              src={require("./assets/Group3411.svg")}
              alt="img"
            />{" "}
            {this.props.titleArticle}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className={classes.textAccor}>
              <p className={classes.catAwards}>International Awards</p>
              <Card.Title className={classes.titleAccorContent}>
                PHENNIKAA
              </Card.Title>
              {dummytextContennt}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            12/01/2020
            <img
              className={classes.imgLogo}
              src={require("./assets/Group3412.svg")}
              alt="img"
            />
            Lorem ipsum lorem ipsum lorem
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body className={classes.textAccor}>
              {dummytextContennt}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="2">
            12/01/2020
            <img
              className={classes.imgLogo}
              src={require("./assets/Group3411.svg")}
              alt="img"
            />
            Lorem ipsum lorem ipsum lorem
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body className={classes.textAccor}>
              {dummytextContennt}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="3">
            12/01/2020
            <img
              className={classes.imgLogo}
              src={require("./assets/Group3412.svg")}
              alt="img"
            />
            Lorem ipsum lorem ipsum lorem
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body className={classes.textAccor}>
              {dummytextContennt}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    );
    return (
      <div className={classes.componentViews}>
        {/* {dummytextTitle} */}
        {/* {dummytextContent} */}
        <p className={classes.titleTxt}>{this.props.topTitle}</p>
        <h1 className={classes.contentArtTxt}>{this.props.titleArticle}</h1>

        <div className={classes.highlight}>
          {/* {filterAll} */}
          {dummyFilterCategory}
          {highlightYear}
        </div>

        <Accordion className={classes.customAccordion} defaultActiveKey="0">
          {awardCards ? awardCards : dummyAccordion}
        </Accordion>
      </div>
    );
  }
}

export default Views;
