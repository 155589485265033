import React, { Component } from "react";
import classes from "./C_Section1.module.css";
import Api from "./api";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
import Slider from "react-slick";

export default class CO extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      notLoaded: false,
      contentSection: [],
      articles: [],
      app: null,
      text: "",
      dummy: [{}],
      careerId: null,
      categories: [],
      IdArt: null,
      counts: 0,
      url: "",
    };
    this.onFilterChange = this.onFilterChange.bind(this);
    this.getSectionDetail = this.getSectionDetail.bind(this);
    this.getArticles = this.getArticles.bind(this);
    this.getHtmlParagraph = this.getHtmlParagraph.bind(this);
    this.getSearchedData = this.getSearchedData.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }
  onFilterChange(e) {
    this.setState({
      filtered: e.target.value,
    });
    this.getArticles(this.state.IdArt, e.target.value);
  }

  getSearchedData(e) {
    if (e.keyCode === 13) {
      if (e.target.value !== "") {
        this.getArticles(this.state.IdArt, "", "", e.target.value);
      }
    }
  }

  getSectionDetail() {
    const articleCollection = [];
    const apiData = new Api();
    this.setState(
      {
        sections: [],
        articleArray: [],
        categories: [],
      },
      () => {
        apiData
          .getSectionDetail()
          .then((result) => {
            // this.setState({ isLoaded: true });
            const sections = result.data;
            const widget = [];
            const dataCat = [];

            dataCat.push(sections.widgets[1].article_categories);

            let urlWidget = sections.widgets[1].url;

            let textAv = sections.widgets[3].widget_contents[0]
              ? sections.widgets[3].widget_contents[0].content
              : "Available";

            result.data.widgets.forEach((item) => {
              if (item.category === "Article" && !item.is_internal) {
                this.getArticles(item.id, "");
              }
              widget.push({
                id: item.id,
                index: item.index,
                category: item.category,
                name: item.name,
                sections: item,
                contentItems: 20,
              });
            });

            sections.page = widget;

            this.setState({
              sectionName: result.data.name,
              sections: widget,
              categories: dataCat,
              isLoaded: true,
              url: urlWidget,
              IdArt: widget[1].id,
              articleArray: articleCollection,
              text: textAv,
            });
          })
          .catch((error) => {
            this.setState({ notLoaded: true });
          });
      }
    );
  }

  getArticles(param, filter, articleURL, search) {
    const apiData = new Api();
    this.setState({}, () => {
      apiData
        .getArticleDetailByYear(param, filter, articleURL, search)
        .then((result) => {
          const articles = result.data;
          const art = [];
          let count = articles.count;

          articles.results.forEach((a) => {
            const assets = [];

            a.article_assets.forEach((ass, i) => {
              if (i === 0) {
                assets.push({ asset: ass.asset_file });
              }
            });

            art.push({
              id: a.id,
              category: a.category_name,
              name: a.name,
              title: a.article_languages.title,
              content: a.article_languages.content,
              assets: assets,
              date: a.date,
              url: articleURL + "/" + a.id,
              isLoaded: true,
            });
          });

          this.setState({
            articleArray: art,
            counts: count,
          });
        })
        .catch((error) => {
          this.setState({ notLoaded: true });
        });
    });
  }

  getHtmlParagraph(str) {
    if (str !== "") {
      return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ");
    }
  }

  render() {
    let availablePositionsText,
      openPositionsText,
      careerList,
      // navBar,
      idReal,
      thisUrl,
      getDate,
      title,
      app,
      info;

    const { isLoaded, sections } = this.state;

    const sett = {
      className: "classSlider",
      infinite: false,
      centerPadding: "20px",
      variableWidth: true,
      slidesToShow: 3,
      swipeToSlide: true,
    };

    if (isLoaded) {
      sections.forEach((ele, index) => {
        switch (index) {
          case 0:
            openPositionsText = ele.sections.widget_contents[0]
              ? ele.sections.widget_contents[0].content
              : "";
            break;
          case 1:
            idReal = ele.id;
            break;
          case 2:
            break;
          case 3:
            break;
          case 4:
            break;
          case 5:
            app = ele.sections.widget_contents[0].content;
            break;
          case 6:
            break;
          default:
        }

        info = (
          <>
            <p className={classes.subtitle}>
              {this.state.counts} {this.state.text}
            </p>
          </>
        );

        const arr = this.state.articleArray.reverse();

        careerList = arr.map((d, i) => {
          thisUrl = this.state.url ? this.state.url + "/" + d.id : "/";
          let strDate = d.date;
          getDate = dateFormat(strDate, "dd-mm-yyyy");
          title = d.title;

          return (
            <div className={classes.box}>
              <div className={classes.cardContent}>
                <h4 className={classes.titleCard}>{title}</h4>
                <p className={classes.titleDate}>{getDate}</p>
              </div>
              <div className={classes.cardContentHover}>
                <div className={classes.boxText}>
                  <h4 className={classes.titleCard2}>{title}</h4>
                </div>
                <div className={classes.boxTrans}>
                  <Link to={thisUrl} className={classes.customLink}>
                    <div className={classes.btnApply}>
                      <p>{app}</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          );
        });
      });
    }

    let viewOnchange = null;
    if (idReal) {
      viewOnchange = (
        <>
          <div className={classes.bottomContent}>
            <Slider {...sett}>{careerList}</Slider>
          </div>
        </>
      );
    }
    return (
      <>
        <div className={classes.top}>
          <div className={["container", classes.cusT].join(" ")}>
            <div className="row">
              <div className={["col", classes.pad0].join(" ")}>
                <h3 className={classes.title}>{openPositionsText}</h3>
                {info}
              </div>
              <div className={classes.rightCol}></div>
            </div>
          </div>
        </div>

        <div className={classes.bottom}>
          <div className={["container", classes.cusT].join(" ")}>
            <h3 className={classes.bottomTitle}>{availablePositionsText}</h3>
          </div>
          <div>{viewOnchange}</div>
        </div>
      </>
    );
  }
}
