import React, { Component } from "react"

import Slider from "react-slick"

import Industry from "./Industry/Industry"
import Technology from "./Technology/Technology"
import Educations from "./Educations/Educations"
import Scientific from "./Scientific/Scientific"
import Distributions from "./Distribution/Distribution"
import Api from "../Api"

import classes from "./Mobile.module.css"

class Mobile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabs: [
        { id: 1, title: "Lorem Ipsum", component: <Industry key={1} /> },
        { id: 2, title: "Lorem Ipsum", component: <Technology key={2} /> },
        { id: 3, title: "Lorem Ipsum", component: <Educations key={3} /> },
        { id: 4, title: "Lorem Ipsum", component: <Scientific key={4} /> },
        { id: 5, title: "Lorem Ipsum", component: <Distributions key={5} /> },
      ],
      currentPage: null,
      // tabsActive: this.props.tab,
      tabsActive: 3,
      activePage: <div></div>,

      sections: {
        widgets: [],
      },
      sections1: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,

      industry: <div></div>,
      technology: <div></div>,
      educations: <div></div>,
      scientific: <div></div>,
      distributions: <div></div>,
    }
    this.getSectionDetail = this.getSectionDetail.bind(this)
  }

  componentDidMount() {
    this.getSectionDetail()
  }

  getSectionDetail() {
    const apiData = new Api()
    this.setState({ sections: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          this.setState({
            isApiLoaded: true,
          })
          const sections = result.data
          const widget = []
          const dataParamApi = []

          dataParamApi.push({
            id: sections.widgets[0].id,
            url: sections.widgets[0].url,
            dataIndex: sections.widgets[0].widget_contents,
          })

          let subsData = []
          result.data.widgets.forEach((item, i) => {
            if (i === 16){
              subsData.push({
                url: item.url,
                content: item.widget_contents[0].content
              })
            }
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            })
          })
          sections.page = widget
          let industry,
            technology,
            educations,
            scientific,
            distributions = null
          const ind = []
          const tech = []
          const edu = []
          const sci = []
          const dist = []
          const dataAll = []
          const dataExt = []
          const dataExtI = []
          const dataExtEdu = []
          const dataExtS = []
          const dataExtD = []

          let count = 0
          let readMore,
            readLess,
            viewDetails,
            industryMov = ""

          widget.forEach((s) => {
            switch (s.index) {
              case 1:
                readMore = s.sections.widget_contents[0] ? s.sections.widget_contents[0].content : "Read More"
                break
              case 2:
                readLess = s.sections.widget_contents[0] ? s.sections.widget_contents[0].content : "Read Less"
                break
              case 15:
                viewDetails = s.sections.widget_contents[0] ? s.sections.widget_contents[0].content : "View details"
                break
              case 16:
                industryMov = s.sections.widget_contents[0] ? s.sections.widget_contents[0].file : ""
                break
              default:
                break
            }

            if (s.index === 3) {
              s.sections.widget_contents.forEach((s, i) => {
                dataAll.push({
                  id: s.id,
                  index: i,
                  category: s.category,
                  sections1: s,
                })

                if (i === 0) {
                  ind.push({
                    item: s,
                  })
                } else if (i === 1) {
                  tech.push({
                    item: s,
                  })
                } else if (i === 2) {
                  edu.push({
                    item: s,
                  })
                } else if (i === 3) {
                  sci.push({
                    item: s,
                  })
                } else if (i === 4) {
                  dist.push({
                    item: s,
                  })
                  // distributions = <Distributions items={s}/>
                }
              })
            } else if (s.index === 4) {
              s.sections.widget_contents.forEach((s, i) => {
                if (i === 0) {
                  ind.push({
                    desc: s,
                    id: i,
                  })
                } else if (i === 1) {
                  tech.push({
                    desc: s,
                    id: i,
                  })
                } else if (i === 2) {
                  edu.push({
                    desc: s,
                    id: i,
                  })
                } else if (i === 3) {
                  sci.push({
                    desc: s,
                    id: i,
                  })
                } else if (i === 4) {
                  dist.push({
                    desc: s,
                    id: i,
                  })
                }
              })
            } else if (s.index === 5) {
              s.sections.widget_contents.forEach((s, i) => {
                dataExt.push({
                  id: s.id,
                  index: i,
                  category: s.category,
                  sections: s,
                })
              })
            } else if (s.index === 6 || s.index === 7 || s.index === 8 || s.index === 9) {
              let arr = []
              s.sections.widget_contents.forEach((x, i) => {
                arr.push(x)
              })
              dataExtEdu.push({
                sections: arr,
              })
              count = count + 1
            } else if (s.index === 10) {
              let arr = []
              s.sections.widget_contents.forEach((s, i) => {
                arr.push(s)
              })
              dataExtS.push({
                sections: arr,
              })
            } else if (s.index === 11) {
              let arr = []
              s.sections.widget_contents.forEach((s, i) => {
                arr.push(s)
              })
              dataExtD.push({
                sections: arr,
              })
            } else if (s.index === 12) {
              let arr = []
              s.sections.widget_contents.forEach((s, i) => {
                arr.push(s)
              })
              dataExtI.push({
                sections: arr,
              })
            } else if (s.index === 13) {
              let arr = []
              s.sections.widget_contents.forEach((s, i) => {
                arr.push(s)
              })
              dataExtS.push({
                sections: arr,
              })
            }
          })

          industry = <Industry items={ind} dataAll={dataAll} dataParam={dataParamApi} dataExt={dataExtI} readMore={readMore} readLess={readLess} subsData={subsData} viewDetails={viewDetails} />
          technology = <Technology items={tech} dataAll={dataAll} dataParam={dataParamApi} dataExt={dataExt} readMore={readMore} readLess={readLess} subsData={subsData} viewDetails={viewDetails} movie={industryMov} />
          educations = <Educations items={edu} dataAll={dataAll} dataParam={dataParamApi} dataExt={dataExtEdu} readMore={readMore} readLess={readLess} subsData={subsData} viewDetails={viewDetails} />
          scientific = <Scientific items={sci} dataAll={dataAll} dataParam={dataParamApi} dataExt={dataExtS} readMore={readMore} readLess={readLess} subsData={subsData} viewDetails={viewDetails} />
          distributions = <Distributions items={dist} dataAll={dataAll} dataParam={dataParamApi} dataExt={dataExtD} readMore={readMore} readLess={readLess} subsData={subsData} viewDetails={viewDetails} />

          let activeTab = null
          if (parseInt(this.props.tab) >= 0) {
            let tab = this.props.tab
            if (parseInt(tab) === 0) {
              activeTab = industry
            } else if (parseInt(tab) === 1) {
              activeTab = technology
            } else if (parseInt(tab) === 2) {
              activeTab = educations
            } else if (parseInt(tab) === 3) {
              activeTab = scientific
            } else if (parseInt(tab) === 4) {
              activeTab = distributions
            } else {
              activeTab = industry
            }
          }
          this.setState({
            industry: industry,
            technology: technology,
            educations: educations,
            scientific: scientific,
            distributions: distributions,
            sections: widget,
            sections1: dataAll,
            isLoaded: true,
            tabsActive: this.props.tab,
            activePage: activeTab,
          })
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
            tabsActive: this.props.tab,
            activePage: <Industry key={1} />,
          })
        })
    })
  }

  toggleActiveHandler = (val, key) => {
    this.setState({
      tabsActive: key,
      activePage: [val],
    })
  }

  handlePageChange = (number) => {
    this.setState({ currentPage: number })
  }

  render() {
    const settings = {
      infinite: false,
      variableWidth: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 2,
      swipe: true,
    }

    const { sections } = this.state

    let navItem = null
    if (this.state.isLoaded) {
      sections.forEach((s, i) => {
        if (s.index === 3) {
        } else if (s.index === 4) {
          navItem = s.sections.widget_contents.map((s, i) => {
            let key = "tab" + i
            let active = parseInt(this.state.tabsActive)
            let activeTab = active === i ? (active % 2 === 0 ? classes.isActiveB : classes.isActiveO) : ""
            // let isActive = active === i ? true : false
            let boxs = i % 2 === 0 ? classes.boxsB : classes.boxsO
            let url = s.assets[0] ? s.assets[0].file_small : require("../assets/default-image.svg")
            let description = s.assets[0]?.description
            let tab = null
            if (i === 0) {
              tab = this.state.industry
            } else if (i === 1) {
              tab = this.state.technology
            } else if (i === 2) {
              tab = this.state.educations
            } else if (i === 3) {
              tab = this.state.scientific
            } else if (i === 4) {
              tab = this.state.distributions
            }

            return (
              <div className={[boxs, activeTab].join(" ")}>
                {/* <Navbar idx={i} box={boxs} isActive={isActive} active={activeTab} onClick={() => this.toggleActiveHandler(tab, i)} img={url} key={key} content={s.title} /> */}
                <div className={[classes.boxSlider].join(" ")} onClick={() => this.toggleActiveHandler(tab, i)}>
                  <img className={classes.imgIcon} src={url} alt={description}></img>
                  <span id={key}>{/* {s.title} */}</span>
                </div>
              </div>
            )
          })
        }
      })
    } else if (this.state.isApiLoaded) {
      navItem = this.state.tabs.map((n, i) => {
        let key = "tab" + n.id
        let boxs = n.id % 2 === 0 ? classes.boxsB : classes.boxsO
        let activeTab = this.state.tabsActive === n.id - 1 ? classes.isActiveB : ""
        return (
          <div key={n.id} className={[boxs, activeTab].join(" ")} onClick={() => this.toggleActiveHandler(n.component, n.id - 1)}>
            <img className={classes.imgIcon} src={require("../assets/icon.svg")} alt=''></img>
            <span id={key}></span>
          </div>
        )
      })
    }

    return (
      <div className={["section-wrapping", classes.sectionWrapping].join(" ")}>
        <div className={[classes.newNavbar].join(" ")}>
          <div className={["container d-flex", classes.customContainer].join(" ")}>
            {/* <div className="d-flex flex-row">{navItem}</div> */}
            <Slider {...settings}>{navItem}</Slider>
          </div>
        </div>
        {this.state.activePage}
      </div>
    )
  }
}

export default Mobile
