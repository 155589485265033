import React, { Component } from "react";
import classes from "./../Section4.module.css";
import { Link } from "react-router-dom";
import Api from "./../Api";
import ReactHtmlParser from "react-html-parser";

import visualclass from "./../SD_Section4.module.scss";

class SD_Section4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentSection: [],
      aboutPhenikaa: [],
      sectionName: '',
      isLoaded: false,
      isApiLoaded: true,
    };

    this.chartClass = {
      6: visualclass.six,
      7: visualclass.seven,
      8: visualclass.eight,
      9: visualclass.nine,
      10: visualclass.ten,
      11: visualclass.eleven
    }

    this.getSectionDetail = this.getSectionDetail.bind(this);
    this.setCurrentLink = this.setCurrentLink.bind(this);

  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState(
      {
        contentSection: [],
      },
      () => {
        apiData
          .getSectionDetail()
          .then((result) => {
            const sections = result.data;
            const widget = [];
            const aboutPhenikaa = [];
            let sectionName = sections.name.toLowerCase().replace(/\s+/g, '-')

            result.data.widgets.forEach((item) => {
              widget.push({
                id: item.id,
                index: item.index,
                category: item.category,
                name: item.name,
                url: item.is_internal,
                urlType: item.url,
                sections: item,
              });
            });

            result.data.widgets[2].widget_contents.forEach((item) => {
              aboutPhenikaa.push(item.title);
            });

            sections.page = widget;
            this.setState({
              contentSection: widget,
              aboutPhenikaa: aboutPhenikaa,
              sectionName: sectionName,
              isLoaded: true,
            });
          })
          .catch((error) => {
            this.setState({ isApiLoaded: true });
          });
      }
    );
  }

  getHtmlParagraph(str) {
    if (str !== "") {
      return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ");
    }
  }

  setCurrentLink(){
    localStorage.setItem('prevLink', `${window.location.href}${this.state.sectionName ? `/#${this.state.sectionName}` : ''}`);
  }

  render() {
    const { contentSection, isLoaded, isApiLoaded } = this.state;
    let title_content,
      data_content,
      urlText,
      readText,
      middleText,
      LinkItem,
      contentVisual = [];

    if (isLoaded) {
      let count = 0;
      contentSection.forEach((item) => {
        count = count + 1;
        if (count === 1 && item.index === 1 && item.category === "Text") {
          item.sections.widget_contents.forEach((ele) => {
            if (ele.content !== "") {
              title_content = <h2 className={classes.title}>{ele.content}</h2>;
            }
          });
        }

        if (count === 2 && item.index === 2 && item.category === "Text") {
          item.sections.widget_contents.forEach((ele) => {
            if (ele.content !== "") {
              data_content = <p className={classes.text}>{ele.content}</p>;
            }
          });
        }

        // if (count === 3 && item.index === 3 && item.category === "Article") {

        // }
        if (count === 3 && item.index === 3 && item.category === "Text") {
          urlText = item.urlType;

          LinkItem = item.sections.widget_contents.map((z) => {
            if (z.content) {
              readText = <p className={classes.contentLink}>{z.content}</p>;
            } else {
              readText = <p className={classes.contentLink}></p>;
            }
            return (
              <Link onClick={()=>this.setCurrentLink()} to={urlText} className={classes.link}>
                <div className={classes.padd}>
                  <div className={[classes.contentLink].join(" ")}>
                    <div className={classes.boxLinks}>
                      <div className={classes.outerCircle2}>
                        <div className={classes.innerCircle2}></div>
                      </div>
                      {readText}
                    </div>
                  </div>
                </div>
              </Link>
            );
          });
        }

        if (count === 4 && item.index === 4 && item.category === "Text") {
            urlText = item.urlType;
            middleText = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "";
        }

        if (count === 5 && item.index === 5 && item.category === "Text") {
          const result = item.sections.widget_contents.map((z) => {
            return <strong>{z.content}</strong>
          });

          contentVisual = result.map((e, i) => {
            return (
              <div className={visualclass.wrappContentBox}>
                <div className={visualclass.circleLine2}>
                  <div className={visualclass.circleLineIn2}></div>
                </div>

                <div className={visualclass.boxRound}>
                  <p className={visualclass.textInboxRound}>
                    {e}
                  </p>
                  <div className={visualclass.circleLine}>
                    <div className={visualclass.circleLineIn}></div>
                  </div>
                </div>
                <div className={visualclass.Line}></div>
              </div>
            );
          });
        }
      });
    } else if (isApiLoaded) {

    }

    return (
      <div className={classes.Section4}>
        <div className={["container", classes.textContainer].join(" ")}>
          <div className={classes.boxTitle}>{title_content}</div>
          <div className={classes.boxText}>{data_content}</div>
          {LinkItem}
        </div>
        <div className={["container", classes.boxC].join(" ")}>
          <div className={visualclass.backgroundchart}>
            <div className={[visualclass.wrappContent, this.chartClass[contentVisual.length]].join(' ')}>
              <div className={visualclass.chart}>
                <div className={visualclass.circle}>
                  <div className={visualclass.circleFake}></div>
                  <div className={visualclass.circleInner}>
                    <p className={visualclass.textInnerCircle}>
                      {middleText}
                    </p>
                  </div>
                </div>
                {contentVisual}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SD_Section4;
