import React, { Component } from "react"
import Slider from "react-slick"
import Api from "./Api"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./News.css"
import { Link } from "react-router-dom"
import classes from "./News_Slider_1_row.module.css"

class News_Slider_1_row extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contentSection: [],
      isLoaded: false,
      isApiLoaded: false,
      idApplicant: null,
      isLoggedIn: null,
      isJobLoaded: false,
      jobList: [],
      categories: [],
      defaultSlider: null,
      readText: null,
    }
    this.getSectionDetail = this.getSectionDetail.bind(this)
  }

  componentDidMount() {
    let token = localStorage.getItem("token")
    let isLoggedIn = localStorage.getItem("isLoggedIn")
    let id = localStorage.getItem("id")
    const customParam = {
      // order: "-post_date",
      // page_size: 10,
      max_size: "True",
      order: "-due_date",
      status: "Published"
    }

    if (isLoggedIn && token) {
      this.setState({
        idApplicant: id,
        isLoggedIn: isLoggedIn,
      })
      this.getSectionDetail(() => {
        this.getJobDetail(id, customParam)
      })

      if (window.screen.width <= 1400 && window.screen.width > 1024) {
        this.setState({
          defaultSlider: 5,
        })
      } else if (window.screen.width <= 1024 && window.screen.width > 480) {
        this.setState({
          defaultSlider: 2,
        })
      } else {
        this.setState({
          defaultSlider: 4,
        })
      }
    } else {
      this.getSectionDetail(() => {
        this.getJobDetail(null, customParam)
      })
      this.setState({
        isLoggedIn: false,
      })
    }
  }

  getSectionDetail(callback) {
    const apiData = new Api()
    apiData
      .getSectionDetail()
      .then((result) => {
        const sections = result.data
        this.setState(
          {
            isLoaded: true,
            contentSection: sections,
            readText: sections.widgets[0],
          },
          callback
        )
      })
      .catch((error) => {
        this.setState({
          isApiLoaded: true,
        })
      })
  }

  getJobDetail(param, customParam) {
    const apiData = new Api()
    apiData
      .getJobDetail(param, customParam)
      .then((result) => {
        const jobList = result.data
        const widget = []
        let dataCat = []
        if (!this.state.isLoggedIn) {
          jobList.results.forEach((item) => {
            let dataItem
            let imageDescription
            let art = item.article.article_assets
            if (art.length === 0) {
              dataItem = require("./assets/default-image.svg")
            } else {
              dataItem = art[0].file_small
              imageDescription = art[0].description
            }
            let slug = item.article.article_languages.slug_title

            if (item.article.article_languages?.title && item.article.article_languages?.content) {
              widget.push({
                id: item.id,
                is_image: dataItem,
                image_description: imageDescription,
                position_name: item.position_name,
                title: item.article.article_languages.title,
                url_title: item.article.article_languages.slug_title,
                post_date: item.post_date,
                url: this.state.readText.url + "/" + (slug !== "" ? slug : "-") + "/" + item.id,
                status: !this.state.isLoggedIn ? "" : item.status,
              })
              dataCat.push(item.company_name)
            }
          })
        } else {
          jobList.results.forEach((item) => {
            let dataItem = null
            let imageDescription
            let art = item.article_assets

            if (art.length === 0) {
              dataItem = require("./assets/default-image.svg")
            } else {
              dataItem = art[0].file_small
              imageDescription = art[0].description
            }
            let slug = item.article_languages?.slug_title
            if (item.article_languages?.title && item.article_languages?.content) {
              widget.push({
                id: item.id,
                is_image: dataItem,
                image_description: imageDescription,
                title: item.title,
                url_title: item.slug_title,
                position_name: item.position,
                company: item.company,
                url: this.state.readText.url + "/" + (slug !== "" ? slug : "-") + "/" + item.id,
                status: item.applied,
                post_date: "",
                location_address: item.location,
              })
              dataCat.push(item.company_name)
            }
          })
        }

        dataCat = [...new Set(dataCat)]

        this.setState({
          jobList: widget,
          categories: dataCat,
          isJobLoaded: true,
          counter: 2,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  render() {
    const { jobList } = this.state

    const readText = this.state.readText ? this.state.readText.widget_contents[0].content : "Read More"

    let box,
      backgroundImage = null

    const settings = {
      className: "center",
      centerMode: false,
      infinite: false,
      centerPadding: "",
      variableWidth: true,
      swipeToSlide: true,
      // slidesToShow: this.state.defaultSlider,
    }

    if (this.state.isJobLoaded) {
      box = jobList.map((k, i) => {
        let isMarginfirst = i === 0 ? classes.isMarginLeft : ""

        let id = k.id
        let is_src = k.is_image
        let img = null

        if (k.is_image === require("./assets/default-image.svg")) {
          img = <img className={classes.no_src} src={is_src} alt='' />
        } else {
          img = <img className={classes.is_src} src={is_src} alt={k.image_description} />
        }

        return (
          <div className={[classes.bxf1r, isMarginfirst].join(" ")}>
            {/* <img className={classes.is_src} src={is_src} alt="" /> */}
            {img}
            <div className={classes.shdw0}>
              <div className={classes.cardFd1}>
                <div className={classes.cardcontent}>
                  <p className={classes.kpos}>{k.title}</p>
                  <p className={classes.date}>{k.company}</p>
                  <Link to={k.url}>
                    <div className={classes.outerCircle2}>
                      <div className={classes.innerCircle2}></div>
                    </div>
                    <p className={classes.rdMoreP}>{readText}</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )
      })
    }

    return (
      <div className='section-content'>
        <div style={backgroundImage}>
          <div className='section-blur'>
            <div className={classes.contentWrapping}>
              <div className={["container p-0", classes.joinC].join(" ")}>{/* JOB POSTER */}</div>
              <div className={["section-template", classes.joinT].join(" ")}>
                <div style={{ width: "100vw" }}>
                  <Slider {...settings}>{box}</Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default News_Slider_1_row
