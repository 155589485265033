import React, { Component } from "react";

import Api from './Api'
import classes from './Reset_Password.module.css'

class Reset_Password extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: false,
            isToken: false,
            isPassword: [],
            isConfirmPassword: [],
            contentSection: [],
            isLoaded: false,
            redirectUrl: '/'
        };
        this.resetPassword = this.resetPassword.bind(this)
        this.isValidate = this.isValidate.bind(this)
        this.getSectionDetail = this.getSectionDetail.bind(this)
    }

    componentDidMount() {
        this.getSectionDetail()

        if (this.props.token) {
            this.setState({
                token: this.props.token,
                isToken: true
            })
            this.getSectionDetail()
        }
    }

    getSectionDetail() {
        const apiData = new Api();
        apiData
            .getSectionDetail()
            .then((result) => {
                const sections = result.data;
                const widget = [];
                let url

                sections.widgets.forEach((item) => {
                    url = item.url ? item.url : '/'
                    widget.push({
                        id: item.id,
                        index: item.index,
                        category: item.category,
                        name: item.name,
                        sections: item,
                    });
                });

                sections.page = widget;
                this.setState({
                    contentSection: widget,
                    redirectUrl: url,
                    isLoaded: true,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    isApiLoaded: true
                })
            });
    }

    isValidate(data) {
        let pw = []
        let cpw = []
        if (data) {
            if (data.password === "") {
                pw.push({
                    status: true,
                    text: 'This field is required'
                })
            } else {
                pw.push({
                    status: false,
                    text: ''
                })
            }

            if (data.cpassword === "") {
                cpw.push({
                    status: true,
                    text: 'This field is required'
                })
            } if (data.cpassword !== "" && data.cpassword !== data.password){
                cpw.push({
                    status: true,
                    text: `Password confirmation doesn't match`
                })
            } else {
                cpw.push({
                    status: false,
                    text: ``
                })
            }
            this.setState({
                isPassword: pw,
                isConfirmPassword: cpw
            })
        } else {

        }
        
        if(!pw[0].status && !cpw[0].status){
            return true
        } else {
            return false
        } 
    }

    resetPassword() {
        const reset = new Api();

        const data = {
            password: document.getElementById('password').value,
            cpassword: document.getElementById('confirm-password').value,
            token: this.state.token
        }

        if (this.isValidate(data)) {
            reset.resetPassword(data)
                .then(res => {
                    this.setState({
                        isEmail: false
                    })
                    window.location.assign(this.state.redirectUrl)
                })
                .catch(err => {
                    if (err.response.data.password){
                        let pw = []
                        pw.push({
                            status: true,
                            text: `Password is to short or password is too common`
                        })
                        this.setState({
                            isPassword: pw,
                        })
                    }
                })
        }
    }

    render() {
        let content
        let errorPassword, contentErrorPassword, errorCPassword, contentErrorCPassword

        if (this.state.isToken) {
            if (this.state.isPassword[0]) {
                errorPassword = this.state.isPassword[0].status ? classes.errorVisible : "";
                contentErrorPassword = this.state.isPassword[0].text
            }
            if (this.state.isConfirmPassword[0]) {
                errorCPassword = this.state.isConfirmPassword[0].status ? classes.errorVisible : "";
                contentErrorCPassword = this.state.isConfirmPassword[0].text
            }
        } else {

        }

        return (
            <div className={['container', classes.customContainer].join(' ')}>
                <div className={classes.content}>
                    <form>
                        <div className={classes.boxContent}>
                            <span className={classes.titleContent}>New Password</span>
                            <input id="password" className={classes.inputModal} type={"password"} defaultValue={""} />
                        </div>
                        <span className={[classes.contentError, errorPassword].join(" ")}>
                            {contentErrorPassword}
                        </span>

                        <div className={classes.boxContent}>
                            <span className={classes.titleContent}>Confirm Password</span>
                            <input id="confirm-password" className={classes.inputModal} type={"password"} defaultValue={""} />
                        </div>
                        <span className={[classes.contentError, errorCPassword].join(" ")}>
                            {contentErrorCPassword}
                        </span>

                        <div className={classes.contentAction}>
                            <div className={classes.cancel} onClick={this.props.loginClicked}>
                                <span>Cancel</span>
                            </div>
                            <div className={classes.boxAction} onClick={() => {
                                this.resetPassword()
                            }}>
                                <span>Continue</span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default Reset_Password;
