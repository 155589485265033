import React from 'react';
import { Link } from "react-router-dom";
import classes from "./Button.module.css";

function Button({className, children, url}) {
    return (
        <div className={[classes.readMoreBox, className].join(" ")}>
            <div className={classes.customLink}>
                <div className={classes.outerCircle}>
                    <div className={classes.innerCircle}></div>
                </div>
                <Link
                    to={url}
                    className={classes.readMore}
                >
                    {children}
                </Link>
            </div>
        </div>
    );
}

export default Button;