import React, { Component, Fragment } from "react";
import Api from "./Api";
import classes from "./sec5.module.css";
import { Slide } from "react-awesome-reveal";
import { Card } from "react-bootstrap";
import "./sec5.css";
import ImageSlider from "./ImageSlider";

class sec5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
      boxCount: 0,
    };

    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          this.setState({
            isApiLoaded: true,
          });
          const contentSection = result.data;
          const widget = [];
          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              url: item.url,
              contentSection: item,
            });
          });
          contentSection.page = widget;
          this.setState({
            contentSection: widget,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
          window.location.assign(
            `/error/${error.response.status ? error.response.status : 404}`
          );
        });
    });
  }

  getHtmlParagraph(str) {
    if (str !== "") {
      return str.replace(/&nbsp;/gi, " ");
    }
  }

  render() {
    const { contentSection } = this.state;
    let title,
      content,
      buttonText,
      imageSlider,
      imgfile = null;
    let buttonURL = "/";

    if (this.state.isLoaded) {
      let count = 0;
      contentSection.forEach((item) => {
        count = count + 1;
        if (count === 1 && item.index === 1 && item.category === "Text") {
          title = item.contentSection.widget_contents[0].content;
        }

        if (count === 2 && item.index === 2 && item.category === "Text") {
          content = item.contentSection.widget_contents[0].content;
          // item.contentSection.widget_contents.forEach((s) => {
          //   if (s.content !== "") {
          //     content = <span>{s.content}</span>;
          //   } else {
          //     content = dummyContent;
          //   }
          // });
        } 

        if (count === 3 && item.index === 3 && item.category === "Media") {
          imgfile = item.contentSection.widget_contents[0];
        }

        if (count === 4 && item.index === 4 && item.category === "Text") {
          buttonURL = item.contentSection.url;
          buttonText = (
            <p>
              {item.contentSection.widget_contents[0]
                ? item.contentSection.widget_contents[0].content
                : ""}
            </p>
          );
        }

        if (count === 5 && item.index === 5 && item.category === "Article") {
          imageSlider = (
            <ImageSlider
              widget={item.contentSection.widget_contents}
              getHtml={this.getHtmlParagraph}
              buttonText={buttonText}
              url={buttonURL}
              title={title}
              content={content}
              file={imgfile}
            />
          );
        }
      });
    } else if (this.state.isApiLoaded) {

    }
    return (
      <Fragment>
        <div fluid className={classes.contSec5}>
          <Slide triggerOnce={true} direction={"left"}>
            {imageSlider}
          </Slide>
        </div>
      </Fragment>
    );
  }
}

export default sec5;
