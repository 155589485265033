import React, { Component } from "react";
import { Link } from "react-router-dom";
import { faShare, faBookmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserContext from "../../Context";

import Api from "../../../Api";
import classes from "./Jobs.module.css";

export default class Jobs extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  removeBookMarkJob = (id) => {
    const contextValue = this.context;
    const job = new Api();

    job
      .removeBookmarkJob(id)
      .then((res) => {
        contextValue.getNewDataApplicant();
        contextValue.showNotification("Jobs removed succesfully");
      })
      .catch((err) => {
        console.log("error =>", err);
      });
  };
  render() {
    let jobs, box, linkUrl, margin_right_added, space_none;

    const contextValue = this.context;

    box = this.props.isBookmark
      ? [classes.box, classes.boxBookmarks].join(" ")
      : classes.box;

    if (this.props.data && this.props.isJobLoaded) {
      if (this.props.data.length === 2) {
        margin_right_added = classes.marginRightAdded;
        space_none = classes.spaceNone;
      } else {
        margin_right_added = "";
        space_none = "";
      }

      if (contextValue.isSectionLoaded) {
        if (contextValue.contentSection[0]) {
          linkUrl =
            contextValue.contentSection[0].sections.url !== ""
              ? contextValue.contentSection[0].sections.url
              : "/career/career-list/career-details-page-new";
        }
      }
      jobs = this.props.data.map((j) => {
        return (
          <Link
            to={`${linkUrl}/${j.url_title ? (j.url_title !== "" ? j.url_title : "-") : "-"}/${j.job_poster}`}
            className={classes.customLink}
          >
            <div className={[box, margin_right_added].join(" ")}>
              <span className={classes.category}>{j.confirmation_name}</span>
              <div className={classes.boxContent}>
                <span className={classes.title}>{j.job_title}</span>
                <div className={classes.innerContent}>
                  <span className={classes.content}>{j.location_address}</span>
                </div>
              </div>
              <div className={classes.boxDetail}>
                <div className="d-flex flex-column">
                  <span className={classes.content}>{this.props.send}</span>
                  <span className={classes.content}>{j.apply_date}</span>
                </div>
                {this.props.isBookmark ? (
                  <div
                    className={[classes.Link, classes.LinkB].join(" ")}
                    onClick={() => this.removeBookMarkJob(j.id)}
                  >
                    <FontAwesomeIcon
                      color="#fff"
                      className="m-auto"
                      icon={this.props.isBookmark ? faBookmark : faShare}
                      size="lg"
                    />
                  </div>
                ) : (
                  <div className={classes.Link}>
                    <FontAwesomeIcon
                      color="#fff"
                      className="m-auto"
                      icon={this.props.isBookmark ? faBookmark : faShare}
                      size="lg"
                    />
                  </div>
                )}
              </div>
            </div>
          </Link>
        );
      });
    }
    return <div className={[classes.jobs, space_none].join(" ")}>{jobs}</div>;
  }
}
