import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { store } from "react-notifications-component";

import Api from "../Api";
import UserContext from "../Context";

import classes from "./Modal1.module.css";

class ModalLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      a: 1,
      emptyEmail: false,
      reqEmail: null,
      reqPwd: null,
      errText: [],
    };
    this.loggedIn = this.loggedIn.bind(this);
    this.checkEmail = this.checkEmail.bind(this);
    this.keyLoggedIn = this.keyLoggedIn.bind(this);
  }

  static contextType = UserContext;

  componentDidMount() {
    let textObj = {};
    let textName = [
      "signIn",
      "newUser",
      "createAcc",
      "email",
      "pwd",
      "forgotPwd",
      "continue",
      "cancel",
      "resetP",
    ];
    this.props.texts.forEach((txt, i) => {
      textObj[textName[i]] = txt.content;
    });
    this.setState({ texts: textObj });
  }

  componentWillReceiveProps(newProps) {
    // console.log("will received login newProps => ", newProps);
    if (newProps.dataErrorText) {
      this.setState({
        errText: newProps.dataErrorText,
      });
    }
  }

  checkEmail(param) {
    let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (!param.match(mailformat)) {
      return false;
    } else {
      return true;
    }
  }

  keyLoggedIn(e) {
    if (e.keyCode === 13) {
      this.loggedIn();
    }
  }

  loggedIn() {
    const login = new Api();
    let isRequired, invalidAddres, wrongPass;

    this.state.errText.forEach((ele, i) => {
      switch (i) {
        case 0:
          isRequired = ele.content;
          break;
        case 4:
          invalidAddres = ele.content;
          break;
        case 5:
          wrongPass = ele.content;
          break;
        default:
        // code block
      }
    });

    const loginData = {
      email: document.getElementById("email").value,
      password: document.getElementById("password").value,
    };

    if (!loginData.email) {
      this.setState({
        reqEmail: isRequired,
      });
      // isValid = false;
    } else if (loginData.email) {
      if (this.checkEmail(loginData.email)) {
        this.setState({
          reqEmail: null,
        });
        // return true
      } else {
        this.setState({
          reqEmail: invalidAddres,
        });
        return false;
      }
    }

    if (!loginData.password) {
      this.setState({
        reqPwd: isRequired,
        emptyEmail: true,
      });
    } else {
      login
        .getApplicant(loginData)
        .then((result) => {
          const data = result.data;
          if (data) {
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("token", data.key);
            localStorage.setItem("id", data.applicant);
            this.context.getNewDataApplicant(
              data.applicant,
              this.props.continueClicked
            );
          }
        })
        .then(() => {
          store.addNotification({
            title: "Success!",
            message: "Login Success!",
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.data.non_field_errors) {
            this.setState({
              reqPwd: wrongPass,
              emptyEmail: true,
            });
          } else {
            this.setState({
              emptyEmail: false,
            });
          }
        });
    }
  }

  render() {
    let texts = this.state.texts ? this.state.texts : {};
    let errorActive = this.state.emptyEmail ? classes.errorActive : "";

    return (
      <Modal
        size={"md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.show}
        onHide={this.props.loginClicked}
        backdrop={false}
      >
        <Modal.Header onClick={this.props.close} closeButton>
          <div className="d-flex justify-content-center flex-column">
            <span className={classes.modalTitle}>{texts.signIn}</span>
            <div className="d-flex">
              <span className={classes.option}>{texts.newUser}?</span>
              <span
                className={classes.contentLink}
                onClick={this.props.registerClicked}
              >
                {texts.createAcc}
              </span>
            </div>
          </div>
        </Modal.Header>
        <div className={classes.modalContent}>
          <div className={classes.content}>
            <div className={classes.mB0x}>
              <div className={classes.boxContent}>
                <span className={classes.titleContent}>{texts.email}</span>
                <input
                  id="email"
                  className={classes.inputModal}
                  type={"text"}
                  defaultValue={""}
                />
              </div>
              <h2 className={[classes.validError, errorActive].join("")}>
                {this.state.reqEmail}
              </h2>
            </div>
            <div className={classes.mB0x}>
              <div className={classes.boxContent}>
                <span className={classes.titleContent}>{texts.pwd}</span>
                <input
                  id="password"
                  className={classes.inputModal}
                  type={"password"}
                  defaultValue={""}
                  onKeyDown={this.keyLoggedIn}
                />
              </div>
              <h2 className={[classes.validError, errorActive].join("")}>
                {this.state.reqPwd}
              </h2>
            </div>

            <div className={classes.contentAction}>
              <span
                className={classes.contentLink}
                onClick={this.props.forgotClicked}
              >
                {texts.forgotPwd}?
              </span>
              <div className={classes.boxAction} onClick={this.loggedIn}>
                <span>{texts.continue}</span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalLogin;
