import React, { Component } from "react"
import { Link } from "react-router-dom"

import ReactHtmlParser from "react-html-parser"
import Slider from "react-slick"

import classes from "./Distribution.module.css"
import "../Slider.css"
export default class Distribution extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dummy: [
        { id: 1, name: "Title", content: "What is Lorem Ipsum?" },
        {
          id: 2,
          name: "Content",
          content: "Where does it come from? Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
        },
        {
          id: 4,
          name: "Background Image",
          assets: require("./assets/default-image.svg"),
        },
      ],

      isDetail: false,
    }
  }

  componentDidMount() { }

  getHtmlParagraph(str) {
    if (str && str !== "") {
      return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ")
    }
  }

  toggleDetail = () => {
    this.setState((prevState) => {
      return { isDetail: !prevState.isDetail }
    })
  }

  getUrlParser(url) {
    if (url !== "/") {
      return url.includes("http") ? (
        <a className={classes.customLink} href={url} target='_blank' rel='noopener noreferrer'>
          <div className={classes.outerCircle}>
            <div className={classes.innerCircle}></div>
          </div>
          <p className={classes.viewMore}>{this.props.viewDetails}</p>
        </a>
      ) : (
        <Link className={classes.customLink} to={url}>
          <div className={classes.outerCircle}>
            <div className={classes.innerCircle}></div>
          </div>
          <p className={classes.viewMore}>{this.props.viewDetails}</p>
        </Link>
      )
    } else {
      let isNull = classes.isNull
      return (
        <Link className={[classes.customLink, isNull].join(" ")} to={url}>
          <div className={classes.outerCircle}>
            <div className={classes.innerCircle}></div>
          </div>
          <p className={classes.viewMore}>{this.props.viewDetails}</p>
        </Link>
      )
    }
  }

  render() {
    const settings = {
      dots: true,
      centerMode: false,
      infinite: false,
      centerPadding: "",
      variableWidth: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
      swipe: true,
      appendDots: (dots) => (
        <div className={classes.customDots}>
          <ul style={{ margin: "0px" }}> {dots} </ul>
        </div>
      ),
      customPaging: (i) => <div className={classes.customPaggination}></div>,
    }

    const { dummy } = this.state
    let title,
      tabTitle,
      desc,
      img,
      content,
      link = null
    let detailContent,
      contentL1,
      contentL2,
      contentL3,
      contentL4,
      contentL5,
      contentL6,
      contentL7,
      contentImg1,
      contentImg2,
      contentBox,
      contentExt = null

    let subs = this.props.subsData

    if (this.props.items) {
      let items = this.props.items
      title = <h2 className={classes.title}>{items[0].item.title}</h2>
      tabTitle = <p className={classes.tabTitle}>{items[1].desc.title}</p>

      let contentImg = items[0].item.assets[0]
      img = <img className={classes.imgContent} src={contentImg ? contentImg.file_small : require("./assets/default-image.svg")} alt={contentImg?.description} />
      content = <div className={classes.contentDesc}>{ReactHtmlParser(items[1].desc.article_language.content)}</div>
      link = !this.state.isDetail ? (
        <div className={[classes.contentLink].join(" ")} onClick={this.toggleDetail}>
          <div className='d-flex'>
            <div className={classes.outerCircle}>
              <div className={classes.innerCircle}></div>
            </div>
            <p className={classes.contentLink}>{this.props.readMore}</p>
          </div>
        </div>
      ) : (
        <></>
      )

      const splitStr = items[0].item.article_language.content.split("#")
      desc = <span className={classes.content}>{ReactHtmlParser(splitStr[0] ? splitStr[0] : "")}</span>

      contentL1 = splitStr[1] ? splitStr[1] : ""
      contentL2 = splitStr[2] ? splitStr[2] : ""
      contentL3 = splitStr[3] ? splitStr[3] : ""
      contentL4 = splitStr[4] ? splitStr[4] : ""
      contentL5 = splitStr[5] ? splitStr[5] : ""
      contentL6 = splitStr[6] ? splitStr[6] : ""
      contentL7 = splitStr[7] ? splitStr[7] : ""

      let contentImg1 = items[0].item.assets[1]
      let contentImg2 = items[0].item.assets[2]

      if (this.props.dataExt) {
        this.props.dataExt.forEach((d, i) => {
          switch (i) {
            case 0:
              contentBox = d.sections.map((s, i) => {
                let url = s.assets ? s.assets[0].file_small || s.assets[0].file : require("./assets/default-image.svg")
                let description = s.assets[0]?.description
                let link = s.article_language.url ? this.getUrlParser(s.article_language.url) : this.getUrlParser("/")

                return (
                  <Link to={subs ? `${subs[0].url}/?tab=5&index=${i + 1}` : ''}>
                    <div className={classes.box}>
                      <img src={url} alt={description}></img>
                      <div className={classes.boxMask}></div>
                      <div className={classes.contentFront}>
                        <div className={classes.boxContentIn}>
                          <div className='mt-auto mb-auto ml-auto mr-3'>
                            <p>{this.getHtmlParagraph(s.article_language.title)}</p>
                            <div className='d-flex flex-row justify-content-end position-relative m-auto mt-1'>{link}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              })
              break
            default:
          }
        })

        contentExt = (
          <div id='mobile-distribution' className='m-0 p-0'>
            <Slider className='m-0 p-0' {...settings}>
              {contentBox}
            </Slider>
          </div>
        )
      }

      let animate = this.state.isDetail ? classes.fadeInBottom : classes.fadeOutTop
      detailContent = this.state.isDetail ? (
        <div className={[classes.detailContent, animate].join(" ")}>
          <>
            <hr />
            <div className={classes.allContent}>
              <div className={classes.boxTop}>
                <img className={classes.imgTop} src={contentImg1 ? contentImg1.file_small : require("./assets/default-image.svg")} alt={contentImg1?.description} />
              </div>
              <div className={classes.boxBottom}>
                <p className={classes.content}> {ReactHtmlParser(contentL1)}</p>
              </div>
            </div>
          </>

          <>
            {/* {ReactHtmlParser(contentL2)} */}
            <Link to={subs ? subs[0].url : ''} style={{ color: 'black' }} className='text-decoration-none'>
              <p><strong>{subs ? subs[0].content : 'Subsidiaries'}</strong></p>
            </Link>
            {contentExt}
          </>

          <div className={[classes.contentLink].join(" ")} onClick={this.toggleDetail}>
            <div className='d-flex'>
              <div className={classes.outerCircle}>
                <div className={classes.innerCircle}></div>
              </div>
              <p className={classes.contentLink}>{this.props.readLess}</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )
    } else {
    }

    return (
      <div className={classes.sectionDistribution}>
        <div className={["container d-flex flex-column", classes.customContainer].join(" ")}>
          <div className={classes.boxImage}>{img}</div>
          <div className={classes.contentTop}>
            <div className={classes.boxContent}>
              {tabTitle}
              {title}
              {desc}
              <div className={[classes.contentOverflow].join(" ")}>{content}</div>
              {link}
            </div>
          </div>

          {detailContent}
        </div>
      </div>
    )
  }
}
