import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Slide } from "react-awesome-reveal";
import Sliders from "./Slider";
import Api from "./Api";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SectionThree.css";
import classes from "./SectionThree.module.css";
import dateFormat from "dateformat";

export default class BoxTablet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentArticle: {
        widgets: [],
      },
      url: "",
      readMore: "Read More",
      isLoaded: false,
      isApiLoaded: true,
    };
    this.getArticleDetail = this.getArticleDetail.bind(this);
    this.setCurrentLink = this.setCurrentLink.bind(this);
  }

  componentDidMount() {
    if (this.props.idf) {
      this.getArticleDetail(this.props.idf);
      this.setState({
        url: this.props.url,
        readMore: this.props.readMore,
      });
    }
  }

  getArticleDetail(param) {
    const apiData = new Api();
    this.setState({ contentArticle: [] }, () => {
      apiData
        .getArticleDetailByYearDescending(param, null, 12, null)
        .then((result) => {
          const articles = result.data;
          let id_widget = articles.widgets ? articles.widgets[0].id : "";
          let id_catg = articles.categories ? articles.categories[0].id : "";
          const art = [];
          articles.results.forEach((a) => {
            const assets = [];
            a.article_assets.forEach((ass, i) => {
              assets.push({
                asset: ass.file_small || ass.asset_file || ass.asset_link,
                asset_type: ass.asset_type,
                description: ass.description
              });
            });

            art.push({
              id: a.id,
              category: a.category_names,
              categoryID: a.categories,
              name: a.name,
              title: a.article_languages.title,
              url_title: a.article_languages.slug_title,
              content: a.article_languages.content,
              assets: assets,
              date: a.date,
            });
          });

          this.setState({
            contentArticle: art,
            isLoaded: true,
            id_widget: id_widget,
            id_catg: id_catg,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  setCurrentLink() {
    localStorage.setItem(
      "prevLink",
      `${window.location.href}${
        this.props.sectionName ? `#${this.props.sectionName}` : ""
      }`
    );
  }

  render() {
    const { contentArticle } = this.state;
    let box = null;
    if (this.state.isLoaded) {
      box = contentArticle.map((s, i) => {
        let category_id = s.categoryID ? s.categoryID[0] : "";
        let widget0, widget1;

        if (this.props.id) {
          this.props.id.forEach((ele, i) => {
            if (i === 0) {
              widget0 = ele;
            } else {
              widget1 = ele;
            }
          });
        }

        let ids = s.id + "-" + category_id + "-" + widget0 + "-" + widget1;
        let strYear = s.date;
        let dateFull = dateFormat(strYear, "dd-mm-yyyy");

        let adjClass = s.content ? "pb-0" : "";

        let isBoxImage = null;
        let idx = 0;
        while (idx < s.assets.length && !isBoxImage) {
          let _asset = s.assets[idx].asset;

          if (s.assets[idx].asset.indexOf("youtube.com") !== -1) {
            _asset = _asset.split("/");
            let ytVideoId = _asset[_asset.length - 1];

            isBoxImage = (
              <img
                className={classes.imgOnBox}
                src={`https://img.youtube.com/vi/${ytVideoId}/hqdefault.jpg`}
                alt={s.assets[idx].description}
              ></img>
            );
          } else if (s.assets[idx].asset_type === "Image") {
            isBoxImage = (
              <img
                className={classes.imgOnBox}
                src={s.assets[idx].asset}
                alt={s.assets[idx].description}
              ></img>
            );
          }
          idx++;
        }

        if (!s.assets.length || !isBoxImage) {
          isBoxImage = (
            <img
              className={classes.imgOnBoxD}
              src={require("./assets/default-image.svg")}
              alt="Default image"
            />
          );
        }

        let firstClass = i === 0 ? classes.firstWrap : "";
        let isImage = (
          <div className={[classes.ContentWrapp, firstClass].join(" ")}>
            <Card className={classes.cardfront}>
              <Card.Body className={[classes.cardFd1, adjClass].join(" ")}>
                <div className={classes.cardcontent}>
                  <p>{s.title}</p>
                </div>
              </Card.Body>
              <div className={classes.cardFd2}>
                <div className={classes.one}>
                  {" "}
                  <Link
                    onClick={() => this.setCurrentLink()}
                    className={classes.customLink}
                    to={`${this.state.url}${s.url_title !== "" ? s.url_title : '-'}/${s.id}`}
                  >
                    <p>{this.state.readMore}</p>
                  </Link>
                </div>
                <div className={classes.two}>
                  <p className={classes.date2}>{dateFull}</p>
                </div>
              </div>
            </Card>
          </div>
        );

        return <>{isImage}</>;
      });
    }

    return (
      <Slide triggerOnce direction="top">
        <Sliders setting={"top"} content={box} />
      </Slide>
    );
  }
}
