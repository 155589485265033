import React, { Component } from "react";
import classes from "./Job_Details_New.module.css";
import { faShareAlt, faBookmark, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Api from './Api'


export default class JobBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isBookmarked: false,
      idBookmarked: '',
      contentArticle: {
        widgets: [],
      }
    }
  }

  componentDidMount() {
    this.isJobBookmarked()
  }

  isJobBookmarked() {
    if (this.props.data) {
      const arr = this.props.data.bookmarks;
      if (arr) {
        let setA = new Set(arr)
        const found = setA.has(parseInt(this.props.idJob)) 
        if (found) {
          this.setState({ isBookmarked: true })
        } else {
          this.setState({ isBookmarked: false })
        }
      } else {
        this.setState({ isBookmarked: false })
      }

      const bookmark = this.props.data.job_bookmarks
      if (bookmark && bookmark.length > 0) {
        const foundID = bookmark.find(element => element = this.props.idJob);
        this.setState({ idBookmarked: foundID.id })
      }
    } else {
      this.setState({ isBookmarked: false })
    }
  }

  bookMarkJob = () => {
    const job = new Api()
    const applicant = parseInt(localStorage.getItem("id"))
    const job_poster = parseInt(this.props.idJob)

    job.bookmarkJob(applicant, job_poster)
      .then(res => {
        // this.props.getNewDataApplicant()
        // this.isJobBookmarked()
        window.location.reload()
        this.props.notifClick(
          "Jobs saved succesfully"
        );
      })
      .catch(err => {
        console.log("error =>", err)
      })
  }

  removeBookMarkJob = () => {
    const job = new Api()

    job.removeBookmarkJob(this.state.idBookmarked)
      .then(res => {
        // this.props.getNewDataApplicant()
        // this.isJobBookmarked()
        window.location.reload()

        this.props.notifClick(
          "Jobs removed succesfully"
        );
      })
      .catch(err => {
        console.log("error =>", err)
      })
  }

  render() {
    let share, save, contentSave;

    share = this.props.share ? this.props.share : "Share"
    save = this.props.save ? this.props.save : "Save"

    if (localStorage.getItem("isLoggedIn") === "true" && localStorage.getItem("id")) {
      if (this.state.isBookmarked) {
        save = this.props.saved ? this.props.saved : "Saved"
        contentSave = <div className={classes.share} onClick={this.removeBookMarkJob}>
          <FontAwesomeIcon className={classes.faIcon} icon={faBookmark} />
          <span>{save}</span>
        </div>
      } else {
        save = this.props.save ? this.props.save : "Save"
        contentSave = <div className={classes.share} onClick={this.bookMarkJob}>
          <FontAwesomeIcon className={classes.faIcon} icon={faBookmark} />
          <span>{save}</span>
        </div>
      }
    } else {
      save = this.props.save ? this.props.save : "Save"
        contentSave = <div className={[classes.share, classes.shareInActive].join(' ')} >
          <FontAwesomeIcon className={classes.faIcon} icon={faBookmark} />
          <span>{save}</span>
        </div>
    }

    return (
      <div className={classes.shareSaveApplyCon}>
        <div className={classes.topShare}>
          <div className={classes.share} onClick={this.props.shareClick}>
            <FontAwesomeIcon className={classes.faIcon} icon={faShareAlt} />
            <span>{share}</span>
          </div>
          {contentSave}
        </div>
      </div>
    );
  }
}
