import React, { Component } from "react"
import ReactHtmlParser from "react-html-parser"
import Api from "./Api"

import classes from "./SD_Community.module.css"

export default class SD_Community extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contentSection: [],
      isLoaded: false,
      isApiLoaded: false,
      activeImg: 0,
    }
    this.getSectionDetail = this.getSectionDetail.bind(this)
  }

  componentDidMount() {
    this.getSectionDetail()
  }

  getSectionDetail() {
    const apiData = new Api()
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data
          const widget = []
          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
              url: item.url,
            })
          })

          sections.page = widget
          this.setState({
            contentSection: widget,
            isLoaded: true,
          })
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          })
        })
    })
  }

  getHtmlParagraph(str) {
    if (str !== "") {
      return str
      //return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ");
    }
  }

  toggleExtends = (i) => {
    this.setState({
      activeImg: i,
    })
  }

  render() {
    const { contentSection } = this.state

    let runningText, rightUrl, banner, title, content1, gallery, link, bannerDescription

    if (this.state.isLoaded) {
      contentSection.forEach((s, i) => {
        switch (i) {
          case 0:
            s.sections.widget_contents.forEach((s) => {
              runningText = s.content ? s.content : ""
            })
            break
          case 1:
            s.sections.widget_contents.forEach((s) => {
              banner = s ? s.file_big : require("./assets/default-image.svg")
              bannerDescription = s?.description
            })
            break
          case 2:
            s.sections.widget_contents.forEach((s) => {
              title = s.article_language.title ? s.article_language.title : ""
              const splitStr = s.article_language.content.split("#")
              content1 = this.getHtmlParagraph(splitStr[0] ? splitStr[0] : "")
              //   content2 = this.getHtmlParagraph(splitStr[1] ? splitStr[1] : "");
            })
            break
          case 3:
            gallery = s.sections.widget_contents.map((s, i) => {
              let active = this.state.activeImg === i ? classes.activeImg : ""
              return (
                <div className={[classes.boxGallery, active].join(" ")} onClick={() => this.toggleExtends(i)}>
                  <figure>
                    <img src={s.file_small} alt={s.description}></img>
                  </figure>
                </div>
              )
            })
            break
          case 4:
            rightUrl = s.sections.url
            link = s.sections.widget_contents.map((s) => {
              let prevLink = "" //  localStorage.getItem("prevLink");

              return (
                <div className={classes.navBack}>
                  <a className={classes.customLink} href={prevLink && prevLink !== "" ? prevLink : rightUrl ? rightUrl : "/sustainable"}>
                    <div className={"d-flex"}>
                      <div className={"d-flex"}>
                        <i className={[classes.arrow, classes.left, classes.customMargin].join(" ")}></i>
                      </div>
                      <span className={classes.textBack}>{s.content}</span>
                    </div>
                  </a>
                </div>
              )
            })
            break
          default:
            break
        }
      })
    } else if (this.state.isApiLoaded) {
    }

    return (
      <div className={classes.contentSection}>
        <div className={classes.banner}>
          <img className={classes.imgBanner} src={banner} alt={bannerDescription}></img>
          <div className={classes.contentRunning}>
            <h1 className={classes.textRunning}>{runningText}</h1>
            <div className={classes.underlineAnimate}></div>
          </div>
        </div>
        <div className={["container", classes.customContainer].join(" ")}>
          <div className={classes.boxContent}>
            <h2 className={classes.titleSection}>{title}</h2>
            <div className={["d-flex"]}>
              <div className={["w-100", classes.textIn].join(" ")}>
                <span className={classes.content}>{ReactHtmlParser(content1)}</span>
              </div>
            </div>
          </div>

          <div className={classes.wrapGallery}>{gallery}</div>
          {link}
        </div>
      </div>
    )
  }
}
