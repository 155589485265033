import React, { Component, createRef } from "react"

import Slider from "react-slick"
import ReactHtmlParser from "react-html-parser"
import BannerIcon from "./BannerIcon"
import Api from "../Api"

import classes from "./Mobile.module.css"
import "./Slider.css"

class Mobile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      sections: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
      selectedBox: null,
      activeBox: 0,
    }
    this.getSectionDetail = this.getSectionDetail.bind(this)
    this.scrollingHandlerBs = this.scrollingHandlerBs.bind(this)
    this.handleOverride = this.handleOverride.bind(this)
  }

  componentDidMount() {
    this.getSectionDetail()
    window.addEventListener("scroll", this.scrollingHandlerBs, true)
    this.handleOverride()
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollingHandlerBs, true)
  }

  bs_sec1_div1 = createRef()
  bs_sec1_div2 = createRef()

  handleOverride() {
    this.props.override(false)
  }

  scrollingHandlerBs() {
    if (this.bs_sec1_div1.current.getBoundingClientRect().bottom < this.bs_sec1_div1.current.getBoundingClientRect().height && this.bs_sec1_div1.current.getBoundingClientRect().bottom > -1) {
      //   console.log(this.bs_sec1_div1.current.getBoundingClientRect().bottom)
      this.props.updateLogo(true)
    }

    if (this.bs_sec1_div2.current.getBoundingClientRect().bottom < this.bs_sec1_div2.current.getBoundingClientRect().height && this.bs_sec1_div2.current.getBoundingClientRect().bottom > -1) {
      this.props.updateLogo(false)
    }
  }

  getSectionDetail() {
    const apiData = new Api()
    this.setState({ sections: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          this.setState({
            isApiLoaded: true,
          })
          const sections = result.data
          const widget = []
          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            })
          })
          sections.page = widget
          this.setState({
            sections: widget,
            isLoaded: true,
          })
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          })
        })
    })
  }

  updateSelectedBox(index) {
    this.setState({
      activeBox: index,
    })
  }

  isBanner = (url) => {
    let content
    if (url !== "") {
      let type = [".png", ".jpg", ".jpeg"]
      let myArr = url.split()
      let result = myArr.some((i) => type.includes(i))

      if (result) {
        content = <img className={classes.imgBanner} src={url} alt=''></img>
      } else {
        content = (
          <video loop={true} autoPlay={true} playsInline muted>
            <source src={url} type='video/mp4' />
          </video>
        )
      }
    } else {
      content = <img className={classes.imgBanner} src={require("../assets/default-image.svg")} alt=''></img>
    }
    return content
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: true,
      appendDots: (dots) => (
        <div className={classes.customDots}>
          <ul style={{ margin: "0px" }}> {dots} </ul>
        </div>
      ),
      customPaging: (i) => <div className={classes.customPaggination}>{/* {i + 1} */}</div>,
    }

    const { sections } = this.state
    let titleBanner, colorTitleBanner, imageBanner, contentSection, infoBoxes, videoPhenikaa, content
    if (this.state.isLoaded) {
      let count = 0
      sections.forEach((item) => {
        count = count + 1
        if (item.index === 1 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            colorTitleBanner = s.font_color
            if (s.content !== "") {
              titleBanner = s.content
            }
          })
        } else if (item.index === 1 && item.category !== "Text") {
          titleBanner = ""
        }

        if (item.index === 2 && item.category === "Media") {
          item.sections.widget_contents.forEach((s) => {
            imageBanner = this.isBanner(s.file ? s.file : require("../assets/default-image.svg"))
          })
        } else if (item.index === 2 && item.category !== "Media") {
          imageBanner = <img className={classes.imageBanner} src={require("../assets/default-image.svg")} alt=''></img>
        }

        if (item.index === 3 && item.category === "Article") {
          contentSection = item.sections.widget_contents.map((s) => {
            return (
              <>
                <p className={[classes.titleContent].join(" ")}>{s.article_language.title}</p>
                <p className={classes.content}>{ReactHtmlParser(s.article_language.content)}</p>
              </>
            )
          })
        } else if (item.index === 3 && item.category !== "Article") {
        }

        if (item.index === 4 && item.category === "Article") {
          infoBoxes = item.sections.widget_contents.map((s, index) => {
            content = <span>{ReactHtmlParser(item.sections.widget_contents[index].article_language.content)}</span>
            return (
              <div className={classes.boxSlider}>
                <div className={classes.boxSliderTop}>
                  <img src={s.assets[0] ? s.assets[0].file : null} alt={s.assets[0] ? s.assets[0].description : null} />
                  <div className={[classes.titleSlider].join(" ")}>
                    <p>{s.title}</p>
                  </div>
                </div>
                {content}
              </div>
            )
          })
        } else if (item.index === 4 && item.category !== "Article") {
        }

        if (item.index === 5 && item.category === "Media") {
          videoPhenikaa = item.sections.widget_contents.map((s) => {
            var filesplit = s.file.split(".")
            var filetype = filesplit[filesplit.length - 1]
            if (filetype === "mp4") {
              return (
                <video className={classes.isvideo} controls autoPlay loop muted playsInline>
                  <source src={s.file} type='video/mp4' />
                </video>
              )
            } else {
              return <img className={classes.houseOfPhenikaa} src={s.file} alt='' />
            }
          })
        }
      })
    } else if (this.state.isApiLoaded) {
    }

    let houseOfPhenikaa = videoPhenikaa

    return (
      <div id='content-section' className={["content-section", classes.contentSection].join(" ")}>
        <div ref={this.bs_sec1_div1} className={classes.banner}>
          {imageBanner}
          <div className={classes.bannerIcon}>
            <BannerIcon />
          </div>
          <div className={classes.bannerContent}>
            <h1 className={[classes.titleBanner, classes.animate].join(" ")} style={{color: colorTitleBanner}}>{titleBanner}</h1>
            <div className={[classes.underlineAnimate]} style={{backgroundColor: colorTitleBanner}}></div>
          </div>
        </div>
        <div ref={this.bs_sec1_div2} id='mobile-bs1' className={["container", classes.customContainer].join(" ")}>
          <div className={[classes.boxContent].join(" ")}>
            <div className={[classes.Content].join(" ")}>
              <div className={classes.boxTop}>{contentSection}</div>
              <div className={classes.boxBottom}>{houseOfPhenikaa}</div>
            </div>
            <div className={classes.infoBoxes}>
              <Slider className='' {...settings}>
                {infoBoxes}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Mobile
