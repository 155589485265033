import React, { Component } from 'react';

import classes from './Banner.module.css'

class Banner extends Component {
    constructor(props) {
        super(props);
        
    }
    

    render() {
        return (
            <div>
                <div className={classes.banner}>
                    <img className={classes.imgBanner} src={this.props.bannerUrl} alt='Banner'></img>
                    {/* <div className={classes.contentRunning}>
                        <p className={classes.textRunning}>{this.props.runningText}</p>
                        <div className={classes.underlineAnimate}></div>
                    </div> */}
                </div>
            </div>
        );
    }
}

export default Banner;