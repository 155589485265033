import React from "react";
import DesktopNew from "./DesktopNew";
import Mobile from "./Mobile/Mobile";
import { isMobile, isTablet } from "react-device-detect";

export default function ContactUs({ updateLogo, override }) {
  let content;

  if (isTablet) {
    content = <DesktopNew updateLogo={updateLogo} override={override} />;
  } else if (isMobile) {
    content = <Mobile updateLogo={updateLogo} override={override} />;
  } else {
    content = <DesktopNew updateLogo={updateLogo} override={override} />;
  }

  return <>{content}</>;
}
