import React from "react"
import useScrollPosition from "@react-hook/window-scroll"
import classes from "../../News.module.css"
import clases from "./Navbar.module.css"
import { xml } from "d3"

export default function Navbar(props) {
  const scrollY = useScrollPosition(10)
  let nav,
    sticky,
    contentSticky,
    filters,
    colorGrid,
    colorList,
    colorGrid2,
    colorList2 = null
  if (props.type) {
    if (props.type === "list" || props.type === "list2") {
      colorList = <img className={classes.img_list} onClick={props.listClick} src={require("../assets/list2.svg")} alt='description' />
      colorList2 = <img className={classes.img_list} onClick={props.listClick2} src={require("../assets/list2White.svg")} alt='description' />
      colorGrid = <img className={classes.img_grid} onClick={props.gridClick} src={require("../assets/grid.svg")} alt='description' />
      colorGrid2 = <img className={classes.img_grid} onClick={props.gridClick2} src={require("../assets/gridWhite.svg")} alt='description' />
    } else {
      colorList = <img className={classes.img_list} onClick={props.listClick} src={require("../assets/list.svg")} alt='description' />
      colorList2 = <img className={classes.img_list} onClick={props.listClick2} src={require("../assets/listWhite.svg")} alt='description' />
      colorGrid = <img className={classes.img_grid} onClick={props.gridClick} src={require("../assets/grid2.svg")} alt='description' />
      colorGrid2 = <img className={classes.img_grid} onClick={props.gridClick2} src={require("../assets/grid2White.svg")} alt='description' />
    }
  }

  let lang = localStorage.getItem("language")
  let defaultFilter = lang !== "English" ? "Tin tức và Sự kiện" : "News and Events"
  let selectedFilter = props.selectedFilter !== "" ? props.selectedFilter : defaultFilter

  if (props.category) {
    let arrCategory = props.category
    let newArrCategory = []
    newArrCategory.push({
      id: 99,
      name: props.filterMagazine
    })

    const newArr = new Set()
    const filteredArr = arrCategory.concat(newArrCategory).filter(el => {
      const duplicate = newArr.has(el.id);
      newArr.add(el.id);
      return !duplicate;
    });

    filters = filteredArr.map((f, i) => {
      if (i === 0 || i === (filteredArr.length - 1)) {
        let isSelected
        if (props.paramValue) {
          if (parseInt(props.paramValue) !== 99) {
            isSelected = parseInt(f.id) !== 99 ? true : false
          } else if (parseInt(props.paramValue) === 99) {
            isSelected = parseInt(f.id) === parseInt(props.paramValue) ? true : false
          }
        } else {
          isSelected = f.name === selectedFilter ? true : false
        }

        let tabClass = isSelected ? [clases.tabFilter, clases.tabActive].join(' ') : clases.tabFilter

        return (
          <div key={f.name} id={f.id} selected={isSelected} className={tabClass} onClick={() => props.onChange(f.id)}>
            {f.name}
          </div>
        )
      }
    })
  }

  let contentNavbar

  if (scrollY > "36") {
    props.onChangeNavbar(true)
    let now = new Date()
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }
    let dateNow = now.toLocaleDateString(lang !== "English" ? "vi" : "en", options)
    sticky = clases.sticky
    contentSticky = clases.contentSticky

    nav = (
      <div className={["d-flex flex-row w-auto h-100"].join(" ")}>
        {/* <select name='filter' id='filter' className={classes.customSelect} onChange={props.onChange}>
          {filters}
        </select> */}
        <div className={clases.stickyFilter}>
          {filters}
        </div>
        {/* <p className={clases.date2}>
          {dateNow}
        </p> */}
        <div className={classes.wrap_option}>
          <div className={classes.grid}>{colorGrid}</div>
          <div className={classes.list}>{colorList}</div>
        </div>
        <label className={classes.box_search}>
          <input onKeyDown={props.onKeyDown} className={classes.input_search} type='text' placeholder={props.searchHere} name='search' />
          <img className='pull-right' src={require("../assets/search.svg")} alt='' />
        </label>
      </div>
    )
    contentNavbar = <div className={[clases.customNavbar, clases.customNavbarSticky, sticky].join(" ")}>
      <div className={clases.navbar}>{nav}</div>
    </div>
  } else {
    props.onChangeNavbar(false)
    let now = new Date()
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    }
    let dateNow = now.toLocaleDateString(lang !== "English" ? "vi" : "en", options)

    sticky = contentSticky = ""
    nav = (
      <>
        <h1 className={clases.navTitle}>{props.title}</h1>
        <p className={clases.date}>
          {dateNow}
        </p>
        <div className={[clases.boxNavbar].join(" ")}>
          {/* <select name='filter' id='filter' onChange={props.onChange}>
            {filters}
          </select> */}
          <div className='d-flex w-100'>
            {filters}
          </div>

          <div className={clases.viewFilter}>
            <div className={clases.colorGridWhite}>{colorGrid2}</div>
            <div className={clases.colorListWhite}>{colorList2}</div>
          </div>

          <div className={clases.boxSearch}>
            <input onKeyDown={props.onKeyDown} className={clases.inputSearch} type='text' placeholder={props.searchHere} />
          </div>
        </div>
      </>
    )
    contentNavbar = <div className={[clases.customNavbar, sticky].join(" ")}>
      <div className={clases.navbar}>{nav}</div>
    </div>
  }
  return contentNavbar
}
