import React, { Component } from "react";
import { Link } from 'react-router-dom'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faGlobe, faMapMarkerAlt, faPhoneAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import Swiper from 'react-id-swiper';
// import 'swiper/swiper.min.css';

import ReactHtmlParser from "react-html-parser";
import Navbar from './Navbar/Navbar'
import CustomModal from './Modal/Modal'
import Api from "./Api";

import './Sub_Section2.css'
import classes from "./Sub_Section2.module.css";


class Sub_Section2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentSection: {
                widgets: [],
            },
            contentArticle: {},
            isLoaded: false,
            isApiLoaded: false,
            isBoxHover: false,
            isShowModal: false,
            boxCount: 0,
            activeTab: null,
            activeTabId: null,
            activeCompany: 1,
            column: 3
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
        this.getArticles = this.getArticles.bind(this);
        this.showingProfile = this.showingProfile.bind(this);
    }

    componentDidMount() {
        let orientation = (window.screen.orientation || {}).type || window.screen.mozOrientation || window.screen.msOrientation;
        this.getSectionDetail();
        if (window.innerWidth >= 2560) {
            this.setState({ column: 4 })
        } else if (window.innerWidth <= 900 && orientation === 'landscape-primary') {
            this.setState({ column: 1 })
        } else if (window.innerWidth < 2560 && window.innerWidth > 768) {
            this.setState({ column: 3 })
        } else if (window.innerWidth <= 480) {
            this.setState({ column: 1 })
        } else {
            this.setState({ column: 2 })
        }
        window.addEventListener("scroll", this.scrollingHandler, true)

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const tab = urlParams.get('tab')
        const index = urlParams.get('index')
        if (tab || (tab && index)) {
            this.setState({
                activeCompany: parseInt(tab),
                activeTab: parseInt(index),
            })
        } else {
            this.setState({
                activeCompany: 1,
            })
        }

    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ contentSection: [] }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    const sections = result.data;
                    const widget = [];

                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            index: item.index,
                            category: item.category,
                            name: item.name,
                            sections: item,
                        });

                        if (item.category === "Article") {
                            this.getArticles(item.id)
                        }
                    });

                    sections.page = widget;
                    this.setState({
                        contentSection: widget,
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        isApiLoaded: true
                    })
                });
        });
    }

    getArticles(param) {
        const apiData = new Api();
        apiData
            .getArticleDetail(param)
            .then((result) => {
                const articles = result.data.results;

                let newArticle = {};
                articles.forEach(article => {
                    newArticle[article.id] = article;
                })

                this.setState({
                    contentArticle: { ...this.state.contentArticle, ...newArticle }
                })
            })
            .catch((error) => {
                console.log(error);
                this.setState({ isApiLoaded: true });
            });
    }

    toggleBoxHandlerEnter = () => {
        this.setState((prevState) => {
            return { isBoxHover: !prevState.isBoxHover };
        });
    };

    showingProfile = (index, id) => {
        this.setState((prevState) => {
            return {
                activeTab: prevState.activeTab === index ? null : index,
                activeTabId: prevState.activeTab === index ? null : id
            };
        });

        setTimeout(function () {
            let elmnt = document.getElementById("content-preview" + id);
            let elmnt2 = document.getElementById("box" + id)
            let stickyNavbar = document.getElementById("stickyNavbar")
            if (elmnt && elmnt2) {
                let top = (elmnt.offsetTop);
                window.scrollTo({
                    top: top - elmnt2.offsetHeight - stickyNavbar.offsetHeight,
                    behavior: 'smooth'
                });
            }
        }, 100);
    }

    getHtmlParagraph(str) {
        if (str !== '') {
            return str.replace(/<\/?p>/gi, " ")
                .replace(/<p>/gi, " ");
        }
    }

    getCards(arr) {
        let content = null
        let isFilled = null
        let isOnlyOne = false

        let company = {}
        if (this.state.contentSection[7]?.category === "Text"
            && this.state.contentSection[7]?.name === "Company Text"
        ) {
            const companyTextArr = this.state.contentSection[7]?.sections.widget_contents;
            companyTextArr.forEach(text => {
                company[text.name] = text.content;
            })
        }

        let returnArr = arr.map((item, idx) => {
            let data = arr
            let contentPreview = <></>
            const location = this.state.contentArticle[data[idx].id]?.location;
            const coordinate = location ? {
                lat: parseFloat(location.latitude),
                lng: parseFloat(location.longitude)
            } : null

            let address = location?.address
            let phone = location?.phone
            let website = location?.website
            let email = location?.email

            if (location?.has_languages) {
                const language = localStorage.getItem("language")
                const idxLanguage = language === "English" ? 0 : 1

                address = location.location_languages[idxLanguage].address
                website = location.location_languages[idxLanguage].website
            }


            if (this.state.activeTab === idx + 1) {
                // const splitStr = item.content.split('#')
                let altImg = data[idx].assets[0]?.description
                content = (
                    <div id={'content-preview' + data[idx].id} className={[classes.boxPreview, classes.active].join(' ')}>
                        <figure className={[classes.figureContent].join(' ')}>
                            <img
                                src={data[idx].assets[0]?.file_small}
                                className={classes.contentImage}
                                alt={altImg}
                            />
                            <FontAwesomeIcon
                                icon={faTimes}
                                className={classes.closeIcon}
                                onClick={() => this.showingProfile(idx + 1, data[idx].id)}
                            />
                        </figure>
                        <div className={classes.textContent}>
                            {ReactHtmlParser(item.article_language.content)}
                        </div>
                        <div className={["d-sm-flex", classes.companyDetailContainer].join(' ')}>
                            <div className={classes.mapContainer}>
                                <LoadScript
                                    googleMapsApiKey="AIzaSyCigRL-AQS62AdkaUj42rp9OX_5Mbze8PM"
                                >
                                    <GoogleMap
                                        mapContainerStyle={{
                                            width: '100%',
                                            height: '500px'
                                        }}
                                        center={coordinate ? coordinate : {
                                            lat: 21.0278,
                                            lng: 105.8342
                                        }}
                                        zoom={14}
                                    >
                                        { /* Child components, such as markers, info windows, etc. */}
                                        <Marker
                                            position={coordinate}
                                        />
                                    </GoogleMap>
                                </LoadScript>
                            </div>
                            <div className={classes.companyIdentityContainer}>
                                <div>
                                    <h4 className="mb-4">{data[idx].article_language.title}</h4>
                                    {
                                        website && website !== "-" ?
                                            <div className="d-flex">
                                                <FontAwesomeIcon
                                                    icon={faGlobe}
                                                    className={classes.icon}
                                                />
                                                <div>
                                                    <h4>{company['Website']}</h4>
                                                    <p>{website}</p>
                                                </div>
                                            </div>
                                            : <></>
                                    }
                                    {
                                        address ?
                                            <div className="d-flex">
                                                <FontAwesomeIcon
                                                    icon={faMapMarkerAlt}
                                                    className={classes.icon}
                                                />
                                                <div>
                                                    <h4>{company['Address']}</h4>
                                                    <p>{address ? address : "-"}</p>
                                                </div>
                                            </div> : <></>
                                    }
                                    {
                                        phone ?
                                            <div className="d-flex">
                                                <FontAwesomeIcon
                                                    icon={faPhoneAlt}
                                                    className={classes.icon}
                                                />
                                                <div>
                                                    <h4>{company['Phone']}</h4>
                                                    <p>{phone}</p>
                                                </div>
                                            </div> : <></>
                                    }
                                    {
                                        email ?
                                            <div className="d-flex">
                                                <FontAwesomeIcon
                                                    icon={faEnvelope}
                                                    className={classes.icon}
                                                />
                                                <div>
                                                    <h4>{company['Email']}</h4>
                                                    <p>{email}</p>
                                                </div>
                                            </div> : <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
                isFilled = idx + 1
            }

            let cnt
            if (this.state.column === 5) {
                cnt = 4
            } else if (this.state.column === 4) {
                cnt = 3
            } else if (this.state.column === 3) {
                cnt = 2
            } else if (this.state.column === 2) {
                cnt = 1
            }

            if (content !== null && !isOnlyOne && Number.isInteger(parseInt(this.state.column === 1 ? idx + 1 : idx - cnt) / this.state.column) && (isFilled <= this.state.activeTab)) {
                contentPreview = content
                isOnlyOne = true
            }

            let activeClass = this.state.activeTab ? (this.state.activeTab === idx + 1 ? 'active' : 'inactive') : ''
            let hidingIdx;
            if (this.state.activeTab) {
                if (window.innerWidth <= 480) {
                    hidingIdx = this.getHidingIdx(1)
                } else if (window.innerWidth <= 768) {
                    hidingIdx = this.getHidingIdx(2)
                } else if (window.innerWidth < 2560) {
                    hidingIdx = this.getHidingIdx(3)
                } else {
                    hidingIdx = this.getHidingIdx(4)
                }
            }

            return (
                <>
                    <CustomModal
                        data={data}
                        idx={idx}
                        onClick={() => this.showingProfile(idx + 1, data[idx].id)}
                        onStyle={activeClass}
                        hidingIdx={hidingIdx}
                    />
                    {contentPreview}
                </>
            )
        })
        return returnArr
    }

    toogleCompany = (index, callback) => {
        this.setState({
            activeCompany: index,
            activeTab: null,
            activeTabId: null
        }, callback)
    }

    getHidingIdx(column) {
        const row = Math.ceil(this.state.activeTab / column);
        const upper = (row * column) - 1;
        return [upper - (column - 1), upper];
    }

    getNavContent(contentSection) {
        return contentSection.filter(item => {
            return item.index !== 1 && item.category === "Article"
        })
    }

    render() {
        let cardsBOD, link, filters, companyFilter = null;
        let isBorder = classes.customBorderDefault
        if (this.state.isLoaded) {
            let count = -1
            let art
            const navContent = this.getNavContent(this.state.contentSection)
            this.state.contentSection.forEach((item, i) => {
                count = count + 1
                if (this.state.activeCompany === i) {
                    const arts = item.sections.widget_contents
                    if (arts && arts.length > 0) {
                        art = arts
                    } else {
                        art = []
                    }
                }
                switch (i) {
                    case 0:
                        filters = item.sections.widget_contents.map((s, i) => {
                            let key = "tab" + i
                            let active = parseInt(this.state.activeCompany)
                            // let active = (this.state.activeCompany - 1) === i ? classes.filterActive : ''
                            let activeTab = (active - 1) === i ? (active % 2 === 0 ? classes.isActiveB : classes.isActiveO) : ""
                            let isActive = (active - 1) === i ? true : false
                            let url = s.assets[0] ? s.assets[0].file_small : require("./assets/default-image.svg")

                            let content
                            if (window.innerWidth > 480) {
                                let boxs = i % 2 === 0 ? 'B' : 'O'
                                content = <Navbar
                                    idx={i}
                                    box={boxs}
                                    isActive={isActive}
                                    active={activeTab}
                                    onClick={{
                                        toogleCompany: this.toogleCompany,
                                        showingProfile: this.showingProfile
                                    }}
                                    img={url}
                                    key={key}
                                    title={s.article_language.title}
                                    contents={navContent[i]?.sections?.widget_contents}
                                    activeTabId={this.state.activeTabId}
                                />
                            } else {
                                let boxs = i % 2 === 0 ? classes.boxsB : classes.boxsO
                                content = <div className={[boxs, activeTab].join(" ")}>
                                    <div className={[classes.boxSlider].join(" ")} onClick={() => this.toogleCompany(i + 1)}>
                                        <img className={classes.imgIcon} src={url} alt=''></img>
                                        <span id={key}></span>
                                    </div>
                                </div>
                            }
                            return (
                                <>
                                    {content}
                                </>
                            )
                        })
                        break;
                    case 7:
                        link = item.sections.widget_contents.map(s => {
                            return (
                                <div className={classes.navBack}>
                                    <Link className={classes.customLink} to={item.sections.url ? item.sections.url : '/about-us'}>
                                        <div className={'d-flex'}>
                                            <div className={'d-flex'}>
                                                <i className={[classes.arrow, classes.left, classes.customMargin].join(" ")}></i>
                                            </div>
                                            <span className={classes.textBack}>{s.content}</span>
                                        </div>
                                    </Link>
                                </div>
                            )
                        })

                        isBorder = classes.customBorder
                        break;

                    default:
                        break;
                }

            });


            let newArray
            if (art && art.length !== 0) {
                const div = art.length / this.state.column
                let res = div.toString().substr(div.toString().lastIndexOf(".") + 1);

                let dummyLength
                if (this.state.column === 4) {
                    switch (res) {
                        case "25":
                            dummyLength = 3
                            break;
                        case "5":
                            dummyLength = 2
                            break;
                        case "75":
                            dummyLength = 1
                            break;
                        default:
                            break;
                    }
                } else if (this.state.column === 3) {
                    if (res.toString().includes("33")) {
                        dummyLength = 2

                    } else if (res.toString().includes("66")) {
                        dummyLength = 1
                    }
                } else if (this.state.column === 2) {
                    if (res === "5") {
                        dummyLength = 1
                    }
                } else if (this.state.column === 1) {
                    dummyLength = 0
                }

                let arrDummy = []
                if (!Number.isInteger(art.length / this.state.column)) {
                    for (let index = 0; index < dummyLength; index++) {
                        arrDummy.push({
                            assets: [],
                            categories: "",
                            article_language: {
                                content: "",
                                title: ""
                            },
                            date: "",
                            id: "",
                            name: "",
                        })
                    }

                    newArray = art.concat(arrDummy)
                    cardsBOD = this.getCards(newArray)
                } else {
                    cardsBOD = this.getCards(art)

                }
            }

            companyFilter = filters
            // if (window.innerWidth < 480) {
            //     companyFilter = <Swiper className="subs-m-slider">{filters}</Swiper>
            // } else {
            //     companyFilter = filters
            // }
        }

        return (
            <div className={[classes.posRelative, isBorder].join(' ')}>
                <div id="stickyNavbar" className={["w-100", classes.bgGrey, classes.stickyBar].join(' ')}>
                    <div className={[classes.customContainer, classes.topContainer].join(' ')}>
                        {companyFilter}
                    </div>
                </div>
                <div className={['container', classes.customContainer].join(' ')}>
                    <div className={classes.flexContainer}>
                        {cardsBOD}
                    </div>
                    {link}
                </div>
            </div>
        );
    }
}

export default Sub_Section2;
