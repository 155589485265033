import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import dateFormat from "dateformat"

import UserContext from '../../Context'

import 'react-data-table-component-extensions/dist/index.css';
import './Table.css'
import classes from './Table.module.css'

export default class Table extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            lang: localStorage.getItem("language")
        };
    }

    formatDate = (date, lang) => {
        return lang === "Vietnamese" ? dateFormat(date, "dd-mm-yyyy") : dateFormat(date, "mm-dd-yyyy")
    }

    render() {
        const contextValue = this.context;
        let columns, customPagination, customNoData, linkUrl
        let rows, ofText, allText, titleJ, pos, company, location, date, type, search, noData
        if (contextValue.isSectionLoaded) {
            if (contextValue.contentSection[0]) {
                linkUrl = contextValue.contentSection[0].sections.url !== "" ? contextValue.contentSection[0].sections.url : '/career/career-list/career-details-page-new'
            }
            if (contextValue.contentSection[6]) {
                contextValue.contentSection[6].sections.widget_contents.forEach((c, i) => {
                    switch (i) {
                        case 0:
                            rows = c.content
                            break;
                        case 1:
                            ofText = c.content
                            break;
                        case 2:
                            allText = c.content
                            break;
                        case 3:
                            titleJ = c.content
                            break;
                        case 4:
                            company = c.content
                            break;
                        case 5:
                            location = c.content
                            break;
                        case 6:
                            date = c.content
                            break;
                        case 7:
                            search = c.content
                            break;
                        case 8:
                            pos = c.content
                            break;
                        case 9:
                            type = c.content
                            break;
                        case 10:
                            noData = c.content
                            break;
                        default:
                            break;
                    }
                })

                columns = [
                    {
                        name: <div className={classes.tableColumn}>
                            <div className={classes.contentColumn}>
                                <span>{titleJ}</span>
                            </div>
                            {/* <div id="c-fil-pos" className={classes.inputColumn}>
                                <input type="text" placeholder={pos} />
                            </div> */}
                        </div>,
                        selector: 'title',
                        sortable: true,
                        width: '20vw',
                        cell: (row, i) => {
                            return (
                                <Link to={`${linkUrl}/${row.url_title !== "" ? row.url_title : "-"}/${row.id}`} className={classes.customLink}>
                                    <p>{row.title}</p>
                                </Link>
                            )
                        }
                    },
                    {
                        name: <div className={classes.tableColumn}>
                            <div className={classes.contentColumn}>
                                <span>{pos}</span>
                            </div>
                        </div>,
                        selector: 'position_name',
                        sortable: true,
                        cell: (row, i) => {
                            return (
                                <span>{row.position_name}</span>
                            )
                        }

                    },
                    {
                        name: <div className={classes.tableColumn}>
                            <div className={classes.contentColumn}>
                                <span>{company}</span>
                            </div>
                        </div>,
                        selector: 'company_name',
                        sortable: true,
                        cell: (row) => {
                            return (
                                <span>{row.company_name}</span>
                            )
                        }

                    },
                    {
                        name: <div className={classes.tableColumn}>
                            <div className={classes.contentColumn}>
                                <span>{location}</span>
                            </div>
                        </div>,
                        selector: 'location',
                        minWidth: '18vw',
                        sortable: true,
                        cell: (row) => {
                            return (
                                <span>{row.location}</span>
                            )
                        }

                    },
                    {
                        name: <div className={classes.tableColumn}>
                            <div className={classes.contentColumn}>
                                <span>{date}</span>
                            </div>
                        </div>,
                        selector: 'due_date',
                        sortable: true,
                        width: '10vw',
                        cell: (row) => {
                            return (
                                <span>{this.formatDate(row.due_date, this.state.lang)}</span>
                            )
                        }
                    },
                    {
                        name: <div className={classes.tableColumn}>
                            <div className={classes.contentColumn}>
                                <span>{type}</span>
                            </div>
                        </div>,
                        selector: 'job_type',
                        sortable: true,
                        width: '10vw',
                        cell: (row, i) => {
                            return (
                                <span>{row.job_type}</span>
                            )
                        }

                    }
                ];

                customPagination = {
                    rowsPerPageText: rows,
                    rangeSeparatorText: ofText,
                    noRowsPerPage: true,
                    selectAllRowsItem: false,
                    selectAllRowsItemText: allText
                }

                customNoData = <div className={classes.contentNoData}>
                    <span>{noData}</span>
                </div>
            }
        }

        return (
            <div id="c_dt_tbl" >
                <DataTableExtensions
                    columns={columns}
                    data={this.props.data}
                    export={false}
                    print={false}
                    exportHeaders={true}
                    filterPlaceholder={search}
                >
                    <DataTable
                        className={["table table-bordered dataTable", classes.dataTable].join(' ')}
                        title="Job List"
                        noHeader
                        highlightOnHover={true}
                        striped={true}
                        defaultSortField="due_date"
                        defaultSortAsc={false}
                        pagination={true}
                        paginationComponentOptions={customPagination}
                        paginationPerPage={20}
                        responsive={true}
                        noDataComponent={customNoData}
                        customStyles={{
                            cells: {
                                style: {
                                    paddingTop: '1rem',
                                    paddingBottom: '1rem'
                                }
                            }
                        }}
                    />
                </DataTableExtensions>
            </div>
        )
    }
}
