import React, { Component } from "react";
import Slider from "react-slick";
import './Slider.scss'

export default class VerticalSwipeToSlide extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const settings = {
            slidesToShow: 2,
            infinite: true,
            slidesToScroll: 1,
            vertical: true,
            autoplay: true,
            // verticalSwiping: true,
            // swipeToSlide: true,
        };
        return (
            <div id="IN_Section2_Slider">
                <Slider {...this.props} {...settings}>
                    {this.props.children}
                </Slider>
            </div>
        );
    }
}