import React, { Component } from "react"
import classes from "./../Section1.module.css"
import Api from "./../Api"
import ReactHtmlParser from "react-html-parser"
import SmallSizeVideo from "./../assets/solar-panels-and-wind-generator-HYH74FQ1.mp4"

export default class Desktop extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contentSection: [],
      isLoaded: false,
      isApiLoaded: true,
    }
    this.getSectionDetail = this.getSectionDetail.bind(this)
  }

  componentDidMount() {
    this.getSectionDetail()
  }

  getSectionDetail() {
    const apiData = new Api()
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data
          const widget = []

          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            })
          })

          sections.page = widget
          this.setState({
            contentSection: widget,
            isLoaded: true,
          })
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          })
        })
    })
  }
  render() {
    const { contentSection } = this.state
    let imgcont,
      titlecont,
      imgback,
      datacont

    if (this.state.isLoaded) {
      let count = 0
      contentSection.forEach((item) => {
        count = count + 1
        if (count === 1 && item.index === 1 && item.category === "Article") {
          item.sections.widget_contents.forEach((ele) => {
            titlecont = (
              <div className={["container", classes.contentInBox].join(" ")}>
                <span className={classes.textInside}>
                  <h1>{ele.article_language.title}</h1>
                </span>
                <div className={[classes.p_0_underline]}></div>
              </div>
            )
            imgcont = (
              <div className={classes.boxImage}>
                <video className={classes.imgContent} autoPlay loop muted>
                  <source src={SmallSizeVideo} type='video/mp4' />
                </video>
              </div>
            )
            datacont = (
              <div className={classes.boxTextBottom}>
                <span>{ReactHtmlParser(ele.article_language.content)}</span>
              </div>
            )
          })
        }

        if (count === 2 && item.index === 2 && item.category === "Media") {
          item.sections.widget_contents.forEach((ele) => {
            imgback = (
              <div className={classes.overlayBackground}>
                <img className={classes.imgBackground} src={ele.file_small} alt={ele.description} />
              </div>
            )
          })
        }
      })
    } else if (this.state.isApiLoaded) {

    }

    return (
      <div className={classes.Section1}>
        {imgback}
        {imgcont}
        {titlecont}

        <div className={["container", classes.boxC, classes.customContainer].join(" ")}>{datacont}</div>
      </div>
    )
  }
}
