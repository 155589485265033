import React from "react";

import classes from "./Card.module.css";

function Card({ first, url, text, imgUrl, idx, onClick }) {
  let isNull;
  if (url === "") {
    isNull = classes.isNull;
  } else {
    isNull = "";
  }
  const bottomText = (
    <div
      className={classes.textContainer}
      onClick={onClick}
    >
      <div className={classes.textContent}>
        <div className={classes.title_content1}>
          <p>{text}</p>
        </div>
      </div>
    </div>
  );

  const marginLeft = idx === 0 ? classes.marginLeft : "";

  return (
    <div
      className={[
        classes.contentWrap,
        classes.fadeInBottomm,
        marginLeft,
        first,
        isNull,
      ].join(" ")}
    >
      <div className={classes.boxContent1}>
        <div className={classes.box_content_image}>
          <img
            className={classes.box_image}
            src={imgUrl ? imgUrl : ""}
            alt={text}
          />
        </div>
        {bottomText}
      </div>
    </div>
  );
}

export default Card;
