import React, { useState } from "react";

import { isMobile, isTablet } from "react-device-detect";

import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { useMediaQuery } from "react-responsive";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import classes from "./GlobalNetwork.module.css";

export default function Box(props) {
  const isM = useMediaQuery({
    query: "(max-device-width: 424px)",
  });

  const settings = {
    className: "",
    infinite: false,
    centerPadding: "",
    variableWidth: true,
    slidesToShow: 1,
    swipeToSlide: true,
    dots: true,
  };
  let blackBox = false;

  const [focus, setFocus] = useState(false);
  let content = props.content.map((item, i) => {
    let countdown = "";
    let highlight = "";
    let description = "";
    let lightClass = "";
    let dur = 15;
    if (i < 4) {
      item.sections.widget_contents.forEach((element, index) => {
        switch (index) {
          case 0:
            countdown = element.content ? element.content : "";
            if (countdown >= 10) {
              dur = 12;
            }
            if (countdown >= 100) {
              dur = 10;
            }
            if (countdown >= 1000) {
              dur = 8;
            }
            if (countdown >= 10000) {
              dur = 6;
            }
            break;
          case 1:
            highlight = element.content ? element.content : "";
            break;
          case 2:
            description = element.content ? element.content : "";
            break;
          default:
        }
      });
    } else {
      // titleCountry = <p>{item.sections.name ? item.sections.name : ''} </p>
      // countryList = item.sections.widget_contents.map((s, i) => {
      //     let boxes = null
      //     arr.push(s.content)
      //     if (Number.isInteger(parseInt(i + 1) / 6)) {
      //         let items = arr.map(x => {
      //             return (
      //                 <p className={classes.country}>{x}</p>
      //             )
      //         })
      //         boxes = <div className={classes.boxCountry}>
      //             {items}
      //         </div>
      //         arr = []
      //     }
      //     return (
      //         <>
      //             {boxes}
      //         </>
      //     )
      // })
    }

    if (blackBox) {
      lightClass = classes.boxLight;
    } else {
      lightClass = classes.boxDark;
    }

    blackBox = !blackBox;

    let defaultC =
      countdown !== "" ? (
        <div className={lightClass}>
          <div
            className={["align-self-center", "m-auto", classes.boxText].join(
              " "
            )}
          >
            <CountUp
              className={classes.num}
              start={focus ? 0 : null}
              end={countdown}
              duration={dur}
              separator="."
            >
              {({ countUpRef }) => (
                <VisibilitySensor
                  onChange={(isVisible) => {
                    if (isVisible) {
                      setFocus(true);
                    }
                  }}
                >
                  <span className={classes.num} ref={countUpRef} />
                </VisibilitySensor>
              )}
            </CountUp>
            <span className={classes.text}>{highlight}</span>
            <br />
            <span className={classes.content}>{description}</span>
          </div>
        </div>
      ) : (
        <></>
      );

    let mobileC = (
      <div className={classes.box}>
        <div className={classes.contentBox}>
          <CountUp
            className={classes.contentSlider}
            end={countdown}
            duration={dur}
            separator="."
          />
          <span className={classes.textSlider}>{highlight}</span>
          <br />
          <span className={classes.contentSlider}>{description}</span>
        </div>
      </div>
    );

    let visualContent;
    if (isTablet) {
      visualContent = defaultC;
    } else if (isMobile || isM) {
      visualContent = mobileC;
    } else {
      visualContent = defaultC;
    }

    return <>{visualContent}</>;
  });

  let contents;
  if (isTablet) {
    contents = content;
  } else if (isMobile) {
    // contents = <Slider {...settings}>{content.slice(0, 3)}</Slider>;
    contents = content.slice(0, 3)
  } else {
    contents = content;
  }

  return (
    <>
      <div className={["container", classes.containerCountry].join(" ")}></div>
      <div className={[classes.boxContent, "AuSec5"].join(" ")}>{contents}</div>
    </>
  );
}
