import React, { Component, Fragment } from "react";
import classes from "../AD_Section1.module.css";
import { ParallaxProvider } from "react-scroll-parallax";
import Slider from "react-slick";
import ReactHtmlParser from "react-html-parser";
import Api from "../Api";
import AwardsBox2 from "../AwardsBox2";
import AwardsBox from "../AwardsBox";
import dateFormat from "dateformat";

class Desktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idSection: this.props.id,
      sections: null,
      article: null,
      isLoaded: false,
      isApiLoaded: false,
      active_img: 0,
      urlBack: null,
    };
    this.toggleNext = this.toggleNext.bind(this);
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }
  toggleNext() {
    this.setState((id) => {
      if (id === id + 1) {
        return { disabledNext: false };
      } else {
        return { i: id + 1 };
      }
    });
  }
  componentDidMount() {
    let paramId = this.props.id;
    this.getSectionDetail(paramId);
  }
  changeSlideBar(key) {
    this.setState({ active_img: key });
  }
  getSectionDetail(paramId) {
    const apiData = new Api();
    this.setState(
      {
        article: [],
        sections: [],
      },
      () => {
        apiData.getSectionDetail().then((result) => {
          const sections = result.data;
          const widget = [];
          let id = sections.id;

          let url = sections.widgets[0].widget_contents[0]
            ? sections.widgets[0].widget_contents[0].content
            : "";

          result.data.widgets.forEach((item) => {
            widget.push(item);
          });

          this.setState({
            idSection: id,
            sections: widget,
            urlBack: url,
          });
        });

        apiData
          .getArticleDetailByArticleId(paramId)
          .then((result) => {
            const article = result.data;
            const assets = [];
            article.article_assets.forEach((item) => {
              assets.push({
                assets: item.file_big || item.asset_file,
                assets_small: item.file_small,
                desc: item.description,
              });
            });

            const allcontent = article.article_languages;
            let content = allcontent.content;
            let title = allcontent.title;
            let category = article.category_names;
            let date = article.date;

            const widget = [];

            widget.push({
              content: content,
              title: title,
              category: category,
              date: date,
              assets: assets,
            });

            article.page = widget;
            this.setState({
              article: widget,
              isLoaded: true,
            });
          })
          .catch((error) => {
            this.setState({ isApiLoaded: true });
          });
      }
    );
  }

  render() {
    const settings = {
      className: "",
      infinite: false,
      centerPadding: "0",
      variableWidth: true,
      slidesToShow: 2,
      autoplay: false,
      swipeToSlide: true,
    };

    const { article } = this.state;

    let articleContent,
      top2,
      top,
      detailImg,
      imgBottom,
      nextBox = null;

    if (this.state.isLoaded) {
      top = <AwardsBox id={this.state.idSection} />;
      top2 = <AwardsBox2 id={this.state.idSection} />;

      article.forEach((item) => {
        let strDate = item.date;
        let dateFull = dateFormat(strDate, "dd-mm-yyyy");
        if (item.assets.length === 1) {
          item.assets.forEach((ele, i) => {
            if (i === this.state.active_img) {
              detailImg = (
                <div className={classes._boxmain1}>
                  <img
                    className={classes._img1}
                    src={item.assets[i].assets}
                    alt=" "
                  />
                  <div className={["container", classes.pad].join(" ")}>
                    <p className={classes.txt2}>{item.assets[i].desc}</p>
                  </div>
                </div>
              );
            }
            nextBox = <></>;

            // (
            //   <div
            //     className={classes._btnBtm}
            //     onClick={this.toggleNext}
            //     disabled={this.state.disabledNext}
            //   >
            //     <div className={classes._next}>
            //       <p className={classes._marg}>Next Page {">"}</p>
            //     </div>
            //   </div>
            // );
          });
          imgBottom = <div></div>;
        } else if (item.assets.length > 1) {
          let img_slide_box = item.assets.map((ele, i) => {
            if (i === this.state.active_img) {
              detailImg = (
                <div className={classes._boxmain}>
                  <img className={classes._img} src={ele.assets} alt=" " />
                  <div className={["container", classes.pad].join(" ")}>
                    <p className={classes.txt2}>{ele.desc}</p>
                  </div>
                </div>
              );
            }
            nextBox = <></>;

            // (
            //   <div className={classes._btnBtm} onClick={this.toggleNext}>
            //     <div className={classes._next}>
            //       <p className={classes._marg}>Next Page {">"}</p>
            //     </div>
            //   </div>
            // );

            return (
              <div className={classes._box2}>
                <img
                  onClick={() => this.changeSlideBar(i)}
                  className={classes._img_slide}
                  src={ele.assets_small}
                  alt=" "
                />
              </div>
            );
          });

          imgBottom = (
            <div className={classes._box3}>
              <div className={["container", classes.padd2].join(" ")}>
                <Slider {...settings}>{img_slide_box}</Slider>
              </div>
            </div>
          );
        }

        articleContent = (
          <div className={classes.box}>
            <div className={classes.boxContent}>
              <div className={classes.judul}>{item.title}</div>

              <div className={classes.box_scroll}>
                <div className={classes.scroll_content}>
                  <div className={classes.tgl}>{dateFull}</div>
                  <div className={classes.isi}>
                    {ReactHtmlParser(item.content)}
                  </div>
                </div>
              </div>
              <div className="d-flex">{top}</div>
            </div>
            <div className={classes.boxContent2}>
              <div className="d-flex-flex column">
                {top2}
                <div className={classes.box_scroll}>
                  <div className={classes.boxImage}>
                    {detailImg}
                    {imgBottom}
                    {nextBox}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else if (this.state.isApiLoaded) {

    }
    return (
      <div className="pages-new">
        <div className={classes.wrapper}>
          <div className={classes.main}>
            <ParallaxProvider>
              <Fragment>{articleContent}</Fragment>
            </ParallaxProvider>
          </div>
        </div>
      </div>
    );
  }
}

export default Desktop;
