import React, { Component } from "react";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import UserContext from "../../Context";

import Api from "../../../Api";
import classes from "./CO.module.css";
export default class CO extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      id: false
    };
  }

  componentDidMount() {
    const contextValue = this.context;

    this.setState({
      id: contextValue.idApplicant,
    });
  }

  render() {
    let careerList,
      thisUrl,
      readMore

    const sett = {
      className: "classSlider",
      infinite: false,
      centerPadding: "20px",
      variableWidth: true,
      slidesToShow: 1,
      swipeToSlide: true,
    };

    const contextValue = this.context;
    if (contextValue.isSectionLoaded){
      if (contextValue.contentSection[2]){
        contextValue.contentSection[2].sections.widget_contents.forEach((c,i) => {
          switch (i) {
            case 2:
              readMore = c.content
              break;
            default:
              break;
          }
        })
      }
    }

    if (contextValue.isJobLoaded && contextValue.isFilterLoaded) {
      const latestJobs = contextValue.jobList.slice(0, 10);

      careerList = latestJobs.map((d, i) => {
        thisUrl = d.url ?
          <Link to={d.url} className={classes.customLink}>
            <div className={classes.outerCircle2}>
              <div className={classes.innerCircle2}></div>
            </div>
            <p className={classes.readMore}>{readMore}</p>
          </Link> :
          <div className={classes.customLink}>
            <div className={classes.outerCircle2}>
              <div className={classes.innerCircle2}></div>
            </div>
            <p className={classes.readMore}>{readMore}</p>
          </div>

        let boxClass = i === 0 ? [classes.box, classes.boxFirst].join(" ") : classes.box;

        let imgContent
        if (d.assets === require("../assets/default-image.svg")) {
          imgContent = <img className={classes.contentDefaultImg} src={d.assets} alt="" />;
        } else {
          imgContent = <img className={classes.contentImg} src={d.assets} alt="" />;
        }
        return (
          <div className={boxClass}>
            {imgContent}
            <div className={classes.boxContent}>
              <div className={classes.cardCustom}>
                <div className={classes.cardContent}>
                  <p className={classes.posContent}>{d.title ? d.title : ''}</p>
                  {thisUrl}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }

    let viewOnchange = null;
    viewOnchange = 
      <div className={classes.bottomContent}>
        <Slider {...sett}>{careerList}</Slider>
      </div>
    
    return (
      <div className={classes.contentSlider}>{viewOnchange}</div>
    );
  }
}
