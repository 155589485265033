import React from 'react'

import { isMobile, isTablet } from "react-device-detect"
import Slider from "react-slick";


import classes from '../AO_Section1.module.scss'

export default function YearFilter(props) {
    const settings = {
        infinite: false,
        variableWidth: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        swipe: true
    };

    let highlightYear = props.article.map((item, i) => {
        // console.log(`${props.active} ++++ ${i} +++ ${props.active.toString() === i.toString()}`)
        return (
            <div className={props.active.toString() === i.toString() ? classes.yearActive : classes.year}>
                <p onClick={props.onClick} data-id={i}>{item}</p>
            </div>
        );
    });

    let content
    if (isMobile && !isTablet) {
        content = <Slider {...settings}>{highlightYear}</Slider>
    } else {
        settings.slidesToShow = 6
        settings.slidesToScroll = 1
        settings.swipeToSlide = true
        content = highlightYear.length < 6 ? highlightYear :  (
            <div style={{width: "65%"}}>
                <Slider {...settings}>{highlightYear}</Slider>
            </div>
            )
    }

    return (
        <>
            {content}
        </>
    )
}
