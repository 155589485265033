import React, { Component } from "react";
import { Modal } from "react-bootstrap";

import UserContext from "../../../../Context";

import Api from "../../../../../Api";

import classes from "./Modal.module.css";

export default class ModalName extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      startDate: "",
      year: "",
      month: "",
      day: "",
      selectedYear: "",
      selectedMonth: "",
      selectedDay: "",
      isLoaded: false,
      isDate: false,
      data: [],
    };
    this.yearChange = this.yearChange.bind(this);
    this.monthChange = this.monthChange.bind(this);
    this.dayChange = this.dayChange.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
  }

  componentDidMount() {
    const contextValue = this.context;
    let year = [];
    let month = [];
    var d = new Date();
    var n = d.getFullYear();
    for (let index = n - 65; index <= n - 15; index++) {
      year.push(index);
    }
    for (let index = 1; index <= 12; index++) {
      month.push(index);
    }

    this.setState({
      isLoggedIn: contextValue.isLoggedIn,
      id: contextValue.idApplicant,
      year: year,
      month: month,
      isLoaded: true,
      data: contextValue.contentSection[9].sections.widget_contents,
    });
  }

  isValidate() {
    let y = document.getElementById("year-select");
    let m = document.getElementById("month-select");
    let d = document.getElementById("day-select");

    if (
      y.options[y.selectedIndex].value !== "x" &&
      m.options[m.selectedIndex].value !== "x" &&
      d.options[d.selectedIndex].value !== "x"
    ) {
      return true;
    } else {
      this.setState({
        isDate: true,
      });
      return false;
    }
  }

  updateProfile() {
    const update = new Api();
    const contextValue = this.context;

    if (this.isValidate()) {
      const year = this.state.selectedYear;
      const month = this.state.selectedMonth;
      const day = this.state.selectedDay;

      const updateData = {
        birthdate: `${year}-${month}-${day}`,
      };

      update
        .updateApplicant(this.state.id, updateData, "birthdate")
        .then((res) => {
          this.setState({
            isDate: false,
          });
          this.props.close();
          contextValue.getNewDataApplicant();
          contextValue.showNotification(
            "Your birthday has been successfully updated."
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  yearChange(e) {
    if (e.target.value !== "x") {
      document.getElementById("month-select").disabled = false;
      this.setState({
        selectedYear: e.target.value,
      });
    } else {
      document.getElementById("month-select").disabled = true;
    }
  }

  monthChange(e) {
    if (e.target.value !== "x") {
      let days = [];
      document.getElementById("day-select").disabled = false;

      let day = this.getDaysInMonth(this.state.selectedYear, e.target.value);
      for (let index = 1; index <= day; index++) {
        days.push(index);
      }
      this.setState({
        selectedMonth: e.target.value,
        day: days,
      });
    } else {
      document.getElementById("day-select").disabled = true;
    }
  }

  dayChange(e) {
    if (e.target.value !== "x") {
      this.setState({
        selectedDay: e.target.value,
      });
    } else {
    }
  }

  getDaysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  render() {
    let title, year, month, day, cancel, save;
    this.state.data.forEach((x, i) => {
      switch (i) {
        case 3:
          title = x.content;
          break;
        case 4:
          year = x.content;
          break;
        case 5:
          month = x.content;
          break;
        case 6:
          day = x.content;
          break;
        case 12:
          cancel = x.content;
          break;
        case 13:
          save = x.content;
          break;
        default:
      }
    });
    let contentYear, contentMonth, contentDay;
    if (this.state.isLoaded) {
      contentYear = this.state.year.map((y) => {
        return <option value={y}>{y}</option>;
      });

      contentMonth = this.state.month.map((m) => {
        return <option value={m}>{m}</option>;
      });

      contentDay = this.state.day
        ? this.state.day.map((m) => {
            return <option value={m}>{m}</option>;
          })
        : "";
    }

    let isError = this.state.isDate ? classes.errorVisible : "";
    return (
      <Modal
        size={"lg"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.show}
        onHide={this.props.close}
      >
        <Modal.Header
          className={classes.modalHeader}
          onClick={this.props.close}
          closeButton
        >
          <span className={classes.modalTitle}>{title}</span>
        </Modal.Header>
        <UserContext.Consumer>
          {(context) => (
            <div className={classes.modalContent}>
              <div className={classes.dateContent}>
                <div className={classes.boxContent}>
                  <span className={classes.titleContent}>{year}</span>

                  <select
                    id="year-select"
                    className={classes.modalSelect}
                    onChange={this.yearChange}
                  >
                    <option value={"x"}>-- select year --</option>
                    {contentYear}
                  </select>
                </div>
                <div className={classes.boxContent}>
                  <span className={classes.titleContent}>{month}</span>
                  <select
                    id="month-select"
                    className={classes.modalSelect}
                    disabled
                    onChange={this.monthChange}
                  >
                    <option value={"x"}>-- select month --</option>
                    {contentMonth}
                  </select>
                </div>
                <div className={classes.boxContent}>
                  <span className={classes.titleContent}>{day}</span>
                  <select
                    id="day-select"
                    className={classes.modalSelect}
                    disabled
                    onChange={this.dayChange}
                  >
                    <option value={"x"}>-- select day --</option>
                    {contentDay}
                  </select>
                </div>
              </div>
              <span className={[classes.contentError, isError].join(" ")}>
                All field is required
              </span>

              <div className={classes.modalAction}>
                <div className={classes.cancel}>
                  <span>{cancel}</span>
                </div>
                <div
                  className={classes.save}
                  onClick={() => {
                    this.updateProfile();
                    // this.props.close()
                    // context.getNewDataApplicant()
                  }}
                >
                  <span>{save}</span>
                </div>
              </div>
            </div>
          )}
        </UserContext.Consumer>
      </Modal>
    );
  }
}
