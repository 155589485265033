import React, { Component } from 'react'
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Api from '../Api'
import classes from './DesktopSec3.module.css'

import ReadMoreButton from '../components/Button/Button'
import NavBack from '../components/NavBack/NavBack';

export default class Desktop extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contentArticle: {},
      contentSection: [],
      backUrl: '/',
      readMoreText: '',
      readMoreUrl: '',
      isLoaded: false,
      isApiLoaded: false,
      isArticleLoaded: {}
    }

    this.getSectionDetail = this.getSectionDetail.bind(this);
    this.getArticleDetailPagination = this.getArticleDetailPagination.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail = () => {
    const apiData = new Api();
    apiData
      .getSectionDetail()
      .then((result) => {
        const sections = result.data;
        const widget = [];
        sections.widgets.forEach((item) => {
          widget.push({
            id: item.id,
            index: item.index,
            category: item.category,
            name: item.name,
            sections: item,
          });
        });

        this.setState({
          backUrl: sections.widgets[2].url,
          backText: sections.widgets[2].widget_contents[0].content,
          readMoreUrl: sections.widgets[3].url,
          readMoreText: sections.widgets[3].widget_contents[0].content,
          contentSection: widget,
          isLoaded: true,
        });
      })
      .catch((error) => {
        this.setState({
          isApiLoaded: true,
        });
        window.location.assign(
          `/error/${error.response.status ? error.response.status : 404}`
        );
      });
  }

  getArticleDetailPagination(param, size, page) {
    const apiData = new Api();
    apiData.getArticleDetailPagination(param, size, page)
      .then(result => {
        const articles = result.data
        const art = []
        articles.results.forEach((a) => {
          const assets = []
          a.article_assets.forEach((ass, i) => {
            if (i === 0) {
              assets.push({
                asset: ass.file_medium
              })
            }
          })

          art.push({
            id: a.id,
            category: a.category_name,
            name: a.name,
            author_name: a.author_name,
            author_avatar: a.author_avatar,
            title: a.article_languages.title,
            summary: a.article_languages.summary,
            content: a.article_languages.content,
            assets: assets,
            date: a.date,
            slug_title: a.article_languages.slug_title
          })
        })

        let isArticleLoaded = { ...this.state.isArticleLoaded }
        isArticleLoaded[param] = true;

        let contentArticle = { ...this.state.contentArticle }
        contentArticle[param] = art;

        this.setState({
          contentArticle: contentArticle,
          isArticleLoaded: isArticleLoaded
        })

      })
      .catch(error => {
        console.log(error);
        this.setState({
          isApiLoaded: true
        })
      });
  }

  getExt = (str) => {
    let chunkedStr = str.split(".")
    return chunkedStr[chunkedStr.length - 1];
  }

  render() {
    const { contentSection } = this.state

    let title, article, cards, viewAll;
    
    if (this.state.isLoaded) {
      contentSection.forEach(el => {
        if (el.index === 1 && el.category === "Text") {
          title = (
            <h2 className={classes.title}>
              {el.sections.widget_contents[0].content}
            </h2>
          )
        }
        else if (el.index === 2 && el.category === "Article") {
          const url = el.sections.url;
          const id = el.id
          const articles = this.state.contentArticle[id];

          if (!this.state.isArticleLoaded[id]) {
            this.getArticleDetailPagination(id, 5)
          }

          if (articles) {
            cards = articles.map(e => {
              const imgSrc = e.assets[0].asset;
              const slug_title = e.slug_title ? e.slug_title : "null"
              return (
                <div className={classes.card}>
                  <div className={classes.figure}>
                    <img className={classes.imgLeft} src={imgSrc ? imgSrc : require('../assets/default-image.svg')} alt=""></img>
                  </div>
                  <div className={classes.content}>
                    <div className={classes.cardTitle}>{e.title}</div>
                    <div className={classes.cardContent}>{e.summary}</div>
                    <ReadMoreButton url={this.state.readMoreUrl + "/" + slug_title + "/" + e.id} >
                      {this.state.readMoreText}
                    </ReadMoreButton>
                  </div>
                </div>
              )
            })
          }
        }
        else if (el.index === 5 && el.category === "Text") {
          viewAll = {}
          viewAll.url = el.sections.url
          viewAll.text = el.sections.widget_contents[0].content
        }
      })
    }

    return (
      <div className={classes.section}>
        <div className={["container", classes.customContainer].join(" ")}>
          {title}
          {cards}
          <ReadMoreButton
            className="pt-0 mb-4"
            url={viewAll?.url}
          >
            {viewAll?.text}
          </ReadMoreButton>
        </div>
        <div className={classes.customContainer}>
          <NavBack url={this.state.backUrl}>{this.state.backText}</NavBack>
        </div>
      </div>
    )
  }
}
