import React, { Component } from 'react'
import dateFormat from "dateformat"

import UserContext from '../../Context'


import classes from './Table.module.css'

export default class Expandable extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            lang: localStorage.getItem("language")
        };
    }

    formatDate = (date, lang) => {
        return lang === "Vietnamese" ? dateFormat(date, "dd-mm-yyyy") : dateFormat(date, "mm-dd-yyyy")
    }

    render() {
        const contextValue = this.context;
        let pos, company, location, date, type
        let contentExpand

        if (this.props.data) {
            if (contextValue.contentSection[6]) {
                contextValue.contentSection[6].sections.widget_contents.forEach((c, i) => {
                    switch (i) {
                        case 4:
                            company = c.content
                            break;
                        case 5:
                            location = c.content
                            break;
                        case 6:
                            date = c.content
                            break;
                        case 8:
                            pos = c.content
                            break;
                        case 9:
                            type = c.content
                            break;
                        
                        default:
                            break;
                    }
                })
            }

            const val = this.props.data
            contentExpand = <div className={classes.contentExpand}>
                <div className={classes.expand}>
                    <div className={classes.firstExp}>
                        <span>{pos}</span>
                    </div>
                    <div className={classes.secondExp}>
                        <span>{val.position_name}</span>
                    </div>
                </div>
                <div className={classes.expand}>
                    <div className={classes.firstExp}>
                        <span>{location}</span>
                    </div>
                    <div className={classes.secondExp}>
                        <span>{val.location}</span>
                    </div>
                </div>
                <div className={classes.expand}>
                    <div className={classes.firstExp}>
                        <span>{date}</span>
                    </div>
                    <div className={classes.secondExp}>
                        <span>{this.formatDate(val.post_date, this.state.lang)}</span>
                    </div>
                </div>
                <div className={classes.expand}>
                    <div className={classes.firstExp}>
                        <span>{type}</span>
                    </div>
                    <div className={classes.secondExp}>
                        <span>{val.job_type}</span>
                    </div>
                </div>
            </div>
        }
        return (
            <>
                {contentExpand}
            </>
        )
    }
}
