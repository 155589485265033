import React, { Component } from "react"
import Api from "./Api"
import NavigationBar from "./Navbar/Navbar"

class HeaderNew extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentLogo: this.props.logoColor,
      menus: [],
      isLoaded: false,
    }
    this.getMenu = this.getMenu.bind(this)
    this.updateColor = this.updateColor.bind(this)
  }

  componentDidMount() {
    this.getMenu()
  }

  getMenu() {
    const apiData = new Api()
    this.setState({ menus: [] }, () => {
      apiData
        .getMenuDetail(3)
        .then((result) => {
          const menu = result.data
          const item = []

          menu.items.forEach((i) => {
            item.push({
              index: i.index,
              id: i.id,
              name: i.title,
              url: i.url,
              children: i.children,
            })
          })
          menu.items = item
          this.setState({
            menus: menu,
            isLoaded: true,
          })
        })
        .catch((error) => {
          console.log(error)
          // window.location.assign(`/error/${error.response.status ? error.response.status : 404}`);
          // window.location.assign(`/error/${error ? (error.response.status ? error.response.status : 404) : 404}`);
        })
    })
  }

  updateColor() {
    this.setState({
      currentLogo: this.props.logoColor,
    })
  }

  render() {
    const { menus } = this.state
    let dataSection, Navbar

    if (this.state.currentLogo !== this.props.logoColor) {
      this.updateColor()
    }

    if (this.state.isLoaded) {
      dataSection = menus
      Navbar = <NavigationBar onPage={this.props.onPage} items={dataSection} logoColor={this.state.currentLogo} />
    } else {
      Navbar = <NavigationBar onPage={this.props.onPage} items={this.state.isLoaded} logoColor={this.state.currentLogo} />
    }
    return <div className='content-header'>{Navbar}</div>
  }
}

export default HeaderNew
