import React, { Component, Fragment } from "react";
import classes from "./Tablet.module.css";
import { ParallaxProvider } from "react-scroll-parallax";
import Slider from "react-slick";
import ReactHtmlParser from "react-html-parser";
import dateFormat from "dateformat";
import { FacebookIcon, LinkedinIcon, FacebookShareButton, LinkedinShareButton } from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
// import { Link } from "react-router-dom";

import ArticleLatest from "./ArticleLatest";
import Option2 from "../Option/Option2/Option2";
import Option3 from "../Option/Option3/Option3";

import Api from "../Api";

import "./Tablet.css";

class TabletNew extends Component {
	constructor(props) {
		super(props);
		this.state = {
			idSection: this.props.id,
			sections: null,
			article: null,
			isLoadedSection: false,
			isLoadedArticle: false,
			isApiLoaded: false,
			active_img: 0,
			contentArticle: [],
			size: 3,
			currentUrl: "",
			id_widget: 0,
			id_catg: 0,
		};
		this.getSectionDetail = this.getSectionDetail.bind(this);
	}

	componentDidMount() {
		this.getSectionDetail(this.props.param.param2);

		let uri = window.location.href;
		let uri_enc = encodeURIComponent(uri);
		let uri_dec = decodeURIComponent(uri_enc);
		this.setState({
			currentUrl: uri_dec,
		});
	}

	getSectionDetail(paramId) {
		const apiData = new Api();
		this.setState(
			{
				article: [],
				sections: [],
			},
			() => {
				apiData.getSectionDetail().then((result) => {
					const sections = result.data;
					const widget = [];
					let id = sections.id;
					result.data.widgets.forEach((item) => {
						widget.push(item);
					});

					this.setState({
						sections: widget,
						idSection: id,
						isLoadedSection: true,
					});
				});

				apiData
					.getArticleDetailByArticleId(paramId)
					.then((result) => {
						const article = result.data;

						let id_widget = article.widgets ? article.widgets[0].id : "";
						let id_catg = article.categories ? article.categories[0].id : "";

						const assets = [];
						article.article_assets.forEach((item) => {
							let desc = item.asset_languages.description;
							assets.push({
								assets: item.file_big || item.asset_file,
								assets_small: item.file_small,
								desc: desc,
							});
						});

						const allcontent = article.article_languages;
						let content = allcontent.content;
						let title = allcontent.title;
						let category = article.category_name;
						let date = article.date;
						let article_type = article.article_type ? article.article_type.id : 1;
						let content_file = allcontent.content_file;
						let content_section = allcontent.content_section;

						const widget = [];

						widget.push({
							article_type: article_type,
							content: content,
							content_file: content_file,
							content_section: content_section,
							title: title,
							category: category,
							date: date,
							assets: assets,
						});

						article.page = widget;
						this.setState({
							article: widget,
							isLoadedArticle: true,
							id_widget: id_widget,
							id_catg: id_catg,
						});
					})
					.catch((error) => {
						this.setState({ isApiLoaded: true });
					});
			}
		);
	}

	render() {
		const settings = {
			className: "",
			infinite: false,
			slidesToShow: 1,
			autoplay: false,
			swipeToSlide: true,
			dots: true,
		};

		const { article, sections } = this.state;

		let articleContent,
			dummydata,
			imgContent,
			sliderContent,
			share,
			Facebook,
			LinkedIn,
			back,
			relatedNewsText,
			imgBottom = null;

		let backText = {},
			shareText = {};

		let shareImgLink, fileImg, articleNewest;

		if (this.state.isLoadedSection && this.state.isLoadedArticle) {
			sections.forEach((section) => {
				if (section.index === 1) {
					backText.url = section.url;
					backText.content = section.widget_contents[0].content;
				} else if (section.index === 2) {
					shareText.content = section.widget_contents[0].content;
				} else if (section.index === 5) {
					shareImgLink = section.widget_contents.map((j, i) => {
						let url = j.url ? j.url : "/";
						fileImg = j.assets[0] ? j.assets[0].file : "";
						let description = j.assets[0]?.description;

						return (
							<a key={j.title} target="_blank" href={url} rel="noopener noreferrer">
								<img className={classes.icon} src={fileImg} alt={description} />
							</a>
						);
					});
				} else if (section.index === 7) {
					relatedNewsText = section.widget_contents[0].content;
				}
			});

			article.forEach((item) => {
				let shareTitle = item.title;
				let strDate = item.date;
				let dateFull = dateFormat(strDate, "dd-mm-yyyy");
				imgContent = item.assets.map((ele, i) => {
					return (
						<div className={classes.boxImg}>
							<img className={classes.imgSlide} src={ele.assets_small} alt={ele.desc} />

							<p>{ele.desc}</p>
						</div>
					);
				});

        if (item.article_type === 1) {
          sliderContent = <Slider {...settings}>{imgContent}</Slider>;
        }
				
				Facebook = (
					<FacebookShareButton
						url={this.state.currentUrl}
						quote={shareTitle}
						// hashtag="#camperstribe"
						className={classes.boxImageIconShare}
					>
						<FacebookIcon size={36} round />
					</FacebookShareButton>
				);
				LinkedIn = (
					<LinkedinShareButton
						url={this.state.currentUrl}
						title={shareTitle}
						// summary={"#camperstribe"}
						className={classes.boxImageIconShare}
					>
						<LinkedinIcon size={36} round />
					</LinkedinShareButton>
				);

				if (item.article_type === 1) {
					articleContent = (
						<div className={classes.boxContent}>
							{/* <div className={classes.category}>{item.category}</div> */}
							<div className={classes.title}>{item.title}</div>
							<div className={classes.date}>{dateFull}</div>
							<div className={classes.isi}>{ReactHtmlParser(item.content)}</div>
						</div>
					);
				} else if (item.article_type === 2) {
					articleContent =
					<>
						<Option2 src={item.content_file ? item.content_file : false} />
						{item.content && <p className="mt-3 float-right mr-3">{item.content}</p>}
					</>
				} else if (item.article_type === 3) {
					articleContent = <Option3 content={item.content} />
				}

				share = (
					<div
						className={classes.share}
						style={{
							fontFamily: "Helvetica",
						}}
					>
						<p>{shareText.content} :</p>
						<div className={classes.boxIconW}>
							{Facebook}
							{LinkedIn}
						</div>
					</div>
				);

				let prevLink = ""; //  localStorage.getItem("prevLink");

				back = (
					<a href={prevLink && prevLink !== "" ? prevLink : backText.url} className={classes.backLink}>
						<FontAwesomeIcon className={classes.backIcon} icon={faArrowLeft} />
						<div>{backText.content}</div>
					</a>
				);
			});

			articleNewest = <ArticleLatest id_widget={this.state.id_widget} />;
		} else if (this.state.isApiLoaded) {
		}
		return (
			<div className="pages-new">
				<div className="sliderGallery">{sliderContent}</div>
				<div className={classes.main}>
					<ParallaxProvider>
						<Fragment>{articleContent}</Fragment>
					</ParallaxProvider>
					<div className={classes.shareContainer}>{share}</div>
					{back}
				</div>
				<div className={classes.main2}>
					<p className={classes.related_news}>{relatedNewsText}</p>
					{articleNewest}
				</div>
			</div>
		);
	}
}

export default TabletNew;
