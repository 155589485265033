import React, { Component } from 'react';
import ReactHtmlParser from "react-html-parser";

import classes from './Article.module.css'

class Article extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: ''
        }
    }

    render() {
        return (
            <div className={classes.container}>
                <div className={classes.content}>
                    {ReactHtmlParser(this.props.article.content)}
                </div>
            </div>
        );
    }
}

export default Article;