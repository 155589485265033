import React, { Component } from "react";
import { Modal } from "react-bootstrap";

import UserContext from "../Context";
import Api from "../../Api";

import classes from "./Modal.module.css";

class ModalLogin extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      id: false,
      isEmail: false,
      texts: '',
    };
    this.resetPassword = this.resetPassword.bind(this);
    this.checkEmail = this.checkEmail.bind(this);
  }

  componentDidMount() {
    let textObj = {}
    let textName = ['signIn', 'newUser', 'createAcc', 'email', 'pwd', 'forgotPwd', 'continue', 'cancel', 'resetP']
    this.props.texts.forEach((txt, i) => {
      textObj[textName[i]] = txt.content;
    })
    this.setState({ texts: textObj })
  }

  checkEmail(param) {
    let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (!param.match(mailformat)) {
      return false;
    } else {
      return true;
    }
  }

  isValidate(email) {
    if (!email) {
      this.setState({
        reqEmail: "* Email is required",
      });
      return false;
    } else if (email) {
      if (this.checkEmail(email)) {
        this.setState({
          reqEmail: null,
        });
        return true;
      } else {
        this.setState({
          reqEmail: "* Email invalid address",
        });
        return false;
      }
    }
  }

  resetPassword() {
    const reset = new Api();
    const contextValue = this.context;

    const email = document.getElementById("m-email").value;

    const data = {
      email: email,
      template: contextValue.template,
      language: localStorage.getItem('language') === "English" ? "en" : "vi"
    }

    if (this.isValidate(email)) {
      reset
        .resetPasswordEmail(data)
        .then((res) => {
          this.setState({
            isEmail: false,
          });
          this.props.close();
          contextValue.showNotification("please check your email");
        })
        .catch((err) => {
          let errEmail = err.response.data.email
            ? err.response.data.email[0]
            : "";
          if (errEmail) {
            this.setState({
              reqEmail:
                "* The email you entered did not match our records. Please double-check and try again.",
            });
          } else {
            this.setState({
              isEmail: false,
            });
          }
        });
    }
  }

  render() {
    let errorEmail = this.state.isEmail ? classes.errorActive : "";
    const { texts } = this.state

    return (
      <Modal
        size={"md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.show}
        onHide={this.props.loginClicked}
      >
        <Modal.Header
          className={classes.modalHeader}
          onClick={this.props.close}
          closeButton
        >
          <span className={classes.modalTitle}>{texts.resetP}</span>
        </Modal.Header>
        <div className={classes.modalContent}>
          <div className={classes.content}>
            <div className={classes.boxContent}>
              <span className={classes.titleContent}>{texts.email}</span>
              <input
                id="m-email"
                className={classes.inputModal}
                type={"text"}
                defaultValue={""}
              />
            </div>
            <span className={[classes.validError, errorEmail].join("")}>
              {this.state.reqEmail}
            </span>
            <div className={classes.contentAction}>
              <div className={classes.cancel} onClick={this.props.loginClicked}>
                <span>{texts.cancel}</span>
              </div>
              <div
                className={classes.boxAction}
                onClick={() => {
                  this.resetPassword();
                }}
              >
                <span>{texts.continue}</span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalLogin;
