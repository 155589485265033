import React, { Component } from "react";
import { Link } from "react-router-dom";

import Api from "./Api";
import classes from "./IN_News_Section1.module.css";

import Box from './Box/Box'
import Banner from './Banner/Banner'

export default class Section3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentSection: [],
      dataUrl: '',
      readMoreTxt: '',
      isLoaded: false,
      isApiLoaded: false,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    apiData
      .getSectionDetail()
      .then((result) => {
        const sections = result.data;

        this.setState({
          contentSection: sections.widgets,
          isLoaded: true,
        })
      })
      .catch((error) => {
        this.setState({
          isApiLoaded: true,
        });
      });
  }

  render() {
    let box, title, banner;
    if (this.state.isLoaded) {
      let s = this.state.contentSection
      banner = <Banner runningText={s[0].widget_contents[0].content} bannerUrl={s[1].widget_contents[0].file_medium} />
      title = <h2 className={classes.title}>{s[2].widget_contents[0].content}</h2>
      box = <Box id={s[3].id} url={s[4].url} readMoreTxt={s[4].widget_contents[0].content} />
    }

    return (
      <div className={classes.contentSection}>
        {banner}
        <div className={classes.customContainer}>
          {title}
        </div>
        {box}
      </div>
    )
  }
}
