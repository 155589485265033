import React, { Component } from "react"
import "swiper/swiper.scss"
import css from "./Mobile.module.css"
import Api from "../Api"
import dateFormat from "dateformat"
import ReactHtmlParser from "react-html-parser"
import "./Mobile.css"
import Slider from "react-slick"
import SlidersSet from "./Slider"

class MobileView extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contentDetail: false,
      contentArticle: [],
      isLoaded: false,
      notLoaded: false,
      indexYear: 0,
      disabledNext: true,
      disabledPrev: true,
      currentIndex: 0,
      nav1: null,
      nav2: null,
      slideIndex: 0,
    }
    this.getArticleGroupbyYear = this.getArticleGroupbyYear.bind(this)
    this.toggleNextButton = this.toggleNextButton.bind(this)
    this.togglePrevButton = this.togglePrevButton.bind(this)
    this.next = this.next.bind(this)
  }

  toggleHandlerBack = () => {
    this.setState({
      contentDetail: false,
    })
  }

  toggleExit = () => {
    this.setState({
      indexYear: false,
      currentIndex: 0,
    })
    this.toggleNextButton(true)
  }

  toggleNextButton(onOrOff) {
    this.setState({
      disabledNext: onOrOff,
    })
  }

  togglePrevButton(onOrOff) {
    this.setState({
      disabledPrev: onOrOff,
    })
  }

  changeHighlight(key) {
    this.setState({ activeYear: key })
  }

  changeBox = () => {
    this.setState({
      content: <div></div>,
    })
  }

  componentDidMount() {
    let id = this.props.id

    if (id !== "dummy") {
      this.getArticleGroupbyYear(id, () => {
        this.setState({
          nav1: this.slider1,
          nav2: this.slider2,
        })
      })
    } else {
      this.setState({
        notLoaded: true,
      })
    }
  }

  next() {
    this.slider2.slickNext()
  }

  changeSlideBar(i) {
    this.state.nav2.slickGoTo(0, true)
    this.setState({
      currentIndex: i,
      indexYear: i + 1,
      contentDetail: true,
    })

    if (this.state.contentArticle[i].art.length === 1) {
      this.toggleNextButton(true)
    } else {
      this.toggleNextButton(false)
    }
  }

  getArticleGroupbyYear(param, callback) {
    const apiData = new Api()
    apiData
      .getArticleGroupbyYear(param)
      .then((result) => {
        this.setState({
          isApiLoaded: true,
        })
        const widget = []
        const years = []
        result.data.forEach((element) => {
          years.push(element.year)

          widget.push({
            year: element.year,
            art: element.articles,
          })
        })

        this.setState(
          {
            contentArticle: widget,
            years: years,
            isLoaded: true,
          },
          callback
        )
      })
      .catch((error) => {
        this.setState({
          isApiLoaded: true,
        })
      })
  }
  render() {
    const { contentArticle, isLoaded } = this.state
    let date, contents, title, year, titleContent, isImage, contentU, contentB, activeYear, dataUp, activeBox, contentText, active, years

    let prevLink = this.props.backUrl // localStorage.getItem('prevLink')

    if (isLoaded) {
      contentArticle.forEach((ele, i) => {
        let idx
        if (this.state.indexYear === 0) {
          idx = 0
        } else {
          idx = 1
        }

        if (i === this.state.indexYear - idx) {
          contentU = ele.art.map((n, index) => {
            date = n.date
            contents = n.article_languages.content
            title = n.article_languages.title
            year = dateFormat(date, "yyyy")
            titleContent = <p className={css.tl02}>{title}</p>
            isImage = <img className={css.image} src={n.article_assets[0].file_small} alt={n.article_assets[0].description} />

            let idxs
            if (this.state.indexYear === 0) {
              idxs = 0
            } else {
              idxs = 1
            }

            return (
              <div className={css.cssT01} asNavFor={this.state.nav2} ref={(slider) => (this.slider1 = slider)}>
                {this.state.indexYear - idxs === i ? isImage : null}
              </div>
            )
          })

          contentText = ele.art.map((n, index) => {
            date = n.date
            contents = n.article_languages.content
            title = n.article_languages.title
            year = dateFormat(date, "yyyy")
            titleContent = <p className={css.tl02}>{title}</p>

            let idxs
            if (this.state.indexYear === 0) {
              idxs = 0
            } else {
              idxs = 1
            }

            return (
              <>
                <div className={css.cssCtt01} onChange={(e) => this.slider.slickGoTo(e.target.value)} value={this.state.slideIndex}>
                  <div className={css.yyy04}>
                    <div className={css.yt0h}>
                      <p>{year}</p>
                    </div>
                    <p className={css.tl01}>{title}</p>
                    <p className={css.tl3}> {ReactHtmlParser(contents)}</p>
                  </div>
                </div>
              </>
            )
          })
        }
      })

      contentB = contentArticle.map((ele, i) => {
        ele.art.forEach((n, index) => {
          isImage = <img className={css.isBottom} src={n.article_assets[0].file_small} alt={n.article_assets[0].description} />

          date = n.date
          contents = n.article_languages.content
          title = n.article_languages.title
          years = dateFormat(date, "yyyy")
          titleContent = <p>{title}</p>

          if (i === this.state.currentIndex) {
            activeYear = css.activeTextReal
            activeBox = css.activeBox
            active = css.active
          } else {
            activeYear = ""
            activeBox = ""
            active = ""
          }
        })

        return (
          <div className={[css.box2, activeYear].join(" ")} onClick={() => this.changeSlideBar(i)}>
            {isImage}
            <div className={[css.isInside, activeBox].join("")}>
              <div className={[css.isYearText, active].join(" ")}>
                <p>{years}</p>
              </div>
            </div>
          </div>
        )
      })

      const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        swipeToSlide: true,
        appendDots: (dots) => (
          <div
            style={{
              backgroundColor: "transparent",
              borderRadius: "10px",
            }}
          >
            <ul style={{ margin: "0px" }}> {dots} </ul>
          </div>
        ),
        customPaging: (i) => (
          <div
            style={{
              width: "10px",
              height: "10px",
              color: "white",
              border: "1px solid #d6d6d6",
              borderRadius: "50%",
              marginRight: "2px",
            }}
          ></div>
        ),
      }

      dataUp = (
        <div className={css.h1} id='sliderU'>
          <Slider {...settings} asNavFor={this.state.nav2} ref={(slider) => (this.slider1 = slider)}>
            {contentU}
          </Slider>
          <div className={css.slideCtt02} id='sliderB'>
            <SlidersSet setting={"top"} content={contentB} />
          </div>
          {/* {contentText} */}
        </div>
      )
    }

    return (
      <div className={css.wrapp}>
        {dataUp}
        <Slider speed={350} asNavFor={this.state.nav1} ref={(slider) => (this.slider2 = slider)}>
          {contentText}
        </Slider>

        {/* <div className={css.cssT02}>
          <div className={css.slideCtt02} id="sliderB">
            <SlidersSet setting={"top"} content={contentB} />
          </div>
        </div> */}
        <div className={css.bactBtm}>
          <img src={require("./asset/arrow.svg")} alt=' ' />
          <a href={prevLink && prevLink !== "" ? prevLink : "/about-us"}>
            <p className={css.backPrev}>{this.props.backBtn}</p>
          </a>
        </div>
      </div>
    )
  }
}

export default MobileView
