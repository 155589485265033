import React from "react";
import { Card } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import classesD from "../MenuDetail.module.css";
import classes from "./MenuPreview5.module.css";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";

const menuPreview5 = (props) => {
    let item = null;
    let lang = localStorage.getItem("language");

    let id_widget;
    let id_article;
    let paramss;
    if (props.items) {
        props.items.forEach((x, index) => {
            if (index === 0) {
                if (x.widget) {
                    id_widget = x.widget.id;
                    const widgetCate = x.widget.category

                    item = x.widget.widget_contents.map((itm, i) => {
                        id_article = itm.id;
                        let slugTitle = itm.article_language.slug_title
                        paramss = `${slugTitle !== "" ? slugTitle : "-"}/${id_article}`

                        if (widgetCate === "Job Poster") {
                            paramss = `${slugTitle !== "" ? slugTitle : "-"}/${itm.job_poster?.id}`;
                        }

                        let dateFull
                        if (itm.date) {
                            dateFull = lang === "Vietnamese" ? dateFormat(itm.date, "dd-mm-yyyy") : dateFormat(itm.date, "mm-dd-yyyy");
                        } else {
                            dateFull = ""
                        }

                        if (i <= 3) {
                            let img = null;
                            let urlArticle = x.url ? `${x.url}/${paramss}` : '/'

                            const vidId = getVidId(itm.assets[0]?.link)
                            const ytThumb = vidId ? `https://img.youtube.com/vi/${vidId}/hqdefault.jpg` : null
                            img = itm.assets[0]?.file_small || itm.assets[0]?.thumbnail || ytThumb

                            let htmlReturn
                            let html = <div className="m-3 col-md-4">
                                <Link
                                    onClick={() => {
                                        setTimeout(() => {
                                            window.location.reload();
                                        }, 200);
                                    }}
                                    to={urlArticle} className={classes.Link}
                                >
                                    <Card className={classesD.cardPreviewBody}>
                                        <Card.Img className={classes.contentImg} variant=""
                                            src={img ? img : require('../assets/default-image.svg')}
                                        />
                                        <span className={[classes.textTitleNews, classes.custom].join(" ")} >
                                            {itm.article_language.title ? ReactHtmlParser(itm.article_language.title) : ""}
                                        </span>
                                        {dateFull ? <p className={classes.dateTitleNews}>{dateFull}</p> : <></>}
                                    </Card>
                                </Link>
                            </div>

                            if (widgetCate === "Job Poster") {
                                if (Object.keys(itm.article_language).length !== 0) {
                                    htmlReturn = html
                                } else {
                                    htmlReturn = <></>
                                }
                            } else if (widgetCate === "Article") {
                                htmlReturn = html
                            }

                            return htmlReturn
                        }
                    });
                }
            }
        });
    }

    let dataLink, tabClass
    if (props.dataList) {
        dataLink = <Link
            onClick={() => {
                setTimeout(() => {
                    window.location.reload();
                }, 200);
            }}
            to={props.dataList[0]?.url ? props.dataList[0]?.url : ''} className={classes.careerLink}
        >
            <span>{props.dataList[1]?.content}</span>
        </Link>
        tabClass = 'd-flex flex-column'
    }
    return (
        <div className={[classesD.sectionPreview, tabClass].join(' ')}>
            <div id="preview5" className="d-flex flex-row">
                {item}
            </div>
            {dataLink}
        </div>
    );
};

function getVidId(ytEmbedUrl) {
    if (!ytEmbedUrl) {
        return ytEmbedUrl
    }

    const url = new URL(ytEmbedUrl)
    const path = url.pathname.split("/")
    const vidId = path[path.length - 1] === "" ? path[path.length - 2] : path[path.length - 1]
    return vidId
}

export default menuPreview5;
