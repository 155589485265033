import React, { Component } from "react";

import Api from "./Api";

import ReactHtmlParser from "react-html-parser";
import classes from "./Sub_Section1.module.css";

class Sub_Section1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentSection: [],
            isLoaded: false,
            isApiLoaded: false,
            isBoxHover: false,
            boxCount: 0,
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        apiData
            .getSectionDetail()
            .then((result) => {
                const sections = result.data;
                const widget = [];

                result.data.widgets.forEach((item) => {
                    widget.push({
                        id: item.id,
                        index: item.index,
                        category: item.category,
                        name: item.name,
                        sections: item,
                    });
                });

                sections.page = widget;
                this.setState({
                    contentSection: widget,
                    isLoaded: true,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    isApiLoaded: true
                })
            });
    }

    toggleBoxHandlerEnter = () => {
        this.setState((prevState) => {
            return { isBoxHover: !prevState.isBoxHover };
        });
    };

    render() {

        var titleBanner, title, description, img = null;
        var dummyTitle = "Title";
        var dummyDescription = "Nice overview"

        if (this.state.isLoaded) {
            this.state.contentSection.forEach((c, i) => {
                switch (i) {
                    case 0:
                        titleBanner = c.sections.widget_contents[0] ? c.sections.widget_contents[0].content : dummyTitle
                        break;
                    case 1:
                        img = c.sections.widget_contents[0] ? c.sections.widget_contents[0].file : null
                        break;
                    case 2:
                        title = c.sections.widget_contents[0] ? c.sections.widget_contents[0].article_language.title : dummyTitle
                        description = c.sections.widget_contents[0] ? c.sections.widget_contents[0].article_language.summary : dummyDescription
                        break;
                    default:
                        break;
                }
            })
        }

        return (
            <div className="position-relative">
                <div className={classes.banner}>
                    <img className={classes.imageFull} src={img} alt="" />
                    {/* <div className={classes.bannerContent}>
                        <p className={[classes.titleBanner, classes.animate].join(" ")}>{titleBanner}</p>
                        <div className={[classes.underlineAnimate]}></div>
                    </div> */}
                </div>
                <div onScroll={this.handleScroll} className={classes.posRelative}>
                    <div className={['container', classes.customContainer].join(' ')}>
                        <h1 className={classes.title}>{title}</h1>
                        <span className={classes.contentDesc}>
                            {ReactHtmlParser(description)}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default Sub_Section1;

