import React, { Component, Fragment } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import Api from "../Api";
import Views from "./MobileView";
// import Core from "../Core";
import classes from "./Mobile.module.css";

export default class Mobile extends Component {
  constructor() {
    super();
    this.state = {
      currentPage: null,
      sections: [],
      isLoaded: false,
      isApiLoaded: false,
      views: null,
      activePage: <div></div>,
      valueQuery: null,
      articleID: null,
      sectionName: "",
      idArticle: null,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
    this.handleOverride = this.handleOverride.bind(this);
  }

  componentDidMount() {
    let id = this.props.id;
    this.getSectionDetail(id);
    this.handleOverride();
  }

  handleOverride() {
    this.props.override(false);
  }

  getSectionDetail(id) {
    const apiData = new Api();

    apiData
      .getSectionDetail(id)
      .then((result) => {
        const sections = result.data;
        const widget = [];
        result.data.widgets.forEach((item) => {
          widget.push({
            id: item.id,
            index: item.index,
            category: item.category,
            name: item.name,
            sections: item,
          });
        });

        this.setState({
          sectionName: result.data.name,
          sections: widget,
          isLoaded: true,
          idArticle: result.data.id,
          articleID: widget[0].id,
        });
      })

      .catch((error) => {
        this.setState({ isApiLoaded: true });
      });
  }

  render() {
    const { sections } = this.state;
    let readMore, share, searchHere, newsButton, textSearch, url;

    let viewOnchange;

    if (this.state.isLoaded) {
      var articleId = 0;
      var idNewsDetails = 0
      var article_categories = [];
      var titleText = ""
      sections.forEach((item) => {
        switch (item.index) {
          case 1:
            readMore = item.sections.widget_contents[0]
              ? item.sections.widget_contents[0].content
              : "Read Less";
            break;
          case 2:
            share = item.sections.widget_contents[0]
              ? item.sections.widget_contents[0].content
              : "Don't share";
            break;
          case 3:
            searchHere = item.sections.widget_contents[0]
              ? item.sections.widget_contents[0].content
              : "Don't search";
            break;
          case 4:
            newsButton = item.sections.widget_contents[0]
              ? item.sections.widget_contents[0].content
              : "Less news";
            break;
          case 5:
            textSearch = item.sections.widget_contents[0]
              ? item.sections.widget_contents[0].content
              : "Search here. . .";
            break;
          case 6:
            url = item.sections.url ? item.sections.url : "/news/detail/";
            articleId = item.id
            break;
          case 7:
            idNewsDetails = item.id
            break;
          case 8:
            article_categories = item.sections.widget_contents;
            break;
          case 9:
            titleText = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "All Innovation News"
            viewOnchange = (
              <Views
                id={articleId}
                idNewsDetails={idNewsDetails}
                idArt={this.state.idArticle}
                url={url}
                category={article_categories}
                view={"grid"}
                readMore={readMore}
                share={share}
                title={titleText}
                searchHere={searchHere}
                newsButton={newsButton}
                textSearch={textSearch}
              />
            );
            break;
          default:
        }
      });
    }

    return (
      <div className="page-news">
        <div className={classes.main}>
          <ParallaxProvider>
            <Fragment>
              <div className={classes.content}>
                <div className={["container", classes.boxC].join(" ")}>
                  {viewOnchange}
                </div>
              </div>
              <div className={classes.coreCon}>
                <div className={classes.Core}>
                  {/* <Core /> */}
                </div>
              </div>
            </Fragment>
          </ParallaxProvider>
        </div>
      </div>
    );
  }
}
