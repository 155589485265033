import React, { Component } from "react";
import classes from "./Job_Details_New.module.css";
import Api from "./Api";
import { Link } from "react-router-dom";

export default class JobBox extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let back,
      url,
      navLink = null;

    back = this.props.back;
    url = this.props.url;
    navLink = this.props.back ? (
      <div className={["d-flex", classes.marg].join(" ")}>
        <div className={"d-flex"}>
          <i
            className={[
              classes.arrow,
              classes.left,
              classes.customMargin,
            ].join(" ")}
          ></i>
        </div>
        <Link to={url} className={classes.customLink}>
          <span className={classes.textBack}>{back}</span>
        </Link>
      </div>
    ) : "";
    
    return <>{navLink}</>;
  }
}
