import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import Api from "./Api";
import classes from "./development.module.css";

export default class Box extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentArticle: [],
      aboutPhenikaa: [],
      isLoaded: false,
      isApiLoaded: true,
    };
    this.getArticleDetail = this.getArticleDetail.bind(this);
  }

  componentDidMount() {
    if (this.props.id) {
      this.getArticleDetail(this.props.id);
    }
  }

  getHtmlParagraph(str) {
    if (str !== "") {
      return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ");
    }
  }

  getArticleDetail(param) {
    const apiData = new Api();
    this.setState({ contentArticle: [] }, () => {
      apiData
        .getArticleDetail(param)
        .then((result) => {
          const articles = result.data;
          const assets = [];
          articles.results.forEach((a) => {
            assets.push({
              title: a.article_languages.title,
              content: a.article_languages.content,
            });
          });

          this.setState({
            contentArticle: assets,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  render() {
    const { contentArticle, isLoaded } = this.state;

    let content = null;

    if (isLoaded) {
      contentArticle.map((z, i) => {
        const splitStr = z.content.split("#");

        // title = z.title;
        content = ReactHtmlParser(
          splitStr[0] ? this.getHtmlParagraph(splitStr[0]) : ""
        );
      });
    }

    return (
      <div className={classes.insideBox}>
        <p className={classes.text}>{content}</p>
      </div>
    );
  }
}
