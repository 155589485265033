import React, { Component } from "react";
import classes from "./Section5.module.css";
import Api from "./Api";
import Card from "./Card/Card";
import Slider from "./Slider/Slider";
import ModalPDF from "./ModalPDF/ModalPDF"

class SD_Section5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentSection: [],
      isLoaded: false,
      isApiLoaded: true,
      lang: false,

      // Pdf preview
      isShowPdfPreview: false,
      pdfFile: "",
      pdfTitle: "",
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
    // this.getAssets = this.getAssets.bind(this);
  }

  componentDidMount() {
    let is_language = localStorage.getItem("language");

    if (is_language) {
      this.setState({
        lang: true,
      });
    }

    // let id = 608;

    this.getSectionDetail();
    // this.getAssets(id);
  }

  getAssets(param) {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getAssets(param)
        .then((result) => {
          const sections = result.data;
          const widget = [];

          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              is_language: item.has_language,
              sections: item,
            });
          });
          sections.page = widget;
          this.setState({
            contentSection: widget,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          console.log(sections)
          const widget = [];

          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              is_language: item.has_language,
              sections: item,
            });
          });
          sections.page = widget;
          this.setState({
            contentSection: widget,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  render() {
    var filePackage = [];
    var title = null;

    var fileArray = [];
    var imageArray = [];

    if (this.state.isLoaded) {
      this.state.contentSection.forEach((item, index) => {
        switch (index) {
          case 0:
            imageArray = [];

            item.sections.widget_contents.forEach((image) => {
              let imgFile;
              let textDesc = null;

              if (image.file_small !== null) {
                imgFile = image.file_small;
                textDesc = image.description;
              } else {
                imgFile = "";
                return textDesc;
              }

              imageArray.push([imgFile, textDesc]);
            });
            break;
          case 1:
            fileArray = [];

            item.sections.widget_contents.forEach((file) => {
              let pdfFile, textDescFile;

              if (file.file !== null) {
                pdfFile = file.file;
                textDescFile = file.description;
              } else {
                pdfFile = "";
                textDescFile = "";
              }

              fileArray.push([pdfFile, textDescFile]);
            });

            break;
          case 2:
            title = item.sections.widget_contents[0]
              ? item.sections.widget_contents[0].content
              : "Title";
            break;
          default:
            break;
        }
      });
      imageArray.forEach((item, index) => {
        var image = item[0];
        var file = fileArray[index] ? fileArray[index][0] : "";
        var desc = fileArray[index] ? fileArray[index][1] : "";

        // var description = fileArray[index] ? fileArray[index][1] : item[1];
        filePackage.push([image, file, desc]);
      });
    }

    var content = filePackage.map((item, idx) => {
      let custom = idx === 0 ? classes.boxFirst : "";
      return (
        <Card
          first={custom}
          idx={idx}
          lang={this.state.lang}
          imgUrl={item[0]}
          // url={item[1]}
          text={item[2]}
          onClick={() => {
            this.setState({
              pdfFile: item[1],
              pdfTitle: item[2]
            }, () => {
              this.setState({ isShowPdfPreview: true, })
            })
          }}
        />
      );
    });

    return (
      <div className={classes.Section5}>
        <div className={["container", classes.customContainer].join(" ")}>
          <h2 className={classes.title}>{title}</h2>
        </div>

        <div className={["container", classes.boxC].join(" ")}>
          <Slider content={content} />
        </div>

        <ModalPDF
          show={this.state.isShowPdfPreview}
          onHide={() => {
            this.setState({ isShowPdfPreview: false, pdfFile: "" })
          }}
          file={this.state.pdfFile}
          title={this.state.pdfTitle}
        />
      </div>
    );
  }
}
export default SD_Section5;
