import React, { Component } from "react";

import { Link } from "react-router-dom";
import dateFormat from "dateformat";

import UserContext from "../Context";
import Career1 from "./C_Section1/CO";
import DataTable from './Table/Table'

import classes from "./Career_BrowseN.module.css";
export default class Career_BrowseN extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            id: "",
        };
    }

    componentDidMount() {
        const contextValue = this.context;

        this.setState({
            id: contextValue.idApplicant,
        });
    }

    render() {
        let positions, filterList, thisUrl, title1, title2, table;

        const contextValue = this.context;

        if (contextValue.isJobLoaded && contextValue.isFilterLoaded) {
            table = <DataTable data={contextValue.jobList} />

            // filterList = contextValue.listFilter.map((s, i) => {
            //   let active = parseInt(this.props.active) === parseInt(i + 1) ? classes.filterActive : ""

            //   return (
            //       <div data-filter={s.id} className={[classes.filterContent, active].join(' ')} onClick={this.props.onClick}>
            //           <span data-filter={s.id} onClick={this.props.onClick}>{s.title}</span>
            //       </div>
            //   );
            // });
        }

        if (contextValue.isSectionLoaded) {
            if (contextValue.contentSection[2]) {
                contextValue.contentSection[2].sections.widget_contents.forEach((c, i) => {
                    switch (i) {
                        case 3:
                            title1 = c.content
                            break;
                        case 4:
                            title2 = c.content
                            break;
                        default:
                            break;
                    }
                })
            }
        }

        return (
            <>
                <div className={["container", classes.customContainer, classes.customTitle].join(" ")}>
                    <h2 className={classes.title}>{title1}</h2>
                </div>
                <Career1 />
                <div className={["container", classes.customContainer].join(" ")}>
                    <h2 className={classes.title}>{title2}</h2>
                    <div className={classes.contentBrowseJob}>
                        {table}
                    </div>
                </div>
            </>
        );
    }
}
