import React, { useState } from "react";
import styled from "styled-components";

import { isMobile, isTablet, isFirefox, browserName, CustomView } from "react-device-detect";

import MegaMenu from "../MegaMenu/MegaMenu";
import MegaMenuMobile from "../MegaMenuMobile/MegaMenuMobile";
import { useMediaQuery } from "react-responsive";
import "./HamburgerMenu.css";

const StyledBurger = styled.button`
  position: absolute;
  top: 20px;
  right: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  // background: #00000080 0% 0% no-repeat padding-box;
  background: transparent;
  height: 56px;
  width: 56px;
  padding: 5px;
  border: 1px solid #00000080;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 100;

  &:focus {
    outline: none;
  }

  @media only screen and (max-width: 1400px) {
    top: 1rem;
    right: 2rem;
  }

  @media only screen and (max-width: 480px) and (min-width: 361px) {
    right: 1rem;
    height: 50px;
    width: 50px;
  }

  @media only screen and (max-width: 360px) {
    right: 1rem;
  }
  @media only screen and (orientation: landscape) and (max-width: 879px) {
    top: 0.5rem;
  }

  span {
    top: 0;
    right: 0;
    width: 37px;
    height: 0.25rem;
    background: ${({ open }) => (open ? "#0D0C1D" : "#223971")};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    @media only screen and (max-width: 1400px) {
      width: 32px;
      height: 0.15rem;
    }

    @media only screen and (max-width: 480px) {
      width: 28px;
      height: 3px;
    }

    :first-child {
      margin: ${({ open }) =>
    open ? ".5rem 0px 0px 1rem" : "1rem auto 0px auto"};
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      margin: 0.4rem auto;
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(10px)" : "translateX(0)")};

      @media only screen and (max-width: 1400px) {
        margin: 0 auto;
      }
      @media only screen and (max-width: 480px) {
        margin: 0.3rem auto;
      }
    }

    :nth-child(3) {
      margin: ${({ open }) =>
    open ? "0px 0px 1rem 1rem" : "0px auto 1rem auto"};
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
      @media only screen and (max-width: 480px) {
        margin: ${({ open }) =>
    open ? "0 0 1.2rem 1rem" : "0 auto 1rem auto"};
      }
    }
  }
`;

const CamouflageDiv = styled.div`
`;

const Burger = (props) => {
  const isM = useMediaQuery({
    query: "(min-device-width: 411px) and (max-device-width: 479px)",
  });
  const [open, setOpen] = useState(false);
  let burgerColor = props.burgerColor ? props.burgerColor : "white";

  let megaMenu;
  if (isTablet) {
    // megaMenu = <MegaMenu open={open} items={props.items} placeholder={props.placeholder} lang={props.lang} icon={props.icon} selectLanguage={props.selectLanguage} />
    open
      ? (document.body.style.overflowY = "hidden")
      : (document.body.style.overflowY = "auto");
    megaMenu = (
      <MegaMenuMobile
        open={open}
        items={props.items}
        placeholder={props.placeholder}
        lang={props.lang}
        icon={props.icon}
        selectLanguage={props.selectLanguage}
      />
    );
  } else if (isMobile || isM) {
    open
      ? (document.body.style.overflowY = "hidden")
      : (document.body.style.overflowY = "auto");
    megaMenu = (
      <MegaMenuMobile
        open={open}
        items={props.items}
        placeholder={props.placeholder}
        lang={props.lang}
        icon={props.icon}
        selectLanguage={props.selectLanguage}
      />
    );
  } else {
    megaMenu = (
      <MegaMenu
        open={open}
        items={props.items}
        placeholder={props.placeholder}
        lang={props.lang}
        icon={props.icon}
        selectLanguage={props.selectLanguage}
      />
    );
  }

  // let backgroundFirefox

  // backgroundFirefox = open ?
  //   <img className="mn-fr mn-fr-op" src={require('../assets/image-background.jpg')} alt=""></img>
  //   <CamouflageDiv className="mn-fr mn-fr-op"></CamouflageDiv>
  //   : 
  //   <CamouflageDiv className="mn-fr mn-fr-cl"></CamouflageDiv>
  //   <img className="mn-fr mn-fr-cl" src={require('../assets/bg-menu.png')} alt=""></img>

  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <span style={{ backgroundColor: burgerColor }} />
        <span style={{ backgroundColor: burgerColor }} />
        <span style={{ backgroundColor: burgerColor }} />
      </StyledBurger>
      {/* <CustomView condition={browserName === "Firefox"}>
        {backgroundFirefox}
      </CustomView> */}


      {/* <MegaMenu open={open} items={props.items} /> */}
      {megaMenu}
    </>
  );
};
export default Burger;
