import React, { Component } from "react";
import { Link } from "react-router-dom";

// import Slider from "react-slick";

import Aux from "./Auxs";
import Api from "../Api";

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "./News.css";

import Slider from '../Slider/Slider'

import classes from "./Box.module.css";
import dateFormat from "dateformat";

export default class Box extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentArticle: [],
      url: "",
      readMore: "Read More",
      isLoaded: false,
      isApiLoaded: true,
    };
    this.getArticleDetailByAuthor = this.getArticleDetailByAuthor.bind(this);
  }

  componentDidMount() {
    if (this.props.id) {
      this.getArticleDetailByAuthor(this.props.id);
      this.setState({
        url: this.props.url,
        readMore: this.props.readMore,
      });
    }
  }

  getArticleDetailByAuthor(param) {
    const apiData = new Api();
    apiData
      .getArticleDetailByAuthor(param, null, null)
      .then((result) => {
        const articles = result.data;
        const art = [];
        articles.results.forEach((a) => {
          const assets = [];
          a.article_assets.forEach((ass, i) => {
            assets.push({
              asset: ass.file_small || ass.asset_file || ass.asset_link,
              asset_type: ass.asset_type,
            });
          });

          art.push({
            id: a.id,
            category: a.category_name,
            name: a.name,
            title: a.article_languages.title,
            content: a.article_languages.content,
            assets: assets,
            is_image: assets[0].asset,
            date: a.date,
            slug_title: a.article_languages.slug_title
          });
        });

        this.setState({
          contentArticle: art,
          isLoaded: true,
        });

      })
      .catch((error) => {
        this.setState({
          isApiLoaded: true,
        });
      });
  }

  render() {
    const { contentArticle } = this.state;
    const readText = this.props.readMoreTxt ? this.props.readMoreTxt : "Read More"
    let box, backgroundImage;

    // let slidesToShow;
    // if (window.innerWidth <= 480) {
    //   slidesToShow = 1
    // } else if (window.innerWidth <= 768) {
    //   slidesToShow = 2
    // } else if (window.innerWidth <= 1024) {
    //   slidesToShow = 3
    // } else {
    //   slidesToShow = 4
    // }

    // const settings = {
    //   slidesToShow: slidesToShow,
    //   swipeToSlide: true,
    //   infinite: false,
    //   variableWidth: true,
    // };

    if (this.state.isLoaded) {
      box = contentArticle.map((k, i) => {
        let isMarginfirst = i === 0 ? classes.isMarginLeft : "";
        let is_src = k.is_image;
        let img = null;
        const slug_title = k.slug_title ? k.slug_title : "null"

        if (k.is_image === require("../assets/default-image.svg")) {
          img = <img className={classes.no_src} src={is_src} alt="" />;
        } else {
          img = <img className={classes.is_src} src={is_src} alt="" />;
        }

        return (
          <div className={[classes.bxf1r, isMarginfirst].join(" ")}>
            {img}
            <div className={classes.shdw0}>
              <div className={classes.cardFd1}>
                <div className={classes.cardcontent}>
                  <p className={classes.kpos}>{k.title}</p>
                  <p className={classes.date}>{k.company}</p>
                  <Link to={this.props.url + "/" + slug_title + "/" + k.id}>
                    <div className={classes.outerCircle2}>
                      <div className={classes.innerCircle2}></div>
                    </div>
                    <p className={classes.rdMoreP}>{readText}</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        );
      });
    }

    return (
      <div className="section-content">

        <div>
          <Slider >{box}</Slider>
        </div>
      </div>
    );
  }
}