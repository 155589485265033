import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Api from "../Api";
import "./Mobile.css";
import classes from "./Mobile.module.css";

class Mobile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			isApiLoaded: false,
			sections: {
				widgets: [],
			},
			items: [
				{
					id: 1,
					title: "",
					img: <img style={{ width: "100vw", height: "100vh", objectFit: "cover" }} src={require("../assets/slide1.png")} alt="1"></img>,
				},
				{
					id: 2,
					title: "",
					img: <img style={{ width: "100vw", height: "100vh", objectFit: "cover" }} src={require("../assets/slide2.png")} alt="2"></img>,
				},
				{
					id: 3,
					title: "",
					img: <img style={{ width: "100vw", height: "100vh", objectFit: "cover" }} src={require("../assets/slide3.png")} alt="3"></img>,
				},
			],
			activeSlide: 0,
		};
		this.play = this.play.bind(this);
		this.pause = this.pause.bind(this);
		this.getSectionDetail = this.getSectionDetail.bind(this);
		this.getcontentFromTextItem = this.getcontentFromTextItem.bind(this);
	}

	componentDidMount() {
		this.getSectionDetail();
	}

	getSectionDetail() {
		const apiData = new Api();
		this.setState(
			{
				sections: [],
			},
			() => {
				apiData
					.getSectionDetail()
					.then((result) => {
						const sections = result.data;
						const widget = [];
						result.data.widgets.forEach((item) => {
							widget.push({
								id: item.id,
								index: item.index,
								category: item.category,
								name: item.name,
								sections: item,
							});
						});
						sections.page = widget;
						this.setState({
							sections: widget,
							isLoaded: true,
						});
					})
					.catch((error) => {
						this.setState({
							isApiLoaded: true,
						});
						// window.location.assign(
						//   `/error/${error.response.status ? error.response.status : 404}`
						// );
					});
			}
		);
	}

	play() {
		this.slider.slickPlay();
	}
	pause() {
		this.slider.slickPause();
	}

	getcontentFromTextItem(textItem) {
		var dummyText = "";

		if (!textItem.widget_contents[0]) {
			return dummyText;
		} else {
			if (textItem.widget_contents[0].content) {
				return textItem.widget_contents[0].content;
			} else {
				return dummyText;
			}
		}
	}

	getcolorFromTextItem = (textItem) => {
		var colorText = "#000000";

		if (!textItem.widget_contents[0]) {
			return colorText;
		} else {
			if (textItem.widget_contents[0].content) {
				return textItem.widget_contents[0].font_color;
			} else {
				return colorText;
			}
		}
	};

	render() {
		const sections = this.state.sections;

		let titleLine1,
			titleLine2,
			description,
			colorLine1,
			colorLine2,
			colorDescription = "";

		let imgSlide = null;
		const settings = {
			dots: true,
			infinite: true,
			slidesToShow: 1,
			autoplay: true,
			autoplaySpeed: 5000,
			swipeToSlide: true,
			afterChange: (current) => this.setState({ activeSlide: current }),
			appendDots: (dots) => (
				<div
					style={{
						backgroundColor: "transparent",
						borderRadius: "10px",
					}}
				>
					<ul style={{ margin: "0px", padding: "0px" }}> {dots} </ul>
				</div>
			),
			customPaging: (i) => (
				<div
					style={{
						width: "10px",
						height: "10px",
						color: "white",
						border: "1px solid white",
						borderRadius: "50%",
						marginRight: "2px",
					}}
				></div>
			),
		};

		if (this.state.isLoaded) {
			sections.forEach((item) => {
				switch (item.index) {
					case 1:
						titleLine1 = this.getcontentFromTextItem(item.sections);
						colorLine1 = this.getcolorFromTextItem(item.sections);
						break;

					case 2:
						titleLine2 = this.getcontentFromTextItem(item.sections);
						colorLine2 = this.getcolorFromTextItem(item.sections);
						break;

					case 3:
						description = this.getcontentFromTextItem(item.sections);
						colorDescription = this.getcolorFromTextItem(item.sections);
						break;

					case 5:
						if (item.sections.widget_contents) {
							const arrImg = [];

							imgSlide = item.sections.widget_contents.map((sliderItem, index) => {
								var element = null;
								var filesplit = sliderItem.file.split(".");
								var filetype = filesplit[filesplit.length - 1];
								switch (filetype) {
									case "mp4":
									case "mkv":
									case "webm":
									case "mov":

										let url = sliderItem.thumbnail ? sliderItem.thumbnail : require("../assets/default-image.svg");
										element = <img className={classes.picBig} src={url} alt={sliderItem.description}></img>;
										break;
									default:
										element = <img className={classes.picBig} src={sliderItem.file} alt={sliderItem.description}></img>;
								}

								return element;
							});
						}
						break;
					default:
				}
			});
		} else if (this.state.isApiLoaded) {
			var element = null;
			element = (
				<img
					style={{
						width: "100vw",
						height: "100vh",
						objectFit: "cover",
					}}
					src={require("../assets/default-image.svg")}
					alt=" "
				/>
			);

			imgSlide = <div>{element}</div>;
		}

		let motto;
		if (this.state.isLoaded) {
			sections.forEach((item, index) => {
				switch (item.index) {
					case 4:
						item.sections.widget_contents.forEach((sliderItem, index) => {
							if (index === this.state.activeSlide) {
								if (!sliderItem.has_slogan) {
									motto = (
										<div className={classes.contentslider}>
											<h1 className={classes.titleslider} style={{ color: colorLine1 }}>
												{titleLine1}
											</h1>
											<h1 className={classes.titleslider} style={{ color: colorLine2 }}>
												{titleLine2}
											</h1>
											<h2 className={classes.titlesliderdesc} style={{ color: colorDescription }}>
												{description}
											</h2>
										</div>
									);
								} else {
									motto = <></>;
								}
							}
						});
						break;
					default:
						break;
				}
			});
		}

		return (
			<div className={[classes.sectionslider, "m-imageSlider"].join(" ")} id="imageSlider">
				<div className={classes.sliderbox}>{motto}</div>

				<span className={classes.scrollBtn}>
					<span className={classes.mouse}>
						<span></span>
					</span>
				</span>

				<Slider {...settings}>{imgSlide}</Slider>
			</div>
		);
	}
}

export default Mobile;
