import React, { Component } from "react";
import Api from "../Api";
import classes from "./Mobile.module.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

export default class Mobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
      boxCount: 0,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }
  componentDidMount() {
    this.getSectionDetail();
  }

  getHtmlParagraph(str) {
    if (str !== "") {
      return str.replace(/&nbsp;/gi, " ");
    }
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          this.setState({
            isApiLoaded: true,
          });
          const contentSection = result.data;
          const widget = [];
          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              url: item.url,
              contentSection: item,
            });
          });
          contentSection.page = widget;
          this.setState({
            contentSection: widget,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
          window.location.assign(
            `/error/${error.response.status ? error.response.status : 404}`
          );
        });
    });
  }

  render() {
    let title1, content1, src1, button1, content, isTitle, buttonUrl;
    const settings = {
      className: "",
      infinite: true,
      dots: true,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    if (this.state.isLoaded) {
      this.state.contentSection.forEach((item, i) => {
        switch (i) {
          case 0:
            title1 = item.contentSection.widget_contents[0].content;
            break;
          case 1:
            content1 = item.contentSection.widget_contents[0].content;
            break;
          case 2:
            src1 = item.contentSection.widget_contents[0];
            break;
          case 3:
            button1 = item.contentSection.widget_contents[0].content;
            let isPath = item.url;

            buttonUrl = (
              <div className={classes.btnCarReal}>
                <Link to={isPath}>
                  <button className={classes.btn_card}>{button1}</button>
                </Link>
              </div>
            );
            break;
          case 4:
            content = item.contentSection.widget_contents.map((g, i) => {
              let isUrl = g.article_language.url;
              let isSrc = g.assets[0].file_small;
              let isAlt = g.assets[0].description;
              isTitle = ReactHtmlParser(g.article_language.content);
              //   console.log(g.article_language.content);

              return (
                <div className={classes.isBig}>
                  <img className={classes.src2} src={isSrc} alt={isAlt} />
                  <div className={classes.overLay}>
                    <Link to={isUrl}>
                      <div className={classes.isTitle3}>{isTitle}</div>
                    </Link>
                  </div>
                </div>
              );
            });
            break;
          default:
            break;
        }
      });
    }
    return (
      <div className={classes.contentWrap}>
        <div className={[classes.top, "HSec5"].join(" ")}>
          <Slider {...settings}>{content}</Slider>
        </div>
        <div className={classes.bottom}>
          <img className={classes.src1} src={src1?.file_small} alt={src1?.description} />
          <div className={classes.over1}></div>
          <div className={classes.infoCareer}>
            <div className={classes.infoCarReal}>
              <div className={classes.subCarReal}>
                <h2>{title1}</h2>
              </div>
              <div className={classes.contentCarReal}>{content1}</div>
              {buttonUrl}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
