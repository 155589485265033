import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import Api from "../Api";
import Slider from "react-slick";
import Particles from "react-tsparticles";
import classes from "./sec1.module.css";
import "./sec1.css";

class Desktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: null,
      sections: [],
      isLoaded: false,
      isApiLoaded: false,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    apiData
      .getSectionDetail()
      .then((result) => {
        this.setState({
          isApiLoaded: true,
        });
        const sections = result.data;
        const widget = [];
        result.data.widgets.forEach((item) => {
          widget.push({
            id: item.id,
            index: item.index,
            category: item.category,
            name: item.name,
            sections: item,
          });
        });
        sections.page = widget;
        this.setState({
          sections: widget,
          isLoaded: true,
        });
      })
      .catch((error) => {
        this.setState({
          isApiLoaded: true,
        });
        window.location.assign(
          `/error/${error.response.status ? error.response.status : 404}`
        );
      });
  }

  render() {
    const { sections } = this.state;
    let contentDesc,
      contentSlider

    if (this.state.isLoaded) {
      let count = 0;
      sections.forEach((item) => {
        count = count + 1;
        if (count === 1 && item.index === 1 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
            } else {
            }
          });
        } else if (
          count === 1 &&
          item.index === 1 &&
          item.category !== "Text"
        ) {
        }

        if (count === 2 && item.index === 2 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              contentDesc = <p className={classes.txmdl1h}>{s.content}</p>;
            }
          });
        }

        if (count === 3 && item.index === 3 && item.category === "Article") {
          contentSlider = item.sections.widget_contents.map((s) => {
            let isImage = s.assets[0] ? (
              <img
                className={classes.image}
                src={s.assets[0].file_big || s.assets[0].file}
                alt={s.assets[0].description}
              />
            ) : (
                <img
                  className={classes.imageDefault}
                  src={require("../assets/default-image.svg")}
                  alt="default image"
                />
              );
            return (
              <div key={s.title} className={classes.bxbtm02h}>
                <div className={classes.bxct02lft}>
                  <div className={classes.bx0vrhdn}>
                    <p className={classes.title}>{s.article_language.title}</p>
                    <p className={classes.paragraph}>
                      {ReactHtmlParser(s.article_language.content)}
                    </p>
                  </div>
                </div>
                <div className={classes.bxct02right}>
                  {isImage}
                  {/* <div className={classes.box_2}>{isImage}</div> */}
                </div>
                {/* <div classes={classes.content}>
                  <div className={classes.contentt}>
                    <div className={classes.boxContainer}>
                      <div className={classes.boxL}>
                        <p className={classes.title}>{s.title}</p>
                        <p className={classes.paragraph}>
                          {ReactHtmlParser(s.content)}
                        </p>
                      </div>
                    </div>
                    <div className={classes.box_2}>{isImage}</div>
                  </div>
                </div> */}
              </div>
            );
          });
        }
      });
    } else if (this.state.isApiLoaded) {
      
    }

    const settings = {
      className: "",
      dots: true,
      slidesToShow: 1,
      swipeToSlide: true,
      autoplay: true,
      infinite: true,
      autoplaySpeed: 5000,
      appendDots: (dots) => (
        <div
          style={{
            backgroundColor: "transparent",
            borderRadius: "10px",
            paddingBottom: "2rem",
          }}
        >
          <ul> {dots} </ul>
        </div>
      ),
      customPaging: (i) => (
        <div
          style={{
            width: "10px",
            height: "10px",
            color: "#d6d6d6",
            border: "1px solid #d6d6d6",
            borderRadius: "50%",
          }}
        ></div>
      ),
    };

    // const settings = {
    //   dots: true,
    //   autoplay: true,
    //   infinite: true,
    //   speed: 800,
    //   autoplaySpeed: 5000,
    //   slidesToShow: 1,
    //   pauseOnHover: true,

    //   appendDots: (dots) => <ul>{dots}</ul>,
    //   customPaging: (i) => (
    //     <div className="ft-slick__dots--custom">
    //       <div className="loading" />
    //     </div>
    //   ),
    // };
    return (
      <div className="wraperr">
        <Particles
          className="partikel"
          options={{
            background: {
              color: {
                value: "#f1f1f1",
              },
            },
            fpsLimit: 60,
            interactivity: {
              detectsOn: "canvas",
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "grab",

                  parallax: {
                    enable: true,
                    force: 100,
                    smooth: 50,
                  },
                },
                resize: true,
              },
              modes: {
                bubble: {
                  distance: 50,
                  duration: 2,
                  opacity: 1,
                  size: 800,
                },
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 200,
                  duration: 1,
                },
              },
            },
            particles: {
              color: {
                value: "#d4d4d4",
              },
              links: {
                color: "#d4d4d4",
                distance: 350,
                enable: true,
                opacity: 0.3,
                width: 2,
              },
              collisions: {
                enable: true,
              },
              move: {
                direction: "none",
                enable: true,
                outMode: "bounce",
                random: false,
                speed: 2,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  value_area: 800,
                },
                value: 80,
              },
              opacity: {
                value: 1,
              },
              shape: {
                type: "circle",
              },
              size: {
                random: true,
                value: 3,
              },
            },
            detectRetina: true,
          }}
        />
        <div className={["container", classes.boxAll].join(" ")}>
          {contentDesc}
          <div className={classes.cs01h} id="slider_1">
            <Slider {...settings}>{contentSlider}</Slider>
            {/* <Row className={classes.csbtm02h}>
              <div className="card-sec1">
                <Slider {...settings}>{contentSlider}</Slider>
              </div>
            </Row> */}
          </div>
        </div>

        {/* <div className="content-section_1">
          <Row className="row_2">
            <div className="card-sec1">
              <Slider {...settings}>{contentSlider}</Slider>
            </div>
          </Row>
        </div> */}
      </div>
    );
  }
}

export default Desktop;
