import React, { Component } from "react";

import UserContext from "../../../Context";
import Api from "../../../../Api";
import classes from "./AccountProfile.module.css";

export default class AccountProfile extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      isPassword: false,
      isPassword1: false,
      isPassword2: false,
      isPassword3: false,
      isPassword1_2: false,
      isMatch: false,
      isErrorPassOld: null,
      isErrorNewPwd: null,
      isErrorConfirm: null,
      data: [],
    };
    this.isValidate = this.isValidate.bind(this);
    this.setValidate = this.setValidate.bind(this);
    this.checkPwd = this.checkPwd.bind(this);
  }

  componentDidMount() {
    const contextValue = this.context;
    this.setState({
      data: contextValue.contentSection[10].sections.widget_contents,
    });
  }

  checkPwd(param) {
    let format = /^[a-zA-Z0-9]{8,}$/;
    if (param.match(format)) {
      return true;
    } else {
      return false;
    }
  }

  isValidate() {
    let a = document.getElementById("current-password").value;
    let b = document.getElementById("new-password").value;
    let c = document.getElementById("confirm-password").value;

    let d = this.setValidate(a, "isPassword1");
    let e = this.setValidate(b, "isPassword2");
    let f = this.setValidate(c, "isPassword3");

    if (!a) {
      this.setState({
        isPassword1: true,
        isErrorPassOld: "*This field required",
      });
    } else {
      if (a.length < 8) {
        this.setState({
          isPassword1: true,
          isErrorPassOld: "Ensure this field has at least 8 characters.",
        });
      } else {
        this.setState({
          isPassword1: false,
        });
      }
    }

    if (!b) {
      this.setState({
        isPassword2: true,
        isErrorNewPwd: "*This field required",
      });
    } else {
      if (b.length < 8) {
        this.setState({
          isPassword2: true,
          isErrorNewPwd: "Ensure this field has at least 8 characters.",
        });
      } else {
        this.setState({
          isPassword2: false,
        });
      }
    }
    if (!c) {
      this.setState({
        isPassword3: true,
        isErrorConfirm: "*This field required",
      });
    } else {
      if (c.length < 8) {
        this.setState({
          isPassword3: true,
          isErrorConfirm: "Ensure this field has at least 8 characters.",
        });
      } else {
        this.setState({
          isPassword3: false,
        });
      }
    }
    if (d && e && f) {
      return true;
    } else {
      return false;
    }
  }

  setValidate(val, value) {
    if (val !== "") {
      return true;
    } else {
      this.setState({
        [value]: true,
      });
      return false;
    }
  }

  updatePassword() {
    const update = new Api();
    const contextValue = this.context;

    if (this.isValidate()) {
      const updateData = {
        current: document.getElementById("current-password").value,
        new: document.getElementById("new-password").value,
        confirm: document.getElementById("confirm-password").value,
      };

      update
        .updatePassword(updateData)
        .then((res) => {
          if (res.data.status === 200) {
            let container, inputs, index;
            container = document.getElementById("content-reset-password");
            inputs = container.getElementsByTagName("input");
            for (index = 0; index < inputs.length; ++index) {
              if (inputs[index].type === "password") inputs[index].value = "";
            }
            // this.setState({
            //   isPassword: false,
            //   isPassword1: false,
            //   isPassword2: false,
            //   isPassword3: false,
            //   isMatch: false,
            // });
            contextValue.showNotification(
              "Your password has been successfully updated."
            );
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data) {
            let notMatch = err.response.data.non_field_errors;

            if (err.response.data.password) {
              let errMessage = err.response.data.password[0];
              let errMessageNumeric = err.response.data.password[1];

              if (
                errMessage === "This password is too common." &&
                errMessageNumeric === "This password is entirely numeric."
              ) {
                this.setState({
                  isPassword2: true,
                  isPassword3: true,
                  isErrorConfirm:
                    "This password is too common, must contain alphabets and numeric.",
                  isErrorNewPwd:
                    "This password is too common, must contain alphabets and numeric.",
                });
              } else if (
                errMessage === "The password is too similar to the username."
              ) {
                this.setState({
                  isPassword2: true,
                  isPassword3: true,
                  isErrorConfirm:
                    "The password is too similar to the username/email.",
                  isErrorNewPwd:
                    "The password is too similar to the username/email.",
                });
              } else {
                this.setState({
                  isPassword2: false,
                  isPassword3: false,
                  isErrorConfirm: null,
                  isErrorNewPwd: null,
                });
              }
            } else if (notMatch) {
              if (notMatch[0] === "Old password is incorrect.") {
                this.setState({
                  isPassword1: true,
                  isErrorPassOld: "Old password is incorrect.",
                });
              } else if (notMatch[0] === "Those passwords do not match.") {
                this.setState({
                  isPassword3: true,
                  isErrorConfirm: "Those passwords do not match.",
                });
              }
            }
          }
        });
    }
  }
  render() {
    let isError = this.state.isPassword1 ? classes.errorVisible : "";
    let isError1 = this.state.isPassword2 ? classes.errorVisible : "";
    let isError2 = this.state.isPassword3 ? classes.errorVisible : "";
    let isError_1 = this.state.isPassword1_2 ? classes.errorVisible : "";

    let current_pwd, new_pwd, confirm_pwd, save_pwd;

    this.state.data.forEach((ele, i) => {
      switch (i) {
        case 0:
          current_pwd = ele.content;
          break;
        case 1:
          new_pwd = ele.content;
          break;
        case 2:
          confirm_pwd = ele.content;
          break;
        case 3:
          save_pwd = ele.content;
          break;
        default:
      }
    });

    return (
      <div className={classes.accountProfile}>
        <div id="content-reset-password" className={classes.contentProfile}>
          <div className={classes.boxLeft}>
            <div className={classes.boxContent}>
              <span className={classes.titleContent}>{current_pwd}</span>
              <input
                id="current-password"
                className={classes.inputContent}
                type={"password"}
                defaultValue={""}
              />
            </div>

            <span
              className={[classes.contentError, isError, isError_1].join(" ")}
            >
              {this.state.isErrorPassOld}
              {/* This field is required */}
            </span>
            {/* <span className={[classes.contentError, isError0].join(" ")}>
              Old password is incorrect
            </span> */}
          </div>
          <div className={classes.boxRight}>
            <>
              <div className={classes.boxContent}>
                <span className={classes.titleContent}>{new_pwd}</span>
                <input
                  id="new-password"
                  className={classes.inputContent}
                  type={"password"}
                  defaultValue={""}
                />
              </div>
              <span className={[classes.contentError, isError1].join(" ")}>
                {this.state.isErrorNewPwd}
                {/* This field is required */}
              </span>
            </>
            <>
              <div className={classes.boxContent}>
                <span className={classes.titleContent}>{confirm_pwd}</span>
                <input
                  id="confirm-password"
                  className={classes.inputContent}
                  type={"password"}
                  defaultValue={""}
                />
              </div>
              <span className={[classes.contentError, isError2].join(" ")}>
                {this.state.isErrorConfirm}
                {/* This field is required */}
              </span>
              {/* <span className={[classes.contentError, isErrorX].join(" ")}>
                Those passwords do not match
              </span> */}
            </>
          </div>
        </div>
        <div
          className={classes.boxAction}
          onClick={() => {
            this.updatePassword();
          }}
        >
          <span>{save_pwd}</span>
        </div>
      </div>
    );
  }
}
