import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import clases from "./Navbar.module.css";

export default function Navbar(props) {
  const [searchBoxField, setSearchBoxField] = useState('');

  useEffect(() => {
    setSearchBoxField(props.searchHere)
  }, [props.searchHere]);

  // const scrollY = useScrollPosition(60);
  let nav, sticky, filters, backBtn, submitBtn;

  if (props.category) {
    filters = props.category.map((f) => {
      if (f.id !== 14) {
        return <option value={f.id}>{f.category}</option>;
      }
    });
  }

  backBtn =
    window.screen.width <= 480 ? (
      <div className={clases.backBtn}>
        <FontAwesomeIcon className={clases.backIcon} icon={faArrowLeft} />
        <span>Back</span>
      </div>
    ) : (
      <></>
    );

  submitBtn =
    window.screen.width <= 480 ? (
      <div
        onClick={() => props.getSearchedDataByClick(searchBoxField)}
        className={clases.submitBtn}
      >
        <FontAwesomeIcon className={clases.submitIcon} icon={faArrowRight} />
      </div>
    ) : (
      <></>
    );

  sticky = "";
  nav = (
    <>
      {/* <p className={clases.navTitle}>{props.title}</p> */}
      {backBtn}
      {submitBtn}
      <input
        onChange={(e) => setSearchBoxField(e.target.value)}
        onKeyDown={props.onKeyDown}
        className={clases.searchHere}
        type="text"
        value={searchBoxField}
        placeholder="Search here"
      />
      <p className={clases.placeholder}>
        {props.textSearch
          ? props.textSearch
          : "type article name, news, events, blog"}
      </p>
      <div className={[clases.boxNavbar].join(" ")}>
        <select name="filter" id="filter" onChange={props.onChange}>
          <option value="">
            {props.textFilter ? props.textFilter : "All"}
          </option>
          {filters}
        </select>
        <p className={clases.contentTotal}>
          {props.total ?
            <>
              <span className={clases.total}>
                {props.total ? props.total : "no"}
              </span>{" "}
              {props.textFound}
            </>
            :
            props.textError
          }

        </p>
        {/* <p className={clases.date}><strong>Monday,</strong> September 7, 2020 | 10:09 (GMT + 7)</p> */}
        {/* <div className={clases.boxSearch}>
                    <input onKeyDown={props.onKeyDown} className={clases.inputSearch} type='text' placeholder={props.searchHere} />
                </div> */}
      </div>
    </>
  );

  return (
    <div className={[clases.customNavbar, sticky].join(" ")}>
      <div className={clases.navbar}>{nav}</div>
    </div>
  );
}
