import React from "react";
import { isMobile, isTablet } from "react-device-detect";
import { useMediaQuery } from "react-responsive";

import Desktop from "./Desktop/Desktop";
import Mobile from "./Mobile/Mobile";

export default function AU_Section1({ updateLogo, override }) {
  let content;
  const isM = useMediaQuery({
    query: "(min-device-width: 411px) and (max-device-width: 479px)",
  });
  if (isTablet) {
    content = <Desktop updateLogo={updateLogo} override={override} />;
  } else if (isMobile || isM) {
    content = <Mobile updateLogo={updateLogo} override={override} />;
  } else {
    content = <Desktop updateLogo={updateLogo} override={override} />;
  }
  return <>{content}</>;
}
