import React, { Component } from "react";
import { Card, Row, Modal } from "react-bootstrap";
import Slider from "react-slick";
import ReactHtmlParser from "react-html-parser";
import Api from "../Api";
import Letter from "./Letter";
import classes from "./Mobile.module.css";
import "../teams.css";

class Mobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: null,
      sections: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
      isShowing: false,
      isClose: false,
      closeModal: false,
      detailLoaded: false,
      detailChart: false,
      sectionName: "",
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
    this.setCurrentLink = this.setCurrentLink.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  toggleShowing = () => {
    this.setState({
      isShowing: true,
    });
  };

  isClose = () => {
    this.setState({
      isShowing: false,
    });
  };

  getHtmlParagraph(str) {
    if (str && str !== "") {
      return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ");
    }
  }

  toggleShowing = (param) => {
    if (param === "chart") {
      this.setState({ detailChart: true });
    } else {
      this.setState({ detailLoaded: true });
    }
  };
  toggleHiding = (param) => {
    if (param === "chart") {
      this.setState({ detailChart: false });
    } else {
      this.setState({ detailLoaded: false });
    }
  };

  getSectionDetail() {
    const apiData = new Api();
    this.setState(
      {
        sections: [],
      },
      () =>
        apiData
          .getSectionDetail()
          .then((result) => {
            this.setState({
              isApiLoaded: true,
            });
            const sections = result.data;
            const widget = [];

            let sectionName = sections.name.toLowerCase().replace(/\s+/g, "-");
            result.data.widgets.forEach((item) => {
              widget.push({
                id: item.id,
                index: item.index,
                category: item.category,
                name: item.name,
                sections: item,
              });
            });
            sections.page = widget;
            this.setState({
              sections: widget,
              sectionName: sectionName,
              isLoaded: true,
            });
          })
          .catch((error) => {
            this.setState({
              isApiLoaded: true,
            });
          })
    );
  }

  setCurrentLink() {
    localStorage.setItem(
      "prevLink",
      `${window.location.href}${
        this.state.sectionName ? `/#${this.state.sectionName}` : ""
      }`
    );
  }

  render() {
    const settings = {
      className: "",
      dots: true,
      infinite: false,
      centerPadding: "16px",
      slidesToShow: 1,
      swipeToSlide: true,
    };

    const { sections } = this.state;

    let images,
      titleP,
      imageChart,
      contentArticle,
      contentLetter,
      signatureTitle,
      signatureName,
      articleBoxes,
      readText;

    if (this.state.isLoaded) {
      let count = 0;
      let titleSection = null;
      sections.forEach((item) => {
        count = count + 1;

        if (count === 1 && item.index === 1 && item.category === "Media") {
          item.sections.widget_contents.forEach((s) => {
            if (s.file !== "") {
              images = (
                <img className={classes.img_first} src={s.file_small} alt="" />
              );
            } else {
              images = <></>;
            }
          });
        } 

        if (count === 2 && item.index === 2 && item.category === "Text") {
          readText = item.sections.widget_contents[0]
            ? item.sections.widget_contents[0].content
            : "Click More";
        }

        if (count === 3 && item.index === 3 && item.category === "Article") {
          contentArticle = item.sections.widget_contents.map((s) => {
            titleP = <h2>{s.article_language.title}</h2>;

            let lang = localStorage.getItem("language");
            let quoteClass =
              lang === "English"
                ? [classes.quote, classes.quoteEn].join(" ")
                : classes.quote;
            let contents = ReactHtmlParser(
              this.getHtmlParagraph(s.article_language.content)
            );
            return (
              <div className={classes.col_second}>
                <span className={quoteClass}>“</span>
                {titleP}
                <p>{contents}</p>
                {/* </div> */}
                <div className={classes.boxLinks2}>
                  <div className={classes.outerCircle2}>
                    <div className={classes.innerCircle2}></div>
                  </div>

                  <p className={classes.readMore} onClick={this.toggleShowing}>
                    {readText}
                  </p>
                </div>
              </div>
            );
          });
        }

        if (count === 4 && item.index === 4 && item.category === "Text") {
          // let url = item.sections.url ? item.sections.url : "";
          item.sections.widget_contents.forEach((ele) => {
            if (ele.content !== "") {
              // name = (
              //   <Link to={url}>
              //     <Card.Text
              //       onClick={() => this.toggleShowing("chart")}
              //       className={[classes.card_text_row_1_col_2].join(" ")}
              //     >
              //       {ele.content}
              //     </Card.Text>
              //     <p
              //       onClick={() => this.toggleShowing("chart")}
              //       className={classes.cardTextView}
              //     >
              //       Click to view
              //     </p>
              //   </Link>
              // );
            }
          });
        }

        if (count === 5 && item.index === 5 && item.category === "Media") {
          item.sections.widget_contents.map((s) => {
            imageChart = (
              <Card.Img className={classes.image_zoom} src={s.file} />
            );
            return <>{imageChart}</>;
          });
        }

        if (count === 6 && item.index === 6 && item.category === "Text") {
          signatureTitle = item.sections.widget_contents[0]
            ? item.sections.widget_contents[0].content
            : "";
          signatureName = item.sections.widget_contents[1]
            ? item.sections.widget_contents[1].content
            : "";
        }

        if (count === 7 && item.index === 7 && item.category === "Article") {
          item.sections.widget_contents.forEach((s) => {
            contentLetter = (
              <Letter
                isData={true}
                title={titleSection}
                signatureTitle={signatureTitle}
                signatureName={signatureName}
                data={s}
                onClick={this.toggleHiding}
                onStyle={"in"}
              />
            );
          });
        }

        if (count === 8 && item.index === 8 && item.category === "Article") {
          articleBoxes = item.sections.widget_contents.map((article, index) => {
            return (
              <div className={classes.box_1}>
                <div className={classes.box_hover}>
                  <img
                    className={classes.img_box}
                    src={
                      article.assets.length > 0
                        ? article.assets[0].file_small
                        : require("../assets/1.jpg")
                    }
                    alt={
                      article.assets.length > 0
                        ? article.assets[0].description
                        : "Default image"
                    }
                  />
                  <div
                    className={[classes._overlay, classes._absolute].join(" ")}
                  ></div>
                  <div className={classes._absolute}>
                    <div className={classes._content}>
                      <div className={classes._box}>
                        <h2>{article.article_language.title} </h2>
                        <div className={classes.contentBottomTitle}>
                          <div className={classes.outerCircle}>
                            <div className={classes.innerCircle}></div>
                          </div>

                          <span className={classes.readMore2}>
                            <a
                              onClick={() => this.setCurrentLink()}
                              href={article.article_language.url}
                            >
                              {readText}
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          });
        }
      });
    } else if (this.state.isApiLoaded) {

    }

    return (
      <>
        <Row>
          <div className={classes.backgroundRowFirst}>
            <div
              className={[
                "container",
                classes.row_first,
                classes.customContainer,
              ].join(" ")}
            >
              {/* <div className={classes.Circuit}>
                <Circuit />
              </div> */}
              <div className={classes._flexDir}>
                <div className={classes.boxPhoto}>{images}</div>
                <div className={classes._boxB}>{contentArticle}</div>
              </div>

              <Modal
                dialogClassName={classes.modalFull}
                show={this.state.detailLoaded}
                onHide={this.toggleHiding}
                id="modal_section2"
              >
                <Modal.Header
                  className={classes.modalHeader}
                  onClick={this.toggleHiding}
                  closeButton
                />
                <div className={classes.boxModal}>{contentLetter}</div>
              </Modal>
            </div>
          </div>
        </Row>
        <Row className={classes.Row_2}>
          <div
            className={[
              "container",
              classes.box_c,
              classes.customContainer,
              "AuSec2",
            ].join(" ")}
          >
            {window.screen.width <= 835 ? (
              <Slider {...settings}>{articleBoxes}</Slider>
            ) : (
              <div className={classes.flexContentRow}>{articleBoxes}</div>
            )}
          </div>
        </Row>
      </>
    );
  }
}

export default Mobile;
