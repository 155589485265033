import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import Icon from '../Icon'

import './xChart.css'
import classes from './xChart.module.css'
import Api from '../Api'

export default class Desktop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sections: {
                widgets: []
            },
            menus: {
                items: []
            },
            backButton: "",
            isLoaded: false
        };
        this.getMenu = this
            .getMenu
            .bind(this);
        this.getSectionDetail = this
            .getSectionDetail
            .bind(this)
        this.recursiveTreeParserFunction = this
            .recursiveTreeParserFunction
            .bind(this);

        this.checkUrlTarget = this
            .checkUrlTarget
            .bind(this);
        this.getUrlIfThereIsURL = this
            .getUrlIfThereIsURL
            .bind(this);

    }

    componentDidMount() {
        this.getMenu();
        this.getSectionDetail()
    }

    getMenu() {
        const apiData = new Api();
        this.setState({
            menus: []
        }, () => {
            apiData
                .getMenuDetail()
                .then(result => {

                    const menu = result.data
                    const item = []

                    menu
                        .items
                        .forEach((i) => {
                            item.push({ index: i.index, id: i.id, name: i.title, url: i.url, children: i.children })
                        })
                    menu.items = item
                    this.setState({ menus: menu, isLoaded: true })
                })
                .catch(error => {

                });
        })
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({
            sections: []
        }, () => {
            apiData
                .getSectionDetail()
                .then(result => {

                    this.setState({
                        sections: result.data
                    }, () => {
                        this.setState({
                            backButton: this.state.sections.widgets[1]
                                ?.widget_contents[0]
                                ?.content
                                ? this.state.sections.widgets[1]
                                    ?.widget_contents[0]
                                    ?.content
                                : "Quay lại trang trước"
                        })
                    })

                })
                .catch(error => {
                    this.setState({ isApiLoaded: true })
                });
        })
    }

    checkUrlTarget(url) {
        if (url === "") {
            return ""
        }
        var substring = url.substr(0, 4);
        if (substring === "http") {
            return "external";
        } else {
            return "internal";
        }
    }

    recursiveTreeParserFunction(items, elements) {

        items.forEach((item) => {
            if (item.children) {
                this.recursiveTreeParserFunction(item.children, elements)
                var name = item.name
                    ? item.name
                    : item.title;
                elements.push([name, item.url])
            } else {
                elements.push([item.title, item.url])
            }

        })

    }

    getUrlIfThereIsURL(elementArray) {
        // console.log('elementArray', elementArray)
         switch (this.checkUrlTarget(elementArray[1])) {
            case "internal":
                return (
                    <Link className={classes.customLink} to={elementArray[1]}>{(elementArray[0])}</Link>
                )
            case "external":
                return (
                    <a
                        className={classes.customLink}
                        href={elementArray[1]}
                        target="_blank"
                        rel="noopener noreferrer">{elementArray[0]}</a>
                )
            default:
                return (<> {
                    (elementArray[0])
                } </>)
        }
    }

    toTitleCase(str) {
        return str.replace(
            /\w\S * /g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }


    render() {
        let elements = [];
        let icon = null
        if (this.state.isLoaded) {
            this.recursiveTreeParserFunction(this.state.menus.items, elements)
            icon = <Icon />
        }

        let prevLink = localStorage.getItem('prevLink')
        let lang = localStorage.getItem('language')
        
        let leftx1title
        if (window.screen.width <= 1600){
            leftx1title = (lang === 'English' ? 'left-x1-title left-x1En-title' : 'left-x1-title') 
        } else {
            leftx1title = 'left-x1-title' 
        }

        console.log(elements)

        return (
            <div className={classes.charts}>
                <div className={classes.customContainer}>
                    <div className={classes.chart}>
                        {icon}
                        <div className={classes.chartL}>
                            <div className='left-1 v-line'></div>
                            <div className='left-2 h-line'></div>
                            <div className={[`${leftx1title} title-chart`].join()}>
                                {elements[10]
                                    ? (elements[10] ? this.getUrlIfThereIsURL(elements[10]) : "")
                                    : "SAN XUAT CONG NGHIEP"}
                            </div>

                            <div className='left-21 h-line'></div>
                            <div className='left-22 v-line'></div>
                            <div className='left-x2-title --subtitle-chart'>
                                {elements[4]
                                    ? (elements[4] ? this.getUrlIfThereIsURL(elements[4]) : "")
                                    : "Vat lieu moi cao cap"}
                            </div>
                            <div className='left-23 h-line'></div>
                            <div className='left-x3-content --chart-content chart-content-animate'>
                                {elements[0]
                                    ? (elements[0] ? this.getUrlIfThereIsURL(elements[0]) : "")
                                    : "Hoa chat"}
                            </div>
                            <div className='left-24 v-line'></div>
                            <div className='left-25 h-line'></div>
                            <div className='left-x4-content --chart-content chart-content-animate'>
                                {elements[1]
                                    ? (elements[1] ? this.getUrlIfThereIsURL(elements[1]) : "")
                                    : "Da Thach anh"}
                            </div>
                            <div className='left-26 h-line'></div>
                            <div className='left-x5-content --chart-content chart-content-animate'>
                                {elements[2]
                                    ? (elements[2] ? this.getUrlIfThereIsURL(elements[2]) : "")
                                    : "Cristobalite"}
                            </div>
                            <div className='left-27 h-line'></div>
                            <div className='left-x6-content --chart-content chart-content-animate'>
                                {elements[3]
                                    ? (elements[3] ? this.getUrlIfThereIsURL(elements[3]) : "")
                                    : "Vat lieu tien tien"}
                            </div>

                            {/* {item 2} */}
                            <div className='left-x3-title --subtitle-chart'>
                                {elements[9]
                                    ? (elements[9] ? this.getUrlIfThereIsURL(elements[9]) : "")
                                    : "Dien - Dien tu Thiet bi thong minh"}
                            </div>
                            <div className='left-28 v-line'></div>
                            <div className='left-29 h-line'></div>
                            <div className='left-x7-content --chart-content chart-content-animate'>
                                {elements[5]
                                    ? (elements[5] ? this.getUrlIfThereIsURL(elements[5]) : "")
                                    : "San pham chieu sang thong minh"}
                            </div>
                            <div className='left-30 h-line'></div>
                            <div className='left-x8-content --chart-content chart-content-animate'>
                                {elements[6]
                                    ? (elements[6] ? this.getUrlIfThereIsURL(elements[6]) : "")
                                    : "Nha thong minh"}
                            </div>
                            <div className='left-31 h-line'></div>
                            <div className='left-x9-content --chart-content chart-content-animate'>
                                {elements[7]
                                    ? (elements[7] ? this.getUrlIfThereIsURL(elements[7]) : "")
                                    : "Thiet bi thong minh"}
                            </div>
                            <div className='left-32 h-line'></div>
                            <div className='left-x10-content --chart-content chart-content-animate'>
                                {elements[8]
                                    ? (elements[8] ? this.getUrlIfThereIsURL(elements[8]) : "")
                                    : "Robot thong minh"}
                            </div>

                            {/* {item 3} */}
                            <div className='left-3 h-line'></div>
                            <div className='left-x4-title title-chart'>
                                {elements[18]
                                    ? (elements[21] ? this.getUrlIfThereIsURL(elements[21]) : "")
                                    : "CHONG NGHE"}
                            </div>
                            <div className='left-33 v-line'></div>
                            <div className='left-34 h-line'></div>
                            <div className='left-35 v-line'></div>
                            <div className='left-x11-content --chart-content chart-content-animate'>
                                {elements[11]
                                    ? (elements[11] ? this.getUrlIfThereIsURL(elements[11]) : "")
                                    : "Giải pháp ứng dụng cho thành phố thông minh"}
                            </div>
                            <div className='left-36 v-line'></div>
                            <div className='left-x12-content --chart-content chart-content-animate'>
                                {elements[12]
                                    ? (elements[12] ? this.getUrlIfThereIsURL(elements[12]) : "")
                                    : "Hệ sinh thái mở Phenikaa iCloud"}
                            </div>
                            <div className='left-37 v-line'></div>
                            <div className='left-x13-content --chart-content chart-content-animate'>
                                {elements[13]
                                    ? (elements[13] ? this.getUrlIfThereIsURL(elements[13]) : "")
                                    : "Thiết bị IoT thông minh"}
                            </div>
                            <div className='left-38 v-line'></div>
                            <div
                                className='left-x14-content --chart-content --chart-content chart-content-animate'>
                                {elements[14]
                                    ? (elements[14] ? this.getUrlIfThereIsURL(elements[14]) : "")
                                    : "Công nghệ tự hành"}
                            </div>
                            <div className='left-39 v-line'></div>
                            <div className='left-x15-content --chart-content chart-content-animate'>
                                {elements[15]
                                    ? (elements[15] ? this.getUrlIfThereIsURL(elements[15]) : "")
                                    : "Trí tuệ nhân tạo"}
                            </div>
                            <div className='left-40 v-line'></div>
                            <div className='left-x16-content --chart-content chart-content-animate'>
                                {elements[16]
                                    ? (elements[16] ? this.getUrlIfThereIsURL(elements[16]) : "")
                                    : "Xử lý hình ảnh"}
                            </div>
                            <div className='left-41 v-line'></div>
                            <div className='left-x17-content --chart-content chart-content-animate'>
                                {elements[17]
                                    ? (elements[17] ? this.getUrlIfThereIsURL(elements[17]) : "")
                                    : "Xử lý giọng nói"}
                            </div>
                            {/* New */}
                            <div className='left-50 v-line'></div>
                            <div className='left-x20-content --chart-content chart-content-animate'>
                                {elements[18]
                                    ? (elements[18] ? this.getUrlIfThereIsURL(elements[18]) : "")
                                    : "Công nghệ Giáo dục"}
                            </div>
                            <div className='left-51 v-line'></div>
                            <div className='left-x21-content --chart-content chart-content-animate'>
                                {elements[19]
                                    ? (elements[19] ? this.getUrlIfThereIsURL(elements[19]) : "")
                                    : "Phần mềm, giải pháp phần mềm mô phỏng kỹ thuật, công nghệ"}
                            </div>
                            <div className='left-52 v-line'></div>
                            <div className='left-x22-content --chart-content chart-content-animate'>
                                {elements[20]
                                    ? (elements[20] ? this.getUrlIfThereIsURL(elements[20]) : "")
                                    : "Chuyển giao Công nghệ"}
                            </div>

                            {/* {item 4} */}
                            <div className='left-42 h-line'></div>
                            <div className='left-x5-title title-chart'>
                                {elements[23]
                                    ? (elements[26] ? this.getUrlIfThereIsURL(elements[26]) : "")
                                    : "GIAO DUC"}
                            </div>
                            <div className='left-43 h-line'></div>
                            <div className='left-x6-title --subtitle-chart'>
                                {elements[19]
                                    ? (elements[22] ? this.getUrlIfThereIsURL(elements[22]) : "")
                                    : "Dai hoc"}
                            </div>
                            <div className='left-44 v-line'></div>
                            <div className='left-x7-title --subtitle-chart'>
                                {elements[22]
                                    ? (elements[25] ? this.getUrlIfThereIsURL(elements[25]) : "")
                                    : "Pho thong"}
                            </div>
                            <div className='left-45 v-line'></div>
                            <div className='left-46 h-line'></div>
                            <div className='left-47 v-line'></div>
                            <div className='left-x18-content --chart-content chart-content-animate'>
                                {elements[20]
                                    ? (elements[23] ? this.getUrlIfThereIsURL(elements[23]) : "")
                                    : "Xử lý giọng nói"}
                            </div>
                            <div className='left-48 v-line'></div>
                            <div className='left-x19-content --chart-content chart-content-animate'>
                                {elements[21]
                                    ? (elements[24] ? this.getUrlIfThereIsURL(elements[24]) : "")
                                    : "Xử lý giọng nói"}
                            </div>
                        </div>
                        <div className={classes.chartR}>
                            <div className='r-1 v-line'></div>

                            {/* {item 5 R} */}
                            <div className='r-2 h-line'></div>
                            <div className='r-y1-title title-chart'>
                                {elements[36]
                                    ? (elements[37] ? this.getUrlIfThereIsURL(elements[37]) : "")
                                    : "NGHIEN CU U"}
                            </div>

                            <div className={classes.customContentRight}>
                                <div className='r-3 h-line'></div>
                                <div className='r-4 v-line'></div>

                                <div className='r-5 h-line'></div>
                                <div className='r-y1-content --chart-content chart-content-animate'>
                                    {elements[24]
                                        ? (elements[27] ? this.getUrlIfThereIsURL(elements[27]) : "")
                                        : "Trung tâm chiếu sáng"}
                                </div>
                                <div className='r-6 h-line'></div>
                                <div className='r-y2-content --chart-content chart-content-animate'>
                                    {elements[25]
                                        ? (elements[28] ? this.getUrlIfThereIsURL(elements[28]) : "")
                                        : "TT thiết kế kiểu dáng công nghiệp"}
                                </div>

                                <div className='r-7 h-line'></div>
                                <div className='r-y3-content --chart-content chart-content-animate'>
                                    {elements[26]
                                        ? (elements[29] ? this.getUrlIfThereIsURL(elements[29]) : "")
                                        : "TT IoT & AI"}
                                </div>
                                <div className='r-8 h-line'></div>
                                <div className='r-y4-content --chart-content chart-content-animate'>
                                    {elements[27]
                                        ? (elements[30] ? this.getUrlIfThereIsURL(elements[30]) : "")
                                        : "Công nghệ vật liệu (Polymer, Ceramic, Nano)"}
                                </div>

                                <div className='r-9 h-line'></div>
                                <div className='r-y5-content --chart-content chart-content-animate'>
                                    {elements[28]
                                        ? (elements[31] ? this.getUrlIfThereIsURL(elements[31]) : "")
                                        : "TT thiết kế vi mạch"}
                                </div>
                                <div className='r-10 h-line'></div>
                                <div className='r-y6-content --chart-content chart-content-animate'>
                                    {elements[29]
                                        ? (elements[32] ? this.getUrlIfThereIsURL(elements[32]) : "")
                                        : "Công nghệ in 3D"}
                                </div>

                                <div className='r-11 h-line'></div>
                                <div className='r-y7-content --chart-content chart-content-animate'>
                                    {elements[30]
                                        ? (elements[33] ? this.getUrlIfThereIsURL(elements[33]) : "")
                                        : "Khoa học Y – Dược"}
                                </div>
                                <div className='r-12 h-line'></div>
                                <div className='r-y8-content --chart-content chart-content-animate'>
                                    {elements[31]
                                        ? (elements[34] ? this.getUrlIfThereIsURL(elements[34]) : "")
                                        : "Nông nghiệp công nghệ cao"}
                                </div>

                                <div className='r-13 h-line'></div>
                                <div className='r-y9-content --chart-content chart-content-animate'>
                                    {elements[32]
                                        ? (elements[35] ? this.getUrlIfThereIsURL(elements[35]) : "")
                                        : "ĐIện tử, Điện tử hữu cơ"}
                                </div>
                                <div className='r-14 h-line'></div>
                                <div className='r-y10-content --chart-content chart-content-animate'>
                                    {elements[33]
                                        ? (elements[36] ? this.getUrlIfThereIsURL(elements[36]) : "")
                                        : "Cơ Điện tử, Tự động hóa"}
                                </div>

                                {/* <div className='r-15 h-line'></div>
                                <div className='r-y11-content --chart-content chart-content-animate'>
                                    {elements[34]
                                        ? (elements[37] ? this.getUrlIfThereIsURL(elements[37]) : "")
                                        : "TT nghiên cứu xe tự hành"}
                                </div>
                                <div className='r-16 h-line'></div>
                                <div className='r-y12-content --chart-content chart-content-animate'>
                                    {elements[35]
                                        ? (elements[38] ? this.getUrlIfThereIsURL(elements[38]) : "")
                                        : "TT nghiên cứu trí tuệ nhân tạo"}
                                </div> */}
                            </div>

                            {/* {item 6 R} */}
                            <div className='r-17 h-line'></div>
                            <div className='r-y2-title title-chart'>
                                {elements[37]
                                    ? (elements[38] ? this.getUrlIfThereIsURL(elements[38]) : "")
                                    : "QUỸ ĐỔI MỚI SÁNG TẠO"}
                            </div>

                            {/* {item 7 R} */}
                            <div className='r-18 h-line'></div>
                            <div className='r-y3-title title-chart'>
                                {elements[44]
                                    ? (elements[45] ? this.getUrlIfThereIsURL(elements[45]) : "")
                                    : "THƯƠNG MẠI"}
                            </div>
                            <div className='r-19 h-line'></div>
                            <div className='r-y4-title --subtitle-chart'>
                                {elements[38]
                                    ? (elements[39] ? this.getUrlIfThereIsURL(elements[39]) : "")
                                    : "Việt Nam"}
                            </div>
                            <div className='r-20 v-line'></div>
                            <div className='r-y5-title --subtitle-chart'>
                                {elements[43]
                                    ? (elements[44] ? this.getUrlIfThereIsURL(elements[44]) : "")
                                    : "Quốc tế"}
                            </div>

                            <div className='r-21 h-line'></div>
                            <div className='r-22 v-line'></div>
                            <div className='r-y13-content --chart-content chart-content-animate'>
                                {elements[39]
                                    ? (elements[40] ? this.getUrlIfThereIsURL(elements[40]) : "")
                                    : "Công ty Vicostone US (Mỹ)"}
                            </div>
                            <div className='r-23 h-line'></div>
                            <div className='r-y14-content --chart-content chart-content-animate'>
                                {elements[40]
                                    ? (elements[41] ? this.getUrlIfThereIsURL(elements[41]) : "")
                                    : "Công ty Vicostone Canada"}
                            </div>
                            <div className='r-24 h-line'></div>
                            <div className='r-y15-content --chart-content chart-content-animate'>
                                {elements[41]
                                    ? (elements[42] ? this.getUrlIfThereIsURL(elements[42]) : "")
                                    : "Hệ thống phân phối tại Châu Âu"}
                            </div>
                            <div className='r-25 h-line'></div>
                            <div className='r-y16-content --chart-content chart-content-animate'>
                                {elements[42]
                                    ? (elements[43] ? this.getUrlIfThereIsURL(elements[43]) : "")
                                    : "Hệ thống phân phối tại Úc"}
                            </div>

                        </div>
                    </div>
                </div>
                <div className={["container", classes.customContainerC].join(' ')}>
                    <div className={classes.navBack}>
                        {/* <Link className={classes.customLink} to={'/about-us'}> */}
                        <a className={classes.customLink} href={prevLink && prevLink !== "" ? prevLink : '/about-us'}>
                            <div className={'d-flex'}>
                                <div className={'d-flex'}>
                                    <i className={[classes.arrow, classes.left, classes.customMargin].join(" ")}></i>
                                </div>

                                <span className={classes.textBack}>{this.state.backButton}</span>
                            </div>
                        </a>
                    </div>
                </div>

            </div>
        )
    }
}
