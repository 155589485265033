import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import Api from "../Api";
import Slider from "../Slider";
import classes from "./DesktopSec1.module.css";

export default class Desktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentSection: [],
      isLoaded: false,
      isApiLoaded: false,
    };
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail = () => {
    const apiData = new Api();
    apiData
      .getSectionDetail()
      .then((result) => {
        const sections = result.data;
        const widget = [];
        sections.widgets.forEach((item) => {
          widget.push({
            id: item.id,
            index: item.index,
            category: item.category,
            name: item.name,
            sections: item,
          });
        });

        this.setState({
          contentSection: widget,
          isLoaded: true,
        });
      })
      .catch((error) => {
        this.setState({
          isApiLoaded: true,
        });
        window.location.assign(
          `/error/${error.response.status ? error.response.status : 404}`
        );
      });
  };

  render() {
    const { contentSection } = this.state;
    let title1, content, title2, cards, swiperCard;
    if (this.state.isLoaded) {
      contentSection.forEach((el) => {
        if (el.index === 1 && el.category === "Text") {
          title1 = el.sections.widget_contents[0].content;
        } else if (el.index === 2 && el.category === "Article") {
          content = el.sections.widget_contents[0].article_language.content;
        } else if (el.index === 3 && el.category === "Text") {
          title2 = el.sections.widget_contents[0].content;
        } else if (el.index === 4 && el.category === "Article") {
          cards = el.sections.widget_contents.map((e, i) => {
            // let cardClass = i === 0 ? [classes.card, classes.marginLeft].join(" ") : classes.card;
            let cardClass = classes.card;
            return (
              <div className={cardClass}>
                <div className={classes.cardTitle}>{e.article_language.title}</div>
                <div className={classes.cardContent}>
                  {ReactHtmlParser(e.article_language.content)}
                </div>
              </div>
            );
          });
        }
      });

      swiperCard =
        window.innerWidth < 1400 ? (
          <Slider>{cards}</Slider>
        ) : (
          <div className={classes.cardContainer}>{cards}</div>
        );
    }

    return (
      <div className={classes.section}>
        <div className={["container", classes.customContainer].join(" ")}>
          <h2 className={classes.title}>{title1}</h2>
          <p className={classes.content}>{ReactHtmlParser(content)}</p>
          <h3 className={classes.subTitle}>{title2}</h3>
          <div className={classes.cardWrap}>{cards}</div>
        </div>
        {/* {swiperCard} */}
      </div>
    );
  }
}
