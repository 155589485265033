import React, { Component } from "react";
import Table from "./Table/Table";
import Api from "./Api";
import classes from "./stakeholders.module.css";

class SD_Stakeholders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: <div></div>,

      data: [
        {
          id: 1,
          title: "Workers",
        },
        {
          id: 2,
          title: "Customer",
        },
        {
          id: 3,
          title: "Student, parents",
        },
        {
          id: 4,
          title: "Partners and suppliers",
        },
        {
          id: 5,
          title: "Social community",
        },
        {
          id: 6,
          title: "House Agencies",
        },
        {
          id: 7,
          title: "Country",
        },
        {
          id: 8,
          title: "Shareholders and Investors",
        },
      ],
      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
      activeSidebar: 0,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  changeActiveSidebar(key) {
    this.setState({ activeSidebar: key });
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState(
      {
        contentSection: [],
        contentBox1: [],
        contentBox2: [],
      },
      () => {
        apiData
          .getSectionDetail()
          .then((result) => {
            const sections = result.data;
            const widget = [];

            result.data.widgets.forEach((item) => {
              if (item.category === "Article") {
                switch (item.index) {
                  case 4:
                    this.setState({ contentBox1: item.widget_contents });
                    break;
                  case 5:
                    this.setState({ contentBox2: item.widget_contents });
                    break;
                  default:
                    break;
                }
              }
              widget.push({
                id: item.id,
                index: item.index,
                category: item.category,
                name: item.name,
                url: item.is_internal,
                titleStake: sections.widgets[2].widget_contents[0].content,
                sections: item,
              });
            });

            sections.page = widget;
            this.setState({ contentSection: widget, isLoaded: true });
          })
          .catch((error) => {
            this.setState({ isApiLoaded: true });
          });
      }
    );
  }
  render() {
    const { contentSection } = this.state;
    let title,
      desc,
      contentTable,
      link = null;

    if (this.state.isLoaded) {
      let columnTable = [];
      contentSection.forEach((item, i) => {
        switch (i) {
          case 0:
            title = item.sections.widget_contents.map((s) => {
              return (
                <h1 className={classes.title}>{s.content ? s.content : ""}</h1>
              );
            });
            break;
          case 1:
            desc = item.sections.widget_contents.map((s) => {
              return (
                <p className={classes.desc}>{s.content ? s.content : ""}</p>
              );
            });
            break;
          case 2:
            break;
          case 3:
            item.sections.widget_contents.forEach((s) => {
              s.content ? columnTable.push(s.content) : columnTable.push("");
            });
            break;
          case 4:
            contentTable = <Table id={item.id} column={columnTable} />;
            break;
          case 5:
            link = item.sections.widget_contents.map((s) => {
              return (
                <div className={classes.navBack}>
                  {/* <a href={item.sections.url} className={classes.backLink}>
                    <FontAwesomeIcon className={classes.backIcon} icon={faArrowLeft} />
                    <div>{s.content}</div>
                  </a> */}
                  <a href={item.sections.url} className={classes.customLink}>
                    <div className={"d-flex"}>
                      <div className={"d-flex"}>
                        <i
                          className={[
                            classes.arrow,
                            classes.left,
                            classes.customMargin,
                          ].join(" ")}
                        ></i>
                      </div>
                      <span className={classes.textBack}>{s.content}</span>
                    </div>
                  </a>
                </div>
              );
            });
            break;
          default:
            break;
        }
      });
    }

    return (
      <div className={classes.contentSection}>
        <div className={["container", classes.customContainer].join(" ")}>
          {title}
          {desc}
          {contentTable}
          {link}
        </div>
      </div>
    );
  }
}

export default SD_Stakeholders;
