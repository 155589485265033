import React, { Component } from 'react'
import { Modal } from "react-bootstrap";

import classes from './Modal.module.css'

export default class ModalLinks extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <Modal
                size={"md"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.show}
                onHide={this.props.close}
            >
                <Modal.Header className={classes.modalHeader} onClick={this.props.close} closeButton>
                    <div className="d-flex justify-content-between">
                        <span className={classes.modalTitle}>Social links</span>
                        <span className={classes.option}>(Optional)</span>
                    </div>
                </Modal.Header>
                <div className={classes.modalContent}>
                    <div className={classes.content}>
                        <div className={classes.boxContent}>
                            <span className={classes.titleContent}>Linkedin</span>
                            <input className={classes.inputModal} type={"text"} value={"http://linkedin.com/"} />
                        </div>

                        <div className={classes.btnFullAction}>
                            <div className={classes.contentBtn}>
                                <div className={classes.circle}>
                                    <img src={require('../../../assets/add.svg')} alt="" />
                                </div>
                                <span>New link</span>
                            </div>
                        </div>
                        <span className={classes.note}>Fun fact: 92% of employers we surveyed use social links as part of their recruiting process. Add 3 links for a higher chance of a successful application!</span>
                    </div>
                    <div className={classes.modalAction}>
                        <div className={classes.cancel}>
                            <span>Cancel</span>
                        </div>
                        <div className={classes.save}>
                            <span>Save</span>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}
