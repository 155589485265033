import React from "react";
import Sliders from "react-slick";

import { useMediaQuery } from "react-responsive";

export default function Slider(props) {
  const isMobile = useMediaQuery({
    query: "(max-device-width: 420px)",
  });

  const Action = (params) => {
    let slideTtoShow = null;
    switch (params) {
      case "top":
        slideTtoShow = (isMobile || window.screen.width <= 480) && params === "top" ? 1 : 3;
        break;
      case "bottom":
        slideTtoShow = (isMobile || window.screen.width <= 480) && params === "bottom" ? 1 : 3;
        break;
      default:
        slideTtoShow = 1;
    }

    return {
      className: "",
      infinite: false,
      centerPadding: "",
      variableWidth: true,
      slidesToShow: slideTtoShow,
      swipeToSlide: true,
    };
  };

  let settings = Action(props.setting);
  return <Sliders {...settings}>{props.content}</Sliders>;
}
