import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import UserContext from '../../Context'

import classes from './Filter.module.css'

export default class Filter extends Component {
    static contextType = UserContext

    constructor(props) {
        super(props);
        this.state = {
            filter: [
                { id: 1, title: "All Brands" },
                { id: 2, title: "Phenikaa" },
                { id: 3, title: "Vicostone" }
            ],
        };
    }

    componentDidMount(){
        const contextValue = this.context
        console.log('im here')
    }
    render() {
        const contextValue = this.context
        let filterList

        if (contextValue.isFilterLoaded){
            if (contextValue.listFilter && this.props.isJobLoaded && this.props.jobs.length > 0){
                filterList = contextValue.listFilter.map((s, i) => {
                    let active = parseInt(this.props.active) === parseInt(i + 1) ? classes.filterActive : ""
                    return (
                        <div data-filter={s.id} className={[classes.filterContent, active].join(' ')} onClick={this.props.onClick}>
                            <span data-filter={s.id} onClick={this.props.onClick}>{s.title}</span>
                        </div>
                    )
                })
            } else if (contextValue.listFilter && this.props.isJobLoaded && this.props.jobs.length === 0){
                filterList = <span>You dont have applied jobs</span>
            }
        }

        const settings = {
            slidesToShow: 3,
            swipeToSlide: true,
            infinite: false,
            variableWidth: true,
            centerPadding: "20px"
        };
        
        return (
            // <div className={classes.filterList}>
                <Slider {...settings}>{filterList}</Slider>
            // </div>
        )
    }
}
