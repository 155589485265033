import React, { Component } from "react"
import { Link } from "react-router-dom"
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Modal } from "react-bootstrap"
import Api from "./Api"
import ReactHtmlParser from "react-html-parser"

import classes from "./orientation.module.css"

class orientation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeSidebar: 0,
      box1: null,
      box2: null,
      contentSection: [],
      isLoaded: false,
      detailLoaded1: false,
      detailLoaded2: false,
      detailLoaded3: false,
    }
    this.getSectionDetail = this.getSectionDetail.bind(this)
    this.toggleHiding = this.toggleHiding.bind(this)
  }

  componentDidMount() {
    this.getSectionDetail()
  }
  changeIndex(key) {
    this.setState({ activeSidebar: key })
  }
  getHtmlParagraph(str) {
    if (str !== "") {
      return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ")
    }
  }

  getSectionDetail() {
    const apiData = new Api()

    apiData
      .getSectionDetail()
      .then((result) => {
        const sections = result.data
        const widget = []
        result.data.widgets.forEach((item) => {
          widget.push({
            id: item.id,
            index: item.index,
            name: item.name,
            category: item.category,
            url: item.url,
            sections: item,
          })
        })

        sections.page = widget
        this.setState({
          contentSection: widget,
          isLoaded: true,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  toggleShowing(i) {
    switch (i) {
      case 1:
        this.setState({ detailLoaded1: true })
        break
      case 2:
        this.setState({ detailLoaded2: true })

        break
      case 3:
        this.setState({ detailLoaded3: true })

        break
      default:
        break
    }
  }

  toggleHiding() {
    this.setState({
      detailLoaded1: false,
      detailLoaded2: false,
      detailLoaded3: false,
    })
  }

  render() {
    const { contentSection, isLoaded } = this.state
    let desc,
      titlepages,
      img,
      content,
      titles,
      titles2,
      titles3,
      boxLeft,
      boxRight,
      boxBottom,
      detail1,
      detail2,
      detail3,
      link = null

    var boxBottom1 = []
    var boxBottom2 = []

    if (isLoaded) {
      contentSection.forEach((item, index) => {
        switch (index) {
          case 0:
            item.sections.widget_contents.forEach((ele) => {
              if (ele.content) {
                titlepages = <h1 className={classes.titleTop1}>{ele.content}</h1>
              } else {
                titlepages = <p className={classes.titleTop1}></p>
              }
            })
            break
          case 1:
            item.sections.widget_contents.forEach((ele) => {
              if (ele.content) {
                desc = <p className={classes.titleTop}>{ele.content}</p>
              } else {
                desc = <p className={classes.titleTop}></p>
              }
            })
            break
          case 2:
            item.sections.widget_contents.forEach((ele) => {
              if (ele.content) {
                titles = <p className={classes.titles}>{ele.content}</p>
              } else {
                titles = <p className={classes.titles}></p>
              }
            })
            break
          case 3:
            item.sections.widget_contents.forEach((ele) => {
              if (ele.content) {
                titles2 = <p className={classes.titles}>{ele.content}</p>
              } else {
                titles2 = <p className={classes.titles}></p>
              }
            })
            break
          case 4:
            item.sections.widget_contents.forEach((ele) => {
              if (ele.content) {
                titles3 = <p className={classes.titles}>{ele.content}</p>
              } else {
                titles3 = <p className={classes.titles}></p>
              }
            })
            break
          case 5:
            boxLeft = item.sections.widget_contents.map((ele) => {
              img = ele.assets[0] ? <img className={classes.imgInside} src={ele.assets[0].file} alt={ele.assets[0]?.description} /> : <img className={classes.imgInside} src={require("./assets/default-image.svg")} alt=' ' />

              if (ele.article_language.content) {
                let content2 = ele.article_language.content.substring(ele.article_language.content.indexOf(":") + 1)
                let content1 = ele.article_language.content.split(":")[0]
                content = (
                  <p className={classes.textC}>
                    {ReactHtmlParser(content1)}:&nbsp;
                    <span>{ReactHtmlParser(content2)}</span>
                  </p>
                )
              } else {
                content = <p className={classes.textC}></p>
              }

              return (
                <div className={classes._L1}>
                  <div className={classes._L1a}>{img}</div>
                  <div className={classes._L1b}>{content}</div>
                </div>
              )
            })
            break
          case 6:
            boxRight = item.sections.widget_contents.map((ele) => {
              img = ele.assets[0] ? <img className={classes.imgInside} src={ele.assets[0].file} alt={ele.assets[0]?.description} /> : <img className={classes.imgInside} src={require("./assets/default-image.svg")} alt=' ' />

              if (ele.article_language.content) {
                let content2 = ele.article_language.content.substring(ele.article_language.content.indexOf(":") + 1)
                let content1 = ele.article_language.content.split(":")[0]
                content = (
                  <p className={classes.textC}>
                    {ReactHtmlParser(content1)}:&nbsp;
                    <span>{ReactHtmlParser(content2)}</span>
                  </p>
                )
              } else {
                content = <p className={classes.textC}></p>
              }

              return (
                <div className={classes._R1}>
                  <div className={classes._R1a}>{img}</div>
                  <div className={classes._L1b}>{content}</div>
                </div>
              )
            })
            break
          case 7:
            var counter = 0
            boxBottom = item.sections.widget_contents.map((ele) => {
              img = ele.assets[0] ? <img className={classes.imgInside} src={ele.assets[0].file} alt={ele.assets[0]?.description} /> : <img className={classes.imgInside} src={require("./assets/default-image.svg")} alt=' ' />

              if (ele.article_language.content) {
                let content2 = ele.article_language.content.substring(ele.article_language.content.indexOf(":") + 1)
                let content1 = ele.article_language.content.split(":")[0]
                content = (
                  <p className={classes.textC}>
                    {ReactHtmlParser(content1)}:&nbsp;
                    <span>{ReactHtmlParser(content2)}</span>
                  </p>
                )
              } else {
                content = <p className={classes.textC}></p>
              }
              switch (counter) {
              }
              return (
                <div className={classes._Left2a}>
                  <div className={classes._L1a}>{img}</div>
                  <div className={classes._L1b}>{content}</div>
                </div>
              )
            })

            boxBottom.forEach((item, index) => {
              if (index < boxBottom.length / 2) {
                boxBottom1.push(item)
              } else {
                boxBottom2.push(item)
              }
            })

            break
          case 8:
            let prevLink = "" // localStorage.getItem("prevLink");

            link = item.sections.widget_contents.map((ele) => {
              return (
                <div className={classes.navBack}>
                  <a href={prevLink && prevLink !== "" ? prevLink : item.sections.url ? item.sections.url : "/sustainable"} className={classes.customLink}>
                    <div className={"d-flex"}>
                      <div className={"d-flex"}>
                        <i className={[classes.arrow, classes.left, classes.customMargin].join(" ")}></i>
                      </div>
                      <span className={classes.textBack}>{ele.content ? ele.content : "Back to prev"}</span>
                    </div>
                  </a>
                </div>
              )
            })

            break
          case 9:
            item.sections.widget_contents.forEach((s) => {
              detail1 = (
                <>
                  <Modal.Header onClick={this.toggleHiding} closeButton>
                    <Modal.Title className={["pl-3", classes.modalTitle].join(" ")}>{s.article_language.title}</Modal.Title>
                  </Modal.Header>
                  <div className={classes.boxModal}>{ReactHtmlParser(s.article_language.content)}</div>
                </>
              )
            })
            break
          case 10:
            item.sections.widget_contents.forEach((s) => {
              detail2 = (
                <>
                  <Modal.Header onClick={this.toggleHiding} closeButton>
                    <Modal.Title className={["pl-3", classes.modalTitle].join(" ")}>{s.article_language.title}</Modal.Title>
                  </Modal.Header>
                  <div className={classes.boxModal}>{ReactHtmlParser(s.article_language.content)}</div>
                </>
              )
            })
            break
          case 11:
            item.sections.widget_contents.forEach((s) => {
              detail3 = (
                <>
                  <Modal.Header onClick={this.toggleHiding} closeButton>
                    <Modal.Title className={["pl-3", classes.modalTitle].join(" ")}>{s.article_language.title}</Modal.Title>
                  </Modal.Header>
                  <div className={classes.boxModal}>{ReactHtmlParser(s.article_language.content)}</div>
                </>
              )
            })
            break
          default:
        }
      })
    }

    return (
      <div className='' style={{}}>
        <div className={["container", classes._joinBox].join(" ")}>
          <div className={classes.maintitle}>
            {titlepages}
            {desc}
          </div>
          <div className={classes._box1}>
            <div className={classes._boxLeft}>
              {boxLeft}
              <div className={classes._boxImgL}>
                <div className={classes._boxInner}>
                  {titles}
                  <img className={classes.imgCircle} src={require("./assets/1.svg")} alt=' ' />
                </div>
                <div className={classes.boxDetail} onClick={() => this.toggleShowing(1)}>
                  <FontAwesomeIcon color='#233871' className='m-auto' icon={faPlusCircle} size='lg' />
                </div>
                <Modal size='xl' aria-labelledby='example-modal-sizes-title-sm' centered dialogClassName={classes.modalCustom} show={this.state.detailLoaded1} onHide={this.toggleHiding}>
                  {detail1}
                </Modal>
              </div>
            </div>

            <div className={classes._boxRight}>
              {boxRight}

              <div className={classes._boxImgR}>
                <div className={classes._boxInner}>
                  {titles2}
                  <img className={classes.imgCircle} src={require("./assets/2.svg")} alt=' ' />
                </div>
                <div className={classes.boxDetail} onClick={() => this.toggleShowing(2)}>
                  <FontAwesomeIcon color='#233871' className='m-auto' icon={faPlusCircle} size='lg' />
                </div>
                <Modal size='xl' aria-labelledby='example-modal-sizes-title-sm' centered dialogClassName={classes.modalCustom} show={this.state.detailLoaded2} onHide={this.toggleHiding}>
                  {detail2}
                </Modal>
              </div>
            </div>
          </div>
          <div className={classes._box2}>
            <div className={classes.boxLeft2}>{boxBottom1}</div>
            <div className={classes.boxLeft2}>{boxBottom2}</div>
            <div className={classes._box2ImgR}>
              <div className={classes._boxInner2}>
                {titles3}
                <img className={classes.imgCircle} src={require("./assets/3.svg")} alt=' ' />
              </div>
              <div className={classes.boxDetail} onClick={() => this.toggleShowing(3)}>
                <FontAwesomeIcon color='#233871' className='m-auto' icon={faPlusCircle} size='lg' />
              </div>
              <Modal size='xl' aria-labelledby='example-modal-sizes-title-sm' centered dialogClassName={classes.modalCustom} show={this.state.detailLoaded3} onHide={this.toggleHiding}>
                {detail3}
              </Modal>
            </div>
          </div>

          {link}
        </div>
      </div>
    )
  }
}

export default orientation
