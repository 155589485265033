import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import Api from "../Api";
import classes from "./DesktopSec4.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

export default class Desktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentSection: [],
      isLoaded: false,
      isApiLoaded: false,
    };
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail = () => {
    const apiData = new Api();
    apiData
      .getSectionDetail()
      .then((result) => {
        const sections = result.data;
        const widget = [];

        sections.widgets.forEach((item) => {
          widget.push({
            id: item.id,
            index: item.index,
            category: item.category,
            name: item.name,
            sections: item,
            url: item.url,
          });
        });

        this.setState({
          contentSection: widget,
          isLoaded: true,
        });
      })
      .catch((error) => {
        this.setState({
          isApiLoaded: true,
        });
        window.location.assign(
          `/error/${error.response.status ? error.response.status : 404}`
        );
      });
  };

  render() {
    const { contentSection } = this.state;

    let content1, content2, content3;
    if (this.state.isLoaded) {
      contentSection.forEach((el) => {
        if (el.index === 1 && el.category === "Article") {
          content1 = (
            <div className={classes.content1}>
              {ReactHtmlParser(el.sections.widget_contents[0].article_language.content)}
            </div>
          );
        } else if (el.index === 2 && el.category === "Article") {
          content2 = (
            <div className={classes.content2}>
              {ReactHtmlParser(el.sections.widget_contents[0].article_language.content)}
            </div>
          );
        } else if (el.index === 3 && el.category === "Text") {
          let url = el.sections.url;
          let backText = el.sections.widget_contents[0].content;
          let prevLink = ""; //  localStorage.getItem("prevLink");

          content3 = (
            <div className={classes.navBack}>
              <a href={prevLink && prevLink !== "" ? prevLink : url}>
                <div className={"d-flex"}>
                  <div className={"d-flex"}>
                    <i
                      className={[
                        classes.arrow,
                        classes.left,
                        classes.customMargin,
                      ].join(" ")}
                    ></i>
                  </div>
                  <span className={classes.textBack}>
                    {ReactHtmlParser(backText)}
                  </span>
                </div>
                {/* <div className={classes.content3}>
                <FontAwesomeIcon
                  className={classes.backIcon}
                  icon={faArrowLeft}
                />

                {ReactHtmlParser(backText)}
              </div> */}
              </a>
            </div>
          );
        }
      });
    }

    return (
      <div className={classes.section}>
        <div className={["container", classes.customContainer].join(" ")}>
          {content1}
          {content2}
          {content3}
        </div>
      </div>
    );
  }
}
