import React from 'react'

import { isTablet, isMobile } from 'react-device-detect';
// import { useMediaQuery } from "react-responsive";

import classes from './Desktop.module.css'

export default function Navbar(props) {
    // const isTablet = useMediaQuery({
    //     query: "(max-device-width: 768px), (min-device-width: 480px)",
    // });

    let isActive, isActiveBox
    if (isTablet) {
        isActive = props.isActive ? props.content : <></>
        isActiveBox = props.isActive ? classes.customBoxA : classes.customBox
    } 
    
    let spanEnStyle
    let lang = localStorage.getItem("language");
    if (lang === "English") { 
        spanEnStyle = classes.navEn
    }

    let navbar = !isTablet ?
        <div key={props.idx} className={[props.box, props.active, spanEnStyle].join(" ")} onClick={props.onClick} >
            <div className="d-flex">
                <img className={classes.imgIcon} src={props.img} alt=""></img>
                <span
                    id={props.key}
                    className={classes.customSpan}
                    data-hover={props.content}
                >
                    {props.content}
                </span>
            </div>
        </div> :
        <div key={props.idx} className={[props.box, props.active].join(" ")} onClick={props.onClick}>
            <div className={isActiveBox}>
                <img className={classes.imgIcon} src={props.img} alt=""></img>
                <span id={props.key} >
                    {isActive}
                </span>
            </div>
        </div>
    return (
        <>
            {navbar}
        </>
    )
}
