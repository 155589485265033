import React, { Component } from "react"

import { faCaretDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"

import classes from "./LanguageItems.module.css"

const LangItem = styled.div`
  color: white;
  font: 16px/32px Helvetica;
  width: 100%;
  display: inline;
  @media only screen and (max-width: 1400px) {
    font: 14px Helvetica;
  }
  @media only screen and (max-width: 768px) {
    font: 14px Helvetica;
    margin-bottom: 5px;
  }
`
class LanguageItems extends Component {
  state = {
    items: [
      {
        id: 1,
        title: "Vietnamese",
        type: "inactive",
        img: require("./assets/iconVn.svg"),
      },
      {
        id: 2,
        title: "English",
        type: "inactive",
        img: require("./assets/iconUk.svg"),
      },
    ],
    selectLanguage: this.props.selectLanguage,
    language: null,
    activeLang: 0,
  }

  componentDidMount() {
    let defaultLang = localStorage.getItem("language")
    let active = 0
    if (defaultLang && defaultLang.toLowerCase() === "vietnamese") {
      active = 1
    } else if (defaultLang && defaultLang.toLowerCase() === "english") {
      active = 0
    } else {
      active = 1
      localStorage.setItem("language", "Vietnamese")
    }

    this.setState({
      activeLang: active,
    })
  }

  toggleSwitchLanguage = (lang) => {
    let active = null
    if (lang.toLowerCase() === "vietnamese") {
      active = 1
    } else {
      active = 0
    }
    this.setState({
      language: lang,
      activeLang: active,
    })
    localStorage.setItem("language", lang)
    window.location.reload(true)
  }

  render() {
    let langActive,
      langItem = null

    if (this.props.lang) {
      const icon = this.props.icon.sections.widget_contents
      langItem = this.props.lang.sections.widget_contents.map((i, index) => {
        let iconLang = icon[index] ? icon[index].file : ""
        let type = this.state.activeLang === index ? classes.active : classes.inActive
        if (this.state.activeLang === index) {
          langActive = i.content
        }
        return (
          <li onClick={() => this.toggleSwitchLanguage(i.name)}>
            <i className={classes.slFlag}>
              <img className={classes.imgFlag} src={iconLang} alt=''></img>
            </i>{" "}
            <span className={type}>{i.content}</span>
          </li>
        )
      })
    } else {
      langItem = this.state.items.map((i, index) => {
        let type = this.state.activeLang === index ? classes.active : classes.inActive
        langActive = this.state.activeLang === index ? i.title : "Vietnamese"
        return (
          <li onClick={() => this.toggleSwitchLanguage(i.title)}>
            <i className={classes.slFlag}>
              <img className={classes.imgFlag} src={i.img} alt=''></img>
            </i>{" "}
            <span className={type}>{i.title}</span>
          </li>
        )
      })
    }

    return (
      <div className='mt-5'>
        <div className={classes.slNav}>
          <LangItem>{this.props.selectLanguage}:</LangItem>
          <ul>
            <li>
              <b className='ml-2'>{langActive}</b> <FontAwesomeIcon color='#d4d4d4' className='ml-auto' icon={faCaretDown} size='lg' />
              <div className={classes.triangle}></div>
              <ul>{langItem}</ul>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default LanguageItems
