import React, { Component, Fragment, createRef } from "react";
import { Row } from "react-bootstrap";

import classes from "./Mobile.module.css";
import Api from "../Api";
import ReactHtmlParser from "react-html-parser";
import EarthVideo from "../assets/earth-video-smaller.mp4";

class Mobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: null,
      sections: {
        widgets: [],
      },
      expanded: false,
      isLoaded: false,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
    this.scrollingHandlerAu = this.scrollingHandlerAu.bind(this);
    this.handleOverride = this.handleOverride.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
    window.addEventListener("scroll", this.scrollingHandlerAu, true);
    this.handleOverride();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollingHandlerAu, true);
  }

  au_sec1_div1 = createRef();
  au_sec1_div2 = createRef();

  handleOverride() {
    this.props.override(false);
  }

  scrollingHandlerAu() {
    if (
      this.au_sec1_div1.current.getBoundingClientRect().bottom <
      this.au_sec1_div1.current.getBoundingClientRect().height &&
      this.au_sec1_div1.current.getBoundingClientRect().bottom > -1
    ) {
      this.props.updateLogo(true);
    }
    if (
      this.au_sec1_div2.current.getBoundingClientRect().bottom <
      this.au_sec1_div2.current.getBoundingClientRect().height &&
      this.au_sec1_div2.current.getBoundingClientRect().bottom > -1
    ) {
      this.props.updateLogo(false);
    }
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState(
      {
        sections: [],
      },
      () => {
        apiData
          .getSectionDetail()
          .then((result) => {
            this.setState({
              isApiLoaded: true,
            });
            const sections = result.data;
            const widget = [];
            result.data.widgets.forEach((item) => {
              widget.push({
                id: item.id,
                index: item.index,
                category: item.category,
                name: item.name,
                sections: item,
              });
            });
            sections.page = widget;
            this.setState({
              sections: widget,
              isLoaded: true,
            });
          })
          .catch((error) => {
            this.setState({
              isApiLoaded: true,
            });
          });
      }
    );
  }

  render() {
    const { sections } = this.state;
    // const toggledClass = expanded ? "expanded" : "collapsed"
    let mainText,
      colorMainText,
      imageBack,
      content,
      runningText,
      name;

    let count = 0;

    if (this.state.isLoaded) {
      sections.forEach((item) => {
        count += 1;
        if (count === 1 && item.index === 1 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            colorMainText = s.font_color
            if (s.content !== "") {
              mainText = <h1 style={{color: colorMainText}}>{s.content}</h1>;
            } else {
              mainText = <></>;
            }
          });
        }

        if (count === 2 && item.index === 2 && item.category === "Media") {
          item.sections.widget_contents.forEach((s) => {
            imageBack = s.file ? (
              <img className={classes.imgTop} src={s.file} alt={s.description} />
            ) : (
              <img
                className={classes.imgTop}
                src={require("../assets/default-image.svg")}
                alt="Default image"
              />
            );

            return <>{imageBack}</>;
          });
        }

        if (count === 3 && item.index === 3 && item.category === "Article") {
          item.sections.widget_contents.forEach((s) => {
            if (s.title !== "") {
              name = <h2>{s.article_language.title}</h2>;
            } else {
              name = <></>;
            }

            if (s.content !== "") {
              content = <p>{ReactHtmlParser(s.article_language.content)}</p>;
            } else {
              content = <></>;
            }
          });
        }

        if (count === 4 && item.index === 4 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              runningText = <span>{s.content}</span>;
            } else {
              runningText = <></>;
            }

            return <>{runningText}</>;
          });
        }
      });
    } else if (this.state.isApiLoaded) {

    }

    return (
      <Fragment>
        <div>
          <div ref={this.au_sec1_div1} className={classes.bannerTop}>
            <div className={classes.boxImage}>
              <video className={classes.imgTop} autoPlay loop muted playsInline>
                <source src={EarthVideo} type="video/mp4" />
              </video>
            </div>
            <div className={classes.contentInsideImage}>
              <p className={[classes.p_0, classes.p_0_animation].join(" ")}>
                {mainText}
              </p>
              <div className={[classes.p_0_underline]} style={{backgroundColor: colorMainText}}></div>
            </div>
          </div>

          <div ref={this.au_sec1_div2} className={classes.wraperOveriew}>
            <div>
              <Row className={classes.p_1}>{name}</Row>

              <div className={classes.p_2}>{content}</div>
            </div>
            <Row></Row>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Mobile;
