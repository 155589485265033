import React, { Component } from "react";
import styled from "styled-components";
import MegaMenuItems from "./MegaMenuItems/MegaMenuItems";
import LanguageItems from "./MegaMenuItems/LanguageItems/LanguageItems";

import Api from "../Api";

import classes from "./MegaMenu.module.css";
import { tickStep } from "d3";

const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;

  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(50px);

  ${({ open }) => {
    if (open) {
      return `
        clip-path: circle(100%);
        height: 100vh;
        width: 100vw;
        transition: clip-path 1s, opacity 0.5s;
      `
    } else {
      return `
        clip-path: circle(0% at 30px 48%);
        height: 0;
        width: 0;
        transition: clip-path 1s, opacity 0.5s, width 0s 2s, height 0s 2s;
      `
    }
  }}

  text-align: left;
  z-index: ${({ open }) => (open ? "2" : "-1")};
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 0rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: #0d0c1d;
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: #343078;
    }
  }
  @media only screen and (max-width: 1024px) {

  }

  @media only screen and (max-width: 768px) {
  }
  @media only screen and (max-width: 420px) {
  }
  @media only screen and (max-width: 360px) {
  }
`;
export default class MegaMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
    };
    this.keyPress = this.keyPress.bind(this);
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const widget = [];

          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            });
          });

          sections.page = widget;
          this.setState({
            contentSection: widget,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  keyPress(e) {
    const currentUrl = window.location.href;
    const pathname = window.location.pathname;
    if (e.keyCode === 13) {
      if (pathname === "/") {
        window.location.href = currentUrl + `search/?search=${e.target.value}`;
      } else if (pathname === "/search/") {
        window.location.href = currentUrl + `search/?search=${e.target.value}`;
      } else {
        window.location.href =
          currentUrl.replace(pathname, "/") +
          `search/?search=${e.target.value}`;
      }
    }
  }

  render() {
    const { contentSection } = this.state;

    let placeholder,
      selectLanguage,
      lang,
      icon = null;

    if (this.state.isLoaded) {
      contentSection.forEach((item, i) => {
        switch (i) {
          case 0:
            item.sections.widget_contents.forEach((s) => {
              placeholder = s.content ? s.content : "";
            });
            break;
          case 1:
            item.sections.widget_contents.forEach((s) => {
              selectLanguage = s.content ? s.content : "";
            });
            break;
          case 2:
            lang = item;
            break;
          case 3:
            icon = item;
            break;
          default:
            break;
        }
      });
    } else if (this.state.isApiLoaded) {
      placeholder = "";
      lang = false;
    }

    return (
      <StyledMenu open={this.props.open}>
        <div style={{ flex: "none" }} className="col-md-3"></div>
        <div className="row">
          <div
            style={{ left: "8vw" }}
            className={["col-md-6", classes.itemList].join(" ")}
          >
            <div className={["input-group", classes.customSearch].join(" ")}>
              <input
                onKeyDown={this.keyPress}
                type="text"
                autoComplete="off"
                id="btnSearch"
                placeholder={placeholder}
              />
              <i
                className={["fa fa-search fa-sm", classes.faCustom].join(" ")}
                aria-hidden="true"
              ></i>
            </div>
            <MegaMenuItems items={this.props.items} />
            <LanguageItems
              lang={lang}
              icon={icon}
              selectLanguage={selectLanguage}
            />
          </div>
        </div>
      </StyledMenu>
    );
  }
}
