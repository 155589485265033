import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import UserContext from "../../../Context";
import Api from "../../../../Api";

import classes from "./Modal.module.css";
export default class ModalLinks extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      file: [],
      id: 0,
      isFile: false,
      acceptPDF: {},
      titleModalResume: "",
      cancelText: "",
      saveText: "",
      data: [],
    };
    this.uploadResume = this.uploadResume.bind(this);
    this.resetFile = this.resetFile.bind(this);
  }

  componentDidMount() {
    const contextValue = this.context;
    this.setState({
      data: contextValue.contentSection[11].sections.widget_contents,
      id: contextValue.idApplicant,
    });
  }

  isValidate() {
    if (this.state.file.length > 0) {
      this.setState({
        isFile: false,
      });
      return true;
    } else {
      this.setState({
        isFile: true,
      });
      return false;
    }
  }

  uploadResume() {
    const resume = new Api();
    const contextValue = this.context;

    if (this.isValidate()) {
      resume
        .uploadResume(this.state.id, this.state.file)
        .then((res) => {
          this.setState({
            isFile: false,
          });
          this.props.close();
          contextValue.getNewDataApplicant();
          contextValue.showNotification(
            "Your resume has been successfully added."
          );
        })
        .catch((err) => {
          console.log("error =>", err);
        });
    }
  }

  handleChangeFile(e) {
    for (let index = 0; index < e.target.files.length; index++) {
      let newFile = e.target.files[index];
      this.setState((prevState) => {
        const file = prevState.file.concat(newFile);
        return { file };
      });
    }
  }

  removeFileUploader(i) {
    this.setState((state) => {
      const file = state.file.filter((item, j) => i !== j);
      return {
        file,
      };
    });
  }

  resetFile() {
    this.setState({
      file: [],
    });
  }

  render() {
    const { file } = this.state;

    let title, optional, upload_file, info, cancel, save;

    this.state.data.forEach((x, i) => {
      switch (i) {
        case 0:
          title = x.content;
          break;
        case 1:
          info = x.content;
          break;
        case 2:
          optional = x.content;
          break;
        case 3:
          upload_file = x.content;
          break;
        case 19:
          cancel = x.content;
          break;
        case 20:
          save = x.content;
          break;
        default:
      }
    });

    let contentUpload;
    if (file) {
      contentUpload = file.map((f, i) => {
        return (
          <div className={classes.boxFile}>
            <span>{f.name}</span>
            <FontAwesomeIcon
              color="#000000"
              className={classes.iconClose}
              icon={faTimes}
              size="md"
              onClick={() => this.removeFileUploader(i)}
            />
          </div>
        );
      });
    }

    let isError = this.state.isFile ? classes.errorVisible : "";

    return (
      <Modal
        size={"md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.show}
        onHide={() => {
          this.props.close();
          this.resetFile();
        }}
      >
        <Modal.Header
          className={classes.modalHeader}
          onClick={this.props.close}
          closeButton
        >
          <div className="d-flex justify-content-between">
            <span className={classes.modalTitle}>{title}</span>
            <span className={classes.option}>({optional})</span>
          </div>
        </Modal.Header>

        <UserContext.Consumer>
          {(context) => (
            <div className={classes.modalContent}>
              <div className={classes.content}>
                <div className={classes.contentUpload}>{contentUpload}</div>

                <input
                  type="file"
                  id="btn-upload"
                  onChange={(e) => this.handleChangeFile(e)}
                  multiple
                  hidden
                />
                <label htmlFor="btn-upload" className={classes.btnFullAction}>
                  <div className={classes.contentBtn}>
                    <div className={classes.circle}>
                      <img src={require("../../assets/add.svg")} alt="" />
                    </div>
                    <span>{upload_file}</span>
                  </div>
                </label>
                <span
                  className={[classes.contentError, isError, "mb-3"].join(" ")}
                >
                  you should at least add one file
                </span>

                <span className={classes.note}>{info}</span>
              </div>

              <div className={classes.modalAction}>
                <div className={classes.cancel} onClick={this.props.close}>
                  <span>{cancel}</span>
                </div>
                <div
                  className={classes.save}
                  onClick={() => {
                    this.uploadResume();
                  }}
                >
                  <span>{save}</span>
                </div>
              </div>
            </div>
          )}
        </UserContext.Consumer>
      </Modal>
    );
  }
}
