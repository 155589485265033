import React, { useContext } from 'react'

import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import { AccordionContext } from 'react-bootstrap';

import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classes from '../IN_PP.module.scss'

export default function Accordion({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey),
    );

    const isCurrentEventKey = currentEventKey === eventKey;
    let icon = isCurrentEventKey ? <FontAwesomeIcon color='#d4d4d4' className='ml-auto mb-auto mt-auto' icon={faMinus} size="lg" /> : <FontAwesomeIcon color='#f16b26' className='ml-auto mb-auto mt-auto' icon={faPlus} size="lg" />
    return (
        <div onClick={decoratedOnClick} className={['d-flex relative card-header', classes.cardHeader].join(' ')}>
            <div className={classes.groupAccordion}>
                {children}
            </div>
            {icon}
        </div>
    )
}
