import React, { Component } from "react";
import classes from "./Section1.module.css";
import Api from "./Api";
import {
  FacebookIcon,
  LinkedinIcon,
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share";
// import { Link } from "react-router-dom";

export default class Box2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: {
        widgets: [],
      },
      url: "",
      currentUrl: "",
      readMore: "",
      isLoaded: false,
      windowObjectReference: null,
      isApiLoaded: true,
      isOpened: false,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }
  componentDidMount() {
    if (this.props.id) {
      let param = this.props.id;
      this.getSectionDetail(param);
      let uri = window.location.href;
      let uri_enc = encodeURIComponent(uri);
      let uri_dec = decodeURIComponent(uri_enc);
      this.setState({
        url: this.props.url,
        currentUrl: uri_dec,
      });
    }
  }

  openNewWindow(url) {
    if (!this.state.isOpened) {
      this.setState({
        isOpened: true,
      });

      let target = "Check";
      window.open(
        url,
        target,
        "toolbars=0,width=570,height=570,left=400,top=200,scrollbars=1,resizable=1"
      );
    } else {
      if (url) {
        window.open(
          url,
          "target",
          "toolbars=0,width=570,height=570,left=400,top=200,scrollbars=1,resizable=1"
        );
      } else {
        console.log("else--");
      }
    }
  }

  getSectionDetail(param) {
    const apiData = new Api();
    this.setState({ contentArticle: [] }, () => {
      apiData
        .getSectionDetail(param)
        .then((result) => {
          const articles = result.data;
          const y = [];

          y.push({
            back: articles.widgets[0].widget_contents[0].content,
            url: articles.widgets[0].url,
            share: articles.widgets[1].widget_contents[0].content,
          });

          this.setState({
            article: articles.widgets,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }
  render() {
    const { article } = this.state;
    let shareText,
      imgShare,
      dataShareIcon,
      Facebook,
      LinkedIn = null;

    if (this.state.isLoaded) {
      article.forEach((ele, index) => {
        switch (index) {
          case 0:
            // shareText = ele.widget_contents[0]
            //   ? ele.widget_contents[0].content
            //   : "";
            break;
          case 1:
            shareText = ele.widget_contents[0]
              ? ele.widget_contents[0].content
              : "";
            // code block
            break;
          case 2:
            break;
          case 4:
            ele.widget_contents.forEach((j, index) => {
              let url = j.url ? j.url : "/";
              switch (index) {
                case 0:
                  // Facebook = (
                  //   <FacebookShareButton
                  //     url={this.state.currentUrl}
                  //     quote={this.props.shareTitle}
                  //     // hashtag="#camperstribe"
                  //     className={classes.boxImageIconShare}
                  //   >
                  //     <FacebookIcon size={36} round />
                  //   </FacebookShareButton>
                  // );
                  break;
                case 1:
                  // LinkedIn = (
                  //   <LinkedinShareButton
                  //     url={this.state.currentUrl}
                  //     title={this.props.shar let uri = window.location.href;

                  //     // summary={"#camperstribe"}
                  //     className={classes.boxImageIconShare}
                  //   >
                  //     <LinkedinIcon size={36} round />
                  //   </LinkedinShareButton>
                  // );
                  break;

                default:
              }
              //   imgShare = j.assets[0] ? j.assets[0].file : "";

              //   return (

              // <div
              //   className={classes.boxImageIconShare}
              //   onClick={() => this.openNewWindow(url, this.state.isOpened)}
              // >
              //   <img className={classes.icon} src={imgShare} alt="" />
              // </div>
              // <a
              //   key={j.title}
              //   href={url}
              //   target="blank"
              //   rel="noopener noreferrer"
              //   //   onClick={() => this.windowShare(this.href)}
              // >
              // </a>
              //   );
            });

            break;
          default:
          // code block
        }
      });
    }

    Facebook = (
      <FacebookShareButton
        url={this.state.currentUrl}
        quote={this.props.shareTitle}
        // hashtag="#camperstribe"
        className={classes.boxImageIconShare}
      >
        <FacebookIcon size={36} round />
      </FacebookShareButton>
    );

    LinkedIn = (
      <LinkedinShareButton
        url={this.state.currentUrl}
        title={this.props.shareTitle}
        // summary={"#camperstribe"}
        className={classes.boxImageIconShare}
      >
        <LinkedinIcon size={36} round />
      </LinkedinShareButton>
    );

    return (
      <div className={classes.wrappbtnback2}>
        <div
          className={classes.share}
          style={{
            fontFamily: "Helvetica",
          }}
        >
          <p>{shareText} :</p>
          <div className={classes.boxIconW}>
            {Facebook}
            {LinkedIn}
          </div>
        </div>
      </div>
    );
  }
}
