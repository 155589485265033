import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import HOME from "../pages/home/home";
import PAGE_136 from "../pages/page_136/page_136";
import PAGE_139 from "../pages/page_139/page_139";
import PAGE_142 from "../pages/page_142/page_142";
import PAGE_147 from "../pages/page_147/page_147";
import PAGE_161 from "../pages/page_161/page_161";
import PAGE_181 from "../pages/page_181/page_181";
import PAGE_186 from "../pages/page_186/page_186";
import PAGE_188 from "../pages/page_188/page_188";
import PAGE_201 from "../pages/page_201/page_201";
import PAGE_203 from "../pages/page_203/page_203";
import PAGE_204 from "../pages/page_204/page_204";
import PAGE_205 from "../pages/page_205/page_205";
import PAGE_140 from "../pages/page_140/page_140";
import PAGE_182 from "../pages/page_182/page_182";
import PAGE_190 from "../pages/page_190/page_190";
import PAGE_199 from "../pages/page_199/page_199";
import PAGE_207 from "../pages/page_207/page_207";
import PAGE_218 from "../pages/page_218/page_218";
import PAGE_141 from "../pages/page_141/page_141";
import PAGE_146 from "../pages/page_146/page_146";
import PAGE_183 from "../pages/page_183/page_183";
import PAGE_191 from "../pages/page_191/page_191";
import PAGE_143 from "../pages/page_143/page_143";
import PAGE_178 from "../pages/page_178/page_178";
import PAGE_184 from "../pages/page_184/page_184";
import PAGE_208 from "../pages/page_208/page_208";
import PAGE_144 from "../pages/page_144/page_144";
import PAGE_189 from "../pages/page_189/page_189";
import PAGE_198 from "../pages/page_198/page_198";
import PAGE_209 from "../pages/page_209/page_209";
import PAGE_159 from "../pages/page_159/page_159";
import PAGE_217 from "../pages/page_217/page_217";
import PAGE_185 from "../pages/page_185/page_185";
import PAGE_187 from "../pages/page_187/page_187";
import PAGE_192 from "../pages/page_192/page_192";
import PAGE_193 from "../pages/page_193/page_193";
import PAGE_194 from "../pages/page_194/page_194";
import PAGE_195 from "../pages/page_195/page_195";
import PAGE_197 from "../pages/page_197/page_197";
import PAGE_206 from "../pages/page_206/page_206";
import PAGE_202 from "../pages/page_202/page_202";
import PAGE_138 from "../pages/page_138/page_138";

class site extends Component {
     render() {
         return (
             <div>
                 <Switch>
                     <Route path='/ve-chung-toi/van-hoa-doanh-nghiep' exact component={PAGE_198} lang='vi'/>
                     <Route path='/ve-chung-toi/lich-su/history-detail/:param1/:param2' exact component={PAGE_201} lang='vi'/>
                     <Route path='/ve-chung-toi/lich-su' exact component={PAGE_139} lang='vi'/>
                     <Route path='/ve-chung-toi/giai-thuong/chi-tiet-giai-thuong/:param1/:param2' exact component={PAGE_199} lang='vi'/>
                     <Route path='/ve-chung-toi/giai-thuong/chi-tiet-giai-thuong/:param1/:param2' exact component={PAGE_147} lang='vi'/>
                     <Route path='/ve-chung-toi/giai-thuong' exact component={PAGE_146} lang='vi'/>
                     <Route path='/ve-chung-toi/cau-truc-to-chuc' exact component={PAGE_140} lang='vi'/>
                     <Route path='/ve-chung-toi/ban-lanh-dao' exact component={PAGE_178} lang='vi'/>
                     <Route path='/ve-chung-toi' exact component={PAGE_136} lang='vi'/>
                     <Route path='/tuyen-dung' exact component={PAGE_193} lang='vi'/>
                     <Route path='/truy-cap' exact component={PAGE_194} lang='vi'/>
                     <Route path='/tin-tuc-&-su-kien/chi-tiet/:param1/:param2' exact component={PAGE_142} lang='vi'/>
                     <Route path='/tin-tuc-&-su-kien' exact component={PAGE_141} lang='vi'/>
                     <Route path='/tim-kiem' exact component={PAGE_159} lang='vi'/>
                     <Route path='/sustainable-development/stakeholders' exact component={PAGE_184} lang='en'/>
                     <Route path='/sustainable-development/orientation' exact component={PAGE_181} lang='en'/>
                     <Route path='/sustainable-development/environment' exact component={PAGE_183} lang='en'/>
                     <Route path='/sustainable-development/development' exact component={PAGE_182} lang='en'/>
                     <Route path='/sustainable-development/community-and-society' exact component={PAGE_189} lang='en'/>
                     <Route path='/sustainable-development' exact component={PAGE_144} lang='en'/>
                     <Route path='/search' exact component={PAGE_159} lang='en'/>
                     <Route path='/reset-password' exact component={PAGE_197} lang='en'/>
                     <Route path='/register' exact component={PAGE_195} lang='en'/>
                     <Route path='/recruitment' exact component={PAGE_193} lang='en'/>
                     <Route path='/phat-trien-ben-vung/phat-trien-con-nguoi' exact component={PAGE_182} lang='vi'/>
                     <Route path='/phat-trien-ben-vung/moi-truong-ben-vung' exact component={PAGE_183} lang='vi'/>
                     <Route path='/phat-trien-ben-vung/dinh-huong' exact component={PAGE_181} lang='vi'/>
                     <Route path='/phat-trien-ben-vung/cong-dong-xa-hoi' exact component={PAGE_189} lang='vi'/>
                     <Route path='/phat-trien-ben-vung/cac-ben-lien-quan' exact component={PAGE_184} lang='vi'/>
                     <Route path='/phat-trien-ben-vung' exact component={PAGE_144} lang='vi'/>
                     <Route path='/news-&-events/details/:param1/:param2' exact component={PAGE_142} lang='en'/>
                     <Route path='/news-&-events' exact component={PAGE_141} lang='en'/>
                     <Route path='/new-home-menu' exact component={PAGE_202} lang='vi'/>
                     <Route path='/new-home-menu' exact component={PAGE_202} lang='en'/>
                     <Route path='/login' exact component={PAGE_194} lang='en'/>
                     <Route path='/linh-vuc-hoat-dong/cac-don-vi-thanh-vien' exact component={PAGE_203} lang='vi'/>
                     <Route path='/linh-vuc-hoat-dong' exact component={PAGE_218} lang='vi'/>
                     <Route path='/lien-he' exact component={PAGE_187} lang='vi'/>
                     <Route path='/innovations/publication-and-project' exact component={PAGE_208} lang='en'/>
                     <Route path='/innovations/news-innovation/innovation-news-detail/:param1/:param2' exact component={PAGE_205} lang='en'/>
                     <Route path='/innovations/news-innovation/all-innovation-news' exact component={PAGE_207} lang='en'/>
                     <Route path='/innovations/news-innovation' exact component={PAGE_161} lang='en'/>
                     <Route path='/innovations/innovation-view-articles' exact component={PAGE_209} lang='en'/>
                     <Route path='/innovations/innovation-details/:param1/:param2' exact component={PAGE_191} lang='en'/>
                     <Route path='/innovations/authors/authors-detail/:param1/:param2' exact component={PAGE_204} lang='en'/>
                     <Route path='/innovations/authors' exact component={PAGE_190} lang='en'/>
                     <Route path='/innovations' exact component={PAGE_143} lang='en'/>
                     <Route path='/doi-moi-sang-tao/tin-tuc-doi-moi/innovation-news-detail/:param1/:param2' exact component={PAGE_205} lang='vi'/>
                     <Route path='/doi-moi-sang-tao/tin-tuc-doi-moi/all-innovation-news' exact component={PAGE_207} lang='vi'/>
                     <Route path='/doi-moi-sang-tao/tin-tuc-doi-moi' exact component={PAGE_161} lang='vi'/>
                     <Route path='/doi-moi-sang-tao/publication-and-project' exact component={PAGE_208} lang='vi'/>
                     <Route path='/doi-moi-sang-tao/innovation-view-articles' exact component={PAGE_209} lang='vi'/>
                     <Route path='/doi-moi-sang-tao/chi-tiet-doi-moi/:param1/:param2' exact component={PAGE_191} lang='vi'/>
                     <Route path='/doi-moi-sang-tao/cac-tac-gia/chi-tiet-tac-gia/:param1/:param2' exact component={PAGE_204} lang='vi'/>
                     <Route path='/doi-moi-sang-tao/cac-tac-gia' exact component={PAGE_190} lang='vi'/>
                     <Route path='/doi-moi-sang-tao' exact component={PAGE_143} lang='vi'/>
                     <Route path='/dat-lai-mat-khau' exact component={PAGE_197} lang='vi'/>
                     <Route path='/dang-ky' exact component={PAGE_195} lang='vi'/>
                     <Route path='/contact-us' exact component={PAGE_187} lang='en'/>
                     <Route path='/co-hoi-nghe-nghiep/vi-tri-tuyen-dung/chi-tiet-cong-viec/:param1/:param2' exact component={PAGE_188} lang='vi'/>
                     <Route path='/co-hoi-nghe-nghiep/vi-tri-tuyen-dung' exact component={PAGE_186} lang='vi'/>
                     <Route path='/co-hoi-nghe-nghiep' exact component={PAGE_185} lang='vi'/>
                     <Route path='/career/vacancies/job-details/:param1/:param2' exact component={PAGE_188} lang='en'/>
                     <Route path='/career/vacancies' exact component={PAGE_186} lang='en'/>
                     <Route path='/career' exact component={PAGE_185} lang='en'/>
                     <Route path='/business-areas/subsidiaries' exact component={PAGE_203} lang='en'/>
                     <Route path='/business-areas' exact component={PAGE_218} lang='en'/>
                     <Route path='/about-us/organization-structure' exact component={PAGE_140} lang='en'/>
                     <Route path='/about-us/history/history-detail/:param1/:param2' exact component={PAGE_201} lang='en'/>
                     <Route path='/about-us/history' exact component={PAGE_139} lang='en'/>
                     <Route path='/about-us/corporate-culture' exact component={PAGE_198} lang='en'/>
                     <Route path='/about-us/bod' exact component={PAGE_178} lang='en'/>
                     <Route path='/about-us/awards/awards-overview-detail/:param1/:param2' exact component={PAGE_199} lang='en'/>
                     <Route path='/about-us/awards/awards-detail/:param1/:param2' exact component={PAGE_147} lang='en'/>
                     <Route path='/about-us/awards' exact component={PAGE_146} lang='en'/>
                     <Route path='/about-us' exact component={PAGE_136} lang='en'/>
                     <Route path='/3rd-option-menu' exact component={PAGE_206} lang='vi'/>
                     <Route path='/3rd-option-menu' exact component={PAGE_206} lang='en'/>
                     <Route path='/error/:id' exact component={PAGE_192} />
                     <Route path='/' exact component={HOME}/>
                     <Route exact component={PAGE_192} />
                </Switch>
             </div>
         );
     }
}
export default site;