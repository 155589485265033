import React, { Component } from "react";
import styled from "styled-components";

// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import MegaMenuItems from "./MegaMenuItems/MegaMenuItems";
import LanguageItems from "./LanguageItems/LanguageItems";
import SearchItem from "./SearchItem/SearchItem";

import Api from "../Api";

import classes from "./MegaMenuMobile.module.css";

const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;

  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(50px);
  // clip-path: ${({ open }) => open ? "circle(100% at 50% 0%)" : "circle(0% at calc(100vw - 45px) 45px)"};
  // transition: clip-path 1s, opacity 0.5s;

  // height: ${({ open }) => (open ? "100vh" : "0")};
  // width: ${({ open }) => (open ? "100vw" : "0")};
  ${({ open }) => {
    if (open) {
      return `
        clip-path: circle(100%);
        height: 100vh;
        width: 100vw;
        transition: clip-path 1s, opacity 0.5s;
      `
    } else {
      return `
        clip-path: circle(0% at calc(100vw - 45px) 45px);
        height: 0;
        width: 0;
        transition: clip-path 1s, opacity 0.5s, width 0s 2s, height 0s 2s;
      `
    }
  }}
  text-align: left;
  padding: 2rem 2rem 7rem 2rem;
  position: absolute;
  z-index: ${({ open }) => (open ? "1" : "-1")};
  top: 0;
  right: 0;
  @media only screen and (max-width: 1024px) {
    // clip-path: ${({ open }) => (open ? "circle(100% at 50% 20%)" : "circle(0% at 95.2% 2.7rem)")};
  }
  @media only screen and (max-width: 768px) and (orientation: portrait) {
    // clip-path: ${({ open }) => open ? "circle(100% at 50% 30%)" : "circle(0% at 92% 4%)"};
  }
  @media only screen and (max-width: 420px) and (orientation: portrait) {
    // clip-path: ${({ open }) => open ? "circle(100% at 50% 30%)" : "circle(0% at 35% 65%)"};
  }
  @media only screen and (max-width: 360px) and (orientation: portrait) {
    // clip-path: ${({ open }) => open ? "circle(100% at 50% 30%)" : "circle(0% at 88% 6%)"};
  }

  a: {
    font: 16px Helvetica;
    text-transform: uppercase;
    letter-spacing: 0;
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s linear;
    text-align: left;

    &:hover {
      color: #343078;
    }
  }
`;
export default class MegaMenuMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
    };
    this.keyPress = this.keyPress.bind(this);
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const widget = [];
          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            });
          });

          sections.page = widget;
          this.setState({
            contentSection: widget,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  keyPress(e) {
    const currentUrl = window.location.href;
    const pathname = window.location.pathname;
    if (e.keyCode === 13) {
      if (pathname === "/") {
        window.location.href = currentUrl + `search/?search=${e.target.value}`;
      } else if (pathname === "/search/") {
        window.location.href = currentUrl + `search/?search=${e.target.value}`;
      } else {
        window.location.href =
          currentUrl.replace(pathname, "/") +
          `search/?search=${e.target.value}`;
      }
    }
  }

  render() {
    const { contentSection } = this.state;

    let lang = null;

    if (this.state.isLoaded) {
      contentSection.forEach((item, i) => {
        switch (i) {
          case 0:
            item.sections.widget_contents.forEach((s) => {
              // placeholder = s.content ? s.content : "";
            });
            break;
          case 1:
            item.sections.widget_contents.forEach((s) => {
              // selectLanguage = s.content ? s.content : "";
            });
            break;
          case 2:
            lang = item;
            break;
          case 3:
            // icon = item;
            break;
          default:
            break;
        }
      });
    } else if (this.state.isApiLoaded) {
      // placeholder = "";
      lang = false;
    }

    return (
      <StyledMenu open={this.props.open}>
        <div className={classes.contentMenu}>
          <MegaMenuItems items={this.props.items} />
          <div className={classes.iconItems}>
            <LanguageItems lang={lang} open={this.props.open} />
            <SearchItem />
          </div>
        </div>
      </StyledMenu>
    );
  }
}
