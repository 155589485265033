import React, { Fragment, Component } from "react";
import { Row } from "react-bootstrap";

import Api from "../Api";
import HistoryViews from "../HistoryViews";

import classes from "../history.module.css";

class Desktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: null,
      sections: [],
      isLoaded: false,
      isApiLoaded: false,
      updateValue: 0,
      slideIndex: 0,
      nav1: null,
      nav2: null,
      title: null,
      sectionName: '',
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
    this.next = this.next.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  next() {
    this.slider2.slickNext();
  }

  getSectionDetail() {
    const apiData = new Api();
    apiData
      .getSectionDetail()
      .then((result) => {
        const sections = result.data;
        const widget = [];

        let title = sections.widgets[3].widget_contents[0].content;
        let sectionName = sections.name.toLowerCase().replace(/\s+/g, '-')

        result.data.widgets.forEach((item) => {
          widget.push({
            id: item.id,
            index: item.index,
            category: item.category,
            name: item.name,
            sections: item,
          });
        });
        sections.page = widget;
        this.setState({
          sections: widget,
          title: title,
          sectionName: sectionName,
          isLoaded: true,
        });
      })
      .catch((error) => {
        this.setState({
          isApiLoaded: true,
        });
      });
  }

  render() {
    const { sections } = this.state;

    let name,
      contents,
      year,
      titleContent,
      title,
      historyViews,
      dummyTitleContent,
      contentSlider,
      imageSlider,
      viewAll;

    let count = 0;

    let viewAllContent = "";

    if (this.state.isLoaded) {
      sections.forEach((item) => {
        count += 1;

        name = <p>{item.name}</p>;

        switch (count) {
          case 1:
            viewAllContent = item.sections.widget_contents[0]
              ? item.sections.widget_contents[0].content
              : "view all";

            break;

          case 2:
            // item.sections.widget_contents.forEach((s, i) => {
            //   viewAll = i === 0 ? s.content : "View All";
            // });
            break;
          case 3:
            historyViews = (
              <HistoryViews
                id={item.id}
                title={this.state.title}
                url={item.sections.url ? item.sections.url : "/"}
                viewAll={viewAllContent}
                sectionName={this.state.sectionName}
              />
            );

            contentSlider = item.sections.widget_contents.map((s) => {
              let strYear = s.date;
              let yearFull = new Date(strYear);
              year = yearFull.getFullYear();

              if (s.title !== "") {
                titleContent = <p>{s.title}</p>;
              } else {
                titleContent = dummyTitleContent;
              }

              return (
                <div>
                  <div
                    onChange={(e) => this.slider.slickGoTo(e.target.value)}
                    value={this.state.slideIndex}
                    type="range"
                    min={0}
                    max={4}
                  >
                    {year}
                  </div>
                  <div
                    className={classes.p_col_2}
                    onChange={(e) => this.slider.slickGoTo(e.target.value)}
                    value={this.state.slideIndex}
                    type="range"
                    min={0}
                    max={4}
                  >
                    {titleContent}
                  </div>
                </div>
              );
            });
            imageSlider = item.sections.widget_contents.map((s) => {
              let isImage = s.assets[0] ? (
                <img
                  className={classes.img_col_1}
                  src={s.assets[0].file}
                  alt={s.assets[0].description}
                />
              ) : (
                  <img
                    className={classes.img_col_1}
                    src={require("../assets/default-image.svg")}
                    alt="Default image"
                  />
                );

              return <>{isImage}</>;
            });
            break;
          case 4:
            title = item.sections.widget_contents[0].content;

            // viewAllContent = item.sections.widget_contents[0]
            //   ? item.sections.widget_contents[0].content
            //   : "view all";

            break;
          default:
        }
      });
    } else if (this.state.isApiLoaded) {
      
    }

    return (
      <Fragment>
        <div fluid>
          {/* <div className="container p-0"> */}
          <Row className={classes.row_1}>
            {/* <p className={classes.titleP}>{title}</p> */}
            {historyViews}
          </Row>
          {/* </div> */}
        </div>
      </Fragment>
    );
  }
}

export default Desktop;
