import React, { Component } from "react"
import { Link } from "react-router-dom"

import { Accordion } from "react-bootstrap"
import ContextAwareToggle from "../Accordion/Accordion"

import classes from "./MegaMenuItems.module.css"

export default class MegaMenuItems extends Component {
  render() {
    let content
    let isLogin = localStorage.getItem("isLoggedIn")
    let token = localStorage.getItem("token")
    let dataToken = token ? true : false

    let isHide

    if (isLogin && dataToken) {
      isHide = classes.isHide
    } else {
      isHide = ""
    }

    if (this.props.items) {
      const items = this.props.items
      const groupItem = []
      const item = []
      const jobPosters = []

      let listItems = null
      items.items.forEach((x, i) => {
        if (i === 4) {
          x.children[0].widget.widget_contents.forEach((ele) => {
            jobPosters.push({
              title: ele.title,
              id: ele.id,
            })
          })
        }
        if (x.children) {
          x.children.forEach((m, index) => {
            const child = []
            if (m.children) {
              m.children.forEach((y) => {
                child.push(y)
              })
            } else {
              child.push("none")
            }

            item.push({
              index: m.index,
              id: m.id,
              name: m.title,
              children: child,
            })
          })
          groupItem.push({
            name: x.name,
            items: item,
          })
        } else {
          groupItem.push({
            name: x.name,
            items: "null",
          })
        }

        items.item = groupItem
        listItems = items
      })

      content = listItems.items.map((m, i) => {
        let parentUri = m.url !== "" ? m.url : "/"
        let parent = m.url.includes("http") ? (
          <a href={parentUri} className={classes.customLink} target='_blank' rel='noopener noreferrer'>
            {m.name}
          </a>
        ) : (
          <Link
            onClick={() => {
              setTimeout(() => {
                window.location.reload()
              }, 500)
            }}
            to={parentUri}
            className={classes.customLink}
          >
            {m.name}
          </Link>
        )

        let details
        let icon
        if (m.children) {
          icon = "default"
          let detail = m.children.map((c) => {
            let urls

            if (c.children) {
              urls = c.children.map((itm, i) => {
                let uri, url
                uri = itm.url !== "" ? itm.url : "/"
                if (uri.includes("http")) {
                  url = (
                    <a key={itm.id} href={uri} className={classes.customLink} target='_blank' rel='noopener noreferrer'>
                      <p className={classes.content}>{itm.title}</p>
                    </a>
                  )
                } else {
                  let title, id
                  id = itm.id

                  if (id === 280) {
                    urls = jobPosters.map((j) => {
                      title = j.title
                      let url = "/career/career-list/career-details-page-new/"

                      let urlData = url + j.id
                      return (
                        <Link
                          onClick={() => {
                            setTimeout(() => {
                              window.location.reload()
                            }, 500)
                          }}
                          to={urlData}
                        >
                          <p className={classes.content}>{title}</p>
                        </Link>
                      )
                    })

                    url = (
                      <>
                        <div className='d-flex flex-column'>{urls}</div>
                      </>
                    )
                  } else if (id === 281) {
                    url = <></>
                  } else {
                    url = (
                      <Link
                        onClick={() => {
                          setTimeout(() => {
                            window.location.reload()
                          }, 500)
                        }}
                        key={itm.id}
                        to={uri}
                        className={classes.customLink}
                      >
                        <p className={classes.content}>{itm.title}</p>
                      </Link>
                    )
                  }
                  //   url = (
                  //     <Link key={itm.id} to={uri} className={classes.customLink}>
                  //       <p className={classes.content}>
                  //         {id === 280 ? title : itm.title}
                  //       </p>
                  //     </Link>
                  //   );
                }
                return <>{url}</>
              })
            }

            return <>{urls}</>
          })
          details = <div className={classes.contentDetail}>{detail}</div>
        } else {
          icon = "link"
          details = <></>
        }

        return (
          <div className={classes.boxItem}>
            <div className={classes.titleItem}>
              <ContextAwareToggle eventKey={i + 1} icon={icon}>
                {parent}
              </ContextAwareToggle>
            </div>
            <Accordion.Collapse eventKey={i + 1}>{details}</Accordion.Collapse>
          </div>
        )
      })
    }
    return (
      <Accordion className={classes.customAccordion} defaultActiveKey='0'>
        {content}
      </Accordion>
    )
  }
}
