import React, { Component } from "react"
import ReactHtmlParser from "react-html-parser"
import { Link } from "react-router-dom"

import classes from "./Educations.module.css"
import "./Educations.css"

export default class Educations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dummy: [
        { id: 1, name: "Title", content: "What is Lorem Ipsum?" },
        {
          id: 2,
          name: "Content",
          content: "Where does it come from? Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
        },
        {
          id: 4,
          name: "Background Image",
          assets: require("./assets/default-image.svg"),
        },
      ],

      isDetail: false,
    }
  }

  getHtmlParagraph(str) {
    if (str && str !== "") {
      return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ")
    }
  }

  toggleDetail = () => {
    this.setState((prevState) => {
      return { isDetail: !prevState.isDetail }
    })
  }

  getUrlParser(url) {
    if (url !== "") {
      return url.includes("http") ? (
        <a className={classes.customLink} href={url} target='_blank' rel='noopener noreferrer'>
          <div className={classes.outerCircle}>
            <div className={classes.innerCircle}></div>
          </div>
          <p className={classes.viewMore}>View Details</p>
        </a>
      ) : (
        <Link className={classes.customLink} to={url}>
          <div className={classes.outerCircle}>
            <div className={classes.innerCircle}></div>
          </div>
          <p className={classes.viewMore}>View Details</p>
        </Link>
      )
    } else {
      let isNull = classes.isNull
      return (
        <Link className={[classes.customLink, isNull].join(" ")} to={url}>
          <div className={classes.outerCircle}>
            <div className={classes.innerCircle}></div>
          </div>
          <p className={classes.viewMore}>{this.props.viewDetails}</p>
        </Link>
      )
      // return (
      //   <Link className={classes.customLink} to={url}>
      //     <div className={classes.outerCircle}>
      //       <div className={classes.innerCircle}></div>
      //     </div>
      //     <p className={classes.viewMore}>{this.props.viewDetails}</p>
      //   </Link>
      // );
    }
  }

  render() {
    const { dummy } = this.state
    let title,
      desc,
      img,
      content,
      link = null
    let detailContent,
      contentL1,
      contentL2,
      contentL3,
      contentL4,
      contentL5,
      contentL6,
      contentL7,
      contentL8,
      contentL9,
      contentL10,
      contentL11,
      contentL12,
      contentL13,
      contentL14,
      contentL15,
      contentL16,
      contentL17,
      contentL18 = null
    let contentBox,
      contentBox1,
      contentBox2,
      contentBox3 = null
    if (this.props.items) {
      let items = this.props.items
      title = <h2 className={classes.title}>{items[0].item.title}</h2>
      desc = <span className={classes.content}>{ReactHtmlParser(items[0].item.content)}</span>

      let contentImg = items[0].item.assets[0] 
      img = <img className={[classes.imgContent].join(" ")} src={contentImg ? contentImg.file_small : require("./assets/default-image.svg")} alt={contentImg?.description} />
      content = <div className={classes.contentDesc}>{ReactHtmlParser(items[1].desc.article_language.content)}</div>
      link = !this.state.isDetail ? (
        // <Link className={classes.link} to={{ pathname: `${items[1].desc.url ? items[1].desc.url : '/'}` }}>
        <div className={[classes.contentLink].join(" ")} onClick={this.toggleDetail}>
          <div className='d-flex'>
            <div className={classes.outerCircle}>
              <div className={classes.innerCircle}></div>
            </div>
            <p className={classes.contentLink}>{this.props.readMore}</p>
          </div>
        </div>
      ) : (
        <></>
      )
      // </Link>

      const splitStr = items[0].item.article_language.content.split("#")
      desc = <span className={classes.content}>{ReactHtmlParser(splitStr[0] ? splitStr[0] : "")}</span>
      contentL1 = splitStr[1] ? splitStr[1] : ""
      contentL2 = splitStr[2] ? splitStr[2] : ""
      contentL3 = splitStr[3] ? splitStr[3] : ""
      contentL4 = splitStr[4] ? splitStr[4] : ""
      contentL5 = splitStr[5] ? splitStr[5] : ""
      contentL6 = splitStr[6] ? splitStr[6] : ""
      contentL7 = splitStr[7] ? splitStr[7] : ""
      contentL8 = splitStr[8] ? splitStr[8] : ""
      contentL9 = splitStr[9] ? splitStr[9] : ""
      contentL10 = splitStr[10] ? splitStr[10] : ""
      contentL11 = splitStr[11] ? splitStr[11] : ""
      contentL12 = splitStr[12] ? splitStr[12] : ""
      contentL13 = splitStr[13] ? splitStr[13] : ""
      contentL14 = splitStr[14] ? splitStr[14] : ""
      contentL15 = splitStr[15] ? splitStr[15] : ""
      contentL16 = splitStr[16] ? splitStr[16] : ""
      contentL17 = splitStr[17] ? splitStr[17] : ""
      contentL18 = splitStr[18] ? splitStr[18] : ""

      let isNull
      let contentTitleFix

      let contentTitle = ReactHtmlParser(contentL1)

      //   if (contentL1) {
      //     ReactHtmlParser(contentL1).forEach((m, index) => {
      //       if (m.props.children.length === 0) {
      //         console.log(ReactHtmlParser(contentL1));
      //         isNull = classes.isNull;
      //       } else {

      //         isNull = classes.notNull;
      //       }
      //     });
      //   }

      let contentImg3 = items[0].item.assets[2] 
      let contentImg4 = items[0].item.assets[3] 
      let contentImg5 = items[0].item.assets[4] 
      let contentImg6 = items[0].item.assets[5] 
      let contentImg7 = items[0].item.assets[6] 
      let contentImg8 = items[0].item.assets[7] 
      let contentImg9 = items[0].item.assets[8] 
      let contentImg2 = items[0].item.assets[1] 
      let contentImg10 = items[0].item.assets[9]

      if (this.props.dataExt) {
        this.props.dataExt.forEach((d, i) => {
          switch (i) {
            case 0:
              contentBox = d.sections.map((s, i) => {
                return (
                  <div className={classes.boxX}>
                    <p className={classes.num}>{s.name}</p>
                    <p className={classes.boxxContent}>{s.content}</p>
                  </div>
                )
              })
              break
            case 1:
              contentBox1 = d.sections.map((s, i) => {
                return <span>{s.content}</span>
              })
              break
            case 2:
              contentBox2 = d.sections.map((s, i) => {
                return (
                  <div className={classes.boxX}>
                    <p className={classes.num1}>{s.name}</p>
                    <p className={classes.boxxContent}>{s.content}</p>
                  </div>
                )
              })
              break
            case 3:
              contentBox3 = d.sections.map((s, i) => {
                let url = s.assets ? s.assets[0].file_small || s.assets[0].file : require("./assets/default-image.svg")
                let description = s.assets[0]?.description
                let link = s.url ? this.getUrlParser(s.url) : "/"
                return (
                  <div className={classes.box}>
                    <img src={url} alt={description}></img>
                    <div className={classes.boxMask}></div>
                    {/* <div className={classes.contentFront}>
                      <div className={classes.boxContentIn}>
                        <p>{s.title}</p>
                      </div>
                    </div> */}
                    <div className={classes.contentBack}>
                      <div className={classes.boxContentIn}>
                        <div className='d-flex flex-column text-right mt-auto mb-auto'>
                          <p>{ReactHtmlParser(s.content)}</p>
                          <div className='d-flex flex-row justify-content-end position-relative mt-2'>{link}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
              break
            default:
          }
        })
      }

      let animate = this.state.isDetail ? classes.fadeInBottom : classes.fadeOutTop
      detailContent = this.state.isDetail ? (
        <div className={[classes.detailContent, animate].join(" ")}>
          <div className='section-a'>
            <h2 className={[classes.title, isNull].join(" ")}>{contentTitle}</h2>

            <>
              <hr />
              <div className={classes.allContentB}>
                <div className={classes.txtParB}>
                  <div>
                    <p className={classes.titleCont}>{ReactHtmlParser(contentL2)}</p>
                    <p className={classes.txtReal}>{ReactHtmlParser(contentL3)}</p>
                  </div>
                </div>
                <div className={classes.txtParB2}>
                  <img className={classes.imgLogo} src={contentImg2 ? contentImg2.file_small : require("./assets/default-image.svg")} alt={contentImg2?.description} />
                </div>
              </div>
            </>

            <>
              <hr />
              <div className={classes.allContentC}>
                <div className={classes.boxPar}>
                  <div>
                    <p className={classes.titleCont}>{ReactHtmlParser(contentL4)}</p>
                  </div>
                </div>
                <div className={classes.absImg}>
                  <img className={classes.imgLogo2} src={contentImg3 ? contentImg3.file_small : require("./assets/default-image.svg")} alt={contentImg3?.description} />
                </div>
              </div>
            </>

            <>
              <hr />
              <div className={classes.allContentB}>
                <div className={classes.txtParB}>
                  <div>
                    {/* <p className={classes.pArt}>Title</p> */}
                    <p className={classes.titleCont}>{ReactHtmlParser(contentL5)}</p>
                    <p className={classes.txtReal}>{ReactHtmlParser(contentL6)}</p>
                  </div>
                </div>
                <div className={classes.txtParB2}>
                  <img className={classes.imgLogo} src={contentImg4 ? contentImg4.file_small : require("./assets/default-image.svg")} alt={contentImg4?.description} />
                </div>
              </div>
            </>

            <>
              <hr />
              <div className={classes.allContentC}>
                <div className={classes.boxPar}>
                  <div>
                    <p className={[classes.txtParC, "educations-box-table"].join(" ")}>{ReactHtmlParser(contentL7)}</p>
                  </div>
                </div>
                <div className={classes.absImg}>
                  <img className={classes.imgLogo2} src={contentImg5 ? contentImg5.file_small : require("./assets/default-image.svg")} alt={contentImg5?.description} />
                </div>
              </div>
            </>

            <>
              <hr />
              <div className={classes.allContentB}>
                <div className={classes.txtParB}>
                  <div>
                    <p className={classes.txtReal}>{ReactHtmlParser(contentL8)}</p>
                  </div>
                </div>
                <div className={classes.txtParB2}>
                  <img className={classes.imgLogo} src={contentImg6 ? contentImg6.file_small : require("./assets/default-image.svg")} alt={contentImg6?.description} />
                </div>
              </div>
            </>

            <>
              <hr />
              <div className={classes.allContentC}>
                <div className={classes.boxPar}>
                  <div>
                    <p className={[classes.txtParC, "educations-box-table"].join(" ")}>{ReactHtmlParser(contentL9)}</p>
                  </div>
                </div>
                <div className={classes.absImg}>
                  <img className={classes.imgLogo2} src={contentImg7 ? contentImg7.file_small : require("./assets/default-image.svg")} alt={contentImg7?.description} />
                </div>
              </div>
            </>

            <>
              <h2 className={classes.title}>{ReactHtmlParser(contentL10)}</h2>
              <hr />
              <div className={classes.allContentB}>
                <div className={classes.txtParB}>
                  <div>
                    <p className={classes.txtReal}>{ReactHtmlParser(contentL11)}</p>
                  </div>
                </div>
                <div className={classes.txtParB2}>
                  <img className={classes.imgLogo} src={contentImg8 ? contentImg8.file_small : require("./assets/default-image.svg")} alt={contentImg8?.description} />
                </div>
              </div>
            </>

            <>
              <hr />
              <div className={classes.allContentC}>
                <div className={classes.boxPar}>
                  <div>
                    <p className={classes.txtParC}>{ReactHtmlParser(contentL12)}</p>
                  </div>
                </div>
                <div className={classes.absImg}>
                  <img className={classes.imgLogo2} src={contentImg9 ? contentImg9.file_small : require("./assets/default-image.svg")} alt={contentImg9?.description} />
                </div>
              </div>
            </>

            <>
              <hr />
              <div className={classes.allContentB}>
                <div className={classes.txtParB}>
                  <div>
                    <p className={classes.txtReal}>
                      {ReactHtmlParser(contentL13)}
                      {ReactHtmlParser(contentL14)}
                    </p>
                  </div>
                </div>
                <div className={classes.txtParB2}>
                  <img className={classes.imgLogo} src={contentImg10 ? contentImg10.file_small : require("./assets/default-image.svg")} alt={contentImg10?.description} />
                </div>
              </div>
            </>
          </div>

          <div className={[classes.contentLink].join(" ")} onClick={this.toggleDetail}>
            <div className='d-flex'>
              <div className={classes.outerCircle}>
                <div className={classes.innerCircle}></div>
              </div>
              <p className={classes.contentLink}>{this.props.readLess}</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )
    } else {
      title = <h2 className={classes.title}>{/* {dummy[0].content} */}</h2>
      desc = (
        <p className={classes.content}>
          <></>
          {/* {dummy[1].content} */}
        </p>
      )
      img = <img className={classes.imgContentD} src={dummy[2].assets} alt='' />
      content = (
        <div>
          <p>{/* Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book */}</p>
          <br />
          <p>{/* Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book */}</p>
          <br />
          <p>{/* Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book */}</p>
        </div>
      )
    }
    let customMargin = !this.state.isDetail ? "mb-5" : ""

    return (
      <div className={classes.sectionEducations}>
        <div className={["container d-flex flex-column", classes.customContainer].join(" ")}>
          <div className={classes.boxImage}>
            <div className={classes.animate}>{img}</div>
          </div>
          <div className={["d-flex", customMargin].join(" ")}>
            <div className={classes.boxContent}>
              {title}
              {desc}
              {link}
            </div>
            <div className={classes.boxOverflow}>
              <div className={classes.boxContentOverflow}>{content}</div>
            </div>
          </div>

          {detailContent}
        </div>
      </div>
    )
  }
}
