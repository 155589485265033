import React from "react";
import classes from "./Mobile.module.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "./Mobile.css"

export default function AccordionMobile(props) {
  let content;
  // const settings = {
  //   className: "",
  //   infinite: false,
  //   centerPadding: "",
  //   variableWidth: true,
  //   slidesToShow: 1,
  //   swipeToSlide: true,
  // };

  const settings = {
    className: "",
    dots: true,
    slidesToShow: 1,
    autoplay: false,
    swipeToSlide: true,
    appendDots: (dots) => (
      <div
        style={{
          backgroundColor: "transparent",
          borderRadius: "10px",
          paddingBottom: "2rem",
        }}
      >
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          color: "#d6d6d6",
          border: "1px solid #d6d6d6",
          borderRadius: "50%",
          marginRight: "2px",
        }}
      ></div>
    ),
  };

  if (props.data) {
    content = props.data.map((d, i) => {
      let isSrc = d.assets[0]
        ? d.assets[0].file_small
        : require("../assets/default-image.svg");

      let srcSmall = d.assets[1]
        ? d.assets[1].file
        : require("../assets/default-image.svg");

      let isTitle = d.article_language.title;
      let isUrl = d.article_language.url;

      return (
        <div className={classes.bxprt01}>
          <div className={classes.bxprt01_1}>
            <Link to={isUrl}>
              <div className={classes.urgent}>
                <img className={classes.isImgSmall} src={srcSmall} alt=" " />
                <p className={classes.isText}>{isTitle}</p>
              </div>
            </Link>
          </div>
          <img className={classes.isImg2} src={isSrc} alt=" " />
        </div>
      );
    });
  }

  return (
    <div id="slide">
      <Slider {...settings}>{content}</Slider>
    </div>
  );
}
