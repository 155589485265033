import * as React from "react";
import classes from "./Mobile.module.css";
import clases from "./Navbar.module.css";

export default function Navbar(props) {
  let nav,
    sticky,
    filters,
    activeBar = null;

  let lang = localStorage.getItem('language')
  let defaultFilter = (lang !== "English" ? 'Tin tức và Sự kiện' : 'News and Events')
  let selectedFilter = props.selectedFilter !== '' ? props.selectedFilter : defaultFilter
  console.log(props)
  if (props.category) {
    let arrCategory = props.category
    let newArrCategory = []
    newArrCategory.push({
      id: 99,
      name: props.filterMagazine
    })

    const newArr = new Set()
    const filteredArr = arrCategory.concat(newArrCategory).filter(el => {
      const duplicate = newArr.has(el.id);
      newArr.add(el.id);
      return !duplicate;
    });

    filters = filteredArr.map((f) => {
      let isSelected
      if (props.paramValue) {
        isSelected = parseInt(f.id) === parseInt(props.paramValue) ? true : false
      } else {
        isSelected = f === selectedFilter ? true : false
      }
      return (
        <option value={f.id} selected={isSelected}>
          {f.name}
        </option>
      )
    })
  }

  if (props.sText === "search") {
    activeBar = clases.activeBar;
  } else {
    activeBar = "";
  }

  sticky = clases.sticky;

  nav = (
    <div className={classes.boxNew}>
      <select
        name="filter"
        id="filter"
        className={classes.customSelect2}
        onChange={props.onChange}
      >
        {filters}
      </select>
      <div className={[clases.boxSearch2, activeBar].join(" ")}>
        <div className={clases.inputSearch}>
          <input
            onKeyDown={props.onKeyDown}
            className={clases.inputBox}
            type="text"
            placeholder={props.searchHere}
          />
          <div className={clases.boxImgS}>
            <img src={require("./assets/search-icon.svg")} alt="" />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={[clases.customNavbar, sticky].join(" ")}>
      <div className={clases.navbar}>{nav}</div>
    </div>
  );
}
