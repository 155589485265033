import React, { Component } from "react";
import Career1 from "./C_Section1/CO";
import Navbar from "./Navbar/Navbar";
import Api from "./api";
import classes from "./C_Section2/C_Section2.module.css";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import dateFormat from "dateformat";
import "./C_Section2/C_Section2.css";

export default class Career extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentSection: [],
      contentArticle: {
        widgets: [],
      },
      sections: [],
      app: null,
      text: "",
      dummy: [{}],
      careerId: null,
      categories: [],
      IdArt: null,
      counts: 0,
      filtered: "",
      search: "",
      ss: "",
      url: "",
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
    this.filterData = this.filterData.bind(this);
    this.getArticles = this.getArticles.bind(this);
    this.getData = this.getData.bind(this);
  }
  componentDidMount() {
    this.getSectionDetail();
  }

  filterData(e, i) {
    this.setState({
      filtered: e,
      activeBtn: i,
    });
    this.getArticles(this.state.IdArt, e);
  }

  getData(e) {
    let ss;
    if (e.keyCode === 13 && e.target.value !== "") {
      ss = e.target.value;

      this.setState({
        search: ss,
      });

      this.getArticles(this.state.IdArt, "", "", ss);
    }
  }

  getSectionDetail() {
    const articleCollection = [];
    const apiData = new Api();
    this.setState(
      {
        sections: [],
        articleArray: [],
        categories: [],
      },
      () => {
        apiData
          .getSectionDetail()
          .then((result) => {
            const sections = result.data;
            const widget = [];
            const dataCat = [];
            dataCat.push(sections.widgets[1].article_categories);

            let getUrl = sections.widgets[1].url;

            let textAv = sections.widgets[3].widget_contents[0]
              ? sections.widgets[3].widget_contents[0].content
              : "Available";

            result.data.widgets.forEach((item) => {
              if (item.category === "Article" && !item.is_internal) {
                this.getArticles(item.id, "", "", this.state.search);
              }
              widget.push({
                id: item.id,
                index: item.index,
                category: item.category,
                name: item.name,
                sections: item,
                contentItems: 20,
              });
            });

            sections.page = widget;
            this.setState({
              sectionName: result.data.name,
              sections: widget,
              categories: dataCat,
              isLoaded: true,
              url: getUrl,
              IdArt: widget[1].id,
              articleArray: articleCollection,
              text: textAv,
            });
          })
          .catch((error) => {
            this.setState({ notLoaded: true });
          });
      }
    );
  }

  getArticles(param, filter, articleURL, search) {
    const apiData = new Api();
    this.setState({ contentArticle: [] }, () => {
      apiData
        .getArticleDetailByYear(param, filter, articleURL, search)
        .then((result) => {
          const articles = result.data;
          const art = [];
          let count = articles.count;

          articles.results.forEach((a) => {
            const assets = [];

            a.article_assets.forEach((ass, i) => {
              if (i === 0) {
                assets.push({ asset: ass.asset_file });
              }
            });

            art.push({
              id: a.id,
              category: a.category_name,
              name: a.name,
              title: a.article_languages.title,
              content: a.article_languages.content,
              assets: assets,
              date: a.date,
              url: articleURL + "/" + a.id,
              isLoaded: true,
            });
          });

          this.setState({
            contentArticle: art,
            counts: count,
          });
        })
        .catch((error) => {
          this.setState({ notLoaded: true });
        });
    });
  }
  
  render() {
    const { isLoaded, sections, contentArticle } = this.state;
    let navData, positions, categories, thisUrl, isUrl;
    if (isLoaded) {
      sections.forEach((ele, index) => {
        switch (index) {
          case 0:
            break;
          case 1:
            isUrl = ele.sections.url;
            break;
          case 2:
            break;
          case 3:
            break;
          case 4:
            break;
          case 5:
            break;
          case 6:
            navData = ele.sections.widget_contents;
            break;
          default:
        }
      });

      const arr = contentArticle.reverse();
      positions = arr.map((z, index) => {
        thisUrl = z.url ? isUrl + z.url : "/";
        let strDate = z.date;
        let getDate = dateFormat(strDate, "dd-mm-yyyy");
        return (
          <Link to={thisUrl}>
            <Card className={[classes.box, classes.margcustom].join(" ")}>
              <div className={classes.cardContent}>
                <div className={classes.boxTop}>
                  <div className={classes.cardStatus}>
                    <p className={[classes.text, classes.font_18].join(" ")}>
                      {z.category}
                    </p>
                  </div>
                  <h4 className={classes.titleCard}>{z.title}</h4>
                </div>
                <div className={classes.boxBottom}>
                  <div>
                    <p className={[classes.text, classes.font_16].join(" ")}>
                      {getDate}
                    </p>
                  </div>
                </div>
              </div>
            </Card>
          </Link>
        );
      });

      categories = this.state.categories[0].map((category, index) => {
        let selected = null;
        if (this.state.activeBtn === index) {
          selected = classes.selected;
        }
        return (
          <div
            className={["cat-button", selected].join(" ")}
            onClick={() => this.filterData(category, index)}
          >
            {category}
          </div>
        );
      });
    }

    // categories = this.state.categories.map((category, index) => {
    //   let selected = null;
    //   if (this.state.activeBtn === index) {
    //     selected = classes.selected;
    //   }
    //   return (
    //     <div
    //       className={["cat-button", selected].join(" ")}
    //       onClick={() => this.filterData(category, index)}
    //     >
    //       {category}
    //     </div>
    //   );
    // });

    return (
      <div>
        <Navbar
          widget={navData}
          cat={this.state.categories ? this.state.categories[0] : ""}
          onChange={this.onFilterChange}
          onKeyDown={this.getData}
        />
        <Career1 />
        <div className={["container", classes.cusT].join(" ")}>
          <div className="category">
            {categories}
            <div className="cat-button-plus">
              <p>+</p>
            </div>
          </div>
          <div className={classes.bottomContent}>
            <div className="d-flex flex-wrap justify-content-between">
              {positions}
            </div>
          </div>
        </div>
        {/* {career} */}
        {/* <Career2 onKeyDown={this.getData} search={this.state.search} /> */}
      </div>
    );
  }
}
