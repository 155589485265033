import React from 'react'

import { isTablet, isMobile } from 'react-device-detect';

import classes from './Navbar.module.css'

export default function Navbar(props) {
    let isActive, isActiveBox
    if (isTablet) {
        isActive = props.isActive ? props.title : <></>
        isActiveBox = props.isActive ? classes.customBoxA : classes.customBox
    }

    let spanEnStyle
    let lang = localStorage.getItem("language");
    if (lang === "English") {
        spanEnStyle = classes.navEn
    }

    let boxs = props.box === 'B' ? classes.boxsB : classes.boxsO
    let navbar = !isTablet ?
        <>
            <div
                key={props.idx}
                className={[classes.Navbar, props.isActive ? classes.Navbar___active : "", spanEnStyle].join(" ")}
                onClick={() => { props.onClick.toogleCompany(props.idx + 1) }}
            >
                <div className="d-flex">
                    {/* <img className={classes.imgIcon} src={props.img} alt=""></img> */}
                    <span
                        id={props.key}
                        className={classes.customSpan}
                        data-hover={props.title}
                    >
                        {props.title}
                    </span>
                </div>
                <div className={classes.Content}>
                    {
                        props.contents.map((item, idx) => {
                            const activeClass = item.id === props.activeTabId ? classes.Content_item___active : "";
                            return (
                                <div
                                    className={[classes.Content_item, activeClass].join(" ")}
                                    onClick={() => {
                                        props.onClick.toogleCompany(props.idx + 1, () => {
                                            props.onClick.showingProfile(idx + 1, item.id)
                                        })
                                    }}
                                >
                                    {item.article_language.title}
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        </> :
        <div key={props.idx} className={[boxs, props.active].join(" ")} onClick={props.onClick}>
            <div className={isActiveBox}>
                <img className={classes.imgIcon} src={props.img} alt=""></img>
                <span id={props.key} >
                    {isActive}
                </span>
            </div>
        </div>
    return (
        <>
            {navbar}
        </>
    )
}
