import React, { Component } from 'react'
import ReactHtmlParser from "react-html-parser";
import Api from '../Api'
import classes from './DesktopSec3.module.css'

export default class Desktop extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contentSection: [],
      isLoaded: false,
      isApiLoaded: false,
    }
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail = () => {
    const apiData = new Api();
    apiData
      .getSectionDetail()
      .then((result) => {
        const sections = result.data;
        const widget = [];
        sections.widgets.forEach((item) => {
          widget.push({
            id: item.id,
            index: item.index,
            category: item.category,
            name: item.name,
            sections: item,
          });
        });

        this.setState({
          contentSection: widget,
          isLoaded: true,
        });
      })
      .catch((error) => {
        this.setState({
          isApiLoaded: true,
        });
        window.location.assign(
          `/error/${error.response.status ? error.response.status : 404}`
        );
      });
  }

  getExt = (str) => {
    let chunkedStr = str.split(".")
    return chunkedStr[chunkedStr.length - 1];
  }

  render() {
    const { contentSection } = this.state

    let title1, content, title2, cards, swiperCard;
    if (this.state.isLoaded) {
      contentSection.forEach(el => {
        if (el.index === 1 && el.category === "Article") {
          cards = el.sections.widget_contents.map((e, i) => {
            let imgSrc;
            let imgExt = new Set(["svg", "jpg", "jpeg", "png"]);
            if (e.assets.length) {
              if (e.assets[0].asset_type === "Image") {
                imgSrc = e.assets[0].file_small
              } else if (imgExt.has(this.getExt(e.assets[0].file))) {
                imgSrc = e.assets[0].file
              }
            }
            return (
              <div className={classes.card}>
                <div className={classes.figure}>
                  <img
                    className={classes.imgLeft}
                    src={imgSrc ? imgSrc : require('../assets/default-image.svg')}
                    alt={imgSrc ? e.assets[0].description : null}
                  />
                </div>
                <div className={classes.content}>
                  <div className={classes.cardTitle}>{e.article_language.title}</div>
                  <div className={classes.cardContent}>{ReactHtmlParser(e.article_language.content)}</div>
                </div>
              </div>
            )
          });

        }
      })
    }

    return (
      <div className={classes.section}>
        <div className={["container", classes.customContainer].join(" ")}>
          {cards}
        </div>
      </div>
    )
  }
}
