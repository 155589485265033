import React, { Component } from 'react';

import classes from './Banner.module.css'

class Banner extends Component {
    constructor(props) {
        super(props);
        
    }
    

    render() {
        return (
            <div>
                <div className={classes.banner}>
                    <img className={classes.imgBanner} src={this.props.bannerUrl} alt=''></img>
                    <div className={classes.contentRunning}>
                        <h1 className={classes.textRunning}>{this.props.runningText}</h1>
                        <div className={classes.underlineAnimate}></div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Banner;