import React from "react"
import Desktop from "./Desktop"
import Mobile from "./Mobile/Mobile"
import { isMobile, isTablet } from "react-device-detect"
import Tablet from "./Tablet/Tablet"
import { useMediaQuery } from "react-responsive"

export default function AU_History(props) {
  let content

  const isM = useMediaQuery({
    query: "(min-device-width: 411px) and (max-device-width: 479px)",
  })

  if (isTablet) {
    content = <Tablet updateLogo={props.updateLogo} override={props.override1} darkSection={props.darkSection}/>
  } else if (isMobile || isM) {
    content = <Mobile />
  } else {
    content = <Desktop updateLogo={props.updateLogo} override={props.override1} darkSection={props.darkSection}/>
  }

  return <>{content}</>
}
