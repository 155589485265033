import React, { Component } from "react";

import Api from "../Api";
import classes from "./Section1.module.css";

export default class Section1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentSection: [],
            isLoaded: false,
            isApiLoaded: true,
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ contentSection: [] }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    const sections = result.data;
                    const widget = [];

                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            index: item.index,
                            category: item.category,
                            is_internal: item.is_internal,
                            name: item.name,
                            sections: item,
                        });
                    });

                    sections.page = widget;
                    this.setState({
                        contentSection: widget,
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    this.setState({
                        isApiLoaded: true,
                    });
                });
        });
    }

    render() {
        const { dummy, contentSection } = this.state;
        let dummyTitle,
            dummyImg,
            title,
            Img;

        if (this.state.isLoaded) {
            let count = 0
            contentSection.forEach((item) => {
                count = count + 1
                if (count === 1 && item.index === 1 && item.category === "Text") {
                    item.sections.widget_contents.forEach((s) => {
                        if (s.content !== "") {
                            title =
                                <div className={classes.contentBanner} >
                                    <h1 className={classes.title} style={{color: s.font_color}}>{s.content}</h1>
                                    <div className={[classes.p_0_underline]} style={{backgroundColor: s.font_color}}></div>
                                </div>
                        } else {
                            title = dummyTitle
                        }
                    })
                } else if (count === 1 && item.index === 1 && item.category !== "Text") {
                    title = dummyTitle
                }

                if (count === 2 && item.index === 2 && item.category === "Media") {
                    item.sections.widget_contents.forEach((s) => {
                        if (s.file) {
                            Img = <img className={classes.img} src={s.file_small} alt={s.description}></img>
                        } else {
                            Img = dummyImg
                        }
                    })
                } else if (count === 2 && item.index === 2 && item.category !== "Media") {
                    Img = dummyImg
                }

            })
        }

        return (
            <div className={classes.contentSection}>
                <div className={classes.contentImage}>{Img}</div>
                <div className={classes.contentTitle}>
                    {title}
                </div>
            </div>
        );
    }
}
