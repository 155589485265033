import React, { Component } from 'react'

import classes from './Icon.module.css'

export default class Icon extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };

    }

    componentDidMount() {

    }

    render() {
        const data = this.props.data
        const index = String(this.props.idx)
        let icons = null

        if (data && index) {
            data.forEach((s, j) => {
                if (s.category === "Media" && j === 0) {
                    console.log(parseInt(index), s.sections.widget_contents.length, parseInt(index) < s.sections.widget_contents.length)
                    if (parseInt(index) < s.sections.widget_contents.length) {
                        icons = s.sections.widget_contents.map((k, i) => {
                            if (i < 7 && String(i) === index) {
                                let style = index < 4 ? classes.iconL : classes.iconR
                                return (
                                    <div className={[classes.titleIcon, style].join(' ')}>
                                        <img src={k ? k.file : require('../assets/logo.svg')} alt=""></img>
                                    </div>
                                )
                            }
                        })
                    } else {
                        let style = index < 4 ? classes.iconL : classes.iconR
                        icons =
                            <div className={[classes.titleIcon, style].join(' ')} >
                                <img src={require('../assets/icon.svg')} style={{objectFit: 'contain'}} alt=""></img>
                            </div>
                    }

                }
            })
        }
        return icons
    }
}
