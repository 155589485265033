import React from "react";
import Sliders from "react-slick";

import { useMediaQuery } from "react-responsive";

export default function Slider(props) {
  const isMobile = useMediaQuery({
    query: "(min-device-width: 480px)",
  });

  const isGalaxy = useMediaQuery({
    query: "max-device-width: 320px)",
  });

  const Action = (params) => {
    let slideTtoShow = null;

    if (isGalaxy) {
      slideTtoShow = 1;
    } else if (isMobile) {
      slideTtoShow = 3;
    } else {
      slideTtoShow = 2;
    }
    // switch (params) {
    //   case "top":
    //     slideTtoShow = isMobile && params === "top" ? 3 : 4;
    //     break;
    //   default:
    //     slideTtoShow = 1;
    // }

    return {
      className: "",
      infinite: false,
      centerPadding: "",
      // dots: true,
      variableWidth: true,
      slidesToShow: slideTtoShow,
      swipeToSlide: true,
    };
  };

  let settings = Action(props.setting);
  return <Sliders {...settings}>{props.content}</Sliders>;
}
