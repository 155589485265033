import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import UserContext from "../../../../Context";
import Api from "../../../../../Api";

import classes from "./Modal.module.css";
export default class ModalAvatar extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      file: [],
      id: 0,
      data: [],
    };
    this.uploadAvatar = this.uploadAvatar.bind(this);
    this.resetFile = this.resetFile.bind(this);
  }

  componentDidMount() {
    const contextValue = this.context;
    this.setState({
      id: contextValue.idApplicant,
      data: contextValue.contentSection[9].sections.widget_contents,
    });
  }

  uploadAvatar() {
    const resume = new Api();
    const contextValue = this.context;

    resume
      .updateApplicant(this.state.id, this.state.file, "avatar")
      .then((res) => {
        console.log(res);
        this.props.close();
        contextValue.getNewDataApplicant();
        contextValue.showNotification(
          "Your avatar has been successfully updated."
        );
      })
      .catch((err) => {
        console.log("error =>", err);
      });
  }

  handleChangeFile(e) {
    for (let index = 0; index < e.target.files.length; index++) {
      let newFile = e.target.files[index];
      this.setState((prevState) => {
        const file = prevState.file.concat(newFile);
        return { file };
      });
    }
  }

  removeFileUploader(i) {
    this.setState((state) => {
      const file = state.file.filter((item, j) => i !== j);
      return {
        file,
      };
    });
  }

  resetFile() {
    this.setState({
      file: [],
    });
  }

  render() {
    const { file } = this.state;
    let title, info, cancel, save, uploadFile;

    this.state.data.forEach((x, i) => {
      switch (i) {
        case 10:
          title = x.content;
          break;
        case 11:
          info = x.content;
          break;
        case 12:
          cancel = x.content;
          break;
        case 13:
          save = x.content;
          break;
        case 15:
          uploadFile = x.content;
          break;
        default:
      }
    });

    let contentUpload;
    if (file) {
      contentUpload = file.map((f, i) => {
        return (
          <div className={classes.boxFile}>
            <span>{f.name}</span>
            <FontAwesomeIcon
              color="#000000"
              className={classes.iconClose}
              icon={faTimes}
              size="md"
              onClick={() => this.removeFileUploader(i)}
            />
          </div>
        );
      });
    }

    return (
      <Modal
        size={"md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.show}
        onHide={() => {
          this.props.close();
          this.resetFile();
        }}
      >
        <Modal.Header
          className={classes.modalHeader}
          onClick={this.props.close}
          closeButton
        >
          <div className="d-flex justify-content-between">
            <span className={classes.modalTitle}>{title}</span>
          </div>
        </Modal.Header>

        <UserContext.Consumer>
          {(context) => (
            <div className={classes.modalContent}>
              <div className={classes.content}>
                <div className={classes.contentUpload}>{contentUpload}</div>

                <input
                  type="file"
                  id="btn-upload"
                  onChange={(e) => this.handleChangeFile(e)}
                  hidden
                />
                <label htmlFor="btn-upload" className={classes.btnFullAction}>
                  <div className={classes.contentBtn}>
                    <div className={classes.circle}>
                      <img src={require("../../../assets/add.svg")} alt="" />
                    </div>
                    <span>{uploadFile}</span>
                  </div>
                </label>
                <span className={classes.note}>{info}</span>
              </div>
              <div className={classes.modalAction}>
                <div className={classes.cancel}>
                  <span>{cancel}</span>
                </div>
                <div
                  className={classes.save}
                  onClick={() => {
                    this.uploadAvatar();
                  }}
                >
                  <span>{save}</span>
                </div>
              </div>
            </div>
          )}
        </UserContext.Consumer>
      </Modal>
    );
  }
}
