import React, { Component, createRef } from "react"
import ReactHtmlParser from "react-html-parser"
import BannerIcon from "./BannerIcon"
import Api from "../Api"
import classes from "./Desktop.module.css"

class Desktop extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      sections: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
      selectedBox: null,
      activeBox: 0,
    }
    this.toggleHandlerEnter = this.toggleHandlerEnter.bind(this)
    this.getSectionDetail = this.getSectionDetail.bind(this)
    this.updateSelectedBox = this.updateSelectedBox.bind(this)
    this.scrollingHandlerBs = this.scrollingHandlerBs.bind(this)
    this.handleOverride = this.handleOverride.bind(this)
  }

  componentDidMount() {
    this.getSectionDetail()
    window.addEventListener("scroll", this.scrollingHandlerBs, true)
    this.handleOverride()
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollingHandlerBs, true)
  }

  bs_sec1_div1 = createRef()
  bs_sec1_div2 = createRef()

  handleOverride() {
    this.props.override(false)
  }

  scrollingHandlerBs() {
    if (this.bs_sec1_div1.current.getBoundingClientRect().bottom < this.bs_sec1_div1.current.getBoundingClientRect().height && this.bs_sec1_div1.current.getBoundingClientRect().bottom > -1) {
      this.props.updateLogo(true)
    }

    if (this.bs_sec1_div2.current.getBoundingClientRect().bottom < this.bs_sec1_div2.current.getBoundingClientRect().height && this.bs_sec1_div2.current.getBoundingClientRect().bottom > -1) {
      this.props.updateLogo(false)
    }
  }
  getSectionDetail() {
    const apiData = new Api()
    this.setState({ sections: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          this.setState({
            isApiLoaded: true,
          })
          const sections = result.data
          const widget = []
          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            })
          })
          sections.page = widget
          this.setState({
            sections: widget,
            isLoaded: true,
          })
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          })
        })
    })
  }

  updateSelectedBox(index) {
    this.setState({
      activeBox: index,
    })
  }

  toggleHandlerEnter = () => {
    this.setState((prevState) => {
      return {
        isOpen: !prevState.isOpen,
      }
    })
  }

  isBanner = (url) => {
    let content
    if (url !== "") {
      let type = [".png", ".jpg", ".jpeg"]
      let myArr = url.split()
      let result = myArr.some((i) => type.includes(i))

      if (result) {
        content = <img className={classes.imgBanner} src={url} alt=''></img>
      } else {
        content = (
          <video autoPlay loop muted>
            <source src={url} type='video/mp4' />
          </video>
        )
      }
    } else {
      content = <img className={classes.imgBanner} src={require("../assets/default-image.svg")} alt='Default image'></img>
    }
    return content
  }

  render() {
    const { sections } = this.state
    let titleBanner,
      colorTitleBanner,
      imageBanner,
      contentSection,
      infoBoxes,
      videoPhenikaa,
      content = null
    if (this.state.isLoaded) {
      let count = 0
      sections.forEach((item) => {
        count = count + 1
        if (item.index === 1 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            colorTitleBanner = s.font_color
            if (s.content !== "") {
              titleBanner = s.content
            }
          })
        } else if (item.index === 1 && item.category !== "Text") {
          titleBanner = ""
        }
        if (item.index === 2 && item.category === "Media") {
          item.sections.widget_contents.forEach((s) => {
            imageBanner = this.isBanner(s.file ? s.file : require("../assets/default-image.svg"))
          })
        } else if (item.index === 2 && item.category !== "Media") {
          imageBanner = <img className={classes.imageBanner} src={require("../assets/default-image.svg")} alt='Default image'></img>
        }
        if (item.index === 3 && item.category === "Article") {
          contentSection = item.sections.widget_contents.map((s) => {
            return (
              <>
                <h2 className={[classes.titleContent2, classes.customMargin].join(" ")}>{s.article_language.title}</h2>
                <p className={classes.content}>{ReactHtmlParser(s.article_language.content)}</p>
              </>
            )
          })
        } else if (item.index === 3 && item.category !== "Article") {
        }
        if (item.index === 4 && item.category === "Article") {
          infoBoxes = item.sections.widget_contents.map((s, index) => {
            if (this.state.activeBox === index) {
              var classtitle = classes.titleContentBold
              content = ReactHtmlParser(item.sections.widget_contents[index].article_language.content)
            }

            return (
              <div className={classes.wrapBox} onClick={(e) => this.updateSelectedBox(index)}>
                <div className={classes.boxBelow}>
                  <img src={s.assets[0] ? s.assets[0].file : null} alt={s.assets[0] ? s.assets[0].description : null} />
                  <div className={[classes.textBelow, classtitle].join(" ")}>
                    <p>{s.title}</p>
                  </div>
                  {/* <span className={classes.content}>{ReactHtmlParser(s.content)}</span> */}
                </div>
              </div>
            )
          })
        } else if (item.index === 4 && item.category !== "Article") {
        }

        if (item.index === 5 && item.category === "Media") {
          videoPhenikaa = item.sections.widget_contents.map((s) => {
            var filesplit = s.file.split(".")
            var filetype = filesplit[filesplit.length - 1]
            if (filetype === "mp4") {
              return (
                <video className={classes.isvideo} controls autoPlay loop muted>
                  <source src={s.file} type='video/mp4' />
                </video>
              )
            } else {
              return <img className={classes.houseOfPhenikaa} src={s.file} alt='' />
            }
          })
        }
      })
    } else if (this.state.isApiLoaded) {
      imageBanner = <img className={classes.imageBanner} src={require("../assets/default-image.svg")} alt='Default image' />

      titleBanner = ""

      contentSection = (
        <>
          <p className={[classes.titleContent2, classes.customMargin].join(" ")}></p>
          <p className={classes.content}></p>
        </>
      )

      if (this.state.activeBox === 0) {
        var classtitle = classes.titleContentBold
      }

      infoBoxes = (
        <div className={classes.wrapBox} onClick={(e) => this.updateSelectedBox(0)}>
          <div className={classes.boxBelow}>
            <img src={require("../assets/default-image.svg")} alt='' />
            <div className={[classes.textBelow, classtitle].join(" ")}>
              <p></p>
            </div>
          </div>
        </div>
      )

      content = <p></p>
    }

    // let contentCore = this.state.isOpen ? (
    //   <Circuit newStyle={classes.isAbsolute} />
    // ) : (
    //   <Circuit />
    // );

    // let houseOfPhenikaa = <HouseofPhenikaa />
    let houseOfPhenikaa = videoPhenikaa

    return (
      <div id='sectionEcosystem' className={["section-ecosystem", classes.sectionEcosystem].join(" ")}>
        <div ref={this.bs_sec1_div1} className={classes.banner}>
          {imageBanner}
          <div className={classes.bannerIcon}>
            <BannerIcon />
          </div>
          <div className={classes.bannerContent}>
            <h1 className={[classes.titleBanner, classes.animate].join(" ")} style={{color: colorTitleBanner}}>{titleBanner}</h1>
            <div className={[classes.underlineAnimate]} style={{backgroundColor: colorTitleBanner}}></div>
          </div>
        </div>
        <div ref={this.bs_sec1_div2} className={["container", classes.cusTom].join(" ")}>
          <div className={[classes.boxContent].join(" ")}>
            <div className={["d-flex", classes.pp].join(" ")}>
              <div className={classes.boxLeft}>{contentSection}</div>
              <div className={classes.boxRight}>{houseOfPhenikaa}</div>
            </div>
            <div className={classes.infoBoxes}>{infoBoxes}</div>
            <div className={classes.content}>{content}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default Desktop
