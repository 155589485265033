import React, { Component, Fragment } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import classes from "./Mobile.module.css";
import Api from "../Api";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";
import Swiper from "react-id-swiper";
import "swiper/swiper.scss";

class Mobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: null,
      sections: {
        widgets: [],
      },

      awards_1: [],
      awards_2: [],

      isLoaded: false,
      isApiLoaded: false,
      sectionTitle: "",
      sectionDescription: "",
      allAwardsUrl: "",
      awardDetails1: "",
      awardDetails2: "",
      latestAwardsButtonText: "",
      highlightAwardsButtonText: "",
      allAwardsButtonText: "",

      hoverMode: "",
      tabIndex: 1,
      sectionName: "",
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
    this.hiddenBackground = this.hiddenBackground.bind(this);
    this.changeBackground = this.changeBackground.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.setCurrentLink = this.setCurrentLink.bind(this);

    this.makeSliderElements = this.makeSliderElements.bind(this);
    this.makeImageHover = this.makeImageHover.bind(this);
    this.getArticles = this.getArticles.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getArticles(param, slider) {
    const apiData = new Api();
    this.setState(
      {
        contentArticle: [],
      },
      () => {
        apiData
          .getArticleDetail(param)
          .then((result) => {
            const articles = result.data;
            const art = [];
            articles.results.forEach((a) => {
              const assets = [];
              a.article_assets.forEach((ass, i) => {
                if (i === 0) {
                  assets.push({ 
                    asset: ass.asset_file, 
                    description : ass.description
                  });
                }
              });

              art.push({
                id: a.id,
                category: a.category_name,
                name: a.name,
                title: a.article_languages.title,
                url_title: a.article_languages.slug_title,
                content: a.article_languages.summary,
                summary: a.article_languages.summary,
                assets: assets,
                date: a.date,
              });
            });

            switch (slider) {
              case 1:
                this.setState({ awards_1: art, isLoaded: true });
                break;

              case 2:
                this.setState({ awards_2: art, isLoaded: true });
                break;
              default:
                break;
            }
          })
          .catch((error) => {
            this.setState({ isApiLoaded: true });
          });
      }
    );
  }

  getSectionDetail() {
    const apiData = new Api();
    var slider = 1;
    this.setState(
      {
        sections: [],
        awards_1: [],
        awards_2: [],
      },
      () => {
        apiData
          .getSectionDetail()
          .then((result) => {
            this.setState({ isApiLoaded: true });
            const sections = result.data;
            const widget = [];
            let sectionName = sections.name.toLowerCase().replace(/\s+/g, "-");

            result.data.widgets.forEach((item) => {
              widget.push({
                id: item.id,
                index: item.index,
                category: item.category,
                name: item.name,
                sections: item,
              });
              if (item.category === "Article") {
                this.getArticles(item.id, slider);
                slider += 1;
              }
            });
            sections.page = widget;
            this.setState({
              sections: widget,
              sectionName: sectionName,
              isLoaded: true,
            });
          })
          .catch((error) => {
            this.setState({ isApiLoaded: true });
          });
      }
    );
  }

  handleSelect(i) {
    this.setState({ tabIndex: i });
  }

  changeBackground(i) {
    this.setState({ hoverMode: i });
  }

  hiddenBackground() {
    this.setState({ hoverMode: "" });
  }

  makeSliderElements(sliderInput, url) {
    let titleContent = "";

    return sliderInput.map((s, i) => {
      titleContent = <p>{s.summary}</p>;

      let imgBackground;
      if (this.state.hoverMode !== "" && this.state.hoverMode + 1 === i + 1) {
        imgBackground = (
          <img
            src={
              s.assets
                ? s.assets[0].asset
                : require("../assets/default-image.svg")
            }
            alt={
              s.assets
                ? s.assets[0].description
                : "Default image"
            }
          />
        );
      }
      return (
        <div
          className={classes.content_wrap}
          onMouseOver={() => this.changeBackground(i)}
          onMouseLeave={this.hiddenBackground}
          onTouchStart={() => this.changeBackground(i)}
          onTouchEnd={this.hiddenBackground}
        >
          {imgBackground}
          <Link
            className={classes.link}
            onClick={() => this.setCurrentLink()}
            to={{
              pathname: `${url}/${s.url_title !== "" ? s.url_title : '-'}/${s.id}`,
            }}
          >
            <p className={classes.paragraph}>{titleContent}</p>
          </Link>
          <p className={classes.paragraph2}>
            {dateFormat(s.date, "dd-mm-yyyy")}
          </p>
        </div>
      );
    });
  }

  makeImageHover(imageHoverInput) {
    return imageHoverInput.map((s, i) => {
      let images2 = null;
      if (this.state.hoverMode) {
        if (this.state.hoverMode === i) {
          images2 = (
            <div>
              <Card.Body className={classes.card_body}>
                <img
                  className={classes.imgcard}
                  src={s.assets[0].file}
                  alt={s.assets[0].description}
                />
              </Card.Body>
            </div>
          );
        }
      }
      return <> {images2} </>;
    });
  }

  setCurrentLink(param, url) {
    localStorage.setItem(
      "prevLink",
      `${window.location.href}${
        this.state.sectionName ? `/#${this.state.sectionName}` : ""
      }`
    );
  }

  render() {
    const { sections, hoverMode, tabIndex } = this.state;
    let title,
      content,
      name,
      titleContent,
      mainSlider,
      imageHover,
      imgHover,
      secondSlider,
      contentSlider,
      hoverSlider,
      content2 = null;

    const widgets = this.state.widgets;

    const settings = {
      slidesPerView: 1,
      updateOnWindowResize: false,
    };

    if (this.state.isLoaded) {
      let count = 0;

      sections.forEach((item) => {
        count += 1;
        switch (count) {
          case 1:
            if (!item.sections.widget_contents[0]) {
              name = <></>;
            } else {
              if (item.sections.widget_contents[0].content !== "") {
                name = item.sections.widget_contents[0].content;

                this.state.sectionTitle =
                  item.sections.widget_contents[0].content;
              } else {
                name = <></>;
              }
            }
            break;

          case 2:
            if (!item.sections.widget_contents[0]) {
              content2 = <></>;
            } else {
              if (item.sections.widget_contents[0].content !== "") {
                content2 = (
                  <Card.Text className={classes.card_text_3}>
                    {ReactHtmlParser(item.sections.widget_contents[0].content)}
                  </Card.Text>
                );
              } else {
                content2 = <></>;
              }
            }
            break;

          case 3:
            if (!item.sections.widget_contents[0]) {
              this.state.latestAwardsButtonText = "Button 1";
            } else {
              if (item.sections.widget_contents[0].content !== "") {
                this.state.latestAwardsButtonText =
                  item.sections.widget_contents[0].content;
              } else {
                this.state.allAwardsButtonText = "Button 1";
              }
            }
            break;

          case 4:
            if (!item.sections.widget_contents[0]) {
              this.state.highlightAwardsButtonText = "Button 2";
            } else {
              if (item.sections.widget_contents[0].content !== "") {
                this.state.highlightAwardsButtonText =
                  item.sections.widget_contents[0].content;
              } else {
                this.state.highlightAwardsButtonText = "Button 2";
              }
            }
            break;

          case 5:
            this.state.allAwardsUrl = item.sections.url
              ? item.sections.url
              : "/awards/";
            if (!item.sections.widget_contents[0]) {
              this.state.allAwardsButtonText = "Button 3";
            } else {
              if (item.sections.widget_contents[0].content !== "") {
                this.state.allAwardsButtonText =
                  item.sections.widget_contents[0].content;
              } else {
                this.state.allAwardsButtonText = "Button 3";
              }
            }
            break;

          case 6:
            this.state.awardDetails1 = item.sections.url
              ? item.sections.url
              : "/awards/detail/";
            if (hoverMode) {
              imageHover = this.makeImageHover(this.state.awards_1);
            }

            mainSlider = this.makeSliderElements(
              this.state.awards_1,
              this.state.awardDetails1
            );
            break;

          case 7:
            this.state.awardDetails2 = item.sections.url
              ? item.sections.url
              : "/awards/detail/";

            if (hoverMode) {
              imgHover = this.makeImageHover(this.state.awards_2);
            }

            secondSlider = this.makeSliderElements(
              this.state.awards_2,
              this.state.awardDetails2
            );
            break;
          default:
        }
      });

      switch (tabIndex) {
        case 1:
          contentSlider = mainSlider;
          hoverSlider = imageHover;
          break;
        case 2:
          contentSlider = secondSlider;
          hoverSlider = imgHover;
          break;
        default:
          contentSlider = mainSlider;
          hoverSlider = imageHover;
          break;
      }

      if (!hoverMode) {
        hoverSlider = (
          <Card.Body className={classes.card_body}>
            <Card.Text className={classes.text_midle}>
              <img
                src={require("../assets/thropybig.svg")}
                className={classes.thropy_big}
                alt="Trophy"
              />{" "}
              {this.state.sectionTitle}
            </Card.Text>
          </Card.Body>
        );
      }
    } else if (this.state.isApiLoaded) {

    }

    return (
      <div className={classes.sectionMobile}>
        <div className={[classes.contentMobile, classes.customBox].join(" ")}>
          {" "}
          <div>
            <h2 className={[classes.card_row_3_title].join(" ")}>{name}</h2>
            {content2}
          </div>
          <div className={classes.boxLatest}>
            <div className={classes.lates} onClick={() => this.handleSelect(1)}>
              <img
                src={require("../assets/thropy.svg")}
                className={classes.thropy}
                alt="Trophy"
              />
              {this.state.latestAwardsButtonText}
            </div>
            <Link to={this.state.allAwardsUrl}>
              <div className={classes.all}>
                {this.state.allAwardsButtonText}
              </div>
            </Link>
          </div>
        </div>
        <div className={[classes.contentCenter, "pr-2 pl-2"].join(" ")}>
          <Swiper {...settings}>{contentSlider}</Swiper>
        </div>
      </div>
    );
  }
}

export default Mobile;
