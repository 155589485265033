import React, { Component, createRef } from "react";
import gsap from "gsap";
import { TweenMax } from "gsap";
import Aux from "../hoc/Auxs/Auxs";
import NavbarLogo from "./NavbarLogo";
import AboutUsLogo from "./AboutUsLogo";
import BusinessLogo from "./BusinessLogo";
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu";
import SustainLogo from "./SustainLogo";
import classes from "./Navbar.module.css";
import "./animate.css";

class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menus: {
        items: [],
      },
      isLoaded: false,
    };
    this.magnetMouse = this.magnetMouse.bind(this);
  }

  componentDidMount() {
    this.magnetMouse();
  }

  magnetMouse() {}

  test = createRef();

  mouseLeave(e) {
    let test = this.test.current.getDomNode();
  }

  mouseMove = (e) => {
    this.callParallax(e);
  };

  mouseEnter = (e) => {
    TweenMax.to(this, 0.3, { transformOrigin: "0 0", scale: 1.5 });
    TweenMax.to(".circle", 0.3, { scale: 0.85 });
  };

  callParallax = (e) => {
    this.parallaxIt(e, ".circle", 80);
    this.parallaxIt(e, ".hamburger", 60);
  };

  parallaxIt(e, target, movement) {
    var el = document.getElementById("test");
    var boundingRect = el.getBoundingClientRect();
    var relX = e.pageX - boundingRect.left;
    var relY = e.pageY - boundingRect.top;

    TweenMax.to(target, 0.3, {
      x: ((relX - boundingRect.width / 2) / boundingRect.width) * movement,
      y: ((relY - boundingRect.height / 2) / boundingRect.width) * movement,
      ease: "Power2.easeOut",
    });
  }

  render() {
    let color = this.props.color ? this.props.color : "transparent";
    let bgClass = this.props.color ? "navbar-top-transparent" : "navbar-top";
    let whatPage,
      burgerColor,
      from = null;
    burgerColor = this.props.logoColor ? "#fff" : "#223771";
    return (
      <Aux>
        <HamburgerMenu
          from={from}
          burgerColor={burgerColor}
          items={this.props.items}
        />
      </Aux>
    );
  }
}

export default NavigationBar;
