import React, { Component } from 'react'

import FilterList from './Filter/Filter'
import Jobs from './Jobs/Jobs'
import UserContext from '../Context'
import Api from '../../Api'

import classes from './Career_JobsN.module.css'

export default class Career_JobsN extends Component {
    static contextType = UserContext
    constructor(props) {
        super(props);
        this.state = {
            id: false,
            filter: '',
            search: '',
            isLoggedIn: true,
            listJobs: [],
            isJobLoaded: false
        };
    }

    componentDidMount() {
        const contextValue = this.context
        console.log(contextValue)
        this.setState({
            id: contextValue.idApplicant
        })

        this.getJobByApplicant(contextValue.idApplicant, '')
    }

    getJobByApplicant(id, search, filter) {
        const countries = new Api()

        countries.getJobByApplicant(id, search, filter)
            .then(res => {
                const data = res.data;
                const jobs = [];
                data.results.forEach((a) => {
                    jobs.push({
                        id_applicant: a.applicant,
                        id: a.id,
                        apply_date: a.apply_date,
                        company_name: a.company_name,
                        confirmation_name: a.confirmation_name,
                        job_poster: a.job_poster,
                        job_title: a.job_title,
                        url_title: a.slug_title,
                        location_address: a.location_address,
                    });
                });
                this.setState({
                    listJobs: jobs,
                    isJobLoaded: true
                })
            })
            .catch(err => {
                console.log("error =>", err)
            })

    }

    toggleActiveFilter = (e) => {
        const filter = e.target.getAttribute("data-filter");

        let filterContent
        if (this.state.filter === filter) {
            filterContent = ''
            this.setState({
                filter: '',
            });
        } else {
            filterContent = filter
            this.setState({
                filter: filter,
            });
        }

        this.getJobByApplicant(this.state.id, this.state.search, filterContent)
    };

    searchHandler(e) {
        if (e.keyCode === 13) {
            this.setState({
                search: e.target.value
            })
            this.getJobByApplicant(this.state.id, e.target.value, this.state.filter)
        }
    }

    render() {
        const contextValue = this.context;
        let apply, send, bookmarks
        if (contextValue.isSectionLoaded) {
            if (contextValue.contentSection[3]) {
                contextValue.contentSection[3].sections.widget_contents.forEach((c, i) => {
                    switch (i) {
                        case 0:
                            apply = c.content
                            break;
                        case 1:
                            send = c.content
                            break;
                        default:
                            break;
                    }
                })
            }

            const dataApp = contextValue.dataApplicant
            if (dataApp && dataApp.job_bookmarks && dataApp.job_bookmarks.length > 0) {
                bookmarks =
                    <div className={classes.contentBookmarks}>
                        <p className={classes.titleContentB}>Bookmark Jobs</p>
                        <Jobs data={dataApp.job_bookmarks} isJobLoaded={this.state.isJobLoaded} send={send} isBookmark={true}/>
                    </div>
            }
        }
        return (
            <div className={['container', classes.customContainer].join(' ')}>
                <p className={classes.titleContent}>{apply}</p>
                <FilterList onClick={this.toggleActiveFilter} active={this.state.filter} jobs={this.state.listJobs} isJobLoaded={this.state.isJobLoaded} />
                <Jobs data={this.state.listJobs} isJobLoaded={this.state.isJobLoaded} send={send} isBookmark={false}/>
                {bookmarks}
            </div>
        )
    }
}
