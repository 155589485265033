import React, { Component } from "react"
import classes from "./../Section3.module.css"
import Api from "./../Api"
import { Link } from "react-router-dom"
import ReactHtmlParser from "react-html-parser"

class Mobile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contentSection: [],
      sectionName: "",
      isLoaded: false,
      isApiLoaded: true,
    }
    this.getSectionDetail = this.getSectionDetail.bind(this)
    this.setCurrentLink = this.setCurrentLink.bind(this)
  }

  componentDidMount() {
    this.getSectionDetail()
  }

  getSectionDetail() {
    const apiData = new Api()
    this.setState(
      {
        contentSection: [],
      },
      () => {
        apiData
          .getSectionDetail()
          .then((result) => {
            const sections = result.data
            const widget = []
            let sectionName = sections.name.toLowerCase().replace(/\s+/g, "-")

            result.data.widgets.forEach((item) => {
              widget.push({
                id: item.id,
                index: item.index,
                category: item.category,
                name: item.name,
                url: item.url,
                sections: item,
              })
            })

            sections.page = widget
            this.setState({ contentSection: widget, sectionName: sectionName, isLoaded: true })
          })
          .catch((error) => {
            this.setState({ isApiLoaded: true })
          })
      }
    )
  }

  setCurrentLink() {
    localStorage.setItem("prevLink", `${window.location.href}${this.state.sectionName ? `/#${this.state.sectionName}` : ""}`)
  }

  render() {
    const { contentSection, isLoaded, isApiLoaded } = this.state

    let boxO,
      title,
      content,
      image_art,
      readText,
      urlText,
      LinkItem = null

    if (isLoaded) {
      let count = 0
      contentSection.forEach((item) => {
        count = count + 1
        if (count === 1 && item.index === 1 && item.category === "Article") {
          item.sections.widget_contents.forEach((z) => {
            if (z.article_language.title) {
              title = <h2 className={classes.title}>{z.article_language.title}</h2>
            }

            if (z.article_language.content) {
              content = <p className={classes.content}>{ReactHtmlParser(z.article_language.content)}</p>
            }

            image_art = z.assets[0] ? (
              <div className={classes.boxRight}>
                <img className={classes.contentImgRight} src={z.assets[0].file_small} alt={z.assets[0].description} />
              </div>
            ) : (
                <div className={classes.boxRight}>
                  <img className={classes.contentImgRight} src={require("./../assets/default-image.svg")} alt="Default image" />
                </div>
              )
          })
        }

        if (count === 2 && item.index === 2 && item.category === "Text") {
          urlText = item.url
          LinkItem = item.sections.widget_contents.map((z) => {
            if (z.content) {
              readText = <p className={classes.contentLink}>{z.content}</p>
            } else {
              readText = <p className={classes.contentLink}>Read More</p>
            }
            return (
              <Link onClick={() => this.setCurrentLink()} to={urlText} className={classes.Link}>
                <div className={classes.readMore}>
                  <div className={[classes.contentLink].join(" ")}>
                    <div className='d-flex'>
                      <div className={classes.outerCircle}>
                        <div className={classes.innerCircle}></div>
                      </div>
                      {readText}
                    </div>
                  </div>
                </div>
              </Link>
            )
          })
        }
      })
    } else if (isApiLoaded) {

    }

    return (
      <div className={classes.Section3}>
        <div className={["container", classes.boxC, classes.customContainer].join(" ")}>
          {title}
          <div className={classes.box}>
            <div className={classes.boxLeft}>
              {content}
              {LinkItem}
            </div>
            {image_art}
          </div>
        </div>
      </div>
    )
  }
}
export default Mobile
