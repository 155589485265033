import React, { Component } from 'react'

import UserContext from '../../Context'
import EditProfile from './EditProfile/EditProfile'
import AccountProfile from './AccountProfile/AccountProfile'

import classes from './Profile.module.css'

export default class Profile extends Component {
    static contextType = UserContext
    constructor(props) {
        super(props);
        this.state = {
            tabs: [
                { id: 1, title: "Edit Profile" },
                { id: 2, title: "Account Profile" }
            ],
            tabsActive: 0
        };
    }

    toggleActiveTab(idx) {
        this.setState({
            tabsActive: idx,
        });
    };

    render() {
        const contextValue = this.context
        let editProfile, accountProfile, navItem, contact
        let tabs = []
        if (contextValue.isSectionLoaded){
            if (contextValue.contentSection[4]){
                contextValue.contentSection[4].sections.widget_contents.forEach((c,i) => {
                    switch (i) {
                        case 1:
                            editProfile = c.content
                            tabs.push({
                                id: i + 1,
                                title: editProfile
                            })
                            break;
                        case 2:
                            accountProfile = c.content
                            tabs.push({
                                id: i + 1,
                                title: accountProfile
                            })
                            break;
                        case 3:
                            contact = c.content
                            break;
                        default:
                    }
                })
                navItem = tabs.map((s, i) => {
                    let active = parseInt(this.state.tabsActive) === i ? classes.navActive : ""
                    return (
                        <div className={[classes.navbarContent, active].join(' ')} onClick={()=>this.toggleActiveTab(i)}>
                            <span onClick={this.props.onClick}>{s.title}</span>
                        </div>
                    )
                })
            }
        }
        // let navItem = this.state.tabs.map((s, i) => {
        //     let active = parseInt(this.state.tabsActive) === i ? classes.navActive : ""
        //     return (
        //         <div className={[classes.navbarContent, active].join(' ')} onClick={()=>this.toggleActiveTab(i)}>
        //             <span onClick={this.props.onClick}>{s.title}</span>
        //         </div>
        //     )
        // })
        
        let content = !this.state.tabsActive ? <EditProfile data={this.props.data} id={this.props.id} contact={contact}/> : <AccountProfile data={this.props.data} id={this.props.id}/>
        return (
            <div className={classes.navProfile}>
                <div className={classes.tabProfile}>
                    {navItem}
                </div>
                <div className={classes.contentProfile}>
                    {/* <EditProfile data={this.props.data} id={this.props.id}/> */}
                    {content}
                </div>
            </div>
        )
    }
}
