import React, { Component } from "react"
import HorizontalScroll from "react-scroll-horizontal"
import HistoryList from "./AU_HistoryTabletView"
import Api from "../Api"
import classes from "../History.module.css"

class Tablet extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dummy: [
        {
          id: 2,
          name: "Articles",
          articles: [
            {
              name: "Articles1",
              title: "",
              year: "2020",
              content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("../asset/default-image.svg"),
            },
            {
              name: "Articles2",
              title: "",
              year: "2020",
              content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("../asset/default-image.svg"),
            },
            {
              name: "Articles3",
              title: "",
              year: "2020",
              content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("../asset/default-image.svg"),
            },
            {
              name: "Articles4",
              title: "",
              year: "2020",
              content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("../asset/default-image.svg"),
            },
            {
              name: "Articles4",
              title: "",
              year: "2020",
              content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("../asset/default-image.svg"),
            },
            {
              name: "Articles4",
              title: "",
              year: "2020",
              content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("../asset/default-image.svg"),
            },
            {
              name: "Articles4",
              title: "",
              year: "2020",
              content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("../asset/default-image.svg"),
            },
            {
              name: "Articles4",
              title: "",
              year: "2020",
              content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("../asset/default-image.svg"),
            },
          ],
        },
      ],
      sections: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
    }
    this.getSectionDetail = this.getSectionDetail.bind(this)
  }

  componentDidMount() {
    this.getSectionDetail()
  }

  getSectionDetail() {
    const apiData = new Api()
    this.setState(
      {
        sections: [],
      },
      () => {
        apiData
          .getSectionDetail()
          .then((result) => {
            this.setState({
              isApiLoaded: true,
            })
            const sections = result.data
            const widget = []

            result.data.widgets.forEach((item) => {
              widget.push({
                id: item.id,
                index: item.index,
                category: item.category,
                internal: item.is_internal,
                name: item.name,
                years: item.article_years,
                sections: item,
              })
            })
            sections.page = widget
            this.setState({
              sections: widget,
              isLoaded: true,
            })
          })
          .catch((error) => {
            this.setState({
              isApiLoaded: true,
            })
          })
      }
    )
  }

  render() {
    const { sections, dummy, isLoaded } = this.state

    let historyList,
      dummyContentSample,
      year,
      titleContent,
      backBtn = null,
      backUrl = "/about-us"

    dummyContentSample = dummy[0].articles.map((ele) => {
      year = <p>{ele.year}</p>
      titleContent = <p>{ele.title}</p>

      return (
        <div>
          <div className={classes.box}>
            <div className={classes._overlay}></div>
            <div className={classes.colorbox}>
              <p className={classes.text_fake}>{year}</p>

              <img className={classes.btn_hover} src={require("../asset/plus.svg")} alt='' />
            </div>
            <p className={classes.title_mid}>{titleContent}</p>
            <figure className={classes.figureimg}>
              <img className={classes.image_box} src={ele.assets} alt='' />
            </figure>
          </div>
        </div>
      )
    })

    if (isLoaded) {
      let count = 0
      sections.forEach((item, index) => {
        count += 1
        if (count === 1 && item.index === 1 && item.category === "Text") {
          backBtn = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Back"
          backUrl = item.sections.url ? item.sections.url : "/about-us"
        }
        if (count === 2 && item.index === 2 && item.category === "Article") {
          historyList = <HistoryList id={item.id} years={item.years} backBtn={backBtn} backUrl={backUrl} />
        }
      })
    } else if (this.state.isApiLoaded) {
      historyList = (
        <div className={classes.wrapp}>
          <HorizontalScroll reverseScroll={true} config={{ stiffness: 160, damping: 110 }}>
            {dummyContentSample}
          </HorizontalScroll>
        </div>
      )
    }

    return <>{historyList}</>
  }
}

export default Tablet
