import React, { Component, createRef } from "react"
import { Link } from "react-router-dom"

import ReactHtmlParser from "react-html-parser"

import Api from "./Api"
import "./BOD_Section2.css"
import classes from "./BOD_Section2.module.css"

import CustomModal from "./Modal/Modal"
import { SplitLetters } from "react-gsap"

class BOD_Section2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dummy: [
        {
          id: 1,
          name: "content",
          articles: [
            {
              frontImgUrl: require("./assets/default-image.svg"),
              title: "Ông Hồ Xuân Năng",
              subtitle: "Chủ tịch HĐQT kiêm Tổng Giám đốc Tập đoàn",
            },
          ],
        },
      ],

      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
      isBoxHover: false,
      isShowModal: false,
      boxCount: 0,
      contentArticle: [],
      activeTab: null,
      column: 3,
    }
    this.getSectionDetail = this.getSectionDetail.bind(this)
    this.getArticles = this.getArticles.bind(this)
    this.showingProfile = this.showingProfile.bind(this)
  }

  componentDidMount() {
    let orientation = (window.screen.orientation || {}).type || window.screen.mozOrientation || window.screen.msOrientation
    this.getSectionDetail()
    if (window.screen.width >= 2560) {
      this.setState({ column: 4 })
    } else if (window.screen.width <= 900 && orientation === "landscape-primary") {
      this.setState({ column: 1 })
    } else if (window.screen.width < 2560 && window.screen.width > 768) {
      this.setState({ column: 3 })
    } else if (window.screen.width <= 480) {
      this.setState({ column: 1 })
    } else {
      this.setState({ column: 2 })
    }
    window.addEventListener("scroll", this.scrollingHandler, true)
  }

  getSectionDetail() {
    const apiData = new Api()
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data
          const widget = []

          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            })

            if (!item.is_internal && item.category === "Article") {
              this.getArticles(item.id)
            }
          })

          sections.page = widget
          this.setState({
            contentSection: widget,
            isLoaded: true,
          })
        })
        .catch((error) => {
          console.log(error)
          this.setState({
            isApiLoaded: true,
          })
        })
    })
  }

  getArticles(param) {
    const apiData = new Api()
    this.setState(
      {
        contentArticle: [],
      },
      () => {
        apiData
          .getArticleDetailOrderByIndex(param)
          .then((result) => {
            const articles = result.data
            const art = []

            articles.results.forEach((a) => {
              const assets = []
              a.article_assets.forEach((ass, i) => {
                if (i === 0) {
                  assets.push({ asset: ass.asset_file, description: ass.description })
                }
              })

              art.push({
                id: a.id,
                category: a.category_name,
                name: a.name,
                title: a.article_languages.title,
                content: a.article_languages.content,
                assets: assets,
                date: a.date,
              })
            })
            this.setState({
              contentArticle: art,
            })
          })
          .catch((error) => {
            console.log(error)
            this.setState({ isApiLoaded: true })
          })
      }
    )
  }

  toggleBoxHandlerEnter = () => {
    this.setState((prevState) => {
      return { isBoxHover: !prevState.isBoxHover }
    })
  }

  showingProfile = (index, id) => {
    this.setState((prevState) => {
      return { activeTab: prevState.activeTab === index ? null : index }
    })

    setTimeout(function () {
      let elmnt = document.getElementById("content-preview" + id)
      let elmnt2 = document.getElementById("box" + id)
      if (elmnt && elmnt2) {
        // let top = (elmnt.offsetTop - ( window.innerHeight ) / 2);
        let top = elmnt2.offsetTop
        window.scrollTo({
          top: top,
          behavior: "smooth",
        })
      }
    }, 100)
  }

  getHtmlParagraph(str) {
    if (str !== "") {
      return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ")
    }
  }

  getCards(arr) {
    let content = null
    let isFilled = null
    let isOnlyOne = false

    let returnArr = arr.map((item, idx) => {
      let data = this.state.contentArticle
      let contentPreview = <></>

      if (this.state.activeTab === idx + 1) {
        const splitStr = item.content.split("#")
        content = (
          <div id={"content-preview" + data[idx].id} className={[classes.boxPreview, classes.active].join(" ")}>
            {ReactHtmlParser(item.content)}
          </div>
        )
        isFilled = idx + 1
      }

      let cnt
      if (this.state.column === 4) {
        cnt = 3
      } else if (this.state.column === 3) {
        cnt = 2
      } else if (this.state.column === 2) {
        cnt = 1
      }

      if (content !== null && !isOnlyOne && Number.isInteger(parseInt(this.state.column === 1 ? idx + 1 : idx - cnt) / this.state.column) && isFilled <= this.state.activeTab) {
        contentPreview = content
        isOnlyOne = true
      }

      // if (content !== null && !isOnlyOne && Number.isInteger(parseInt(idx + 1) / this.state.column) && (isFilled <= this.state.activeTab)) {
      //     contentPreview = content
      //     isOnlyOne = true
      // }

      let activeClass = this.state.activeTab ? (this.state.activeTab === idx + 1 ? "active" : "inactive") : ""

      return (
        <>
          <CustomModal data={data} idx={idx} onClick={() => this.showingProfile(idx + 1, data[idx].id)} onStyle={activeClass} />
          {contentPreview}
        </>
      )
    })
    return returnArr
  }

  render() {
    let headingTitle,
      cardsBOD,
      link = null
    let isBorder = classes.customBorderDefault
    if (this.state.isLoaded) {
      let count = 0
      this.state.contentSection.forEach((item, i) => {
        count = count + 1

        if (count === 1 && item.index === 1 && item.category === "Article") {
          cardsBOD = item.sections.widget_contents.map((article, index) => {
            let activeClass = this.state.activeTab ? (this.state.activeTab === index ? "active" : "inactive") : ""
            return (
              <>
                <CustomModal data={item.sections.widget_contents} idx={index} onStyle={activeClass} />
              </>
            )
          })
        } else if (count === 2 && item.index === 2 && item.category === "Article") {
          headingTitle = item.sections.widget_contents[0].article_language.title
        }

        switch (i) {
          case 2:
            link = item.sections.widget_contents.map((s) => {
              return (
                <div className={classes.navBack}>
                  <Link className={classes.customLink} to={item.sections.url ? item.sections.url : "/about-us"}>
                    <div className={"d-flex"}>
                      <div className={"d-flex"}>
                        <i className={[classes.arrow, classes.left, classes.customMargin].join(" ")}></i>
                      </div>
                      <span className={classes.textBack}>{s.content}</span>
                    </div>
                  </Link>
                </div>
              )
            })

            isBorder = classes.customBorder
            break

          default:
            break
        }
      })

      let content = null
      let isFilled = null
      let isOnlyOne = false

      let art = this.state.contentArticle
      let newArray

      if (art.length !== 0) {
        // console.log(art.length)
        // console.log(Number.isInteger(art.length / 3))
        const div = art.length / this.state.column
        let res = div.toString().substr(div.toString().lastIndexOf(".") + 1)

        let dummyLength
        if (this.state.column === 4) {
          switch (res) {
            case "25":
              dummyLength = 3
              break
            case "5":
              dummyLength = 2
              break
            case "75":
              dummyLength = 1
              break
            default:
              break
          }
        } else if (this.state.column === 3) {
          if (res.toString().includes("33")) {
            dummyLength = 2
          } else if (res.toString().includes("66")) {
            dummyLength = 1
          }
        } else if (this.state.column === 2) {
          if (res === "5") {
            dummyLength = 1
          }
        } else if (this.state.column === 1) {
          dummyLength = 0
        }

        let arrDummy = []
        if (!Number.isInteger(art.length / this.state.column)) {
          for (let index = 0; index < dummyLength; index++) {
            arrDummy.push({
              assets: [],
              category: "",
              content: "",
              date: "",
              id: "",
              name: "",
              title: "",
            })
          }

          // newArray = [...art, arrDummy];
          newArray = art.concat(arrDummy)
          cardsBOD = this.getCards(newArray)
        } else {
          cardsBOD = this.getCards(this.state.contentArticle)
        }
      }
    } else if (this.state.isApiLoaded) {
      cardsBOD = this.state.dummy[0].articles.map((article) => {
        return (
          <a className={classes.aCard} onClick={this.toggleModal}>
            <div className={classes.card}>
              <img src={article.frontImgUrl} className={classes.cardImageFront} alt='' />
              <h4 className={classes.cardTitle}>{article.title}</h4>
              <p className={classes.cardSubTitle}>{article.subtitle}</p>
            </div>
          </a>
        )
      })
    }

    return (
      <div className={[classes.posRelative, isBorder].join(" ")}>
        <div className={["container", classes.customContainer].join(" ")}>
          <div className={classes.topContainer}>
            <h2 className={classes.topTitle}>{headingTitle}</h2>
            <div className={classes.topHorizontalLine}></div>
          </div>
          <div className={classes.flexContainer}>{cardsBOD}</div>
          {link}
        </div>
      </div>
    )
  }
}

export default BOD_Section2
