import React, { Component } from 'react'
import { Modal } from "react-bootstrap";
import {
    FacebookIcon,
    LinkedinIcon,
    FacebookShareButton,
    LinkedinShareButton,
} from "react-share";

import classes from './ModalShare.module.css'

export default class ModalShare extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUrl: '',
        };
    }

    componentDidMount() {
        let uri = window.location.href;
        let uri_enc = encodeURIComponent(uri);
        let uri_dec = decodeURIComponent(uri_enc);
        this.setState({
          currentUrl: uri_dec,
        });
    }

    render() {
        return (
            <Modal
                size={"md"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.show}
                onHide={this.props.close}
            >
                <Modal.Header className={classes.modalHeader} closeButton>
                    <span className={classes.modalTitle}>Share</span>
                </Modal.Header>
                <div className={classes.modalContent}>
                    <FacebookShareButton
                        url={this.state.currentUrl}
                        quote={this.props.shareTitle}
                        className={classes.boxImageIconShare}
                    >
                        <FacebookIcon size={36} round />
                    </FacebookShareButton>
                    <LinkedinShareButton
                        url={this.state.currentUrl}
                        title={this.props.shareTitle}
                        className={classes.boxImageIconShare}
                    >
                        <LinkedinIcon size={36} round />
                    </LinkedinShareButton>
                </div>
            </Modal>
        )
    }
}
