import React from "react";
import { useMediaQuery } from "react-responsive";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import classes from "./sec5.module.css";
import "./sec5.css";
import Slider from "react-slick";

export default function ImageSlider(props) {
  console.log(props)
  const isTablet = useMediaQuery({
    query: "(max-device-width: 1024px)",
  });

  const settings = {
    className: "",
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <div
        style={{
          backgroundColor: "transparent",
          borderRadius: "10px",
          paddingBottom: "2rem",
        }}
      >
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "10px",
          height: "10px",
          color: "#d6d6d6",
          border: "1px solid #d6d6d6",
          borderRadius: "50%",
        }}
      ></div>
    ),
  };

  let imageSlide = null;

  let imgBack = (
    <img
      className={["img", classes.imgContent].join(" ")}
      src={props.file.file_big}
      alt={props.file.description}
    />
  );

  let title = <h2 className={classes.text_title}>{props.title}</h2>;
  let contents = <span>{props.content}</span>;

  imageSlide = props.widget.map((s) => {
    let path = s.article_language.url;
    let isImage = s.assets[0] ? (
      <Card.Img
        className={["img", classes.imgSlider].join(" ")}
        src={s.assets[0].file_big || s.assets[0].file}
        alt={s.assets[0].description}
      />
    ) : (
      <Card.Img
        className={["img", classes.imgSlider].join(" ")}
        src={require("./assets/default-image.svg")}
        alt="Default image"
      />
    );

    return (
      <Card key={s.content} className={classes.contentCard}>
        <div className={classes.gradientOverlay}></div>
        {isImage}
        <Link to={path} className={classes.link}>
          <div className={classes.contentAbsolute}>
            <div className={classes.contentInBox}>
              <span className={classes.textInsideOverlay}>
                <h2>
                  {ReactHtmlParser(props.getHtml(s.article_language.content))}
                </h2>
              </span>
            </div>
          </div>
        </Link>
      </Card>
    );
  });

  let dataTab = (
    <div className={classes.cardGroupSec5}>
      <div id="h5_sliderR" className={classes.cardSec5}>
        <Slider {...settings}>{imageSlide}</Slider>
      </div>
      <div className={classes.cardSec51}>
        {imgBack}

        <div className={classes.overlayInfo}></div>

        <div className={classes.infoCareer}>
          <div className={classes.infoCarReal}>
            <div className={classes.subCarReal}>{title}</div>
            <div className={classes.contentCarReal}>{contents}</div>
            <div className={classes.btnCarReal}>
              <Link to={props.url}>
                <button className={classes.btn_card}>{props.buttonText}</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  let dataDesktop = (
    <div className={classes.cardGroupSec5}>
      <div className={classes.cardSec51}>
        {imgBack}

        <div className={classes.overlayInfo}></div>

        <div className={classes.infoCareer}>
          <div className={classes.infoCarReal}>
            <div className={classes.subCarReal}>
              <h2>{title}</h2>
            </div>
            <div className={classes.contentCarReal}>{contents}</div>
            <div className={classes.btnCarReal}>
              <Link to={props.url}>
                <button className={classes.btn_card}>{props.buttonText}</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div id="h5_sliderR" className={classes.cardSec5}>
        <Slider {...settings}>{imageSlide}</Slider>
      </div>
    </div>
  );

  let data = isTablet ? dataTab : dataDesktop;

  return <>{data}</>;
}
