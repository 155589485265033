import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import classes from "./ModalSuccess.module.css";

export default class ModalSuccess extends Component {
  render() {
    return (
      <div>
        <Modal
          size={"md"}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.props.show}
          onHide={this.props.isClosed}
        >
          <Modal.Header onClick={this.props.isClosed} closeButton>
            <span className={classes.modalTitle1}>Success</span>
          </Modal.Header>
          <div className={classes.modalContent}>
            <span className={classes.modalTitle}>
              You'll receive email to verify here so you can reset your account
              password
            </span>
          </div>
        </Modal>
      </div>
    );
  }
}
