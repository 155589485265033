import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Line } from "react-chartjs-2";
import ReactHtmlParser from "react-html-parser";

import Api from "../Api";

import classes from "./Section4.module.css";

export default class Section4 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: {},
      data: [],
      contentSection: [],
      isLoaded: false,
      isApiLoaded: true,
    };
    this.chartRef = React.createRef();
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    apiData
      .getSectionDetail()
      .then((result) => {
        const sections = result.data;
        const widget = [];
        const chartData = [];

        let cek = "Jan 90";
        let spilt = cek.split(" ", 2);

        result.data.widgets.forEach((item) => {
          widget.push({
            id: item.id,
            index: item.index,
            category: item.category,
            is_internal: item.is_internal,
            name: item.name,
            sections: item,
          });
        });
        const chartY = [];
        const chartX = [];
        result.data.widgets[3].widget_contents.forEach((ele, index) => {

          chartX.push(ele.content.split(" ", 2));
          return chartX;
        });

        sections.page = widget;
        this.setState({
          contentSection: widget,
          chartData: chartX,
          isLoaded: true,
        });
      })

      .catch((error) => {
        this.setState({
          isApiLoaded: true,
        });
      });
  }

  render() {
    const { contentSection, chartData } = this.state;
    let title,
      dataChart,
      article,
      chart,
      url,
      readMore,
      chartTitle;

    if (this.state.isLoaded) {
      let count = 0;
      contentSection.forEach((item) => {
        count = count + 1;

        if (count === 1 && item.index === 1 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              title = <h2 className={classes.title}>{s.content}</h2>;
            }
          });
        }

        if (count === 2 && item.index === 2 && item.category === "Text") {
          url = item.sections.url;
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              readMore = s.content;
            } else {
              readMore = "Read More";
            }
          });
        }

        if (count === 3 && item.index === 3 && item.category === "Article") {
          if (item.sections.is_internal) {
            article = item.sections.widget_contents
              ? item.sections.widget_contents.map((s) => {
                return (
                  <>
                    <span className={classes.content}>
                      {ReactHtmlParser(s.article_language.content)}
                    </span>
                  </>
                );
              })
              : "";
          }
        }

        if (count === 4 && item.index === 4 && item.category === "Text") {
          let XX = [];
          let YY = [];
          chartData.map((n, index) => {

            XX.push(n[0]);
            YY.push(n[1]);
          });
          dataChart = (canvas) => {
            // console.log(XX);
            const ctx = canvas.getContext("2d");
            const gradient = ctx.createLinearGradient(0, 0, 0, 450);
            gradient.addColorStop(0, "rgba(241, 102, 36, 1)");
            gradient.addColorStop(0.5, "rgba(241, 102, 36, 0.75)");
            gradient.addColorStop(1, "rgba(241, 102, 36, 0.5)");
            return {
              labels: XX,
              datasets: [
                {
                  label: "Customer",
                  backgroundColor: gradient,
                  pointBackgroundColor: "#223771",
                  borderWidth: 1,
                  borderColor: "#147AD6",
                  data: YY,
                },
              ],
            };
          };
        }

        if (count === 5 && item.index === 5 && item.category === "Text") {
          chartTitle = item.sections?.widget_contents[0].content;
        }

      });
    } else if (this.state.isApiLoaded) {

    }

    var options = {
      responsive: true,
      maintainAspectRatio: true,
      animation: {
        easing: "easeInOutQuad",
        duration: 520,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              // color: 'rgba(200, 200, 200, 0.05)',
              color: "rgba(20, 122, 214, 0.05)",
              lineWidth: 1,
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              // color: 'rgba(200, 200, 200, 0.08)',
              color: "rgba(20, 122, 214, 0.08)",
              lineWidth: 1,
            },
          },
        ],
      },
      elements: {
        line: {
          tension: 0.4,
        },
      },
      legend: {
        display: false,
      },
      point: {
        backgroundColor: "white",
      },
      tooltips: {
        titleFontFamily: "Open Helvetica",
        backgroundColor: "rgba(0,0,0,0.3)",
        titleFontColor: "red",
        caretSize: 5,
        cornerRadius: 2,
        xPadding: 10,
        yPadding: 10,
      },
    };

    return (
      <div className={classes.contentSection}>
        <div className={["container", classes.boxC].join(" ")}>
          {title}

          <div className={classes.box}>
            <div className={classes.boxLeft}>
              {article}
            </div>
            <div className={classes.boxRight}>
              <p className={classes.titleChart}>
                {chartTitle}
              </p>
              <div className={classes.lineChart}>
                <Line
                  id="lineChart"
                  ref={this.chartRef}
                  data={dataChart}
                  width={100}
                  height={55}
                  options={options}
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
