import React, { Component } from "react";
// import { Link } from "react-router-dom";

import { Accordion, Card } from "react-bootstrap";
import ReactHtmlParser from "react-html-parser";
import dateFormat from "dateformat";

import ContextAwareToggle from "./Accordion";
import YearFilter from "./YearFilter";
import Api from "../Api";

import classes from "./../IN_PP.module.scss";

class Views extends Component {
  constructor(props) {
    super(props);
    this.state = {
      yearItem: ["2020"],

      active: 0,
      activeYear: false,
      years: [],
      isLoaded: false,
      articleList: [],
      articlesFiltered: [],
      isApiloaded: false,
      views: null,
      idNews: "",
      filtered: "",
      articleCategories: null,
      pagination: 6,
      articleID: null,
      displayStyle: "grid",
      articleByYearCollection: [],
      filterIndex: 0,
      isFiltered: false,
      loadedArticles: false,
    };
    this.getArticleDetail = this.getArticleDetail.bind(this);

    this.onFilterChange = this.onFilterChange.bind(this);
    this.getSearchedData = this.getSearchedData.bind(this);
    this.toggleClass = this.toggleClass.bind(this);
    this.loadArticlesToState = this.loadArticlesToState.bind(this);
    this.sortAndFilterArray = this.sortAndFilterArray.bind(this);
    this.sortAllArray = this.sortAllArray.bind(this);
    this.setCurrentLink = this.setCurrentLink.bind(this);
  }

  componentDidMount() {
    this.setState({
      articleID: this.props.id ? this.props.id : "",
      articleCategories: this.props.category ? this.props.category : "",
      displayStyle: this.props.view ? this.props.view : "grid",
    });
    if (this.props.idPP) {
      this.getArticleDetail(
        this.props.idPP,
        "",
        "",
        this.props.valueSearch ? this.props.valueSearch : "",
        "2"
      );
    }
    this.loadArticlesToState();
  }

  loadArticlesToState() {
    this.setState(
      {
        loadedArticles: true,
        articleList: this.props.articleList,
        articlesFiltered: this.props.articleList,
      },
      function () {
        this.sortAndFilterArray(null);
      }
    );
  }

  sortAndFilterArray(yearToFilter) {
    let outputArticle = [...this.state.articleList];
    var finalOutputArray = [];

    if (yearToFilter) {
      outputArticle.forEach((item) => {
        if (
          item.date &&
          item.date.substring(0, 4) === yearToFilter.toString()
        ) {
          finalOutputArray.push(item);
        }
      });
    } else {
      finalOutputArray = outputArticle;
    }

    // Sorting the array by date
    finalOutputArray = this.sortAllArray(finalOutputArray);

    this.setState({
      articlesFiltered: finalOutputArray,
    });
  }

  sortAllArray(arr) {
    var listToSort = arr;

    if (listToSort.length > 0) {
      listToSort.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
      return listToSort;
    }
  }

  getArticleDetail(param, filter, size, search, counted) {
    let count = 0;
    if (count === 0) {
      const apiData = new Api();

      apiData
        .getArticleGroupbyYear(param) //, filter, size, search)
        .then((result) => {
          const results = result.data;
          const yearArray = [];

          results.forEach((yearElement) => {
            const articleDetails = [];
            yearElement.articles.forEach((articleElement) => {
              articleDetails.push({ articleDetails: articleElement });
            });

            yearArray.push(yearElement.year);
          });

          // Merge Years for filtering
          let yearSet = new Set(yearArray);
          let yearStateSet = new Set(this.state.articleByYearCollection);
          let mergeYearSet = new Set([...yearSet, ...yearStateSet]);
          let mergeYearArray = [...mergeYearSet];

          // Sorting merged array
          //console.log(mergeYearArray)
          // mergeYearArray = [2021, 2020, 2019, 2018, 2017, 2016, 2015];
          mergeYearArray.sort((a, b) => b - a);
          let isCounted = counted === "3" ? true : false;
          this.setState({
            articleByYearCollection: mergeYearArray,
            isLoaded: isCounted,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isApiLoaded: true });
        });
      count = 1;
    }
  }

  onFilterChange(e) {
    this.setState({ filtered: e.target.value });
    this.getArticleDetail(this.state.articleID, e.target.value, "", "", "3");
  }

  getSearchedData(e) {
    if (e.keyCode === 13) {
      if (e.target.value !== "") {
        this.getArticleDetail(
          this.state.articleID,
          "",
          "",
          e.target.value,
          "3"
        );
      }
    }
  }

  toggleClass(e) {
    let i = e.target.getAttribute("data-id");
    let item = e.target.innerText;
    if (this.state.active === i) {
      this.setState({ active: false, activeYear: false });
      this.sortAndFilterArray(false);
    } else {
      this.setState({ active: i, activeYear: item });
      this.sortAndFilterArray(item);
    }
  }

  toggleTabActive = (event) => {
    let val = event.target.value;
    // const currentState = this.state.active;
    if (val === "0") {
      this.setState({ isFiltered: false, filterIndex: 0 });
    } else {
      this.setState({ isFiltered: true, filterIndex: val });
    }
  };

  setCurrentLink() {
    localStorage.setItem(
      "prevLink",
      `${window.location.href}${this.props.sectionNameN ? `/#${this.props.sectionNameN}` : ""
      }`
    );
  }

  render() {

    let dummyAccordion,
      highlightYear,
      filterAll,
      apiTitle

    let listOfArticles;

    if (this.props.articleList.length && !this.state.articleList.length) {
      this.loadArticlesToState();
      //this.props.articleList;
    }

    listOfArticles = this.state.articlesFiltered;
    let awardCards = null;

    const { articleByYearCollection } = this.state;

    if (listOfArticles) {
      awardCards = listOfArticles.map((article, index) => {
        let urlTo = `${this.props.readmoreUrl}/${article.id}`;
        let strYear = article.date;
        let dateFull = dateFormat(strYear, "dd-mm-yyyy");

        return (
          <Card className={classes.customCard}>
            <ContextAwareToggle as={Card.Header} eventKey={index + 1} className={classes.cardHeader}>
              <div className={classes.accParent}>
                <div className={classes.accFirst}>
                  {/* <p className={classes.dateArcticle}>{dateFull}</p> */}
                  <p className={classes.titleArticle}>{article.title}</p>
                </div>
              </div>
            </ContextAwareToggle>
            <Accordion.Collapse eventKey={index + 1}>
              <Card.Body className={classes.textAccor}>
                <Card.Title className={classes.titleAccorContent}>{/* {article.content} */}</Card.Title>
                {ReactHtmlParser(article.content)}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        );
      });
    }

    filterAll = <p className={classes.yearActive}>{this.props.filterAll}</p>;
    highlightYear = (
      <YearFilter
        article={articleByYearCollection}
        active={this.state.active}
        onClick={this.toggleClass}
      />
    );

    apiTitle = (
      <div className={classes.title1}>
        <p>{this.props.title}</p>
        {/* <p>AWARDS</p> */}
      </div>
    );

    return (
      <div className={classes.componentViews}>
        <h1 className={classes.titleTxt}>{this.props.topTitle}</h1>

        <Accordion className={classes.customAccordion} defaultActiveKey="0">
          {awardCards ? awardCards : dummyAccordion}
        </Accordion>
      </div>
    );
  }
}

export default Views;
