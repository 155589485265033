import React, { Component } from 'react';

import classes from './AuthorBox.module.css'

class AuthorBox extends Component {
    constructor(props) {
        super(props);
        
    }
    
    render() {
        return (
            <div className={classes.authorBox}>
                <img className={classes.authorImg} src={this.props.avatar} alt={this.props.name}></img>
                <p className={classes.authorName}>{this.props.name}</p>
                <p className={classes.authorTitle}>{this.props.title}</p>
            </div>
        );
    }
}

export default AuthorBox;