import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";

// import {Context} from './Context' using first context default value declared
import Context from "./Context";
import Navbar from "./Navbar/Navbar";

import CareerBrowse from "./Career_BrowseN/Career_BrowseN";
import CareerJobs from "./Career_JobsN/Career_JobsN";
import CareerProfile from "./Career_ProfileN/Career_ProfileN";

import ModalLogin from "./Modal/ModalLogin";
import ModalRegister from "./Modal/ModalRegister";
import ModalPassword from "./Modal/ModalPassword";

import Api from "../Api";
import "react-notifications-component/dist/theme.css";
import classes from "./Mobile.module.css";

class Mobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataApplicant: [],
      tabsActive: 1,
      isLoggedIn: false,
      isModalLogin: false,
      isModalRegister: false,
      isModalForgot: false,
      idApplicant: 0,
      isLoaded: false,
      template: 41,
      getNewDataApplicant: () => {
        this.getDataApplicant(this.state.idApplicant);
      },
      showNotification: (message) => {
        this.showNotification(message);
      },
      isJobLoaded: false,
      jobList: {
        widgets: [],
      },
      isFilterLoaded: false,
      listFilter: [],
      isSectionLoaded: false,
      contentSection: [],
      search: "",
      filter: "",
    };

    this.defaultParam = {
      max_size: "True",
      order: "-post_date",
      status: "Published"
    }

    this.getDataApplicant = this.getDataApplicant.bind(this);
    this.getListCompanyHasJobs = this.getListCompanyHasJobs.bind(this);
    this.toggleModalLogin = this.toggleModalLogin.bind(this);
    this.toggleModalRegister = this.toggleModalRegister.bind(this);
    this.toggleModalForgot = this.toggleModalForgot.bind(this);
    this.toggleModalClose = this.toggleModalClose.bind(this);
    this.searchHandler = this.searchHandler.bind(this);
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    let token = localStorage.getItem("token");
    let isLoggedIn = localStorage.getItem("isLoggedIn");
    let id = localStorage.getItem("id");

    if (isLoggedIn && token && id) {
      this.setState({
        idApplicant: id,
        isLoggedIn: isLoggedIn,
      });
      this.getSectionDetail(() => {
        this.getDataApplicant(id);
        this.getJobDetail(id, { ...this.defaultParam });
      });
    } else {
      this.getSectionDetail(() => {
        this.getJobDetail(null, { ...this.defaultParam });
      });
      this.setState({
        isLoggedIn: false,
      });
    }
    this.getListCompanyHasJobs();
  }

  getSectionDetail(callback) {
    const apiData = new Api();
    apiData
      .getSectionDetail()
      .then((result) => {
        const sections = result.data;
        const widget = [];

        sections.widgets.forEach((item) => {
          widget.push({
            id: item.id,
            index: item.index,
            category: item.category,
            name: item.name,
            sections: item,
          });
        });

        this.setState(
          {
            template: sections.template,
            contentSection: widget,
            isSectionLoaded: true,
            readText: widget[5].sections,
          },
          callback
        );
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          isSectionLoaded: false,
        });
      });
  }

  getDataApplicant(param) {
    const login = new Api();

    this.setState({ dataApplicant: [] }, () => {
      login
        .getDataApplicant(param)
        .then((result) => {
          const data = result.data;
          const itm = data.user;
          const user = [];
          const exp = [];

          user.push({
            firstname: itm.first_name,
            lastname: itm.last_name,
            email: itm.email,
            username: itm.username,
            isActive: itm.is_active,
          });

          data.user = user;
          this.setState({
            dataApplicant: data,
            isLoggedIn: true,
            isLoaded: true,
          });
        })
        .catch((error) => {
          console.log(error);
          // window.location.assign(`/error/${error ? (error.response.status ? error.response.status : 404) : 404}`);
        });
    });
  }

  getJobDetail(param, customParam) {
    const apiData = new Api();
    apiData
      .getJobDetail(param, customParam)
      .then((result) => {
        const jobList = result.data;
        const widget = [];
        let dataCat = [];

        if (!this.state.isLoggedIn) {
          jobList.results.forEach((item) => {
            let slug = item.article.article_languages.slug_title
            if (item.article.article_languages?.title && item.article.article_languages?.content) {
              widget.push({
                id: item.id,
                title: item.article.article_languages.title,
                url_title: item.article.article_languages.slug_title,
                position_name: item.position_name,
                company_name: item.company_name,
                location: item.location_address,
                assets: item.article.article_assets[0]
                  ? item.article.article_assets[0].file_medium
                  : require("./assets/default-image.svg"),
                due_date: item.due_date,
                job_type: item.job_type_name,
                url: this.state.readText.url + "/" + (slug !== "" ? slug : "-") + "/" + item.id,
                status: !this.state.isLoggedIn ? "" : item.status,
              });
              dataCat.push(item.company_name);
            }
          });
        } else {
          jobList.results.forEach((item) => {
            let slug = item.article_languages?.slug_title
            if (item.article_languages?.title && item.article_languages?.content) {
              widget.push({
                id: item.id,
                title: item.title,
                url_title: item.article_languages.slug_title,
                position_name: item.position,
                company_name: item.company,
                assets: item.article_assets[0]
                  ? item.article_assets[0].file_medium
                  : require("./assets/default-image.svg"),
                url: this.state.readText.url + "/" + (slug !== "" ? slug : "-") + "/" + item.id,
                status: item.applied,
                due_date: item.due_date,
                job_type: item.job_type,
                location: item.location,
              });
              dataCat.push(item.company_name);
            }
          });
        }

        dataCat = [...new Set(dataCat)];

        this.setState({
          jobList: widget,
          categories: dataCat,
          isJobLoaded: true,
          counter: 2,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getListCompanyHasJobs() {
    const jobs = new Api();

    jobs
      .getCompanyHasJobs()
      .then((res) => {
        const data = res.data;
        const job = [];
        data.results.forEach((a) => {
          job.push({
            id: a.id,
            title: a.name,
          });
        });
        this.setState({
          listFilter: job,
          isFilterLoaded: true,
        });
      })
      .catch((err) => {
        console.log("error =>", err);
      });
  }

  toggleActiveTab = (e) => {
    const idx = e.target.getAttribute("data-tab");
    // const idx = e.target.value;
    this.setState({
      tabsActive: idx,
    });
  };

  toggleModalLogin() {
    this.setState((prevState) => {
      return {
        isModalRegister: false,
        isModalForgot: false,
        isModalLogin: !prevState.isModalLogin,
      };
    });
  }

  toggleModalClose() {
    this.setState({
      isModalRegister: false,
      isModalForgot: false,
      isModalLogin: false,
    });
  }

  toggleModalRegister() {
    this.setState((prevState) => {
      return {
        isModalLogin: false,
        isModalRegister: !prevState.isModalRegister,
      };
    });
  }

  toggleModalForgot() {
    this.setState((prevState) => {
      return {
        isModalLogin: false,
        isModalForgot: !prevState.isModalForgot,
      };
    });
  }

  toggleActiveFilter = (e) => {
    const filter = e.target.getAttribute("data-filter");
    let filterContent;
    if (this.state.filter === filter) {
      filterContent = "";
      this.setState({
        filter: "",
      });
    } else {
      filterContent = filter;
      this.setState({
        filter: filter,
      });
    }

    this.state.isLoggedIn
      ? this.getJobDetail(
        this.state.idApplicant,
        {
          ...this.defaultParam,
          search: this.state.search,
          company: filterContent,
        }
      )
      : this.getJobDetail(null,
        {
          ...this.defaultParam,
          company: filterContent
        });
  };

  searchHandler(e) {
    if (e.keyCode === 13) {
      this.setState({
        search: e.target.value,
      });
      this.getJobDetail(
        this.state.idApplicant,
        {
          ...this.defaultParam,
          search: e.target.value,
          company: this.state.filter
        }
      );
    }
  }

  showNotification(message) {
    store.addNotification({
      title: "Success!",
      message: message,
      type: "phenikaa",
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 3000,
        onScreen: true,
      },
    });
  }

  render() {
    let content, htmlContent;
    const cs = this.state.contentSection ? this.state.contentSection : null;

    if (
      this.state.isLoaded &&
      this.state.isLoggedIn &&
      this.state.dataApplicant &&
      this.state.isSectionLoaded
    ) {
      let tabActive = parseInt(this.state.tabsActive);
      if (tabActive === 1) {
        content = (
          <CareerBrowse
            search={this.state.Search}
            onClick={this.toggleActiveFilter}
            active={this.state.filter}
          />
        );
      } else if (tabActive === 2) {
        content = <CareerJobs />;
      } else if (tabActive === 3) {
        content = (
          <CareerProfile
            data={this.state.dataApplicant}
            id={this.state.idApplicant}
          />
        );
      }

      htmlContent = (
        <Context.Provider value={this.state}>
          <ModalLogin
            texts={cs ? cs[7].sections.widget_contents : []}
            show={this.state.isModalLogin}
            loginClicked={this.toggleModalLogin}
            registerClicked={this.toggleModalRegister}
            forgotClicked={this.toggleModalForgot}
          />
          <ModalRegister
            texts={cs ? cs[8].sections.widget_contents : []}
            show={this.state.isModalRegister}
            loginClicked={this.toggleModalLogin}
            registerClicked={this.toggleModalRegister}
          />
          <ModalPassword
            texts={cs ? cs[7].sections.widget_contents : []}
            show={this.state.isModalForgot}
            loginClicked={this.toggleModalLogin}
            forgotClicked={this.toggleModalForgot}
            close={this.toggleModalClose}
          />
          <Navbar
            onClick={this.toggleActiveTab}
            searchHandler={this.searchHandler}
            active={this.state.tabsActive}
            data={this.state.dataApplicant}
            loginClicked={this.toggleModalLogin}
            isLoggedIn={this.state.isLoggedIn}
          />
          {content}
        </Context.Provider>
      );
    } else if (
      !this.state.isLoggedIn &&
      this.state.isSectionLoaded &&
      this.state.isJobLoaded
    ) {
      let dataErrorText = cs[12].sections.widget_contents;

      htmlContent = (
        <Context.Provider value={this.state}>
          <ModalLogin
            texts={cs ? cs[7].sections.widget_contents : []}
            show={this.state.isModalLogin}
            loginClicked={this.toggleModalLogin}
            registerClicked={this.toggleModalRegister}
            forgotClicked={this.toggleModalForgot}
            dataErrorText={dataErrorText}
          />
          <ModalRegister
            texts={cs ? cs[8].sections.widget_contents : []}
            show={this.state.isModalRegister}
            loginClicked={this.toggleModalLogin}
            registerClicked={this.toggleModalRegister}
            dataErrorText={dataErrorText}
          />
          <ModalPassword
            texts={cs ? cs[7].sections.widget_contents : []}
            show={this.state.isModalForgot}
            loginClicked={this.toggleModalLogin}
            forgotClicked={this.toggleModalForgot}
            close={this.toggleModalClose}
            dataErrorText={dataErrorText}
          />
          <Navbar
            onClick={this.toggleActiveTab}
            searchHandler={this.searchHandler}
            active={this.state.tabsActive}
            data={this.state.dataApplicant}
            loginClicked={this.toggleModalLogin}
            isLoggedIn={this.state.isLoggedIn}
          />
          <CareerBrowse
            search={this.state.Search}
            onClick={this.toggleActiveFilter}
            active={this.state.filter}
          />
        </Context.Provider>
      );
    }

    return (
      <div className={classes.careerSection}>
        <ReactNotification
          types={[
            {
              htmlClasses: [classes.phenikaaNotif],
              name: "phenikaa",
            },
          ]}
        />
        {htmlContent}
      </div>
    );
  }
}

export default withRouter(Mobile);
