import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

import Api from "./Api";
import classes from "./Career_Full_Screen_Image_Part.module.css";

export default class Career_Full_Screen_Image_Part extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dummy: [
                { id: 1, name: "title", content: "CAREER AT PHENIKAA GROUP" },
                {
                    id: 2,
                    name: "subtitle",
                    content: "Lorem ipsum lorem ipsum lorem",
                },
                {
                    id: 3,
                    name: "desc",
                    content:
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
                },
                {
                    id: 4,
                    name: "brands",
                    articles: [
                        {
                            name: "brand1",
                            title: "VICOSTONE",
                            assets: require("./assets/Vicostone.jpg"),
                        },
                        {
                            name: "brand2",
                            title: "PHENIKAA UNIVERSITY",
                            assets: require("./assets/Phenikaa_University.jpg"),
                        },
                        {
                            name: "brand3",
                            title: "PRATI",
                            assets: require("./assets/Phenikaa_Prati.jpg"),
                        },
                        {
                            name: "brand4",
                            title: "SCHOOL",
                            assets: require("./assets/Phenikaa_School.jpg"),
                        },
                        {
                            name: "brand5",
                            title: "PHENIKAA CHEMICAL",
                            assets: require("./assets/Phenikaa_Chermical.jpg"),
                        },
                        {
                            name: "brand6",
                            title: "PHENIKAA MANUFACTURING",
                            assets: require("./assets/Phenikaa_Manufacturing.jpg"),
                        },
                    ],
                },
                {
                    id: 4,
                    name: "culture",
                    articles: [
                        {
                            name: "culture1",
                            title: "Lorem Ipsum",
                            assets: require("./assets/default-image.svg"),
                        },
                        {
                            name: "culture2",
                            title: "Lorem Ipsum?",
                            assets: require("./assets/default-image.svg"),
                        },
                        {
                            name: "culture3",
                            title: "Lorem Ipsum?",
                            assets: require("./assets/default-image.svg"),
                        },
                        {
                            name: "culture4",
                            title: "Lorem Ipsum?",
                            assets: require("./assets/default-image.svg"),
                        },
                        {
                            name: "culture5",
                            title: "Lorem Ipsum?",
                            assets: require("./assets/default-image.svg"),
                        },
                        {
                            name: "culture6",
                            title: "Lorem Ipsum?",
                            assets: require("./assets/default-image.svg"),
                        },
                    ],
                },
            ],

            contentSection: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: false,
            activeImg: 3,
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ contentSection: [] }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    const sections = result.data;
                    const widget = [];

                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            index: item.index,
                            category: item.category,
                            name: item.name,
                            sections: item,
                        });
                    });

                    sections.page = widget;
                    this.setState({
                        contentSection: widget,
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    this.setState({
                        isApiLoaded: true,
                    });
                });
        });
    }

    toggleExtends = (i) => {
        this.setState({
            activeImg: i,
        });
    };

    render() {
        let companyCultureImages = null;

        let dummyCulture = null;

        dummyCulture = this.state.dummy[4].articles.map((s, i) => {
            let active = this.state.activeImg === i ? classes.activeImg : "";
            return (
                <div
                    className={[classes.boxGallery, active].join(" ")}
                    onClick={() => this.toggleExtends(i)}
                >
                    <figure>
                        <img src={s.assets} alt=""></img>
                    </figure>
                </div>
            );
        });

        let title, content, readmore, link;
        if (this.state.isLoaded) {
            this.state.contentSection.forEach((item, index) => {
                switch (index) {
                    case 0:
                        title = item.sections.widget_contents[0]
                            ? item.sections.widget_contents[0].content
                            : "Company Culture";
                        break;
                    case 1:
                        content = item.sections.widget_contents[0]
                            ? item.sections.widget_contents[0].article_language.content
                            : "";
                        break;
                    case 2:
                        readmore = item.sections.widget_contents[0]
                            ? item.sections.widget_contents[0].content
                            : "Read more";
                        link = <Link to={item.sections.url !== '' ? item.sections.url : '/company-culture'} className={classes.contentLink}>
                            <div className={classes.outerCircle}>
                                <div className={classes.innerCircle}></div>
                            </div>
                            <p className={classes.readMore}>{readmore}</p>
                        </Link>
                        break;
                    case 3:
                        companyCultureImages = item.sections.widget_contents.map(
                            (imageItem, i) => {
                                let active =
                                    this.state.activeImg === i
                                        ? classes.activeImg
                                        : "";
                                return (
                                    <div
                                        className={[
                                            classes.boxGallery,
                                            active,
                                        ].join(" ")}
                                        onClick={() => this.toggleExtends(i)}
                                    >
                                        <figure>
                                            <img
                                                src={imageItem.file_medium}
                                                alt=""
                                            ></img>
                                        </figure>
                                    </div>
                                );
                            }
                        );
                        break;
                    default:
                        break;
                }
            });
        }

        return (
            <>
                <div className={["container", classes.customContainer].join(" ")}>
                    {/* <p className={classes.title}>{title}</p>
                    <p className={classes.desc}>{ReactHtmlParser(content)}</p>
                    {link} */}

                    <div className={classes.section}>
                        <div className={classes.cultureWrap}>
                            {companyCultureImages
                                ? companyCultureImages
                                : dummyCulture}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
