import React, { Component } from "react"
import { Link } from "react-router-dom"

import Api from "../Api"
import MobileView from "./MobileViews"

import classes from "./Mobile.module.css"

export default class Mobile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      sections: [],
      isLoaded: false,
      isApiLoaded: false,
      url: null,
      sectionName: "",
    }
    this.getSectionDetail = this.getSectionDetail.bind(this)
  }
  componentDidMount() {
    this.getSectionDetail()
  }

  getSectionDetail() {
    const apiData = new Api()
    apiData
      .getSectionDetail()
      .then((result) => {
        this.setState({ isApiLoaded: true })
        const sections = result.data
        const widget = []

        let url = sections.widgets[4].url
        let sectionName = sections.name.toLowerCase().replace(/\s+/g, "-")

        result.data.widgets.forEach((item) => {
          widget.push({
            id: item.id,
            index: item.index,
            category: item.category,
            internal: item.is_internal,
            name: item.name,
            sections: item,
          })
        })
        sections.page = widget
        this.setState({ sections: widget, url: url, sectionName: sectionName, isLoaded: true })
      })
      .catch((error) => {
        this.setState({ isApiLoaded: true })
      })
  }
  render() {
    const { sections } = this.state

    let imgChairman, contentArticle, title, viewAll, viewAllContent, viewAllBtn, dummyImgChairman, dummyArticle

    if (this.state.isLoaded) {
      let count = 0
      sections.forEach((item) => {
        count = count + 1
        switch (item.index) {
          case 1:
            item.sections.widget_contents.forEach((s, i) => {
              viewAllContent = i === 0 ? s.content : "View All"
              imgChairman = s.file !== "" ? <img className={classes.imgContent} src={s.file} alt={s.description} /> : dummyImgChairman
            })
            break

          case 2:
            viewAll = item.sections.widget_contents.length > 0 ? item.sections.widget_contents[0].content : "View All History"
            viewAllBtn = (
              <Link to={item.sections.url}>
                <p className={classes.textLinkBlack}>
                  <div className={classes.outerCircle}>
                    <div className={classes.innerCircle}></div>
                  </div>
                  {viewAll}
                </p>
              </Link>
            )
            break
          case 3:
            contentArticle = !item.is_internal ? <MobileView id={item.id} url={item.sections.url} urlMobile={this.state.url} viewAll={viewAllContent} viewAllM={viewAll} sectionName={this.state.sectionName} /> : dummyArticle

            break
          case 4:
            title = item.sections.widget_contents[0].content
            break
          default:
        }
      })
    } else if (this.state.isApiLoaded) {
      imgChairman = dummyImgChairman
      contentArticle = dummyArticle
      title = "Our History"
    }
    const settings = {
      className: "",
      infinite: false,
      centerPadding: "",
      variableWidth: true,
      slidesToShow: 1,
      swipeToSlide: true,
    }
    return (
      <div className={classes.contentSection}>
        <div className={classes.sectionHistory}>
          <p className={classes.sectionTitle}>{title}</p>
          {viewAllBtn}
        </div>
        <div className={classes.contentArt}>{contentArticle}</div>
      </div>
    )
  }
}
