import React, { Component } from 'react'

import UserContext from '../Context'
import Profile from './Profile/Profile'
import Experience from './Experience/Experience'

import classes from './Career_ProfileN.module.css'

export default class Career_ProfileN extends Component {
    static contextType = UserContext
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount(){
        
    }

    render() {
        const contextValue = this.context
        let profile, skill
        if (contextValue.isSectionLoaded){
            if (contextValue.contentSection[4]){
                contextValue.contentSection[4].sections.widget_contents.forEach((c,i) => {
                    switch (i) {
                        case 0:
                            profile = c.content
                            break;
                        case 8:
                            skill = c.content
                            break;
                        default:
                    }
                })
            }
        }

        return (
            <div className={['container', classes.customContainer].join(' ')}>
                <div className={classes.boxContent}>
                    <p className={classes.titleContent}>{profile}</p>
                    <div className={classes.box1}>
                        <div className={classes.boxProfile}>
                            <Profile data={this.props.data} updateData={this.props.updateData} id={this.props.id}/>
                        </div>

                        {/* <div className={classes.boxProgress}>
                            <Progress id={this.props.id}/>
                        </div> */}
                    </div>

                    <p className={classes.titleContent}>{skill}</p>
                    <div className={classes.box2}>
                        <Experience data={this.props.data} id={this.props.id}/>
                    </div>
                </div>
            </div>
        )
    }
}
