import axios from 'axios';

export default class Api {
   base_api = 'https://api.phenikaa.com/'
   token = '82e2e0f4b2d790b30cf2916d1bf664bdb3620149'


   async getSectionDetail() {
        let params = 801
        let url = null
        let lang = localStorage.getItem('language')
        if (lang==='English'){
            url = this.base_api+`section_customer/${params}/?language=en`
        } else {
            url = this.base_api+`section_customer/${params}/`
        }
        return await axios.get(url, {
            params: {
            },
            headers: {
                'Authorization': `Token ${this.token}`,
                'Content-Type': 'application/json',
            }
        })
    }

   async getArticleDetail(param) {
       let params = param
       let url = null
       let lang = localStorage.getItem('language')
       let status = '&status=Published'
       if (lang==='English'){
           url = this.base_api+`articles/?widget=${params}${status ? status : ''}&language=en&max_size=true`
       } else {
           url = this.base_api+`articles/?widget=${params}${status ? status : ''}&language=vi&max_size=true`
       }
       return await axios.get(url, {
           params: {
           },
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'application/json',
           }
       })
   }

   async getArticleDetailOrderByIndex(param) {
       let params = param
       let url = null
       let lang = localStorage.getItem('language')
       let status = '&status=Published'
       if (lang==='English'){
           url = this.base_api+`articles/?widget=${params}${status ? status : ''}&language=en&max_size=true&order=index`
       } else {
           url = this.base_api+`articles/?widget=${params}${status ? status : ''}&language=vi&max_size=true&order=index`
       }
       return await axios.get(url, {
           params: {
           },
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'application/json',
           }
       })
   }

   async getArticleDetailByArticleId(param) {
       let params = param;
       let url = null;
       let lang = localStorage.getItem('language');
       let status = '&status=Published'
       if (lang === 'English') {
           url = this.base_api + `articles/${params}/?language=en${status ? status : ''}`;
       } else {
           url = this.base_api + `articles/${params}/?language=vi${status ? status : ''}`;
       }
       return await axios.get(url, {
       params: {},
       headers: {
           Authorization: `Token ${this.token}`,
           'Content-Type': 'application/json',
       },
       });
   }

   async getMenuDetail(content) {
       let params_menu = null
       let url = null
       let lang = localStorage.getItem('language')
       if (lang==='English'){
           if (content) {
               url = this.base_api+`menus/${params_menu}/?language=en&content=${content}`
           } else {
               url = this.base_api+`menus/${params_menu}/?language=en`
           }
       } else {
            if (content) {
               url = this.base_api+`menus/${params_menu}/?content=${content}`
            } else {
               url = this.base_api+`menus/${params_menu}/`
            }
       }
       return axios.get(url,{
           params_menu:{
               // template:params.template
           },
           headers:{
               'Authorization':`Token ${this.token}`,
               'Content-Type':'application/json',
           }
       })
   }

   async getAllCategories() {
       let lang = localStorage.getItem('language')
       let url
       if (lang === 'English') {
           url = this.base_api + 'article_categories/?language=en'
       } else {
           url = this.base_api + 'article_categories/?language=vi'
       }
       console.log(url)
       return await axios.get(url, {
       params: {
           },
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'application/json',
           },
       })
   }

   async getAllArticleDetails(filter, size, search) {
       let setfilter = `&category=${filter ? filter : ''}`
       let setSize = `?page_size=${size}`
       let setSearch = `&search=${search}`
       let url = null
       let lang = localStorage.getItem('language')
       let status = '&status=Published'
       if (lang === 'English') {
           url = this.base_api + `articles/${size ? setSize : `?page_size=50`}${status ? status : ''}&details=true&language=en${filter ? setfilter : ''}${search ? setSearch : ''}`
       } else {
           url = this.base_api + `articles/${size ? setSize : `?page_size=50`}${status ? status : ''}&details=true&language=vi${filter ? setfilter : ''}${search ? setSearch : ''}`
       }
       console.log(url)
       // 'http://54.255.70.35/articles/?details=true&search=vicostone&exclude_category=9'
       return await axios.get(url, {
           params: {
           },
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'application/json',
           },
       })
   }

    async getArticleDetailByYear(param, filter, size, search) {
        let params = param
        let setfilter = `&category=${filter ? filter : ''}`
        let setSize = `?page_size=${size}`
        let setSearch = `&search=${search}`
        let order = '&order=date'
        let url = null
        let lang = localStorage.getItem('language')
       let status = '&status=Published'
        if (lang === 'English') {
            url = this.base_api + `articles/${size ? setSize : `?page_size=5000`}${order}${status ? status : ''}&widget=${params}&language=en${filter ? setfilter : ''}${search ? setSearch : ''}`
        } else {
            url = this.base_api + `articles/${size ? setSize : `?page_size=5000`}${order}${status ? status : ''}&widget=${params}&language=vi${filter ? setfilter : ''}${search ? setSearch : ''}`
        }
        console.log(url)
        return await axios.get(url, {
            params: {
            },
            headers: {
                'Authorization': `Token ${this.token}`,
                'Content-Type': 'application/json',
            },
        })
    }

   async getArticleGroupbyYear(param) {
       let paramId = param;
       let url = null;
       let lang = localStorage.getItem('language');
       let status = '&status=Published'
       if (lang === 'English') {
           url = this.base_api + `articles/group_by_year/?widget=${paramId}&language=en${status ? status : ''}`;
       } else {
           url = this.base_api + `articles/group_by_year/?widget=${paramId}&language=vi${status ? status : ''}`;
       }
       return await axios.get(url, {
           paramId: {},
           headers: {
               Authorization: `Token ${this.token}`,
               'Content-Type': 'application/json',
           },
       });
   }

   getArticleDetailByYearDescending(param, filter, size, search) {
       let params = param
       let setfilter = `&category=${filter ? filter : ''}`
       let setSize = `?page_size=${size}`
       let setSearch = `&search=${search}`
       let order = '&order=-date'
       let url = null
       let lang = localStorage.getItem('language')
       let status = '&status=Published'
       if (lang === 'English') {
           url = this.base_api + `articles/${size ? setSize : `?page_size=5000`}${order}${status ? status : ''}&widget=${params}&language=en${filter ? setfilter : ''}${search ? setSearch : ''}`
       } else {
           url = this.base_api + `articles/${size ? setSize : `?page_size=5000`}${order}${status ? status : ''}&widget=${params}&language=vi${filter ? setfilter : ''}${search ? setSearch : ''}`
       }
       return axios.get(url, {
           params: {
           },
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'application/json',
           },
       })
   }

   async getJobDetail(param, customParam) {
       let url = this.base_api + `job_posters/`;
       let lang = localStorage.getItem('language');
       return await axios.get(url, {
           params: {
               applicant: param ? param : null,
               language: lang === 'English' ? 'en' : 'vi',
               ...customParam
           },
           headers: {
               Authorization: `Token ${this.token}`,
               'Content-Type': 'application/json',
           },
       });
   }

    async getJobByApplicant(id, search, filter) {
        let url
        let lang = localStorage.getItem('language')
        let isSearch = search ? `&search=${search}` : ''
        let isFilter = filter ? `&company=${filter}` : ''
        if (lang === 'English') {
            url = this.base_api + `applications/?applicant=${id}${isSearch}${isFilter}&language=en`
        } else {
            url = this.base_api + `applications/?applicant=${id}${isSearch}${isFilter}`
        }
        return await axios.get(url, {
            params: {
            },
            headers: {
                'Authorization': `Token ${this.token}`,
                'Content-Type': 'application/json',
            }
        })
    }

   getCompanyHasJobs() {
       let url = this.base_api + 'companies/?has_job=true'
       return axios.get(url, {
           params: {
           },
           headers: {
                'Authorization': `Token ${this.token}`,
                'Content-Type': 'application/json',
           }
       })
   }

   registerApplicant(data) {
       var bodyFormData = new FormData()
       bodyFormData.append('gender', data.gender)
       bodyFormData.append('country', data.country)
       bodyFormData.append('birth_date', data.birthdate)
       bodyFormData.append('working_experience', '')
       bodyFormData.append('last_education', '')
       bodyFormData.append('resume', '')
       bodyFormData.append('avatar', '')
       bodyFormData.append('phone', data.phone)
       bodyFormData.append('bio', '')
       bodyFormData.append('portfolios', '')
       bodyFormData.append('user.username', data.email)
       bodyFormData.append('user.first_name', data.firstname)
       bodyFormData.append('user.last_name', data.lastname)
       bodyFormData.append('user.email', data.email)
       bodyFormData.append('user.password', data.password)
       bodyFormData.append('user.confirm_password', data.password)
       return axios.post(this.base_api + 'applicants/', bodyFormData, {
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'multipart/form-data',
           }
       })
   }

   async getApplicant(data) {
       return await axios.post(this.base_api + 'login/', data, {
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'application/json',
           }
       })
   }

   async getDataApplicant(param) {
       let url = this.base_api + 'applicants/' + param
       let token = localStorage.getItem('token')
       return await axios.get(url, {
           params: {},
           headers: {
               'Authorization': `Token ${token}`,
               'Content-Type': 'application/json',
           }
       })
   }

   setLogoutApplicant() {
       let url = this.base_api + 'rest-auth/logout/'
       let token = localStorage.getItem('token')
       return axios.post(url, {
           params: {},
           headers: {
               'Authorization': `Token ${token}`,
               'Content-Type': 'application/json',
           }
       })
   }

   getListCountries() {
       let url = this.base_api + 'countries/?max_size=True'
       return axios.get(url, {
           params: {},
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'application/json',
           }
       })
   }

   updateApplicant(id, data, param) {
       let url = `${this.base_api}applicants/${id}/`;
       var bodyFormData = new FormData();
       if (param === 'name') {
           bodyFormData.append('user.first_name', data.firstname);
           bodyFormData.append('user.last_name', data.lastname);
       } else if (param === 'email') {
           bodyFormData.append('user.email', data.email);
       }
       else if (param === 'bio') {
           bodyFormData.append('address', data.address);
       } else if (param === 'phone') {
           bodyFormData.append('phone', data.phone);
       } else if (param === 'birthdate') {
           bodyFormData.append('birth_date', data.birthdate);
       } else if (param === 'working') {
           bodyFormData.append('working_experience', data.working);
       } else if (param === 'education') {
           bodyFormData.append('last_education', data.education);
       } else if (param === 'country') {
           bodyFormData.append('country', data.country);
       } else if (param === 'avatar') {
           data.forEach((element) => {
               bodyFormData.append('avatar', element);
           });
       }
       return axios.patch(url, bodyFormData, {
           headers: {
               Authorization: `Token ${this.token}`,
               'Content-Type': 'multipart/form-data',
           },
       });
   }

   updatePassword(data) {
       let url = `${this.base_api}change_password/`
       let token = localStorage.getItem('token')
       var bodyFormData = new FormData()
       bodyFormData.append('old_password', data.current)
       bodyFormData.append('new_password', data.new)
       bodyFormData.append('confirm_password', data.confirm)
       return axios.put(url, bodyFormData, {
           headers: {
               'Authorization': `Token ${token}`,
               'Content-Type': 'multipart/form-data',
           }
       })
   }

   uploadResume(id, data) {
       let url = `${this.base_api}applicant_attachments/`
       let bodyFormData = new FormData()
       data.forEach(element => {
           bodyFormData.append('applicant', id)
           bodyFormData.append('attachment_type', 'Resume')
           bodyFormData.append('attachment_file', element)
           bodyFormData.append('attachment_link', '')
       });
       return axios.post(url, bodyFormData, {
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'multipart/form-data',
           }
       })
   }

   deleteResume(data) {
       let url = `${this.base_api}applicant_attachments/${data}`
       return axios.delete(url, {
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'application/json',
           }
       })
   }

   uploadPortofolio(id, dataFile, dataLink) {
       let url = `${this.base_api}applicant_attachments/`
       let bodyFormData = new FormData()
       if (dataFile.length > 0) {
           dataFile.forEach(file => {
               bodyFormData.append('applicant', id)
               bodyFormData.append('attachment_type', 'Portfolio')
               bodyFormData.append('attachment_file', file)
               bodyFormData.append('attachment_link', '')
           });
       }
       if (dataLink.length > 0) {
           dataLink.forEach(link => {
               bodyFormData.append('applicant', id)
               bodyFormData.append('attachment_type', 'Portfolio')
               bodyFormData.append('attachment_file', '')
               bodyFormData.append('attachment_link', link)
           });
       }
       return axios.post(url, bodyFormData, {
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'multipart/form-data',
           }
       })
   }

   addLastEducation(id, data){
       let url = `${this.base_api}applicant_educations/`
       let bodyFormData = new FormData()
       bodyFormData.append('applicant', id)
       bodyFormData.append('title', data.title)
       bodyFormData.append('level', data.level)
       bodyFormData.append('school', data.school)
       bodyFormData.append('description', data.description)
       bodyFormData.append('date_start', data.date_start)
       bodyFormData.append('date_finish', data.date_finish)
       return axios.post(url, bodyFormData, {
           headers: {
                  'Authorization': `Token ${this.token}`,
                  'Content-Type': 'multipart/form-data',
           }
       })
   }

   updateLastEducation(id, data){
       let url = `${this.base_api}applicant_educations/${id}/`
       let bodyFormData = new FormData()    
       bodyFormData.append('title', data.title)
       bodyFormData.append('level', data.level)
       bodyFormData.append('school', data.school)
       bodyFormData.append('description', data.description)
       bodyFormData.append('date_start', data.date_start)
       bodyFormData.append('date_finish', data.date_finish)
       return axios.patch(url, bodyFormData, {
           headers: {
                  'Authorization': `Token ${this.token}`,
                  'Content-Type': 'multipart/form-data',
           }
       })
   }

   addWorkingExperience(id, data){
       let url = `${this.base_api}applicant_experiences/`
       let bodyFormData = new FormData()
       bodyFormData.append('applicant', id)
       bodyFormData.append('title', data.title)
       bodyFormData.append('company', data.company)
       bodyFormData.append('description', data.description)
       bodyFormData.append('date_start', data.date_start)
       bodyFormData.append('date_finish', data.date_finish)
       return axios.post(url, bodyFormData, {
           headers: {
              'Authorization': `Token ${this.token}`,
              'Content-Type': 'multipart/form-data',
           }
       })
   }

   updateWorkingExperience(id, data){
       let url = `${this.base_api}applicant_experiences/${id}/`
       let bodyFormData = new FormData()
       bodyFormData.append('title', data.title)
       bodyFormData.append('company', data.company)
       bodyFormData.append('description', data.description)
       bodyFormData.append('date_start', data.date_start)
       bodyFormData.append('date_finish', data.date_finish)
       return axios.patch(url, bodyFormData, {
           headers: {
              'Authorization': `Token ${this.token}`,
              'Content-Type': 'multipart/form-data',
           }
       })
   }

   resetPassword(data){
       let url = this.base_api + 'password_reset/confirm/'
       var bodyFormData = new FormData()
       bodyFormData.append('password', data.password)
       bodyFormData.append('token', data.token)
       return axios.post(url, bodyFormData, {
           params: {
           },
           headers: {
              'Authorization': `Token ${this.token}`,
              'Content-Type': 'multipart/form-data',
           }
       })
   }

   resetPasswordEmail(data) {
       let url = this.base_api + 'password_reset/';
       return axios.post(url, data, {
           params: {},
           headers: {
               Authorization: `Token ${this.token}`,
               'Content-Type': 'application/json',
           },
       });
   }

   sendAnEmail(data, id) {
       let url = `${this.base_api}templates/${id}/send_message/`;
       return axios.post(
           url,
           {
               name: data.name,
               email: data.email,
               phone: data.phone,
               subject: data.subject,
               message: data.message,
               language: data.language,
               recipient_ids: data.recipients,
           },
           {
               headers: {
                   Authorization: `Token ${this.token}`,
                   'Content-Type': 'application/json',
               },
           }
       );
   }

   async applyJobApplicant(data) {
       let url = this.base_api + 'applications/'
       let token = localStorage.getItem('token')
       return await axios.post(url, data, {
           headers: {
              'Authorization': `Token ${this.token}`,
              'Content-Type': 'application/json',
           }
       })
   }

   async getArticleDetailByYearDescendingPagination(param, filter, size, search, page) {
       let params = param
       let setfilter = `&category=${filter ? filter : ''}`
       let setSize = `?page_size=${size}`
       let setSearch = `&search=${search}`
       let setPage = `&page=${page}`
       let order = '&order=-date'
       let url = null
       let lang = localStorage.getItem('language')
       let status = '&status=Published'
       if (lang === 'English') {
           url = this.base_api + `articles/${size ? setSize : `?page_size=5000`}${page ? setPage : ''}${order}&widget=${params}&language=en${filter ? setfilter : ''}${search ? setSearch : ''}${status ? status : ''}`
       } else {
           url = this.base_api + `articles/${size ? setSize : `?page_size=5000`}${page ? setPage : ''}${order}&widget=${params}&language=vi${filter ? setfilter : ''}${search ? setSearch : ''}${status ? status : ''}`
       }
       return await axios.get(url, {
           params: {
           },
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'application/json',
           },
       })
   }

   async getArticleDetailByYearDescendingPaginationMultiWidget(arrParam, filter, size, search, page) {
       let params = arrParam
       let setfilter = `&category=${filter ? filter : ''}`
       let setSize = `?page_size=${size}`
       let setSearch = `&search=${search}`
       let setPage = `&page=${page}`
       let order = '&order=-date'
       let widgets = ''
       params.forEach(e => {
           widgets += `&widget=${e}`
       })
       let url = null
       let lang = localStorage.getItem('language')
       let status = '&status=Published'
       if (lang === 'English') {
           url = this.base_api + `articles/${size ? setSize : `?page_size=5000`}${page ? setPage : ''}${order}${widgets}&language=en${filter ? setfilter : ''}${search ? setSearch : ''}${status ? status : ''}`
       } else {
           url = this.base_api + `articles/${size ? setSize : `?page_size=5000`}${page ? setPage : ''}${order}${widgets}&language=vi${filter ? setfilter : ''}${search ? setSearch : ''}${status ? status : ''}`
       }
       return await axios.get(url, {
           params: {
           },
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'application/json',
           },
       })
   }

   bookmarkJob(idApplicant, idJob){
       var bodyFormData = new FormData();
       bodyFormData.append('applicant', idApplicant);
       bodyFormData.append('job_poster', idJob);
       return axios.post(this.base_api + 'job_bookmarks/', bodyFormData, {
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'application/json',
           },
       });
   }

    async getArticleDetailByMultipleWigdetOnNews(id, size, widget_1, widget_2) {
        let order = '&order=-date';
        let url = null;
        let widget2 = `&widget=${widget_2}`;
        let lang = localStorage.getItem('language');
        if (lang === 'English') {
        url = this.base_api + `articles/?category=${id}&page_size=${size}&widget=${widget_1}${widget2}&language=en${order}&page=1&details=true`;
        } else {
        url = this.base_api + `articles/?category=${id}&page_size=${size}&widget=${widget_1}${widget2}&language=vi${order}&page=1&details=true`;
        }
        return await axios.get(url, {
        params: {},
        headers: {
            Authorization: `Token ${this.token}`,
            'Content-Type': 'application/json',
        },
        });
    }
   removeBookmarkJob(idJob){
       let url = `${this.base_api}job_bookmarks/${idJob}`;
       return axios.delete(url, {
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'application/json',
           },
       });
   }

   async getArticleDetailByAuthor(param, authorName, size, customParam) {
       let params = param
       let url = null
       let lang = localStorage.getItem('language')
       let status = '&status=Published'
       if (lang==='English'){
           url = this.base_api+`articles/?language=en${status ? status : ''}`
       } else {
           url = this.base_api+`articles/?language=vi${status ? status : ''}`
       }
       return await axios.get(url, {
           params: {
               page_size: size,
               author: authorName,
               widget: params,
               ...customParam
           },
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'application/json'
           }
       })
   }

   async getAuthorsByWidget(param) {
       let params = param
       let url = null
       let lang = localStorage.getItem('language')
       if (lang==='English'){
           url = this.base_api+`authors/?widget=${params}&language=en`
       } else {
           url = this.base_api+`authors/?widget=${params}&language=vi`
       }
       return await axios.get(url, {
           params: {
           },
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'application/json'
           }
       })
   }

   async getArticleDetailPagination(param, size, page, customParam) {
       let params = param
       let setSize = `?page_size=${size}`;
       let setPage = `&page=${page}`;
       let url = null
       let lang = localStorage.getItem('language')
       let status = '&status=Published'
       if (lang==='English'){
           url = this.base_api+`articles/${size ? setSize : '?page_size=5000'}${page ? setPage : ''}&widget=${params}&language=en${status ? status : ''}`
       } else {
           url = this.base_api+`articles/${size ? setSize : '?page_size=5000'}${page ? setPage : ''}&widget=${params}&language=vi${status ? status : ''}`
       }
       return await axios.get(url, {
           params: customParam,
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'application/json',
           }
       })
   }

   updateFinalSallary(param) {
       let lang = localStorage.getItem('language');
       let token = localStorage.getItem('token');
       let url = null;
       var bodyFormData = new FormData();
       bodyFormData.append('final_offer', 'True');
       if (lang === 'English') {
           url = this.base_api + `application_offers/${param}/`;
       } else {
           url = this.base_api + `application_offers/${param}/`;
       }
       return axios.patch(url, bodyFormData, {
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'application/json',
           },
       });
   }

   addOfferingSalary(data) {
       let lang = localStorage.getItem('language');
       var bodyFormData = new FormData();
       bodyFormData.append('application', data[0].id_user);
       bodyFormData.append('currency', data[0].currency_id);
       bodyFormData.append('salary_offer', data[0].salary_offer);
       bodyFormData.append('offered_by', data[0].offered_by);
       let url = null;
       if (lang === 'English') {
           url = this.base_api + `application_offers/?max_size=True&language=en`;
       } else {
           url = this.base_api + `application_offers/?max_size=True&language=vi`;
       }
       return axios.post(url, bodyFormData, {
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'application/json',
           },
       });
   }

   addOfferingSalaryNew(data) {
       let lang = localStorage.getItem('language');
       var bodyFormData = new FormData();
       bodyFormData.append('applicant', data[0].id_applicant);
       bodyFormData.append('job_poster', data[0].id_job);
       bodyFormData.append('currency', data[0].id_currency);
       bodyFormData.append('salary_offer', data[0].salary_offer);
       bodyFormData.append('offered_by', data[0].offered_by);
       bodyFormData.append('final_offer', data[0].final_offer);
       let url = null;
       if (lang === 'English') {
           url = this.base_api + `job_offers/?max_size=True&language=en`;
       } else {
           url = this.base_api + `job_offers/?max_size=True&language=vi`;
       }
       return axios.post(url, bodyFormData, {
       headers: {
               Authorization: `Token ${this.token}`,
               'Content-Type': 'application/json',
           },
       });
   }

   async getOfferingData(idA, idJ) {
       console.log(idA + '--' + idJ);
       let lang = localStorage.getItem('language');
       let url = null;
       if (lang === 'English') {
           url = this.base_api + `application_offers/?job_poster=${idJ}&applicant=${idA}&max_size=True&language=en`;
       } else {
           url = this.base_api + `application_offers/?job_poster=${idJ}&applicant=${idA}&max_size=True&language=vi`;
       }
       return await axios.get(url, {
           params: {},
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'application/json',
           },
       });
   }

   async getOfferingDataNew(idA, idJ) {
       let lang = localStorage.getItem('language');
       let url = null;
       if (lang === 'English') {
           url =
               this.base_api +
               `job_offers/?job_poster=${idJ}&applicant=${idA}&max_size=True&language=en`;
       } else {
           url =
               this.base_api +
               `job_offers/?job_poster=${idJ}&applicant=${idA}&max_size=True&language=vi`;
       }
       return await axios.get(url, {
       headers: {
               Authorization: `Token ${this.token}`,
               'Content-Type': 'application/json',
           },
       });
   }

   getCurrency() {
       let lang = localStorage.getItem('language');
       let url = null;
       if (lang === 'English') {
           url = this.base_api + `currencies/?language=en`;
       } else {
           url = this.base_api + `currencies/?language=vi`;
       }
       return axios.get(url, {
           params: {},
           headers: {
               'Authorization': `Token ${this.token}`,
               'Content-Type': 'application/json',
           },
       });
   }

   async getArticleDetailByWigdet(size, widget) {
       let order = '&order=-date';
       let url = null;
       let lang = localStorage.getItem('language');
       let status = '&status=Published'
       if (lang === 'English') {
           url =
               this.base_api +
               `articles/?page_size=${size}&widget=${widget}&language=en${order}&page=1&details=true${status ? status : ''}`;
       } else {
           url =
               this.base_api +
               `articles/?page_size=${size}&widget=${widget}&language=vi${order}&page=1&details=true${status ? status : ''}`;
       }
       return await axios.get(url, {
           params: {},
           headers: {
               Authorization: `Token ${this.token}`,
               'Content-Type': 'application/json',
           },
       });
   }

   async getJobDetailById(id, customParam) {
       let url = this.base_api + `job_posters/${id}/`;
       let lang = localStorage.getItem('language');
       return await axios.get(url, {
           params: {
               language: lang === 'English' ? 'en' : 'vi',
               ...customParam
           },
           headers: {
               Authorization: `Token ${this.token}`,
               'Content-Type': 'application/json',
           },
       });
   }

   loginBzPublish(email, password) {
       return axios.post(`http://api.bzpublish.com/admin-login/`, {
               email: email,
               password: password,
           },
           {
               params: {},
           }
       );
   }
}
