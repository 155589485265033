import React, { Component } from "react";
import classes from "./IN_PP.module.scss";
import Views from "./Views/Views";
import Api from "./Api";

export default class IN_PP extends Component {
  constructor() {
    super();
    this.state = {
      hasRun: false,
      currentPage: null,
      articleArray: [],
      catfilter: [],
      tabs: [
        {
          id: 1,
          title: "All news & Events",
          component: <div key={1} />,
          logo: (
            <img
              className={classes.button_tab}
              src={require("./assets/Polygon7.svg")}
              alt="description"
            />
          ),
        },
      ],
      yearG: [],
      yearO: [],
      sections: [],
      isLoaded: false,
      isApiLoaded: false,
      views: null,
      activePage: <div></div>,
      valueQuery: null,
      articleID: null,
      sectionName: "",
      sectionNameN: "",
      counted: 0,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
    this.getHtmlParagraph = this.getHtmlParagraph.bind(this);
    this.getArticles = this.getArticles.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
    window.addEventListener("scroll", this.handleScroll, true);
  }

  getSectionDetail() {
    const articleCollection = [];
    const apiData = new Api();
    apiData
      .getSectionDetail()
      .then((result) => {
        const sections = result.data;
        const widget = [];
        const yearG = [];
        const yearT = [];
        const yearO = [];
        let sectionName = sections.name.toLowerCase().replace(/\s+/g, "-");

        yearG.push(sections.widgets[4].article_years);
        yearT.push(sections.widgets[5].article_years);
        yearO.push(sections.widgets[6].article_years);
        result.data.widgets.forEach((item) => {
          if (item.category === "Article" && !item.is_internal) {
            this.getArticles(item.id, item.index);
          }

          widget.push({
            id: item.id,
            index: item.index,
            category: item.category,
            name: item.name,
            sections: item,
          });
        });

        sections.page = widget;

        this.setState({
          sectionName: result.data.name,
          sections: widget,
          yearG: yearG,
          yearT: yearT,
          yearO: yearO,
          isLoaded: true,
          sectionNameN: sectionName,
          articleID: widget[0].id,
          articleArray: articleCollection,
        });
      })
      .catch((error) => {
        this.setState({ isApiLoaded: true });
      });
  }

  getArticles(param, index) {
    const apiData = new Api();
    this.setState({}, () => {
      apiData
        .getArticleDetail(param)
        .then((result) => {
          const articles = result.data;
          const art = [];

          articles.results.forEach((a) => {
            const assets = [];
            a.article_assets.forEach((ass, i) => {
              if (i === 0) {
                assets.push({ asset: ass.asset_file });
              }
            });

            art.push({
              id: a.id,
              index: index,
              category: a.category_names,
              name: a.name,
              title: a.article_languages.title,
              content: a.article_languages.content,
              summary: a.article_languages.summary,
              assets: assets,
              date: a.date,
            });
          });
          if (this.state.articleArray.length > 0) {
            var tempArray = this.state.articleArray;
            var concatenatedArray = tempArray.concat(art);
            this.setState({ articleArray: concatenatedArray });
          } else {
            this.setState({ articleArray: art });
          }
          this.setState({
            counted: this.state.counted + 1,
            isLoaded: true
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isApiLoaded: true });
        });
    });
  }

  getHtmlParagraph(str) {
    if (str && str !== "") {
      return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ");
    }
  }

  render() {
    const { sections } = this.state;

    var newsButton = "";
    let Apititle1,
      Apititle2,
      apiContent,
      topTitle,
      contentArticle,
      dummytitle,
      imageBackground,
      logo,
      titleArticle,
      sectionTitle,
      allFilter;


    let titleFilter1,
      titleFilter2,
      titleFilter3,
      idPP,
      link,
      urlDetail,
      readmore,
      readmoreUrl = null;

    if (this.state.isLoaded ) {
      apiContent = sections.map((item, i) => {
        logo = <img src={require("./Views/assets/Group3411.svg")} alt="img" />;
        Apititle1 = item.nameAwards;
        Apititle2 = item.nameOverview;
        switch (item.index) {
          case 1:
            imageBackground = item.sections.widget_contents[0]
              ? item.sections.widget_contents[0].file_big
              : require("./assets/banner.jpg");
            break;
          case 2:
            sectionTitle = item.sections.widget_contents[0]?.content
              ? item.sections.widget_contents[0]?.content
              : "dummytitle";
            break;
          case 3:
            item.sections.widget_contents.forEach((dropDownText, index) => {
              switch (index) {
                case 0:
                  allFilter = dropDownText.content;
                  break;
                default:
              }
            });
            break;
          case 4:
            topTitle = item.sections.widget_contents[0]
              ? item.sections.widget_contents[0].article_language.title
              : "Awards Overview";
            titleArticle = item.sections.widget_contents[0]
              ? item.sections.widget_contents[0].article_language.content
              : "A nice content of this overview introduction";
            break;
          case 5:
            idPP = item.id;
            urlDetail = item.sections.url
              ? item.sections.url
              : "/về-chúng-tôi/giải-thưởng/awards-overview-detail";

            break;
          case 7:
            link = item.sections.widget_contents.map((ele) => {
              let prevLink = ""; //  localStorage.getItem("prevLink");

              return (
                <div className={classes.navBack}>
                  {/* <a href={prevLink && prevLink !== "" ? prevLink : item.sections.url} className={classes.backLink}>
                    <FontAwesomeIcon className={classes.backIcon} icon={faArrowLeft} />
                    <div>{ele.content}</div>
                  </a> */}
                  <a href={item.sections.url} className={classes.customLink}>
                    <div className={"d-flex"}>
                      <div className={"d-flex"}>
                        <i
                          className={[
                            classes.arrow,
                            classes.left,
                            classes.customMargin,
                          ].join(" ")}
                        ></i>
                      </div>
                      <span className={classes.textBack}>{ele.content}</span>
                    </div>
                  </a>
                </div>
              );
            });
            break;
          case 8:
            readmoreUrl =
              item.sections.url !== ""
                ? item.sections.url
                : "/about-us/awards/awards-overview-detail";
            readmore = item.sections.widget_contents[0]
              ? item.sections.widget_contents[0].content
              : "Read more";
            return (
              <Views
                idPP={idPP}
                // url={url}
                category={item.sections.article_categories}
                newsButton={newsButton}
                titleFilter1={titleFilter1}
                titleFilter2={titleFilter2}
                titleFilter3={titleFilter3}
                topTitle={sectionTitle}
                filterAll={allFilter}
                articleList={this.state.articleArray}
                readmoreUrl={readmoreUrl}
                readmore={readmore}
                sectionNameN={this.state.sectionNameN}
              />
            );
          default:
        }
      });

      contentArticle = (
        <div className={classes.titleSection}>
          <div>
            <p>{Apititle1}</p>
          </div>
          <div>
            <p>{Apititle2}</p>
          </div>
          <div className={classes.imgBanner}></div>
        </div>
      );
    } else {
      contentArticle = dummytitle;
    }

    return (
      <div className="page-news">
        <div className={classes.main}>
          <div className={classes.bannerTop}>
            <img src={imageBackground} alt="Background banner" />
          </div>
          <div className={["container", classes.customContainer].join(" ")}>
            <div className={[classes.contentWrap].join(" ")}>{apiContent}</div>
            {link}
          </div>
        </div>
      </div>
    );
  }
}
