import React, { Component } from "react";
import { Modal } from "react-bootstrap";

import UserContext from "../../../Context";

import Api from "../../../../Api";
import classes from "./Modal.module.css";

export default class ModalName extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      id: 0,
    };
  }

  componentDidMount() {
    const contextValue = this.context;

    this.setState({
      isLoggedIn: contextValue.isLoggedIn,
      id: contextValue.idApplicant,
    });
  }

  updateProfile() {
    const update = new Api();

    let updateData;
    if (this.props.content === "working") {
      updateData = {
        working: document.getElementById("m-working").value,
      };
    } else if (this.props.content === "education") {
      updateData = {
        education: document.getElementById("m-education").value,
      };
    }

    update
      .updateApplicant(this.state.id, updateData, this.props.content)
      .then((res) => {
        this.props.close();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    let labelTitle, content, contentId;
    let working, education;

    const contextValue = this.context;
    if (contextValue && this.props.content) {
      switch (this.props.content) {
        case "working":
          labelTitle = "Working Experience";
          content = "working";
          contentId = "m-working";
          working =
            contextValue.dataApplicant.working_experience !== ""
              ? contextValue.dataApplicant.working_experience
              : "-";
          break;
        case "education":
          labelTitle = "Last education";
          content = "education";
          contentId = "m-education";
          education =
            contextValue.dataApplicant.last_education !== ""
              ? contextValue.dataApplicant.last_education
              : "-";
          break;
        default:
          break;
      }
    }

    return (
      <Modal
        size={"md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.show}
        onHide={this.props.close}
      >
        <Modal.Header
          className={classes.modalHeader}
          onClick={this.props.close}
          closeButton
        >
          <span className={classes.modalTitle}>{labelTitle}</span>
        </Modal.Header>
        <UserContext.Consumer>
          {(context) => (
            <div className={classes.modalContent}>
              <div className={classes.content}>
                <div className={classes.boxContent}>
                  <span className={classes.titleContent}>{labelTitle}</span>
                  <input
                    id={contentId}
                    className={classes.inputModal}
                    type={"text"}
                    defaultValue={
                      context.dataApplicant.user
                        ? content === "working"
                          ? context.dataApplicant.working_experience
                          : content === "education"
                          ? context.dataApplicant.last_education
                          : ""
                        : ""
                    }
                  />
                </div>
              </div>
              <div className={classes.modalAction}>
                <div className={classes.cancel} onClick={this.props.close}>
                  <span>Cancel</span>
                </div>
                <div
                  className={classes.save}
                  onClick={() => {
                    this.updateProfile();
                    context.getNewDataApplicant();
                    this.props.close();
                  }}
                >
                  <span>Save</span>
                </div>
              </div>
            </div>
          )}
        </UserContext.Consumer>
      </Modal>
    );
  }
}
