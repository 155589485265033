import React, { Component } from "react"
import Api from "./Api"
import Box from "./Box"

import Map from "./Map/Map"
import classes from "./GlobalNetwork.module.css"
import "./GlobalNetwork.css"
class AU_Section5 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: null,
      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
    }

    this.getSectionDetail = this.getSectionDetail.bind(this)
  }

  componentDidMount() {
    this.getSectionDetail()
  }

  getSectionDetail() {
    const apiData = new Api()
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data
          const widget = []

          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            })
          })
          this.setState({
            contentSection: widget,
            isLoaded: true,
          })
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          })
        })
    })
  }

  setEnded() {
    this.setState({
      isEnded: true,
    })
  }

  render() {
    const { contentSection } = this.state
    let map,
      boxes,
      backgroundImg = null

    if (this.state.isLoaded) {
      contentSection.forEach((m, i) => {
        if (i === 3) {
          backgroundImg = m.sections.widget_contents.map((s) => {
            let image = s ? <div className={classes.imageMask} style={{ backgroundImage: `url(${s.file_small})` }}></div> : <></>
            return <>{image}</>
          })
        }
      })
      map = <Map mapsData={contentSection} />

      boxes = <Box content={this.state.contentSection} />
    }

    return (
      <div className={classes.wrap}>
        <div className={classes.boxGlobal}>
          <div className={classes.boxImage}>{backgroundImg}</div>
          {map}
          {boxes}
        </div>
      </div>
    )
  }
}

export default AU_Section5
