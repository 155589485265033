import React, { Component } from "react";
import { Link } from "react-router-dom";

import Api from "../Api";
import classes from "./Section3.module.css";

import CustomAccordion from "../components/Accordion/AccordionMobile";
import BlogView from "../components/BlogView/BlogViewMobile";
import Button from "../components/Button/Button";

export default class Section3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentSection: [],
            contentArticle: {},
            dataUrl: null,
            dataIndex: null,
            isLoaded: false,
            isApiLoaded: false,
            isArticleLoaded: {},
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
        this.getArticleDetailPagination =
            this.getArticleDetailPagination.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        console.log("its updated mobile");
        const apiData = new Api();
        apiData
            .getSectionDetail()
            .then((result) => {
                const wid = [];
                result.data.widgets.forEach((el) => {
                    console.log(el);
                    wid.push({
                        id: el.id,
                        index: el.index,
                        category: el.category,
                        is_internal: el.is_internal,
                        name: el.name,
                        sections: el,
                    });
                });
                this.setState({
                    contentSection: wid,
                    isLoaded: true,
                });
            })
            .catch((error) => {
                this.setState({
                    isApiLoaded: true,
                });
            });
    }

    getArticleDetailPagination(param, size, page) {
        const apiData = new Api();
        apiData
            .getArticleDetailPagination(param, size, page)
            .then((result) => {
                const articles = result.data;
                const art = [];
                articles.results.forEach((a) => {
                    const assets = [];
                    a.article_assets.forEach((ass, i) => {
                        if (i === 0) {
                            assets.push({
                                asset: ass.file_medium,
                            });
                        }
                    });

                    art.push({
                        id: a.id,
                        category: a.category_name,
                        name: a.name,
                        author_name: a.author_name,
                        author_avatar: a.author_avatar,
                        title: a.article_languages.title,
                        summary: a.article_languages.summary,
                        content: a.article_languages.content,
                        assets: assets,
                        date: a.date,
                    });
                });

                let isArticleLoaded = { ...this.state.isArticleLoaded };
                isArticleLoaded[param] = true;

                let contentArticle = { ...this.state.contentArticle };
                contentArticle[param] = art;

                this.setState({
                    contentArticle: contentArticle,
                    isArticleLoaded: isArticleLoaded,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    isApiLoaded: true,
                });
            });
    }

    render() {
        const { contentSection } = this.state;
        let title,
            article,
            title2,
            url,
            blogComponent,
            readMore,
            readMoreLink,
            sidebarText,
            viewAllBtn;

        if (this.state.isLoaded) {
            let count = 0,
                widget = {},
                readMoreBlog = {};

            contentSection.forEach((item) => {
                count = count + 1;

                if (
                    count === 1 &&
                    item.index === 1 &&
                    item.category === "Text"
                ) {
                    item.sections.widget_contents.forEach((s) => {
                        if (s.content !== "") {
                            title2 = (
                                <h2 className={classes.title2}>{s.content}</h2>
                            );
                        }
                    });
                }

                if (
                    count === 2 &&
                    item.index === 2 &&
                    item.category === "Article"
                ) {
                    widget.id = item.id;
                    widget.url = item.sections.url;
                }

                if (
                    count === 3 &&
                    item.index === 3 &&
                    item.category === "Text"
                ) {
                    // console.log(item.sections);
                    readMoreBlog.url = item.sections.url;
                    readMoreBlog.text =
                        item.sections.widget_contents[0].content;
                }

                if (
                    count === 4 &&
                    item.index === 4 &&
                    item.category === "Text"
                ) {
                    // console.log(item.sections);
                    sidebarText = item.sections.widget_contents;
                }

                if (
                    count === 5 &&
                    item.index === 5 &&
                    item.category === "Text"
                ) {
                    viewAllBtn = (
                        <Button
                            className="d-flex justify-content-end"
                            url={item.sections.url}
                        >
                            {item.sections.widget_contents[0].content}
                        </Button>
                    );
                }
            });

            blogComponent = (
                // <></>
                <BlogView
                    widget={widget}
                    readMore={readMoreBlog}
                    sidebarText={sidebarText}
                />
            );
        }

        return (
            <div className={classes.contentSection}>
                <div className={["container", classes.boxC].join(" ")}>
                    {title2}
                    {blogComponent}
                    {viewAllBtn}
                </div>
            </div>
        );
    }
}
