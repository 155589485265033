import React, { Component } from 'react'

import Api from './Api'

export default class Icon extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,
            sections: {
                widgets: []
            },
            isLoaded: false,
            isApiLoaded: false,
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);

    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ sections: [] }, () => {
            apiData.getSectionDetail()
                .then(result => {
                    this.setState({
                        isApiLoaded: true
                    })
                    const sections = result.data
                    const widget = []
                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            index: item.index,
                            category: item.category,
                            name: item.name,
                            sections: item
                        })
                    })
                    sections.page = widget
                    this.setState({
                        sections: widget,
                        isLoaded: true
                    })
                })
                .catch(error => {
                    this.setState({
                        isApiLoaded: true
                    })
                });
        })
    }

    render() {
        const { sections } = this.state
        let icons = null
        if (this.state.isLoaded) {
            sections.forEach((s, j) => {
                if (s.category === "Media") {
                    icons = s.sections.widget_contents.map((k, i) => {
                        if (i < 7) {
                            let iconStyle = "chart-icon-" + i
                            return (
                                <div className={iconStyle}>
                                    <img src={k ? k.file : require('./assets/logo.svg')} alt=""></img>
                                </div>
                            )
                        }
                    })
                }
            })
        }
        return (
            <div>
                {icons}
            </div>
        )
    }
}
