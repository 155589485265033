import React, { Component } from "react";
import { Link } from "react-router-dom";
import Box from "../components/Box/BoxMobile";
import Api from "../Api";
import classes from "./Mobile.module.css";
// import HorizontalTimeline from "react-horizontal-timeline";
// import Timeline from "./timeline";

export default class Mobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentSection: [],
            isLoaded: false,
            isApiLoaded: false,
            value: 0,
            previous: 0,
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
        this.getTextContent = this.getTextContent.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        apiData
            .getSectionDetail()
            .then((result) => {
                const sections = result.data;
                const widget = [];
                const data = [];

                data.push({
                    title: sections.widgets[0].widget_contents[0].content,
                    desc: sections.widgets[1].widget_contents[0].content,
                    sections: sections.widgets[2],
                });

                result.data.widgets.forEach((item) => {
                    widget.push({
                        id: item.id,
                        index: item.index,
                        category: item.category,
                        internal: item.is_internal,
                        name: item.name,
                        url: sections.widgets[3].url,
                        sections: item,
                    });
                });

                this.setState({
                    contentSection: widget,
                    isLoaded: true,
                });
            })
            .catch((error) => {
                this.setState({
                    isApiLoaded: true,
                });
            });
    }

    getTextContent(item, dummyText) {
        var textContent;

        if (item.sections.widget_contents[0]) {
            item.sections.widget_contents.forEach((s) => {
                if (s.content !== "") {
                    textContent = s.content;
                } else {
                    textContent = dummyText;
                }
            });
        } else {
            textContent = dummyText;
        }

        return textContent;
    }

    render() {
        const { contentSection } = this.state;
        let dummyTitle,
            dummyDesc,
            dummyContent,
            title,
            Desc,
            Content,
            urlAll,
            urlDetail,
            readMore,
            viewAll;

        if (this.state.isLoaded) {
            contentSection.forEach((item, i) => {
                const count = i + 1;
                switch (count) {
                    case 1:
                        title = (
                            <h2 className={classes.title}>
                                {this.getTextContent(item, dummyTitle)}
                            </h2>
                        );
                        break;
                    case 2:
                        Desc = (
                            <p className={classes.content}>
                                {this.getTextContent(item, dummyDesc)}
                            </p>
                        );
                        break;
                    case 3:
                        readMore = this.getTextContent(item, "Read More");
                        urlDetail = item.sections.url ? item.sections.url : "/innovation/blogs/details/";
                        break;
                    case 4:
                        Content = item.internal ? (
                            <></>
                        ) : (
                                <Box
                                    category={item.sections.article_categories}
                                    urlDetail={urlDetail}
                                    id={item.id}
                                    readMore={readMore}
                                />
                            );
                        break;
                    case 5:
                        viewAll = this.getTextContent(item, "View All");
                        urlAll = item.sections.url ? item.sections.url : "/innovation/news/";
                        // urlAll = "/innovation/news/";
                        break;
                    default:
                }
            });
        } else if (this.state.isApiLoaded) {
            title = dummyTitle;
            Desc = dummyDesc;
            Content = dummyContent;
        }

        return (
            <div className={classes.contentSection}>
                <div className={["container", classes.boxC].join(" ")}>
                    {Desc}
                    {title}
                    <div className={classes.boxs}>{Content}</div>
                    <div className={classes.boxLink}>
                        <div className={classes.outerCircle}>
                            <div className={classes.innerCircle}></div>
                        </div>

                        <Link
                            className={classes.linkAll}
                            to={{
                                pathname: urlAll,
                                // pathname: `/innovation/blogs/${urlDetail}`,
                                // search: "?sort=name",
                                // hash: "#the-hash",
                                // state: { articleCategories: this.state.contentSection }
                            }}
                        >
                            {viewAll}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}
