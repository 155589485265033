import React, { Component } from "react";

import ModalName from "./Modal/ModalName";
import ModalLink from "./Modal/ModalLinks";
import ModalEmail from "./Modal/ModalEmail";
import ModalDate from "./Modal/ModalDate";
import ModalCountry from "./Modal/ModalCountry";
import ModalGlobal from "./Modal/ModalGlobal";
import ModalAvatar from "./Modal/ModalAvatar";
import UserContext from "../../../Context";

import classes from "./EditProfile.module.css";

export default class EditProfile extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      modalName: false,
      modalLink: false,
      modalEmail: false,
      modalDate: false,
      modalGlobal: false,
      modalCountry: false,
      modalAvatar: false,
      modalContent: "",
      isLoggedIn: false,
    };
  }

  componentDidMount() {
    const contextValue = this.context;

    console.log("value....", contextValue);

    const isLoggedIn = localStorage.getItem("isLoggedIn");
    this.setState({
      isLoggedIn: isLoggedIn,
    });
  }

  toggleShowHide(param, modal, content) {
    // console.log(`${param} --- ${modal} --- ${content}`)
    switch (param) {
      case "show":
        if (modal === "name") {
          this.setState({ modalName: true });
        }
        if (modal === "link") {
          this.setState({ modalLink: true });
        }
        if (modal === "email") {
          this.setState({ modalEmail: true });
        }
        if (modal === "date") {
          this.setState({ modalDate: true });
        }
        if (modal === "country") {
          this.setState({ modalCountry: true });
        }
        if (modal === "avatar") {
          this.setState({ modalAvatar: true });
        }
        if (modal === "global") {
          console.log(content);
          this.setState({
            modalGlobal: true,
            modalContent: content,
          });
        }
        break;
      case "hide":
        if (modal === "name") {
          this.setState({ modalName: false });
        }
        if (modal === "link") {
          this.setState({ modalLink: false });
        }
        if (modal === "email") {
          this.setState({ modalEmail: false });
        }
        if (modal === "date") {
          this.setState({ modalDate: false });
        }
        if (modal === "country") {
          this.setState({ modalCountry: false });
        }
        if (modal === "avatar") {
          this.setState({ modalAvatar: false });
        }
        if (modal === "global") {
          this.setState({
            modalGlobal: false,
            modalContent: "",
          });
        }
        break;
      default:
        break;
    }
  }

  render() {
    let name,
      country,
      link,
      birthdate,
      address,
      email,
      phone,
      avatar,
      contentModal;

    if (this.state.isLoggedIn && this.props.data.user) {
      const dataA = this.props.data;

      name = `${dataA.user[0].firstname} ${dataA.user[0].lastname}`;
      country = dataA.country ? dataA.country_name : "-";
      avatar = dataA.avatar ? dataA.avatar : require("../../assets/man.svg");
      birthdate = dataA.birth_date !== "" ? dataA.birth_date : "-";
      link = "Add links";

      // bio = dataA.bio !== "" ? dataA.bio : "bio";
      email = `${dataA.user[0].email}`;
      phone = dataA.phone !== "" ? dataA.phone : "phone number";

      if (dataA.address) {
        address = dataA.address;
      } else if (dataA.bio) {
        address = dataA.bio;
      }

      contentModal = (
        <ModalGlobal
          show={this.state.modalGlobal}
          close={() => this.toggleShowHide("hide", "global")}
          content={this.state.modalContent}
        />
      );
    }

    // let contentModal = this.state.modalContent
    return (
      <div className={classes.editProfile}>
        <ModalName
          show={this.state.modalName}
          close={() => this.toggleShowHide("hide", "name")}
          updateData={this.props.updateData}
        />
        <ModalLink
          show={this.state.modalLink}
          close={() => this.toggleShowHide("hide", "link")}
        />
        <ModalEmail
          show={this.state.modalEmail}
          close={() => this.toggleShowHide("hide", "email")}
        />
        <ModalDate
          show={this.state.modalDate}
          close={() => this.toggleShowHide("hide", "date")}
        />
        <ModalCountry
          show={this.state.modalCountry}
          close={() => this.toggleShowHide("hide", "country")}
        />
        <ModalAvatar
          show={this.state.modalAvatar}
          close={() => this.toggleShowHide("hide", "avatar")}
        />

        {contentModal}

        <div className={classes.profile}>
          <div className={classes.boxImage}>
            <div className={classes.boxRounded}>
              <img className={classes.imgProfile} src={avatar} alt="" />
            </div>
            <div
              className={classes.iconEditAva}
              onClick={() => this.toggleShowHide("show", "avatar", "")}
            >
              <img src={require("../../assets/edit.svg")} alt="" />
            </div>
          </div>
          <div className={classes.contentProfile}>
            <div className={classes.boxProfile}>
              <p className={classes.title}>{name}</p>
              <div
                className={classes.iconEdit}
                onClick={() => this.toggleShowHide("show", "name", "")}
              >
                <img src={require("../../assets/edit.svg")} alt="" />
              </div>
            </div>
            <div className={classes.boxProfile}>
              <p className={classes.content}>{country}</p>
              <div
                className={classes.iconEdit}
                onClick={() => this.toggleShowHide("show", "country", "")}
              >
                <img src={require("../../assets/edit.svg")} alt="" />
              </div>
            </div>
            <div className={classes.boxProfile}>
              <p className={classes.content}>{birthdate}</p>
              <div
                className={classes.iconEdit}
                onClick={() => this.toggleShowHide("show", "date", "")}
              >
                <img src={require("../../assets/edit.svg")} alt="" />
              </div>
            </div>
            {/* <div className={classes.boxProfile}>
                            <p className={classes.content}>{link}</p>
                            <div className={classes.iconEdit} onClick={() => this.toggleShowHide('show', 'link', '')}>
                                <img src={require('../../assets/edit.svg')} alt="" />
                            </div>
                        </div> */}
            <div className={classes.boxProfile}>
              <p className={classes.content}>{address}</p>
              <div
                className={classes.iconEdit}
                onClick={() => this.toggleShowHide("show", "global", "bio")}
              >
                <img src={require("../../assets/edit.svg")} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.contact}>
          <p className={classes.titleContent}>{this.props.contact}</p>
          <div className={classes.boxContact}>
            <div
              className={classes.boxProfile}
              onClick={() => this.toggleShowHide("show", "email", "")}
            >
              <p className={classes.content}>{email}</p>
              <div className={classes.iconEdit}>
                <img src={require("../../assets/edit.svg")} alt="" />
              </div>
            </div>
            <div className={classes.boxProfile}>
              <p className={classes.content}>{phone}</p>
              <div
                className={classes.iconEdit}
                onClick={() => this.toggleShowHide("show", "global", "phone")}
              >
                <img src={require("../../assets/edit.svg")} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
