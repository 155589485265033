import React, { Component } from "react";
import "swiper/swiper.scss";
import classes from "./HistoryTablet.module.css";
import Api from "../Api";
import dateFormat from "dateformat";
import ReactHtmlParser from "react-html-parser";
import "../CustomSlider.css";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

class AU_HistoryTabletView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentDetail: false,
      currentPage: null,
      contentArticle: {
        widgets: [],
      },
      isLoaded: false,
      notLoaded: false,
      indexYear: 0,
      disabledNext: true,
      disabledPrev: true,
      currentIndex: 0,
      nav1: null,
      nav2: null,
    };
    this.getArticleGroupbyYear = this.getArticleGroupbyYear.bind(this);
    // this.toggleNext = this.toggleNext.bind(this);
    this.togglePrev = this.togglePrev.bind(this);
    this.toggleNextButton = this.toggleNextButton.bind(this);
    this.togglePrevButton = this.togglePrevButton.bind(this);
  }

  toggleHandlerBack = () => {
    this.setState({
      contentDetail: false,
    });
  };

  toggleExit = () => {
    this.setState({
      indexYear: false,
      currentIndex: 0,
    });
    this.toggleNextButton(true);
  };

  changeHighlight(key) {
    this.setState({ activeYear: key });
  }

  changeBox = () => {
    this.setState({
      content: <div></div>,
    });
  };

  componentDidMount() {
    let id = this.props.id;

    if (id !== "dummy") {
      this.getArticleGroupbyYear(id);
    } else {
      this.setState({
        notLoaded: true,
      });
    }

    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  toggleNextButton(onOrOff) {
    this.setState({
      disabledNext: onOrOff,
    });
  }

  togglePrevButton(onOrOff) {
    this.setState({
      disabledPrev: onOrOff,
    });
  }

  togglePrev() {
    var k = this.state.currentIndex;

    if (k > 0) {
      this.setState({
        currentIndex: k - 1,
      });
      if (k - 1 === 0) {
        this.togglePrevButton(true);
      }
      if (
        k < this.state.contentArticle[this.state.indexYear - 1].art.length &&
        this.state.contentArticle[this.state.indexYear - 1].art.length > 1
      ) {
        this.toggleNextButton(false);
      }
    }
  }

  changeSlideBar(i) {
    this.setState({ currentIndex: i, indexYear: i + 1, contentDetail: true });
    this.togglePrevButton(true);
    if (this.state.contentArticle[i].art.length === 1) {
      this.toggleNextButton(true);
    } else {
      this.toggleNextButton(false);
    }
  }

  getArticleGroupbyYear(param) {
    const apiData = new Api();
    this.setState(
      {
        sections: [],
      },
      () => {
        apiData
          .getArticleGroupbyYear(param)
          .then((result) => {
            this.setState({
              isApiLoaded: true,
            });
            const widget = [];
            const years = [];
            result.data.forEach((element) => {
              years.push(element.year);

              widget.push({
                year: element.year,
                art: element.articles,
              });
            });

            this.setState({
              contentArticle: widget,
              years: years,
              isLoaded: true,
            });
          })
          .catch((error) => {
            this.setState({
              isApiLoaded: true,
            });
          });
      }
    );
  }
  render() {
    const { contentArticle, isLoaded } = this.state;
    let date,
      contents,
      title,
      year,
      titleContent,
      isImage,
      contentU,
      contentB,
      activeYear,
      dataUp,
      activeBox,
      active,
      activeImg,
      years;

    if (isLoaded) {
      contentArticle.forEach((ele, i) => {
        let idx;
        if (this.state.indexYear === 0) {
          idx = 0;
        } else {
          idx = 1;
        }

        if (i === this.state.indexYear - idx) {
          contentU = ele.art.map((n, index) => {
            activeImg = this.state.indexYear
              ? this.state.indexYear - 1 === i
                ? classes.activeImg
                : classes.inActiveImg
              : "";
            date = n.date;
            contents = n.article_languages.content;
            title = n.article_languages.title;
            year = dateFormat(date, "yyyy");
            titleContent = title;
            isImage = (
              <img
                className={classes.image_box}
                src={n.article_assets[0].file_medium}
                alt={n.article_assets[0].description}
              />
            );

            let idxs;
            if (this.state.indexYear === 0) {
              idxs = 0;
            } else {
              idxs = 1;
            }

            return (
              <div
                className={[classes.box, activeImg].join(" ")}
                onClick={() => this.changeSlideBar(i)}
              >
                {this.state.indexYear - idxs === i ? (
                  <>
                    <div>{isImage}</div>
                    <div className={classes.bxabs1}>
                      <p className={classes.text_fake}>{year}</p>
                      <h1 className={classes.title_mid}>{titleContent}</h1>
                      <div className={classes.bxparent}>
                        <p className={classes.p_text}>
                          {ReactHtmlParser(contents)}
                        </p>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            );
          });
        }
      });

      contentB = contentArticle.map((ele, i) => {
        ele.art.forEach((n, index) => {
          isImage = (
            <img
              className={classes.isBottom}
              src={n.article_assets[0].file_medium}
              alt={n.article_assets[0].description}
            />
          );

          date = n.date;
          contents = n.article_languages.content;
          title = n.article_languages.title;
          years = dateFormat(date, "yyyy");
          titleContent = <p>{title}</p>;

          if (i === this.state.currentIndex) {
            activeYear = classes.activeTextReal;
            activeBox = classes.activeBox;
            active = classes.active;
          } else {
            activeYear = "";
            activeBox = "";
            active = "";
          }
        });

        return (
          <div
            className={[classes.box2, activeYear].join(" ")}
            onClick={() => this.changeSlideBar(i)}
          >
            {isImage}
            <div className={[classes.isInside, activeBox].join("")}>
              <div className={[classes.isYearText, active].join(" ")}>
                <p>{years}</p>
              </div>
            </div>
          </div>
        );
      });

      dataUp = (
        <div className={classes.h1}>
          <Slider
            slidesToShow={1}
            swipeToSlide={true}
            infinite={false}
            dots={true}
          >
            {contentU}
          </Slider>
        </div>
      );
    }

    return (
      <div>
        <div className={["wrapp", classes.wrapp].join(" ")}>
          {dataUp}
          <div className={classes.image_small}>
            <div className={classes.ctx003} id="sliderHistory">
              <Slider
                slidesToShow={4}
                swipeToSlide={true}
                infinite={false}
                dots={true}
              >
                {contentB}
              </Slider>
            </div>
          </div>
          <div className={classes.backBoxBtn}>
            <div className={["d-flex row", classes.mL03].join(" ")}>
              <FontAwesomeIcon
                className={classes.backIcon}
                icon={faArrowLeft}
              />
              <Link to={this.props.backUrl}>
                <p>{this.props.backBtn}</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AU_HistoryTabletView;
