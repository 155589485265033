import React, { Component } from "react";
import { average, prominent } from "color.js";
import css from "./HistoryDetail.module.css";
import Api from "./Api";
import Slider from "react-slick";
import ReactHtmlParser from "react-html-parser";
import "./HistoryDetail.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { xorBy } from "lodash";

export default class HistoryDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentArticle: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: true,
      indexYear: false,
      currentIndex: 0,
      indexurl: null,
      backurl: "/about-us",
      activeSlide: "",
      lightOrDark: "",
    };

    this.getArticleGroupbyYear = this.getArticleGroupbyYear.bind(this);
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    let idFix = this.props.param.param2.split("_");

    let param = idFix[0];
    let index = idFix[1];

    if (param) {
      this.getArticleGroupbyYear(param);
      this.getSectionDetail();
      this.setState({
        indexurl: index,
      });
    }
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;

          let backtext = sections.widgets[0].widget_contents[0].content;
          let backurl = sections.widgets[0].url;

          this.setState({
            backtext: backtext,
            backurl: backurl,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  getArticleGroupbyYear(param) {
    const apiData = new Api();
    this.setState(
      {
        contentArticle: [],
      },
      () => {
        apiData
          .getArticleGroupbyYear(param)
          .then((result) => {
            const articles = result.data;

            const widget = [];
            const years = [];
            const art = articles.reverse()[this.state.indexurl];
            let lightOrDark;
            art.articles.forEach((a, i) => {
              if (i === 0) {
                let ass = a.article_assets[0].asset_file;
                if (a.article_assets[0].set_logo_opacity) {
                  this.props.updateLogo(a.article_assets[0].logo_opacity);
                } else {
                  prominent(ass, {
                    format: "hex",
                    amount: 10,
                    group: 20,
                    sample: 10,
                  }).then((color) => this.lightOrDark(color[3]));
                }
              }
            });
            this.setState({
              contentArticle: articles[this.state.indexurl],
              lightOrDark: lightOrDark,
              years: years,
              isLoaded: true,
            });
          })
          .catch((error) => {
            this.setState({ isApiLoaded: true });
          });
      }
    );
  }

  lightOrDark(color) {
    let r, g, b, hsp;
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(
        /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
      );

      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +(
        "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
      );

      r = color >> 16;
      g = (color >> 8) & 255;
      b = color & 255;
    }

    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using the HSP value, determine whether the color is light or dark
    const darkSection = this.props.darkSection;
    if (hsp > 127.5) {
      if (darkSection) {
        this.props.updateLogo(false);
      }
      return "light";
    } else {
      if (!darkSection) {
        this.props.updateLogo(true);
      }
      return "dark";
    }
  }

  updateLogo(idx){
    this.state.contentArticle.articles.forEach((x, i)=>{
      if (this.state.activeSlide >= 0 && idx === i) {
        let image = x.article_assets[0] ? x.article_assets[0].asset_file : "";
        if (image !== "") {
          if (x.article_assets[0].set_logo_opacity) {
            this.props.updateLogo(x.article_assets[0].logo_opacity === "Dark" ? false : true);
          } else {
            prominent(image, {
              format: "hex",
              amount: 10,
              group: 20,
              sample: 10,
            }).then((color) => this.lightOrDark(color[3]));
          }
        }
      }
    })
  }

  render() {
    let data = this.state.contentArticle;
    let historyDataAll;
    if (this.state.isLoaded) {
      historyDataAll = data.articles.map((ele, i) => {
        let image = ele.article_assets[0]
          ? ele.article_assets[0].file_small
          : "";
        let content = ele.article_languages.content;
        let title = ele.article_languages.title;
        let date = ele.date;

        return (
          <div className={css.pagesLitle}>
            <div className={css.cardImage}>
              {/* <img crossOrigin="anonymous" src={image} alt="" /> */}
              <img src={image} alt="" />
            </div>
            <div className={css.cardText}>
              <h1 className={css.cardTitle}>{title}</h1>

              <span>{ReactHtmlParser(content)}</span>
              <Link to={this.state.backurl}>
                <div className={css.iconDiv}>
                  <div className={"d-flex"}>
                    <i
                      className={[css.arrow, css.left, css.customMargin].join(
                        " "
                      )}
                    ></i>
                  </div>
                  {this.state.backtext}
                </div>
              </Link>
            </div>
          </div>
        );
      });
    }
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 1,
      swipeToSlide: true,
      afterChange: (current) => this.setState({ activeSlide: current },()=> this.updateLogo(current)),
      appendDots: (dots) => (
        <div
          style={{
            backgroundColor: "transparent",
            borderRadius: "10px",
          }}
        >
          <ul style={{ margin: "0px" }}> {dots} </ul>
        </div>
      ),
      customPaging: (i) => (
        <div
          style={{
            width: "10px",
            height: "10px",
            color: "white",
            border: "1px solid #d6d6d6",
            borderRadius: "50%",
            // marginRight: "2px",
          }}
        ></div>
      ),
    };
    return (
      <div className={css.pages} id="slidermobile">
        <Slider {...settings}>{historyDataAll}</Slider>
      </div>
    );
  }
}
