import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import Api from '../Api'
import AllIcon from '../Icon/Icon'
import Icon from '../assets/icon.svg'

import classes from './Desktop.module.css'

export default class Desktop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sections: {
                widgets: []
            },
            section: {
                widgets: []
            },
            menus: {
                items: []
            },
            backButton: "",
            logoIcon: Icon,
            bgChart: false,
            isMenuLoaded: false,
            isSectionLoaded: false
        };
        this.getMenu = this.getMenu.bind(this);
        this.getSectionDetail = this.getSectionDetail.bind(this)
    }

    componentDidMount() {
        this.getMenu();
        this.getSectionDetail()
    }

    getMenu() {
        const apiData = new Api();
        this.setState({
            menus: []
        }, () => {
            apiData
                .getMenuDetail()
                .then(result => {

                    const menu = result.data
                    const item = []

                    menu
                        .items
                        .forEach((i) => {
                            item.push({ index: i.index, id: i.id, name: i.title, url: i.url, children: i.children })
                        })
                    menu.items = item
                    this.setState({ menus: menu, isMenuLoaded: true })
                })
                .catch(error => {

                });
        })
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({
            section: []
        }, () => {
            apiData
                .getSectionDetail()
                .then(result => {
                    const sections = result.data
                    const widget = []

                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            index: item.index,
                            category: item.category,
                            name: item.name,
                            sections: item
                        })
                    })
                    sections.page = widget

                    this.setState({
                        sections: sections,
                        section: widget,
                        isSectionLoaded: true,
                    }, () => {
                        this.setState({
                            bgChart: this.state.section[2]?.sections.widget_contents[0] ? this.state.section[2].sections.widget_contents[0].file : false,
                            logoIcon: this.state.section[0].sections.widget_contents[0]?.file ? this.state.section[0].sections.widget_contents[0]?.file : Icon,
                            backButton: this.state.sections.widgets[1]?.widget_contents[0]?.content ? this.state.sections.widgets[1]?.widget_contents[0]?.content : "Quay lại trang trước"
                        })
                    })

                })
                .catch(error => {
                    // this.setState({ isSectionLoaded: false })
                });
        })
    }

    checkUrlTarget(url) {
        if (url === "") {
            return "default"
        } else {
            var substring = url?.substr(0, 4);
            if (substring === "http") {
                return "external";
            } else if (substring !== "http") {
                return "internal";
            } else {
                return "default";
            }
        }
    }

    getUrlIfThereIsURL(item) {
        switch (this.checkUrlTarget(item.url)) {
            case "internal":
                return (
                    <Link className={classes.customLink} to={item}><span>{item.title}</span></Link>
                )
            case "external":
                return (
                    <a
                        className={classes.customLink}
                        href={item}
                        target="_blank"
                        rel="noopener noreferrer"><span>{item.title}</span></a>
                )
            case "default":
                return <span>{item.title}</span>
            default:
                return <span>{item.title}</span>
        }
    }

    // Manual style
    parseItemToList = (item, index) => {
        console.log(item)

        let itmStyle0 = {
            marginRight: parseInt(index) === 0 ? '2rem' : ''
        }

        let title =
            <div className={[classes.boxL].join(' ')} style={itmStyle0}>
                <span>{item.title}</span>
            </div>
        let itmchilds, childs
        if (item?.children?.length > 0) {
            itmchilds = item.children.map(itm1 => {
                return this.parseItemToChart(itm1, index + 1)
            });

            let itmStyle = {
                marginRight: parseInt(index) === 0 ? '4rem' : `calc(4rem * 1)`
            }

            childs = <div style={itmStyle}>
                {itmchilds}
            </div>
        }

        return (
            <>
                {title}
                {childs}
            </>
        )
    }

    // Automatic style
    parseItemToChart = (item, param, idx, x) => {
        let itmchilds, childs

        if (item?.children?.length > 0) {
            itmchilds = item.children.map((itmX, i) => {
                return this.parseItemToChart(itmX, false, false, (itmX.children?.length ? true : false))
            });

            childs = itmchilds
        }

        // style between ul after first li item
        let styles = {
            marginBottom: !param ? 'unset' : '2rem'
        }

        // styleTtl => style for only first title
        // styleCh => style for li::after if having child or not
        let styleTtl, styleCh, icon
        if (param) {
            styleTtl = classes.liTtlItm
            styleCh = item?.children?.length > 0 ? classes.liHvItms : classes.liNotHvItms
            icon = <AllIcon data={this.state.section} idx={idx + 1} />
        } else {
            if (x){
                styleTtl = [classes.liCtnItm, classes.liCtnItm2nd].join(' ')
            } else {
                styleTtl = classes.liCtnItm
            }
            styleCh = classes.liDefItms
        }

        let items =
            <li className={styleCh}>
                <div className={styleTtl}>
                    {icon}
                    {this.getUrlIfThereIsURL(item)}
                    {/* {item.title} */}
                </div>
                <ul style={styles}>
                    {childs}
                </ul>
            </li>

        return items
    }

    render() {
        const { isMenuLoaded, isSectionLoaded, menus } = this.state

        let chartL, chartR, linkContent
        if (isMenuLoaded && isSectionLoaded) {
            chartL = menus?.items[0]?.children?.map((itm, i) => {
                if (i < 3) {
                    return this.parseItemToChart(itm, true, i)
                }
            })

            chartR = menus?.items[0]?.children?.map((itm, i) => {
                if (i > 2) {
                    return this.parseItemToChart(itm, true, i)
                }
            })

            let prevLink = localStorage.getItem('prevLink')
            linkContent =
                <a className={classes.customLink} href={prevLink && prevLink !== "" ? prevLink : '/about-us'}>
                    <div className={'d-flex'}>
                        <div className={'d-flex'}>
                            <i className={[classes.arrow, classes.left, classes.customMargin].join(" ")}></i>
                        </div>

                        <span className={classes.textBack}>{this.state.backButton}</span>
                    </div>
                </a>
        }


        return (
            <>
                {
                    this.state.isSectionLoaded && this.state.bgChart ?
                        <picture className={classes.bgChart}>
                            <source media="(min-width:768px)" srcset={this.state.bgChart} />
                            <img src={this.state.bgChart} alt='bg' loading='lazy' />
                        </picture> : <></>
                }
                <div className={classes.contentCharts}>

                    <img className={classes.lgChart} src={this.state.logoIcon} alt='logo' />
                    <div className={classes.charts}>
                        <div className={classes.chartsL}>
                            <div className={classes.treeL}>
                                {chartL}
                            </div>
                        </div>
                        <div className={classes.chartsR}>
                            <div className={classes.treeR}>
                                {chartR}
                                {/* <ul>
                                <li>
                                    Fruit
                                    <ul>
                                        <li>
                                            Red
                                            <ul>
                                                <li>Cherry</li>
                                                <li>Strawberry</li>
                                            </ul>
                                        </li>
                                        <li>
                                            Yellow
                                            <ul>
                                                <li>Banana</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    Meat
                                    <ul>
                                        <li>Beef</li>
                                        <li>Pork</li>
                                    </ul>
                                </li>
                            </ul> */}
                            </div>
                        </div>
                    </div>
                    <div className={["container", classes.customContainerC].join(' ')}>
                        <div className={classes.navBack}>
                            {linkContent}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
