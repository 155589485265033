import React, { useContext } from "react";

import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import { AccordionContext } from "react-bootstrap";

import {
  faPlus,
  faMinus,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./Accordion.module.css";

export default function Accordion({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext);

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;
  // console.log(`${children} --- ${eventKey} --- ${callback} --- ${props}`)
  let icon;
  if (eventKey < 6) {
    icon = isCurrentEventKey ? (
      <FontAwesomeIcon
        color="rgb(34, 55, 113)"
        className="ml-auto"
        icon={faMinus}
        size="lg"
      />
    ) : (
      <FontAwesomeIcon
        color="#f16b26"
        className="ml-auto"
        icon={faPlus}
        size="lg"
      />
    );
  } else {
    icon = (
      <FontAwesomeIcon
        color="#f16b26"
        className="ml-auto"
        icon={faArrowRight}
        size="lg"
      />
    );
  }
  return (
    <div onClick={decoratedOnClick} className="d-flex relative">
      <p className={classes.titleAccordion}>{children}</p>
      {icon}
    </div>
  );
}
