import React, { Component } from "react";
import { Link } from "react-router-dom";

import Api from "../Api";
import classes from "./IN_View.module.css";
import NavBack from "../components/NavBack/NavBack"

export default class Section3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentSection: [],
      isSectionLoaded: false,
      isLoaded: false,
      backUrl: '/',
      backText: '',
      authorText: '',
      params: {
        title: this.props.param.param1 ? this.props.param.param1 : "",
        id: this.props.param.param2 ? this.props.param.param2 : ""
      }
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
    this.getArticleDetailByAuthor = this.getArticleDetailByAuthor.bind(this)
  }

  componentDidMount() {
    console.log(this.state)
    this.getSectionDetail(() => {
      this.getArticleDetailByAuthor(this.state.idBlogWidget, this.unslugStr(this.state.params.id))
    })
  }

  getSectionDetail(callback) {
    const apiData = new Api();
    apiData
      .getSectionDetail()
      .then((result) => {
        const sections = result.data;

        this.setState({
          contentSection: sections.widgets,
          idBlogWidget: sections.widgets[0].id,
          isSectionLoaded: true,
          url: sections.widgets[0].url,
          backUrl: sections.widgets[1].url,
          backText: sections.widgets[1].widget_contents[0].content,
          authorText: sections.widgets[2].widget_contents[0].content,
        }, callback)
      })
      .catch((error) => {
        this.setState({
          isApiLoaded: true,
        });
      });
  }

  getArticleDetailByAuthor(param, authorName, size) {
    const apiData = new Api();
    apiData.getArticleDetailByAuthor(param, authorName, size)
      .then(result => {
        const articles = result.data.results

        if (!articles.length) {
          throw Error("Author not found or hasn't write any articles")
        }

        this.setState({
          articles: articles,
          isLoaded: true
        })
      })
      .catch(error => {
        console.log(error);
        // window.location.assign(`/error/${error.response ? error.response.status : 404}`)
      });
  }

  unslugStr(str) {
    return str.split('-').join(' ')
  }

  render() {
    let avatarUrl, avatar, authorName, articles, authorText
    if (this.state.isLoaded) {
      avatarUrl = this.state.articles[0].author_avatar
      avatar = <img
        className={classes.authorImg}
        src={avatarUrl ? avatarUrl : require('../assets/dummy-profile.jpg')}
        alt={this.state.articles[0].author_name}
      />
      authorName = <><h1 className={classes.authorName}>{this.state.articles[0].author_name}</h1><hr></hr></>
      authorText = <div className={classes.authorText}>{this.state.authorText}</div>
      articles = this.state.articles.map(article => {
        const slug_title = article.article_languages.slug_title ? article.article_languages.slug_title : "null"
        return (
          <Link
            to={this.state.url + "/" + slug_title + "/" + article.id}
            className={classes.link}
          >
            <div className={classes.card}>
              <p className={classes.title}>{article.article_languages.title}</p>
              <p className={classes.summary}>{article.article_languages.summary}</p>
              <p className={classes.date}>{article.date}</p>
            </div>
            <hr></hr>
          </Link>
        )
      })
    }

    return (
      <div className={classes.contentSection}>
        <div className={[classes.customContainer, classes.topContainer].join(' ')}>
          <div className={classes.col1}>
            <div className={classes.imgCont}>
              {avatar}
            </div>
          </div>
          <div className={classes.col2}>
            {authorName}
            {articles}
          </div>
        </div>
        <div className={classes.customContainer}>
          <NavBack url={this.state.backUrl}>{this.state.backText}</NavBack>
        </div>
      </div>
    )
  }
}
