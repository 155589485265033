import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import clas from "./Desktop.module.css";
import Api from "../Api";
import "./Desktop.css";
import classes from "./Desktop.module.css";

class ImageSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isApiLoaded: false,
            sections: {
                widgets: [],
            },
            items: [
                {
                    id: 1,
                    title: "",
                    img: (
                        <img
                            style={{ width: "100vw", height: "100vh", objectFit: "cover" }}
                            src={require("../assets/slide1.png")}
                            alt="1"
                        ></img>
                    ),
                },
                {
                    id: 2,
                    title: "",
                    img: (
                        <img
                            style={{ width: "100vw", height: "100vh", objectFit: "cover" }}
                            src={require("../assets/slide2.png")}
                            alt="2"
                        ></img>
                    ),
                },
                {
                    id: 3,
                    title: "",
                    img: (
                        <img
                            style={{ width: "100vw", height: "100vh", objectFit: "cover" }}
                            src={require("../assets/slide3.png")}
                            alt="3"
                        ></img>
                    ),
                },
            ],
            activeSlide: 0,
            vidBackground: false
        };
        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);
        this.getSectionDetail = this.getSectionDetail.bind(this);
        this.getcontentFromTextItem = this.getcontentFromTextItem.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
        // window.addEventListener("resize", this.resize.bind(this));
        // this.resize();
    }

    resize() {
        this.setState({ hideNav: window.innerWidth <= 760 });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState(
            {
                sections: [],
            },
            () => {
                apiData
                    .getSectionDetail()
                    .then((result) => {
                        const sections = result.data;
                        const widget = [];

                        const vidBackground = []
                        let isVid = false
                        result.data.widgets.forEach((item, i) => {
                            if (!isVid) {
                                item.widget_contents.forEach((itm, index) => {
                                    if (itm.asset_type === 'Video' || itm.asset_type === 'Others') {
                                        vidBackground.push({
                                            index: index,
                                            desc: itm.description,
                                            file: itm.file,
                                        })
                                        isVid = true
                                    }
                                })
                            }
                            widget.push({
                                id: item.id,
                                index: item.index,
                                category: item.category,
                                name: item.name,
                                sections: item,
                            });
                        });
                        sections.page = widget;
                        this.setState({
                            sections: widget,
                            vidBackground: vidBackground,
                            isLoaded: true,
                        });
                    })
                    .catch((error) => {
                        this.setState({
                            isApiLoaded: true,
                        });
                        // window.location.assign(
                        //   `/error/${error.response.status ? error.response.status : 404}`
                        // );
                    });
            }
        );
    }

    play() {
        this.slider.slickPlay();
    }
    pause() {
        this.slider.slickPause();
    }

    getcontentFromTextItem(textItem) {
        var dummyText = "";

        if (!textItem.widget_contents[0]) {
            return dummyText;
        } else {
            if (textItem.widget_contents[0].content) {
                return textItem.widget_contents[0].content;
            } else {
                return dummyText;
            }
        }
    }

    getcolorFromTextItem = (textItem) => {
        var colorText = "#000000";

        if (!textItem.widget_contents[0]) {
            return colorText;
        } else {
            if (textItem.widget_contents[0].content) {
                return textItem.widget_contents[0].font_color;
            } else {
                return colorText;
            }
        }
    }

    render() {
        const sections = this.state.sections;

        let titleLine1,
            titleLine2,
            description,
            colorLine1,
            colorLine2,
            colorDescription = "";

        let imgSlide = null;
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            swipeToSlide: true,
            afterChange: current => this.setState({ activeSlide: current }),
            appendDots: (dots) => (
                <div
                    style={{
                        backgroundColor: "transparent",
                        borderRadius: "10px",
                        paddingBottom: "2rem",
                    }}
                >
                    <ul style={{ margin: "0px" }}> {dots} </ul>
                </div>
            ),
            customPaging: (i) => (
                <div
                    style={{
                        width: "10px",
                        height: "10px",
                        color: "white",
                        border: "1px solid white",
                        borderRadius: "50%",
                        marginRight: "2px",
                    }}
                ></div>
            ),
        };

        if (this.state.isLoaded) {
            sections.forEach((item) => {
                switch (item.index) {
                    case 1:
                        titleLine1 = this.getcontentFromTextItem(item.sections);
                        colorLine1 = this.getcolorFromTextItem(item.sections);
                        break;

                    case 2:
                        titleLine2 = this.getcontentFromTextItem(item.sections);
                        colorLine2 = this.getcolorFromTextItem(item.sections);
                        break;

                    case 3:
                        description = this.getcontentFromTextItem(item.sections);
                        colorDescription = this.getcolorFromTextItem(item.sections);
                        break;

                    case 4:
                        if (item.sections.widget_contents) {
                            const arrImg = [];
                            item.sections.widget_contents.forEach((ele) => {
                                arrImg.push({
                                    file_small: ele.file_small,
                                    description: ele.description
                                });
                            });

                            // imgSlide = arrImg.map((z) => {
                            //     return <img className={classes.picBig} src={z.file_small} alt={z.description} />;
                            // });
                            // this.state.items = [];

                            if (this.state.vidBackground && this.state.vidBackground.length > 0) {
                                imgSlide = item.sections.widget_contents.map((sliderItem, index) => {
                                    let element = null;
                                    if (index === this.state.vidBackground[0].index) {
                                        let filesplit = sliderItem.file.split(".");
                                        let filetype = filesplit[filesplit.length - 1];

                                        if (filetype === "mp4") {
                                            element = (
                                                <video
                                                    style={{
                                                        width: "100vw",
                                                        height: "100vh",
                                                        objectFit: "cover",
                                                    }}
                                                    autoPlay
                                                    loop
                                                    muted
                                                >
                                                    <source src={sliderItem.file} type="video/mp4" />
                                                </video>
                                            );
                                        }
                                    }
                                    return element
                                });
                            } else {
                                imgSlide = item.sections.widget_contents.map((sliderItem, index) => {
                                    var element = null;
                                    var filesplit = sliderItem.file.split(".");
                                    var filetype = filesplit[filesplit.length - 1];
                                    // console.log(filetype)
                                    switch (filetype) {
                                        case "mp4":
                                            element = (
                                                <video
                                                    style={{
                                                        width: "100vw",
                                                        height: "100vh",
                                                        objectFit: "cover",
                                                    }}
                                                    autoPlay
                                                    loop
                                                    muted
                                                >
                                                    <source src={sliderItem.file} type="video/mp4" />
                                                </video>
                                            );
                                            break;
                                        default:
                                            element = (
                                                <img
                                                    // style={{
                                                    //     width: "100vw",
                                                    //     height: "100vh",
                                                    //     objectFit: "cover",
                                                    // }}
                                                    className={classes.picBig}
                                                    src={sliderItem.file}
                                                    alt={sliderItem.description}
                                                ></img>
                                            );
                                    }

                                    // this.state.items.push({
                                    //     id: index,
                                    //     title: sliderItem.description,
                                    //     img: element,
                                    // });

                                    return element
                                });
                            }



                        }
                        break;
                    default:
                }
            });
        } else if (this.state.isApiLoaded) {
            var element = null;
            element = (
                <img
                    style={{
                        width: "100vw",
                        height: "100vh",
                        objectFit: "cover",
                    }}
                    src={require("../assets/default-image.svg")}
                    alt=" "
                />
            );

            imgSlide = <div>{element}</div>;
        }

        let motto
        if (this.state.isLoaded) {
            sections.forEach((item, index) => {
                switch (item.index) {
                    case 4:
                        item.sections.widget_contents.forEach((sliderItem, index) => {
                            if (index === this.state.activeSlide) {
                                if (!sliderItem.has_slogan) {
                                    motto = <div className={clas.contentslider}>
                                        <h1 className={clas.titleslider} style={{color: colorLine1}}>{titleLine1}</h1>
                                        <h1 className={clas.titleslider} style={{color: colorLine2}}>{titleLine2}</h1>
                                        <div className={clas.boxBottomText}>
                                            <h2 className={clas.titlesliderdesc} style={{color: colorDescription}}>{description}</h2>
                                        </div>
                                    </div>
                                } else {
                                    motto = <></>
                                }
                            }
                        })
                        break;
                    default:
                        break;
                }
            })
        }

        return (
            <div className={clas.sectionslider}>
                <div className={clas.sliderbox}>
                    {motto}
                </div>

                <span className={clas.scrollBtn}>
                    <span className={clas.mouse}>
                        <span>
                        </span>
                    </span>
                    {/* <p>scroll down</p> */}
                </span>

                <Slider {...settings}>{imgSlide}</Slider>
                {/* <Slider {...settings}>{this.state.items.img}</Slider> */}
            </div>
        );
    }
}

export default ImageSlider;
