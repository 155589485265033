import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { store } from "react-notifications-component";
import Api from "../Api";
import classes from "./ModalPassword.module.css";

class ModalPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: false,
      isEmail: false,
      texts: "",
      data: [],
    };
    this.resetPasswordEmail = this.resetPasswordEmail.bind(this);
    this.checkEmail = this.checkEmail.bind(this);
  }

  componentWillReceiveProps(newProps) {
    // console.log("newProps is : ", newProps);
    let textObj = {};
    let errorObj = {};
    let textName = [
      "signIn",
      "newUser",
      "createAcc",
      "email",
      "pwd",
      "forgotPwd",
      "continue",
      "cancel",
      "resetP",
    ];
    let errName = [
      "is_required",
      "similar_pwd",
      "already_registered",
      "pwd",
      "invalid_address",
      "wrong_pwd",
      "not_match",
    ];
    if (newProps.texts) {
      newProps.texts.forEach((txt, i) => {
        textObj[textName[i]] = txt.content;
      });

      this.setState({ texts: textObj });
    }
    if (newProps.dataErrorText) {
      newProps.dataErrorText.forEach((txt, i) => {
        errorObj[errName[i]] = txt.content;
      });

      this.setState({ data: errorObj });
    }
  }

  checkEmail(param) {
    let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (!param.match(mailformat)) {
      return false;
    } else {
      return true;
    }
  }

  isValidate(email) {
    const { data } = this.state;
    if (!email) {
      //   console.log("masuk", data.is_required);
      this.setState({
        reqEmail: data.is_required,
      });
      //   return false;
    } else if (email) {
      if (this.checkEmail(email)) {
        this.setState({
          reqEmail: null,
        });
        return true;
      } else {
        this.setState({
          reqEmail: data.invalid_address,
        });
        return false;
      }
    }
  }

  resetPasswordEmail() {
    const { data } = this.state;
    const reset = new Api();

    const email = document.getElementById("m-email").value;

    const dataEmail = {
      email: email,
      template: this.props.template,
      language: localStorage.getItem('language') === "English" ? "en" : "vi"
    }

    if (this.isValidate(email)) {
      reset
        .resetPasswordEmail(dataEmail)
        .then((res) => {
          this.setState({
            isEmail: false,
          });
          this.props.close();
          store.addNotification({
            title: "Success!",
            message: "An email has been sent to your inbox",
            type: "phenikaa",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 3000,
              onScreen: true,
            },
          });
        })
        .catch((err) => {
          console.log(err);
          let errEmail = err.response.data.email
            ? err.response.data.email[0]
            : "";
          if (errEmail) {
            this.setState({
              reqEmail: data.not_match,
            });
          } else {
            this.setState({
              isEmail: false,
            });
          }
        });
    }
  }

  render() {
    let errorEmail = this.state.isEmail ? classes.errorActive : "";
    const { texts } = this.state;

    // console.log(data);

    return (
      <Modal
        size={"md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.show}
        onHide={this.props.loginClicked}
      >
        <Modal.Header onClick={this.props.close} closeButton>
          <span className={classes.modalTitle}>{texts.resetP}</span>
        </Modal.Header>
        <div className={classes.modalContent}>
          <div className={classes.content}>
            <div className={classes.boxContent}>
              <span className={classes.titleContent}>{texts.email}</span>
              <input
                id="m-email"
                className={classes.inputModal}
                type={"text"}
                defaultValue={""}
              />
            </div>
            <span className={[classes.validError, errorEmail].join("")}>
              {this.state.reqEmail}
            </span>
            <div className={classes.contentAction}>
              <div className={classes.cancel} onClick={this.props.loginClicked}>
                <span>{texts.cancel}</span>
              </div>
              <div
                className={classes.boxAction}
                onClick={() => {
                  this.resetPasswordEmail();
                }}
              >
                <span>{texts.continue}</span>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalPassword;
