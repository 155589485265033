import React, { Component } from 'react';
import classes from "./HorizontalScroll.module.css"

class HorizontalScroll extends Component {
    constructor(props) {
        super(props);
        
    }

    onWheel = (e) => {
        var container = document.getElementById('container')
        var containerScrollPosition = document.getElementById('container').scrollLeft
        container.scrollTo({
            top: 0,
            left: containerScrollPosition + e.deltaY,
            behaviour: 'smooth' //if you want smooth scrolling
        })

    }
    
    render() {
        return (
            <div id="container" className={classes.scrollMenu}>
                {this.props.children}
            </div>
        );
    }
}

export default HorizontalScroll;