import React, { Component } from "react"
import { Navbar, Nav } from "react-bootstrap"
import { isMobile, isTablet } from "react-device-detect"

import Aux from "../hoc/Auxs/Auxs"
import HamburgerMenu from "../HamburgerMenu/HamburgerMenu"
import Api from "../Api"
import classes from "./Navbar.module.css"

class NavigationBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contentSection: [],
      isLoaded: false,
      isApiLoaded: false,
      isHamburgerOpen: false,
      isHeader: 1,
      lang: "",
      styleBlur: {backdropFilter: "blur(24px)"}
    }
    this.getSectionDetail = this.getSectionDetail.bind(this)
    this.timeout = {}
  }

  componentDidMount() {
    this.getSectionDetail()
    if (isTablet) {
      this.setState({
        isHeader: 2,
      })
    } else if (isMobile) {
      this.setState({
        isHeader: 3,
      })
    } else {
      this.setState({
        isHeader: 1,
      })
    }
    this.setState({ lang: localStorage.getItem('language') })
  }

  getSectionDetail() {
    const apiData = new Api()
    apiData
      .getSectionDetail()
      .then((result) => {
        const sections = result.data

        this.setState({
          contentSection: sections.widgets,
          isLoaded: true,
        })
      })
      .catch((error) => {
        this.setState({
          isApiLoaded: true,
        })
        // window.location.assign(`/error/${error.response.status ? error.response.status : 404}`)
      })
  }

  hamburgerHandler = (val) => {
    this.setState({ isHamburgerOpen: val }, () => {
      clearTimeout(this.timeout.blur)
      if(this.state.isHamburgerOpen){
        this.setState({styleBlur: {backdropFilter: "unset"}})
      } else {
        this.timeout.blur = setTimeout(() => {
          this.setState({styleBlur: {backdropFilter: "blur(24px)"}})
        }, 1000)
      }
    })
  }

  render() {
    let from;

    const { contentSection } = this.state
    let logo,
      selectLanguage,
      srcLogo

    let placeholder,
      lang,
      icon

    if (this.state.isLoaded) {
      srcLogo = this.state.lang === "English" ? contentSection[4].widget_contents[0].file : contentSection[4].widget_contents[0].file
      logo = (
        <Navbar.Brand href='/' className={['nav-text', classes.logoBrand].join(" ")}>
          <img className={classes.imgLogo} src={srcLogo} alt='logo' />
        </Navbar.Brand>
      )
    }

  // let unsetBlur = this.state.isHamburgerOpen ? { } : {transition: "backdrop-filter 0s 1s", backdropFilter: "blur(50px)"};

    return (
      <Aux>
        <Navbar className={["navbar-top", classes.nav].join(" ")} style={this.state.styleBlur} >
          {logo}
          <Nav className='ml-auto'>
            <HamburgerMenu from={from} burgerColor={"#223771"} items={this.props.items} placeholder={placeholder} selectLanguage={selectLanguage} lang={lang} icon={icon} hamburgerHandler={this.hamburgerHandler} />
          </Nav>
        </Navbar>
      </Aux>
    )
  }
}

export default NavigationBar
