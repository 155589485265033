import React, { Component } from "react";
import { Modal } from "react-bootstrap";

import UserContext from "../../../../Context";

import Api from "../../../../../Api";

import classes from "./Modal.module.css";

export default class ModalCountry extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      id: 0,
      listCountries: [],
      isLoaded: false,
      isCountry: false,
      data: [],
    };
  }

  componentDidMount() {
    const contextValue = this.context;

    this.setState({
      isLoggedIn: contextValue.isLoggedIn,
      id: contextValue.idApplicant,
      data: contextValue.contentSection[9].sections.widget_contents,
    });

    this.getListCountries();
  }

  getListCountries() {
    const countries = new Api();

    countries
      .getListCountries()
      .then((res) => {
        const data = res.data;
        const country = [];
        data.results.forEach((a) => {
          country.push({
            id: a.id,
            code: a.iso_code,
            name: a.name,
          });
        });
        this.setState({
          listCountries: country,
          isLoaded: true,
        });
      })
      .catch((err) => {
        console.log("error =>", err);
      });
  }

  updateProfile() {
    const update = new Api();
    const contextValue = this.context;

    if (this.isValidate()) {
      let c = document.getElementById("country-select");
      let country = c.options[c.selectedIndex].value;

      const updateData = {
        country: country,
      };

      update
        .updateApplicant(this.state.id, updateData, "country")
        .then((res) => {
          this.setState({
            isCountry: false,
          });
          this.props.close();
          contextValue.getNewDataApplicant();
          contextValue.showNotification(
            "Your country has been successfully updated."
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  isValidate() {
    let c = document.getElementById("country-select");
    let country = c.options[c.selectedIndex].value;

    if (country !== "x") {
      return true;
    } else {
      this.setState({
        isCountry: true,
      });
      return false;
    }
  }

  render() {
    let title, cancel, save;
    this.state.data.forEach((x, i) => {
      switch (i) {
        case 2:
          title = x.content;
          break;
        case 12:
          cancel = x.content;
          break;
        case 13:
          save = x.content;
          break;
        default:
      }
    });
    let countries;
    if (this.state.isLoaded) {
      countries = this.state.listCountries.map((c) => {
        return <option value={c.id}>{c.name}</option>;
      });
    }
    let isError = this.state.isCountry ? classes.errorVisible : "";

    return (
      <Modal
        size={"md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.show}
        onHide={this.props.close}
      >
        <Modal.Header
          className={classes.modalHeader}
          onClick={this.props.close}
          closeButton
        >
          <span className={classes.modalTitle}>{title}</span>
        </Modal.Header>
        <UserContext.Consumer>
          {(context) => (
            <div className={classes.modalContent}>
              <div className={classes.content}>
                <div className={classes.boxContent}>
                  <span className={classes.titleContent}>{title}</span>
                  <select id="country-select" className={classes.modalSelect}>
                    <option value={"x"}>---</option>
                    {countries}
                  </select>
                </div>
                <span className={[classes.contentError, isError].join(" ")}>
                  This field is required
                </span>
              </div>
              <div className={classes.modalAction}>
                <div className={classes.cancel}>
                  <span>{cancel}</span>
                </div>
                <div
                  className={classes.save}
                  onClick={() => {
                    this.updateProfile();
                    // context.getNewDataApplicant()
                    // this.props.close()
                  }}
                >
                  <span>{save}</span>
                </div>
              </div>
            </div>
          )}
        </UserContext.Consumer>
      </Modal>
    );
  }
}
