import React, { Component } from "react";
import classes from "./Tablet.module.css";
import { Link } from "react-router-dom";
import Api from "../Api";
import dateFormat from "dateformat";

import imgDef from "../assets/default-image.svg";

export default class ArticleLatest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id_wid: 0,
            id_catg: 0,
            size: 3,
            contentArticle: [],
            isLoaded: false,
        };

        this.getArticleDetailByWigdet =
            this.getArticleDetailByWigdet.bind(this);
    }
    componentDidMount() {
        this.getArticleDetailByWigdet(this.state.size, this.props.id_widget);
    }

    getArticleDetailByWigdet(size, widget) {
        let count = 0;
        if (count === 0) {
            const apiData = new Api();

            var art = [...this.state.contentArticle];

            apiData
                .getArticleDetailByWigdet(size, widget)
                .then((result) => {
                    const articles = result.data;
                    let newArts = [];
                    articles.results.forEach((a) => {
                        const assets = [];
                        let typeVid = [
                            "webm",
                            "mkv",
                            "flv",
                            "gif",
                            "avi",
                            "mov",
                            "wmv",
                            "mp4",
                            "3gp",
                        ];
                        let typeImg = [
                            "jpg",
                            "jpeg",
                            "png",
                            "svg",
                            "webp",
                            "bmp",
                            "tiff",
                            "exif",
                        ];

                        a.article_assets.forEach((ass, i) => {
                            if (i === 0) {
                                if (ass.file_small) {
                                    let extension = ass.file_small.substring(
                                        ass.file_small.lastIndexOf(".") + 1
                                    );

                                    if (typeVid.includes(extension)) {
                                        assets.push({ assetV: ass.file_small });
                                    } else if (typeImg.includes(extension)) {
                                        assets.push({ asset: ass.file_small });
                                    }
                                } else {
                                    assets.push({ assetV: ass.asset_link });
                                }
                            }
                        });

                        let newArticle = {
                            id: a.id,
                            category: a.category_names,
                            categoryID: a.categories,
                            name: a.name,
                            title: a.article_languages.title,
                            url_title: a.article_languages.slug_title,
                            summary: a.article_languages.summary,
                            content: a.article_languages.content,
                            assets: assets,
                            date: a.date,
                        };

                        newArts.push(newArticle);
                    });

                    art = newArts;
                    return art;
                })
                .then((art) => {
                    this.setState({
                        contentArticle: this.state.contentArticle.concat(art),
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
            count = 1;
        }
    }

    render() {
        let articles;
        if (this.state.isLoaded) {
            articles = this.state.contentArticle.map((ele) => {
                let dates = ele.date;
                let strDate = dateFormat(dates, "dd/mm/yyyy");
                let ur = `${this.props.url}/${ele.url_title}/${ele.id}`;

                return (
                    <div>
                        <div className={[classes.col_md, "mt-3"].join(" ")}>
                            <div className="d-flex flex-row">
                                <div className={classes.box_md_img}>
                                    <img
                                        src={
                                            ele.assets[0]
                                                ? ele.assets[0].asset
                                                : imgDef
                                        }
                                        alt=""
                                    />
                                </div>
                                <div
                                    className={[
                                        "d-flex flex-column",
                                        "ml-3",
                                        classes.md_box_text,
                                    ].join(" ")}
                                >
                                    <Link
                                        onClick={() => {
                                            setTimeout(() => {
                                                window.location.reload();
                                            }, 200);
                                        }}
                                        to={ur}
                                        className={classes.customLink}
                                    >
                                        <h1>{ele.title}</h1>
                                    </Link>
                                    <p>{strDate}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            });
        }
        return <>{articles}</>;
    }
}
