import React, { Component } from 'react'

import anime from 'animejs';

export default class Anime extends Component {
    componentDidMount(){
        const path = document.querySelectorAll("path#vn-id, path#vn-us, path#vn-afsel, path#vn-ru, path#vn-br, path#vn-aus, path#vn-ca");
        for (let i = 0; i < path.length; i++) {
            let pathEl = path[i];
            let offset = anime.setDashoffset(pathEl);
            let dur = 5000
            pathEl.setAttribute('stroke-dashoffset', offset);
            anime({
                targets: pathEl,
                strokeDashoffset: [offset, 0],
                duration: dur,
                delay: 1000 * i,
                
                loop: true,
                direction: 'normal',
                easing: 'easeInOutExpo',
                autoplay: true
            });
        }
    }

    render() {
        return (
            <>
            </>
        )
    }

}
