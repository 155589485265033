import React, { Component, Fragment } from "react";
import Slider from "react-slick";
import { Slide } from "react-awesome-reveal";
import { Card, Row, Col } from "react-bootstrap";
import classes from "./Desktop.module.css";
import Api from "../Api";
import ReactHtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";

class Desktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: null,
      sections: {
        widgets: [],
      },

      awards_1: [],
      awards_2: [],
      contentArticle: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
      sectionTitle: "",
      sectionDescription: "",
      allAwardsUrl: "",
      awardDetails1: "",
      awardDetails2: "",
      latestAwardsButtonText: "",
      highlightAwardsButtonText: "",
      allAwardsButtonText: "",
      currentSlide: 0,
      hoverMode: false,
      hoverIndex: 0,
      tabIndex: 1,
      sectionName: ""
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
    this.hiddenBackground = this.hiddenBackground.bind(this);
    this.changeBackground = this.changeBackground.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

    this.makeSliderElements = this.makeSliderElements.bind(this);
    this.makeImageHover = this.makeImageHover.bind(this);
    this.getArticles = this.getArticles.bind(this);
    this.sortAllArray = this.sortAllArray.bind(this);
    this.changeSlide = this.changeSlide.bind(this);
    this.setCurrentLink = this.setCurrentLink.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  sortAllArray(arr) {
    var listToSort = arr;

    if (listToSort.length > 0) {
      listToSort.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
      return listToSort;
    }
  }

  getArticles(param, slider) {
    const apiData = new Api();
    this.setState(
      {
        contentArticle: [],
      },
      () => {
        apiData
          .getArticleDetailByYearDescending(param, "", 12, "")
          .then((result) => {
            const articles = result.data;
            const art = [];

            articles.results.forEach((a) => {
              const assets = [];
              a.article_assets.forEach((ass, i) => {
                let ext,
                  imageExt = new Set(["jpg", "png", "jpeg"]);

                if (ass.file_small) {
                  ext = ass.file_small.split(".");
                  ext = ext[ext.length - 1];
                }

                if (
                  !assets.length &&
                  (ass.asset_type === "Image" ||
                    imageExt.has(ext.toLowerCase()))
                ) {
                  assets.push({
                    asset: ass.file_small,
                    asset_type: ass.asset_type,
                    description: ass.description
                  });
                } else if (
                  !assets.length &&
                  ass.asset_link &&
                  ass.asset_link.indexOf("youtube.com") !== -1
                ) {
                  let _asset = ass.asset_link.split("/");
                  let ytVideoId = _asset[_asset.length - 1];
                  let asset = `https://img.youtube.com/vi/${ytVideoId}/hqdefault.jpg`;

                  assets.push({
                    asset: asset,
                    asset_type: ass.asset_type,
                    description: ass.description
                  });
                }
              });

              art.push({
                id: a.id,
                category: a.category_names,
                name: a.name,
                title: a.article_languages.title,
                url_title: a.article_languages.slug_title,
                content: a.article_languages.summary,
                summary: a.article_languages.summary,
                assets: assets,
                date: a.date,
              });
            });

            switch (slider) {
              case 1:
                let art1 = this.sortAllArray(art);
                this.setState({ awards_1: art1, isLoaded: true });
                break;

              case 2:
                let art2 = this.sortAllArray(art);
                this.setState({ awards_2: art2, isLoaded: true });
                break;
              default:
                break;
            }

            // this.setState({
            //   contentArticle: art,
            //   isLoaded: true,
            // });
          })
          .catch((error) => {
            this.setState({ isApiLoaded: true });
          });
      }
    );
  }

  getSectionDetail() {
    const apiData = new Api();
    var slider = 1;
    this.setState(
      {
        sections: [],
        awards_1: [],
        awards_2: [],
      },
      () => {
        apiData
          .getSectionDetail()
          .then((result) => {
            this.setState({ isApiLoaded: true });
            const sections = result.data;
            const widget = [];

            let sectionName = sections.name.toLowerCase().replace(/\s+/g, "-");

            result.data.widgets.forEach((item) => {
              widget.push({
                id: item.id,
                index: item.index,
                category: item.category,
                name: item.name,
                sections: item,
              });
              if (item.category === "Article") {
                this.getArticles(item.id, slider);
                slider += 1;
              }
            });
            sections.page = widget;
            this.setState({
              sections: widget,
              sectionName: sectionName,
              isLoaded: true,
            });
          })
          .catch((error) => {
            this.setState({ isApiLoaded: true });
          });
      }
    );
  }

  getHtmlParagraph = (str) => {
    if (str && str !== "") {
      return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ");
    }
  };

  changeSlide(currentSlide) {
    this.setState({
      currentSlide: currentSlide,
    });
  }

  handleSelect(i) {
    this.setState({ tabIndex: i });
  }

  changeBackground(i) {
    this.setState({ hoverMode: true, hoverIndex: i });
  }

  hiddenBackground() {
    this.setState({ hoverMode: false });
  }

  makeSliderElements(sliderInput, url) {
    let titleContent = "";

    return sliderInput.map((s, i) => {
      let strDate = s.date;
      let dateFull = dateFormat(strDate, "dd-mm-yyyy");
      titleContent = <p>{ReactHtmlParser(s.summary)}</p>;

      return (
        <div
          className={classes.content_wrap}
          onMouseEnter={() => this.changeBackground(i)}
          // onMouseLeave={this.hiddenBackground}
        >
          <Link
            className={classes.link}
            onClick={() => this.setCurrentLink()}
            to={{
              pathname: `${url}/${s.url_title !== "" ? s.url_title : '-'}/${s.id}`,
            }}
          >
            <p className={classes.paragraph}>{titleContent}</p>
          </Link>
          <p className={classes.paragraph3}>{dateFull}</p>
        </div>
      );
    });
  }

  makeImageHover(imageHoverInput) {
    return imageHoverInput.map((s, i) => {
      let images2 = null;
      if (this.state.hoverMode) {
        if (this.state.hoverIndex === i && s) {
          if (!s.assets.length) {
            images2 = (
              <div>
                <Card.Body className={classes.card_body}>
                  <img
                    className={classes.imgcard}
                    src={require("../assets/default-image.svg")}
                    alt="Default image"
                  />
                </Card.Body>
              </div>
            );
          } else {
            images2 = (
              <div>
                <Card.Body className={classes.card_body}>
                  <img
                    className={classes.imgcard}
                    src={s.assets[0].asset}
                    alt={s.assets[0].description}
                  />
                </Card.Body>
              </div>
            );
          }
        }
      }

      return <> {images2} </>;
    });
  }

  setCurrentLink(param, url) {
    localStorage.setItem(
      "prevLink",
      `${window.location.href}${
        this.state.sectionName ? `/#${this.state.sectionName}` : ""
      }`
    );
  }

  render() {
    const {
      sections,
      hoverMode,
      tabIndex,
      awards_1,
      awards_2,
      contentArticle,
    } = this.state;

    let title,
      content,
      name,
      titleContent,
      dummyName,
      dummyContent,
      mainSlider,
      dummyTitleContent,
      imageHover,
      imgHover,
      dummyContentSlider,
      secondSlider,
      contentSlider,
      hoverSlider,
      imageBackground,
      content2 = null;

    const widgets = this.state.widgets;

    const settings = {
      className: "",
      infinite: false,
      centerPadding: "60px",
      slidesToShow: 3,
      swipeToSlide: true,
      beforeChange: function (currentSlide) {
        this.setState({
          currentSlide: currentSlide,
        });
      }.bind(this),
      // afterChange: function (index) {
      //   console.log(
      //     `Slider Changed to: ${index + 3}, background: #222; color: #bada55`
      //   );
      // },
    };

    if (window.screen.width <= 835) {
      settings.slidesToShow = 1.5;
    } else if (window.screen.width <= 1024) {
      settings.slidesToShow = 2.5;
    }

    if (this.state.isLoaded) {
      let count = 0;
      sections.forEach((item) => {
        count += 1;

        switch (count) {
          case 1:
            if (!item.sections.widget_contents[0]) {
              name = <></>;
            } else {
              if (item.sections.widget_contents[0].content !== "") {
                name = (
                  <h2 className={classes.card_row_3_title}>
                    {item.sections.widget_contents[0].content}
                  </h2>
                );
                this.state.sectionTitle =
                  item.sections.widget_contents[0].content;
              } else {
                name = <></>;
              }
            }
            break;

          case 2:
            if (!item.sections.widget_contents[0]) {
              content2 = <></>;
            } else {
              if (item.sections.widget_contents[0].content !== "") {
                content2 = (
                  <p className={classes.card_text_3}>
                    {ReactHtmlParser(item.sections.widget_contents[0].content)}
                  </p>
                );
              } else {
                content2 = <></>;
              }
            }
            break;

          case 3:
            if (!item.sections.widget_contents[0]) {
              this.state.latestAwardsButtonText = "";
            } else {
              if (item.sections.widget_contents[0].content !== "") {
                this.state.latestAwardsButtonText =
                  item.sections.widget_contents[0].content;
              } else {
                this.state.allAwardsButtonText = "";
              }
            }
            break;

          case 4:
            if (!item.sections.widget_contents[0]) {
              this.state.highlightAwardsButtonText = "";
            } else {
              if (item.sections.widget_contents[0].content !== "") {
                this.state.highlightAwardsButtonText =
                  item.sections.widget_contents[0].content;
              } else {
                this.state.highlightAwardsButtonText = "";
              }
            }
            break;

          case 5:
            this.state.allAwardsUrl = item.sections.url
              ? item.sections.url
              : "/awards/";
            if (!item.sections.widget_contents[0]) {
              this.state.allAwardsButtonText = "";
            } else {
              if (item.sections.widget_contents[0].content !== "") {
                this.state.allAwardsButtonText =
                  item.sections.widget_contents[0].content;
              } else {
                this.state.allAwardsButtonText = "";
              }
            }
            break;

          case 6:
            if (tabIndex != 2) {
              this.state.awardDetails1 = item.sections.url
                ? item.sections.url
                : "/awards/detail/";
              if (hoverMode) {
                imageHover = this.makeImageHover(awards_1);
              }

              mainSlider = this.makeSliderElements(
                awards_1,
                this.state.awardDetails1
              );
            }
            break;

          case 7:
            if (tabIndex === 2) {
              this.state.awardDetails2 = item.sections.url
                ? item.sections.url
                : "/awards/detail/";

              if (hoverMode) {
                imgHover = this.makeImageHover(awards_2);
              }

              secondSlider = this.makeSliderElements(
                awards_2,
                this.state.awardDetails2
              );
            }
            break;
          default:
        }
      });

      switch (tabIndex) {
        case 1:
          contentSlider = mainSlider;
          hoverSlider = imageHover;
          break;
        case 2:
          contentSlider = secondSlider;
          hoverSlider = imgHover;
          break;
        default:
          contentSlider = mainSlider;
          hoverSlider = imageHover;
          break;
      }

      if (!hoverMode) {
        let images = awards_1.forEach((s, i) => {
          if (this.state.hoverIndex === i) {
            hoverSlider = (
              <Card.Body className={classes.card_body}>
                <img
                  className={classes.imgcardBackground}
                  src={
                    s.assets.length
                      ? s.assets[0].asset
                      : require("../assets/default-image.svg")
                  }
                  alt={
                    s.assets.length
                      ? s.assets[0].description
                      : "Default image"
                  }
                />
              </Card.Body>
            );
          }
        });
      }
    } else if (this.state.isApiLoaded) {

    }

    let activeClass1 =
      this.state.tabIndex === 1 ? classes.active : classes.inActive;
    let activeClass2 =
      this.state.tabIndex === 2 ? classes.active : classes.inActive;

    return (
      <Fragment>
        <div>
          <div className={["container", classes.containerAwards].join(" ")}>
            <Slide direction={"right"} triggerOnce={true}>
              <Row>
                {name}
                {content2}
              </Row>

              <Row className={classes.Row_3}>
                <Col className={classes.col_img}>
                  <Card className={classes.card_row_3_1}>{hoverSlider}</Card>
                </Col>
                <Col>
                  <Card className={classes.card_row_3}>
                    <Card.Title
                      className={[classes.lates, activeClass1].join(" ")}
                      onClick={() => this.handleSelect(1)}
                    >
                      <img
                        src={require("../assets/thropy.svg")}
                        className={classes.thropy}
                        alt="Trophy"
                      />{" "}
                      <span>{this.state.latestAwardsButtonText}</span>
                    </Card.Title>

                    <Link to={this.state.allAwardsUrl}>
                      <Card.Title className={classes.all}>
                        <img
                          src={require("../assets/thropy.svg")}
                          className={classes.thropy}
                          alt="Trophy"
                        />{" "}
                        <span>
                          {ReactHtmlParser(this.state.allAwardsButtonText)}
                        </span>
                      </Card.Title>
                    </Link>
                    <Slider {...settings}>{contentSlider}</Slider>
                  </Card>
                </Col>
              </Row>
            </Slide>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Desktop;
