import React, { Component } from "react";
import BlogView from "../components/BlogView/BlogView"
import NavBack from "../components/NavBack/NavBack"
import Api from "../Api";
import classes from "./IN_View_Detail.module.css";

export default class Section3 extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };

    this.getSectionDetail = this.getSectionDetail.bind(this)
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    apiData
      .getSectionDetail()
      .then((result) => {
        const sections = result.data;
        const widget = [];

        result.data.widgets.forEach((item) => {
          widget.push({
            id: item.id,
            index: item.index,
            category: item.category,
            is_internal: item.is_internal,
            name: item.name,
            sections: item,
          });
        });

        sections.page = widget;
        this.setState({
          contentSection: widget,
          isLoaded: true,
        });
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          isApiLoaded: true,
        });
      });
  }

  render() {
    const { contentSection } = this.state;
    let blogComponent
    let readMoreLink
    let sidebarText
    let title
    let backBtn

    if (this.state.isLoaded) {
      let widget = {}
      let readMoreBlog = {};

      contentSection.forEach((item) => {

        if (item.index === 1 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              title = <h2 className={classes.title2}>{s.content}</h2>;
            }
          });
        }

        if (item.index === 2 && item.category === "Article") {
          widget.id = item.id
          widget.url = item.sections.url
        }

        if (item.index === 3 && item.category === "Text") {
          readMoreBlog.url = item.sections.url
          readMoreBlog.text = item.sections.widget_contents[0].content
        }

        if (item.index === 4 && item.category === "Text") {
          sidebarText = item.sections.widget_contents
        }

        if (item.index === 5 && item.category === "Text") {
          backBtn = <NavBack url={item.sections.url}>{item.sections.widget_contents[0].content}</NavBack>
        }

        blogComponent = <BlogView widget={widget} readMore={readMoreBlog} sidebarText={sidebarText} />
      })
    }

    return (
      <div className={classes.contentSection}>
        <div className={["container", classes.boxC].join(" ")}>
          {title}
          {blogComponent}
          {backBtn}
        </div>
      </div>
    )
  }
}
