import React, { Component } from "react";
import classes from "./Mobile.module.css";
import Api from "../Api";
import Tintuc from "./Tintuc";
import Baocao from "./Baocao";

export default class Mobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentSection: {
        widgets: [],
      },
      readMore: "Read",
      sectionName: "",
      isLoaded: false,
      isApiLoaded: false,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }
  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const widget = [];

          let sectionName = sections.name.toLowerCase().replace(/\s+/g, "-");

          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              name: item.name,
              index: item.index,
              internal: item.is_internal,
              category: item.category,
              // url: "/news/detail/",
              url: item.url,
              sections: item,
            });
          });

          sections.page = widget;
          this.setState({
            contentSection: widget,
            sectionName: sectionName,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
          window.location.assign(
            `/error/${error.response.status ? error.response.status : 404}`
          );
        });
    });
  }
  render() {
    let tinTuc, baoCao, title1, read0, isImageBack, backgroundImage, title2;
    var articleIds = [];

    if (this.state.isLoaded) {
      this.state.contentSection.forEach((item, i) => {
        switch (i) {
          case 0:
            read0 = item.sections.widget_contents[0].content;
            break;
          case 1:
            title1 = item.sections.widget_contents[0].content;
            break;
          case 2:
            break;
          case 3:
            articleIds.push(item.id);
            tinTuc = (
              <Tintuc
                id={articleIds}
                idf={item.id}
                rd={read0}
                url={item.url}
                sectionName={this.state.sectionName}
              />
            );
            break;
          case 4:
            title2 = item.sections.widget_contents[0].content;
            break;
          case 5:
            break;
          case 6:
            articleIds.push(item.id);
            baoCao = (
              <Baocao id={articleIds} idf={item.id} rd={read0} url={item.url} />
            );
            break;
          case 7:
            isImageBack = item.sections.widget_contents[0].file_small;
            backgroundImage = {
              width: "100vw",
              height: "auto",
              backgroundImage: `url(${isImageBack})`,
              backgroundSize: "cover",
            };
            break;
          default:
            break;
        }
      });
    }

    return (
      <div className={classes.wrpct001}>
        <div style={backgroundImage}>
          <div className={classes.Blur}>
            <div className={["container", classes.wrpct001_1].join(" ")}>
              <p className={classes.isTitle1}>{title1}</p>
              <div className={classes.isTintuc}>{tinTuc}</div>
            </div>
            <div className={["container", classes.wrpct001_2].join(" ")}>
              <p className={classes.isTitle1}>{title2}</p>
              <div className={classes.isBaocao}>{baoCao}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
