import * as React from "react";

import useScrollPosition from "@react-hook/window-scroll";
import "./SectionShortcut.css"

export default function HomeSectionShortcut(props) {
    const scrollY = useScrollPosition(60);
    let isFixed = null
    if ((scrollY >= '300')) {
        isFixed = 'home-section-shortcut-fixed'
    } else {
        isFixed = 'home-section-shortcut'
    }
    return (
        <div className={isFixed}>
            {/* <span>{scrollY}</span> */}
            {props.section}
        </div>
    );
}
