import React, { Component, Fragment } from "react";
import classes from "../Section1.module.css";
import { ParallaxProvider } from "react-scroll-parallax";
import Slider from "react-slick";
import ReactHtmlParser from "react-html-parser";
import Api from "../Api";
import Box from "../Box";
import Box2 from "../Box2";
import dateFormat from "dateformat";
import ArticleLatest from "./ArticleLatest";
import Option2 from "../Option/Option2/Option2";
import Option3 from "../Option/Option3/Option3";

// import { map } from 'core-js/fn/array';

class Section1 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sections: {
				widgets: [],
			},
			isLoaded: false,
			isApiLoaded: false,
			isLoadedID: false,
			idSection: this.props.id,
			active_img: 0,
			disabledNext: false,
			contentArticle: [],
			size: 3,
			href_param: "",
			id_widget: 0,
			id_catg: 0,
		};
		this.toggleNext = this.toggleNext.bind(this);
		this.getSectionDetail = this.getSectionDetail.bind(this);
		// this.getArticleDetailByMultipleWigdetOnNews = this.getArticleDetailByMultipleWigdetOnNews.bind(
		//   this
		// );
	}

	componentDidMount() {
		if (this.props.param.param2) {
			this.getSectionDetail(this.props.param.param2);
		}
	}
	changeSlideBar(key) {
		this.setState({ active_img: key });
	}
	toggleNext() {
		this.setState((id) => {
			if (id === id + 1) {
				return { disabledNext: false };
			} else {
				return { i: id + 1 };
			}
		});
	}
	getSectionDetail(paramId) {
		const apiData = new Api();

		this.setState(
			{
				article: [],
				sections: [],
			},
			() => {
				apiData.getSectionDetail().then((result) => {
					const sections = result.data;
					const widget = [];
					let id = sections.id;
					result.data.widgets.forEach((item) => {
						widget.push(item);
					});

					this.setState({
						sections: widget,
						idSection: id,
						isLoadedID: true,
					});
				});
				apiData
					.getArticleDetailByArticleId(paramId)
					.then((result) => {
						const article = result.data;
						let id_widget = article.widgets ? article.widgets[0].id : "";
						let id_catg = article.categories ? article.categories[0].id : "";
						// console.log(article);

						const assets = [];

						let typeVid = ["webm", "mkv", "flv", "gif", "avi", "mov", "wmv", "mp4", "3gp"];
						let typeImg = ["jpg", "jpeg", "png", "svg", "webp", "bmp", "tiff", "exif"];
						article.article_assets.forEach((item) => {
							let desc = item.asset_languages.description;

							if (item.file_small) {
								let extension = item.file_small.substring(item.file_small.lastIndexOf(".") + 1);
								if (typeVid.includes(extension)) {
									assets.push({
										assetV: item.file_small,
										desc: item.description,
									});
								} else if (typeImg.includes(extension)) {
									assets.push({
										asset: item.file_small,
										desc: desc,
									});
								}
							} else {
								assets.push({
									assetV: item.asset_link,
									desc: desc,
								});
							}
							// assets.push({ asset: item.asset_file, desc: item.description });
						});

						const allcontent = article.article_languages;

						let content = allcontent.content;
						let title = allcontent.title;
						let category = article.category_name;
						let date = article.date;
						let article_type = article.article_type ? article.article_type.id : 1;
						let content_file = allcontent.content_file;
						let content_section = allcontent.content_section;

						const widget = [];

						widget.push({
							article_type: article_type,
							content: content,
							content_file: content_file,
							content_section: content_section,
							title: title,
							category: category,
							date: date,
							assets: assets,
						});

						article.page = widget;
						this.setState({
							article: widget,
							isLoaded: true,
							id_widget: id_widget,
							id_catg: id_catg,
						});
					})
					.catch((error) => {
						this.setState({ isApiLoaded: true });
					});
			}
		);
	}

	imageOrVideo = (url, param, index, imageDescription) => {
		let content;
		if (url !== "") {
			switch (param) {
				case "img":
					content = <img className={classes.mediaPreview} src={url} alt={imageDescription} />;
					break;
				case "img1":
					content = <img onClick={() => this.changeSlideBar(index)} className={classes.mediaThumbnail} src={url} alt={imageDescription} />;
					break;
				case "vid":
					if (url) {
						if (url.includes("phenikaa-assets.s3")) {
							content = (
								<video className={classes.mediaPreview} controls loop={false}>
									<source src={url} type="video/mp4" />
								</video>
							);
						} else {
							content = <iframe allowfullscreen="allowfullscreen" mozallowfullscreen="mozallowfullscreen" msallowfullscreen="msallowfullscreen" oallowfullscreen="oallowfullscreen" webkitallowfullscreen="webkitallowfullscreen" src={url} className={classes.mediaPreviewiF} title="news movie"></iframe>;
						}
					} else {
						content = <img className={classes.mediaPreview} src={require("../assets/default-image.svg")} alt="description" />;
					}
					break;
				case "vid1":
					content = (
						<div onClick={() => this.changeSlideBar(index)} className={classes.mediaThumbnail}>
							{/* <iframe src={require('../assets/default-image.svg')} className={classes.videoThumbnail} title="news movie"></iframe> */}
							<img src={require("../assets/default-image.svg")} className={classes.videoThumbnail} alt=""></img>
						</div>
					);
					break;
				default:
					break;
			}
		} else {
			content = <img className={classes.box_image} src={require("../assets/default-image.svg")} alt="description" />;
		}
		return content;
	};

	render() {
		const settings = {
			className: "",
			infinite: false,
			centerPadding: "0",
			variableWidth: true,
			slidesToShow: 2,
			autoplay: false,
			// autoplaySpeed: 5000,
			swipeToSlide: true,
		};

		const { article, sections } = this.state;
		var shareTitle = null;

		let articleContent, disp, top, top2, detailImg, imgBottom, artices, articleNewest, relatedNewsText;

		if (this.state.isLoaded && this.state.isLoadedID) {
			sections.forEach((item) => {
				switch (item.index) {
					case 3:
						// item.widget_contents.forEach((x) => {
						//   nextText = x.content ? x.content : "";
						// });
						break;
					case 7:
						relatedNewsText = item.widget_contents[0].content;
						break;
					default:
						break;
				}
			});

			article.forEach((item) => {
				let strDate = item.date;
				let dateFull = dateFormat(strDate, "dd-mm-yyyy");

				if (item.assets.length === 1) {
					disp = classes.displayy;
					item.assets.forEach((ele, i) => {
						let param = item.assets[i] ? (item.assets[i].asset ? "img" : "vid") : "";
						let newAsset = this.imageOrVideo(item.assets[i] ? (item.assets[i].asset ? item.assets[i].asset : item.assets[i].assetV) : "", param, "", item.assets[i]?.desc);

						if (i === this.state.active_img) {
							detailImg = (
								<div className={classes._boxmain1}>
									<div className={classes.picImg}>{newAsset}</div>
									<div className={["container", classes.pad].join(" ")}>
										<p className={classes.txt2}>{item.assets[i].desc ? (item.assets[i].desc !== "" ? item.assets[i].desc : "") : ""}</p>
									</div>
								</div>
							);
						}
					});
					imgBottom = <div></div>;
				} else if (item.assets.length > 1) {
					let img_slide_box = item.assets.map((ele, i) => {
						let param = item.assets[i] ? (item.assets[i].asset ? "img" : "vid") : "";
						let newAsset = this.imageOrVideo(item.assets[i] ? (item.assets[i].asset ? item.assets[i].asset : item.assets[i].assetV) : "", param, null, item.assets[i]?.desc);
						if (i === this.state.active_img) {
							detailImg = (
								<div className={classes._boxmain}>
									<div className={classes.picImg}>{newAsset}</div>

									<div className={["container", classes.pad].join(" ")}>
										<p className={classes.txt2}>{item.assets[i].desc ? (item.assets[i].desc !== "" ? item.assets[i].desc : "") : ""}</p>
									</div>
								</div>
							);
						}

						let param1 = ele.asset ? "img1" : "vid1";
						let newAsset1 = this.imageOrVideo(ele.asset ? ele.asset : ele.assetV, param1, i, ele.desc);

						return <div className={classes._box2}>{newAsset1}</div>;
					});

					imgBottom = (
						<div className={classes._box3}>
							<div className={["container", classes.padd2].join(" ")}>
								<Slider {...settings}>{img_slide_box}</Slider>
							</div>
						</div>
					);
				}

				articleNewest = <ArticleLatest id_widget={this.state.id_widget} id_category={this.state.id_catg} id_current_article={this.props.id} />;

				artices = (
					<div>
						<p className={classes.related_news}>{relatedNewsText}</p>
						<div className={classes.box_two}>{articleNewest}</div>
					</div>
				);
				shareTitle = item.title;
				top = <Box id={this.state.idSection} />;
				top2 = <Box2 shareTitle={shareTitle} id={this.state.idSection} />;

				if (item.article_type === 1) {
					articleContent = (
						<div className={classes.box}>
							<div className={classes.boxContent2}>
								<div className="d-flex-flex column">
									<div className={classes.box_scroll2}>
										<div className={[classes.boxImage, disp].join(" ")}>
											{detailImg}
											{imgBottom}
										</div>
									</div>
								</div>
							</div>
							<div className={classes.boxContent}>
								<h1 className={classes.judul}>{item.title}</h1>

								<div className={classes.box_scroll}>
									<div className={classes.scroll_content}>
										<div className={classes.tgl}>{dateFull}</div>
										<div className={classes.isi}>{ReactHtmlParser(item.content)}</div>
										{/* <div className={classes.box_newest}>{articleNewest}</div> */}
									</div>
								</div>
								<div className="d-flex">
									{top}
									{top2}
								</div>
							</div>
						</div>
					);
				} else if (item.article_type === 2) {
					articleContent = 
					<>
						<Option2 src={item.content_file ? item.content_file : false} />
						{item.content && <p className="mt-3 float-right mr-3">{item.content}</p>}
					</>
				} else if (item.article_type === 3) {
					articleContent = <Option3 content={item.content} />
				}
			});
		} else if (this.state.isApiLoaded) {
		}
		return (
			<div className="pages-new">
				<div className={classes.wrapper}>
					<div className={classes.main}>
						<ParallaxProvider>
							<Fragment>{articleContent}</Fragment>
							<Fragment>{artices}</Fragment>
							{/* <Option2 /> */}
						</ParallaxProvider>
					</div>
				</div>
			</div>
		);
	}
}

export default Section1;
