import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import dateFormat from "dateformat";

import UserContext from "../../../Context";
import Api from "../../../../Api";

import classes from "./Modal.module.css";

export default class ModalExperience extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      startDate: "",
      year: "",
      year1: "",
      month: "",
      day: "",
      day1: "",
      selectedYear: "",
      selectedMonth: "",
      selectedDay: "",
      selectedYear1: "",
      selectedMonth1: "",
      selectedDay1: "",
      toogle: false,
      isLoaded: false,
      isTitle: false,
      isCompany: false,
      isStartDate: false,
      isFinishDate: false,
      data: [],
    };
    this.yearChange = this.yearChange.bind(this);
    this.monthChange = this.monthChange.bind(this);
    this.dayChange = this.dayChange.bind(this);
    this.toogleSwitch = this.toogleSwitch.bind(this);
    this.setWorkingExperience = this.setWorkingExperience.bind(this);
    this.isValidate = this.isValidate.bind(this);
    this.setValidate = this.setValidate.bind(this);
  }

  componentDidMount() {
    const contextValue = this.context;
    let year = [];
    let month = [];
    var d = new Date();
    var n = d.getFullYear();
    for (let index = n - 65; index <= n; index++) {
      year.push(index);
    }
    for (let index = 1; index <= 12; index++) {
      month.push(index);
    }

    let id = localStorage.getItem("id");
    this.setState({
      year: year,
      month: month,
      id: id,
      isLoaded: true,
      data: contextValue.contentSection[11].sections.widget_contents,
    });
  }

  setValidate(val, value) {
    if (val !== "") {
      this.setState({
        [value]: false,
      });
      return true;
    } else {
      this.setState({
        [value]: true,
      });
      return false;
    }
  }

  setDateValidate(year, month, day, value) {
    if (year !== "" && month !== "" && day !== "") {
      this.setState({
        [value]: false,
      });
      return true;
    } else {
      this.setState({
        [value]: true,
      });
      return false;
    }
  }

  isValidate() {
    let title = document.getElementById("m-title").value;
    let company = document.getElementById("m-company").value;

    let t = this.setValidate(title, "isTitle");
    let c = this.setValidate(company, "isCompany");
    let d1 = this.setDateValidate(
      this.state.selectedYear,
      this.state.selectedMonth,
      this.state.selectedDay,
      "isStartDate"
    );
    let d2;
    if (!this.state.toogle) {
      d2 = this.setDateValidate(
        this.state.selectedYear1,
        this.state.selectedMonth1,
        this.state.selectedDay1,
        "isFinishDate"
      );
    } else {
      this.setState({
        isFinishDate: true,
      });
      d2 = true;
    }
    if (t && c && d1 && d2) {
      return true;
    } else {
      return false;
    }
  }

  setWorkingExperience() {
    const update = new Api();
    const contextValue = this.context;

    if (this.isValidate()) {
      const year = this.state.selectedYear;
      const month = this.state.selectedMonth;
      const day = this.state.selectedDay;
      const year1 = this.state.selectedYear1;
      const month1 = this.state.selectedMonth1;
      const day1 = this.state.selectedDay1;

      let date = new Date();
      let today = dateFormat(date, "yyyy-mm-dd");
      let dateFinish = this.state.toogle ? today : `${year1}-${month1}-${day1}`;

      const workingExp = {
        applicant: this.state.id,
        title: document.getElementById("m-title").value,
        company: document.getElementById("m-company").value,
        description: document.getElementById("m-description").value,
        date_start: `${year}-${month}-${day}`,
        date_finish: dateFinish,
      };

      update
        .addWorkingExperience(this.state.id, workingExp)
        .then((res) => {
          this.setState({
            isTitle: false,
            isCompany: false,
            isStartDate: false,
            isFinishDate: false,
          });
          this.props.close();
          contextValue.getNewDataApplicant();
          contextValue.showNotification(
            "Your work experience has been successfully added."
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  yearChange(e, param) {
    if (e.target.value !== "x") {
      if (!param) {
        document.getElementById("month-select").disabled = false;
        this.setState({ selectedYear: e.target.value });
      } else {
        document.getElementById("month1-select").disabled = false;
        this.setState({ selectedYear1: e.target.value });
      }
    } else {
      if (!param) {
        document.getElementById("month-select").disabled = true;
      } else {
        document.getElementById("month1-select").disabled = true;
      }
    }
  }

  monthChange(e, param) {
    if (e.target.value !== "x") {
      if (!param) {
        let days = [];
        document.getElementById("day-select").disabled = false;

        let day = this.getDaysInMonth(this.state.selectedYear, e.target.value);
        for (let index = 1; index <= day; index++) {
          days.push(index);
        }

        this.setState({
          selectedMonth: e.target.value,
          day: days,
        });
      } else {
        let days = [];
        document.getElementById("day1-select").disabled = false;

        let day = this.getDaysInMonth(this.state.selectedYear, e.target.value);
        for (let index = 1; index <= day; index++) {
          days.push(index);
        }
        this.setState({
          selectedMonth1: e.target.value,
          day1: days,
        });
      }
    } else {
      if (!param) {
        document.getElementById("day-select").disabled = true;
      } else {
        document.getElementById("day1-select").disabled = true;
      }
    }
  }

  dayChange(e, param) {
    if (e.target.value !== "x") {
      !param
        ? this.setState({ selectedDay: e.target.value })
        : this.setState({ selectedDay1: e.target.value });
      if (!param) {
        document.getElementById("year1-select").disabled = false;
      }
    }
  }

  getDaysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  toogleSwitch() {
    this.setState((prevState) => {
      return {
        toogle: !prevState.toogle,
      };
    });
  }

  render() {
    let contentYear,
      contentYear1,
      contentMonth,
      contentDay,
      contentDay1,
      toogleSwitch,
      isDateDisable;
    let title,
      position,
      company,
      length_of_work,
      year,
      month,
      day,
      to,
      now,
      description,
      cancel,
      save;

    if (this.state.isLoaded) {
      this.state.data.forEach((x, i) => {
        switch (i) {
          case 4:
            title = x.content;
            break;
          case 5:
            position = x.content;
            break;
          case 6:
            company = x.content;
            break;
          case 7:
            length_of_work = x.content;
            break;
          case 8:
            year = x.content;
            break;
          case 9:
            month = x.content;
            break;
          case 10:
            day = x.content;
            break;
          case 11:
            now = x.content;
            break;
          case 12:
            description = x.content;
            break;
          case 19:
            cancel = x.content;
            break;
          case 20:
            save = x.content;
            break;
          case 21:
            to = x.content;
            break;
          default:
        }
      });
      contentYear = this.state.year.map((y) => {
        return <option value={y}>{y}</option>;
      });

      if (this.state.selectedYear !== "") {
        contentYear1 = this.state.year.map((y) => {
          if (y >= this.state.selectedYear) {
            return <option value={y}>{y}</option>;
          }
        });
      }

      let months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      contentMonth = this.state.month.map((m, i) => {
        return <option value={m}>{months[i]}</option>;
      });

      contentDay = this.state.day
        ? this.state.day.map((m) => {
            return <option value={m}>{m}</option>;
          })
        : "";

      contentDay1 = this.state.day1
        ? this.state.day1.map((m) => {
            return <option value={m}>{m}</option>;
          })
        : "";

      toogleSwitch = this.state.toogle ? classes.toggleActive : "";
      isDateDisable = this.state.toogle ? classes.disableDate : "";
    }
    let isErrorT = this.state.isTitle ? classes.errorVisible : "";
    let isErrorC = this.state.isCompany ? classes.errorVisible : "";
    let isErrorD1 = this.state.isStartDate ? classes.errorVisible : "";
    let isErrorD2 = this.state.isFinishDate ? classes.errorVisible : "";
    return (
      <Modal
        size={"lg"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.show}
        onHide={this.props.close}
      >
        <Modal.Header
          className={classes.modalHeader}
          onClick={this.props.close}
          closeButton
        >
          <div className="d-flex justify-content-center flex-column">
            <span className={classes.modalTitle}>{title}</span>
          </div>
        </Modal.Header>
        <div className={classes.modalContent}>
          <div className={classes.content}>
            <div className={classes.boxContent}>
              <span className={classes.titleContent}>{position}</span>
              <input
                id="m-title"
                className={classes.inputModal}
                type={"text"}
                defaultValue={""}
              />
            </div>
            <span className={[classes.contentError, isErrorT].join(" ")}>
              This field is required
            </span>
            <div className={classes.boxContent}>
              <span className={classes.titleContent}>{company}</span>
              <input
                id="m-company"
                className={classes.inputModal}
                type={"text"}
                defaultValue={""}
              />
            </div>
            <span className={[classes.contentError, isErrorC].join(" ")}>
              This field is required
            </span>

            <span className={classes.titleContent}>{length_of_work}</span>
            <div className={[classes.contentDate, "mb-3"].join(" ")}>
              <div className={classes.boxContent}>
                <span className={classes.titleContent}>{year}</span>

                <select
                  id="year-select"
                  className={classes.modalSelect}
                  onChange={this.yearChange}
                >
                  <option value={"x"}>-- select year --</option>
                  {contentYear}
                </select>
              </div>
              <div className={classes.boxContent}>
                <span className={classes.titleContent}>{month}</span>
                <select
                  id="month-select"
                  className={classes.modalSelect}
                  disabled
                  onChange={this.monthChange}
                >
                  <option value={"x"}>-- select month --</option>
                  {contentMonth}
                </select>
              </div>
              <div className={classes.boxContent}>
                <span className={classes.titleContent}>{day}</span>
                <select
                  id="day-select"
                  className={classes.modalSelect}
                  disabled
                  onChange={this.dayChange}
                >
                  <option value={"x"}>-- select day --</option>
                  {contentDay}
                </select>
              </div>
            </div>
            <span className={[classes.contentError, isErrorD1].join(" ")}>
              All date field is required
            </span>

            <span className={classes.titleContent}>{to}</span>
            <div className={[classes.contentDate, isDateDisable].join(" ")}>
              <div className={classes.boxContent}>
                <span className={classes.titleContent}>{year}</span>

                <select
                  id="year1-select"
                  className={classes.modalSelect}
                  disabled
                  onChange={(e) => this.yearChange(e, "1")}
                >
                  <option value={"x"}>-- select year --</option>
                  {contentYear1}
                </select>
              </div>
              <div className={classes.boxContent}>
                <span className={classes.titleContent}>{month}</span>
                <select
                  id="month1-select"
                  className={classes.modalSelect}
                  disabled
                  onChange={(e) => this.monthChange(e, "1")}
                >
                  <option value={"x"}>-- select month --</option>
                  {contentMonth}
                </select>
              </div>
              <div className={classes.boxContent}>
                <span className={classes.titleContent}>{day}</span>
                <select
                  id="day1-select"
                  className={classes.modalSelect}
                  disabled
                  onChange={(e) => this.dayChange(e, "1")}
                >
                  <option value={"x"}>-- select day --</option>
                  {contentDay1}
                </select>
              </div>
            </div>
            <span className={[classes.contentError, isErrorD2].join(" ")}>
              All date field is required
            </span>

            <div className={classes.boxContent}>
              <span className={classes.titleContent}>{now}</span>
              <label className={classes.switch}>
                <input
                  className={toogleSwitch}
                  id="checkDate"
                  type="checkbox"
                  onClick={this.toogleSwitch}
                />
                <span
                  className={[classes.slider, classes.round].join(" ")}
                ></span>
              </label>
            </div>

            <div className={classes.boxContent}>
              <span className={classes.titleContent}>{description}</span>
              <textarea
                id="m-description"
                className={classes.inputTextarea}
                type={"text"}
                defaultValue={""}
              />
            </div>
          </div>
          <div className={classes.modalAction}>
            <div className={classes.cancel} onClick={this.props.close}>
              <span>{cancel}</span>
            </div>
            <div
              className={classes.save}
              onClick={() => {
                this.setWorkingExperience();
              }}
            >
              <span>{save}</span>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
