import React, { Component, Fragment } from "react"
import { Link } from "react-router-dom"
import { ParallaxProvider } from "react-scroll-parallax"

import Api from "./Api"
import Views from "./Views/Views"
// import Core from "./Core"

// import BackgroundMask from "./BackgroundMask"

import classes from "./News.module.css"

export default class Desktop extends Component {
  constructor() {
    super()
    this.state = {
      currentPage: null,

      tabs: [
        {
          id: 1,
          title: "All news & Events",
          component: <div key={1} />,
          logo: <img className={classes.button_tab} src={require("./assets/Polygon7.svg")} alt='description' />,
        },
      ],
      sections: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
      views: null,
      activePage: <div></div>,
      valueQuery: null,
      articleID: null,
      sectionName: "",
      idArticle: null,
      topCorePos: 0,
      sectionNameN: "",
    }
    this.getSectionDetail = this.getSectionDetail.bind(this)
    this.handleOverride = this.handleOverride.bind(this)
  }

  componentDidMount() {
    let id = this.props.id
    this.getSectionDetail(id)
    this.handleOverride()
  }

  handleOverride() {
    this.props.override(false)
  }

  getSectionDetail(id) {
    const apiData = new Api()
    this.setState(
      {
        sections: [],
      },
      () => {
        apiData
          .getSectionDetail(id)
          .then((result) => {
            this.setState({ isApiLoaded: true })
            const sections = result.data
            let sectionName = sections.name.toLowerCase().replace(/\s+/g, "-")

            const widget = []
            sections.widgets.forEach((item) => {
              widget.push({
                id: item.id,
                index: item.index,
                category: item.category,
                name: item.name,
                sections: item,
              })
            })

            this.setState({
              sectionName: result.data.name,
              sections: widget,
              isLoaded: true,
              idArticle: result.data.id,
              articleID: widget[0].id,
              sectionNameN: sectionName,
            })
          })

          .catch((error) => {
            this.setState({ isApiLoaded: true })
          })
      }
    )
  }

  render() {
    const { sections } = this.state
    let readMore, share, searchHere, newsButton, textSearch, url

    // let dummyContentList = dummy.map((s, i) => {
    //   let backgroundColor = s.title === "NEWS" ? classes.newsColor : classes.eventColor
    //   let formatedDate = new Date(s.date)
    //   let day = formatedDate.getDay()
    //   let month = formatedDate.getMonth() + 1
    //   let year = formatedDate.getFullYear()
    //   formatedDate = day + "-" + month + "-" + year
    //   return (
    //     <Link to={"/news/detail/" + s.id} className={classes.link}>
    //       <div className={classes.contentWrap}>
    //         <div className={classes.boxContent}>
    //           <div className={classes.contentBox}>{s.title}</div>
    //           <div className={classes.title_content}>{s.Content}</div>
    //           <div className={classes.txt_content}>{s.Detail}</div>
    //           <div className={classes.foot_content}>
    //             <p>Read more</p>
    //             <p className={classes.date_right}>{s.Date}</p>
    //           </div>
    //         </div>
    //         <div className={classes.boxImage}>
    //           <figure className={classes.wrapp_image}>
    //             <img className={classes.img_content} src={s.img.src} alt='description' /> {/* <div className={classes.inside_image}>test</div> */}
    //             <div className={classes.inside_image}>
    //               <img className={classes.icon_share} src={require("./assets/Group 843.svg")} alt='description' />
    //               <br />
    //               <p>Share on :</p>
    //               <img className={classes.icon} src={require("./assets/Subtraction 1.svg")} alt='description' />
    //               <img className={classes.icon} src={require("./assets/Group 17.svg")} alt='description' />
    //               <img className={classes.icon} src={require("./assets/Group 19.svg")} alt='description' />
    //               <img className={classes.icon} src={require("./assets/Group 831.svg")} alt='description' />
    //             </div>
    //           </figure>
    //         </div>
    //       </div>
    //     </Link>
    //   )
    // })

    let viewOnchange

    if (this.state.isLoaded) {
      var articleId = 0
      var idNewsDetails = 0
      var article_categories = []
      var titleText = ""
      viewOnchange = sections.map((item) => {
        switch (item.index) {
          case 1:
            readMore = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Read Less"
            break
          case 2:
            share = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Don't share"
            break
          case 3:
            searchHere = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Don't search"
            break
          case 4:
            newsButton = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Less news"
            break
          case 5:
            textSearch = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Search here. . ."
            break
          case 6:
            url = item.sections.url ? item.sections.url : "/news/detail/"
            articleId = item.id
            break
          case 7:
            idNewsDetails = item.id
            break
          case 8:
            article_categories = item.sections.widget_contents;
            break
          case 9:
            titleText = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "All Innovation News"
            return (
              <Views
                id={articleId}
                idNewsDetails={idNewsDetails}
                idArt={this.state.idArticle}
                sectionNameN={this.state.sectionNameN}
                url={url}
                category={article_categories}
                view={"grid"}
                readMore={readMore}
                share={share}
                title={titleText}
                searchHere={searchHere}
                newsButton={newsButton}
                textSearch={textSearch}
              />
            )
          default:
        }
      })
    } else if (this.state.isApiLoaded) {
      // viewOnchange = dummyContentList
    }

    let navItem = this.state.tabs.map((n) => {
      let key = "tab" + n.id
      let activeTab = this.state.tabsActive === n.id ? classes.isActive : ""
      return (
        <div key={n.id} className={[classes.box, activeTab].join(" ")} onClick={() => this.toggleActiveHandler(n.component, n.id)}>
          <span id={key}>{n.title}</span>
          <div className={classes.btn_wrap}>{n.logo}</div>
        </div>
      )
    })

    return (
      <>
        <div className='page-news'>
          <div className={classes.main}>
            <ParallaxProvider>
              <Fragment>
                {/* <BackgroundMask /> */}
                <div className={classes.content}>
                  <div className={["container", classes.boxC].join(" ")}>{viewOnchange}</div>
                </div>
              </Fragment>
            </ParallaxProvider>
          </div>
        </div>
        <div className={classes.coreCon}>
          <div className={classes.Core}>
            {/* <Core /> */}
          </div>
        </div>
      </>
    )
  }
}
