import React, { Component } from "react";
import classes from "../Section5.module.css";
import Api from "../Api";
import Card from "../Card/Card";
import Slider from "../Slider/Slider";
import ModalPDF from "../ModalPDF/ModalPDF";

import "./Mobile.css";

class SD_Section5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentSection: [],
      isLoaded: false,
      isApiLoaded: true,

      // Pdf preview
      isShowPdfPreview: false,
      pdfFile: "",
      pdfTitle: "",
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const widget = [];

          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            });
          });
          sections.page = widget;
          this.setState({
            contentSection: widget,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }
  render() {
    var filePackage = [];
    var title = null;
    var fileArray = [];
    var imageArray = [];
    if (this.state.isLoaded) {
      this.state.contentSection.forEach((item, index) => {
        switch (index) {
          case 0:
            imageArray = [];
            item.sections.widget_contents.forEach((image) => {
              let imgFile;
              let textDesc = null;

              if (image.file_small !== null) {
                imgFile = image.file_small;
                textDesc = image.description;
              } else {
                imgFile = "";
                return textDesc;
              }

              // if (image.has_languages) {
              //   imgFile = image.file_small;
              //   textDesc = image.description;
              // } else {
              //   imgFile = "";
              //   return textDesc;
              // }

              imageArray.push([imgFile, textDesc]);
              // imageArray.push([image.file_small, image.description]);
            });
            break;
          case 1:
            fileArray = [];
            item.sections.widget_contents.forEach((file) => {
              let pdfFile, textDescFile;
              if (file.file !== null) {
                pdfFile = file.file;
                textDescFile = file.description;
              } else {
                pdfFile = "";
                textDescFile = "";
              }

              // if (file.has_languages) {
              //   pdfFile = file.file;
              //   textDescFile = file.description;
              // } else {
              //   pdfFile = "";
              //   textDescFile = "";
              // }
              fileArray.push([pdfFile, textDescFile]);
              //   fileArray.push([file.file, file.description]);
            });
            break;
          case 2:
            title = item.sections.widget_contents[0]
              ? item.sections.widget_contents[0].content
              : "Title";
            break;
          default:
            break;
        }
      });
      imageArray.forEach((item, index) => {
        var image = item[0];
        var file = fileArray[index] ? fileArray[index][0] : "";
        var desc = fileArray[index] ? fileArray[index][1] : "";
        // var description = fileArray[index] ? fileArray[index][1] : item[1];
        filePackage.push([image, file, desc]);
      });
    }

    var content = filePackage.map((item) => {
      return <Card
        // url={item[1]} 
        text={item[2]}
        imgUrl={item[0]}
        onClick={() => {
          this.setState({
            pdfFile: item[1],
            pdfTitle: item[2]
          }, () => {
            this.setState({ isShowPdfPreview: true, })
          })
        }}
      />;
    });

    return (
      <div className={classes.Section5}>
        <div className={["container", classes.customContainer].join(" ")}>
          <h2 className={classes.title}>{title}</h2>
        </div>
        <div id="mobile-sd-section5" className={classes.boxC}>
          <Slider content={content} />
        </div>

        <ModalPDF
          show={this.state.isShowPdfPreview}
          onHide={() => {
            this.setState({ isShowPdfPreview: false, pdfFile: "" })
          }}
          file={this.state.pdfFile}
          title={this.state.pdfTitle}
        />
      </div>
    );
  }
}
export default SD_Section5;
