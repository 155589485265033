import React, { Component } from "react";
import classes from "./Section1.module.css";
import Api from "./Api";

export default class Box extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: {
        widgets: [],
      },
      url: "",
      readMore: "Read More",
      isLoaded: false,
      isApiLoaded: true,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }
  componentDidMount() {
    if (this.props.id) {
      let param = this.props.id;
      this.getSectionDetail(param);
      this.setState({
        url: this.props.url,
      });
    }
  }

  getSectionDetail(param) {
    const apiData = new Api();
    this.setState({ contentArticle: [] }, () => {
      apiData
        .getSectionDetail(param)
        .then((result) => {
          const articles = result.data;
          const y = [];
          y.push({
            back: articles.widgets[0].widget_contents[0].content,
            url: articles.widgets[0].url,
            share: articles.widgets[1].widget_contents[0].content,
          });

          this.setState({
            article: y,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }
  render() {
    const { article } = this.state;

    let articleContent,
      returnBackElement,
      shareText = null;
    let prevLink = ""; //  localStorage.getItem("prevLink");

    if (this.state.isLoaded) {
      let backText = article[0].back ? article[0].back : "";
      let backUrl = article[0].url ? article[0].url : "/news/";
      shareText = article[0].share ? article[0].share : "";

      if (this.props.history) {
        returnBackElement = (
          <div
            onClick={() => this.props.history.goBack()}
            className={classes.images_goback}
          >
            <img src={require("./assets/Path235.svg")} alt="" />
            &nbsp; {backText}
          </div>
        );
      } else {
        returnBackElement = (
          // <a
          //   href={prevLink && prevLink !== "" ? prevLink : backUrl}
          //   className={classes.backLink}
          // >
          //   <FontAwesomeIcon className={classes.backIcon} icon={faArrowLeft} />
          //   <div>{backText}</div>
          // </a>

          /*cek */
          //   <a
          //     href={prevLink && prevLink !== "" ? prevLink : backUrl}
          //     className={classes.link}
          //   >
          //     <div className={classes.images_goback}>
          //       <img src={require("./assets/Path235.svg")} alt="" />
          //       &nbsp; {backText}
          //     </div>
          //   </a>
          <div className={classes.navBack}>
            <a href={backUrl} className={classes.customLink}>
              <div className={"d-flex"}>
                <div className={"d-flex"}>
                  <i
                    className={[
                      classes.arrow,
                      classes.left,
                      classes.customMargin,
                    ].join(" ")}
                  ></i>
                </div>
                <span className={classes.textBack}>{backText}</span>
              </div>
            </a>
          </div>
        );
      }
    }
    return <div className={classes.wrappbtnback}>{returnBackElement}</div>;
  }
}
