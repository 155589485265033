import React, { Component } from "react";
import Api from "../Api";
import { Slide } from "react-awesome-reveal";
import ArtBox from "../ArtBox";
import classes from "./Desktop.module.css";
import ContentR from "./ContentResponsive";
import Tablet from "../Tablet/Tablet";

class Desktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      boxCount: 0,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const widget = [];
          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              name: item.name,
              index: item.index,
              category: item.category,
              sections: item,
            });
          });

          sections.page = widget;
          this.setState({
            contentSection: widget,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
          window.location.assign(
            `/error/${error.response.status ? error.response.status : 404}`
          );
        });
    });
  }

  render() {
    const { contentSection } = this.state;
    let titleContent,
      contentDesc,
      contentBox,
      contentOverflow,
      InternalBox,
      readTextItem,
      isUrl = null;

    if (this.state.isLoaded) {
      let count = 0;
      contentSection.forEach((item) => {
        count = count + 1;
        if (count === 1 && item.index === 1 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              titleContent = (
                <h2 className={classes.sectionTitle}>{s.content}</h2>
              );
            } else {
              titleContent = <></>;
            }
          });
        } 

        if (count === 2 && item.index === 2 && item.category === "Text") {
          readTextItem = item.sections.widget_contents[0]
            ? item.sections.widget_contents[0].content
            : "Read More";
        }

        if (count === 3 && item.index === 3 && item.category === "Article") {
          contentDesc = item.sections.is_internal ? (
            <>{InternalBox}</>
          ) : (
            <ArtBox
              id={item.sections.id}
              url={item.sections.url}
              name={item.sections.name}
            />
          );
          InternalBox = item.sections.widget_contents.forEach((s, i) => {
            isUrl = s.article_language.url ? s.article_language.url : "/";
            let isImage = s.assets[0] ? (
              <img
                className={classes.imgOverflow}
                src={s.assets[0].file_big || s.assets[0].file}
                alt={s.assets[0].description}
              ></img>
            ) : (
              <img
                className={classes.imgOverflow}
                src={require("../assets/default-image.svg")}
                alt="Default image"
              />
            );

            let str = s.article_language.content.toString();
            let x = str.split("</p>");
            let content = x.map((x) => {
              let c = x.replace(/(<([^>]+)>)/gi, " ").replace(/&nbsp;/gi, " ");
              return <p>{c}</p>;
            });

            contentDesc = (
              <Tablet
                url={isUrl}
                title={s.article_language.title}
                content={content}
                rd={readTextItem}
              />
            );
            // (
            //   <>
            //     <Slide triggerOnce direction="top">
            //       <div className={classes.boxChild}>
            //         <p className={classes.title}>{s.title}</p>
            //         <p className={classes.contentt2}>{content}</p>
            //         <div className={classes.boxLinks}>
            //           <div className={classes.outerCircle2}>
            //             <div className={classes.innerCircle2}></div>
            //           </div>
            //           <Link to={isUrl} className={classes.link1}>
            //             <p className={classes.readMore}>{readTextItem}</p>
            //           </Link>
            //         </div>
            //       </div>
            //     </Slide>
            //   </Tablet>
            // );

            contentOverflow = (
              <Slide triggerOnce direction="top">
                {isImage}
              </Slide>
            );
          });
        } 

        if (count === 4 && item.index === 4 && item.category === "Article") {
          contentBox = <ContentR data={item.sections.widget_contents} />;
        }
      });
    } else if (this.state.isApiLoaded) {

    }
    return (
      <div className="section-content">
        <div className={classes.contentDetail}>
          <div className={["container", classes.customContainer].join(" ")}>
            <div className={classes.ppD}>
              <divc className={classes.bxsc4h}>
                <Slide triggerOnce direction="top">
                  {titleContent}
                </Slide>
                <div className={classes.bxsc4habs}>
                  <div className={classes.bxct4h}>{contentDesc}</div>
                  <div className={classes.bximg4h}>{contentOverflow}</div>
                </div>
              </divc>
            </div>
          </div>

          <div className={["container", classes.customContainer2].join(" ")}>
            {contentBox}
          </div>
        </div>
      </div>
    );
  }
}
export default Desktop;
