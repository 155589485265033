import React from "react";
import Desktop from "./Desktop/Desktop";
import Mobile from "./Mobile/Mobile";
import { isMobile, isTablet } from "react-device-detect";

export default function GS_Section1(props) {
  const parsedUrl = new URL(window.location.href);
  const searchQuery = parsedUrl.searchParams.get("search")
  
  let content;
  if (isTablet) {
    content = <Desktop param={searchQuery}/>;
  } else if (isMobile) {
    content = <Mobile param={searchQuery}/>;
  } else {
    content = <Desktop param={searchQuery}/>;
  }

  return <>{content}</>;
}