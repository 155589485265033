import React, { Component } from 'react';
import css from './CircleDiagram.module.scss';

class CircleDiagram extends Component {
    constructor(props) {
        super(props);
        
        this.classList = {
            3: css.three,
            4: css.four,
            5: css.five,
            6: css.six,
            7: css.seven,
            8: css.eight
        }
    }
    
    render() {
        const arrIcon2 = this.props.arrIcon2

        const dataCircle = this.props.dataScroll.map(() => {
            return <div className={css.circle}></div>;
        });


        const insideText = this.props.dataScroll.map((z, i) => {

            return (
                <div
                    className={css.circleBig}
                    onClick={() => this.props.toggleDetail(i + 1)}
                    onMouseEnter={() => this.props.toggleEnter(i + 1)}
                    onMouseLeave={() => this.props.toggleEnter(i + 1)}
                >
                    <img
                        src={arrIcon2[i]?.file ? arrIcon2[i].file : require("../../assets/default-image.svg")}
                        alt={arrIcon2[i]?.description ? arrIcon2[i].description : "Phenikaa"}
                        onClick={this.props.handleClick}
                    />
                    <div className={css.textBox}>
                        <p className={css.activeText}>{z}</p>
                    </div>
                </div>
            );
        });

        const classElement = this.classList[insideText.length] ? this.classList[insideText.length] : this.classList[5]

        return (
            <div className={[css.outerContent2, classElement].join(' ')}>
                {dataCircle}
                {insideText}
                {this.props.imageBackground}
            </div>
        );
    }
}

export default CircleDiagram;