import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Api from "../Api";
import UserContext from "../Context";
import { store } from "react-notifications-component";

import classes from "./Modal1.module.css";

// import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default class ModalLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: "",
      year: "",
      month: "",
      day: "",
      selectedYear: "",
      selectedMonth: "",
      selectedDay: "",
      selectedGender: "",
      listCountries: [],
      isLoaded: false,
      reqName1: null,
      reqName2: null,
      reqEmail: null,
      reqPwd: null,
      reqBirth: null,
      reqCon: null,
      reqGen: null,
      checkVal: null,
      input: {},
      isEmpty: false,
      errorTextData: [],
      isEnglish: false,
    };
    this.yearChange = this.yearChange.bind(this);
    this.monthChange = this.monthChange.bind(this);
    this.dayChange = this.dayChange.bind(this);
    this.genderChange = this.genderChange.bind(this);
    this.registerNewApplicant = this.registerNewApplicant.bind(this);
    this.getListCountries = this.getListCountries.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.checkEmail = this.checkEmail.bind(this);
    this.checkPwd = this.checkPwd.bind(this);
  }

  static contextType = UserContext;

  componentDidMount() {
    let year = [];
    let month = [];
    var d = new Date();
    var n = d.getFullYear();
    for (let index = n - 65; index <= n - 15; index++) {
      year.push(index);
    }
    for (let index = 1; index <= 12; index++) {
      month.push(index);
    }
    this.setState({
      year: year,
      month: month,
    });

    this.getListCountries();

    // create texts object
    let textObj = {};
    let textName = [
      "createAcc",
      "alreadyHaveAccount",
      "signIn",
      "firstName",
      "lastName",
      "email",
      "pwd",
      "day",
      "month",
      "year",
      "gender",
      "phone",
      "countryRegion",
      "createAccBtn",
      "dob",
      "terms",
      "male",
      "female",
    ];
    this.props.texts.forEach((txt, i) => {
      textObj[textName[i]] = txt.content;
    });
    this.setState({ texts: textObj });

    let lang = localStorage.getItem("language");

    if (lang === "English") {
      this.setState({
        isEnglish: true,
      });
    } else {
      this.setState({
        isEnglish: false,
      });
    }
  }

  componentWillReceiveProps(newProps) {
    // console.log("will received newProps => ", newProps);
    if (newProps.dataErrorText) {
      this.setState({
        errorTextData: newProps.dataErrorText,
      });
    }
  }

  getListCountries() {
    const countries = new Api();

    countries
      .getListCountries()
      .then((res) => {
        const data = res.data;
        const country = [];
        data.results.forEach((a) => {
          country.push({
            id: a.id,
            code: a.iso_code,
            name: a.name,
          });
        });
        this.setState({
          listCountries: country,
          isLoaded: true,
        });
      })
      .catch((err) => {
        console.log("error =>", err);
      });
  }

  checkEmail(param) {
    let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (!param.match(mailformat)) {
      return false;
    } else {
      return true;
    }
  }

  checkPwd(param) {
    let format = /^[a-zA-Z0-9]{8,}$/;
    if (param.length < 8) {
      return false;
    } else if (param.length >= 8) {
      if (param.match(format)) {
        return true;
      } else {
        return false;
      }
    }
  }

  submitForm(e) {
    e.preventDefault();
    if (this.checkValidity()) {
      this.registerNewApplicant();
    } else {
      this.setState({
        isEmpty: true,
        // reqBirth : "is required",
      });
    }
  }

  checkValidity() {
    // alert("val...");
    let isRequired, containMinimal, invalidAddres;

    this.state.errorTextData.forEach((ele, i) => {
      switch (i) {
        case 0:
          isRequired = ele.content;
          break;
        case 3:
          containMinimal = ele.content;
          break;
        case 4:
          invalidAddres = ele.content;
          break;
        default:
        // code block
      }
    });
    let isValid = true;
    const year = this.state.selectedYear;
    const month = this.state.selectedMonth;
    const day = this.state.selectedDay;
    let c = document.getElementById("country-select");
    const country = c.options[c.selectedIndex].value;
    const regData = {
      // username: document.getElementById("email").value,
      firstname: document.getElementById("fname").value,
      lastname: document.getElementById("lname").value,
      email: document.getElementById("email").value,
      password: document.getElementById("password").value,
      phone: document.getElementById("phone").value,
      birthdate: `${year}-${month}-${day}`,
      country: country,
    };

    if (!regData.firstname) {
      this.setState({
        reqName1: isRequired,
      });
      isValid = false;
    } else {
      this.setState({
        reqName1: null,
      });
    }

    if (!regData.firstname) {
      this.setState({
        reqName2: isRequired,
      });
      isValid = false;
    } else {
      this.setState({
        reqName2: null,
      });
    }

    if (!regData.email) {
      this.setState({
        reqEmail: isRequired,
      });
      isValid = false;
    } else if (regData.email) {
      if (this.checkEmail(regData.email)) {
        this.setState({
          reqEmail: null,
        });
        // return true
      } else {
        this.setState({
          reqEmail: invalidAddres,
        });
        isValid = false;
      }
    }

    if (!regData.password) {
      this.setState({
        reqPwd: isRequired,
      });
      isValid = false;
    } else if (regData.password) {
      if (this.checkPwd(regData.password)) {
        this.setState({
          reqPwd: null,
        });
        // return true;
      } else {
        this.setState({
          reqPwd: containMinimal,
        });
        isValid = false;
      }
    }

    if (!year) {
      this.setState({
        reqBirth: isRequired,
      });
      isValid = false;
    } else {
      this.setState({
        reqBirth: null,
      });
    }

    if (!month) {
      this.setState({
        reqBirth: isRequired,
      });
      isValid = false;
    } else {
      this.setState({
        reqBirth: null,
      });
    }
    if (!day) {
      this.setState({
        reqBirth: isRequired,
      });
      isValid = false;
    } else {
      this.setState({
        reqBirth: null,
      });
    }

    if (country === "x") {
      this.setState({
        reqCon: isRequired,
      });
      isValid = false;
    } else {
      this.setState({
        reqCon: null,
      });
    }

    return isValid;
  }

  registerNewApplicant() {
    let dontUsePassSame, alreadyRegistered;

    this.state.errorTextData.forEach((ele, i) => {
      switch (i) {
        case 1:
          dontUsePassSame = ele.content;
          break;
        case 2:
          alreadyRegistered = ele.content;
          break;
        default:
        // code block
      }
    });
    const api = new Api();
    const year = this.state.selectedYear;
    const month = this.state.selectedMonth;
    const day = this.state.selectedDay;
    const gender = this.state.selectedGender;

    let c = document.getElementById("country-select");
    let country = c.options[c.selectedIndex].value;

    const regData = {
      username: document.getElementById("email").value,
      firstname: document.getElementById("fname").value,
      lastname: document.getElementById("lname").value,
      email: document.getElementById("email").value,
      password: document.getElementById("password").value,
      phone: document.getElementById("phone").value,
      birthdate: `${year}-${month}-${day}`,
      gender: gender,
      country: country,
    };
    
    api
      .registerApplicant(regData)
      .then((res) => {
        const loginData = {
          email: res.data.user.email,
          password: regData.password,
        };

        store.addNotification({
          title: "Success!",
          message: "Registration Success!",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });

        api
          .getApplicant(loginData)
          .then((result) => {
            const data = result.data;
            if (data) {
              localStorage.setIteconsom("isLoggedIn", true);
              localStorage.setItem("token", data.key);
              localStorage.setItem("id", data.applicant);
              this.context.getNewDataApplicant(
                data.applicant,
                this.props.continueClicked
              );
            }
          })
          .catch((error) => {
            this.props.continueClicked();
          });
      })
      .catch((err) => {
        console.log(err);

        let errMessage = err.response.data;

        if (errMessage) {
          let errPwd = errMessage.password;
          let errEmail = errMessage ? errMessage[0] : "";

          if (
            errEmail ===
            "Someone with that email has already registered. Was it you?"
          ) {
            this.setState({
              reqEmail: alreadyRegistered,
            });
          } else if (errPwd) {
            this.setState({
              reqPwd: dontUsePassSame,
            });
          }
        }
      });
  }

  yearChange(e) {
    if (e.target.value !== "x") {
      document.getElementById("month-select").disabled = false;
      this.setState({
        selectedYear: e.target.value,
      });
    } else {
      document.getElementById("month-select").disabled = true;
    }
  }

  monthChange(e) {
    if (e.target.value !== "x") {
      let days = [];
      document.getElementById("day-select").disabled = false;

      let day = this.getDaysInMonth(this.state.selectedYear, e.target.value);
      for (let index = 1; index <= day; index++) {
        days.push(index);
      }
      this.setState({
        selectedMonth: e.target.value,
        day: days,
      });
      // var getDaysInMonth = function (month, year) {
      //     return new Date(year, month, 0).getDate();
      // };
    } else {
      document.getElementById("day-select").disabled = true;
    }
  }

  dayChange(e) {
    if (e.target.value !== "x") {
      this.setState({
        selectedDay: e.target.value,
      });
    } else {
    }
  }

  genderChange(e) {
    if (e.target.value !== "x") {
      this.setState({
        selectedGender: e.target.value,
      });
    }
  }

  getDaysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  render() {
    let contentYear, contentMonth, contentDay, countries;
    let texts = this.state.texts ? this.state.texts : {};

    if (this.state.isLoaded) {
      contentYear = this.state.year.map((y) => {
        return (
          <option value={y}>{y}</option>
          // <span>{y}</span>
        );
      });

      contentMonth = this.state.month.map((m) => {
        return <option value={m}>{m}</option>;
      });

      contentDay = this.state.day
        ? this.state.day.map((m) => {
            return <option value={m}>{m}</option>;
          })
        : "";

      countries = this.state.listCountries.map((c) => {
        return <option value={c.id}>{c.name}</option>;
      });
    }

    let isEmpty = this.state.isEmpty ? classes.isEmpty : "";

    return (
      <Modal
        size={"lg"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.show}
        onHide={this.props.close}
        backdrop={false}
      >
        <Modal.Header onClick={this.props.close} closeButton>
          <div className="d-flex justify-content-center flex-column">
            <span className={classes.modalTitle}>{texts.createAcc}</span>
            <div className="d-flex">
              <span className={classes.optionTerms}>
                {texts.alreadyHaveAccount}
              </span>
              <span className={classes.contentLink}>{texts.signIn}</span>
            </div>
          </div>
        </Modal.Header>
        <div className={classes.modalContent}>
          <div className={classes.content}>
            <div className={classes.contentWrapp}>
              <div className={classes.wrap1}>
                <div className={classes.boxContent}>
                  <span className={classes.titleContent}>
                    {texts.firstName}
                  </span>
                  <input
                    id="fname"
                    className={classes.inputModal}
                    type={"text"}
                    required
                    // defaultValue={"John"}
                  />
                </div>
                <p className={[classes.validError, isEmpty].join("")}>
                  {this.state.reqName1}
                </p>
              </div>

              <div className={classes.wrap2}>
                <div className={classes.boxContent}>
                  <span className={classes.titleContent}>{texts.lastName}</span>
                  <input
                    id="lname"
                    className={classes.inputModal}
                    type={"text"}
                    required
                    // defaultValue={"Doe"}
                  />
                </div>
                <p className={[classes.validError, isEmpty].join("")}>
                  {this.state.reqName2}
                </p>
              </div>
            </div>

            <div className={classes.contentWrapp}>
              <div className={classes.wrap1}>
                <div className={classes.boxContent}>
                  <span className={classes.titleContent}>{texts.email}</span>
                  <input
                    id="email"
                    className={classes.inputModal}
                    type={"text"}
                    required
                    // defaultValue={"google@johndoe.com"}
                  />
                </div>
                <p className={[classes.validError, isEmpty].join("")}>
                  {this.state.reqEmail}
                </p>
              </div>

              <div className={classes.wrap2}>
                <div className={classes.boxContent}>
                  <span className={classes.titleContent}>{texts.pwd}</span>
                  <input
                    id="password"
                    className={classes.inputModal}
                    type={"password"}
                    required
                  />
                </div>
                <p className={[classes.validError, isEmpty].join("")}>
                  {this.state.reqPwd}
                </p>
              </div>
            </div>

            <div className={classes.contentWrapp}>
              <div className={classes.wrap1}>
                <div className={classes.boxContent}>
                  <span className={classes.titleContent}>{texts.phone}</span>
                  <input
                    id="phone"
                    className={classes.inputModal}
                    type={"text"}
                  />
                </div>
                {/* <p className={[classes.validError, isEmpty].join("")}>
                  {this.state.reqPhone}
                </p> */}
              </div>
            </div>

            <span
              className={[classes.optionTerms, classes.optionTitle].join(" ")}
            >
              {texts.dob}
            </span>
            <div className={classes.contentDate}>
              <div className={classes.boxContent2}>
                <span className={classes.titleContent}>{texts.year}</span>

                <select
                  id="year-select"
                  className={classes.modalSelect}
                  onChange={this.yearChange}
                >
                  <option value={"x"}>yyyy</option>
                  {contentYear}
                </select>
                <p className={[classes.validError, isEmpty].join("")}>
                  {this.state.reqBirth}
                </p>
              </div>
              <div className={classes.boxContent2}>
                <span className={classes.titleContent}>{texts.month}</span>
                <select
                  id="month-select"
                  className={classes.modalSelect}
                  disabled
                  onChange={this.monthChange}
                >
                  <option value={"x"}>mm</option>
                  {contentMonth}
                </select>
                <p className={[classes.validError, isEmpty].join("")}>
                  {this.state.reqBirth}
                </p>
              </div>
              <div className={classes.boxContent2}>
                <span className={classes.titleContent}>{texts.day}</span>
                <select
                  id="day-select"
                  className={classes.modalSelect}
                  disabled
                  onChange={this.dayChange}
                >
                  <option value={"x"}>dd</option>
                  {contentDay}
                </select>
                <p className={[classes.validError, isEmpty].join("")}>
                  {this.state.reqBirth}
                </p>
              </div>

              <div className={classes.boxContent2}>
                <span className={classes.titleContent}>{texts.gender}</span>
                <select
                  className={classes.modalSelect}
                  onChange={this.genderChange}
                >
                  <option>--</option>
                  <option value={"Male"}>{texts.male}</option>
                  <option value={"Female"}>{texts.female}</option>
                </select>
                <p className={[classes.validError, isEmpty].join("")}>
                  {this.state.reqBirth}
                </p>
              </div>
            </div>

            <div
              className={[classes.boxContent2, classes.boxContentCountry].join(
                " "
              )}
            >
              <span className={classes.titleContent}>
                {texts.countryRegion}
              </span>
              <select id="country-select" className={classes.modalSelect}>
                <option value={"x"}>---</option>
                {countries}
              </select>
              <p className={[classes.validError, isEmpty].join("")}>
                {this.state.reqCon}
              </p>
            </div>

            <span className={["mt-4", classes.optionTerms].join(" ")}>
              {texts.terms}
            </span>
          </div>
          <div className={classes.modalAction}>
            <div
              className={[classes.boxAction, classes.boxRight].join(" ")}
              onClick={this.submitForm}
            >
              <span>{texts.createAccBtn}</span>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
