import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Api from "../Api";
import classes from "./ModalPassword.module.css";
import UserContext from "../Context";
// import Loading from "./Loading";

class ModalLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: false,
      isEmail: false,
      // loading: <Loading />,
    };
    this.resetPasswordEmail = this.resetPasswordEmail.bind(this);
    this.checkEmail = this.checkEmail.bind(this);
  }

  static contextType = UserContext;

  componentDidMount() {
    
  }

  checkEmail(param) {
    let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (!param.match(mailformat)) {
      return false;
    } else {
      return true;
    }
  }

  isValidate(email) {
    if (!email) {
      this.setState({
        reqEmail: "* Email is required",
      });
      return false;
    } else if (email) {
      if (this.checkEmail(email)) {
        this.setState({
          reqEmail: null,
        });
        return true;
      } else {
        this.setState({
          reqEmail: "* Email invalid address",
        });
        return false;
      }
    }
  }

  resetPasswordEmail() {
    const reset = new Api();
    const email = document.getElementById("m-email").value;

    const data = {
      email: email,
      template: this.props.template,
      language: localStorage.getItem('language') === "English" ? "en" : "vi"
    }

    if (this.isValidate(email)) {
      reset
        .resetPasswordEmail(data)
        .then((res) => {
          //   const data = res.data;
          this.setState({
            isEmail: false,
          });
          setTimeout(() => {
            this.props.close2();
          }, 0);
        })
        .catch((err) => {
          console.log(err);
          let errEmail = err.response.data.email
            ? err.response.data.email[0]
            : "";
          if (errEmail) {
            this.setState({
              reqEmail:
                "* The email you entered did not match our records. Please double-check and try again.",
            });
          } else {
            this.setState({
              isEmail: false,
            });
          }
        });
    }
  }

  render() {
    let errorEmail = this.state.isEmail ? classes.errorActive : "";
    return (
      <Modal
        size={"md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.show}
        onHide={this.props.close}
      >
        <Modal.Header>
          <span className={classes.modalTitle}>Reset Password</span>
        </Modal.Header>
        <div className={classes.modalContent}>
          <div className={classes.content}>
            <div className={classes.boxContent}>
              <span className={classes.titleContent}>Email address</span>
              <input
                id="m-email"
                className={classes.inputModal}
                type={"text"}
                defaultValue={""}
              />
            </div>
            <span className={[classes.validError, errorEmail].join("")}>
              {this.state.reqEmail}
            </span>
            <div className={classes.contentAction}>
              <div className={classes.cancel} onClick={this.props.loginClicked}>
                <span>Cancel</span>
              </div>
              <div
                className={classes.boxAction}
                onClick={() => {
                  this.resetPasswordEmail();
                }}
              >
                <span>Continue</span>
              </div>
            </div>
          </div>
        </div>
        {/* {this.state.loading} */}
      </Modal>
    );
  }
}

export default ModalLogin;
