import React, { Component } from "react";

import { isMobile, isTablet } from "react-device-detect";
import Api from './Api'

import classes from './CC_Banner.module.css'
export default class CC_Banner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gallery: false,
            title: '',
            titleColor: '#000000',
            viewMore: '',
            default: 6,
            pagination: 18,
            isLoaded: false,
            isApiLoaded: false
        };
        this.getDataGallery = this.getDataGallery.bind(this);
        this.loadMoreGallery = this.loadMoreGallery.bind(this);
    }

    componentDidMount() {
        this.getDataGallery()

        let width = window.innerWidth

        if (isTablet && width <= 768){
            this.setState({
                default: 4,
                pagination: 20
            })
        } else if (isTablet && width <= 1024){
            this.setState({
                default: 4,
                pagination: 20
            })
        } else if (isMobile){
            this.setState({
                default: 2,
                pagination: 8
            })
        } else {
            this.setState({
                default: 6,
                pagination: 18
            })
        }
    }

    getDataGallery() {
        const apiData = new Api();
        this.setState({ gallery: [] }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    const sections = result.data;
                    const widget = [];
                    let title, viewmore, titleColor
                    // sections.widgets[0].widget_contents.forEach((item) => {
                    //     widget.push({
                    //         file: item.file_medium,
                    //         desc: item.description,
                    //         index: item.index
                    //     });
                    // });
                    sections.widgets.forEach((item, i) => {
                        if (i === 0){
                            item.widget_contents.forEach((x) => {
                                widget.push({
                                    file: x.file_medium,
                                    desc: x.description,
                                    index: x.index
                                });
                            })
                        }
                        if (i === 1){
                            title = item.widget_contents[0] ? item.widget_contents[0].content : 'Company Culture'
                            titleColor = item.widget_contents[0] ? item.widget_contents[0].font_color : '#000000'
                        }
                        if (i === 2){
                            viewmore = item.widget_contents[0] ? item.widget_contents[0].content : 'View more'
                        }
                    });

                    sections.page = widget;
                    this.setState({
                        gallery: widget,
                        title: title,
                        titleColor: titleColor,
                        viewMore: viewmore,
                        isLoaded: true
                    });
                })
                .catch((error) => {
                    this.setState({
                        isApiLoaded: true,
                    });
                });
        });
    }

    loadMoreGallery() {
        this.setState((prevState) => {
            return { pagination: prevState.pagination + this.state.default };
        });
    }

    render() {
        const { gallery } = this.state

        let content, title, viewMore
        if (this.state.isLoaded){
            content = gallery.slice(0, this.state.pagination).map(g =>{
                return (
                    <div className={classes.box}>
                        <div className={classes.backgroundOverflow}></div>
                        <figure>
                            <img src={g.file} alt={g.desc}/>
                        </figure>
                    </div>
                )
            })

            title = 
            <div className={classes.boxTitle}>
                <h1 className={classes.title} style={{color: this.state.titleColor}}>{this.state.title}</h1>
            </div>

            viewMore = gallery.length !== this.state.pagination ? 
            <div className={classes.viewMore} onClick={this.loadMoreGallery}>
                {/* <div className={classes.contentViewMore}>
                    <div className={classes.outerCircle}>
                        <div className={classes.innerCircle}></div>
                    </div>
                    <span>{this.state.viewMore}</span>
                </div> */}
            </div>:
            <></>
        } else if (this.state.isApiLoaded){

        }

        return (
            <div className={classes.gallery}>
                <div className={classes.contentGallery}>
                    {content}
                </div>
                {title}
                {viewMore}
            </div>
        );
    }
}
