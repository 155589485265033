import React, { Component } from "react";
import { Modal } from "react-bootstrap";

import UserContext from "../../../../Context";

import Api from "../../../../../Api";
import classes from "./Modal.module.css";

export default class ModalName extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      id: 0,
      isContent: false,
      data: [],
    };
  }

  componentDidMount() {
    const contextValue = this.context;

    this.setState({
      isLoggedIn: contextValue.isLoggedIn,
      id: contextValue.idApplicant,
      data: contextValue.contentSection[9].sections.widget_contents,
    });
  }

  isValidate() {
    let content;

    if (this.props.content === "bio") {
      content = document.getElementById("m-bio").value;
      console.log(content);
    } else if (this.props.content === "address") {
      content = document.getElementById("m-address").value;
    } else if (this.props.content === "phone") {
      content = document.getElementById("m-phone").value;
    }

    if (content !== "") {
      return true;
    } else {
      this.setState({
        isContent: true,
      });
      return false;
    }
  }

  updateProfile() {
    const update = new Api();
    const contextValue = this.context;

    if (this.isValidate()) {
      let updateData;
      if (this.props.content === "bio") {
        updateData = {
          address: document.getElementById("m-bio").value,
        };
      }
      // else if (this.props.content === "address") {
      //   updateData = {
      //     address: document.getElementById("m-address").value,
      //   };
      // }
      else if (this.props.content === "phone") {
        updateData = {
          phone: document.getElementById("m-phone").value,
        };
      }

      update
        .updateApplicant(this.state.id, updateData, this.props.content)
        .then((res) => {
          this.props.close();
          contextValue.getNewDataApplicant();
          contextValue.showNotification(
            `Your ${this.props.content} has been successfully updated.`
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  render() {
    let labelTitle, content, contentId;
    let bio, cancel, save, phone_number;
    if (this.state.data) {
      this.state.data.forEach((x, i) => {
        switch (i) {
          case 7:
            bio = x.content;
            break;
          case 9:
            phone_number = x.content;
            break;
          case 12:
            cancel = x.content;
            break;
          case 13:
            save = x.content;
            break;
          default:
        }
      });
    }
    if (this.props.content) {
      switch (this.props.content) {
        case "bio":
          labelTitle = bio;
          content = "bio";
          contentId = "m-bio";
          break;
        // case "address":
        //   labelTitle = "Address";
        //   content = "first_name";
        //   contentId = "m-address";
        //   break;
        case "phone":
          labelTitle = phone_number;
          content = "phone";
          contentId = "m-phone";
          break;
        default:
          break;
      }
    }

    let isError = this.state.isContent ? classes.errorVisible : "";
    return (
      <Modal
        size={"md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.show}
        onHide={this.props.close}
      >
        <Modal.Header
          className={classes.modalHeader}
          onClick={this.props.close}
          closeButton
        >
          <span className={classes.modalTitle}>{labelTitle}</span>
        </Modal.Header>
        <UserContext.Consumer>
          {
            // context => console.log(context)
            (context) => (
              <div className={classes.modalContent}>
                <div className={classes.content}>
                  <div className={classes.boxContent}>
                    <span className={classes.titleContent}>{labelTitle}</span>
                    {/* <input id="m-fname" className={classes.inputModal} type={"text"} defaultValue={context.dataApplicant.user ? context.dataApplicant.user[0].firstname : ''} /> */}
                    <input
                      id={contentId}
                      className={classes.inputModal}
                      type={"text"}
                      defaultValue={
                        context.dataApplicant.user
                          ? content === "bio"
                            ? context.dataApplicant.address
                            : content === "phone"
                            ? context.dataApplicant.phone
                            : ""
                          : ""
                      }
                    />
                  </div>
                  <span className={[classes.contentError, isError].join(" ")}>
                    This field is required
                  </span>
                </div>
                <div className={classes.modalAction}>
                  <div className={classes.cancel}>
                    <span>{cancel}</span>
                  </div>
                  <div
                    className={classes.save}
                    onClick={() => {
                      this.updateProfile();
                    }}
                  >
                    <span>{save}</span>
                  </div>
                </div>
              </div>
            )
          }
        </UserContext.Consumer>
      </Modal>
    );
  }
}
