import * as React from "react";
import useScrollPosition from "@react-hook/window-scroll";
import clases from "./Navbar.module.css";
import classes from "../Career.module.css";

export default function Navbar(props) {
  const scrollY = useScrollPosition(60);
  let nav,
    sticky,
    filters,
    colorGrid,
    colorList,
    title,
    search = null;

  if (props.widget) {
    props.widget.forEach((ele, i) => {
      if (i === 0) {
        title = ele.content;
      } else if (i === 4) {
        search = ele.content;
      }
    });
  }
  if (props.cat) {
    filters = props.cat.map((f) => {
      return <option value={f}>{f}</option>;
    });
  }

  if (scrollY > "100") {
    let now = new Date();
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let dateNow = now.toLocaleDateString("vi", options);
    sticky = clases.sticky;
    nav = (
      <div className={["d-flex flex-row", classes.customNavbar].join(" ")}>
        {/* <p className={clases.navbrand2}>{title}</p> */}
        <select
          name="filter"
          id="filter"
          className={classes.customSelect}
          onChange={props.onChange}
        >
          {filters}
        </select>
        <p className={clases.date2}>{dateNow}</p>
        <div className={classes.wrap_option}>
          <div className={classes.grid}>{colorGrid}</div>
          <div className={classes.list}>{colorList}</div>
        </div>
        <div>
          <label className={classes.box_search}>
            <input
              onKeyDown={props.onKeyDown}
              className={classes.input_search}
              type="text"
              placeholder={search}
              name="search"
            />
            <img
              className="pull-right"
              src={require("../assets/search.svg")}
              alt=""
            />
          </label>
        </div>
      </div>
    );
  } else {
    let now = new Date();
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let dateNow = now.toLocaleDateString("vi", options);
    sticky = "";
    nav = (
      <>
        <p className={clases.navbrand}>{title}</p>
        <div className={[clases.boxNavbar].join(" ")}>
          <select name="filter" id="filter" onChange={props.onChange}>
            <option value="">Tất cả</option>
            {filters}
          </select>
          <p className={clases.date}>{dateNow}</p>
          {/* <div className={clases.colorGridWhite}>{colorGrid2}</div>
          <div className={clases.colorListWhite}>{colorList2}</div> */}
          <div className={clases.boxSearch}>
            <input
              onKeyDown={props.onKeyDown}
              className={clases.inputSearch}
              type="text"
              placeholder={search}
            />
          </div>
        </div>
      </>
    );
  }
  return (
    <div className={[clases.customNavbar, sticky].join(" ")}>
      <div className={clases.navbar}>{nav}</div>
    </div>
  );
}
