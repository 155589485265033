import React from "react";

export default function SectionList(props) {
    const [array] = React.useState(props.sections);
    const [displayArray, setDisplayArray] = React.useState([]);
    const [displayEl, setDisplayEl] = React.useState();

    const delay = (ms) =>
        new Promise((res) => {
            setTimeout(() => {
                res();
            }, ms);
        });

    React.useEffect(() => {
        (async function () {
            for (let el of array) {
                await delay(200);
                setDisplayEl(el);
            }
            setDisplayEl(undefined);
        })();
    }, [array]);

    React.useEffect(() => {
        displayEl && setDisplayArray((prev) => [...prev, displayEl]);
    }, [displayEl]);

    return <>{displayArray.map((elem, key) => elem)}</>;
}
