import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import classes from "./Mobile.module.css";
import Api from "./../Api";
import Box from "./../Box";
import BoxImage from "./../BoxImage";
import BoxTitle from "./../BoxTitle";
export default class Mobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dummy: [
        {
          id: 1,
          name: "image",
          title: "Lorem ipsum dummy title content",
          content:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem" +
            " Ipsum has been the industry's standard dummy text ever since the 1500s, when an" +
            " unknown printer took a galley of type and scrambled it to make a type specimen " +
            "book. It has survived not only five centuries, but also the leap into electronic" +
            " typesetting, remaining essentially unchanged. It was popularised in the 1960s w" +
            "ith the release of Letraset sheets containing Lorem Ipsum passages, and more rec" +
            "ently with desktop publishing software like Aldus PageMaker including versions o" +
            "f Lorem Ipsum.",
          asset: require("./../assets/default-image.svg"),
        },
        {
          id: 2,
          name: "title",
          content: "Lorem ipsum",
        },
      ],
      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: true,
      activeImg: 0,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState(
      {
        contentSection: [],
      },
      () => {
        apiData
          .getSectionDetail()
          .then((result) => {
            const sections = result.data;
            const widget = [];
            result.data.widgets.forEach((item) => {
              widget.push({
                id: item.id,
                index: item.index,
                category: item.category,
                name: item.name,
                type: item.is_internal,
                sections: item,
              });
            });

            sections.page = widget;
            this.setState({ contentSection: widget, isLoaded: true });
          })
          .catch((error) => {
            this.setState({ isApiLoaded: true });
          });
      }
    );
  }

  toggleExtends = (i) => {
    this.setState({ activeImg: i });
  };

  render() {
    const { contentSection, dummy, isLoaded, isApiLoaded } = this.state;

    let title,
      dummy_title,
      dummy_image_art,
      box_article,
      dummy_box_article,
      title_banner,
      img_article,
      dummy_title_banner,
      gallery,
      link = null;

    if (isLoaded) {
      let count = 0;
      contentSection.forEach((item) => {
        count = count + 1;
        if (count === 1 && item.index === 1 && item.category === "Article") {
          if (item.type === false) {
            title = <BoxTitle id={item.id} />;
            box_article = <Box id={item.id} />;
            img_article = <BoxImage id={item.id} />;
          }
        } else if (
          count === 1 &&
          item.index === 1 &&
          item.category !== "Article"
        ) {
          box_article = dummy_box_article;
        }
        if (count === 2 && item.index === 2 && item.category === "Text") {
          item.sections.widget_contents.forEach((ele) => {
            title_banner = (
              <span className={classes.textInside}>
                <h1>{ele.content}</h1>
              </span>
            );
          });
        }

        if (count === 3 && item.index === 3 && item.category === "Media") {
          gallery = item.sections.widget_contents.map((s, i) => {
            let active = this.state.activeImg === i ? classes.activeImg : "";
            return (
              <div
                className={[classes.boxGallery, active].join(" ")}
                onClick={() => this.toggleExtends(i)}
              >
                <figure>
                  <img src={s.file_small} alt=""></img>
                </figure>
              </div>
            );
          });
        }

        if (count === 4 && item.index === 4 && item.category === "Text") {
          link = item.sections.widget_contents.map((ele) => {
            return (
              <div className={classes.navBack}>
                <a href={item.sections.url} className={classes.backLink}>
                  <div className={"d-flex"}>
                    <i
                      className={[
                        classes.arrow,
                        classes.left,
                        classes.customMargin,
                      ].join(" ")}
                    ></i>
                  </div>
                  {/* <FontAwesomeIcon className={classes.backIcon} icon={faArrowLeft} /> */}
                  <span>{ele.content}</span>
                </a>
              </div>
              // <div className={classes.navBack}>
              //     <Link
              //         className={classes.customLink}
              //         to={item.sections.url
              //         ? item.sections.url
              //         : '/sustainable'}>
              //         <div className={'d-flex'}>
              //             <div className={'d-flex'}>
              //                 <i className={[classes.arrow, classes.left, classes.customMargin].join(" ")}></i>
              //             </div>
              //             <span className={classes.textBack}>{ele.content
              //                     ? ele.content
              //                     : 'Back to prev'}</span>
              //         </div>
              //     </Link>
              // </div>
            );
          });
        } else if (
          count === 4 &&
          item.index === 4 &&
          item.category !== "Text"
        ) {
          let prevLink = ""; //  localStorage.getItem('prevLink')

          link = (
            <div className={classes.navBack}>
              <a
                className={classes.customLink}
                href={
                  prevLink && prevLink !== ""
                    ? prevLink
                    : item.sections.url
                    ? item.sections.url
                    : "/sustainable"
                }
              >
                <div className={"d-flex"}>
                  <div className={"d-flex"}>
                    <i
                      className={[
                        classes.arrow,
                        classes.left,
                        classes.customMargin,
                      ].join(" ")}
                    ></i>
                  </div>
                  <span className={classes.textBack}>Back to prev</span>
                </div>
              </a>
            </div>
          );
        }
      });
    } else if (isApiLoaded) {
      title = dummy_title;
      box_article = dummy_box_article;
      title_banner = dummy_title_banner;
      img_article = dummy_image_art;
    }

    return (
      <div className="" style={{}}>
        <div className={classes.mainDev}>
          <div className={classes.boxImage}>
            {img_article}
            <div className={classes.contentInBox}>
              {title_banner}
              <div className={[classes.p_0_underline]}></div>
            </div>
          </div>
          <div
            className={[
              "container",
              classes.boxC,
              classes.customContainer,
            ].join(" ")}
          >
            <div className={classes.boxLeft}>
              {title}
              {box_article}
            </div>
            <div className={classes.boxRight}></div>
            <div className={classes.wrapGallery}>{gallery}</div>
            {link}
          </div>
        </div>
      </div>
    );
  }
}
