import React from "react";
import Desktop from "./Desktop/Desktop";
import Mobile from "./Mobile/Mobile";
import { isMobile, isTablet } from "react-device-detect";
import { useMediaQuery } from "react-responsive";

export default function IN_Section2() {
    let content;

    const isM = useMediaQuery({
        query: "(min-device-width: 411px) and (max-device-width: 479px)",
    });

    if (isTablet) {
        content = <Desktop />;
    } else if (isMobile || isM) {
        content = <Mobile />;
    } else {
        content = <Desktop />;
    }

    return <>{content}</>;
}
