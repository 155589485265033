import React, { Component, createRef } from "react";
import { store } from "react-notifications-component";
import ReactNotification from "react-notifications-component";
import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/core";
import { AiFillLinkedin, AiFillYoutube, AiFillFacebook } from "react-icons/ai";

import Api from "../Api";
import classes from "./Mobile.module.css";
class Mobile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dummy: [],

			contentSection: {
				widgets: [],
			},
			isLoaded: false,
			isApiLoaded: false,
			isLoading: false,
			isName: false,
			isEmail: false,
			isPhone: false,
			isSubject: false,
			isMessage: false,
			templateId: 0,

			name: null,
			email: null,
			phone: null,
			message: null,
			subject: null,
		};
		this.getSectionDetail = this.getSectionDetail.bind(this);
		this.scrollingHandlerCu = this.scrollingHandlerCu.bind(this);
		this.handleOverride = this.handleOverride.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.sendAnEmail = this.sendAnEmail.bind(this);
		// this.isValidate = this.isValidate.bind(this);
		this.showNotification = this.showNotification.bind(this);

		this.onChangeHandle = this.onChangeHandle.bind(this);
	}

	componentDidMount() {
		this.getSectionDetail();
		window.addEventListener("scroll", this.scrollingHandlerCu, true);
		this.handleOverride();
	}

	onChangeHandle(e, key) {
		if (key === "name") {
			this.setState({
				name: e,
			});
		} else if (key === "email") {
			this.setState({
				email: e,
			});
		} else if (key === "phone") {
			this.setState({
				phone: e,
			});
		} else if (key === "subject") {
			this.setState({
				subject: e,
			});
		} else if (key === "message") {
			this.setState({
				message: e,
			});
		}
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.scrollingHandlerCu, true);
	}

	cu_sec1_div1 = createRef();
	cu_sec1_div2 = createRef();

	handleOverride() {
		this.props.override(false);
	}

	scrollingHandlerCu() {
		// console.log(this.state.currentSection, this.state.darkLogo)
		if (this.cu_sec1_div1.current.getBoundingClientRect().bottom < this.cu_sec1_div1.current.getBoundingClientRect().height && this.cu_sec1_div1.current.getBoundingClientRect().bottom > -1) {
			this.props.updateLogo(true);
			// this.setState({
			//     currentSection: "Section1",
			//     darkLogo: false
			// })
		}
		//if(this.section2.current.getBoundingClientRect().y < 100 && this.section2.current.getBoundingClientRect().bottom > 100){
		if (this.cu_sec1_div2.current.getBoundingClientRect().bottom < this.cu_sec1_div2.current.getBoundingClientRect().height && this.cu_sec1_div2.current.getBoundingClientRect().bottom > -1) {
			this.props.updateLogo(false);
			// this.setState({
			//     currentSection: "bs_sec1_div1",
			//     darkLogo: true
			// })
		}
	}

	isValidate() {
		var isValid = true;
		const { isName, isPhone, isSubject, isMessage, isEmail } = this.state;

		const emailData = {
			name: document.getElementById("c-name").value,
			email: document.getElementById("c-email").value,
			phone: document.getElementById("c-phone").value,
			subject: document.getElementById("c-subject").value,
			message: document.getElementById("c-message").value,
		};

		if (emailData.name === "") {
			this.setState({
				isName: true,
			});
			isValid = false;
		} else {
			this.setState({
				isName: false,
			});
		}

		if (emailData.email === "") {
			this.setState({
				isEmail: true,
				errorContent: "* This field is required",
			});
			isValid = false;
		} else {
			if (this.checkEmail(emailData.email)) {
				this.setState({
					// errorContent: "* Invalid Email address",
					isEmail: false,
				});

				// isValid = false;
			} else {
				this.setState({
					errorContent: "* Invalid Email address",
					isEmail: true,
				});
				isValid = false;
			}
		}

		if (emailData.phone === "") {
			this.setState({
				isPhone: true,
			});
			isValid = false;
		} else {
			this.setState({
				isPhone: false,
			});
		}

		if (emailData.subject === "") {
			this.setState({
				isSubject: true,
			});
			isValid = false;
		} else {
			this.setState({
				isSubject: false,
			});
		}

		if (emailData.message === "") {
			this.setState({
				isMessage: true,
			});
			isValid = false;
		} else {
			this.setState({
				isMessage: false,
			});
		}

		if (isName && isMessage && isEmail && isPhone && isSubject) {
			return isValid;
		} else {
			return false;
		}
	}

	submitForm(e) {
		e.preventDefault();

		const emailData = {
			name: document.getElementById("c-name").value,
			email: document.getElementById("c-email").value,
			phone: document.getElementById("c-phone").value,
			subject: document.getElementById("c-subject").value,
			message: document.getElementById("c-message").value,
		};
		if (this.isValidate()) {
			this.setState({ isLoading: true }, () => {
				this.sendAnEmail();
			});
		} else {
			if (emailData.name !== "" && emailData.email !== "" && emailData.phone !== "" && emailData.subject !== "" && emailData.message !== "") {
				this.setState(
					{
						isLoading: true,
					},
					() => {
						this.sendAnEmail();
					}
				);
			} else {
				this.setState({
					isLoading: false,
				});
			}
		}
	}

	sendAnEmail() {
		const send = new Api();
		// const { name, email, phone, subject, message } = this.state;
		// this.setState({
		//     isLoading: true,
		// });

		let lang = localStorage.getItem("language");
		let langs;

		if (lang === "English") {
			langs = "en";
		} else {
			langs = "vi";
		}

		const recipient = [1]; // recipient anies@baezeni
		const emailData = {
			name: document.getElementById("c-name").value,
			email: document.getElementById("c-email").value,
			phone: document.getElementById("c-phone").value,
			subject: document.getElementById("c-subject").value,
			message: document.getElementById("c-message").value,
			language: langs,
			recipients: recipient,
		};

		send.sendAnEmail(emailData, this.state.templateId)
			.then((res) => {
				this.setState({
					isLoading: false,
				});
				this.showNotification("Your email has been sent.");
			})

			.catch((err) => {
				console.log(err);
				this.showNotification(`${err.response.statusText}`);
			});
	}

	showNotification(message) {
		store.addNotification({
			title: "Success!",
			message: message,
			type: "phenikaa",
			insert: "top",
			container: "top-right",
			animationIn: ["animate__animated", "animate__fadeIn"],
			animationOut: ["animate__animated", "animate__fadeOut"],
			dismiss: {
				duration: 3000,
				onScreen: true,
			},
		});
	}

	getSectionDetail() {
		const apiData = new Api();
		this.setState({ contentSection: [] }, () => {
			apiData
				.getSectionDetail()
				.then((result) => {
					const sections = result.data;
					const widget = [];
					const id = sections.template;
					result.data.widgets.forEach((item) => {
						widget.push({
							id: item.id,
							index: item.index,
							category: item.category,
							name: item.name,
							sections: item,
						});
					});

					sections.page = widget;
					this.setState({
						contentSection: widget,
						isLoaded: true,
						templateId: id,
					});
				})
				.catch((error) => {
					console.log(error);
					this.setState({
						isApiLoaded: true,
					});
				});
		});
	}

	render() {
		const { contentSection } = this.state;

		let title, email, number, subject, content, btn, guest, emailTitle, phoneTitle, officeTitle, factoryTitle;
		let contact, office, factory, emailS, phone, company, img, banner;

		if (this.state.isLoaded) {
			contentSection.forEach((c, i) => {
				switch (i) {
					case 0:
						c.sections.widget_contents.forEach((s, x) => {
							switch (x) {
								case 0:
									title = s.content;
									break;
								case 1:
									email = s.content;
									break;
								case 2:
									number = s.content;
									break;
								case 3:
									subject = s.content;
									break;
								case 4:
									content = s.content;
									break;
								case 5:
									// error = s.content;
									break;
								case 6:
									btn = s.content;
									break;
								case 7:
									guest = s.content;
									break;
								default:
									break;
							}
						});
						break;
					case 1:
						officeTitle = c.sections.widget_contents[0].content;
						factoryTitle = c.sections.widget_contents[1].content;
						break;
					case 2:
						emailTitle = c.sections.widget_contents[0].content;
						phoneTitle = c.sections.widget_contents[1].content;
						break;
					case 3:
						c.sections.widget_contents.forEach((s, x) => {
							switch (x) {
								case 0:
									contact = s.content;
									break;
								case 1:
									let addressOffice = s.content;
									office = (
										<div className={classes.officeDiv}>
											<p className={classes.smallAnchor}>{officeTitle}</p>
											<p className={classes.address}>{addressOffice}</p>
										</div>
									);
									break;
								case 2:
									let addressFactory = s.content;

									factory = (
										<div className={classes.factoryDiv}>
											<p className={classes.smallAnchor}>{factoryTitle}</p>
											<p className={classes.address}>{addressFactory}</p>
										</div>
									);
									break;
								case 3:
									emailS = (
										<div>
											<p className={[classes.label, classes.top].join(" ")}>{emailTitle}</p>
											<p className={[classes.text, classes.email].join(" ")}>{s.content}</p>
										</div>
									);
									break;
								case 4:
									phone = (
										<div>
											<p className={classes.label}>{phoneTitle}</p>
											<p className={classes.text}>{s.content}</p>
										</div>
									);
									break;
								case 5:
									// fax = (
									//   <div>
									//     <p className={classes.label}>{s.name}</p>
									//     <p className={classes.text}>{s.content}</p>
									//   </div>
									// );
									break;
								case 6:
									// qr = s.content;
									break;
								case 7:
									company = s.content;
									break;
								default:
									break;
							}
						});
						break;

					case 4:
						c.sections.widget_contents.forEach((s, x) => {
							let file = s.file ? s.file : require("../assets/Image 40.png");

							img = <img className={classes.imgQR} src={file} alt=" " />;
						});
						break;
					case 5:
						banner = c.sections.widget_contents[0] ? c.sections.widget_contents[0].file_big : require("../assets/default-image.svg");
						break;
					default:
						break;
				}
			});
		} else if (this.state.isApiLoaded) {
			office = (
				<div className={classes.officeDiv}>
					<p className={classes.smallAnchor}>Off</p>
					<p className={classes.address}>Office</p>
				</div>
			);

			factory = (
				<div className={classes.factoryDiv}>
					<p className={classes.smallAnchor}>Fact</p>
					<p className={classes.address}>Factory</p>
				</div>
			);

			emailS = (
				<div>
					<p className={[classes.label, classes.top].join(" ")}>Name</p>
					<p className={[classes.text, classes.email].join(" ")}>Email</p>
				</div>
			);

			phone = (
				<div>
					<p className={classes.label}>Phone</p>
					<p className={classes.text}>+6222222009</p>
				</div>
			);

			// fax = (
			//   <div>
			//     <p className={classes.label}>Fax</p>
			//     <p className={classes.text}>xxx@phenikaa.com</p>
			//   </div>
			// );

			// qr = "QR";
			company = "Phenikaa";
			img = <img className={classes.imgQR} src={require("../assets/Image 40.png")} alt=" " />;
		}

		let isError1 = this.state.isName ? classes.errorVisible : "";
		let isError2 = this.state.isEmail ? classes.errorVisible : "";
		let isError3 = this.state.isPhone ? classes.errorVisible : "";
		let isError4 = this.state.isSubject ? classes.errorVisible : "";
		let isError5 = this.state.isMessage ? classes.errorVisible : "";

		const override = css`
			display: block;
			margin: 0 auto;
		`;
		return (
			<div className={classes.prBox}>
				<ReactNotification
					types={[
						{
							htmlClasses: [classes.phenikaaNotif],
							name: "phenikaa",
						},
					]}
				/>
				<div className={["sweet-loading", classes.sweetLoading].join(" ")}>
					<BeatLoader css={override} size={30} margin={2} color={"#000"} loading={this.state.isLoading} />
				</div>
				<div ref={this.cu_sec1_div1} className={classes.headMapContainer}>
					<div className={classes.frontMap}></div>
					<img className={[classes.headMapImg].join(" ")} src={banner} alt=""></img>
				</div>
				<div ref={this.cu_sec1_div2} className={["container", classes.mWid].join(" ")}>
					<div className="row">
						<div className="col-md-6">
							<form action="" method="" className={classes.sendMessageSection}>
								<h2 className={classes.heading}>{title}</h2>
								<input id="c-name" className={classes.input1} type="text" placeholder={guest} />
								<span className={[classes.contentError, isError1].join(" ")}>* this field is required</span>
								<input id="c-email" className={classes.input} type="text" placeholder={email} />
								<span className={[classes.contentError, isError2].join(" ")}>{this.state.errorContent}</span>

								<input id="c-phone" className={classes.input} type="text" placeholder={number} />
								<span className={[classes.contentError, isError3].join(" ")}>* this field is required</span>
								<input id="c-subject" className={classes.input} type="text" placeholder={subject} />
								<span className={[classes.contentError, isError4].join(" ")}>* this field is required</span>
								<textarea id="c-message" className={classes.bigInput} placeholder={content} type="text" />
								<span className={[classes.contentError, isError5].join(" ")}>* this field is required</span>

								<div className={classes.btnSend} onClick={this.submitForm}>
									<span>{btn}</span>
								</div>
							</form>
						</div>
						<div className={["col-md-6", classes.joinCss].join(" ")}>
							<h2 className={classes.heading}>{contact}</h2>
							<p className={classes.companyName}>{company}</p>

							{office}
							{factory}

							<div className={classes.thirdDiv}>
								<div className={classes.QRCode}>{img}</div>
								<div className={classes.emailPhoneFaxContainer}>
									{emailS}
									{phone}

									<div className={classes.footerSocialMedia}>
										<a href="https://www.facebook.com/phenikaagroup" target="_blank" rel="noopener noreferrer">
											<AiFillFacebook color="#0674E8" />
										</a>
										<a href="https://www.youtube.com/c/PHENIKAAGROUP" target="_blank" rel="noopener noreferrer">
											<AiFillYoutube color="#ff0000" />
										</a>
										<a href="https://www.linkedin.com/company/71278835/admin/" target="_blank" rel="noopener noreferrer">
											<AiFillLinkedin color="#0A66C2" />
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Mobile;
