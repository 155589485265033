import React, { Component } from "react";
// import { Card, Button } from "react-bootstrap";
import { Slide } from "react-awesome-reveal";
// import Slider from "react-slick";
// import posed from "react-pose";
// import Ripples from "react-ripples";
import Boxs from "./Box";
import BoxTop from "./BoxTop";
import Api from "./Api";
// import Aux from "./Auxs";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SectionThree.css";
import classes from "./SectionThree.module.css";

// const Box = posed.div({
//   pressable: true,
//   init: { scale: 1 },
//   press: { scale: 0.8 },
// });
class SectionThree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentSection: {
                widgets: [],
            },
            readMore: "Read",
            sectionName: "",
            isLoaded: false,
            isApiLoaded: false,
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ contentSection: [] }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    const sections = result.data;

                    const widget = [];
                    let sectionName = sections.name
                        .toLowerCase()
                        .replace(/\s+/g, "-");

                    result.data.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            name: item.name,
                            index: item.index,
                            internal: item.is_internal,
                            category: item.category,
                            // url: "/news/detail/",
                            url: item.url,
                            sections: item,
                        });
                    });

                    sections.page = widget;
                    this.setState({
                        contentSection: widget,
                        sectionName: sectionName,
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    this.setState({
                        isApiLoaded: true,
                    });
                    window.location.assign(
                        `/error/${
                            error.response.status ? error.response.status : 404
                        }`
                    );
                });
        });
    }

    render() {
        const { contentSection } = this.state;
        let titleContent1,
            subTitle1,
            contentBox1,
            titleContent2,
            subTitle2,
            contentBox2,
            slideTitle1,
            slideTitle2,
            backgroundImage,
            readMore = null;

        const settings = {
            className: "center",
            centerMode: false,
            infinite: false,
            centerPadding: "",
            variableWidth: true,
            slidesToShow: 1,
            swipe: true,
        };

        var articleIds = [];

        if (this.state.isLoaded) {
            let count = 0;
            contentSection.forEach((item) => {
                count += 1;

                if (
                    count === 1 &&
                    item.index === 1 &&
                    item.category === "Text"
                ) {
                    item.sections.widget_contents.forEach((s) => {
                        if (s.content !== "") {
                            readMore = s.content;
                        } else {
                            readMore = "";
                        }
                    });
                } else if (
                    count === 1 &&
                    item.index === 1 &&
                    item.category !== "Text"
                ) {
                    readMore = "";
                }

                if (
                    count === 2 &&
                    item.index === 2 &&
                    item.category === "Text"
                ) {
                    item.sections.widget_contents.forEach((s) => {
                        if (s.content !== "") {
                            titleContent1 = (
                                <h2 className={classes.sectionTitle}>
                                    {s.content}
                                </h2>
                            );
                        } else {
                            titleContent1 = <></>;
                        }
                    });
                }

                if (
                    count === 3 &&
                    item.index === 3 &&
                    item.category === "Text"
                ) {
                    item.sections.widget_contents.forEach((s) => {
                        if (s.content !== "") {
                            subTitle1 = (
                                <h2 className={classes.sectionContentTitle}>
                                    {s.content}
                                </h2>
                            );
                        } else {
                            subTitle1 = <></>;
                        }
                    });
                }

                if (
                    count === 4 &&
                    item.index === 4 &&
                    item.category === "Article"
                ) {
                    articleIds.push(item.id);
                    // contentBox1 = (
                    //   <ContentR
                    //     id={item.id}
                    //     readMore={readMore}
                    //     url={item.url ? item.url + "/" : "/news-events/news-detail/"}
                    //   />
                    // );

                    contentBox1 = !item.internal ? (
                        <BoxTop
                            id={articleIds}
                            idx={item.id}
                            readMore={readMore}
                            url={
                                item.url
                                    ? item.url + "/"
                                    : "/news-events/news-detail/"
                            }
                            sectionName={this.state.sectionName}
                        />
                    ) : (
                        <></>
                    );
                }

                if (
                    count === 5 &&
                    item.index === 5 &&
                    item.category === "Text"
                ) {
                    item.sections.widget_contents.forEach((s) => {
                        if (s.content !== "") {
                            titleContent2 = (
                                <h3 className={classes.sectionTitle}>
                                    {s.content}
                                </h3>
                            );
                        } else {
                            titleContent2 = <></>;
                        }
                    });
                }

                if (
                    count === 6 &&
                    item.index === 6 &&
                    item.category === "Text"
                ) {
                    item.sections.widget_contents.forEach((s) => {
                        if (s.content !== "") {
                            subTitle2 = (
                                <h3 className={classes.sectionContentTitle}>
                                    {s.content}
                                </h3>
                            );
                        } else {
                            subTitle2 = <></>;
                        }
                    });
                }

                if (
                    count === 7 &&
                    item.index === 7 &&
                    item.category === "Article"
                ) {
                    articleIds.push(item.id);
                    // contentBox2 = (
                    //   <ContentR
                    //     id={item.id}
                    //     readMore={readMore}
                    //     url={item.url ? item.url + "/" : "/news-events/news-detail/"}
                    //   />
                    // );
                    contentBox2 = item.internal ? (
                        <></>
                    ) : (
                        <Boxs
                            id={articleIds}
                            idf={item.id}
                            readMore={readMore}
                            url={
                                item.url
                                    ? item.url + "/"
                                    : "/news-events/news-detail/"
                            }
                        />
                    );
                }

                if (
                    count === 8 &&
                    item.index === 8 &&
                    item.category === "Media"
                ) {
                    item.sections.widget_contents.forEach((s) => {
                        let url = s.file
                            ? s.file
                            : require("./assets/default-image.svg");
                        backgroundImage = {
                            width: "100vw",
                            height: "auto",
                            backgroundImage: `url(${url})`,
                            backgroundSize: "cover",
                        };
                    });
                }
            });
            slideTitle1 = (
                <Slide triggerOnce direction="top">
                    {titleContent1}
                    {subTitle1}
                </Slide>
            );
            slideTitle2 = (
                <Slide triggerOnce direction="top">
                    {titleContent2}
                    {subTitle2}
                </Slide>
            );
        } else if (this.state.isApiLoaded) {
        }

        return (
            <div className="section-content">
                <div style={backgroundImage}>
                    <div className="section-blur">
                        <div className={classes.contentWrapping}>
                            <div
                                className={[
                                    "container",
                                    classes.paddingBoxTitle,
                                    classes.customContainer,
                                ].join(" ")}
                            >
                                {slideTitle1}
                            </div>
                            <div className={classes.secTempl}>
                                <div>{contentBox1}</div>
                            </div>
                            <div
                                className={[
                                    "container",
                                    classes.paddingBoxTitle,
                                    classes.mt5rem,
                                    classes.customContainer,
                                ].join(" ")}
                            >
                                {slideTitle2}
                            </div>
                            <div
                                className={[
                                    classes.secTempl,
                                    classes.paddingBox,
                                ].join(" ")}
                            >
                                <div>{contentBox2}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SectionThree;
