import React, { Component } from "react";
import Api from "./Api";
import { Link } from "react-router-dom";
import classes from "./Article.module.css";

export default class Article1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentArticle: [],
      isLoaded: false,
      isApiLoaded: true,
      activeSidebar: 0,
      box1: null,
      box2: null,
    };
    this.getArticleDetail = this.getArticleDetail.bind(this);
    this.setCurrentLink = this.setCurrentLink.bind(this);
  }

  componentDidMount() {
    if (this.props.id) {
      this.getArticleDetail(this.props.id);
    }
  }
  changeActiveSidebar(key) {
    this.setState({ activeSidebar: key });
  }
  getHtmlParagraph(str) {
    if (str !== "") {
      return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ");
    }
  }

  setCurrentLink(){
    localStorage.setItem('prevLink', `${window.location.href}${this.props.sectionName ? `/#${this.props.sectionName}` : ''}`);
  }

  getArticleDetail(param) {
    const apiData = new Api();
    this.setState({ contentArticle: [] }, () => {
      apiData
        .getArticleDetail(param)
        .then((result) => {
          const articles = result.data;
          const assets = [];

          let data;
          let description
          articles.results.forEach((a) => {
            if (a.article_assets.length === 0) {
              data = require("./assets/default-image.svg");
              description = "Default image"
            } else {
              data = a.article_assets[0].file_small;
              description = a.article_assets[0].description;
            }

            assets.push({
              title: a.article_languages.title,
              asset: data,
              description: description
            });
          });

          this.setState({
            contentArticle: assets,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  render() {
    const { contentArticle } = this.state;

    let img_article,
      title,
      readMore = null;
    let path = this.props.url;
    let click = this.props.click;

    contentArticle.forEach((z) => {
      img_article = z.asset ? (
        <img className={classes.img_box} src={z.asset} alt={z.description} />
      ) : (
        <img
          className={classes.img_box}
          src={require("./assets/default-image.svg")}
          alt="Default image"
        />
      );

      if (z.title) {
        title = z.title;
      } else {
        title = "";
      }
    });

    click.forEach((z) => {
      readMore = z.content;
    });

    return (
      <div className={classes.box_1}>
        <div className={classes.box_hover}>{img_article}</div>
        <div className={classes.boxBtm}>
          <div className={classes._box}>
            <p className={classes.tx}>{title}</p>
          </div>
          <div className={["d-flex flex-row", classes.margCustom].join(" ")}>
            <div className={classes.outerCircle2}>
              <div className={classes.innerCircle2}></div>
            </div>
            <Link onClick={()=>this.setCurrentLink()} to={path} className={classes.link1}>
              <span className={classes.readMore2}>{readMore}</span>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
