import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Modal } from "react-bootstrap";
import classes from "./Letter.module.scss";

function Letter(props) {
  let customStyle = null;
  if (props.onStyle) {
    if (props.onStyle === "out") {
      customStyle = classes.fadeOutFwd;
    } else {
      customStyle = classes.fadeInBack;
    }
  }

  let titleContent,
    subTitle,
    leftContent,
    rightContent,
    img = null;
  if (props.isData) {
    let content = props.data.article_language.content;
    let count = props.data.assets.length;

    console.log("ini img :", props.data.assets[0]);

    if (count !== 0) {
      img = props.data.assets[0].file_small ? (
        <img
          className={classes.backgroundImg}
          src={props.data.assets[0].file_small}
          alt={props.data.assets[0].description}
        ></img>
      ) : (
        <img
          className={classes.backgroundImg}
          src={props.data.assets[0].file_small}
          alt={props.data.assets[0].description}
        ></img>
      );
    }
    titleContent = (
      <p className={classes.title}>{props.data.article_language.title}</p>
    );

    if (content) {
      let result = content.match(/<p>(.*?)<\/p>/g).map(function (val) {
        return val.replace(/<\/?p>/g, " ").replace(/&nbsp;/gi, " ");
      });

      subTitle = (
        <p className={classes.contentSub}>
          {ReactHtmlParser(result[0] ? result[0] : "")}
        </p>
      );

      leftContent = (
        <>
          <span className={classes.content}>
            {ReactHtmlParser(result[1] ? result[1] : "")}
          </span>
          <br />
          <br />
          <span className={classes.content}>
            {ReactHtmlParser(result[2] ? result[2] : "")}
          </span>
        </>
      );
      rightContent = (
        <>
          <br />
          <br />
          <span className={classes.content}>
            {ReactHtmlParser(result[3] ? result[3] : "")}
          </span>
          <br />
          <br />
          <span className={classes.content}>
            {ReactHtmlParser(result[4] ? result[4] : "")}
          </span>
          <br />
          <br />
          <span className={classes.content}>
            {ReactHtmlParser(result[5] ? result[5] : "")}
          </span>
          <br />
          <br />
          <span className={classes.content}>
            {ReactHtmlParser(result[6] ? result[6] : "")}
          </span>
          <br />
          <br />
          <span className={classes.content}>
            {ReactHtmlParser(result[7] ? result[7] : "")}
          </span>
        </>
      );
    }
  }

  //   else {
  //     titleContent = (
  //       <p className={classes.title}>THÔNG ĐIỆP CỦA CHỦ TỊCH HỘI ĐỒNG QUẢN TRỊ</p>
  //     );
  //     subTitle = (
  //       <p className={classes.subTitle}>
  //         Kính thưa Quý Cổ đông, Nhà đầu tư, Khách hàng, Đối tác và các bên liên
  //         quan,
  //       </p>
  //     );
  //     leftContent = (
  //       <>
  //         <span className={classes.content}>
  //           Tập đoàn Phenikaa đã kết thúc năm 2019 với những chuyển dịch mang tính
  //           bước ngoặt, kết quả kinh doanh vượt kế hoạch và cao nhất từ trước tới
  //           nay. Hệ sinh thái Phenikaa với ba trụ cột Doanh nghiệp - Giáo dục &
  //           Đào tạo - Nghiên cứu khoa học đã chính thức kích hoạt, đánh dấu giai
  //           đoạn tái cấu trúc mạnh mẽ về cơ cấu sản phẩm và dịch vụ toàn Tập đoàn,
  //           nhằm hướng tới mục tiêu trở thành Tập đoàn Công nghệ - Công nghiệp
  //           hàng đầu trong lĩnh vực sản xuất thông minh và giải pháp thông minh.
  //         </span>
  //         <br />
  //         <span className={classes.content}>
  //           Năm 2020 đánh dấu chặng đường một thập kỷ phát triển của Tập Đoàn
  //           Phenikaa, cũng là khởi đầu cho một thập kỷ mới. Trong bối cảnh thế
  //           giới vận động và biến đổi không ngừng, đặc biệt dưới tác động của khoa
  //           học và công nghệ, Phenikaa bước vào chặng đường mới với tinh thần
  //           “Thích ứng nhanh - Chuyển đổi kịp thời”, chuyển đổi và tái cấu trúc
  //           toàn diện trên tất cả các lĩnh vực. Phenikaa xác định nghiên cứu và
  //           phát triển công nghệ đóng vai trò quyết định trong chiến lược sản xuất
  //           kinh doanh và quản trị. Trong thập kỷ mới này, phát triển công nghệ
  //           thông minh và sản xuất công nghiệp sẽ song hành trở thành hai lĩnh vực
  //           chủ chốt của Tập đoàn.
  //         </span>
  //       </>
  //     );

  //     rightContent = (
  //       <>
  //         <span className={classes.content}>
  //           Để chinh phục những mục tiêu đề ra, quá trình chuyển đổi tại Phenikaa
  //           cần đồng thời đạt được Chất, Lượng, Tốc độ và Hiệu quả. Tập đoàn
  //           Phenikaa tiếp tục làm chủ công nghệ, nhanh chóng thích ứng và chuyển
  //           đổi kịp thời để phù hợp với tốc độ phát triển công nghệ trong kỷ
  //           nguyên số; hoàn thiện hệ thống quản trị theo hướng đồng bộ, thông
  //           minh, tối ưu nguồn lực để kiểm soát tốt các rủi ro và phát huy hiệu
  //           quả; phát triển nguồn nhân lực giỏi chuyên môn và kỹ năng lãnh đạo
  //           trong thời kỳ cách mạng công nghiệp là ưu tiên hàng đầu trong quá
  //           trình chuyển đổi. Luôn nỗ lực “Hiện thực hóa mọi cam kết”, Tập đoàn
  //           Phenikaa coi tri thức, khoa học công nghệ và nghiên cứu phát triển
  //           (R&D) song hành cùng với triết lý kinh doanh đậm tính nhân văn và
  //           trách nhiệm với cộng đồng là yếu tố tiên quyết cho chiến lược phát
  //           triển bền vững và tạo lợi thế cạnh tranh dài hạn.
  //         </span>
  //         <br />
  //         <span className={classes.content}>
  //           Chúng tôi trân trọng cảm ơn và mong muốn tiếp tục nhận được sự tin
  //           tưởng, ủng hộ, đồng hành và hợp tác của Quý vị trong hành trình nỗ lực
  //           hiện thực hoá mọi cam kết với các bên liên quan, thực hiện trách nhiệm
  //           góp phần vì cuộc sống tốt đẹp hơn của cộng đồng xã hội. Trân trọng cảm
  //           ơn!
  //         </span>
  //       </>
  //     );
  //   }

  return (
    <div
      onClick={props.onClick}
      className={[classes.letterContent, customStyle].join(" ")}
    >
      <Modal.Header
        id="letterDekstop"
        className={classes.modalHeader}
        onClick={props.onClick}
        closeButton
      ></Modal.Header>
      <div className={["container", classes.customContainer].join(" ")}>
        <div className={classes.boxLetter}>
          {titleContent}
          {subTitle}
          <div className={classes.contentLetter}>
            <div className={classes.leftContent}>
              {leftContent}
              {rightContent}
            </div>

            {/* <div className={classes.rightContent}>
              {rightContent}
              <div className={classes.signature}>
                <p className={classes.contentSignature}>CHỦ TỊCH HĐQT</p>
                <img
                  src={require("./assets/sign.png")}
                  className={classes.imgSignature}
                  alt=""
                ></img>
                <p className={classes.contentSignature}>HỒ XUÂN NĂNG</p>
              </div>
            </div> */}
          </div>
        </div>
        <div className={classes.signature}>
          <div className={classes.signaturebox}>
            <p className={classes.contentSignature}>{props.signatureTitle}</p>
            <img
              src={require("./assets/sign.png")}
              className={classes.imgSignature}
              alt="Chairman of the Phenikaa BOD Signature"
            ></img>
            <p className={classes.contentSignature}>{props.signatureName}</p>
          </div>
        </div>
      </div>
      <div className={classes.boxImage}>
        {img}
        {/* <img
          className={classes.backgroundImg}
          src={require("./assets/background.png")}
          alt=""
        ></img> */}
      </div>
    </div>
  );
}

export default Letter;
