import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function CustomSlider(props) {
    let slidesToShow;
    if (window.innerWidth <= 480) {
        slidesToShow = 1
    } else if (window.innerWidth <= 768) {
        slidesToShow = 2
    } else if (window.innerWidth <= 1024) {
        slidesToShow = 3
    } else {
        slidesToShow = 4
    }

    const settings = {
        slidesToShow: slidesToShow,
        centerMode: false,
        infinite: false,
        centerPadding: "",
        variableWidth: true,
        slidesToScroll: 1,
        swipe: true,
    };
    return (
        <Slider {...settings}>
            {props.children}
        </Slider>
    )
}

export default CustomSlider