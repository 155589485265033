import React, { Component } from "react"
import dateFormat from "dateformat"
import { Link } from "react-router-dom"
import Api from "../Api"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import classes from "./Mobile.module.css"

export default class MobileViews extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contentArticle: [],
      isLoaded: false,
      isApiLoaded: true,
      indexYear: false,
      currentIndex: 0,
    }
    this.getArticleGroupbyYear = this.getArticleGroupbyYear.bind(this)
    this.setCurrentLink = this.setCurrentLink.bind(this)
  }

  componentDidMount() {
    if (this.props.id) {
      this.getArticleGroupbyYear(this.props.id)
      this.setState({ historyId: this.props.id })
    }
  }

  getArticleGroupbyYear(param) {
    const apiData = new Api()
    apiData
      .getArticleGroupbyYear(param)
      .then((result) => {
        const widget = []
        const years = []

        result.data.forEach((element) => {
          years.push(element.year)

          widget.push({
            year: element.year,
            art: element.articles,
          })
        })

        this.setState({
          contentArticle: widget.reverse(),
          years: years,
          isLoaded: true,
        })
      })
      .catch((error) => {
        this.setState({ isApiLoaded: true })
      })
  }

  setCurrentLink() {
    localStorage.setItem("prevLink", `${window.location.href}${this.props.sectionName ? `/#${this.props.sectionName}` : ""}`)
  }
  render() {
    const { contentArticle } = this.state

    let year,
      date,
      contentSample,
      isImage,
      title,
      img = null

    if (this.state.isLoaded) {
      let reverseData = contentArticle
      contentSample = reverseData.map((ele, index) => {
        let iHistory = index

        let idFix = this.props.id + "_" + iHistory

        ele.art.forEach((dat, i) => {
          if (i === this.state.currentIndex) {
            date = dat.date

            title = dat.article_languages.title
            year = dateFormat(date, "yyyy")

            isImage = dat.article_assets[0] ? dat.article_assets[0].file_small : require("../assets/default-image.svg")
          }
        })
        let boxClass = index === 0 ? [classes.box, classes.firstBoxMargin].join(" ") : classes.box
        
        return (
          <div className={boxClass}>
            <div className={classes.boxImage}>
              <img className={classes.contentImage} src={isImage} alt=''></img>
            </div>

            <div className={classes.boxContent}>
              <div className={classes.boxYear}>
                <p className={classes.contentYear}>{year}</p>
              </div>
              <p className={classes.content}>{title}</p>
            </div>
            <Link onClick={() => this.setCurrentLink()} to={`${this.props.urlMobile}/${year}/${idFix}`} className={classes.boxLink}>
              <p className={classes.textLink}>
                <div className={classes.outerCircle}>
                  <div className={classes.innerCircle}></div>
                </div>
                {this.props.viewAll}
              </p>
            </Link>
          </div>
        )
      })
    }

    const settings = {
      className: "",
      infinite: false,
      centerPadding: "",
      variableWidth: true,
      slidesToShow: 1,
      swipeToSlide: true,
    }
    return <Slider {...settings}>{contentSample}</Slider>
  }
}
