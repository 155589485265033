import React from "react";
import Desktop from "./Desktop";
import Mobile from "./Mobile/Mobile";
import { isMobile, isTablet } from "react-device-detect";
import { useMediaQuery } from "react-responsive";

export default function NO_Section1(props) {
  let content;

  const isM = useMediaQuery({
    query: "(min-device-width: 411px) and (max-device-width: 479px)",
  });

  if (isTablet) {
    content = <Desktop override={props.override} isTablet={true}/>;
  } else if (isMobile || isM) {
    content = <Mobile override={props.override} />;
  } else {
    content = <Desktop override={props.override} />;
  }

  return <>{content}</>;
}
