import React, { useState, Fragment } from "react"
import styled from "styled-components"
import { VectorMap } from "@south-paw/react-vector-maps"

import Anime from "./Anime"

import "./Map.css"
import classes from "./Map.module.css"
import worldMap from "../Flag/World.json"

const MapCustom = styled.div`
  margin: 0 auto 1rem auto;
  width: 45vw;
  position: relative;

  @media only screen and (max-width: 1024px) {
    width: 88vw;
  }

  svg {
    stroke: rgba(255, 255, 255, 0.5);

    path {
      fill: rgba(0, 0, 0, 0.2);
      cursor: pointer;
      outline: none;

      &[class="node-light"] {
        fill: #f16624;
      }

      &[id="node-light-id"],
      &[id="node-light-aus"],
      &[id="node-light-afsel"],
      &[id="node-light-ca"],
      &[id="node-light-br"],
      &[id="node-light-ru"],
      &[id="node-light-us"] {
        fill: rgba(255, 255, 255, 1);
        filter: blur(10px);
        -webkit-filter: blur(100px);
        -moz-filter: blur(10px);
        -o-filter: blur(10px);
        -ms-filter: blur(10px);
      }

      &[id="Vn-ex1"],
      &[id="Vn-ex2"] {
        fill: #f26522;
        // transform: scale(.8);
      }

      &[id="node-light-vn"] {
        fill: #2a3b71;
      }

      // &[id='node-light-vn'] {
      //   fill: #fff;
      // }

      // When a layer is focused.
      &:focus {
        fill: rgba(0, 0, 0, 1);
      }

      // When a layer is 'checked' (via checkedLayers prop).
      &[aria-checked="true"] {
        fill: red;
      }

      // When a layer is 'selected' (via currentLayers prop).
      &[aria-current="true"] {
        fill: red;
      }

      &[id="vn-id"],
      &[id="vn-aus"],
      &[id="vn-afsel"],
      &[id="vn-us"],
      &[id="vn-ca"],
      &[id="vn-br"],
      &[id="vn-ru"] {
        fill: transparent;
      }

      // &[id='us'], &[id='ca'], &[id='ar'], &[id='au'] , &[id='be'] , &[id='br'] , &[id='bn'], &[id='cn'], &[id='co'], &[id='cd'],
      // &[id='cy'], &[id='cz'], &[id='gb'], &[id='ee'], &[id='fr'], &[id='de'], &[id='gr'], &[id='hk'], &[id='in'], &[id='id'],
      // &[id='ie'], &[id='il'], &[id='it'], &[id='jp'], &[id='lb'], &[id='li'], &[id='mx'], &[id='mc'], &[id='mz'], &[id='ph'],
      // &[id='pt'], &[id='qa'], &[id='ru'], &[id='sg'], &[id='rs'], &[id='si'], &[id='za'], &[id='kr'], &[id='es'], &[id='lk'],
      // &[id='tw'], &[id='th'], &[id='ua'], &[id='us'], &[id='ae'] {
      //   fill: #2A3B71;
      // }

      &[id="us"],
      &[id="ca"],
      &[id="au"],
      &[id="br"],
      &[id="id"],
      &[id="ru"],
      &[id="za"] {
        fill: #2a3b71;
      }

      &[id="vn"] {
        fill: #f26522;
      }

      // You can also highlight a specific layer via it's id
      // &[id="nz-can"] {
      //   fill: rgba(56,43,168,0.6);
      // }
    }
  }
`

export default function Map(props) {
  const [selected, setSelected] = useState([])
  const [checked, setChecked] = useState([])

  let backgroundImg = null
  if (props.mapsData) {
    props.mapsData.forEach((m, i) => {
      if (i === 3) {
        backgroundImg = m.sections.widget_contents.map((s) => {
          // let image = s ? <img className={classes.backgroundImg} src={s.file_small} alt=""></img> : <></>
          // let image = s ? <div className={classes.imageMask} style={{backgroundImage: `linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(34,51,113,0.6190185546875) 50%, rgba(0,0,0,1) 100%), , url(${s.file_small})`}}></div> : <></>
          let image = s ? <div className={classes.imageMask} style={{ backgroundImage: `url(${s.file_small})` }}></div> : <></>

          return <>{image}</>
        })
      }
    })
  }

  const onClick = ({ target }) => {
    const name = target.attributes.name.value
    const id = target.attributes.id.value
    // id ? setChecked({checked: id}) : 'vn'
    setChecked({ checked: id })
    // selected.includes(id)
    //   ? setSelected(selected.filter(sid => sid !== id))
    //   : setSelected([...selected, id]);

    selected.includes(name) ? setSelected(name) : setSelected(name)
  }

  return (
    <div id='map' className={classes.Map}>
      <Fragment>
        <div className={classes.fullWidth}>
          <MapCustom>
            <div className={classes.boxDot}>
              <svg className={classes.dot} viewBox='0 0 800 600' enable-background='new 0 0 800 600'>
                <defs>
                  <filter id='blurMe'>
                    <feGaussianBlur stdDeviation='3' />
                  </filter>
                  <filter id='blurMe1'>
                    <feGaussianBlur stdDeviation='5' />
                  </filter>
                </defs>
                <g id='Group_8601' className={classes.nodeCA} data-name='Group 8601' filter='url(#blurMe)'>
                  <path id='Path_21419' filter='url(#blurMe1)' data-name='Path 21419' className='node-light' fill='#FFFFFF' stroke='none' strokeWidth='2' d='M1176.728,103.828A10.118,10.118,0,1,1,1166.61,93.71,10.118,10.118,0,0,1,1176.728,103.828Z' />
                  <path id='Path_21420' data-name='Path 21420' className='node-light' fill='#FFFFFF' stroke='none' strokeWidth='2' d='M1166.61,71.927c1.564,0,2.833,14.282,2.833,31.9s-1.269,31.9-2.833,31.9-2.832-14.282-2.832-31.9S1165.046,71.927,1166.61,71.927Z' />
                  <path id='Path_21421' data-name='Path 21421' className='node-light' fill='#FFFFFF' stroke='none' strokeWidth='2' d='M1198.511,103.828c0,1.564-14.283,2.832-31.9,2.832s-31.9-1.268-31.9-2.832,14.282-2.833,31.9-2.833S1198.511,102.263,1198.511,103.828Z' />
                </g>
                <g id='Group_8601' className={classes.nodeUSA} data-name='Group 8601' filter='url(#blurMe)'>
                  <path id='Path_21419' filter='url(#blurMe1)' data-name='Path 21419' className='node-light' fill='#FFFFFF' stroke='none' strokeWidth='2' d='M1176.728,103.828A10.118,10.118,0,1,1,1166.61,93.71,10.118,10.118,0,0,1,1176.728,103.828Z' />
                  <path id='Path_21420' data-name='Path 21420' className='node-light' fill='#FFFFFF' stroke='none' strokeWidth='2' d='M1166.61,71.927c1.564,0,2.833,14.282,2.833,31.9s-1.269,31.9-2.833,31.9-2.832-14.282-2.832-31.9S1165.046,71.927,1166.61,71.927Z' />
                  <path id='Path_21421' data-name='Path 21421' className='node-light' fill='#FFFFFF' stroke='none' strokeWidth='2' d='M1198.511,103.828c0,1.564-14.283,2.832-31.9,2.832s-31.9-1.268-31.9-2.832,14.282-2.833,31.9-2.833S1198.511,102.263,1198.511,103.828Z' />
                </g>
                <g id='Group_8601' className={classes.nodeBR} data-name='Group 8601' filter='url(#blurMe)'>
                  <path id='Path_21419' filter='url(#blurMe1)' data-name='Path 21419' className='node-light' fill='#FFFFFF' stroke='none' strokeWidth='2' d='M1176.728,103.828A10.118,10.118,0,1,1,1166.61,93.71,10.118,10.118,0,0,1,1176.728,103.828Z' />
                  <path id='Path_21420' data-name='Path 21420' className='node-light' fill='#FFFFFF' stroke='none' strokeWidth='2' d='M1166.61,71.927c1.564,0,2.833,14.282,2.833,31.9s-1.269,31.9-2.833,31.9-2.832-14.282-2.832-31.9S1165.046,71.927,1166.61,71.927Z' />
                  <path id='Path_21421' data-name='Path 21421' className='node-light' fill='#FFFFFF' stroke='none' strokeWidth='2' d='M1198.511,103.828c0,1.564-14.283,2.832-31.9,2.832s-31.9-1.268-31.9-2.832,14.282-2.833,31.9-2.833S1198.511,102.263,1198.511,103.828Z' />
                </g>
                <g id='Group_8601' className={classes.nodeSA} data-name='Group 8601' filter='url(#blurMe)'>
                  <path id='Path_21419' filter='url(#blurMe1)' data-name='Path 21419' className='node-light' fill='#FFFFFF' stroke='none' strokeWidth='2' d='M1176.728,103.828A10.118,10.118,0,1,1,1166.61,93.71,10.118,10.118,0,0,1,1176.728,103.828Z' />
                  <path id='Path_21420' data-name='Path 21420' className='node-light' fill='#FFFFFF' stroke='none' strokeWidth='2' d='M1166.61,71.927c1.564,0,2.833,14.282,2.833,31.9s-1.269,31.9-2.833,31.9-2.832-14.282-2.832-31.9S1165.046,71.927,1166.61,71.927Z' />
                  <path id='Path_21421' data-name='Path 21421' className='node-light' fill='#FFFFFF' stroke='none' strokeWidth='2' d='M1198.511,103.828c0,1.564-14.283,2.832-31.9,2.832s-31.9-1.268-31.9-2.832,14.282-2.833,31.9-2.833S1198.511,102.263,1198.511,103.828Z' />
                </g>
                <g id='Group_8601' className={classes.nodeAUS} data-name='Group 8601' filter='url(#blurMe)'>
                  <path id='Path_21419' filter='url(#blurMe1)' data-name='Path 21419' className='node-light' fill='#FFFFFF' stroke='none' strokeWidth='2' d='M1176.728,103.828A10.118,10.118,0,1,1,1166.61,93.71,10.118,10.118,0,0,1,1176.728,103.828Z' />
                  <path id='Path_21420' data-name='Path 21420' className='node-light' fill='#FFFFFF' stroke='none' strokeWidth='2' d='M1166.61,71.927c1.564,0,2.833,14.282,2.833,31.9s-1.269,31.9-2.833,31.9-2.832-14.282-2.832-31.9S1165.046,71.927,1166.61,71.927Z' />
                  <path id='Path_21421' data-name='Path 21421' className='node-light' fill='#FFFFFF' stroke='none' strokeWidth='2' d='M1198.511,103.828c0,1.564-14.283,2.832-31.9,2.832s-31.9-1.268-31.9-2.832,14.282-2.833,31.9-2.833S1198.511,102.263,1198.511,103.828Z' />
                </g>
                <g id='Group_8601' className={classes.nodeID} data-name='Group 8601' filter='url(#blurMe)'>
                  <path id='Path_21419' filter='url(#blurMe1)' data-name='Path 21419' className='node-light' fill='#FFFFFF' stroke='none' strokeWidth='2' d='M1176.728,103.828A10.118,10.118,0,1,1,1166.61,93.71,10.118,10.118,0,0,1,1176.728,103.828Z' />
                  <path id='Path_21420' data-name='Path 21420' className='node-light' fill='#FFFFFF' stroke='none' strokeWidth='2' d='M1166.61,71.927c1.564,0,2.833,14.282,2.833,31.9s-1.269,31.9-2.833,31.9-2.832-14.282-2.832-31.9S1165.046,71.927,1166.61,71.927Z' />
                  <path id='Path_21421' data-name='Path 21421' className='node-light' fill='#FFFFFF' stroke='none' strokeWidth='2' d='M1198.511,103.828c0,1.564-14.283,2.832-31.9,2.832s-31.9-1.268-31.9-2.832,14.282-2.833,31.9-2.833S1198.511,102.263,1198.511,103.828Z' />
                </g>
                <g id='Group_8601' className={classes.nodeRU} data-name='Group 8601' filter='url(#blurMe)'>
                  <path id='Path_21419' filter='url(#blurMe1)' data-name='Path 21419' className='node-light' fill='#FFFFFF' stroke='none' strokeWidth='2' d='M1176.728,103.828A10.118,10.118,0,1,1,1166.61,93.71,10.118,10.118,0,0,1,1176.728,103.828Z' />
                  <path id='Path_21420' data-name='Path 21420' className='node-light' fill='#FFFFFF' stroke='none' strokeWidth='2' d='M1166.61,71.927c1.564,0,2.833,14.282,2.833,31.9s-1.269,31.9-2.833,31.9-2.832-14.282-2.832-31.9S1165.046,71.927,1166.61,71.927Z' />
                  <path id='Path_21421' data-name='Path 21421' className='node-light' fill='#FFFFFF' stroke='none' strokeWidth='2' d='M1198.511,103.828c0,1.564-14.283,2.832-31.9,2.832s-31.9-1.268-31.9-2.832,14.282-2.833,31.9-2.833S1198.511,102.263,1198.511,103.828Z' />
                </g>
              </svg>
            </div>
            <VectorMap {...worldMap}></VectorMap>
          </MapCustom>
          <Anime />
        </div>
      </Fragment>
    </div>
  )
}
