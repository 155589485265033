import React, { Component } from 'react'
import { Link } from "react-router-dom"
import ReactHtmlParser from "react-html-parser"
import Slider from "react-slick"

import classes from './TabContent.module.css'

export default class TabContent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            props: false,
            isDetail: false,
        }
    }

    componentDidMount() {
        this.setState({
            props: this.props
        })
    }

    componentDidUpdate() {
        if (this.props) {
            if (this.state.props !== this.props) {
                this.setState({
                    props: this.props
                })
            }
        }
    }

    getHtmlParagraph(str) {
        if (str && str !== "") {
            return str
                .replace(/<\/?p>/gi, " ")
                .replace(/<p>/gi, " ")
                .replace(/&nbsp;/gi, " ")
        }
    }

    toggleDetail = () => {
        this.setState((prevState) => {
            return { isDetail: !prevState.isDetail }
        })
    }

    getUrlParser(url) {
        const { props } = this.state

        if (url !== "/") {
            return url.includes("http") ? (
                <a className={classes.customLink} href={url} target='_blank' rel='noopener noreferrer'>
                    <div className={classes.outerCircle}>
                        <div className={classes.innerCircle}></div>
                    </div>
                    <p className={classes.viewMore}>{props.property ? props.property[0]?.readMore : 'Read more'}</p>
                </a>
            ) : (
                <Link className={classes.customLink} to={url}>
                    <div className={classes.outerCircle}>
                        <div className={classes.innerCircle}></div>
                    </div>
                    <p className={classes.viewMore}>{props.property ? props.property[0]?.readMore : 'Read more'}</p>
                </Link>
            )
        } else {
            let isNull = classes.isNull
            return (
                <Link className={[classes.customLink, isNull].join(" ")} to={url}>
                    <div className={classes.outerCircle}>
                        <div className={classes.innerCircle}></div>
                    </div>
                    <p className={classes.viewMore}>{props.property ? props.property[0]?.readMore : 'Read more'}</p>
                </Link>
            )
        }
    }

    checkingFileExtension(file, alts, isOdd) {
        let fileType
        let typeVid = ["webm", "mkv", "flv", "gif", "avi", "mov", "wmv", "mp4", "3gp"];
        let typeImg = ["jpg", "jpeg", "png", "svg", "webp", "bmp", "tiff", "exif"];
        let typeDoc = ["doc", "docx", "odt", "pdf", "xls", "xlsx", "ods", "ppt", "pptx", "txt"];
        let extension = (file && file !== "null") ? file.substring(file.lastIndexOf(".") + 1) : 'default'
        if (typeVid.includes(extension)) {
            fileType =
                <video className={classes.isvideo} controls loop={true} autoPlay='autoplay' playsInline>
                    <source src={file} type='video/mp4' />
                </video>

        } else if (typeImg.includes(extension)) {
            fileType = <img className={classes.imgTop} src={file} alt={alts} />
        } else if (typeDoc.includes(extension)) {
            fileType = "doc"
        } else {
            fileType = <img className={classes.imgTop} src={file} alt={alts} />
        }
        return fileType
    }


    render() {
        const settings = {
            dots: true,
            centerMode: false,
            infinite: false,
            centerPadding: "",
            variableWidth: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500,
            swipe: true,
            appendDots: (dots) => (
                <div className={classes.customDots}>
                    <ul style={{ margin: "0px" }}> {dots} </ul>
                </div>
            ),
            customPaging: (i) => <div className={classes.customPaggination}></div>,
        }

        let title, desc, img, content, link, detailContent, contentBack, contentBackTab, subsText, subsidaries, contentSlider, contentSliderTab = null
        let customMargin = !this.state.isDetail ? "mb-5" : ""

        const { props } = this.state
        if (props) {
            if (props.tabs[0]) { // handling only Highlight
                props.tabs[0].forEach((t, i) => {
                    // console.log(`${props.i} --- ${i} ---- ${props.i === i}`)
                    if (props.i === i) {
                        let contentImg = t?.assets[1]?.file_big ? t?.assets[1]?.file_big || t?.assets[1]?.file : require("../assets/default-image.svg")
                        let imgDescription = t?.assets[1]?.description
                        img = <img className={[classes.imgContent].join(" ")} src={contentImg} alt={imgDescription} />

                        desc = <span className={classes.content}><p>{ReactHtmlParser(t.article_language ? t.article_language?.summary : "")}</p></span>

                        content = <div className={classes.contentDesc}>{ReactHtmlParser(this.getHtmlParagraph(t.article_language ? t.article_language.content : ""))}</div>
                    }
                });
            }

            if (props.data) {
                props.data.forEach((d, i) => {
                    if (props.i === i) {
                        detailContent = d?.data.map((dt, idx) => {
                            let contents
                            let alts = dt.assets ? dt.assets[0]?.description : ''

                            contents =
                                <>
                                    {dt.article_language?.title ?
                                        <h2 className={classes.titleContent}>{ReactHtmlParser(dt.article_language?.title)}</h2> : <></>}
                                    <hr />
                                    <div className={classes.allContent}>
                                        <div className={classes.boxTop}>
                                            {this.checkingFileExtension((dt.assets[0] ? ((dt.assets[0]?.asset_type !== 'Image') ? dt.assets[0]?.file : dt.assets[0]?.file_small) : require("../assets/default-image.svg")), alts, false)}
                                        </div>
                                        <div className={classes.boxBottom}>
                                            <p className={classes.content}>{ReactHtmlParser(dt.article_language?.content)}</p>
                                        </div>
                                    </div>
                                </>

                            return contents
                        })
                    }
                });
            }

            if (props.dataExt && props.dataExt.length > 0) {
                let cLink = props.property[1]?.subsidiaries ? props.property[1]?.subsidiaries?.sections?.url : ''
                subsText =
                    <Link to={cLink} style={{ color: 'black' }} className='text-decoration-none'>
                        <p><strong>{props.property[1]?.subsidiaries ? props.property[1]?.subsidiaries?.sections?.widget_contents[0]?.content : 'Subsidiaries'}</strong></p>
                    </Link>

                subsidaries = props.dataExt.map((s, i) => {
                    let url = s.assets[0] ? s.assets[0].file_small || s.assets[0].file : require("../assets/default-image.svg")
                    let description = s.assets[0]?.description

                    let cLinkTo = props.property[1]?.subsidiaries ? `${props.property[1]?.subsidiaries?.sections?.url}/?tab=${props.i + 1}&index=${i + 1}` : ''
                    let link = s.article_language.url ? this.getUrlParser(s.article_language.url) : this.getUrlParser("/")

                    return (
                        <Link to={cLinkTo}>
                            <div className={classes.box}>
                                <img src={url} alt={description}></img>
                                <div className={classes.boxMask}></div>
                                <div className={classes.contentFront}>
                                    <div className={classes.boxContentIn}>
                                        <div className='mt-auto mb-auto ml-auto mr-3'>
                                            <p>{this.getHtmlParagraph(s.article_language.title)}</p>
                                            <div className='d-flex flex-row justify-content-end position-relative m-auto mt-1'>{link}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    )
                })
            }

            if (!this.state.isDetail) {
                link = <div className={[classes.contentLink].join(" ")} onClick={this.toggleDetail}>
                    <div className='d-flex'>
                        <div className={classes.outerCircle}>
                            <div className={classes.innerCircle}></div>
                        </div>
                        <p className={classes.contentLink}>{props.property ? props.property[0]?.readMore : 'Read more'}</p>
                    </div>
                </div>
            } else if (this.state.isDetail) {
                let isNull = null
                let customLink = !this.state.isDetail ? "" : classes.contentLinkDetail
                let contentSliderD =
                    <div id='mobile-industry'>
                        <Slider {...settings}>{subsidaries}</Slider>
                    </div>
                let contentBackD = (
                    <div className={[classes.contentLink, customLink, isNull].join(" ")} onClick={this.toggleDetail}>
                        <div className='d-flex'>
                            <div className={classes.outerCircle}>
                                <div className={classes.innerCircle}></div>
                            </div>
                            <p className={classes.contentLink}>{props.property ? props.property[0]?.readLess : 'Read less'}</p>
                        </div>
                    </div>
                )

                contentBackTab = contentBackD
                contentSlider = contentSliderD
            }
        }

        let animate = this.state.isDetail ? classes.fadeInBottom : classes.fadeOutTop

        return (
            <div className={classes.sectionIndustry}>
                <div className={["container d-flex flex-column", classes.customContainer].join(" ")}>
                    <div className={[classes.boxImage].join(" ")}>{img}</div>

                    <div className={[classes.contentTop].join(" ")}>
                        <div className={classes.boxContent}>
                            {/* {title} */}
                            {desc}
                            <div className={[classes.contentOverflow].join(" ")}>{content}</div>
                            {link}
                        </div>
                    </div>

                    {
                        this.state.isDetail ?
                            <div div className={[classes.detailContent, animate].join(" ")}>
                                {detailContent}
                                {subsText}
                                {contentSlider}
                                {contentBackTab}
                            </div> : <></>
                    }
                    {contentBack}
                </div>
            </div >
        )
    }
}
