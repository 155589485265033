import React, { Component } from "react";

import { Link } from "react-router-dom";
import dateFormat from "dateformat";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import UserContext from "../Context";
import Career1 from "./C_Section1/CO";
import DataTable from './Table/Table'

import './Career_Browse.css'
import classes from "./Career_BrowseN.module.css";
export default class Career_BrowseN extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      id: "",
    };
  }

  componentDidMount() {
    const contextValue = this.context;

    this.setState({
      id: contextValue.idApplicant,
    });
  }

  render() {
    const settings = {
      slidesToShow: 3,
      swipeToSlide: true,
      infinite: false,
      variableWidth: false,
    };

    let positions, filterList, thisUrl, title1, title2, table;

    const contextValue = this.context;

    if (contextValue.isJobLoaded && contextValue.isFilterLoaded) {
      table = <DataTable data={contextValue.jobList} />

      // const arr = contextValue.jobList.reverse();
      // positions = arr.map((z, index) => {
      //   thisUrl = z.url ? z.url : "/";
      //   let strDate = z.date;
      //   let getDate = dateFormat(strDate, "dd-mm-yyyy");
      //   let boxApply = z.status ? classes.boxApply : "";
      //   return (
      //     <Link className={classes.boxLink} to={thisUrl}>
      //       <div className={[classes.box, boxApply].join(' ')}>
      //         <span className={classes.category}>{z.company}</span>
      //         <div className={classes.boxContent}>
      //           <span className={classes.title}>{z.position_name ? z.position_name : ''}</span>
      //           <div className={classes.innerContent}>
      //             <span className={classes.content}>{z.location_address ? z.location_address : ''}</span>
      //           </div>
      //         </div>
      //         <div className={classes.boxDetail}>
      //           <div className="d-flex flex-column">
      //             <span className={classes.content}>Send</span>
      //             <span className={classes.content}>{getDate}</span>
      //           </div>
      //         </div>
      //       </div>
      //     </Link>
      //   );
      // });

      // filterList = contextValue.listFilter.map((s, i) => {
      //   let active = parseInt(this.props.active) === parseInt(i + 1) ? classes.filterActive : ""

      //   return (
      //     <div data-filter={s.id} className={[classes.filterContent, active].join(' ')} onClick={this.props.onClick}>
      //       <span data-filter={s.id} onClick={this.props.onClick}>{s.title}</span>
      //     </div>
      //   );
      // });
    }

    if (contextValue.isSectionLoaded) {
      if (contextValue.contentSection[2]) {
        contextValue.contentSection[2].sections.widget_contents.forEach((c, i) => {
          switch (i) {
            case 3:
              title1 = c.content
              break;
            case 4:
              title2 = c.content
              break;
            default:
              break;
          }
        })
      }
    }

    return (
      <>
        <div className={["container", classes.customContainer, classes.customTitle].join(" ")}>
          <h2 className={classes.title}>{title1}</h2>
        </div>
        <Career1 />
        <div className={["container", classes.customContainer].join(" ")}>
          <h2 className={classes.title}>{title2}</h2>
          <div className={classes.contentBrowseJob}>
            {table}
          </div>
          {/* <div id="m_c_browse_j" className={classes.contentBrowseJob}>
            <Slider {...settings}>{filterList}</Slider>
            <div className={classes.bottomContent}>
              <div className="d-flex flex-wrap justify-content-between">
                {positions}
              </div>
            </div>
          </div> */}
        </div>
      </>
    );
  }
}
