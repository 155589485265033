import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaShareAlt } from "react-icons/fa";

import classes from "./Card.module.css";
import "./Card.css";

function Card({ first, url, text, imgUrl, idx, onClick, shareLink, isMobile, isTablet, onCopy }) {
	const [isHover, setHover] = useState(false);

	let isNull;
	if (url === "") {
		isNull = classes.isNull;
	} else {
		isNull = "";
	}
	const bottomText = (
		<div className={classes.textContainer}>
			<div className={classes.textContent}>
				<div className={classes.title_content1}>
					<p>{text}</p>
				</div>
			</div>
		</div>
	);

	const marginLeft = idx === 0 ? classes.marginLeft : "";

	return (
		<div className={[classes.contentWrap, classes.fadeInBottomm, marginLeft, first, isNull].join(" ")} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
			{isHover && !isMobile && (
				<div onMouseEnter={() => setHover(true)}>
					<OverlayTrigger
						trigger={["hover", "focus"]}
						key="right"
						placement="right"
						overlay={
							<Tooltip id="custom-tooltip">
								<strong>Share</strong>
							</Tooltip>
						}
					>
						<CopyToClipboard text={shareLink} onCopy={onCopy}>
							<FaShareAlt className={classes.share} />
						</CopyToClipboard>
					</OverlayTrigger>
				</div>
			)}
			{(isMobile || isTablet) && (
				<div>
					<CopyToClipboard text={shareLink} onCopy={onCopy}>
						<FaShareAlt className={["fa fa-share-alt fa-2x", classes.share].join(" ")} />
					</CopyToClipboard>
				</div>
			)}
			<div className={classes.boxContent1} onClick={onClick}>
				<div className={classes.box_content_image}>
					<img className={classes.box_image} src={imgUrl ? imgUrl : ""} alt={text} />
				</div>
				{bottomText}
			</div>
		</div>
	);
}

export default Card;
