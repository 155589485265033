import React, { Component } from "react"

import { faCaretDown, faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import StateContext from "../Context"
import Api from "../../Api"

import classes from "./Navbar.module.css"

export default class Navbar extends Component {
  static contextType = StateContext

  constructor(props) {
    super(props)
    this.state = {
      tabs: [
        { id: 1, title: "Browse jobs" },
        { id: 2, title: "My Jobs" },
        { id: 3, title: "My Profile" },
      ],
      optLoginTrue: [
        { id: 1, title: "Edit Profile" },
        // { id: 2, title: "Account Settings" },
        { id: 2, title: "Logout" },
      ],
      optLoginFalse: [{ id: 1, title: "Login" }],
      isLoggedIn: false,
    }
    this.setLoggedOut = this.setLoggedOut.bind(this)
  }

  componentDidMount() {
    const contextValue = this.context

    this.setState({
      isLoggedIn: contextValue.isLoggedIn,
    })
  }

  setLoggedOut() {
    const logout = new Api()

    logout
      .setLogoutApplicant()
      .then((res) => {
        localStorage.removeItem("token")
        localStorage.removeItem("id")
        localStorage.setItem("isLoggedIn", "false")
        let isLoggedIn = localStorage.getItem("isLoggedIn")
        this.setState({
          isLoggedIn: isLoggedIn,
        })
        window.location.assign(`/recruitment/`)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  setListItem(array, param) {
    let content
    if (array) {
      content = array.map((s, i) => {
        let items
        if (param) {
          items =
            i !== 1 ? (
              <span data-tab={i + 3} onClick={this.props.onClick}>
                {s.title}
              </span>
            ) : (
              <span onClick={this.setLoggedOut}>{s.title}</span>
            )
        } else {
          items = <span onClick={this.props.loginClicked}>{s.title}</span>
        }
        return <>{items}</>
      })
    }
    return content
  }

  render() {
    const contextValue = this.context
    let browseJob, myJobs, myProfile, search, login, editProfile, logout
    let navIdx, navItem
    let avatar, optionItem

    let tabs = []
    let optLoginTrue = []
    let optLoginFalse = []
    if (contextValue.isSectionLoaded) {
      if (contextValue.contentSection[1]) {
        // console.log(contextValue.contentSection[1])
        contextValue.contentSection[1].sections.widget_contents.forEach((c, i) => {
          switch (i) {
            case 0:
              browseJob = c.content
              tabs.push({
                id: i + 1,
                title: browseJob,
              })
              break
            case 1:
              myJobs = c.content
              tabs.push({
                id: i + 1,
                title: myJobs,
              })
              break
            case 2:
              myProfile = c.content
              tabs.push({
                id: i + 1,
                title: myProfile,
              })
              break
            case 3:
              search = c.content
              break
            case 4:
              login = c.content
              optLoginFalse.push({
                id: i - 3,
                title: login,
              })
              break
            case 5:
              editProfile = c.content
              optLoginTrue.push({
                id: i - 4,
                title: editProfile,
              })
              break
            case 6:
              logout = c.content
              optLoginTrue.push({
                id: i - 4,
                title: logout,
              })
              break
            default:
              break
          }
        })

        navIdx = !this.props.isLoggedIn ? 1 : tabs.length

        navItem = tabs.slice(0, navIdx).map((s, i) => {
          let active = parseInt(this.props.active) === s.id ? classes.navActive : ""
          return (
            <div data-tab={s.id} className={[classes.navbarContent, active].join(" ")} onClick={this.props.onClick}>
              <span data-tab={s.id} onClick={this.props.onClick}>
                {s.title}
              </span>
            </div>
          )
        })

        let data = this.props.data
        if (this.props.isLoggedIn) {
          if (data.avatar) {
            avatar = data.avatar
          } else {
            avatar = require("../assets/man.svg")
          }

          optionItem = this.setListItem(optLoginTrue, true)
        } else {
          avatar = require("../assets/man.svg")
          optionItem = this.setListItem(optLoginFalse, false)
        }
      }
    }

    return (
      <div className={classes.navbar}>
        <div className={["container", classes.customContainer].join(" ")}>
          <div className={classes.boxLeft}>{navItem}</div>
          <div className={classes.boxRight}>
            <div className={classes.boxSearch}>
              <input onKeyDown={this.props.searchHandler} placeholder={search} />
              <FontAwesomeIcon color='#000000' className={classes.iconSearch} icon={faSearch} size='lg' />
            </div>
            <div className={classes.boxUserProfile}>
              <figure>
                <img src={avatar} alt='' />
              </figure>

              <div className={classes.slNav}>
                <div className={classes.contentDropdown}>
                  <ul>
                    <li>
                      <FontAwesomeIcon color='#000000' className='m-auto' icon={faCaretDown} size='lg' />
                      <div className={classes.block}></div>
                      <div className={classes.triangle}></div>
                      <ul>
                        <li>{optionItem}</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
