import React, { Component } from "react";
import classes from "./../Section2.module.css";
import Api from "./../Api";
import CircleDiagram from "../components/CircleDiagram/CircleDiagram"

export default class Desktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      circleData: [1, 2, 3, 4, 5],
      istoggleOn: false,
      contentSection: [],
      isLoaded: false,
      isApiLoaded: true,
      activeIndex: [],
      activeText: [],
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
    this.toggleDetail = this.toggleDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    apiData
      .getSectionDetail()
      .then((result) => {
        const sections = result.data;
        const widget = [];

        result.data.widgets.forEach((item) => {
          widget.push({
            id: item.id,
            index: item.index,
            category: item.category,
            name: item.name,
            sections: item,
          });
        });

        sections.page = widget;
        this.setState({ contentSection: widget, isLoaded: true });
      })
      .catch((error) => {
        this.setState({ isApiLoaded: true });
      });
  }

  handleClick = () => {
    this.setState((prevState) => {
      return {
        istoggleOn: !prevState.istoggleOn,
      };
    });
  };

  toggleDetail = (i) => {
    var result = true;
    var result2 = [];

    for (var a = 0; a <= this.state.activeIndex.length; a++) {
      if (i === this.state.activeIndex[a]) result = false;
    }

    if (result === true) {
      result2.push(i);
      this.setState(
        {
          activeIndex: this.state.activeIndex.concat(result2),
        },
        () => { }
      );
    } else {
      let mySet = new Set(this.state.activeIndex);
      if (mySet.has(i)) {
        mySet.delete(i);
        this.setState(
          {
            activeIndex: [...mySet],
          },
          () => { }
        );
      }
    }
  };

  toggleEnter = (i) => {
    var result = true;
    var result2 = [];

    for (var a = 0; a <= this.state.activeText.length; a++) {
      if (i === this.state.activeText[a]) result = false;
    }

    if (result === true) {
      result2.push(i);
      this.setState(
        {
          activeText: this.state.activeText.concat(result2),
        },
        () => { }
      );
    } else {
      let mySet = new Set(this.state.activeText);
      if (mySet.has(i)) {
        mySet.delete(i);
        this.setState(
          {
            activeText: [...mySet],
          },
          () => { }
        );
      }
    }
  };

  render() {
    const { contentSection, circleData } = this.state;

    let dataCircle,
      insideText,
      circleDiagram,
      titleInner,
      imageBackground = null;

    if (this.state.isLoaded) {
      let count = 0;
      let arrIcon1 = [];
      let arrIcon2 = [];
      let dataScroll = [];
      contentSection.forEach((item) => {
        count = count + 1;
        if (count === 1 && item.index === 1 && item.category === "Media") {
          item.sections.widget_contents.forEach((s) => {
            arrIcon1.push(s.file);
          });
        }
        if (count === 2 && item.index === 2 && item.category === "Media") {
          item.sections.widget_contents.forEach((s) => {
            arrIcon2.push({
              file: s.file,
              description: s.description
            });
          });
        }
        if (count === 3 && item.index === 3 && item.category === "Text") {

        }
        if (count === 4 && item.index === 4 && item.category === "Text") {
          item.sections.widget_contents.forEach((dots) => {
            dataScroll.push(dots.content);
          });

        } 
        if (count === 5 && item.index === 5 && item.category === "Text") {
          titleInner = item.sections.widget_contents[0].content;
        }
        if (count === 6 && item.index === 6 && item.category === "Media") {
          imageBackground = (
            <div className={classes.Content2}>
              <div
                style={{
                  background: `url(${item.sections.widget_contents[0].file}) center no-repeat `,
                  backgroundSize: "cover",
                }}
                className={classes.wrapBox}
              ></div>
              <h2 className={classes.innerText}>{titleInner}</h2>
            </div>
          );

          circleDiagram = <CircleDiagram
            dataScroll={dataScroll}
            arrIcon2={arrIcon2}
            toggleDetail={this.toggleDetail}
            toggleEnter={this.toggleEnter}
            handleClick={this.handleClick}
            imageBackground={imageBackground}
          />
        }
      });
    }
    return (
      <div className={classes.Section2}>
        <div className={["container", classes.boxC].join(" ")}>
          <div className={classes.containerBox2}>

              {circleDiagram}

          </div>
        </div>
      </div>
    );
  }
}
