import React, { Component, createRef } from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom'
import {Helmet} from 'react-helmet';
import SectionList from './SectionList';
import { ParallaxProvider } from 'react-scroll-parallax';
import { isMobile  } from 'react-device-detect';
import LOGO_793 from '../../sections/Logo_793/Logo';
import MENU_794 from '../../sections/Menu_794/Menu';
import AU_ORG_CHART_NEW_811 from '../../sections/AU_Org_Chart_New_811/AU_Org_Chart_New';
import FOOTER_795 from '../../sections/Footer_795/Footer';
import SectionShortcut from './SectionShortcut/SectionShortcut';
import HomeSectionShortcut from './SectionShortcut/HomeSectionShortcut';
import styled from 'styled-components';

const MainClass = styled.div`
     min-height: 100vh;
     background: white;
     position: relative;
     z-index: 1;
     box-shadow: 0px 3px 6px #00000029;
`;

class project1 extends Component {
   constructor(props) {
       super(props);
       this.state = {
           currentPage: null,
           currentSection: null,
           override: true,
           darkSection: false,
          section: [
               { id: 1, title: localStorage.getItem('language') === "English"? 'New Chart' : 'New Chart', slug: localStorage.getItem('language') === "English"? 'new-chart' : 'new-chart', has_shortcut: true},
          ],
       activeSection: 'Section1',
       };
       this.updateLogo = this.updateLogo.bind(this)
       this.override1 = this.override1.bind(this)
        this.scrollToSection = this.scrollToSection.bind(this)
        this.isStateChanging = false
   }
   componentDidMount() {
       ReactGA.initialize('G-R7TGZTN26B')
       ReactGA.pageview(window.location.pathname + window.location.search);
          this.scrollToSection()
   }
   componentWillUnmount(){
   }
     section1 = createRef()
   updateLogo(value) {
       this.setState({
           darkSection: value,
       })
   }
   override1(value) {
       this.setState({
       override: value,
       })
   }

     scrolltoSectionHandler = (key) => {
       if (key === 'section1') {
           this.section1.current.scrollIntoView({'behavior': 'smooth', 'block': 'start'});
      }
   };
    scrollingHandler() {
        const sectionsName = ['section1'];
        const isSectionsDarkLogo = [
       {
           name: 'Section1',
           darkSection: false
       },
        ]
       isSectionsDarkLogo.forEach((sec, i) => {
           let section = this[sectionsName[i]]?.current?.getBoundingClientRect();
           if (section && section.bottom < section.height && section.bottom > -1 && !this.isStateChanging) {
               this.setState({
                   activeSection: sec.name
               })
               if (this.state.darkSection !== sec.darkSection && this.state.currentSection !== sec.name) {
                   this.isStateChanging = true
                   this.setState({
                       currentSection: sec.name,
                       darkSection: sec.darkSection,
                   }, () => {
                       this.isStateChanging = false
                   })
               }
           }
       })
    }
   scrollToSection() {
       let currentLocation = window.location.href
       const hasSectionAnchor = currentLocation.includes('/#')
       const hasTabAnchor = currentLocation.includes('*')
       if (hasSectionAnchor) {
           let anchorSectionId = `${currentLocation.substring(currentLocation.indexOf('#') + 1)}`
           if (hasTabAnchor) {
                const tabSectionId = `${anchorSectionId.substring(anchorSectionId.indexOf('*') + 1)}`
                anchorSectionId = anchorSectionId.substring(0, anchorSectionId.length - 2)
                setTimeout(() => { this.scrollAction(anchorSectionId) }, 1000)
                this.setState({ tabsActive: tabSectionId })
            } else {
                setTimeout(() => { this.scrollAction(anchorSectionId) }, 1000)
            }
        } else {
           setTimeout(() => {window.scrollTo(0, 0)}, 1000)
           this.setState({
               activeSection: 'Section1',
               currentSection: null,
           })
        }
   }
    scrollAction(anchorSectionId) {
       const uri_dec = decodeURIComponent(anchorSectionId)
       const key = this.state.section.filter(x => x.slug == uri_dec).map(obj => `section${obj.id}`)
       if (key) {
                if (key[0] === 'section1') {
                    setTimeout(() => {this.section1.current.scrollIntoView({'behavior': 'smooth', 'block': 'start'})}, 1000)
                }
       }
   }
   render() {
       let AllSections = [];
       if (this.state.section) {
           this.state.section.map((s, i) => {
               let section;
               switch (i) {
                   case 0:
                       section = (
                           <div ref={this.section1}>
                               <AU_ORG_CHART_NEW_811
                                   updateLogo={this.updateLogo}
                                   override={this.override1}
                                   darkSection={this.state.darkSection}
                               />
                           </div>
                       );
                       break;
                       default:
                           break;
                   }
           AllSections.push(section);
           });
       }
       let sec = this.state.section.filter(sec => sec.has_shortcut === true).map((s, i) => {
           let activeDot = this.state.activeSection === 'Section' + (i) ? true : false
           let ref = 'section' + s.id
           return (
               <SectionShortcut
                   key={s.id}
                   title={s.title}
                   activeDot={activeDot}
                   onClick={() => this.scrolltoSectionHandler(ref)}
               />
           );
       });
       let homeSectionShortcut = !isMobile? <HomeSectionShortcut section={sec}  /> : null 
         let helmet = localStorage.getItem('language') === "English" ? <title> Test Organization Chart | Phenikaa</title> : <title> Test Organization Chart | Phenikaa</title>
         let description = localStorage.getItem('language') === "English" ? <meta name="description" content="None" /> : <meta name="description" content="None" />
         let keywords = localStorage.getItem('language') === "English" ? <meta name="keywords" content="" /> : <meta name="keywords" content="" />
       return (
           <div style={{}}>
               <Helmet>
                   {helmet}
                   {description}
                   {keywords}
               </Helmet>
               <LOGO_793 logoColor={this.state.darkSection}/>
               <MENU_794 logoColor={this.state.darkSection}/>
               <MainClass>
                   <ParallaxProvider >
                       <React.Fragment>
                               {AllSections.length > 0 ? (
                                   <SectionList sections={AllSections} />
                               ) : (
                                   <></>
                               )}
                       </React.Fragment>
                   </ParallaxProvider>
               </MainClass>
               <FOOTER_795/>
           </div>
       );
   }
}
export default withRouter(project1);