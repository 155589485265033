import React from "react";
import ReactHtmlParser from "react-html-parser";
import { Modal } from "react-bootstrap";
import classes from "./LetterNew.module.scss";

function Letter(props) {
  let customStyle = null;
  if (props.onStyle) {
    if (props.onStyle === "out") {
      customStyle = classes.fadeOutFwd;
    } else {
      customStyle = classes.fadeInBack;
    }
  }

  let titleContent,
    data,
    result,
    subTitle = null;

  let img;

  if (props.isData) {
    let count = props.data.assets.length;

    if (count !== 0) {
      img = props.data.assets[0].file_medium ? (
        <img
          className={classes.backgroundImg}
          src={props.data.assets[0].file_medium}
          alt={props.data.assets[0].description}
        ></img>
      ) : (
        <img
          className={classes.backgroundImg}
          src={props.data.assets[0].file_small}
          alt={props.data.assets[0].description}
        ></img>
      );
    }

    let content = props.data.article_language.content;
    if (content) {
      titleContent = (
        <p className={classes.title}>{props.data.article_language.title}</p>
      );

      result = content.match(/<p>(.*?)<\/p>/g).map(function (val) {
        return val.replace(/<\/?p>/g, " ").replace(/&nbsp;/gi, " ");
      });

      subTitle = (
        <p className={classes.contentSub}>
          {ReactHtmlParser(result[0] ? result[0] : "")}
        </p>
      );

      data = result.map((ele, i) => {
        let col;
        if (i === 0) {
          col = <></>;
        } else {
          col = (
            <>
              {ReactHtmlParser(ele)}
              <br />
              <br />
            </>
          );
        }
        return <>{col}</>;
      });
    }
  }

  return (
    <div
      // onClick={props.onClick}
      className={[classes.letterContent, customStyle].join(" ")}
    >
      <Modal.Header
        id="letterDekstop"
        className={classes.modalHeader}
        onClick={props.onClick}
        closeButton
      ></Modal.Header>
      <div className={["container", classes.customContainer].join(" ")}>
        <div className={classes.boxLetter}>
          {titleContent}
          {subTitle}
          <div className={classes.contentLetter}>{data}</div>
          <div className={classes.signature}>
            <p className={classes.contentSignature}>{props.signatureTitle}</p>
            <img
              src={require("./assets/sign.png")}
              className={classes.imgSignature}
              alt="Chairman of the Phenikaa BOD Signature"
            ></img>
            <p className={classes.contentSignature}>{props.signatureName}</p>
          </div>
        </div>
      </div>
      <div className={classes.boxImage}>
        {img}
      </div>
    </div>
  );
}

export default Letter;
