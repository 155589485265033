import React, { Component } from "react"
import ReactHtmlParser from "react-html-parser"

import Api from "../Api"
import classes from "./Mobile.module.css"

export default class Mobile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
    }
    this.getSectionDetail = this.getSectionDetail.bind(this)
    this.getHtmlParagraph = this.getHtmlParagraph.bind(this)
  }

  componentDidMount() {
    this.getSectionDetail()
  }

  getSectionDetail() {
    const apiData = new Api()
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data
          const widget = []

          sections.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            })
          })

          this.setState({
            contentSection: widget,
            isLoaded: true,
          })
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          })
        })
    })
  }

  getHtmlParagraph(str) {
    if (str !== "") {
      var result = str
        .replace(/<\/?p>/gi, " ")
        .replace(/<p>/gi, " ")
        .split("  ")
      //return result;
      return [str]
    } else {
      return []
    }
  }

  render() {
    let content,
      titleBig = null

    if (this.state.isLoaded) {
      this.state.contentSection.forEach((item, index) => {
        switch (index) {
          case 0:
            let textLeft = true
            let letterLimit = 1000
            let noneBox = null

            content = item.sections.widget_contents.map((article) => {
              let imageIndex = 0
              let imageArray = []
              let descArray = []
              article.assets.forEach((image) => {
                imageArray.push(image.file_small)
                descArray.push(image.description)
              })
              let paragraphArray = this.getHtmlParagraph(article.article_language.content)
              let totalParagraphs = ""
              let letters = 0
              let arrayToMapAndReturn = []
              paragraphArray.forEach((paragraph, index) => {
                if (index !== 0) {
                  totalParagraphs = ""
                }
                if (paragraphArray[0] === null) {
                  noneBox = classes.noneBox
                }

                letters += paragraph.length
                if (imageArray[imageIndex]) {
                  if (letters > letterLimit) {
                    if (textLeft) {
                      textLeft = !textLeft
                      let leftside = (
                        <div className={classes.allContent}>
                          <div className={classes.boxImgL}>
                            <img className={classes.boxImg} src={imageArray[imageIndex]} alt={descArray[imageIndex]} />
                          </div>
                          <div className={classes.boxCT}>
                            <div className={classes.blabla}>
                              <p className={[classes.titleCont, noneBox].join(" ")}>{ReactHtmlParser(paragraphArray[0])}</p>
                              <p className={classes.textPar}>{ReactHtmlParser(totalParagraphs)}</p>
                            </div>
                          </div>
                        </div>
                      )
                      arrayToMapAndReturn.push(leftside)
                    } else {
                      textLeft = !textLeft
                      let rightside = (
                        <div className={classes.allContent2}>
                          <div className={classes.boxImgR}>
                            <img className={classes.boxImg} src={imageArray[imageIndex]} alt={descArray[imageIndex]} />
                          </div>
                          <div className={classes.boxCT2}>
                            <div className={classes.blabla}>
                              <p className={[classes.titleCont, noneBox].join(" ")}>{ReactHtmlParser(paragraphArray[0])}</p>
                              <p className={classes.textPar2}>{ReactHtmlParser(totalParagraphs)}</p>
                            </div>
                          </div>
                        </div>
                      )
                      arrayToMapAndReturn.push(rightside)
                    }
                    imageIndex += 1
                    letters = 0
                    paragraph = ""
                  } else {
                    totalParagraphs = totalParagraphs + paragraph
                  }
                } else {
                  totalParagraphs = totalParagraphs + paragraph
                }
              })
              if (imageArray[imageIndex]) {
                let titleArt = article.title !== "" ? <p className={classes.pArt}>{article.title}</p> : <></>
                if (textLeft) {
                  textLeft = !textLeft
                  let leftside = (
                    <>
                      <hr className={classes.hrLeft} />
                      <div className={classes.allContentB}>
                        <div className={classes.txtParB2}>
                          <div className={classes.boxEx2}>
                            <img className={classes.imgLogo} src={imageArray[imageIndex]} alt={descArray[imageIndex]} />
                          </div>
                        </div>
                        <div className={classes.txtParB}>
                          <div className={classes.blabla}>
                            {/* <p className={classes.pArt}>{article.title}</p> */}
                            {titleArt}
                            {/* <p className={classes.titleCont}>
                              {ReactHtmlParser(paragraphArray[0])}
                            </p> */}
                            <p className={classes.txtReal}>{ReactHtmlParser(totalParagraphs)}</p>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                  arrayToMapAndReturn.push(leftside)
                } else {
                  textLeft = !textLeft
                  let rightside = (
                    <>
                      <hr />
                      <div className={classes.allContentC}>
                        <div className={classes.absImg}>
                          <div className={classes.boxEx}>
                            <img className={classes.imgLogo2} src={imageArray[imageIndex]} alt={descArray[imageIndex]} />
                          </div>
                          {/* <img
                            className={classes.imgLogo2}
                            src={imageArray[imageIndex]}
                            alt=" "
                          /> */}
                        </div>
                        <div className={classes.boxPar}>
                          <div className={classes.blabla}>
                            {/* <p className={classes.pArt}>{article.title}</p> */}
                            {titleArt}
                            {/* <p className={[classes.boxTxt2, noneBox].join(" ")}>
                              {ReactHtmlParser(paragraphArray[0])}
                            </p> */}
                            <p className={classes.txtParC}>{ReactHtmlParser(totalParagraphs)}</p>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                  arrayToMapAndReturn.push(rightside)
                }
              } else {
                arrayToMapAndReturn.push(
                  <div className={classes.boxAll}>
                    <p className={classes.totalPar}>{ReactHtmlParser(totalParagraphs)}</p>
                  </div>
                )
              }

              return <>{arrayToMapAndReturn}</>
            })

            break
          case 1:
            titleBig = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Lorem Ipsum"

            break
          default:
            break
        }
      })
    } else if (this.state.isApiLoaded) {
      titleBig = ""
      let noneBox = classes.noneBox
      content = (
        <>
          <hr />
          <div className={classes.allContentC}>
            <div className={classes.absImg}>
              <img className={classes.imgLogo2} src={require("../assets/default-image.svg")} alt='' />
            </div>
            <div className={classes.boxPar}>
              <div className={classes.blabla}>
                <p className={classes.pArt}>{/* {article.title} */}</p>
                <p className={[classes.boxTxt2, noneBox].join(" ")}>{/* {ReactHtmlParser(paragraphArray[0])} */}</p>
                <p className={classes.txtParC}>{/* {ReactHtmlParser(totalParagraphs)} */}</p>
              </div>
            </div>
          </div>
        </>
      )
    }

    return (
      <div className={classes.section}>
        <div className={["container", classes.customContainer].join(" ")}>
          <h2 className={classes.title}>{titleBig}</h2>
          {content}
        </div>
      </div>
    )
  }
}
