import React, { Component } from "react";
import { Modal } from "react-bootstrap";

import UserContext from "../../../../Context";

import Api from "../../../../../Api";

import classes from "./Modal.module.css";

export default class ModalName extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      id: 0,
      isEmail: false,
      data: [],
    };
    this.isValidate = this.isValidate.bind(this);
  }

  componentDidMount() {
    const contextValue = this.context;

    this.setState({
      isLoggedIn: contextValue.isLoggedIn,
      id: contextValue.idApplicant,
      data: contextValue.contentSection[9].sections.widget_contents,
    });
  }

  isValidate() {
    if (document.getElementById("m-email").value !== "") {
      return true;
    } else {
      this.setState({
        isEmail: true,
      });
      return false;
    }
  }

  updateProfile() {
    const update = new Api();
    const contextValue = this.context;

    if (this.isValidate()) {
      const updateData = {
        email: document.getElementById("m-email").value,
      };

      update
        .updateApplicant(this.state.id, updateData, "email")
        .then((res) => {
          this.props.close();
          contextValue.getNewDataApplicant();
          contextValue.showNotification(
            "Your email has been successfully updated."
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  render() {
    let info, cancel, save;
    this.state.data.forEach((x, i) => {
      switch (i) {
        case 8:
          info = x.content;
          break;
        case 12:
          cancel = x.content;
          break;
        case 13:
          save = x.content;
          break;
        default:
      }
    });
    let isError = this.state.isEmail ? classes.errorVisible : "";
    return (
      <Modal
        size={"md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.show}
        onHide={this.props.close}
      >
        <Modal.Header
          className={classes.modalHeader}
          onClick={this.props.close}
          closeButton
        >
          <span className={classes.modalTitle}>Email</span>
        </Modal.Header>
        <UserContext.Consumer>
          {(context) => (
            <div className={classes.modalContent}>
              <div className={classes.content}>
                <div className={classes.boxContent}>
                  <span className={classes.titleContent}>{info}</span>
                  <input
                    id="m-email"
                    className={classes.inputModal}
                    type={"text"}
                    defaultValue={
                      context.dataApplicant.user
                        ? context.dataApplicant.user[0].email
                        : ""
                    }
                  />
                </div>
                <span className={[classes.contentError, isError].join(" ")}>
                  This field is required
                </span>
              </div>
              <div className={classes.modalAction}>
                <div className={classes.cancel}>
                  <span>{cancel}</span>
                </div>
                <div
                  className={classes.save}
                  onClick={() => {
                    this.updateProfile();
                  }}
                >
                  <span>{save}</span>
                </div>
              </div>
            </div>
          )}
        </UserContext.Consumer>
      </Modal>
    );
  }
}
