import React, { Component, createRef } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import ReactHtmlParser from "react-html-parser";
import Api from "../Api";
import { faMapMarkerAlt, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "../Job_Details_New.module.css";
import JobBox from "../JobBox";
import BackBox from "../BackBox";
import TitleBox from "../TitleBox";
import Context from "../Context";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import ModalLogin from "../Modal/ModalLogin";
import ModalRegister from "../Modal/ModalRegister";
import ModalRegisterStep2 from "../Modal/ModalRegisterStep2";
import ModalPassword from "../Modal/ModalPassword";
import ModalShare from "../Modal/ModalShare";
import BoxNegotiation from "../BoxNegotiationNew";
import { Modal } from "react-bootstrap";
import cdd from "../Modal/ModalAttachment.module.css";

export default class JobDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            param: false,
            contentArticle: {
                widgets: [],
            },
            contentSection: [],
            backnext: [],
            asset: [],
            idSection: this.props.param.param2,
            isJobPosterLoaded: false,
            isSectionLoaded: false,
            isApiLoaded: false,
            id: "0",
            isModalLogin: false,
            isModalRegister: false,
            isModalAttachment: false,
            isModalRegisterStep2: false,
            isModalForgot: false,
            isModalShare: false,
            dataApplicant: {},
            isApplied: false,
            listJobs: [],
            data: null,
            isChanged: false,
            isApplieds: false,
            attachments: [],
            isAlreadyApplyOffer: false,
            idNow: null,
            id_applicants: 0,
            getNewDataApplicant: (idApplicant, callback) => {
                this.getDataApplicant(idApplicant, callback);
            },
            showNotification: (message) => {
                this.showNotification(message);
            },
            template: 41,
        };
        this.getArticleDetailByArticleId =
            this.getArticleDetailByArticleId.bind(this);
        this.getSectionDetail = this.getSectionDetail.bind(this);
        this.scrollingHandlerjdn = this.scrollingHandlerjdn.bind(this);
        this.handleOverride = this.handleOverride.bind(this);
        this.applyJob = this.applyJob.bind(this);
        this.toggleModalLogin = this.toggleModalLogin.bind(this);
        this.toggleModalRegister = this.toggleModalRegister.bind(this);
        this.toggleModalForgot = this.toggleModalForgot.bind(this);
        this.toggleModalClose = this.toggleModalClose.bind(this);
        this.toggleModalShare = this.toggleModalShare.bind(this);
        this.getOfferingData = this.getOfferingData.bind(this);
    }

    static contextType = Context;

    componentDidMount() {
        window.scrollTo(0, 0);
        this.getSectionDetail();
        let param = this.props.param.param2;
        if (param) {
            this.setState({
                param: param
            })
        }
        // this.getArticleDetailByArticleId(param);
        window.addEventListener("scroll", this.scrollingHandlerjdn, true);
        this.props.updateLogo(true);
        if (localStorage.getItem("isLoggedIn") === "true" && localStorage.getItem("id")) {
            this.getJobByApplicant(localStorage.getItem("id"));
            this.getDataApplicant(localStorage.getItem("id"));
            this.getOfferingData(localStorage.getItem("id"), param);
        }
    }

    componentWillReceiveProps(newProps) {
        if (newProps.id) {
            this.setState({
                id_applicants: newProps.id,
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollingHandlerjdn, true);
    }

    jdn_sec1_div1 = createRef();
    jdn_sec1_div2 = createRef();

    handleOverride() {
        this.props.override(false);
    }

    scrollingHandlerjdn() {
        if (
            this.jdn_sec1_div1.current.getBoundingClientRect().bottom <
            this.jdn_sec1_div1.current.getBoundingClientRect().height &&
            this.jdn_sec1_div1.current.getBoundingClientRect().bottom > -1
        ) {
            this.props.updateLogo(true);
        }

        if (
            this.jdn_sec1_div2.current.getBoundingClientRect().bottom <
            this.jdn_sec1_div2.current.getBoundingClientRect().height &&
            this.jdn_sec1_div2.current.getBoundingClientRect().bottom > -1
        ) {
            this.props.updateLogo(false);
        }
    }

    applyJob() {
        // Check isLoggedin and id
        if (
            localStorage.getItem("isLoggedIn") === "true" &&
            localStorage.getItem("id")
        ) {
            if (this.state.dataApplicant.attachments.length > 0) {
                this.toggleModalAttachment();
            } else {
                this.toggleModalRegisterStep2();
            }
        } else {
            this.toggleModalLogin();
        }
    }

    getDataApplicant(param, callback) {
        const login = new Api();

        login
            .getDataApplicant(param)
            .then((result) => {
                const data = result.data;
                const itm = data.user;
                const user = [];
                const bookmarks = [];

                user.push({
                    firstname: itm.first_name,
                    lastname: itm.last_name,
                    email: itm.email,
                    username: itm.username,
                    isActive: itm.is_active,
                });

                if (data.job_bookmarks) {
                    data.job_bookmarks.forEach((j) => {
                        bookmarks.push(j.job_poster);
                    });
                }

                data.user = user;
                data.bookmarks = bookmarks;
                return data;
            })
            .then((data) => {
                this.setState(
                    {
                        dataApplicant: data,
                        isLoggedIn: true,
                    },
                    callback
                );
            })
            .catch((error) => {
                console.log(error);
                // window.location.assign(`/error/${error ? (error.response.status ? error.response.status : 404) : 404}`);
            });
    }

    getHtmlParagraph(str) {
        if (str !== "") {
            return str
                .replace(/<\/?p>/gi, " ")
                .replace(/<p>/gi, " ")
                .replace(/&nbsp;/gi, " ");
        }
    }

    getJobByApplicant(id) {
        // alert("MASUK SINI");
        const countries = new Api();

        countries
            .getJobByApplicant(id)
            .then((res) => {
                const data = res.data;
                const jobs = [];

                data.results.forEach((a) => {
                    jobs.push({
                        id_applicant: a.applicant,
                        id: a.id,
                        apply_date: a.apply_date,
                        company_name: a.company_name,
                        confirmation_name: a.confirmation_name,
                        job_poster: a.job_poster,
                        job_title: a.job_title,
                        location_address: a.location_address,
                    });
                });
                this.setState({
                    listJobs: jobs,
                    isJobLoaded: true,
                });
            })
            .catch((err) => {
                console.log("error =>", err);
            });
    }

    getArticleDetailByArticleId(param) {
        const apiData = new Api();
        this.setState({ contentArticle: [] }, () => {
            apiData
                .getJobDetailById(param)
                .then((result) => {
                    return result.data;
                })
                .then((result) => {
                    if (!result) {
                        throw new Error("Job was not found");
                    }
                    const articles = result.article;
                    const jobDetail = result;
                    const art = [];
                    const assets = [];

                    articles.article_assets.forEach((ass, i) => {
                        if (i === 0) {
                            assets.push({
                                asset: ass.asset_file,
                                description: ass.description,
                            });
                        }
                    });

                    if (articles.article_languages && articles.article_languages.title && articles.article_languages.content) {
                        art.push({
                            id: articles.id,
                            category: articles.category_name,
                            name: jobDetail.position_name,
                            title: articles.article_languages.title,
                            content: articles.article_languages.content,
                            location: jobDetail.location_address,
                            author: articles.author_name,
                            company_name: jobDetail.company_name,
                            assets: assets,
                            post_date: jobDetail.post_date,
                            due_date: jobDetail.due_date,
                            min_salary: jobDetail.min_salary,
                            max_salary: jobDetail.max_salary,
                            currency_name: jobDetail.currency_name,
                            vacancies: jobDetail.vacancies,
                            job_type: jobDetail.job_type_name,
                        });
                        this.setState({
                            contentArticle: art,
                            asset: assets,
                            isJobPosterLoaded: true,
                        });
                    } else {
                        const { contentSection } = this.state
                        if (contentSection.length !== 0) {
                            window.location = contentSection[0]?.url ? contentSection[0].url : "/career"
                        } else {
                            window.location = "/career"
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        isApiLoaded: true,
                    });
                    // window.location.assign(`/error/${error ? (error.response ? error.response.status : 404) : 404}`)
                });
        });
    }

    getSectionDetail() {
        const apiData = new Api();

        apiData
            .getSectionDetail()
            .then((result) => {
                this.setState({
                    contentSection: [...result.data.widgets],
                    isSectionLoaded: true,
                    template: result.data.tenmplate,
                }, () => {
                    if (this.state.param) {
                        this.getArticleDetailByArticleId(this.state.param);
                    }
                });
            })
            .catch((error) => {
                this.setState({
                    isApiLoaded: true,
                });
                // window.location.assign(`/error/${error.response.status ? error.response.status : 404}`)
            });
    }

    salaryDot = (num) => {
        let salaryDot, localeString;
        let lang = localStorage.getItem("language");
        num == null ? (salaryDot = 0) : (salaryDot = num);
        lang === "English"
            ? (localeString = salaryDot.toLocaleString("en"))
            : (localeString = salaryDot.toLocaleString("vi"));

        return localeString;
    };

    toggleModalLogin() {
        this.setState((prevState) => {
            return {
                isModalRegister: false,
                isModalLogin: !prevState.isModalLogin,
            };
        });
    }

    toggleModalRegister() {
        this.setState((prevState) => {
            return {
                isModalLogin: false,
                isModalRegister: !prevState.isModalRegister,
            };
        });
    }

    toggleModalAttachment = () => {
        this.setState((prevState) => {
            return {
                isModalLogin: false,
                isModalRegister: false,
                isModalRegisterStep2: false,
                isModalAttachment: !prevState.isModalAttachment,
            };
        });
    };

    toggleModalRegisterStep2 = () => {
        this.getJobByApplicant(localStorage.getItem("id"));
        this.setState((prevState) => {
            return {
                isModalRegister: false,
                isModalLogin: false,
                isModalRegisterStep2: !prevState.isModalRegisterStep2,
                isApplied: true,
            };
        });
    };

    toggleModalForgot() {
        this.setState((prevState) => {
            return {
                isModalLogin: false,
                isModalForgot: !prevState.isModalForgot,
            };
        });
    }

    toggleModalClose() {
        this.setState({
            isModalRegister: false,
            isModalForgot: false,
            isModalLogin: false,
        });
    }

    toggleModalShare() {
        this.setState((prevState) => {
            return {
                isModalLogin: false,
                isModalShare: !prevState.isModalShare,
            };
        });
    }

    getOfferingData(idUser, id) {
        const get = new Api();
        get.getOfferingDataNew(idUser, id)
            .then((result) => {
                if (result.data.results.length !== 0) {
                    this.setState({
                        isAlreadyApplyOffer: true,
                    });
                } else {
                    this.setState({
                        isAlreadyApplyOffer: false,
                    });
                }
            })
            .catch((error) => {
                console.log("error =>" + error);
            });
    }

    showNotification = (message) => {
        store.addNotification({
            title: "Success!",
            message: message,
            type: "phenikaa",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 3000,
                onScreen: true,
            },
        });
    };

    actionCallback = () => {
        if (
            localStorage.getItem("isLoggedIn") === "true" &&
            localStorage.getItem("id")
        ) {
            this.getDataApplicant(localStorage.getItem("id"));
        }
    };

    include(arr, obj) {
        let obc = parseInt(obj);
        for (var i = 0; i < arr.length; i++) {
            if (arr[i] === obc) return true;
        }
    }

    handleCheckBox = (e) => {
        let setArr = new Set([...this.state.attachments]);
        let val = parseInt(e.target.value);
        if (e.target.checked) {
            setArr.add(val);
            this.setState({
                attachments: [...setArr],
            });
        } else {
            setArr.delete(val);
            this.setState({
                attachments: [...setArr],
            });
        }
    };

    submitAttachments = () => {
        const apply = new Api();

        apply
            .applyJobApplicant({
                applicant: localStorage.getItem("id"),
                job_poster: this.props.param.param2,
                attachments: this.state.attachments,
            })
            .then((res) => {
                this.toggleModalAttachment();
                this.setState({
                    isChanged: true,
                    isApplieds: true,
                });
                store.addNotification({
                    title: "Success!",
                    message: "Job has been applied.",
                    type: "phenikaa",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true,
                    },
                });
            })
            .catch((err) => {
                // this.props.close();
                store.addNotification({
                    title: "Error!",
                    message: "You have applied for this job.",
                    type: "danger",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 3000,
                        onScreen: true,
                    },
                });
            });
    };

    render() {
        const {
            contentArticle,
            contentSection,
            asset,
            isModalAttachment,
            isModalLogin,
            isModalRegister,
            isModalRegisterStep2,
        } = this.state;
        let title,
            content,
            titlejobDesc,
            benefit,
            benefitContent,
            jobDesc,
            post_date = { title: "", value: "" },
            due_date = { title: "", value: "" },
            salary = { title: "", value: "" },
            vacancies = { title: "", value: "" },
            job_type = { title: "" },
            reqContent,
            requirements,
            company_name,
            location,
            img,
            modals;

        let _jobBox,
            _apply,
            shareText,
            saveText,
            applyText,
            typeOfJob,
            savedText,
            min_salary_now,
            appliedText,
            boxNegotiation,
            backText = { content: "", url: "" };

        let contentUpload = [],
            contentLink = [];
        const file = this.state.dataApplicant.attachments;

        let submit2, cancels2, select2;

        if (this.state.isJobPosterLoaded && this.state.isSectionLoaded && contentArticle.length !== 0) {
            img = asset.length
                ? asset[0].asset
                : require("../assets/image.png");

            const a = contentArticle;
            const cs = contentSection;
            const jobInfo = cs[1].widget_contents;

            let dataErrorText = cs[9].widget_contents;
            let skills = cs[10].widget_contents;

            if (skills) {
                skills.forEach((ele, i) => {
                    switch (i) {
                        case 5:
                            select2 = ele.content;
                            break;
                        case 6:
                            submit2 = ele.content;
                            break;
                        case 8:
                            cancels2 = ele.content;
                            break;
                        default:
                    }
                });
            }

            company_name = a[0].company_name
                ? a[0].company_name
                : "Company Name";
            title = <h1 className={classes.title}>{a[0].title}</h1>;
            location = a[0].location ? (
                <>
                    <FontAwesomeIcon className="mr-2" icon={faMapMarkerAlt} />
                    <span>{a[0].location}</span>
                </>
            ) : (
                ""
            );

            const options = { year: "numeric", month: "long", day: "numeric" };
            const localDate =
                localStorage.getItem("language") === "English" ? "en" : "vi";
            post_date = {
                title: jobInfo[0].content,
                value: new Date(a[0].post_date).toLocaleDateString(
                    localDate,
                    options
                ),
            };
            due_date = {
                title: jobInfo[1].content,
                value: new Date(a[0].due_date).toLocaleDateString(
                    localDate,
                    options
                ),
            };

            salary = {
                title: jobInfo[2].content,
                value: `${this.salaryDot(a[0].min_salary)} ${a[0].currency_name
                    } - ${this.salaryDot(a[0].max_salary)} ${a[0].currency_name}`,
            };

            min_salary_now =
                this.salaryDot(a[0].min_salary) + " " + a[0].currency_name;

            vacancies = {
                title: jobInfo[3].content,
                value: a[0].vacancies,
            };
            job_type = {
                title: a[0].job_type,
            };

            typeOfJob = jobInfo[7].content;

            shareText = cs[2].widget_contents[0].content;
            saveText = cs[3].widget_contents[0].content;
            savedText = cs[3].widget_contents[1].content;
            applyText = cs[4].widget_contents[0].content;
            appliedText = cs[4].widget_contents[1].content;

            if (
                localStorage.getItem("isLoggedIn") === "true" &&
                localStorage.getItem("id")
            ) {
                if (this.state.isLoggedIn && this.state.dataApplicant) {
                    const dataN = [];
                    dataN.push({
                        company: company_name,
                        post_date: post_date.value,
                        min_salary: min_salary_now,
                    });

                    const jobIdHasApplied = [];
                    const id = [];

                    this.state.listJobs.forEach((ele, index) => {
                        id.push(ele.id);
                        jobIdHasApplied.push(ele.job_poster);
                    });

                    let ids = parseInt(this.props.param.param2);
                    let index1 = jobIdHasApplied.indexOf(ids);
                    let index2 = id[index1];
                    let idFixed = index2;
                    let isZero = false;

                    const id_applicant = parseInt(this.state.id_applicants);

                    if (
                        this.include(jobIdHasApplied, this.props.param.param2)
                    ) {
                        _apply = (
                            <div className={classes.btnApply2}>
                                <span>{appliedText}</span>
                            </div>
                        );

                        boxNegotiation = <></>;
                    } else {
                        _apply = (
                            <button
                                className={classes.btnApply}
                                onClick={this.applyJob}
                            >
                                <FontAwesomeIcon
                                    className="mr-2"
                                    icon={faArrowLeft}
                                />
                                {applyText}
                            </button>
                        );
                        if (a[0].min_salary === 0 || a[0].min_salary === null) {
                            if (
                                a[0].max_salary === 0 ||
                                a[0].max_salary === null
                            ) {
                                isZero = true;
                            }
                        } else {
                            isZero = false;
                        }

                        if (idFixed === undefined) {
                            if (isZero && this.state.isAlreadyApplyOffer) {
                                boxNegotiation = <></>;
                            } else if (
                                isZero &&
                                !this.state.isAlreadyApplyOffer
                            ) {
                                boxNegotiation = (
                                    <BoxNegotiation
                                        texts={cs[5].widget_contents}
                                        dataN={dataN}
                                        id={id_applicant}
                                        idJob={this.props.param.param2}
                                        salary={salary.value}
                                        negoText={cs[8].widget_contents}
                                        dataText={cs[7].widget_contents}
                                    />
                                );
                            } else {
                                boxNegotiation = <></>;
                            }
                        } else {
                            if (isZero) {
                                boxNegotiation = (
                                    <BoxNegotiation
                                        texts={cs[5].widget_contents}
                                        dataN={dataN}
                                        id={idFixed}
                                        idJob={this.props.param.param2}
                                        salary={salary.value}
                                        negoText={cs[8].widget_contents}
                                        dataText={cs[7].widget_contents}
                                    />
                                );
                            } else {
                                boxNegotiation = <></>;
                            }
                        }

                    }

                    _jobBox = (
                        <JobBox
                            data={this.state.dataApplicant}
                            getNewDataApplicant={this.actionCallback}
                            share={shareText}
                            save={saveText}
                            saved={savedText}
                            shareClick={this.toggleModalShare}
                            notifClick={this.showNotification}
                            idJob={this.props.param.param2}
                        />
                    );
                }
            } else {
                let isZero;

                if (a[0].min_salary === 0 || a[0].min_salary === null) {
                    if (a[0].max_salary === 0 || a[0].max_salary === null) {
                        isZero = true;
                    }
                } else {
                    isZero = false;
                }
                _apply = (
                    <button
                        className={classes.btnApply}
                        onClick={this.applyJob}
                    >
                        <FontAwesomeIcon className="mr-2" icon={faArrowLeft} />
                        {applyText}
                    </button>
                );

                if (isZero) {
                    boxNegotiation = (
                        <button
                            className={classes.btnNego}
                            onClick={this.applyJob}
                        >
                            {cs[8].widget_contents[0].content}
                        </button>
                    );
                }

                _jobBox = (
                    <JobBox
                        data={""}
                        share={shareText}
                        save={saveText}
                        saved={savedText}
                        shareClick={this.toggleModalShare}
                        notifClick={this.showNotification}
                        idJob={this.props.param.param2}
                    />
                );
            }

            backText = {
                content: cs[0].widget_contents[0].content,
                url: cs[0].url,
            };

            const splitStr = a[0].content ? a[0].content.split("#") : "";

            if (file) {
                file.forEach((f, i) => {
                    let name;
                    if (f.attachment_file) {
                        let splitStr = f.attachment_file
                            ? f.attachment_file.split("/")
                            : "x";
                        name = splitStr[splitStr.length - 1];

                        contentUpload.push(
                            <div className={classes.boxFile}>
                                <input
                                    type="checkbox"
                                    value={f.id}
                                    onChange={this.handleCheckBox}
                                />
                                <span>{name}</span>
                            </div>
                        );
                    } else if (f.attachment_link) {
                        name = f.attachment_link;

                        contentLink.push(
                            <div className={classes.boxFile}>
                                <input
                                    type="checkbox"
                                    value={f.id}
                                    onChange={this.handleCheckBox}
                                />
                                <span>{name}</span>
                            </div>
                        );
                    }
                });
            }

            content = (
                <p className={classes.content}>
                    {ReactHtmlParser(splitStr[0] ? splitStr[0] : "")}
                </p>
            );

            titlejobDesc = (
                <p className={classes.jobDesc}>
                    {ReactHtmlParser(splitStr[1] ? splitStr[1] : "")}
                </p>
            );

            jobDesc = (
                <p className={classes.reQ}>
                    {ReactHtmlParser(splitStr[2] ? splitStr[2] : "")}
                </p>
            );

            requirements = (
                <p className={classes.jobDesc}>
                    {ReactHtmlParser(splitStr[3] ? splitStr[3] : "")}
                </p>
            );

            reqContent = (
                <p className={classes.reQ}>
                    {ReactHtmlParser(splitStr[4] ? splitStr[4] : "")}
                </p>
            );

            benefit = (
                <p className={classes.joisApplDesc}>
                    {ReactHtmlParser(splitStr[5] ? splitStr[5] : "")}
                </p>
            );
            benefitContent = (
                <p className={classes.reQ}>
                    {ReactHtmlParser(splitStr[6] ? splitStr[6] : "")}
                </p>
            );

            let objectToPass = {
                dataApplicant: this.state.dataApplicant,
                getNewDataApplicant: this.state.getNewDataApplicant,
                jobId: this.props.param.param2,
            };
            modals = (
                <Context.Provider value={objectToPass}>
                    {/* <ModalAttachment
            show={this.state.isModalAttachment}
            close={this.toggleModalAttachment}
            dataErrorText={dataErrorText}
            skills={skills}
          /> */}
                    <ModalLogin
                        texts={cs[5].widget_contents}
                        show={this.state.isModalLogin}
                        loginClicked={this.toggleModalLogin}
                        registerClicked={this.toggleModalRegister}
                        continueClicked={this.applyJob}
                        forgotClicked={this.toggleModalForgot}
                        dataErrorText={dataErrorText}
                    />

                    <ModalRegister
                        texts={cs[6].widget_contents}
                        show={this.state.isModalRegister}
                        loginClicked={this.toggleModalLogin}
                        close={this.toggleModalLogin}
                        toggleModalRegisterStep2={this.toggleModalRegisterStep2}
                        continueClicked={this.applyJob}
                        dataErrorText={dataErrorText}
                    />
                    <ModalRegisterStep2
                        show={this.state.isModalRegisterStep2}
                        continueClicked={this.applyJob}
                        toggleModalRegisterStep2={this.toggleModalRegisterStep2}
                        dataErrorText={dataErrorText}
                        skills={skills}
                    />
                    <ModalPassword
                        texts={cs[5].widget_contents}
                        show={this.state.isModalForgot}
                        loginClicked={this.toggleModalLogin}
                        forgotClicked={this.toggleModalForgot}
                        close={this.toggleModalClose}
                        dataErrorText={dataErrorText}
                        template={this.state.template}
                    />
                    <ModalShare
                        show={this.state.isModalShare}
                        close={this.toggleModalShare}
                        dataErrorText={dataErrorText}
                    />
                </Context.Provider>
            );
        }

        if (this.state.isChanged) {
            _apply = (
                <div className={classes.btnApply2}>
                    <span>{appliedText}</span>
                </div>
            );
        }

        if (this.state.isApplieds) {
            boxNegotiation = <></>;
        }

        let backdropClass =
            isModalAttachment ||
                isModalLogin ||
                isModalRegister ||
                isModalRegisterStep2
                ? classes.backdropOn
                : "";

        return (
            <>
                <div className={backdropClass}></div>
                <ReactNotification
                    types={[
                        {
                            htmlClasses: [classes.notif],
                            name: "phenikaa",
                        },
                    ]}
                />
                <div className="blog">
                    <Modal
                        size={"md"}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={this.state.isModalAttachment}
                        onHide={this.toggleModalAttachment}
                        backdrop={false}
                    >
                        <Modal.Header closeButton>
                            <div className="d-flex justify-content-between">
                                <span className={cdd.modalTitle}>
                                    {select2}
                                </span>
                            </div>
                        </Modal.Header>
                        <div className={cdd.modalContent}>
                            <div className={cdd.content}>
                                <div>Link</div>
                                <div className={cdd.contentUpload}>
                                    {contentLink}
                                </div>
                                <div>File</div>
                                <div className={cdd.contentUpload}>
                                    {contentUpload}
                                </div>
                            </div>
                            <div className={cdd.modalAction}>
                                <div className={cdd.cancel}>
                                    <span>{cancels2}</span>
                                </div>
                                <div
                                    className={cdd.save}
                                    onClick={this.submitAttachments}
                                >
                                    <span>{submit2}</span>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {modals}
                    <div ref={this.jdn_sec1_div1} className={classes.boxBanner}>
                        <div className={classes.frontDiv}></div>
                        <img src={img} alt={`${asset[0]?.description}`} />
                        <div className={classes.navigator}>
                            {/*<BackNext />*/}
                        </div>
                    </div>

                    {/* <div ref={this.jdn_sec1_div2}>
            <TitleBox />
          </div> */}

                    <div ref={this.jdn_sec1_div2} className={classes.mainClass}>
                        <ParallaxProvider>
                            <React.Fragment>
                                <div
                                    className={[
                                        "container",
                                        classes.blogs,
                                    ].join(" ")}
                                >
                                    <div className={classes.boxContent}>
                                        <div className={classes.boxFlex}>
                                            <div className={classes.leftOnly}>
                                                {title}
                                            </div>
                                            {_jobBox}
                                        </div>

                                        <div
                                            className={
                                                classes.companyAndLocContainer
                                            }
                                        >
                                            <div className={classes.leftOnly}>
                                                <div>
                                                    <span>{company_name}</span>
                                                </div>
                                                <div className={classes.loc}>
                                                    {location}
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    classes.shareSaveApplyCon
                                                }
                                            >
                                                {_apply}
                                            </div>
                                        </div>
                                        <div className={classes.jobInfo}>
                                            <div
                                                className={classes.jobInfoTitle}
                                            >
                                                <p>{post_date.title}</p>
                                                <p>{due_date.title}</p>
                                                <p>{salary.title}</p>
                                                <p>{vacancies.title}</p>
                                                <p>{typeOfJob}</p>
                                            </div>
                                            <div className={classes.jobInfoVal}>
                                                <p>{post_date.value}</p>
                                                <p>{due_date.value}</p>
                                                <div className="d-flex flex-row">
                                                    <p>{salary.value}</p>

                                                    {boxNegotiation}
                                                </div>
                                                <p>{vacancies.value}</p>
                                                <p>{job_type.title}</p>
                                            </div>
                                        </div>
                                        <div className={classes.contP}>
                                            <p>&nbsp;</p>
                                            {content}
                                            <hr className={classes.line} />
                                            {titlejobDesc}
                                            {jobDesc}
                                            <hr className={classes.line} />
                                            {requirements}
                                            {reqContent}
                                            {benefit}
                                            {benefitContent}
                                        </div>
                                    </div>
                                </div>
                                <BackBox
                                    back={backText.content}
                                    url={
                                        backText.url !== ""
                                            ? backText.url
                                            : "/career"
                                    }
                                />
                            </React.Fragment>
                        </ParallaxProvider>
                    </div>
                </div>
            </>
        );
    }
}
