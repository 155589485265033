import React, { Component } from "react";
import classes from "./AD_Section1.module.css";
import Api from "./Api";
// import { Link } from "react-router-dom";

export default class Box2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: {
        widgets: [],
      },
      url: "",
      readMore: "",
      isLoaded: false,
      isApiLoaded: true,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }
  componentDidMount() {
    if (this.props.id) {
      let param = this.props.id;
      this.getSectionDetail(param);
      this.setState({ url: this.props.url });
    }
  }

  getSectionDetail(param) {
    const apiData = new Api();
    this.setState(
      {
        contentArticle: [],
      },
      () => {
        apiData
          .getSectionDetail(param)
          .then((result) => {
            const articles = result.data;

            // y.push({
            //   back: articles.widgets[0].widget_contents[0].content,
            //   url: articles.widgets[0].url,
            //   share: articles.widgets[1].widget_contents[0].content,
            // });

            this.setState({ article: articles.widgets, isLoaded: true });
          })
          .catch((error) => {
            this.setState({ isApiLoaded: true });
          });
      }
    );
  }
  render() {
    const { article } = this.state;

    let shareText,
      imgShare,
      dataShareIcon = null;

    if (this.state.isLoaded) {
      article.forEach((ele, index) => {
        switch (index) {
          case 0:
            // shareText = ele.widget_contents[0]
            //   ? ele.widget_contents[0].content
            //   : "";
            break;
          case 1:
            shareText = ele.widget_contents[0]
              ? ele.widget_contents[0].content
              : "";
            // code block
            break;
          case 2:
            break;
          case 3:
            dataShareIcon = ele.widget_contents.map((j, index) => {
              let url = j.url ? j.url : "/";
              imgShare = j.assets[0] ? j.assets[0].file : "";
              return (
                <a
                  key={j.title}
                  target="_blank"
                  href={url}
                  rel="noopener noreferrer"
                >
                  <img className={classes.icon} src={imgShare} alt="" />
                </a>
              );
            });

            break;
          default:
          // code block
        }
      });
    }
    return (
      <div className={classes.wrappbtnback2}>
        <div
          className={classes.share}
          style={{
            fontFamily: "Helvetica",
          }}
        >
          {shareText}: &nbsp;
          {dataShareIcon}
        </div>
      </div>
    );
  }
}
