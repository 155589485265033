import React, { Component } from "react";
import { Link } from "react-router-dom";

import Api from "../Api";
import classes from "./Desktop.module.css";
import NavBack from "../components/NavBack/NavBack"

export default class Section3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentSection: [],
      urlToDetail: '',
      isSectionLoaded: false,
      isArticleLoaded: false,
      isApiLoaded: false,
      backText: '',
      backUrl: '/',
      authorText: ''
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
    this.getAuthorsByWidget = this.getAuthorsByWidget.bind(this)
  }

  componentDidMount() {
    this.getSectionDetail(() => {
      this.getAuthorsByWidget(this.state.idBlogWidget)
    });
  }

  getSectionDetail(callback) {
    const apiData = new Api();
    apiData
      .getSectionDetail()
      .then((result) => {
        const sections = result.data;

        this.setState({
          contentSection: sections.widgets,
          idBlogWidget: sections.widgets[0].id,
          urlToDetail: sections.widgets[1].url,
          backUrl: sections.widgets[2].url,
          backText: sections.widgets[2].widget_contents[0].content,
          authorText: sections.widgets[3].widget_contents[0].content,
          isSectionLoaded: true,
        }, callback)
      })
      .catch((error) => {
        this.setState({
          isApiLoaded: true,
        });
      });
  }

  getAuthorsByWidget(param) {
    const apiData = new Api();
    apiData
      .getAuthorsByWidget(param)
      .then((result) => {
        const authors = result.data.results

        this.setState({
          authors: authors,
          isAuthorsLoaded: true,
        })
      })
      .catch((error) => {
        console.log(error)
      });
  }

  slugStr(str) {
    return str.toLowerCase().split(' ').join('-')
  }

  render() {
    let arrName = [], authorText
    if (this.state.isAuthorsLoaded) {
      arrName = this.state.authors.map(author => {
        const authorName = author.name ? author.name : "null"
        return (
          <Link
            className={classes.boxName}
            to={this.state.urlToDetail + "/" + this.slugStr(authorName) + "/" + author.id}
          >
            <p>{author.name}</p>
            <hr></hr>
            <div className={classes.imgCont}>
              <img
                className={classes.authorImg}
                src={author.avatar ? author.avatar : require('../assets/dummy-profile.jpg')}
                alt={author.name}
              ></img>
            </div>
          </Link>
        )
      })
    }

    authorText = <h1 className={classes.authorText}>{this.state.authorText}</h1>

    return (
      <div className={classes.contentSection}>
        <div className={classes.customContainer}>
          {authorText}
        </div>
        <div className={[classes.customContainer, classes.topContainer].join(' ')}>
          {arrName}
        </div>
        <div className={classes.customContainer}>
          <NavBack url={this.state.backUrl}>{this.state.backText}</NavBack>
        </div>
      </div>
    )
  }
}
