import React, { Component } from "react";

import { faTimes, faLink, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilePreviewerThumbnail } from "react-file-previewer";

import ModalResume from "./Modal/ModalResume";
import ModalSkill from "./Modal/ModalSkill";
import ModalGlobal from "./Modal/ModalGlobal";
import ModalPortofolio from "./Modal/ModalPortofolio";
import ModalExperience from "./Modal/ModalExperience";
import ModalEducation from "./Modal/ModalEducation";
import ModalUpdateExperience from "./Modal/ModalUpdateExperience";
import ModalUpdateEducation from "./Modal/modalUpdateEducation";

import UserContext from "../../Context";
import Api from "../../../Api";

import classes from "./Experience.module.css";

export default class Experience extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      modalResume: false,
      modalSkill: false,
      modalGlobal: false,
      modalPortofolio: false,
      modalExperience: false,
      modalEducation: false,
      modalUpdateExperience: false,
      modalUEIndex: "x",
      modalUpdateEducation: false,
      modalUEdIndex: "x",
      modalContent: "",
      id: 0,
    };
  }

  componentDidMount() {
    const contextValue = this.context;

    this.setState({
      id: contextValue.idApplicant,
    });
  }

  toggleShowHide(param, modal, content) {
    switch (param) {
      case "show":
        if (modal === "resume") {
          this.setState({ modalResume: true });
        }
        if (modal === "skill") {
          this.setState({ modalSkill: true });
        }
        if (modal === "portofolio") {
          this.setState({ modalPortofolio: true });
        }
        if (modal === "experience") {
          this.setState({ modalExperience: true });
        }
        if (modal === "education") {
          this.setState({ modalEducation: true });
        }
        if (modal === "updateExperience") {
          this.setState({
            modalUpdateExperience: true,
            modalUEIndex: content,
          });
        }
        if (modal === "updateEducation") {
          this.setState({
            modalUpdateEducation: true,
            modalUEdIndex: content,
          });
        }
        break;
      case "hide":
        if (modal === "resume") {
          this.setState({ modalResume: false });
        }
        if (modal === "skill") {
          this.setState({ modalSkill: false });
        }
        if (modal === "portofolio") {
          this.setState({ modalPortofolio: false });
        }
        if (modal === "experience") {
          this.setState({ modalExperience: false });
        }
        if (modal === "education") {
          this.setState({ modalEducation: false });
        }
        if (modal === "updateExperience") {
          this.setState({
            modalUpdateExperience: false,
            modalUEIndex: "x",
          });
        }
        if (modal === "updateEducation") {
          this.setState({
            modalUpdateEducation: false,
            modalUEdIndex: "x",
          });
        }
        break;
      default:
        break;
    }
  }

  deleteResume(param) {
    const resume = new Api();
    const contextValue = this.context;

    resume
      .deleteResume(param)
      .then((res) => {
        contextValue.getNewDataApplicant();
        contextValue.showNotification(
          "Your resume has been successfully deleted."
        );
      })
      .catch((err) => {
        console.log("error =>", err);
      });
  }

  deleteWorkingExp(param) {
    const working = new Api();
    const contextValue = this.context;

    working
      .deleteWorkingExp(param)
      .then((res) => {
        contextValue.getNewDataApplicant();
        contextValue.showNotification(
          "Your work experience has been successfully deleted."
        );
      })
      .catch((err) => {
        console.log("error =>", err);
      });
  }

  deleteLastEdu(param) {
    const education = new Api();
    const contextValue = this.context;

    education
      .deleteLastEdu(param)
      .then((res) => {
        contextValue.getNewDataApplicant();
        contextValue.showNotification("last education deleted successfully");
      })
      .catch((err) => {
        console.log("error =>", err);
      });
  }

  setThumbnail(str) {
    let arr = ["jpg", "png", "svg", "ico"];
    let file = str.split("/").pop();
    let ext = file.split(".").pop();
    let thumbnail = arr.includes(ext) ? (
      <FilePreviewerThumbnail
        file={{
          url: str,
        }}
      />
    ) : (
      <span>{ext}</span>
    );

    return thumbnail;
  }

  monthDiff(dateFrom, dateTo) {
    return (
      dateTo.getMonth() -
      dateFrom.getMonth() +
      12 * (dateTo.getFullYear() - dateFrom.getFullYear())
    );
  }

  render() {
    let working, education;

    const contextValue = this.context;
    if (
      contextValue.isLoaded &&
      contextValue.isLoggedIn &&
      contextValue.dataApplicant
    ) {
      working = contextValue.dataApplicant.experiences
        ? contextValue.dataApplicant.experiences.map((e, i) => {
            let dateFrom = new Date(e.date_start);
            let dateTo = new Date(e.date_finish);
            let diff = this.monthDiff(
              new Date(dateFrom.getFullYear(), dateFrom.getMonth()),
              new Date(dateTo.getFullYear(), dateTo.getMonth())
            );
            let months = (parseInt(diff) / 12).toFixed(1);
            let split = months.split(".");
            let year = split[0]
              ? `${split[0]}${split[0] === 1 ? "year" : "years"} `
              : "";
            let month = split[1]
              ? `${split[1]}${split[1] === 1 ? "month" : "months"}`
              : "";
            return (
              <div className={classes.boxWorking}>
                <div className={classes.boxWorkingContent}>
                  <span className={classes.workTitle}>{e.title}</span>
                  <span className={classes.workCompany}>{e.company}</span>
                  <span>
                    {year}
                    <span>{month}</span>
                  </span>
                </div>
                <div className={classes.boxWorkingAction}>
                  <div className="d-flex">
                    <div
                      className={classes.iconEditW}
                      onClick={() =>
                        this.toggleShowHide("show", "updateExperience", i)
                      }
                    >
                      <img src={require("../assets/edit.svg")} alt="" />
                    </div>
                    <div
                      className={classes.iconEditW}
                      onClick={() => {
                        this.deleteWorkingExp(e.id);
                      }}
                    >
                      <FontAwesomeIcon
                        color="#000000"
                        icon={faTrash}
                        size="sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : "-";
      education = contextValue.dataApplicant.educations
        ? contextValue.dataApplicant.educations.map((e, i) => {
            let dateFrom = new Date(e.date_start);
            let dateTo = new Date(e.date_finish);
            let year1 = dateFrom.getFullYear();
            let year2 = dateTo.getFullYear();
            let year = (
              <span className={classes.yearRange}>
                <em>
                  {" "}
                  ({year1} - {year2})
                </em>
              </span>
            );
            return (
              <div className={classes.boxWorking}>
                <div className={classes.boxWorkingContent}>
                  {/* <span className={classes.workTitle}>{e.level}</span> */}
                  <span className={classes.workCompany}>
                    {e.school} {year}
                  </span>
                  <span className={classes.workTitle}>{e.title}</span>
                </div>
                <div className={classes.boxWorkingAction}>
                  <div className="d-flex">
                    <div
                      className={classes.iconEditW}
                      onClick={() =>
                        this.toggleShowHide("show", "updateEducation", i)
                      }
                    >
                      <img src={require("../assets/edit.svg")} alt="" />
                    </div>
                    <div
                      className={classes.iconEditW}
                      onClick={() => {
                        this.deleteLastEdu(e.id);
                      }}
                    >
                      <FontAwesomeIcon
                        color="#000000"
                        icon={faTrash}
                        size="sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : "-";
    }

    let resume, portfolio, work, edu;
    if (contextValue.isSectionLoaded) {
      if (contextValue.contentSection[4]) {
        contextValue.contentSection[4].sections.widget_contents.forEach(
          (c, i) => {
            switch (i) {
              case 9:
                resume = c.content;
                break;
              case 10:
                portfolio = c.content;
                break;
              case 11:
                work = c.content;
                break;
              case 12:
                edu = c.content;
                break;
              default:
            }
          }
        );
      }
    }

    let contentModal = (
      <ModalGlobal
        show={this.state.modalGlobal}
        close={() => this.toggleShowHide("hide", "global")}
        content={this.state.modalContent}
      />
    );
    let modalUpdateExp =
      this.state.modalUEIndex !== "x" ? (
        <ModalUpdateExperience
          show={this.state.modalUpdateExperience}
          close={() => this.toggleShowHide("hide", "updateExperience")}
          index={this.state.modalUEIndex}
        />
      ) : (
        ""
      );
    let modalUpdateEdu =
      this.state.modalUEdIndex !== "x" ? (
        <ModalUpdateEducation
          show={this.state.modalUpdateEducation}
          close={() => this.toggleShowHide("hide", "updateEducation")}
          index={this.state.modalUEdIndex}
        />
      ) : (
        ""
      );
    return (
      <>
        <ModalResume
          show={this.state.modalResume}
          close={() => this.toggleShowHide("hide", "resume")}
        />
        <ModalSkill
          show={this.state.modalSkill}
          close={() => this.toggleShowHide("hide", "skill")}
        />
        <ModalPortofolio
          show={this.state.modalPortofolio}
          close={() => this.toggleShowHide("hide", "portofolio")}
        />
        <ModalExperience
          show={this.state.modalExperience}
          close={() => this.toggleShowHide("hide", "experience")}
        />
        <ModalEducation
          show={this.state.modalEducation}
          close={() => this.toggleShowHide("hide", "education")}
        />

        {modalUpdateExp}
        {modalUpdateEdu}
        {contentModal}

        <div className={classes.experience}>
          <div className={classes.firstBox}>
            <div className={classes.boxExp}>
              <div className={classes.boxTitle}>
                <p className={classes.contentTitle}>{resume}</p>
                <div
                  className={classes.boxAction}
                  onClick={() => this.toggleShowHide("show", "resume")}
                >
                  <img src={require("../assets/add.svg")} alt="" />
                </div>
              </div>
              <hr />
              <div className={classes.boxContent}>
                <UserContext.Consumer>
                  {(context) =>
                    context.dataApplicant.attachments
                      ? context.dataApplicant.attachments.map((c) => {
                          let arr = ["jpg", "png", "svg", "ico"];
                          if (c.attachment_type === "Resume") {
                            let str = c.attachment_file;
                            let file = str.split("/").pop();
                            let ext = file.split(".").pop();
                            let thumbnail = arr.includes(ext) ? (
                              <FilePreviewerThumbnail
                                file={{
                                  url: str,
                                }}
                              />
                            ) : (
                              <span>{ext}</span>
                            );
                            return (
                              <div className={classes.boxResume}>
                                <div className={classes.boxPreview}>
                                  {thumbnail}
                                </div>
                                <div className={classes.boxDetailPreview}>
                                  <span className={classes.wrap_teks}>
                                    {file}
                                  </span>
                                  <div className="d-flex">
                                    <a
                                      className={classes.customLink}
                                      href={str}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span className={classes.wrap_teks2}>
                                        View resume
                                      </span>
                                    </a>
                                    <img
                                      src={require("../assets/arrowR.svg")}
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div
                                  className={classes.boxRemove}
                                  onClick={() => {
                                    this.deleteResume(c.id);
                                    context.getNewDataApplicant();
                                  }}
                                >
                                  <FontAwesomeIcon
                                    color="#000000"
                                    className={classes.iconClose}
                                    icon={faTimes}
                                    size="md"
                                  />
                                </div>
                              </div>
                            );
                          }
                        })
                      : ""
                  }
                </UserContext.Consumer>
              </div>
            </div>
            <div className={classes.boxExp}>
              <div className={classes.boxTitle}>
                <p className={classes.contentTitle}>{portfolio}</p>
                <div
                  className={classes.boxAction}
                  onClick={() => this.toggleShowHide("show", "portofolio")}
                >
                  <img src={require("../assets/add.svg")} alt="" />
                </div>
              </div>
              <hr />
              <div className={classes.boxContent}>
                <div className={classes.boxPortofolio}>
                  <UserContext.Consumer>
                    {(context) =>
                      context.dataApplicant.attachments
                        ? context.dataApplicant.attachments.map((c) => {
                            if (c.attachment_type === "Portfolio") {
                              let content;

                              // let file = str.split('/').pop()
                              if (c.attachment_file) {
                                let str = c.attachment_file;

                                let thumb = this.setThumbnail(str);
                                content = (
                                  <div className={classes.boxPreview}>
                                    <a
                                      className={classes.customLink}
                                      href={str}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {thumb}
                                    </a>
                                    <div
                                      className={classes.boxRemovePortofolio}
                                      onClick={() => {
                                        this.deleteResume(c.id);
                                        context.getNewDataApplicant();
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        color="#000000"
                                        className={classes.iconClose}
                                        icon={faTimes}
                                        size="md"
                                      />
                                    </div>
                                  </div>
                                );
                              } else if (c.attachment_link) {
                                let str = c.attachment_link;
                                content = (
                                  <div className={classes.boxPreview}>
                                    <a
                                      className={classes.customLink}
                                      href={str}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <FontAwesomeIcon
                                        className={classes.iconLink}
                                        icon={faLink}
                                        size="lg"
                                      />
                                    </a>
                                    <div
                                      className={classes.boxRemovePortofolio}
                                      onClick={() => {
                                        this.deleteResume(c.id);
                                        context.getNewDataApplicant();
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        color="#000000"
                                        className={classes.iconClose}
                                        icon={faTimes}
                                        size="md"
                                      />
                                    </div>
                                  </div>
                                );
                              }
                              return <>{content}</>;
                            }
                          })
                        : ""
                    }
                  </UserContext.Consumer>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.secondBox}>
            <div className={classes.boxExp}>
              <div className={classes.boxTitle}>
                <p className={classes.contentTitle}>{work}</p>
                <div
                  className={classes.boxAction}
                  onClick={() => this.toggleShowHide("show", "experience", "")}
                >
                  <img src={require("../assets/add.svg")} alt="" />
                </div>
              </div>
              <hr />
              <div className={classes.boxContent}>{working}</div>
            </div>

            <div className={classes.boxExp}>
              <div className={classes.boxTitle}>
                <p className={classes.contentTitle}>{edu}</p>
                <div
                  className={classes.boxAction}
                  onClick={() => this.toggleShowHide("show", "education")}
                >
                  <img src={require("../assets/add.svg")} alt="" />
                </div>
              </div>
              <hr />
              <div className={classes.boxContent}>{education}</div>
            </div>
          </div>

          {/* <div className={classes.thirdBox}>
                        <div className={classes.boxExp}>
                            <div className={classes.boxTitle}>
                                <p className={classes.contentTitle}>Skills</p>
                                <div className={classes.boxAction} onClick={() => this.toggleShowHide('show', 'skill')}>
                                    <img src={require('../assets/add.svg')} alt="" />
                                </div>
                            </div>
                            <hr />
                            <div className={classes.boxContent}>
                                <div className={classes.skills}>
                                    <div className={classes.boxSkills}>
                                        <span>Digital Marketing</span>
                                    </div>
                                    <div className={classes.boxSkills}>
                                        <span>Business Development</span>
                                    </div>
                                    <div className={classes.boxSkills}>
                                        <span>Project management</span>
                                    </div>
                                    <div className={classes.boxSkills}>
                                        <span>Content Marketing</span>
                                    </div>
                                    <div className={classes.boxSkills}>
                                        <span>Design</span>
                                    </div>
                                    <div className={classes.boxSkills}>
                                        <span>Microsoft Office</span>
                                    </div>
                                    <div className={classes.boxSkills}>
                                        <span>HTML & CSS3</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </div>
      </>
    );
  }
}
