import React, { Component } from "react";
import css from "./Login.module.css";
import Api from "./Api";
import { Link } from "react-router-dom";
import ModalSuccess from "./Modal/ModalSuccess";
import ModalPassword from "./Modal/ModalPassword";
import Context from "./Context";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEmpty: false,
      reqEmail: null,
      reqPwd: null,
      dataApplicant: null,
      template: 41
    };

    this.loggedIn = this.loggedIn.bind(this);
    this.checkEmail = this.checkEmail.bind(this);
    this.toggleModalForgot = this.toggleModalForgot.bind(this);
    this.toggleModalClose = this.toggleModalClose.bind(this);
    this.toggleModalForgot2 = this.toggleModalForgot2.bind(this);
    this.modalSuccessClosed = this.modalSuccessClosed.bind(this);
    this.keyLoggedIn = this.keyLoggedIn.bind(this);
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  static contextType = Context;

  componentDidMount() {
    this.getSectionDetail()
  }

  getSectionDetail(callback) {
    const apiData = new Api();
    apiData
      .getSectionDetail()
      .then((result) => {
        const sections = result.data;

        this.setState({
          template: sections.template,
        })
      })
      .catch((error) => {
        console.log(error);
      });
  }

  checkEmail(param) {
    let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (!param.match(mailformat)) {
      return false;
    } else {
      return true;
    }
  }

  keyLoggedIn(e) {
    if (e.keyCode === 13) {
      this.loggedIn()
    }
  }

  loggedIn() {
    const login = new Api();
    const loginData = {
      email: document.getElementById("email").value,
      password: document.getElementById("password").value,
    };

    if (!loginData.email) {
      this.setState({
        reqEmail: "* Email is required",
      });
      // isValid = false;
    } else if (loginData.email) {
      if (this.checkEmail(loginData.email)) {
        this.setState({
          reqEmail: null,
        });
        // return true
      } else {
        this.setState({
          reqEmail: "* Email invalid address",
        });
        // isValid = false;
      }
    }

    if (!loginData.password) {
      this.setState({
        reqPwd: "* Password is required",
        isEmpty: true,
      });
    } else {
      login
        .getApplicant(loginData)
        .then((result) => {
          const data = result.data;
          if (data) {
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("token", data.key);
            localStorage.setItem("id", data.applicant);
            window.location.assign(`/career-new`);
          }
        })
        .catch((error) => {
          if (error.response.data.non_field_errors) {
            this.setState({
              reqPwd: "* Wrong password",
              isEmpty: true,
            });
          } else {
            this.setState({
              isEmpty: false,
            });
          }
        });
    }
  }

  toggleModalForgot() {
    this.setState((prevState) => {
      return {
        // isModalSuccess: false,
        isModalForgot: !prevState.isModalForgot,
        isModalSuccess: prevState.isModalSuccess,
      };
    });
  }

  toggleModalForgot2() {
    this.setState((prevState) => {
      return {
        isModalForgot: !prevState.isModalForgot,
        isModalSuccess: !prevState.isModalSuccess,
      };
    });
  }

  toggleModalClose() {
    this.setState({
      isModalForgot: false,
      // isModalSuccess: false,
    });
  }

  modalSuccessOpened() {
    this.setState((prevState) => {
      return {
        isModalSuccess: !prevState.isModalSuccess,
        isModalForgot: !prevState.isModalForgot,
      };
    });
  }
  modalSuccessClosed() {
    this.setState({
      isModalSuccess: false,
      isModalForgot: false,
    });
  }

  render() {
    let errorActive = this.state.isEmpty ? css.errorActive : "";

    let modal = (
      <Context.Provider>
        <ModalPassword
          show={this.state.isModalForgot}
          loginClicked={this.toggleModalForgot}
          forgotClicked={this.toggleModalForgot}
          isOpen={this.modalSuccessOpened}
          close={this.toggleModalClose}
          close2={this.toggleModalForgot2}
          template={this.state.template}
        />
        <ModalSuccess
          show={this.state.isModalSuccess}
          isOpen={this.modalSuccessOpened}
          isClosed={this.modalSuccessClosed}
        />
      </Context.Provider>
    );

    return (
      <div className={["container", css.pad25].join(" ")}>
        {modal}
        <div className={css.boxBig}>
          <div className="d-flex justify-content-center flex-column">
            <span className={css.modalTitle}>Sign in</span>
            <div className="d-flex">
              <span className={css.option}>New user?</span>
              <Link to={"/register"}>
                <span className={css.contentLink}>Create an account</span>
              </Link>
            </div>
          </div>
          <div className={css.modalContent}>
            <div className={css.content}>
              <div className={css.boxContent}>
                <span className={css.titleContent}>Email address</span>
                <input
                  id="email"
                  className={css.inputModal}
                  type={"text"}
                // defaultValue={"admin@test.com"}
                />
              </div>
              <h2 className={[css.validError, errorActive].join("")}>
                {this.state.reqEmail}
              </h2>
              <div className={css.boxContent}>
                <span className={css.titleContent}>Password</span>
                <input
                  id="password"
                  className={css.inputModal}
                  type={"password"}
                  // defaultValue={"anotherpassword"}
                  onKeyDown={this.keyLoggedIn}
                />
              </div>
              <h2 className={[css.validError, errorActive].join("")}>
                {this.state.reqPwd}
              </h2>

              <div className={css.contentAction}>
                <span
                  className={css.contentLink}
                  onClick={this.toggleModalForgot}
                >
                  Forgot password?
                </span>
                <div className={css.boxAction} onClick={this.loggedIn}>
                  <span>Continue</span>
                </div>
              </div>
            </div>

            <span className={css.option}>
              Protected by reCAPTCHA and subject to the Google Privacy Policy
              and Terms of Service.
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
