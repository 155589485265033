import React, { Component } from "react";
import { Link } from "react-router-dom";

import Api from "../Api";
import classes from "./IN_View_Detail.module.css";

import AuthorBox from '../components/AuthorBox/AuthorBox'
import Article from '../components/Article/Article'
import Banner from '../components/Banner/Banner'
import NavBack from '../components/NavBack/NavBack'

export default class Desktop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentSection: [],
      dataUrl: '',
      readMoreTxt: '',
      isSectionLoaded: false,
      isArticleLoaded: false,
      isApiLoaded: false,
      backUrl: '/',
      backText: '',
      params: {
        title: this.props.param.param1 ? this.props.param.param1 : "",
        id: this.props.param.param2 ? this.props.param.param2 : ""
      }
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
    this.getArticleDetailByArticleId = this.getArticleDetailByArticleId.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
    this.getArticleDetailByArticleId(this.state.params.id)
  }

  getSectionDetail() {
    const apiData = new Api();
    apiData
      .getSectionDetail()
      .then((result) => {
        const sections = result.data;

        this.setState({
          contentSection: sections.widgets,
          isSectionLoaded: true,
          backUrl: sections.widgets[0].url,
          backText: sections.widgets[0].widget_contents[0].content,
        })
      })
      .catch((error) => {
        this.setState({

          isApiLoaded: true,
        });
      });
  }

  getArticleDetailByArticleId(param) {
    const apiData = new Api();
    apiData
      .getArticleDetailByArticleId(param)
      .then((result) => {

        const article = result.data;

        this.setState({
          article: article,
          isArticleLoaded: true,
        })
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          isApiLoaded: true,
        });
      });
  }

  render() {
    let authorBox, article, banner
    if (this.state.isArticleLoaded) {
      let art = this.state.article;
      let bannerAsset = art.article_assets.length ? art.article_assets[0].file_big : require("../assets/image.png")
      banner = banner = <Banner bannerUrl={bannerAsset} />
      authorBox = <AuthorBox name={art.author_name} avatar={art.author_avatar ? art.author_avatar : require('../assets/dummy-profile.jpg')} />
      article = <Article article={art.article_languages} date={art.date} />

    }
    return (
      <>
        {banner}
        <div className={classes.contentSection}>
          <div className={[classes.customContainer, classes.topContainer].join(' ')}>
            <div className={classes.col1}>
              {authorBox}
            </div>
            <div className={classes.col2}>
              {article}
            </div>
            <div className={classes.col3}></div>
          </div>
          <div className={classes.customContainer}>
            <NavBack url={this.state.backUrl}>{this.state.backText}</NavBack>
          </div>
        </div>
      </>
    )
  }
}
