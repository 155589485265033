import React, { Component, Fragment } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import ReactNotification, { store } from "react-notifications-component";

import Api from "./Api";
import Views from "./Views/Views";
import Core from "./Core";

import "react-notifications-component/dist/theme.css";
import classes from "./News.module.css";

class Desktop extends Component {
    constructor() {
        super();
        this.state = {
            currentPage: null,

            tabs: [
                {
                    id: 1,
                    title: "All news & Events",
                    component: <div key={1} />,
                    logo: (
                        <img
                            className={classes.button_tab}
                            src={require("./assets/Polygon7.svg")}
                            alt="description"
                        />
                    ),
                },
            ],
            sections: {
                widgets: [],
            },
            isLoaded: false,
            isApiLoaded: false,
            views: null,
            activePage: <div></div>,
            valueQuery: null,
            articleID: null,
            sectionName: "",
            idArticle: null,
            topCorePos: 0,
            sectionNameN: "",
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
        this.handleOverride = this.handleOverride.bind(this);
    }

    componentDidMount() {
        let id = this.props.id;
        this.getSectionDetail(id);
        this.handleOverride();
    }

    handleOverride() {
        this.props.override(false);
    }

    getSectionDetail(id) {
        const apiData = new Api();
        this.setState(
            {
                sections: [],
            },
            () => {
                apiData
                    .getSectionDetail(id)
                    .then((result) => {
                        this.setState({ isApiLoaded: true });
                        const sections = result.data;
                        let sectionName = sections.name
                            .toLowerCase()
                            .replace(/\s+/g, "-");

                        const widget = [];
                        sections.widgets.forEach((item) => {
                            widget.push({
                                id: item.id,
                                index: item.index,
                                category: item.category,
                                name: item.name,
                                sections: item,
                            });
                        });

                        this.setState({
                            sectionName: result.data.name,
                            sections: widget,
                            isLoaded: true,
                            idArticle: result.data.id,
                            articleID: widget[0].id,
                            sectionNameN: sectionName,
                        });
                    })

                    .catch((error) => {
                        this.setState({ isApiLoaded: true });
                    });
            }
        );
    }

    onCopy = () => {
		this.setState(
			{
				isCopied: true,
			},
            () => {
                this.showNotification('Link has copied')
            }
		);
	};

    showNotification(message) {
		store.addNotification({
			title: "Success!",
			message: message,
			type: "phenikaa",
			insert: "bottom",
			container: "bottom-right",
			animationIn: ["animate__animated", "animate__fadeIn"],
			animationOut: ["animate__animated", "animate__fadeOut"],
			dismiss: {
				duration: 3000,
				onScreen: true,
			},
        }, () => {
            this.setState({
				isCopied: false
            })
        });
	}

    render() {
        const { sections } = this.state;
        let readMore, share, searchHere, newsButton, textSearch, filterMagazine;
        let imageMagazine = [],
            fileMagazine = [];

        let viewOnchange;

        if (this.state.isLoaded) {
            var articleIds = [];
            var article_categories = [];
            let defaultCategories;
            let url;
            viewOnchange = sections.map((item) => {
                switch (item.index) {
                    case 1:
                        readMore = item.sections.widget_contents[0]
                            ? item.sections.widget_contents[0].content
                            : "Read Less";
                        break;
                    case 2:
                        share = item.sections.widget_contents[0]
                            ? item.sections.widget_contents[0].content
                            : "Don't share";
                        break;
                    case 3:
                        searchHere = item.sections.widget_contents[0]
                            ? item.sections.widget_contents[0].content
                            : "Don't search";
                        break;
                    case 4:
                        newsButton = item.sections.widget_contents[0]
                            ? item.sections.widget_contents[0].content
                            : "Less news";
                        break;
                    case 5:
                        textSearch = item.sections.widget_contents[0]
                            ? item.sections.widget_contents[0].content
                            : "Search here. . .";
                        break;
                    case 6:
                        url = item.sections.url
                            ? item.sections.url
                            : "/news/detail/";
                        articleIds.push(item.id);
                        item.sections.article_categories.forEach((category) => {
                            if (!article_categories.includes(category)) {
                                article_categories.push(category);
                            }
                        });

                        break;
                    case 7:
                        url = item.sections.url
                            ? item.sections.url
                            : "/news/detail/";
                        articleIds.push(item.id);
                        item.sections.article_categories.forEach((category) => {
                            if (!article_categories.includes(category)) {
                                article_categories.push(category);
                                // defaultCategories = defaultCategories + category
                            }
                        });

                        break;
                    case 8:
                        filterMagazine = item.sections.widget_contents[0]
                            ? item.sections.widget_contents[0].content
                            : "Phenikaa Magazine";

                        break;
                    case 9:
                        item.sections.widget_contents.forEach((image) => {
                            let imgFile;
                            let textDesc = null;

                            if (image.file_small !== null) {
                                imgFile = image.file_small;
                                textDesc = image.description;
                            } else {
                                imgFile = "";
                                return textDesc;
                            }

                            imageMagazine.push([imgFile, textDesc]);
                        });
                        break;
                    case 10:
                        item.sections.widget_contents.forEach((file) => {
                            let pdfFile, textDescFile;
                            if (file.file !== null) {
                                pdfFile = file.file;
                                textDescFile = file.description;
                            } else {
                                pdfFile = "";
                                textDescFile = "";
                            }

                            fileMagazine.push([pdfFile, textDescFile]);
                        });

                        let filePackage = [];

                        imageMagazine.forEach((item, index) => {
                            var image = item[0];
                            var file = fileMagazine[index]
                                ? fileMagazine[index][0]
                                : "";
                            var desc = fileMagazine[index]
                                ? fileMagazine[index][1]
                                : "";

                            filePackage.push([image, file, desc]);
                        });
                        let data;
                        if (url) {
                            data = url;
                        }
                        return (
                            <Views
                                id={articleIds}
                                idArt={this.state.idArticle}
                                sectionNameN={this.state.sectionNameN}
                                url={data}
                                category={article_categories.sort()}
                                filterMagazine={filterMagazine}
                                fileMagazine={filePackage}
                                view={"grid"}
                                readMore={readMore}
                                share={share}
                                title={this.state.sectionName}
                                searchHere={searchHere}
                                newsButton={newsButton}
                                textSearch={textSearch}
                                isTablet={this.props.isTablet}
                                onCopy={this.onCopy}
                            />
                        );
                    default:
                }
            });
        } else if (this.state.isApiLoaded) {
            // viewOnchange = dummyContentList
        }

        let navItem = this.state.tabs.map((n) => {
            let key = "tab" + n.id;
            let activeTab =
                this.state.tabsActive === n.id ? classes.isActive : "";
            return (
                <div
                    key={n.id}
                    className={[classes.box, activeTab].join(" ")}
                    onClick={() => this.toggleActiveHandler(n.component, n.id)}
                >
                    <span id={key}>{n.title}</span>
                    <div className={classes.btn_wrap}>{n.logo}</div>
                </div>
            );
        });

        return (
            <>
                <ReactNotification
					types={[
						{
							htmlClasses: [classes.phenikaaNotif],
							name: "phenikaa",
						},
					]}
				/>
                <div className="page-news">
                    <div className={classes.main}>
                        <ParallaxProvider>
                            <Fragment>
                                {/* <BackgroundMask /> */}
                                <div className={classes.content}>
                                    <div
                                        className={[
                                            "container",
                                            classes.boxC,
                                        ].join(" ")}
                                    >
                                        {viewOnchange}
                                    </div>
                                </div>
                            </Fragment>
                        </ParallaxProvider>
                    </div>
                </div>
                <div className={classes.coreCon}>
                    <div className={classes.Core}>
                        <Core />
                    </div>
                </div>
            </>
        );
    }
}

export default Desktop;
