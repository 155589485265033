import React, { Component } from "react"
import Api from "./Api"
import classes from "./development.module.css"

export default class BoxImage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contentArticle: [],
      aboutPhenikaa: [],
      isLoaded: false,
      isApiLoaded: true,
    }
    this.getArticleDetail = this.getArticleDetail.bind(this)
  }

  componentDidMount() {
    if (this.props.id) {
      this.getArticleDetail(this.props.id)
    }
  }

  getArticleDetail(param) {
    const apiData = new Api()
    this.setState({ contentArticle: [] }, () => {
      apiData
        .getArticleDetail(param)
        .then((result) => {
          const articles = result.data
          const assets = []
          articles.results.forEach((a) => {
            assets.push({
              title: a.article_languages.title,
              content: a.article_languages.content,
              asset: a.article_assets[0],
            })
          })

          this.setState({
            contentArticle: assets,
            isLoaded: true,
          })
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          })
        })
    })
  }

  render() {
    const { contentArticle, isLoaded } = this.state

    let img_article = null

    if (isLoaded) {
      contentArticle.map((z) => {
        img_article = z.asset ? <img className={classes.imgContent} src={z.asset.file_big} alt={z.asset.description} /> : <img className={classes.imgContent} src={require("./assets/default-image.svg")} alt='' />
      })
    }

    return <>{img_article}</>
  }
}
