import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Slide } from "react-awesome-reveal";
import Sliders from "./Slider";
import Aux from "./Auxs";
import Api from "./Api";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SectionThree.css";
import classes from "./SectionThree.module.css";
import dateFormat from "dateformat";

export default class BoxTop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentArticle: {
                widgets: [],
            },
            url: "",
            readMore: "Read More",
            isLoaded: false,
            isApiLoaded: true,

            data: [],
            lang: "",
        };
        this.getArticleDetail = this.getArticleDetail.bind(this);
        this.setCurrentLink = this.setCurrentLink.bind(this);
    }

    componentDidMount() {
        this.getArticleDetail(this.props.idx);
        this.setState({
            url: this.props.url,
            readMore: this.props.readMore,
            lang: localStorage.getItem("language"),
        });
    }

    getArticleDetail(param) {
        const apiData = new Api();
        this.setState({ contentArticle: [] }, () => {
            apiData
                .getArticleDetailByYearDescending(param, null, 12, null)
                .then((result) => {
                    const articles = result.data;
                    const art = [];
                    result.data.results.forEach((a) => {
                        const assets = [];
                        // a.article_assets.forEach((ass, i) => {
                        //     if (
                        //         !assets.length &&
                        //         (ass.asset_type === "Image" ||
                        //             ass.asset_file.indexOf(".jpeg") !== 0)
                        //     ) {
                        //         assets.push({
                        //             asset: ass.file_small || ass.asset_file,
                        //             asset_type: ass.asset_type,
                        //             description: ass.description,
                        //         });
                        //     } else if (
                        //         !assets.length &&
                        //         ass.asset_link &&
                        //         ass.asset_link.indexOf("youtube.com") !== -1
                        //     ) {
                        //         let _asset = ass;
                        //         _asset = _asset.split("/");
                        //         let ytVideoId = _asset[_asset.length - 1];
                        //         assets.push({
                        //             asset: `https://img.youtube.com/vi/${ytVideoId}/hqdefault.jpg`,
                        //             asset_type: ass.asset_type,
                        //             description: ass.description,
                        //         });
                        //     }
                        // });

                        art.push({
                            id: a.id,
                            category: a.category_names,
                            categoryID: a.categories,
                            name: a.name,
                            title: a.article_languages.title,
                            url_title: a.article_languages.slug_title,
                            content: a.article_languages.content,
                            assets: a.article_assets[0],
                            date: a.date,
                        });
                    });

                    this.setState({
                        contentArticle: art,
                        data: result.data.results,
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                    this.setState({
                        isApiLoaded: true,
                    });
                });
        });
    }

    setCurrentLink() {
        localStorage.setItem(
            "prevLink",
            `${window.location.href}${this.props.sectionName ? `#${this.props.sectionName}` : ""
            }`
        );
    }

    render() {
        const { contentArticle, data } = this.state;
        let box = null;

        if (this.state.isLoaded) {
            box = contentArticle.map((s, i) => {
                let widget0, widget1;

                if (this.props.id) {
                    this.props.id.forEach((ele, i) => {
                        if (i === 0) {
                            widget0 = ele;
                        } else {
                            widget1 = ele;
                        }
                    });
                }

                let dateFull =
                    this.state.lang === "Vietnamese"
                        ? dateFormat(s.date, "dd-mm-yyyy")
                        : dateFormat(s.date, "mm-dd-yyyy");

                let adjClass = s.content ? "pb-0" : "";

                let isBoxImage = null;

                let srcImage = s.assets?.asset_thumbnail
                    ? s.assets?.asset_thumbnail
                    : s.assets?.file_small
                        ? s.assets?.file_small
                        : require("./assets/default-image.svg");
                let altImage =
                    s.assets?.length > 0
                        ? s.assets[0].description
                        : "Default image";
                isBoxImage = (
                    <img
                        className={classes.imgOnBox}
                        src={srcImage}
                        alt={altImage}
                    />
                );
                let firstClass = i === 0 ? classes.firstWrap : "";
                let isImage = (
                    <div
                        className={[classes.ContentWrapp, firstClass].join(" ")}
                    >
                        <Card className={classes.cardfront}>
                            <Card.Body
                                className={[classes.cardFd1, adjClass].join(
                                    " "
                                )}
                            >
                                <div className={classes.cardcontent}>
                                    <p>{s.title}</p>
                                </div>
                            </Card.Body>
                            <div className={classes.cardFd}>
                                <p className={[classes.date, "left"].join(" ")}>
                                    {dateFull}
                                </p>
                            </div>
                        </Card>
                        <Card className={classes.cardtemplateback}>
                            <div className={classes.contentImage}>
                                {isBoxImage}
                            </div>
                            <div className={classes.cardfooterback}>
                                <Link
                                    onClick={() => this.setCurrentLink()}
                                    className={classes.customLink}
                                    to={`${this.state.url}${s.url_title !== "" ? s.url_title : "-"
                                        }/${s.id}`}
                                >
                                    <div className={classes.btnRead}>
                                        <p>{this.state.readMore}</p>
                                    </div>
                                </Link>
                            </div>
                        </Card>
                    </div>
                );

                return <Aux key={s.title}>{isImage}</Aux>;
            });
        } else {
            // console.log("api not loaded");
        }

        return (
            <>
                <Slide triggerOnce direction="top">
                    <Sliders setting={"top"} content={box} />
                </Slide>
            </>
        );
    }
}
