import React, { Component } from "react";
import classes from "./MobileBoxNegotiation.module.css";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import css from "../Modal/TableNegotiation.module.css";
import DataTableExtensions from "react-data-table-component-extensions";
import "../Modal/TableNego.css";
import Api from "../Api";
import ModalSuccess from "../Modal/ModalSuccess";
import dateFormat from "dateformat";
import classes2 from "../Modal/ModalNego.module.css";

export default class BoxNegotiationNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalNegotiation: false,
      isNegotiationText: "",
      data: [],
      dataAll: [],
      isApproval: false,
      isCurrencyList: [],
      isOpen: true,
      isApiLoaded: false,
      isEmpty: false,
      isErrorText: null,
      isErrorCurrencyId: null,
      currentSalary: null,
      idJob: null,
      isLastest: false,
      idLang: null,
      idUser: null,
      prevStatus: false,
      offered_by: "Applicant",
      isModalClose: false,
      loading: false,
      isLatestNew: null,
      isStatus: false,
      isResultLength: null,
      isApprovedByAdmin: false,
      selectedRows: [],
      isDisabledButton: false,
      isDisabledBox: false,
    };
    this.toggleModalNegotitation = this.toggleModalNegotitation.bind(this);
    this.toggleModalClose = this.toggleModalClose.bind(this);
    this.toggleModalCloseNego = this.toggleModalCloseNego.bind(this);
    this.getCurrency = this.getCurrency.bind(this);
    this.currencyNow = this.currencyNow.bind(this);
    this.statusApproval = this.statusApproval.bind(this);
    this.createOffering = this.createOffering.bind(this);
    this.addOfferingSalary = this.addOfferingSalary.bind(this);
    this.modalCloseOpened = this.modalCloseOpened.bind(this);
    this.submitApprovalFinal = this.submitApprovalFinal.bind(this);
    this.getOfferingDataNew = this.getOfferingDataNew.bind(this);
  }

  componentDidMount() {
    let idUser = localStorage.getItem("id");
    let lang = localStorage.getItem("language");
    let id = this.props.idJob;
    this.getOfferingDataNew(idUser, id);
    this.getCurrency();
    this.setState({
      idJob: id,
      idLang: lang,
      idUser: idUser,
      currentSalary: this.props.salary,
    });

    if (this.props.negoText) {
      this.setState({
        isNegotiationText: this.props.negoText[0].content,
      });
    }
    // console.log(this.props.id);
  }

  getOfferingDataNew(idUser, id) {
    const get = new Api();
    get
      .getOfferingDataNew(idUser, id)
      .then((result) => {
        const dataEnabled = [];
        const dataDisabled = [];
        let concatData;

        let length = result.data.results.length;

        // console.log(result.data);

        if (length !== 0) {
          this.setState({
            isDisabledBox: true,
          });
        }

        this.setState({
          isResultLength: length,
        });

        result.data.results.forEach((ele, index) => {
          if (this.state.idLang === "English") {
          }

          if (length === 1) {
            if (ele.offered_by === "Applicant") {
              //   console.log("true");

              this.setState({
                isDisabledButton: true,
              });
            } else {
              this.setState({
                isDisabledButton: false,
              });
            }

            if (ele.final_offer) {
              this.setState({
                isApprovedByAdmin: true,
                isApproval: false,
                isStatus: false,
              });
            } else {
              this.setState({
                isApprovedByAdmin: false,
                isApproval: true,
                isStatus: false,
              });
            }

            dataEnabled.push({
              id: ele.id,
              currency_id: ele.currency,
              currency_name: ele.currency_name,
              final_offer: ele.final_offer,
              offered_by: ele.offered_by,
              salary_offer: ele.salary_offer,
              timestamp: ele.timestamp,
            });
            concatData = dataEnabled;
          } else {
            if (index === length - 1) {
              if (ele.offered_by !== "Applicant") {
                this.setState({
                  isApprovedByAdmin: true,
                  isApproval: true,
                  isStatus: false,
                });
              } else {
                if (ele.final_offer === true) {
                  this.setState({
                    isApprovedByAdmin: true,
                    isApproval: false,
                    isStatus: true,
                  });
                } else {
                  this.setState({
                    isApprovedByAdmin: false,
                    isApproval: false,
                    isStatus: false,
                  });
                }
              }

              dataEnabled.push({
                id: ele.id,
                currency_id: ele.currency,
                currency_name: ele.currency_name,
                final_offer: ele.final_offer,
                offered_by: ele.offered_by,
                salary_offer: ele.salary_offer,
                timestamp: ele.timestamp,
              });
            } else {
              if (ele.offered_by !== "Applicant") {
                this.setState({
                  isApprovedByAdmin: true,
                  isApproval: true,
                  isStatus: false,
                });
              } else {
                if (ele.final_offer === true) {
                  this.setState({
                    isApprovedByAdmin: true,
                    isApproval: false,
                    isStatus: true,
                  });
                } else {
                  this.setState({
                    isApprovedByAdmin: false,
                    isApproval: false,
                    isStatus: false,
                  });
                }
              }

              dataDisabled.push({
                id: ele.id,
                currency_id: ele.currency,
                currency_name: ele.currency_name,
                final_offer: ele.final_offer,
                offered_by: ele.offered_by,
                salary_offer: ele.salary_offer,
                timestamp: ele.timestamp,
              });
            }
            concatData = dataDisabled.concat(dataEnabled);
          }
        });

        let leng = concatData.length;

        this.setState({
          dataAll: concatData,
          isLatestNew: leng,
          isApiLoaded: true,
        });
      })
      .catch((error) => {
        console.log("error =>" + error);
      });
  }

  salaryDot = (num) => {
    let salaryDot, localeString;
    let lang = localStorage.getItem("language");
    num == null ? (salaryDot = 0) : (salaryDot = num);
    lang === "English"
      ? (localeString = salaryDot.toLocaleString("en"))
      : (localeString = salaryDot.toLocaleString("vi"));

    return localeString;
  };

  statusApproval(id) {
    this.setState((prevState) => {
      return {
        isApproval: !prevState.isApproval,
      };
    });

    this.updateFinalSallary(id);
    // this.getOfferingData();
  }

  getCurrency() {
    const apiData = new Api();
    this.setState({ isCurrency: [] }, () => {
      apiData
        .getCurrency()
        .then((result) => {
          const currencies = [];
          result.data.results.forEach((c) => {
            if (this.state.idLang === "English") {
              currencies.push({
                id_currency: c.id,
                id: c.currency_languages[0].id,
                name: c.currency_languages[0].name,
              });
            } else {
              currencies.push({
                id_currency: c.id,
                id: c.currency_languages[1].id,
                name: c.currency_languages[1].name,
              });
            }
          });
          this.setState({
            isCurrencyList: currencies,
            isApiLoaded: true,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  modalCloseOpened() {
    alert("modal");
    this.setState((prevState) => {
      return {
        isModalClose: true,
        isModalNegotiation: !prevState.isModalNegotiation,
      };
    });
  }

  toggleModalClose() {
    this.setState((prevState) => {
      return {
        // isModalNegotiation: !prevState.isModalNegotiation,
        isModalClose: false,
      };
    });
  }

  toggleModalCloseNego() {
    this.setState((prevState) => {
      return {
        isModalNegotiation: false,
      };
    });
  }

  updateFinalSallary(id) {
    const patch = new Api();
    patch
      .updateFinalSallary(id)
      .then((result) => {
        this.props.close();
      })
      .catch((error) => {
        console.log("error =>" + error);
      });
  }
  submitApprovalFinal() {
    this.setState((prevState) => {
      if (prevState.isApproval === false) {
        this.props.close();
      } else {
      }
    });
  }
  currencyNow(e) {
    if (this.state.idLang === "English") {
    }
  }

  checkValidity() {
    let isValid = true;
    let c = document.getElementById("currency_id");
    const currency_id = c.options[c.selectedIndex].value;
    const data = {
      nominal_salary: document.getElementById("nominal_salary").value,
      currency_id: currency_id,
    };

    if (!data.nominal_salary) {
      this.setState({
        isErrorText: "*this field is required",
      });
      isValid = false;
    } else {
      this.setState({
        isErrorText: null,
      });
    }

    if (currency_id === "x") {
      this.setState({
        isErrorCurrencyId: "*this field is required",
      });
      isValid = false;
    } else {
      this.setState({
        isErrorCurrencyId: null,
      });
    }

    return isValid;
  }

  addOfferingSalary() {
    alert("ADD ---- Sal");
    const post = new Api();
    let c = document.getElementById("currency_id");
    const currency_id = c.options[c.selectedIndex].value;

    const data = [];

    let idxx = parseInt(this.state.idUser);
    let idss = parseInt(this.props.id);

    data.push({
      id_applicant: idxx,
      id_job: parseInt(this.props.idJob),
      id_currency: currency_id,
      salary_offer: document.getElementById("nominal_salary").value,
      offered_by: this.state.offered_by,
      final_offer: false,
    });

    post
      .addOfferingSalaryNew(data)
      .then((res) => {
        this.modalCloseOpened();
        this.getOfferingDataNew(idxx, idss);
        this.setState({
          isDisabledBox: true,
        });
      })
      .catch((err) => {
        console.log(err.response.data);

        if (err.response.data) {
          if (err.response.data.salary_offer) {
            let errorAdd = err.response.data.salary_offer[0];
            if (
              errorAdd ===
              "Ensure this value is less than or equal to 2147483647."
            ) {
              this.setState({
                isErrorText: "Please dont input more than 9 numbers",
              });
            } else if (errorAdd === "A valid integer is required.") {
              this.setState({
                isErrorText: "Please dont use character, only numbers",
              });
            }
          } else {
            this.setState({
              isErrorText: "error",
            });
          }
        }
      });
  }

  createOffering(e) {
    e.preventDefault();
    if (this.checkValidity()) {
      this.addOfferingSalary();
    } else {
      this.setState({
        isEmpty: true,
      });
    }
  }

  toggleModalNegotitation() {
    this.setState((prevState) => {
      return {
        isModalNegotiation: !prevState.isModalNegotiation,
      };
    });
  }

  render() {
    // let isTextApprove, isTrueApprove,
    let currency;
    let isTextChanged;

    let modaltitle,
      initialrange,
      // approvedText,
      salaryoffer,
      offeredby,
      btn,
      date,
      status,
      action,
      createnew,
      // cancel,
      approval,
      open,
      disabled,
      approved,
      no_data,
      close,
      input_nominal;
    // isFinal,
    // submit;

    const dataAllArr = [];

    const {
      isApiLoaded,
      isCurrencyList,
      dataAll,
      // isLastest,
      // idLang,
      // isStatus,
      isLatestNew,
      isApprovedByAdmin,
      isApproval,
    } = this.state;

    // let statusFinals;

    if (isApiLoaded) {
      if (this.props.dataText) {
        this.props.dataText.forEach((e, index) => {
          switch (index) {
            case 0:
              break;
            case 1:
              modaltitle = e.content;
              break;
            case 2:
              initialrange = e.content;
              break;
            case 3:
              salaryoffer = e.content;
              break;
            case 4:
              date = e.content;
              break;

            case 5:
              offeredby = e.content;
              break;
            case 6:
              status = e.content;
              break;

            case 7:
              open = e.content;
              break;
            case 8:
              close = e.content;
              break;
            case 9:
              approval = e.content;
              break;

            case 10:
              disabled = e.content;
              break;
            case 11:
              createnew = e.content;
              break;
            // case 12:
            //   cancel = e.content;
            //   break;
            // case 13:
            //   submit = e.content;
            //   break;
            case 14:
              action = e.content;
              break;
            case 15:
              approved = e.content;
              break;
            case 16:
              input_nominal = e.content;
              break;
            case 17:
              no_data = e.content;
              break;
            default:
          }
        });
      }

      dataAll.forEach((s) => {
        // statusFinals = s.final_offer;

        let date = s.timestamp;
        let formatss = dateFormat(date, "dd/mm/yyyy");

        dataAllArr.push({
          id: s.id,
          offered_by: s.offered_by,
          salary_offer: s.salary_offer,
          timestamp: formatss,
          final_offer: s.final_offer,
          currency_name: s.currency_name,
        });
      });
      currency = isCurrencyList.map((c) => {
        // console.log(c);
        return <option value={c.id_currency}>{c.name}</option>;
      });
    }

    let columns = [
      {
        name: (
          <div className={css.tableColumn}>
            <div className={css.contentColumn}>
              <span>{offeredby}</span>
            </div>
          </div>
        ),
        selector: "name",
        sortable: true,
        width: "auto",
        cell: (row, i) => {
          return <p>{row.offered_by}</p>;
        },
      },
      {
        name: (
          <div className={css.tableColumn}>
            <div className={css.contentColumn}>
              <span>{salaryoffer}</span>
            </div>
          </div>
        ),
        selector: "current_salary",
        sortable: true,
        cell: (row, i) => {
          let dotValue =
            this.salaryDot(row.salary_offer) + " " + row.currency_name;
          return <span>{dotValue}</span>;
        },
      },
      {
        name: (
          <div className={css.tableColumn}>
            <div className={css.contentColumn}>
              <span>{date}</span>
            </div>
          </div>
        ),
        selector: "post_date",
        sortable: true,
        width: "auto",
        cell: (row) => {
          return <span>{row.timestamp}</span>;
        },
      },
      {
        name: (
          <div className={css.tableColumn}>
            <div className={css.contentColumn}>
              <span>{status}</span>
            </div>
          </div>
        ),
        selector: "date",
        sortable: true,
        cell: (row, index) => {
          if (index === isLatestNew - 1) {
            if (isApprovedByAdmin && !isApproval) {
              isTextChanged = (
                <div className={css.boxStatus}>
                  <span className={css.changeColorApprove}>{approved}</span>
                </div>
              );
            } else {
              isTextChanged = (
                <div className={css.boxStatusOpened}>
                  <span className={css.changeColorGreen}>{open}</span>
                </div>
              );
            }
          } else {
            isTextChanged = (
              <div className={css.boxStatusClosed}>
                <span className={css.changeColor}>{close}</span>
              </div>
            );
          }

          return <>{isTextChanged}</>;
        },
      },
      {
        name: (
          <div className={css.tablecontentArticleolumn}>
            <div className={css.contentColumn}>
              <span>{action}</span>
            </div>
          </div>
        ),
        selector: "status_offer",
        sortable: true,
        cell: (row, index) => {
          // approvedText = "Approved";
          // let isTrueApprove = isApproval ? "" : css.isBlue;
          let isDisabledTrue = css.isGrey;

          if (this.state.isDisabledButton) {
            btn = (
              <div
                id={row.id}
                className={[css.btnApproveDisabled, isDisabledTrue].join(" ")}
              >
                <span>{disabled}</span>
              </div>
            );
          } else {
            btn = (
              <div
                id={row.id}
                className={css.btnApprove}
                onClick={() => this.statusApproval(row.id)}
              >
                <span>{approval}</span>
              </div>
            );
          }

          return <>{btn}</>;
        },
      },
    ];

    let isEmpty = this.state.isEmpty ? classes2.isEmpty : "";
    let isCreateDisabled;

    let isDisabledBox = this.state.isDisabledBox ? classes2.isDis : "";

    if (this.state.isResultLength !== 0) {
      isCreateDisabled = css.isDisabled;
    } else {
      if (isApprovedByAdmin && !isApproval) {
        isCreateDisabled = css.isDisabled;
      } else {
        isCreateDisabled = "";
      }
    }
    return (
      <div>
        <ModalSuccess
          show={this.state.isModalClose}
          close={this.toggleModalClose}
        />
        <Modal
          size={"lg"}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.isModalNegotiation}
          onHide={this.toggleModalCloseNego}
        >
          <Modal.Header closeButton>
            <span style={{ fontWeight: "bold" }}>{modaltitle}</span>
          </Modal.Header>
          <div className={classes2.modalContent}>
            <div className={classes2.content}>
              <div className={classes2.tableRowContent}>
                <p
                  style={{
                    width: " 40%",
                    margin: "auto",
                    justifyContent: "center",
                  }}
                >
                  {initialrange}
                </p>
                <p className={classes2.inputCurrent}>
                  {this.state.currentSalary}
                </p>
              </div>
              <div className={classes2.tableRowContent}>
                <div id="data_table_nego" style={{ width: "100%" }}>
                  <DataTableExtensions
                    // columns={columns(this.handleButtonClick)}
                    onRowSelected={this.handleChange}
                    columns={columns}
                    data={dataAllArr}
                    export={false}
                    print={false}
                    exportHeaders={true}
                  >
                    <DataTable
                      className={[
                        "table table-bordered dataTable",
                        css.dataTable,
                      ].join(" ")}
                      title="Negotiation"
                      noHeader
                      pagination={true}
                      responsive={true}
                      highlightOnHover={true}
                      striped={true}
                      noDataComponent={no_data}
                    />
                  </DataTableExtensions>
                </div>
              </div>

              <div className={[css.boxNewOffer, isCreateDisabled].join(" ")}>
                <div className={css.wrap2}>
                  <div style={{ border: "1px solid #d6d6d6", height: "100%" }}>
                    <input
                      id="nominal_salary"
                      className={classes2.inputModal}
                      placeholder={input_nominal}
                      type={"text"}
                    />
                  </div>
                  <p className={[css.validError, isEmpty].join("")}>
                    {this.state.isErrorText}
                  </p>
                </div>

                <div className={css.wrap3}>
                  <div style={{ border: "1px solid #d6d6d6", height: "100%" }}>
                    <select id="currency_id" className={css.modalSelect}>
                      <option value={"x"}>---</option>
                      {currency}
                    </select>
                  </div>
                  <p className={[css.validError2, isEmpty].join("")}>
                    {this.state.isErrorCurrencyId}
                  </p>
                </div>

                <div className={css.createSal} onClick={this.createOffering}>
                  <p>{createnew}</p>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <div
          className={[classes.nego, isDisabledBox].join(" ")}
          onClick={this.toggleModalNegotitation}
        >
          <p>{this.state.isNegotiationText}</p>
        </div>
      </div>
    );
  }
}
