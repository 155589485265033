import React, { Component, Fragment } from "react";
import classes from "./Mobile.module.css";
import { Link } from "react-router-dom";
import { ParallaxProvider } from "react-scroll-parallax";
import Slider from "react-slick";
import ReactHtmlParser from "react-html-parser";
import Api from "../Api";
import dateFormat from "dateformat";
import "./Mobile.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

class Mobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idSection: this.props.id,
      sections: null,
      article: null,
      isLoadedSection: false,
      isLoadedArticle: false,
      isApiLoaded: false,
      active_img: 0,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    let paramId = this.props.id;
    this.getSectionDetail(paramId);
  }

  getSectionDetail(paramId) {
    const apiData = new Api();
    this.setState(
      {
        article: [],
        sections: [],
      },
      () => {
        apiData.getSectionDetail().then((result) => {
          const sections = result.data;
          const widget = [];
          let id = sections.id;
          result.data.widgets.forEach((item) => {
            widget.push(item);
          });

          this.setState({
            sections: widget,
            idSection: id,
            isLoadedSection: true,
          });
        });

        apiData
          .getArticleDetailByArticleId(paramId)
          .then((result) => {
            const article = result.data;
            const assets = [];
            article.article_assets.forEach((item) => {
              assets.push({
                assets: item.file_big || item.asset_file,
                assets_small: item.file_small,
                desc: item.description,
              });
            });

            const allcontent = article.article_languages;
            let content = allcontent.content;
            let title = allcontent.title;
            let category = article.category_names;
            let date = article.date;

            const widget = [];

            widget.push({
              content: content,
              title: title,
              category: category,
              date: date,
              assets: assets,
            });

            article.page = widget;
            this.setState({
              article: widget,
              isLoadedArticle: true,
            });
          })
          .catch((error) => {
            this.setState({ isApiLoaded: true });
          });
      }
    );
  }

  render() {
    const settings = {
      className: "",
      infinite: false,
      slidesToShow: 1,
      autoplay: false,
      swipeToSlide: true,
      dots: true,
    };

    const { article, sections } = this.state;

    let articleContent,
      imgContent,
      sliderContent,
      share,
      back,
      imgBottom = null;

    let backText = {},
      shareText = {};

    let shareImgLink, fileImg;

    if (this.state.isLoadedSection && this.state.isLoadedArticle) {
      sections.forEach((section) => {
        if (section.index === 1) {
          backText.url = section.url;
          backText.content = section.widget_contents[0].content;
        } else if (section.index === 2) {
          shareText.content = section.widget_contents[0].content;
        } else if (section.index === 4) {
          shareImgLink = section.widget_contents.map((j, i) => {
            let url = j.url ? j.url : "/";
            fileImg = j.assets[0] ? j.assets[0].file : "";

            return (
              <a
                key={j.title}
                target="_blank"
                href={url}
                rel="noopener noreferrer"
              >
                <img className={classes.icon} src={fileImg} alt="" />
              </a>
            );
          });
        }
      });

      article.forEach((item) => {
        let strDate = item.date;
        let dateFull = dateFormat(strDate, "dd-mm-yyyy");
        imgContent = item.assets.map((ele, i) => {
          return (
            <div className={classes.boxImg}>
              <img
                className={classes.imgSlide}
                src={ele.assets_small}
                alt=" "
              />
            </div>
          );
        });

        sliderContent = <Slider {...settings}>{imgContent}</Slider>;

        articleContent = (
          <div className={classes.boxContent}>
            {/* <div className={classes.category}>{item.category}</div> */}
            <div className={classes.title}>{item.title}</div>
            <div className={classes.date}>{dateFull}</div>
            <div className={classes.isi}>{ReactHtmlParser(item.content)}</div>
          </div>
        );

        share = (
          <div
            className={classes.share}
            style={{
              fontFamily: "Helvetica",
            }}
          >
            {shareText.content} : &nbsp;
            {shareImgLink}
          </div>
        );

        back = (
          <Link to={backText.url} className={classes.backLink}>
            <FontAwesomeIcon className={classes.backIcon} icon={faArrowLeft} />
            <div>{backText.content}</div>
          </Link>
        );
      });
    } else if (this.state.isApiLoaded) {

    }
    return (
      <div className="pages-new">
        <div className="sliderGallery">{sliderContent}</div>
        <div className={classes.main}>
          <ParallaxProvider>
            <Fragment>{articleContent}</Fragment>
          </ParallaxProvider>
          <div className={classes.shareContainer}>{share}</div>
          {back}
        </div>
      </div>
    );
  }
}

export default Mobile;
