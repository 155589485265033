import React, { Component } from "react";
import Api from "../Api";
import css from "./Mobile.module.css";
import { Link } from "react-router-dom";

export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: "",
      year: "",
      month: "",
      day: "",
      selectedYear: "",
      selectedMonth: "",
      selectedDay: "",
      listCountries: [],
      isLoaded: false,
      reqName1: null,
      reqName2: null,
      reqEmail: null,
      reqPwd: null,
      reqBirth: null,
      reqCon: null,
      reqGen: null,
      checkVal: null,
      input: {},
      isEmpty: false,
    };
    this.yearChange = this.yearChange.bind(this);
    this.monthChange = this.monthChange.bind(this);
    this.dayChange = this.dayChange.bind(this);
    this.registerNewApplicant = this.registerNewApplicant.bind(this);
    this.getListCountries = this.getListCountries.bind(this);
  }

  componentDidMount() {
    let year = [];
    let month = [];
    var d = new Date();
    var n = d.getFullYear();
    for (let index = n - 65; index <= n - 15; index++) {
      year.push(index);
    }
    for (let index = 1; index <= 12; index++) {
      month.push(index);
    }
    this.setState({
      year: year,
      month: month,
    });

    this.getListCountries();
  }

  getListCountries() {
    const countries = new Api();

    countries
      .getListCountries()
      .then((res) => {
        const data = res.data;
        const country = [];
        data.results.forEach((a) => {
          country.push({
            id: a.id,
            code: a.iso_code,
            name: a.name,
          });
        });
        this.setState({
          listCountries: country,
          isLoaded: true,
        });
      })
      .catch((err) => {
        console.log("error =>", err);
      });
  }

  checkEmail = (param) => {
    let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

    if (!param.match(mailformat)) {
      return false;
    } else {
      return true;
    }
  }

  checkPwd = (param) => {
    let format = /^[a-zA-Z0-9]{8,}$/;
    if (param.length < 8) {
      return false;
    } else if (param.length >= 8) {
      if (param.match(format)) {
        return true;
      } else {
        return false;
      }
    }
  }

  checkValidity = () => {
    let isValid = true;
    const year = this.state.selectedYear;
    const month = this.state.selectedMonth;
    const day = this.state.selectedDay;
    let c = document.getElementById("country-select");
    const country = c.options[c.selectedIndex].value;

    const regData = {
      // username: document.getElementById("email").value,
      firstname: document.getElementById("firstname").value,
      lastname: document.getElementById("lastname").value,
      email: document.getElementById("email").value,
      password: document.getElementById("password").value,
      birthdate: `${year}-${month}-${day}`,
      country: country,
    };

    if (!regData.firstname) {
      this.setState({
        reqName1: "* First name is required",
      });
      isValid = false;
    } else {
      this.setState({
        reqName1: null,
      });
    }

    if (!regData.lastname) {
      this.setState({
        reqName2: "* Last name is required",
      });
      isValid = false;
    } else {
      this.setState({
        reqName2: null,
      });
    }

    if (!regData.email) {
      this.setState({
        reqEmail: "* Email is required",
      });
      isValid = false;
    } else if (regData.email) {
      if (this.checkEmail(regData.email)) {
        this.setState({
          reqEmail: null,
        });
        // return true
      } else {
        this.setState({
          reqEmail: "* Email invalid address",
        });
        isValid = false;
      }
    }

    if (!regData.password) {
      this.setState({
        reqPwd: "* Password is required",
      });
      isValid = false;
    } else if (regData.password) {
      if (this.checkPwd(regData.password)) {
        this.setState({
          reqPwd: null,
        });
        return true;
      } else {
        this.setState({
          reqPwd:
            "* Password must contain minimal 8 character, combination alphabets and number",
        });
        isValid = false;
      }
    }

    if (!year) {
      this.setState({
        reqBirth: "* is required",
      });
      isValid = false;
    } else {
      this.setState({
        reqBirth: null,
      });
    }

    if (!month) {
      this.setState({
        reqBirth: "* is required",
      });
      isValid = false;
    } else {
      this.setState({
        reqBirth: null,
      });
    }
    if (!day) {
      this.setState({
        reqBirth: "* is required",
      });
      isValid = false;
    } else {
      this.setState({
        reqBirth: null,
      });
    }

    if (country === "x") {
      this.setState({
        reqCon: "* is required",
      });
      isValid = false;
    } else {
      this.setState({
        reqCon: null,
      });
    }

    return isValid;
  }

  submitForm = (e) => {
    e.preventDefault();
    if (this.checkValidity()) {
      this.registerNewApplicant();
    } else {
      this.setState({
        isEmpty: true,
        // reqBirth : "is required",
      });
    }
  }

  registerNewApplicant() {
    const register = new Api();

    const year = this.state.selectedYear;
    const month = this.state.selectedMonth;
    const day = this.state.selectedDay;
    let c = document.getElementById("country-select");
    let country = c.options[c.selectedIndex].value;

    const regData = {
      username: document.getElementById("email").value,
      firstname: document.getElementById("fname").value,
      lastname: document.getElementById("lname").value,
      email: document.getElementById("email").value,
      password: document.getElementById("password").value,
      birthdate: `${year}-${month}-${day}`,
      country: country,
    };

    register
      .registerApplicant(regData)
      .then((res) => {
        const data = res.data;
        window.location.assign(`/career-new/${data.applicant}`)

        // setTimeout(() => {
        //     this.props.history.push('/')
        // }, 0)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  yearChange(e) {
    if (e.target.value !== "x") {
      document.getElementById("month-select").disabled = false;
      this.setState({
        selectedYear: e.target.value,
      });
    } else {
      document.getElementById("month-select").disabled = true;
    }
  }

  monthChange(e) {
    if (e.target.value !== "x") {
      let days = [];
      document.getElementById("day-select").disabled = false;

      let day = this.getDaysInMonth(this.state.selectedYear, e.target.value);
      for (let index = 1; index <= day; index++) {
        days.push(index);
      }
      this.setState({
        selectedMonth: e.target.value,
        day: days,
      });
      // var getDaysInMonth = function (month, year) {
      //     return new Date(year, month, 0).getDate();
      // };
    } else {
      document.getElementById("day-select").disabled = true;
    }
  }

  dayChange(e) {
    if (e.target.value !== "x") {
      this.setState({
        selectedDay: e.target.value,
      });
    } else {
    }
  }

  getDaysInMonth(month, year) {
    return new Date(year, month, 0).getDate();
  }

  render() {
    let contentYear, contentMonth, contentDay, countries;
    if (this.state.isLoaded) {
      contentYear = this.state.year.map((y) => {
        return (
          <option value={y}>{y}</option>
          // <span>{y}</span>
        );
      });

      contentMonth = this.state.month.map((m) => {
        return <option value={m}>{m}</option>;
      });

      contentDay = this.state.day
        ? this.state.day.map((m) => {
          return <option value={m}>{m}</option>;
        })
        : "";

      countries = this.state.listCountries.map((c) => {
        return <option value={c.id}>{c.name}</option>;
      });
    }

    let isEmpty = this.state.isEmpty ? css.isEmpty : "";

    return (
      <div className={["container", css.pad25].join(" ")}>
        <div className={css.boxBig}>
          <div className="d-flex justify-content-center flex-column">
            <span className={css.modalTitle}>Create an account</span>
            <div className="d-flex">
              <span className={css.option}>Already have an account?</span>
              <Link to={"/login"}>
                <span
                  className={css.contentLink}
                  onClick={this.props.loginClicked}
                >
                  Sign in
                </span>
              </Link>
            </div>
          </div>
          <div className={css.modalContent}>
            <div className={css.content}>
              <div className={css.contentWrapp}>
                <div className={css.boxContent}>
                  <span className={css.titleContent}>First name</span>
                  <input
                    id="firstname"
                    className={css.inputModal}
                    type={"text"}
                    required
                  // defaultValue={"John"}
                  />
                </div>
                <p className={[css.validError, isEmpty].join("")}>
                  {this.state.reqName1}
                </p>
                <div className={css.boxContent}>
                  <span className={css.titleContent}>Last name</span>
                  <input
                    id="lastname"
                    className={css.inputModal}
                    type={"text"}
                    required
                  // defaultValue={"Doe"}
                  />
                </div>
                <p className={[css.validError, isEmpty].join("")}>
                  {this.state.reqName2}
                </p>
              </div>

              <div className={css.contentWrapp}>
                <div className={css.boxContent}>
                  <span className={css.titleContent}>Email address</span>
                  <input
                    id="email"
                    className={css.inputModal}
                    type={"text"}
                    required
                  // defaultValue={"google@johndoe.com"}
                  />
                </div>
                <p className={[css.validError, isEmpty].join("")}>
                  {this.state.reqEmail}
                </p>
                <div className={css.boxContent}>
                  <span className={css.titleContent}>Password</span>
                  <input
                    id="password"
                    className={css.inputModal}
                    type={"password"}
                    required
                  // defaultValue={"password"}
                  />
                </div>
                <p className={[css.validError, isEmpty].join("")}>
                  {this.state.reqPwd}
                </p>
                {/* <span className={css.validError}>Error login wrong password</span> */}
              </div>

              <span className={[css.option, css.optionTitle].join(" ")}>
                Date of birth
              </span>
              <div className={css.contentDate}>
                <div className={css.boxContent}>
                  <span className={css.titleContent}>Year</span>
                  <select
                    id="year-select"
                    className={css.modalSelect}
                    onChange={this.yearChange}
                  >
                    <option value={"x"}>yyyy</option>
                    {contentYear}
                  </select>
                  <p className={[css.validError, isEmpty].join("")}>
                    {this.state.reqBirth}
                  </p>
                </div>
                <div className={css.boxContent}>
                  <span className={css.titleContent}>Month</span>
                  <select
                    id="month-select"
                    className={css.modalSelect}
                    disabled
                    onChange={this.monthChange}
                  >
                    <option value={"x"}>mm</option>
                    {contentMonth}
                  </select>
                </div>
                <div className={css.boxContent}>
                  <span className={css.titleContent}>Day</span>
                  <select
                    id="day-select"
                    className={css.modalSelect}
                    disabled
                    onChange={this.dayChange}
                  >
                    <option value={"x"}>dd</option>
                    {contentDay}
                  </select>
                </div>
              </div>

              <div className={css.boxContent}>
                <span className={css.titleContent}>Gender</span>
                <select className={css.modalSelect}>
                  <option>--</option>
                  <option>Male</option>
                  <option>Female</option>
                </select>
                <p className={[css.validError, isEmpty].join("")}>
                  {this.state.reqBirth}
                </p>
              </div>

              <div
                className={[css.boxContent, css.boxContentCountry].join(" ")}
              >
                <span className={css.titleContent}>Country/Region</span>
                <select id="country-select" className={css.modalSelect}>
                  <option value={"x"}>---</option>
                  {countries}
                </select>
                <p className={[css.validError, isEmpty].join("")}>
                  {this.state.reqCon}
                </p>
                {/* <input className={css.inputModal} type={"text"} defaultValue={"Wonderland"} /> */}
              </div>

              <span className={["mt-4", css.option].join(" ")}>
                Phenikaa companies may keep me informed with personalized emails
                about products and services. See our Privacy Policy for more
                details or to opt-out at any time.
              </span>
              <span className={["mt-4", css.option].join(" ")}>
                By clicking Create account, I agree that I have read and
                accepted the Terms of Use and Privacy Policy.
              </span>
            </div>
            <div className={css.modalAction}>
              <div
                className={[css.boxAction, css.boxRight].join(" ")}
                onClick={this.submitForm}
              >
                <span>Create account</span>
              </div>
            </div>
            <hr />
            <span className={css.option}>
              Protected by reCAPTCHA and subject to the Google Privacy Policy
              and Terms of Service.
            </span>
          </div>
        </div>
      </div>
    );
  }
}
