import React, { Component } from "react"
import classes from "./Mobile.module.scss"
import Api from "../Api"
// import CircleDiagram from "../components/CircleDiagram/CircleDiagram"

export default class Mobile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      circleData: [1, 2, 3, 4, 5],
      istoggleOn: false,
      contentSection: [],
      isLoaded: false,
      isApiLoaded: true,
      activeIndex: [],
      activeText: [],
    }

    this.classList = {
      3: classes.three,
      4: classes.four,
      5: classes.five,
      6: classes.six,
      7: classes.seven,
      8: classes.eight
    }

    this.getSectionDetail = this.getSectionDetail.bind(this)
    this.toggleDetail = this.toggleDetail.bind(this)
  }

  componentDidMount() {
    this.getSectionDetail()
  }

  getSectionDetail() {
    const apiData = new Api()
    apiData
      .getSectionDetail()
      .then((result) => {
        const sections = result.data
        const widget = []

        result.data.widgets.forEach((item) => {
          widget.push({ id: item.id, index: item.index, category: item.category, name: item.name, sections: item })
        })

        sections.page = widget
        this.setState({ contentSection: widget, isLoaded: true })
      })
      .catch((error) => {
        this.setState({ isApiLoaded: true })
      })
  }

  handleClick = () => {
    this.setState((prevState) => {
      return {
        istoggleOn: !prevState.istoggleOn,
      }
    })
  }

  toggleDetail = (i) => {
    var result = true
    var result2 = []

    for (var a = 0; a <= this.state.activeIndex.length; a++) {
      if (i === this.state.activeIndex[a]) result = false
    }

    if (result === true) {
      result2.push(i)
      this.setState(
        {
          activeIndex: this.state.activeIndex.concat(result2),
        },
        () => { }
      )
    } else {
      let mySet = new Set(this.state.activeIndex)
      if (mySet.has(i)) {
        mySet.delete(i)
        this.setState(
          {
            activeIndex: [...mySet],
          },
          () => { }
        )
      }
    }
  }

  toggleEnter = (i) => {
    var result = true
    var result2 = []

    for (var a = 0; a <= this.state.activeText.length; a++) {
      if (i === this.state.activeText[a]) result = false
    }

    if (result === true) {
      result2.push(i)
      this.setState(
        {
          activeText: this.state.activeText.concat(result2),
        },
        () => { }
      )
    } else {
      let mySet = new Set(this.state.activeText)
      if (mySet.has(i)) {
        mySet.delete(i)
        this.setState(
          {
            activeText: [...mySet],
          },
          () => { }
        )
      }
    }
  }

  render() {
    const { contentSection, activeIndex, activeText, circleData } = this.state

    let titlecont,
      dataCircle,
      insideText = [],
      scroll_list,
      dummy_titlecont,
      box_content,
      dummy_scroll_list,
      dataS,
      dataText,
      titleInner,
      imageBackground,
      hexaBtn,
      circleDiagram

    if (this.state.isLoaded) {
      let count = 0
      let arrIcon1 = []
      let arrIcon2 = []
      let dataScroll = []
      contentSection.forEach((item) => {
        count = count + 1
        if (count === 1 && item.index === 1 && item.category === "Media") {
          item.sections.widget_contents.forEach((s) => {
            arrIcon1.push(s.file)
          })
        }
        if (count === 2 && item.index === 2 && item.category === "Media") {
          item.sections.widget_contents.forEach((s) => {
            arrIcon2.push({
              file: s.file,
              description: s.description
            });
          })
        }
        if (count === 3 && item.index === 3 && item.category === "Text") {
          item.sections.widget_contents.forEach((ele) => {
            titlecont = <p className={classes.titleContent1}>{ele.content}</p>
          })
        }
        if (count === 4 && item.index === 4 && item.category === "Text") {
          item.sections.widget_contents.forEach((dots) => {
            dataScroll.push(dots.content)
          })

          hexaBtn = item.sections.widget_contents.map((z, i) => {
            if (i !== 0) {
              return <div></div>
            }
          })

          dataCircle = dataScroll.map((e) => {
            return <div className={classes.circle}></div>
          })

          dataText = dataScroll.map((e) => {
            return (
              <div className={classes.circle}>
                <div className={classes.textBox}>{e}</div>
              </div>
            )
          })

          insideText = dataScroll.map((z, i) => {
            return (
              <div className={classes.circleBig} onClick={() => this.toggleDetail(i + 1)} onMouseEnter={() => this.toggleEnter(i + 1)} onMouseLeave={() => this.toggleEnter(i + 1)}>
                <img
                  src={arrIcon2[i]?.file ? arrIcon2[i].file : require("./../assets/default-image.svg")}
                  alt={arrIcon2[i]?.description ? arrIcon2[i].description : "Phenikaa"}
                  onClick={this.handleClick}
                />
                <div className={classes.textBox}>
                  <p className={classes.activeText}>{z}</p>
                </div>
              </div>
            )
          })
        }
        if (count === 5 && item.index === 5 && item.category === "Text") {
          titleInner = item.sections.widget_contents[0].content
        }
        if (count === 6 && item.index === 6 && item.category === "Media") {
          imageBackground = (
            <div className={classes.Content2}>
              <div
                style={{
                  background: `url(${item.sections.widget_contents[0].file}) center no-repeat `,
                  backgroundSize: "cover",
                }}
                className={classes.wrapBox}
              ></div>
              <h2 className={classes.innerText}>{titleInner}</h2>
            </div>
          )
        }
      })
    }
    return (
      <div className={classes.Section2}>
        <div className={["container", classes.boxC].join(" ")}>
          <div className={classes.containerBox2}>
            <div className={[classes.outerContent2, this.classList[insideText.length]].join(' ')}>
              {dataCircle}
              {insideText}

              {imageBackground}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
