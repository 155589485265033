import React from "react";

import { isMobile, isTablet } from "react-device-detect";
import Desktop from "./Desktop/Desktop";
import Mobile from "./Mobile/Mobile";
import { useMediaQuery } from "react-responsive";

export default function BS_Section1(props) {
  // let content = !isMobile ? <Desktop updateLogo={props.updateLogo} override={props.override} /> : <Mobile />
  let content;
  const isM = useMediaQuery({
    query: "(min-device-width: 411px) and (max-device-width: 479px)",
  });
  if (isTablet) {
    content = (
      <Desktop updateLogo={props.updateLogo} override={props.override} />
    );
  } else if (isMobile || isM) {
    content = (
      <Mobile
        updateLogo={props.updateLogo}
        override={props.override}
        tab={props.tab}
      />
    );
  } else {
    content = (
      <Desktop updateLogo={props.updateLogo} override={props.override} />
    );
  }
  return <>{content}</>;
}
