import React, { Component } from 'react';
import Api from './../Api'
import classes from "./../GS.module.css"

import ReactHtmlParser from "react-html-parser";

import Navbar from './Navbar/Navbar'

import { Link } from "react-router-dom";

class Views extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentArticle: {
                widgets: []
            },
            isLoaded: false,
            isApiloaded: false,
            views: null,
            idNews: '',
            querySearch: '',
            filtered: '',
            articleCategories: null,
            pagination: 6,
            articleID: null,
            displayStyle: 'grid',
            founded: null,
            isMobile: false
        }
        this.getAllArticleDetail = this
            .getAllArticleDetail
            .bind(this);

        this.onFilterChange = this.onFilterChange.bind(this);
        this.getSearchedData = this.getSearchedData.bind(this);
        this.getSearchedDataByClick = this.getSearchedDataByClick.bind(this);
        this.changeDisplay = this.changeDisplay.bind(this);
        this.loadMoreNews = this.loadMoreNews.bind(this);
        this.getAllCategories = this.getAllCategories.bind(this);
    }

    componentDidMount() {
        this.getAllCategories()
        this.setState({
            articleCategories: this.props.category ? this.props.category : '',
            querySearch: this.props.searchHere ? this.props.searchHere : '',
            isMobile: window.screen.width <= 480 ? true : false
        })

        this.getAllArticleDetail('', this.state.filtered, this.props.searchHere ? this.props.searchHere : '')
    }

    getAllCategories() {

        const apiData = new Api()

        const categories = []

        this.setState({
            article_Categories: [],
        }, () => {

            apiData.getAllCategories().then(result => {
                const values = result.data
                const article_categories = []
                values.results.map((item) => {
                    if (item.category.includes("System")) {
                        //pass
                    } else {
                        article_categories.push(
                            {
                                'id': item.id,
                                'category': item.category
                            }
                        )
                    }
                })

                this.setState({
                    articleCategories: article_categories,
                })
            })

        })

    }

    getAllArticleDetail(filter, size, search) {
        let count = 0
        if (count === 0) {
            const apiData = new Api();

            this.setState({
                contentArticle: []
            }, () => {
                const art = []

                apiData
                    .getAllArticleDetails(filter, size, search)
                    .then(result => {
                        const articles = result.data

                        articles
                            .results
                            .forEach((a) => {
                                const assets = []

                                a
                                    .article_assets
                                    .forEach((ass, i) => {
                                        assets.push({ asset: ass.file_small || ass.asset_file, description: ass.description })
                                    })

                                if (a.article_languages && a.article_languages.content !== "") {
                                    art.push({
                                        id: a.id,
                                        category: a.categories,
                                        category_name: a.category_names,
                                        name: a.name,
                                        title: a.article_languages.title,
                                        url_title: a.article_languages.slug_title,
                                        content: a.article_languages.content,
                                        assets: assets,
                                        date: a.date
                                    })
                                }

                                this.setState({ contentArticle: art, founded: articles.count, isLoaded: true })
                            })


                    })
                    .catch(error => {
                        console.log(error);
                        this.setState({ isApiLoaded: true })
                    });


            })
        }

    }

    onFilterChange(e) {
        this.setState({
            filtered: e.target.value
        })
        this.getAllArticleDetail(e.target.value, '', this.state.querySearch)
    }

    loadMoreNews() {
        this.setState((prevState) => {
            return { pagination: prevState.pagination + 6 };
        });
    }

    getSearchedData(e) {
        if (e.keyCode === 13) {
            if (e.target.value !== "") {
                this.getAllArticleDetail('', '', e.target.value)
            }
        }
    }

    getSearchedDataByClick(value) {
        if (value !== "") {
            this.getAllArticleDetail('', '', value)
        }
    }

    changeDisplay = (params) => {
        this.setState({ displayStyle: params })
    }

    render() {
        const { contentArticle, pagination } = this.state

        // SET CONTENT NEWS LIST MODE
        let apiContentlist = null
        if (this.state.isLoaded) {
            let count = 0

            apiContentlist = contentArticle.slice(0, pagination).map((s, i) => {
                count = count + 1
                let backgroundColor = null

                function getCategoryIndex(categoryList, category) {
                    var count = 1

                    var returnValue = 0
                    categoryList.forEach((thisCategory) => {
                        if (thisCategory.category.localeCompare(category) === 0) {
                            returnValue = count
                        }
                        count += 1
                    })
                    return returnValue
                }

                var categoryIndex = "";
                switch (getCategoryIndex(this.state.articleCategories, s.category)) {
                    case 1:
                        backgroundColor = classes.boxContent
                        break;
                    case 2:
                        backgroundColor = classes.boxContentL1
                        break;
                    default:
                        backgroundColor = classes.boxContentL2
                        break;
                }

                switch (s.category[0]) {
                    case 2:

                        break;
                    default:
                        break;
                }

                let customLink
                if (s.category[0]) {
                    let c = s.category[0]
                    if (c === 2) {
                        customLink = this.props.allUrl[0] // News and Event
                    } else if (c === 4) { // Business
                        customLink = this.props.allUrl[4]
                    } else if (c === 5) { // History
                        customLink = !this.state.isMobile ? this.props.allUrl[2] : this.props.allUrl[3]
                    } else if (c === 6) { // Awards
                        customLink = this.props.allUrl[1]
                    } else {
                        customLink = this.props.allUrl[0]
                    }
                }

                return (
                    <Link to={`${customLink}/${s.url_title !== "" ? s.url_title : "-"}/${s.id}`} className={classes.link}>
                        <div className={[classes.contentWrap, classes.fadeInBottom].join(' ')}>
                            <div className={backgroundColor}>
                                <div className={classes.contentBox}>
                                    {s.category}
                                </div>
                                <div className={classes.title_content}>
                                    {s.title}
                                </div>
                                <div className={classes.txt_content}>
                                    {ReactHtmlParser(s.content)}
                                </div>
                                <div className={classes.foot_content}>
                                    <p>{this.props.readMore}</p>
                                    <p className={classes.date_right}>
                                        {s.date}
                                    </p>
                                </div>
                            </div>
                            <div className={classes.boxImage}>
                                <figure className={classes.wrapp_image}>
                                    <img
                                        className={classes.img_content}
                                        src={s.assets[0] ? s.assets[0].asset : require('./assets/default-image.svg')}
                                        alt={s.assets[0]?.description}
                                    />
                                    <div className={classes.inside_image}>
                                        <img
                                            className={classes.icon_share}
                                            src={require("./../assets/Group 843.svg")}
                                            alt="description" />
                                        <br />
                                        <p>Share on :</p>
                                        <img
                                            className={classes.icon}
                                            src={require("./../assets/Subtraction 1.svg")}
                                            alt="description" />
                                        <img
                                            className={classes.icon}
                                            src={require("./../assets/Group 17.svg")}
                                            alt="description" />
                                        <img
                                            className={classes.icon}
                                            src={require("./../assets/Group 19.svg")}
                                            alt="description" />
                                        <img
                                            className={classes.icon}
                                            src={require("./../assets/Group 831.svg")}
                                            alt="description" />
                                    </div>
                                </figure>
                            </div>
                        </div>
                    </Link>
                )
            })
        }

        // SET CONTENT NEWS GRID MODE
        let apiContentGrid = null
        if (this.state.isLoaded) {
            apiContentGrid = contentArticle.slice(0, pagination).map((s, i) => {
                let customLink
                let c = s.category[0]
                if (s.category[0]) {
                    if (c === 2) {
                        customLink = `${this.props.allUrl[0]}/${s.url_title ? (s.url_title !== "" ? s.url_title : "-") : "-"}/${s.id}` // News and Event
                    } else if (c === 4) { // Business
                        customLink = this.props.allUrl[4]
                    } else if (c === 5) { // History
                        customLink = !this.state.isMobile ? `${this.props.allUrl[2]}` : this.props.allUrl[3]
                    } else if (c === 6) { // Awards
                        customLink = `${this.props.allUrl[1]}/${s.url_title ? (s.url_title !== "" ? s.url_title : "-") : "-"}/${s.id}`
                    } else {
                        customLink = `${this.props.allUrl[0]}/${s.url_title ? (s.url_title !== "" ? s.url_title : "-") : "-"}/${s.id}`
                    }
                }
                return (

                    <div className={[classes.contentWrap, classes.fadeInBottomm].join(" ")}>
                        <div className={classes.boxContent1}>
                            <div className={classes.contentBox}>
                                <div className={classes.backBox}>
                                    {s.category_name}
                                </div>
                            </div>
                            <div className={classes.box_behind}>
                                <div className={classes.box_content_image}>
                                    <img
                                        className={classes.box_image}
                                        src={s.assets[0] ? s.assets[0].asset : require('./assets/default-image.svg')}
                                        alt={s.assets[0]?.description}
                                    />
                                </div>
                                {/* <Link to={c !== 4 || (c !== 5 && !this.state.isMobile) ? (`${customLink}/${s.url_title !== "" ? s.url_title : "-"}/${s.id}`) : `${customLink}`} className={classes.link}> */}
                                {
                                    (c === 2 || c === 4 || c === 5 || c === 6) ?
                                        <Link to={customLink} className={classes.link}>
                                            <div className={classes.div_readmore1}>
                                                <div className={classes.title_content1}>{s.title}</div>
                                                <div className={classes.foot_content1}>
                                                    <p>{this.props.readMore}</p>
                                                    <p className={classes.date_right1}>{s.date}</p>
                                                </div>
                                            </div>
                                        </Link> :
                                        <div className={classes.div_readmore1}>
                                            <div className={classes.title_content1}>{s.title}</div>
                                            <div className={classes.foot_content1}>
                                                <p></p>
                                                <p className={classes.date_right1}>{s.date}</p>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>

                )
            })
        }

        // SET NEWS CATEGORY
        let filters = null
        const { articleCategories } = this.state
        if (articleCategories) {
            filters = articleCategories.map(f => {
                return (
                    <option value={f}>{f}</option>
                )
            })
        }

        // CHANGE LIST/GRID VIEW
        let viewOnchange, colorList, colorGrid, nav = null
        if (this.state.displayStyle === '' || this.state.displayStyle === "list") {
            viewOnchange = <div className={classes.component}>{apiContentlist}</div>
            nav = <Navbar
                listClick={() => this.changeDisplay("list")}
                gridClick={() => this.changeDisplay("grid")}
                type={'list'}
                onKeyDown={this.getSearchedData}
                getSearchedDataByClick={this.getSearchedDataByClick}
                onChange={this.onFilterChange}
                category={articleCategories}
                title={this.props.title}
                searchHere={this.state.querySearch}
                total={this.state.founded}
                textFilter={this.props.textFilter}
                textFound={this.props.textFound}
                textSearch={this.props.textSearch}
                textError={this.props.textError}
            />
        } else {
            viewOnchange = <div className={classes.componentgrid}>
                <div className={["row", classes.box_grid].join(' ')}>
                    {apiContentGrid}
                </div>
            </div>
            nav = <Navbar
                listClick={() => this.changeDisplay("list")}
                gridClick={() => this.changeDisplay("grid")}
                type={'grid'}
                onKeyDown={this.getSearchedData}
                getSearchedDataByClick={this.getSearchedDataByClick}
                onChange={this.onFilterChange}
                category={articleCategories}
                title={this.props.title}
                searchHere={this.state.querySearch}
                total={this.state.founded}
                textFilter={this.props.textFilter}
                textFound={this.props.textFound}
                textSearch={this.props.textSearch}
                textError={this.props.textError}
            />
        }

        let contentNotFound = null
        if (this.state.isLoaded) {
            contentNotFound = !this.state.founded ?
                <div className={classes.contentNoResult}>
                    <div>
                        <img src={require('./assets/noresult.png')} alt='no-result'></img>
                        <p className={classes.warningResultText}><span style={{ color: '#F26522' }}>Sorry</span>, no result found</p>
                        <p style={{ textAlign: "center" }}>Try rewording your search or entering a new keyword.</p>
                    </div>
                </div> : ''
        }



        return (
            <>
                {/* <div className={classes.background_blur}></div> */}
                {nav}
                {contentNotFound}
                {viewOnchange}
                <div className={classes.older} onClick={this.loadMoreNews}>
                    <p>{this.props.loadMore}</p>
                </div>
            </>
        );
    }
}

export default Views;