import React, { Component } from "react"
import { Link } from "react-router-dom"

import ReactHtmlParser from "react-html-parser"
import Slider from "react-slick"

import classes from "./Technology.module.css"
import "../Slider.css"

export default class Technology extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dummy: [
        { id: 1, name: "Title", content: "What is Lorem Ipsum?" },
        {
          id: 2,
          name: "Content",
          content: "Where does it come from? Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
        },
        {
          id: 3,
          name: "Background Image",
          assets: require("./assets/default-image.svg"),
        },
      ],
      isDetail: false,
      defaultSlider: 1,
    }
  }

  componentDidMount() { }

  getHtmlParagraph(str) {
    if (str && str !== "") {
      return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ")
    }
  }

  toggleDetail = () => {
    this.setState((prevState) => {
      return { isDetail: !prevState.isDetail }
    })
  }

  getUrlParser(url) {
    if (url !== "/") {
      return url.includes("http") ? (
        <a className={classes.customLink} href={url} target='_blank' rel='noopener noreferrer'>
          <div className={classes.outerCircle}>
            <div className={classes.innerCircle}></div>
          </div>
          <p className={classes.viewMore}>{this.props.viewDetails}</p>
        </a>
      ) : (
        <Link className={classes.customLink} to={url}>
          <div className={classes.outerCircle}>
            <div className={classes.innerCircle}></div>
          </div>
          <p className={classes.viewMore}>{this.props.viewDetails}</p>
        </Link>
      )
    } else {
      let isNull = classes.isNull
      return (
        <Link className={[classes.customLink, isNull].join(" ")} to={url}>
          <div className={classes.outerCircle}>
            <div className={classes.innerCircle}></div>
          </div>
          <p className={classes.viewMore}>{this.props.viewDetails}</p>
        </Link>
      )
    }
  }

  getParsedHtml(str) {
    let content = str.match(/<p>(.*?)<\/p>/g).map(function (val) {
      return val.replace(/<\/?p>/g, " ").replace(/&nbsp;/gi, " ")
    })
    return content
  }

  render() {
    const settings = {
      dots: true,
      centerMode: false,
      infinite: false,
      centerPadding: "",
      variableWidth: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
      swipe: true,
      appendDots: (dots) => (
        <div className={classes.customDots}>
          <ul style={{ margin: "0px" }}> {dots} </ul>
        </div>
      ),
      customPaging: (i) => <div className={classes.customPaggination}></div>,
    }

    let title,
      tabTitle,
      desc,
      img,
      content,
      link = null
    let detailContent,
      contentL1,
      contentL2,
      contentL3,
      contentL4,
      contentL5,
      contentL7,
      contentL8,
      contentBox,
      contentExt = null

    let subs = this.props.subsData

    if (this.props.items) {
      let items = this.props.items
      title = <h2 className={classes.title}>{items[0].item.title}</h2>
      tabTitle = <p className={classes.tabTitle}>{items[1].desc.title}</p>

      desc = <span className={classes.content}>{ReactHtmlParser(items[0].item.content)}</span>

      let contentImg = items[0].item.assets[0] ? items[0].item.assets[0].file_small || items[0].item.assets[0].file : require("./assets/default-image.svg")

      img = <img className={classes.imgContent} src={contentImg} alt='' />
      content = <div className={classes.contentDesc}>{ReactHtmlParser(items[1].desc.article_language.content)}</div>

      link = !this.state.isDetail ? (
        <div className={[classes.contentLink].join(" ")} onClick={this.toggleDetail}>
          <div className='d-flex'>
            <div className={classes.outerCircle}>
              <div className={classes.innerCircle}></div>
            </div>
            <p className={classes.contentLink}>{this.props.readMore}</p>
          </div>
        </div>
      ) : (
        <></>
      )

      const splitStr = items[0].item.article_language.content.split("#")
      desc = <span className={classes.content}>{ReactHtmlParser(splitStr[0] ? splitStr[0] : "")}</span>

      let arr = []

      contentL1 = splitStr[1] ? splitStr[1] : ""
      contentL2 = splitStr[2] ? splitStr[2] : ""
      contentL3 = splitStr[3] ? splitStr[3] : ""
      contentL4 = splitStr[4] ? splitStr[4] : ""
      contentL5 = splitStr[5] ? splitStr[5] : ""
      // arr.push(this.getParsedHtml(splitStr[3] ? splitStr[3] : ""));
      // arr.push(this.getParsedHtml(splitStr[4] ? splitStr[4] : ""));
      // arr.push(this.getParsedHtml(splitStr[5] ? splitStr[5] : ""));
      // arr.push(this.getParsedHtml(splitStr[6] ? splitStr[6] : ""));

      contentL7 = splitStr[7] ? splitStr[7] : ""
      contentL8 = splitStr[8] ? splitStr[8] : ""

      let contentImg2 = items[0].item.assets[1] ? items[0].item.assets[1].file_small || items[0].item.assets[1].file : require("./assets/default-image.svg")

      if (this.props.dataExt) {
        contentBox = this.props.dataExt.map((s, i) => {
          let url = s.sections.assets ? s.sections.assets[0].file_small : require("./assets/default-image.svg")
          let link = s.sections.article_language.url ? this.getUrlParser(s.sections.article_language.url) : this.getUrlParser("/")

          return (
            <Link to={subs ? `${subs[0].url}/?tab=2&index=${i + 1}` : ''}>
              <div className={classes.box}>
                <img src={url} alt=''></img>
                <div className={classes.boxMask}></div>
                <div className={classes.contentFront}>
                  <div className={classes.boxContentIn}>
                    <div className='mt-auto mb-auto ml-auto mr-3'>
                      <p>{this.getHtmlParagraph(s.sections.article_language.title)}</p>
                      <div className='d-flex flex-row justify-content-end position-relative m-auto mt-1'>{link}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          )
        })
        contentExt = (
          <div id='mobile-technology'>
            <Slider className='m-0 p-0' {...settings}>
              {contentBox}
            </Slider>
          </div>
        )
      }

      let mov
      if (this.props.movie) {
        const urlMov = this.props.movie
        if (urlMov !== "") {
          mov = (
            <video className={classes.isvideo} controls loop={true} autoPlay='autoplay' playsInline>
              <source src={urlMov} type='video/mp4' />
            </video>
          )
        } else {
          mov = (
            <figure>
              <img src={require("./assets/default-image.svg")} alt=''></img>
            </figure>
          )
        }
      }

      let animate = this.state.isDetail ? classes.fadeInBottom : classes.fadeOutTop
      detailContent = this.state.isDetail ? (
        <div className={[classes.detailContent, animate].join(" ")}>
          {ReactHtmlParser(this.getHtmlParagraph(contentL1))}

          <div className={[classes.allContent].join(" ")}>
            <div className={classes.boxBottom}>{mov}</div>
            <div className={classes.boxTop}>
              <p className={classes.content}>{ReactHtmlParser(this.getHtmlParagraph(contentL2))}</p>
            </div>
          </div>
          <hr />
          <div className={classes.allContent}>
            <div className={classes.boxTop}>
              <figure>
                <img className={classes.imgTop} src={contentImg2} alt=''></img>
              </figure>
            </div>
            <div className={classes.boxBottom}>
              <p className={classes.content}>{ReactHtmlParser(contentL3)}</p>
            </div>
          </div>

          {ReactHtmlParser(contentL8)}
          {ReactHtmlParser(contentL5)}

          <Link to={subs ? subs[0].url : ''} style={{ color: 'black' }} className='text-decoration-none'>
            <p><strong>{subs ? subs[0].content : 'Subsidiaries'}</strong></p>
          </Link>
          {contentExt}
          <div className={[classes.contentLink].join(" ")} onClick={this.toggleDetail}>
            <div className='d-flex'>
              <div className={classes.outerCircle}>
                <div className={classes.innerCircle}></div>
              </div>
              <p className={classes.contentLink}>{this.props.readLess}</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )
    } else {
    }

    return (
      <div className={classes.sectionTechnology}>
        <div className={["container d-flex flex-column", classes.customContainer].join(" ")}>
          <div className={classes.boxImage}>
            <div className={classes.animate}>{img}</div>
          </div>

          <div className={classes.contentTop}>
            <div className={classes.boxContent}>
              {tabTitle}
              {title}
              {desc}
              <div className={[classes.contentOverflow].join(" ")}>{content}</div>
              {link}
            </div>
          </div>

          {detailContent}
        </div>
      </div>
    )
  }
}
