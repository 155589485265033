import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import AccordionMobile from "./Accordion.js";
import Api from "../Api";
import classes from "./Mobile.module.css";
import { Link } from "react-router-dom";

class Mobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      boxCount: 0,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const widget = [];

          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              name: item.name,
              index: item.index,
              category: item.category,
              sections: item,
            });
          });

          sections.page = widget;
          this.setState({
            contentSection: widget,
            isLoaded: true,
          });
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  render() {
    const { contentSection } = this.state;
    let titleContent,
      contentMobile,
      contents,
      isImage,
      readText,
      link = null;

    if (this.state.isLoaded) {
      let count = 0;
      contentSection.forEach((item) => {
        count = count + 1;
        if (count === 1 && item.index === 1 && item.category === "Text") {
          item.sections.widget_contents.forEach((s) => {
            if (s.content !== "") {
              titleContent = <h2 className={classes.title}>{s.content}</h2>;
            } else {
              titleContent = <></>;
            }
          });
        }

        if (count === 2 && item.index === 2 && item.category === "Text") {
          readText = item.sections.widget_contents[0].content;
        }

        if (count === 3 && item.index === 3 && item.category === "Article") {
          let isPath = item.sections.widget_contents[0].article_language.url;

          link = (
            <div className={classes.boxLinks}>
              <div className={classes.outerCircle2}>
                <div className={classes.innerCircle2}></div>
              </div>
              <Link to={isPath} className={classes.link1}>
                <p className={classes.readMore}>{readText}</p>
              </Link>
            </div>
          );

          // link = (
          //   <Link to={isPath}>
          //     <p>{readText}</p>
          //   </Link>
          // );

          contents = item.sections.widget_contents[0].article_language.content;
          let isSrc = item.sections.widget_contents[0].assets[0]
            ? item.sections.widget_contents[0].assets[0].file_small
            : require("../assets/default-image.svg");
          isImage = <img className={classes.isImg} src={isSrc} alt=" " />;
        }

        if (count === 4 && item.index === 4 && item.category === "Article") {
          let arr = item.sections.widget_contents;
          contentMobile = <AccordionMobile data={arr} />;
        }
      });
    }
    return (
      <div className={classes.contentSection}>
        <div className={classes.titleGroup}>
          {titleContent}
          <div className={classes.bximg}>{isImage}</div>

          <p className={classes.subtitle}>{ReactHtmlParser(contents)}</p>
          {link}
          {/* <Link to={isPath}>
            <p>{readText}</p>
          </Link> */}

          <div className={classes.contentGroup}>{contentMobile}</div>
        </div>
      </div>
    );
  }
}
export default Mobile;
