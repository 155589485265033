import React, { Component } from 'react';
import TabContent from './TabContent';
import Navbar from './Navbar';
import Api from '../Api';
import classes from './Desktop.module.css';

export default class Desktop extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tabs: [],

			currentPage: null,
			tabsActive: this.props.tab,
			activePage: <div></div>,
			tabContent: [],

			sections: {
				widgets: [],
			},

			isLoaded: false,
			isApiLoaded: false,
		};
		this.getSectionDetail = this.getSectionDetail.bind(this);
	}

	componentDidMount() {
		this.getSectionDetail();
	}

	getSectionDetail() {
		const apiData = new Api();
		this.setState({ sections: [] }, () => {
			apiData
				.getSectionDetail()
				.then((result) => {
					const sections = result.data;
					const widget = [];

					result.data.widgets.forEach((item, i) => {
						widget.push({
							id: item.id,
							index: item.index,
							category: item.category,
							name: item.name,
							sections: item,
						});
					});

					let tabs = [];
					let tabContentData = [];
					let tabContent = [];
					let property = [];

					widget.forEach((s) => {
						if (s.index === 1) {
							property.push({
								readMore: s.sections.widget_contents[0] ? s.sections.widget_contents[0].content : 'Read More',
								readLess: s.sections.widget_contents[1] ? s.sections.widget_contents[1].content : 'Read Less',
							});
						} else if (s.index === 2) {
							tabs.push(s.sections.widget_contents);
						} else if (s.index === 3) {
							property.push({
								subsidiaries: s,
							});
						} else if (s.category === 'Article' && s.index >= 4 && s.index <= 9) {
							tabContentData.push({
								idx: s.index - 5,
								data: s.sections.widget_contents,
							});
						} else if (s.category === 'Article' && s.index >= 10 && s.index <= 15) {
							tabContent.push(
								<TabContent i={s.index - 10} tabs={tabs} data={tabContentData} dataExt={s.sections.widget_contents} property={property} />
							);
						}
					});

					let activeTab = null;
					if (this.props.tab) {
						tabContent.forEach((tb, i) => {
							if (parseInt(this.props.tab) === parseInt(i)) {
								activeTab = tb;
							}
						});
					} else {
						tabContent.forEach((tb, i) => {
							if (i === 0) {
								activeTab = tb;
							}
						});
					}

					this.setState({
						isLoaded: true,
						sections: widget,
						tabContent: tabContent,
						tabsActive: parseInt(this.props.tab),
						activePage: activeTab,
					});
				})
				.catch((error) => {
					this.setState({
						isApiLoaded: true,
						tabsActive: parseInt(this.props.tab),
						activePage: <></>,
					});
				});
		});
	}

	toggleActiveHandler = (val, key) => {
		this.setState({
			tabsActive: key,
			activePage: [val],
		});
	};

	handlePageChange = (number) => {
		this.setState({ currentPage: number });
	};

	render() {
		const { sections, tabContent } = this.state;
		let navItem = null;
		if (this.state.isLoaded) {
			sections.forEach((s, i) => {
				if (s.index === 2) {
					navItem = s.sections.widget_contents.map((s, i) => {
						let key = 'tab' + i;
						let active = parseInt(this.state.tabsActive);
						let activeTab = active === i ? (active % 2 === 0 ? classes.isActiveB : classes.isActiveO) : '';
						let isActive = active === i ? true : false;
						let boxs = i % 2 === 0 ? classes.boxsB : classes.boxsO;
						let url = s.assets[0] ? s.assets[0].file_small : require('../assets/default-image.svg');
						let tab = null;
						tab = tabContent ? tabContent[i] : <></>;
						return (
							<>
								<Navbar
									idx={i}
									box={boxs}
									isActive={isActive}
									active={activeTab}
									onClick={() => this.toggleActiveHandler(tab, i)}
									img={url}
									key={key}
									content={s.article_language.title}
								/>
							</>
						);
					});
				}
			});
		} else if (this.state.isApiLoaded) {
			navItem = this.state.tabs.map((n, i) => {
				let key = 'tab' + n.id;
				let boxs = n.id % 2 === 0 ? classes.boxsB : classes.boxsO;
				let activeTab = this.state.tabsActive === n.id - 1 ? classes.isActiveB : '';
				return (
					<div key={n.id} className={[boxs, activeTab].join(' ')} onClick={() => this.toggleActiveHandler(n.component, n.id - 1)}>
						<img className={classes.imgIcon} src={require('../assets/icon.svg')} alt=''></img>
						<span id={key}></span>
					</div>
				);
			});
		}

		return (
			<div className={['section-wrapping', classes.sectionWrapping].join(' ')}>
				<div className={[classes.newNavbar].join(' ')}>
					<div className={['container d-flex', classes.customContainer].join(' ')}>
						<div className='d-flex flex-row'>{navItem}</div>
					</div>
				</div>
				{this.state.activePage}
			</div>
		);
	}
}
