import React, { Component } from 'react';

import ReactHtmlParser from 'react-html-parser';

import classes from './Modal.module.css';
import './Modal.css';

class CustomModal extends Component {
    constructor(props) {
        super(props)
        // console.log(this.props)
        this.state = {
            isShowModal: false,
            data: this.props.data[this.props.idx],
            maxIdx: this.props.data.length,
            currentIdx: this.props.idx
        }
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    toggleModal = () => {
        this.setState((prevState) => {
            return {
                isShowModal: !prevState.isShowModal,
                currentIdx: this.props.idx,
                data: this.props.data[this.props.idx]
            };
        });
    }

    next() {
        if (this.state.currentIdx < this.state.maxIdx - 1) {
            this.setState(state => ({
                currentIdx: state.currentIdx + 1,
            }), function () {
                this.setState({
                    data: this.props.data[this.state.currentIdx]
                })
            })
        }

    }

    previous() {
        if (this.state.currentIdx > 0) {
            this.setState({
                currentIdx: this.state.currentIdx - 1
            }, function () {
                this.setState({
                    data: this.props.data[this.state.currentIdx]
                })
            })
        }
    }

    render() {
        const { idx, hidingIdx } = this.props;
        const immutableArticle = this.props.data[this.props.idx];
        // const stickyClass = (hidingIdx && idx >= hidingIdx[0] && idx <= hidingIdx[1]) ? (idx === hidingIdx[0] ? [classes.activeSticky, classes.firstSticky].join(" ") : classes.activeSticky) : ''
        let activeClass = this.props.onStyle ? (this.props.onStyle === 'inactive' ? classes.inActive : '') : ''
        let backgroundActive = this.props.onStyle ? (this.props.onStyle === 'inactive' ? classes.backgroundInActive : classes.backgroundActive) : ''
        let dummyCard = immutableArticle.id === "" ? classes.dummyCard : ''
        return (
            <>
                <div id={'box' + (immutableArticle ? immutableArticle.id : '')} className={[classes.aCard, dummyCard].join(' ')} onClick={this.props.onClick}>
                    <div className={classes.card}>
                        <div className={[classes.boxContent, backgroundActive].join(' ')}>
                            {
                                immutableArticle.article_language ?
                                    <h4 className={classes.cardTitle}>
                                        {ReactHtmlParser(immutableArticle.article_language.title)}
                                    </h4> : <></>
                            }
                            {
                                !(hidingIdx && idx >= hidingIdx[0] && idx <= hidingIdx[1]) ?
                                    <figure className={[classes.figureC, activeClass].join(' ')}>
                                        <img src={immutableArticle.assets[0] ? immutableArticle.assets[0].file_small : ''} className={classes.cardImageFront} alt="" />
                                    </figure>
                                    :
                                    <></>
                            }
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default CustomModal;