import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import classes from './SearchItem.module.css'

export default class SearchItem extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {

    }

    render() {
        return (
            <Link to="/search" className={classes.searchContainer}>
                <img src={require('./assets/search-icon.svg')}></img>
            </Link>
        )
    }
}
