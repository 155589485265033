import React, { Component } from 'react'
import { Modal } from "react-bootstrap";

import classes from './Modal.module.css'

export default class ModalLinks extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <Modal
                size={"md"}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={this.props.show}
                onHide={this.props.close}
            >
                <Modal.Header className={classes.modalHeader} onClick={this.props.close} closeButton>
                    <div className="d-flex justify-content-center flex-column">
                        <span className={classes.modalTitle}>Skills</span>
                        <span className={classes.option}>Enter at least 3 skills, with the most relevant first. The more skills, the higher your chance of a successful application!</span>
                    </div>
                </Modal.Header>
                <div className={classes.modalContent}>
                    <div className={classes.content}>
                        <div className={classes.boxSkill1}>
                            <div className={classes.boxSearchSkill}>
                                <img src={require('../../assets/search.svg')} alt="" />
                                <input placeholder="e.g. Microsoft Office" />
                            </div>
                            <div className="d-flex flex-row flex-wrap">
                                <div className={[classes.boxSkill, classes.default].join(' ')}><span>Digital Marketing</span></div>
                                <div className={[classes.boxSkill, classes.default].join(' ')}><span>Business Development</span></div>
                                <div className={classes.boxSkill}><span>HTML & CSS3</span></div>
                            </div>


                        </div>
                        Selected skills
                        <div className={classes.boxSkill2}>
                            <div className={classes.boxSkill}><span>HTML & CSS3</span></div>
                        </div>
                    </div>
                    <div className={classes.modalAction}>
                        <div className={classes.cancel} onClick={this.props.close}>
                            <span>Cancel</span>
                        </div>
                        <div className={classes.save}>
                            <span>Save</span>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}
