import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Api from "./Api";
import classes from "./Career_Top.module.css";

export default class Career_Overview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentSection: [],
            isLoaded: false,
            isApiLoaded: false,
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({ contentSection: [] }, () => {
            apiData
                .getSectionDetail()
                .then((result) => {
                    const sections = result.data;
                    const widget = [];

                    sections.widgets.forEach((item) => {
                        widget.push({
                            id: item.id,
                            index: item.index,
                            category: item.category,
                            name: item.name,
                            sections: item,
                        });
                    });

                    this.setState({
                        contentSection: widget,
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    this.setState({
                        isApiLoaded: true,
                    });
                });
        });
    }

    render() {
        let topTitle,
            colorTopTitle,
            topContent,
            topBannerBackground,
            topBannerDescription,
            imageBackground,
            links,
            urls,
            running = null;

        if (this.state.isLoaded) {
            this.state.contentSection.forEach((item, index) => {
                switch (index) {
                    case 0:
                        topBannerBackground = item.sections.widget_contents[0]
                            ? item.sections.widget_contents[0].file
                            : null;
                        topBannerDescription = item.sections.widget_contents[0]
                            ? item.sections.widget_contents[0].description
                            : null;
                        break;
                    case 1:
                        urls = item.sections.url ? item.sections.url : '/recruitment'
                        links = item.sections.widget_contents[0]
                            ? item.sections.widget_contents[0].content
                            : "Join Us";
                        break;
                    case 2:
                        topTitle = item.sections.widget_contents[0]
                            ? item.sections.widget_contents[0].article_language.title
                            : "TopTitle";
                        topContent = item.sections.widget_contents[0]
                            ? item.sections.widget_contents[0].article_language.content
                            : "Join Us";
                        break;
                    case 3:
                        colorTopTitle = item.sections.widget_contents[0]
                            ? item.sections.widget_contents[0].font_color
                            : "#000000";
                        running = item.sections.widget_contents[0]
                            ? item.sections.widget_contents[0].content
                            : "Running";

                        break;
                    default:
                        break;
                }

                imageBackground = (
                    <img
                        src={
                            topBannerBackground
                                ? topBannerBackground
                                : require("./assets/banner.jpg")
                        }
                        alt={topBannerDescription}
                    ></img>
                );
            });
        }

        const { contentSection } = this.state;
        let title,
            desc,
            link = null;
        let dummyTitle,
            dummyDesc,
            dummyLink = null;

        dummyTitle = (
            <h2 className={classes.title}>{topTitle ? topTitle : ""}</h2>
        );
        dummyDesc = (
            <p className={classes.desc}>
                {topContent ? ReactHtmlParser(topContent) : <></>}
            </p>
        );

        dummyLink = <></>;
        if (this.state.isLoaded) {
            contentSection.forEach((c, i) => {
                switch (i) {
                    case 0:
                        link = c.sections.widget_contents.map((s) => {
                            return (
                                <>
                                    <Link to={urls}>
                                        <div className={classes.circleLink}>
                                            <div>
                                                <span>
                                                    {ReactHtmlParser(
                                                        links
                                                    )}
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                </>
                            );
                        });

                        break;
                    default:
                        break;
                }
            });

            title = dummyTitle;
            desc = dummyDesc;
        } else if (this.state.isApiLoaded) {
            imageBackground = (
                <img src={require("./assets/default-image.svg")} alt="" />
            );
            title = "";
            desc = "";
            link = dummyLink;
        }

        return (
            <div className={classes.section}>
                <div className={classes.banner}>
                    {imageBackground}
                    <div className={classes.bannerContent}>
                        <h1 className={[classes.titleBanner, classes.animate].join(" ")} style={{color: colorTopTitle}}>
                            {running}
                        </h1>
                        <div className={classes.underlineAnimate} style={{backgroundColor: colorTopTitle}}></div>
                    </div>
                </div>
                <div className={["container", classes.customContainer].join(" ")}>
                    {title}
                    {desc}
                    {link}
                </div>
            </div>
        );
    }
}
