import React from "react"

import { isMobile, isTablet } from "react-device-detect"
import { useMediaQuery } from "react-responsive"
import Desktop from "./Desktop/Desktop"
import Mobile from "./Mobile/Mobile"

export default function BS_Section2(props) {
  let content

  const isM = useMediaQuery({
    query: "(min-device-width: 411px) and (max-device-width: 479px)",
  })

  if (isTablet) {
    content = <Desktop tab={props.tab} />
  } else if (isMobile || isM) {
    content = <Mobile tab={props.tab} />
  } else {
    content = <Desktop tab={props.tab} />
  }

  return <>{content}</>
}
