import React, { Component } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import dateFormat from "dateformat";

import Api from "./Api";

import classes from "./history.module.css";

export default class HistoryViews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentArticle: [],
      isLoaded: false,
      isApiLoaded: true,
      updateValue: 0,
      slideIndex: 0,
      nav1: null,
      nav2: null,
      nav3: null,
      url: this.props.url ? this.props.url : "/about-us/history/",
      historyId: null,
    };
    this.getArticleDetail = this.getArticleDetail.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.setCurrentLink = this.setCurrentLink.bind(this);
  }

  componentDidMount() {
    if (this.props.id) {
      this.getArticleDetail(this.props.id);
      this.setState({ historyId: this.props.id });
    }
  }

  getArticleDetail(param) {
    const apiData = new Api();
    apiData
      .getArticleDetailByYear(param)
      .then((result) => {
        const articles = result.data;
        const art = [];

        articles.results.forEach((a) => {
          const assets = [];
          a.article_assets.forEach((ass, i) => {
            if (i === 0) {
              assets.push({
                asset: ass.file_small,
                description: ass.description
              });
            }
          });

          art.push({
            id: a.id,
            category: a.category_names,
            name: a.name,
            title: a.article_languages.title,
            content: a.article_languages.content,
            assets: assets,
            date: a.date,
          });
        });

        this.setState({
          contentArticle: art,
          isLoaded: true,
          nav1: this.slider1,
          nav2: this.slider2,
          nav3: this.slider3,
        });
      })
      .catch((error) => {
        this.setState({
          isApiLoaded: true,
        });
      });
  }

  next() {
    this.slider2.slickNext();
  }

  previous() {
    this.slider2.slickPrev();
  }

  setCurrentLink() {
    localStorage.setItem(
      "prevLink",
      `${window.location.href}${
        this.props.sectionName ? `/#${this.props.sectionName}` : ""
      }`
    );
  }

  render() {
    const { contentArticle } = this.state;
    let contentSliderYear, contentSliderEvents, imageSlider, year;

    if (this.state.isLoaded) {
      contentSliderYear = contentArticle.map((s) => {
        let strYear = s.date;
        year = dateFormat(strYear, "yyyy");

        return (
          <div className={classes._boxYear}>
            <p
              className={classes.contentBoxTitle}
              onChange={(e) => this.slider.slickGoTo(e.target.value)}
              value={this.state.slideIndex}
              type="range"
              min={0}
              max={4}
            >
              {year}
            </p>
          </div>
        );
      });

      contentSliderEvents = contentArticle.map((s) => {
        let titleContent = null;

        if (s.title !== "") {
          titleContent = s.title;
        } else {
          titleContent = <></>;
        }

        return (
          <div className={classes._boxTitle}>
            <p
              className={classes.contentTl}
              onChange={(e) => this.slider.slickGoTo(e.target.value)}
              value={this.state.slideIndex}
              type="range"
              min={0}
              max={4}
            >
              {titleContent}
            </p>
          </div>
        );
      });

      imageSlider = contentArticle.map((s) => {
        let strYear = s.date;
        year = dateFormat(strYear, "yyyy");

        let isImage = s.assets[0] ? (
          <img className={classes.img_col_1} src={s.assets[0].asset} alt={s.assets[0].description} />
        ) : (
          <img
            className={classes.img_col_1}
            src={require("./assets/default-image.svg")}
            alt="Default image"
          />
        );

        return (
          <>
            <div className={[classes.boxImage, ""].join(" ")}>{isImage}</div>
          </>
        );
      });
    }
    const settings = {
      infinite: true,
      autoplay: false, // should be true
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      afterChange: () =>
        this.setState((state) => ({ updateValue: state.updateValue + 1 })),
      beforeChange: (current, next) => this.setState({ slideIndex: next }),
    };
    return (
      <div
        className={["container", classes.joinPad, classes.customContainer].join(
          " "
        )}
      >
        <h2 className={classes.judul}>{this.props.title}</h2>
        <div className={classes._box1}>
          <div className={classes.col_1}>
            <Slider
              asNavFor={this.state.nav3}
              ref={(slider) => (this.slider1 = slider)}
            >
              {imageSlider}
            </Slider>
          </div>
          <div className={classes.col_2}>
            <div className={classes.absBtm2}>
              <div className={classes.absBtm}>
                <div className={classes.card_2020}>
                  <div className={classes._bTextYear}>
                    <Slider
                      asNavFor={this.state.nav1}
                      ref={(slider) => (this.slider2 = slider)}
                      slidesToShow={1}
                      swipeToSlide={false}
                      focusOnSelect={false}
                      {...settings}
                    >
                      {contentSliderYear}
                    </Slider>
                  </div>
                  <div className={classes._bImg}>
                    <img
                      onClick={this.previous}
                      src={require("./assets/arrow.svg")}
                      className={[classes.arrow_2, classes.flip].join(" ")}
                      alt="Back arrow"
                    />
                  </div>
                  <div className={classes._bTextEvents}>
                    <Slider
                      asNavFor={this.state.nav2}
                      ref={(slider) => (this.slider3 = slider)}
                      slidesToShow={1}
                      swipeToSlide={false}
                      focusOnSelect={false}
                    >
                      {contentSliderEvents}
                    </Slider>
                  </div>
                  <div className={classes._bImgR}>
                    <img
                      onClick={this.next}
                      src={require("./assets/arrow.svg")}
                      className={classes.arrow_2}
                      alt="Next arrow"
                    />
                  </div>
                </div>
              </div>

              <div className={classes._box4}>
                <Link
                  onClick={() => this.setCurrentLink()}
                  to={`${this.state.url}`}
                  className={classes.boxLinks}
                >
                  <div className={classes.outerCircle2}>
                    <div className={classes.innerCircle2}></div>
                  </div>

                  <p className={classes.foot}>{this.props.viewAll}</p>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
