import React, { Component } from "react"
import { Link } from "react-router-dom"

import { Slide } from "react-awesome-reveal"
import dateFormat from "dateformat"
import ReactHtmlParser from "react-html-parser"

import Navbar from "./Navbar/Navbar"

import Api from "./../Api"
import classes from "./../News.module.css"

class Views extends Component {
  constructor(props) {
    super(props)
    this.state = {
      contentArticle: [],
      isLoaded: false,
      filtered: "",
      articleCategories: null,
      pagination: 6,
      articleID: null,
      displayStyle: "grid",
      loadedArticle: 6,
      idLoaded: 0,
      page: 1,
      isLast: false,
      isNavbarChanged: false,
      lang: '',
    }
    this.getArticleDetail = this.getArticleDetail.bind(this)

    this.onFilterChange = this.onFilterChange.bind(this)
    this.getSearchedData = this.getSearchedData.bind(this)
    this.changeDisplay = this.changeDisplay.bind(this)
    this.loadMoreNews = this.loadMoreNews.bind(this)
    this.setDefaultLoadedArticle = this.setDefaultLoadedArticle.bind(this)
    this.insertArticleInArray = this.insertArticleInArray.bind(this)
    this.setCurrentLink = this.setCurrentLink.bind(this);
  }

  componentDidMount() {
    this.setState({
      articleID: this.props.id ? this.props.id : "",
      articleCategories: this.props.category ? this.props.category : "",
      lang: localStorage.getItem("language")
    });

    if (this.props.id) {
      this.getArticleDetail(this.props.id, "", this.state.loadedArticle, this.props.valueSearch ? this.props.valueSearch : "", this.state.page)
    }

    this.setDefaultLoadedArticle();
  }

  setDefaultLoadedArticle(multiply = 1) {
    if (window.screen.width >= 2560) {
      this.setState({
        loadedArticle: 12 * multiply,
      })
    } else {
      this.setState({
        loadedArticle: 6 * multiply,
      })
    }
  }

  insertArticleInArray(array, articleToInsert) {
    var inserted = false
    for (var i = 0; i < array.length; i++) {
      if (articleToInsert.date > array[i].date) {
        let startArray = array.slice(0, i)
        let endArray = array.slice(i, array.length)
        startArray.push(articleToInsert)
        array = startArray.concat(endArray)
        inserted = true
        break
      }
    }
    if (!inserted) {
      array.push(articleToInsert)
    }
    return array
  }

  getArticleDetail(param, filter, size, search, page) {
    let customParam = {
      search: search,
      order: "-date",
    }

    if (filter === "Phenikaa's News") {
      customParam.widget = this.props.idNewsDetails
      customParam.widgets_intersection = true
    } else if (filter === "Other News") {
      customParam.widget_exclusive = true
    }

    let count = 0
    if (count === 0) {
      const apiData = new Api()

      var art = [...this.state.contentArticle]

      apiData
        .getArticleDetailPagination(param, size, page, customParam)
        .then((result) => {
          const articles = result.data

          if (!articles.next) {
            this.setState({ isLast: true })
          }
          let newArts = []

          articles.results
            .forEach((a) => {
              const assets = []

              let typeVid = ["webm", "mkv", "flv", "gif", "avi", "mov", "wmv", "mp4", "3gp"]
              let typeImg = ["jpg", "jpeg", "png", "svg", "webp", "bmp", "tiff", "exif"]

              a.article_assets.forEach((ass, i) => {
                if (i === 0) {
                  if (ass.file_small) {
                    let extension = ass.file_small.substring(ass.file_small.lastIndexOf(".") + 1)

                    if (typeVid.includes(extension)) {
                      assets.push({ assetV: ass.file_small })
                    } else if (typeImg.includes(extension)) {
                      assets.push({ asset: ass.file_small })
                    }
                  } else {
                    const vidId = this.getVidId(ass.asset_link)
                    const ytThumb = vidId ? `https://img.youtube.com/vi/${vidId}/hqdefault.jpg` : null
                    const asset = ass.asset_thumbnail || ytThumb
                    if(asset) {
                      assets.push({ asset: asset })
                    } else {
                      assets.push({ assetV: ass.asset_link })
                    }
                  }
                }
              })

              let newArticle = {
                id: a.id,
                category: a.category_names,
                categoryID: a.categories,
                name: a.name,
                title: a.article_languages.title,
                slug_title: a.article_languages.slug_title,
                summary: a.article_languages.summary,
                content: a.article_languages.content,
                assets: assets,
                date: a.date,
              }

              newArts.push(newArticle)
            })

          art = newArts
          return art
        })
        .then((art) => {
          this.setState({
            contentArticle: this.state.contentArticle.concat(art),
            isLoaded: true,
          })
        })
        .catch((error) => {
          console.log(error)
        })
      count = 1

    }
  }

  getVidId(ytEmbedUrl) {
    if(!ytEmbedUrl) {
      return ytEmbedUrl
    }

    const url = new URL(ytEmbedUrl)
    const path = url.pathname.split("/")
    const vidId = path[path.length-1] === "" ? path[path.length-2] : path[path.length-1]
    return vidId
  }

  onFilterChange(e) {
    this.setState({
      filtered: e.target.value,
      contentArticle: [],
      page: 1,
      isLast: false
    }, () => {
      this.getArticleDetail(
        this.state.articleID,
        this.state.filtered,
        this.state.loadedArticle,
        this.props.valueSearch ? this.props.valueSearch : "", this.state.page
      );
    });
  }

  loadMoreNews() {
    this.setState((prevState) => {
      return { pagination: prevState.pagination + this.state.loadedArticle, page: prevState.page + 1 }
    }, () => {
      this.getArticleDetail(
        this.props.id,
        this.state.filtered,
        this.state.loadedArticle,
        this.props.valueSearch ? this.props.valueSearch : "", this.state.page
      )
    })
  }

  getSearchedData(e) {
    if (e.keyCode === 13) {
      if (e.target.value !== "") {
        const value = e.target.value;
        this.setState({
          contentArticle: [],
          page: 1,
          isLast: false
        }, () => {
          this.getArticleDetail(this.state.articleID, '', this.state.loadedArticle, value, this.state.page);
        });
      }
    }
  }

  changeDisplay = (params) => {
    this.setState({ displayStyle: params })
  }

  imageOrVideo = (url, param, display, imageDescription) => {
    let content, imgStyle, vidStyle
    if (display === "grid") {
      imgStyle = classes.boxImageGrid
      vidStyle = classes.boxVideoGrid
    } else {
      imgStyle = classes.box_image
      vidStyle = classes.boxVideo
    }
    if (url !== "") {
      switch (param) {
        case "img":
          content = <img className={imgStyle} src={url ? url : require("./assets/default-image.svg")} alt={imageDescription} />
          break
        case "vid":
          if (url) {
            if (url.includes("phenikaa-assets.s3")) {
              content = (
                <video className={vidStyle} controls loop={false}>
                  <source src={url} type='video/mp4' />
                </video>
              )
            } else {
              content = <iframe src={url} className={vidStyle} title='Iframe Example'></iframe>
            }
          } else {
            content = <img className={imgStyle} src={require("./assets/default-image.svg")} alt='description' />
          }

          break
        default:
          break
      }
    } else {
      content = <img className={imgStyle} src={require("./assets/default-image.svg")} alt='description' />
    }
    return content
  }

  onChangeNavbar = (val) => {
    if (this.state.isNavbarChanged !== val) {
      this.setState({ isNavbarChanged: val })
    }
  }

  setCurrentLink() {
    localStorage.setItem('prevLink', `${window.location.href}${this.state.sectionName ? `#${this.state.sectionName}` : ''}`);
  }

  render() {
    const { contentArticle, pagination } = this.state
    // SET CONTENT NEWS LIST MODE
    let apiContentlist = null
    if (this.state.isLoaded) {
      let count = 0
      const arr = contentArticle

      apiContentlist = arr.map((s, i) => {
        count = count + 1
        let backgroundColor,
          backgroundColor2,
          titleBox = null

        if (count % 2 === 0) {
          titleBox = classes.titleBoxEvent
          backgroundColor2 = classes.boxContentL1
        } else {
          titleBox = classes.titleBoxNews
          backgroundColor2 = classes.boxContentL2
        }

        function getCategoryIndex(categoryList, category) {
          var count = 1

          var returnValue = 0
          categoryList.forEach((thisCategory) => {
            if (thisCategory.name.localeCompare(category) === 0) {
              returnValue = count
            }
            count += 1
          })
          return returnValue
        }

        switch (getCategoryIndex(this.state.articleCategories, s.category)) {
          case 1:
            backgroundColor = classes.boxContentL

            break
          case 2:
            backgroundColor = classes.boxContentL1

            break
          default:
            backgroundColor = classes.boxContentL2

            break
        }

        const NewDate = this.state.lang === "Vietnamese" ? dateFormat(s.date, "dd-mm-yyyy") : dateFormat(s.date, "mm-dd-yyyy")
        let param = s.assets[0] ? (s.assets[0].asset ? "img" : "vid") : ""
        let newAsset = this.imageOrVideo(
          s.assets[0] ? (s.assets[0].asset ? s.assets[0].asset : s.assets[0].assetV) : require('./assets/default-image.svg'),
          param,
          "list",
          s.assets[0]?.description
        )
        return (
          <Link onClick={() => this.setCurrentLink()} to={`${this.props.url}/${s.slug_title ? s.slug_title : "null"}/${s.id}`} className={classes.link}>
            <Slide triggerOnce direction={"right"}>
              <div className={[classes.contentWrap1, classes.fadeInBottomm].join(" ")}>
                <div className={backgroundColor2}>
                  <div className={classes.title_content}>
                    <p>{s.title}</p>
                  </div>
                  <div className={classes.txt_content}>
                    <p>{ReactHtmlParser(s.summary)}</p>
                  </div>
                  <div className={classes.foot_content}>
                    <div className={classes.boxLinks}>
                      <div className={classes.outerCircle2}>
                        <div className={classes.innerCircle2}></div>
                      </div>
                      <p className={classes.readMore}>{this.props.readMore}</p>
                    </div>

                    <p className={classes.date_right}>{NewDate}</p>
                  </div>
                </div>
                <div className={classes.boxImage}>
                  <figure className={classes.wrapp_image}>
                    {newAsset}
                    <div className={classes.inside_image}>
                      <img className={classes.icon_share} src={require("./../assets/Group 843.svg")} alt='description' />
                      <br />
                      <p>Share on :</p>
                      <img className={classes.icon} src={require("./../assets/Subtraction 1.svg")} alt='description' />
                      <img className={classes.icon} src={require("./../assets/Group 17.svg")} alt='description' />
                      <img className={classes.icon} src={require("./../assets/Group 19.svg")} alt='description' />
                      <img className={classes.icon} src={require("./../assets/Group 831.svg")} alt='description' />
                    </div>
                  </figure>
                </div>
              </div>
            </Slide>
          </Link>
        )
      })
    }

    // SET CONTENT NEWS GRID MODE
    let apiContentGrid = null
    if (this.state.isLoaded) {
      apiContentGrid = contentArticle.map((s, i) => {

        const NewDate = this.state.lang === "Vietnamese" ? dateFormat(s.date, "dd-mm-yyyy") : dateFormat(s.date, "mm-dd-yyyy")

        let param = s.assets[0] ? (s.assets[0].asset ? "img" : "vid") : ""

        let newAsset = this.imageOrVideo(
          s.assets[0] ? (s.assets[0].asset ? s.assets[0].asset : s.assets[0].assetV) : "",
          param,
          "grid",
          s.assets[0]?.description
        )

        return (
          <Slide triggerOnce direction={"top"}>
            <div className={[classes.contentWrap, classes.fadeInBottomm].join(" ")}>
              <div className={classes.boxContent1}>
                <div className={classes.box_behind}>
                  <div className={classes.box_content_image}>{newAsset}</div>
                  <Link onClick={() => this.setCurrentLink()} to={`${this.props.url}/${s.slug_title ? s.slug_title : "null"}/${s.id}`} className={classes.link}>
                    <div className={classes.div_readmore1}>
                      <div className={classes.title_content1}>
                        <p>{s.title}</p>
                      </div>
                      <div className={classes.foot_content1}>
                        <div className={classes.boxLinks}>
                          <div className={classes.outerCircle2}>
                            <div className={classes.innerCircle2}></div>
                          </div>
                          <p className={classes.readMore}>{this.props.readMore}</p>
                        </div>
                        <p className={classes.date_right1}>{NewDate}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </Slide>
        )
      })
    }

    // SET NEWS CATEGORY
    let filters = null
    const { articleCategories } = this.state
    if (articleCategories) {
      filters = articleCategories.map((f) => {
        return <option value={f}>{f}</option>
      })
    }

    // CHANGE LIST/GRID VIEW
    let viewOnchange, nav
    let boxGridTopMargin = this.state.isNavbarChanged ? classes.boxGridTopMargin : ""

    if (this.state.displayStyle === "" || this.state.displayStyle === "list" || this.state.displayStyle === "list2") {
      viewOnchange = <div className={classes.component}>{apiContentlist}</div>
      nav = <Navbar
        listClick={() => this.changeDisplay("list")}
        gridClick={() => this.changeDisplay("grid")}
        listClick2={() => this.changeDisplay("list2")}
        gridClick2={() => this.changeDisplay("grid2")}
        type={"list"} onKeyDown={this.getSearchedData}
        onChange={this.onFilterChange}
        category={articleCategories}
        title={this.props.title}
        searchHere={this.props.textSearch}
        selectedFilter={this.state.filtered}
        onChangeNavbar={this.onChangeNavbar}
      />
    } else {
      viewOnchange = (
        <div className={classes.componentgrid}>
          <div className={["row", classes.box_grid, boxGridTopMargin].join(" ")}>{apiContentGrid}</div>
        </div>
      )
      nav = <Navbar
        listClick={() => this.changeDisplay("list")}
        gridClick={() => this.changeDisplay("grid")}
        type={"grid"}
        listClick2={() => this.changeDisplay("list2")}
        gridClick2={() => this.changeDisplay("grid2")}
        onKeyDown={this.getSearchedData}
        onChange={this.onFilterChange}
        category={articleCategories}
        title={this.props.title}
        searchHere={this.props.textSearch}
        selectedFilter={this.state.filtered}
        onChangeNavbar={this.onChangeNavbar} />
    }

    // LOAD MORE BUTTON
    let loadMore;
    if (!this.state.isLast) {
      loadMore = (
        <div className={classes.older} onClick={this.loadMoreNews}>
          <p>{this.props.newsButton}</p>
        </div>
      )
    }

    return (
      <>
        <div className={classes.background_blur}></div>
        {nav}
        {viewOnchange}
        {loadMore}
      </>
    )
  }
}

export default Views
