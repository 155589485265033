import React, { Component } from "react"
import ReactHtmlParser from "react-html-parser"
import { Link } from "react-router-dom"

import classes from "./Educations.module.css"
// import "./Educations.css";

export default class Educations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      dummy: [
        { id: 1, name: "Title", content: "What is Lorem Ipsum?" },
        {
          id: 2,
          name: "Content",
          content: "Where does it come from? Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.",
        },
        {
          id: 4,
          name: "Background Image",
          assets: require("./assets/default-image.svg"),
        },
      ],

      isDetail: false,
    }
  }

  getHtmlParagraph(str) {
    if (str && str !== "") {
      return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ")
    }
  }

  toggleDetail = () => {
    this.setState((prevState) => {
      return { isDetail: !prevState.isDetail }
    })
  }

  getUrlParser(url) {
    if (url !== "") {
      return url.includes("http") ? (
        <a className={classes.customLink} href={url} target='_blank' rel='noopener noreferrer'>
          <div className={classes.outerCircle}>
            <div className={classes.innerCircle}></div>
          </div>
          <p className={classes.viewMore}>View Details</p>
        </a>
      ) : (
        <Link className={classes.customLink} to={url}>
          <div className={classes.outerCircle}>
            <div className={classes.innerCircle}></div>
          </div>
          <p className={classes.viewMore}>View Details</p>
        </Link>
      )
    } else {
      let isNull = classes.isNull
      return (
        <Link className={[classes.customLink, isNull].join(" ")} to={url}>
          <div className={classes.outerCircle}>
            <div className={classes.innerCircle}></div>
          </div>
          <p className={classes.viewMore}>{this.props.viewDetails}</p>
        </Link>
      )
    }
  }

  render() {
    const { dummy } = this.state
    let title,
      tabTitle,
      desc,
      img,
      content,
      link = null
    let detailContent,
      contentL1,
      contentL2,
      contentL3,
      contentL4,
      contentL5,
      contentL6,
      contentL7,
      contentL8,
      contentL9,
      contentL10,
      contentL11,
      contentL12,
      contentL13,
      contentL14 = null
    let contentBox,
      contentBox1,
      contentBox2,
      contentBox3 = null
    if (this.props.items) {
      let items = this.props.items
      title = <h2 className={classes.title}>{items[0].item.title}</h2>
      tabTitle = <p className={classes.tabTitle}>{items[1].desc.title}</p>

      desc = <span className={classes.content}>{ReactHtmlParser(items[0].item.content)}</span>

      let contentImg = items[0].item.assets[0] ? items[0].item.assets[0].file_small || items[0].item.assets[0].file : require("./assets/default-image.svg")
      img = <img className={[classes.imgContent].join(" ")} src={contentImg} alt='' />

      content = <div className={classes.contentDesc}>{ReactHtmlParser(items[1].desc.article_language.content)}</div>

      link = !this.state.isDetail ? (
        <div className={[classes.contentLink].join(" ")} onClick={this.toggleDetail}>
          <div className='d-flex'>
            <div className={classes.outerCircle}>
              <div className={classes.innerCircle}></div>
            </div>
            <p className={classes.contentLink}>{this.props.readMore}</p>
          </div>
        </div>
      ) : (
        <></>
      )

      const splitStr = items[0].item.article_language.content.split("#")
      desc = <span className={classes.content}>{ReactHtmlParser(splitStr[0] ? splitStr[0] : "")}</span>
      contentL1 = splitStr[1] ? splitStr[1] : ""
      contentL2 = splitStr[2] ? splitStr[2] : ""
      contentL3 = splitStr[3] ? splitStr[3] : ""
      contentL4 = splitStr[4] ? splitStr[4] : ""
      contentL5 = splitStr[5] ? splitStr[5] : ""
      contentL6 = splitStr[6] ? splitStr[6] : ""
      contentL7 = splitStr[7] ? splitStr[7] : ""
      contentL8 = splitStr[8] ? splitStr[8] : ""
      contentL9 = splitStr[9] ? splitStr[9] : ""
      contentL10 = splitStr[10] ? splitStr[10] : ""
      contentL11 = splitStr[11] ? splitStr[11] : ""
      contentL12 = splitStr[12] ? splitStr[12] : ""
      contentL13 = splitStr[13] ? splitStr[13] : ""
      contentL14 = splitStr[14] ? splitStr[14] : ""

      let contentImg2 = items[0].item.assets[1] ? items[0].item.assets[1].file_small || items[0].item.assets[1].file : require("./assets/default-image.svg")
      let contentImg3 = items[0].item.assets[2] ? items[0].item.assets[2].file_small || items[0].item.assets[2].file : require("./assets/default-image.svg")
      let contentImg4 = items[0].item.assets[3] ? items[0].item.assets[3].file_small || items[0].item.assets[3].file : require("./assets/default-image.svg")
      let contentImg5 = items[0].item.assets[4] ? items[0].item.assets[4].file_small || items[0].item.assets[4].file : require("./assets/default-image.svg")
      let contentImg6 = items[0].item.assets[5] ? items[0].item.assets[5].file_small || items[0].item.assets[5].file : require("./assets/default-image.svg")
      let contentImg7 = items[0].item.assets[6] ? items[0].item.assets[6].file_small || items[0].item.assets[6].file : require("./assets/default-image.svg")
      let contentImg8 = items[0].item.assets[7] ? items[0].item.assets[7].file_small || items[0].item.assets[7].file : require("./assets/default-image.svg")
      let contentImg9 = items[0].item.assets[8] ? items[0].item.assets[8].file_small || items[0].item.assets[8].file : require("./assets/default-image.svg")
      let contentImg10 = items[0].item.assets[9] ? items[0].item.assets[9].file_small || items[0].item.assets[9].file : require("./assets/default-image.svg")

      if (this.props.dataExt) {
        this.props.dataExt.forEach((d, i) => {
          switch (i) {
            case 0:
              contentBox = d.sections.map((s, i) => {
                return (
                  <div className={classes.boxX}>
                    <p className={classes.num}>{s.name}</p>
                    <p className={classes.boxxContent}>{s.content}</p>
                  </div>
                )
              })
              break
            case 1:
              contentBox1 = d.sections.map((s, i) => {
                return <span>{s.content}</span>
              })
              break
            case 2:
              contentBox2 = d.sections.map((s, i) => {
                return (
                  <div className={classes.boxX}>
                    <p className={classes.num1}>{s.name}</p>
                    <p className={classes.boxxContent}>{s.content}</p>
                  </div>
                )
              })
              break
            case 3:
              contentBox3 = d.sections.map((s, i) => {
                let url = s.assets ? s.assets[0].file_small || s.assets[0].file : require("./assets/default-image.svg")
                let link = s.url ? this.getUrlParser(s.url) : "/"
                return (
                  <div className={classes.box}>
                    <img src={url} alt=''></img>
                    <div className={classes.boxMask}></div>
                    <div className={classes.contentFront}>
                      <div className={classes.boxContentIn}>
                        <div className='mt-auto mb-auto ml-auto mr-3'>
                          <p>{this.getHtmlParagraph(s.content)}</p>
                          <div className='d-flex flex-row justify-content-end position-relative m-auto mt-1'>{link}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
              break
            default:
          }
        })
      }

      let animate = this.state.isDetail ? classes.fadeInBottom : classes.fadeOutTop
      detailContent = this.state.isDetail ? (
        <div className={[classes.detailContent, animate].join(" ")}>
          <div className='section-a'>
            <h2 className={classes.titleContent}>{ReactHtmlParser(contentL1)}</h2>
            <>
              <hr />
              <div className={classes.allContent}>
                <div className={classes.boxTop}>
                  <img className={classes.imgTop} src={contentImg2} alt=' ' />
                </div>
                <div className={classes.boxBottom}>
                  <div>
                    <p className={classes.content}>{ReactHtmlParser(contentL2)}</p>
                    <p className={classes.content}>{ReactHtmlParser(contentL3)}</p>
                  </div>
                </div>
              </div>
            </>

            <>
              <hr />
              <div className={classes.allContent}>
                <div className={classes.boxTop}>
                  <img className={classes.imgTop} src={contentImg3} alt=' ' />
                </div>
                <div className={classes.boxBottom}>
                  <p className={classes.content}>{ReactHtmlParser(contentL4)}</p>
                </div>
              </div>
            </>

            <>
              <hr />
              <div className={classes.allContent}>
                <div className={classes.boxTop}>
                  <img className={classes.imgTop} src={contentImg4} alt=' ' />
                </div>
                <div className={classes.boxBottom}>
                  <div>
                    <p className={classes.content}>{ReactHtmlParser(contentL5)}</p>
                    <p className={classes.content}>{ReactHtmlParser(contentL6)}</p>
                  </div>
                </div>
              </div>
            </>

            <>
              <hr />
              <div className={classes.allContentC}>
                <div className={classes.boxTop}>
                  <img className={classes.imgTop} src={contentImg5} alt=' ' />
                </div>
                <div className={classes.boxBottom}>
                  <div>
                    <p className={[classes.content, "educations-box-table"].join(" ")}>{ReactHtmlParser(contentL7)}</p>
                  </div>
                </div>
              </div>
            </>

            <>
              <hr />
              <div className={classes.allContentB}>
                <div className={classes.txtParB2}>
                  <img className={classes.imgTop} src={contentImg6} alt=' ' />
                </div>
                <div className={classes.txtParB}>
                  <div>
                    <p className={classes.content}>{ReactHtmlParser(contentL8)}</p>
                  </div>
                </div>
              </div>
            </>

            <>
              <hr />
              <div className={classes.allContentC}>
                <div className={classes.boxTop}>
                  <img className={classes.imgTop} src={contentImg7} alt=' ' />
                </div>
                <div className={classes.boxBottom}>
                  <div>
                    <p className={[classes.content, "educations-box-table"].join(" ")}>{ReactHtmlParser(contentL9)}</p>
                  </div>
                </div>
              </div>
            </>

            <>
              <h2 className={classes.titleContent}>{ReactHtmlParser(contentL10)}</h2>
              <hr />
              <div className={classes.allContent}>
                <div className={classes.boxTop}>
                  <img className={classes.imgTop} src={contentImg8} alt=' ' />
                </div>
                <div className={classes.boxBottom}>
                  <div>
                    <p className={classes.content}>{ReactHtmlParser(contentL11)}</p>
                  </div>
                </div>
              </div>
            </>

            <>
              <hr />
              <div className={classes.allContent}>
                <div className={classes.boxTop}>
                  <img className={classes.imgTop} src={contentImg9} alt=' ' />
                </div>
                <div className={classes.boxBottom}>
                  <div>
                    <p className={classes.content}>{ReactHtmlParser(contentL12)}</p>
                  </div>
                </div>
              </div>
            </>

            <>
              <hr />
              <div className={classes.allContent}>
                <div className={classes.boxTop}>
                  <img className={classes.imgTop} src={contentImg10} alt=' ' />
                </div>
                <div className={classes.boxBottom}>
                  <div>
                    <p className={classes.content}>
                      {ReactHtmlParser(contentL13)}
                      {ReactHtmlParser(contentL14)}
                    </p>
                  </div>
                </div>
              </div>
            </>
          </div>

          <div className={[classes.contentLink].join(" ")} onClick={this.toggleDetail}>
            <div className='d-flex'>
              <div className={classes.outerCircle}>
                <div className={classes.innerCircle}></div>
              </div>
              <p className={classes.contentLink}>{this.props.readLess}</p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )
    } else {
    }
    let customMargin = !this.state.isDetail ? "mb-5" : ""

    return (
      <div className={classes.sectionEducations}>
        <div className={["container d-flex flex-column", classes.customContainer].join(" ")}>
          <div className={classes.boxImage}>{img}</div>
          <div className={classes.contentTop}>
            <div className={classes.boxContent}>
              {tabTitle}
              {title}
              {desc}
              <div className={[classes.contentOverflow].join(" ")}>{content}</div>
              {link}
            </div>
          </div>

          {detailContent}
        </div>
      </div>
    )
  }
}
