import React, { Component } from "react";

import Api from "./Api";

import ReactHtmlParser from "react-html-parser";
import classes from "./BOD_Section1.module.css";

class BOD_Section1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentSection: [],
            isLoaded: false,
            isApiLoaded: false,
            isBoxHover: false,
            boxCount: 0,
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        apiData
            .getSectionDetail()
            .then((result) => {
                const sections = result.data;
                const widget = [];

                result.data.widgets.forEach((item) => {
                    widget.push({
                        id: item.id,
                        index: item.index,
                        category: item.category,
                        name: item.name,
                        sections: item,
                    });
                });

                sections.page = widget;
                this.setState({
                    contentSection: widget,
                    isLoaded: true,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    isApiLoaded: true
                })
            });
    }

    toggleBoxHandlerEnter = () => {
        this.setState((prevState) => {
            return { isBoxHover: !prevState.isBoxHover };
        });
    };

    render() {

        var title, description, img, imgDescription;
        var dummyTitle = "Title";
        var dummyDescription = "Nice overview"

        if (this.state.isLoaded) {
            title = this.state.contentSection[0] ? this.state.contentSection[0].sections.widget_contents[0] ? this.state.contentSection[0].sections.widget_contents[0].article_language.title : dummyTitle : dummyTitle;
            description = this.state.contentSection[0] ? this.state.contentSection[0].sections.widget_contents[0] ? this.state.contentSection[0].sections.widget_contents[0].article_language.content : dummyDescription : dummyDescription;
            img = this.state.contentSection[0]?.sections.widget_contents[0]?.assets[0]?.file
            imgDescription = this.state.contentSection[0]?.sections.widget_contents[0]?.assets[0]?.description
        }

        return (
            <>
                <img className={classes.imageFull} src={img} alt="" />
                <div onScroll={this.handleScroll} className={classes.posRelative}>

                    <div className={['container', classes.customContainer].join(' ')}>
                        <h2 className={classes.title}>{title}</h2>
                        <span className={classes.contentDesc}>
                            {ReactHtmlParser(description)}
                        </span>
                    </div>
                </div>
            </>
        );
    }
}

export default BOD_Section1;

