import React, { Component } from "react";
import { Modal } from "react-bootstrap";

import UserContext from "../../../../Context";

import Api from "../../../../../Api";
import classes from "./Modal.module.css";

export default class ModalName extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      id: 0,
      isFirstName: false,
      data: [],
    };
    this.isValidate = this.isValidate.bind(this);
  }

  componentDidMount() {
    const contextValue = this.context;

    this.setState({
      isLoggedIn: contextValue.isLoggedIn,
      id: contextValue.idApplicant,
      data: contextValue.contentSection[9].sections.widget_contents,
    });
  }

  isValidate() {
    if (document.getElementById("m-fname").value !== "") {
      return true;
    } else {
      this.setState({
        isFirstName: true,
      });
      return false;
    }
  }

  updateProfile() {
    const update = new Api();
    const contextValue = this.context;

    if (this.isValidate()) {
      const updateData = {
        firstname: document.getElementById("m-fname").value,
        lastname: document.getElementById("m-lname").value,
      };

      update
        .updateApplicant(this.state.id, updateData, "name")
        .then((res) => {
          this.setState({
            isFirstName: false,
          });
          this.props.close();
          contextValue.getNewDataApplicant();
          contextValue.showNotification("name updated successfully");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  render() {
    let title, first_name, last_name, cancel, save;

    this.state.data.forEach((x, i) => {
      switch (i) {
        case 0:
          title = x.content;
          break;
        case 1:
          first_name = x.content;
          break;
        case 12:
          cancel = x.content;
          break;
        case 13:
          save = x.content;
          break;
        case 14:
          last_name = x.content;
          break;

        default:
      }
    });
    let isError = this.state.isFirstName ? classes.errorVisible : "";
    return (
      <Modal
        size={"md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.show}
        onHide={this.props.close}
      >
        <Modal.Header
          className={classes.modalHeader}
          onClick={this.props.close}
          closeButton
        >
          <span className={classes.modalTitle}>{title}</span>
        </Modal.Header>
        <UserContext.Consumer>
          {
            // context => console.log(context)
            (context) => (
              <div className={classes.modalContent}>
                <div className={classes.content}>
                  <div className={classes.boxContent}>
                    <span className={classes.titleContent}>{first_name}</span>
                    <input
                      id="m-fname"
                      className={classes.inputModal}
                      type={"text"}
                      defaultValue={
                        context.dataApplicant.user
                          ? context.dataApplicant.user[0].firstname
                          : ""
                      }
                    />
                  </div>
                  <span className={[classes.contentError, isError].join(" ")}>
                    This field is required
                  </span>
                  <div className={classes.boxContent}>
                    <span className={classes.titleContent}>{last_name}</span>
                    <input
                      id="m-lname"
                      className={classes.inputModal}
                      type={"text"}
                      defaultValue={
                        context.dataApplicant.user
                          ? context.dataApplicant.user[0].lastname
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className={classes.modalAction}>
                  <div className={classes.cancel}>
                    <span>{cancel}</span>
                  </div>
                  <div
                    className={classes.save}
                    onClick={() => {
                      this.updateProfile();
                      // context.getNewDataApplicant()
                      // this.props.close()
                    }}
                  >
                    <span>{save}</span>
                  </div>
                </div>
              </div>
            )
          }
        </UserContext.Consumer>
      </Modal>
    );
  }
}
