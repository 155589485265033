import React, { Component } from "react"
import { Link } from "react-router-dom"
import { isMobile, isTablet } from "react-device-detect"
import Language from "./Language"
import Api from "./Api"
import classes from "./Footer.module.css"

class Footer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [
        {
          id: 1,
          title: "Vietnamese",
          type: "inactive",
          img: require("./assets/iconVn.svg"),
        },
        {
          id: 2,
          title: "English",
          type: "inactive",
          img: require("./assets/iconUk.svg"),
        },
      ],
      menus: {
        items: [],
      },
      isLoaded: false,
      activeLang: 0,
      languages: "x",
    }
    this.getMenu = this.getMenu.bind(this)
    this.checkUrlTarget = this.checkUrlTarget.bind(this)
    //this.renderHeaderItem = this.renderHeaderItem.bind(this);
    //this.renderRootItem = this.renderRootItem.bind(this);
  }

  componentDidMount() {
    this.getMenu()

    let defaultLang = localStorage.getItem("language")
    let active = 0
    if (defaultLang && defaultLang.toLowerCase() === "vietnamese") {
      active = 0
    } else if (defaultLang && defaultLang.toLowerCase() === "english") {
      active = 1
    } else {
      active = 0
      localStorage.setItem("language", "Vietnamese")
    }

    let lang
    if (isTablet) {
      lang = "x"
    } else if (isMobile) {
      lang = "x"
    } else {
      lang = "y"
    }

    this.setState({
      activeLang: active,
      languages: lang,
    })
  }

  getMenu() {
    const apiData = new Api()
    this.setState({ menus: [] }, () => {
      apiData
        .getMenuDetail()
        .then((result) => {
          const menu = result.data
          const item = []

          menu.items.forEach((i) => {
            item.push({
              index: i.index,
              id: i.id,
              name: i.title,
              url: i.url,
              children: i.children,
            })
          })
          menu.items = item
          this.setState({
            menus: menu,
            isLoaded: true,
          })
        })
        .catch((error) => {
          console.log(error)
        })
    })
  }

  checkUrlTarget(url) {
    var substring = url.substr(0, 4)
    if (substring === "http") {
      return "external"
    } else {
      return "internal"
    }
  }

  toggleLanguage = (lang) => {
    let active = null
    if (lang) {
      if (lang.toLowerCase() === "vietnamese") {
        active = 0
      } else {
        active = 1
      }
      this.setState({
        language: lang,
        activeLang: active,
      })
      localStorage.setItem("language", lang)
      window.location.reload(true)
    }
  }

  render() {
    var renderHeaderItemVar = function renderHeaderItem(headerItem) {
      return headerItem.children.map(function (bodyElement) {
        if (bodyElement.url) {
          if (bodyElement.url !== "") {
            if (bodyElement.url.includes("http")) {
              return (
                <a key={bodyElement.title} className={classes.customLink} href={bodyElement.url} rel='noopener noreferrer'>
                  <p className={classes.contentFooter}>{bodyElement.title}</p>
                </a>
              )
            } else {
              return (
                <Link
                  onClick={() => {
                    setTimeout(() => {
                      window.location.reload()
                    }, 500)
                  }}
                  key={bodyElement.title}
                  className={classes.customLink}
                  to={bodyElement.url}
                >
                  <p className={classes.contentFooter}>{bodyElement.title}</p>
                </Link>
              )
            }
          } else if (bodyElement.url === "") {
            return <p className={classes.contentFooter}>{bodyElement.title}</p>
          }
        } else {
          return (
            <p key={bodyElement.title} className={classes.contentIconLeft}>
              {bodyElement.title}
            </p>
          )
        }
      })
    }

    let count = 0
    let lang
    var renderRootItemVar = function renderRootItem(rootItem, index, l) {
      if (rootItem.children) {
        return (
          <>
            {rootItem.children.map(function (headerItem, i) {
              count += 1

              if (count - 1 === index) {
                lang = <Language lang={l} />
              }

              var headerItemContent = ""
              if (headerItem.children) {
                headerItemContent = renderHeaderItemVar(headerItem)
              }

              return (
                <>
                  <div key={headerItem.title} className={classes.boxFooter}>
                    <p className={classes.titleFooter}>{headerItem.title}</p>
                    {headerItemContent}
                    {count - 1 === index ? (l === "y" ? lang : "") : ""}
                  </div>
                  {/* {count === (index - 1) ? (l === "x" ? lang : '') : ""} */}
                </>
              )
            })}
          </>
        )
      }
    }

    const { menus } = this.state
    let content
    let contentLang
    if (this.state.isLoaded) {
      let lg = this.state.languages
      content = menus.items.map(function (rootItem) {
        return renderRootItemVar(rootItem, menus.items.length, lg)
      })

      if (this.state.languages === "x") {
        contentLang = <Language lang={this.state.languages} />
      }
    } else if (this.state.isApiLoaded) {
      content = (
        <>
          <div className=''>
            <p className='title-footer'>lorem ipsum seo</p>
            <p className='content-footer'>seo keywords</p>
            <p className='content-footer'>human development</p>
            <p className='content-footer'>enviromental development</p>
            <p className='content-footer'>community & society</p>
          </div>
          <div>
            <p className='title-footer'>lorem ipsum seo</p>
            <p className='content-footer'>seo keywords</p>
            <p className='content-footer'>human development</p>
            <p className='content-footer'>enviromental development</p>
            <p className='content-footer'>community & society</p>
          </div>
          <div>
            <p className='title-footer'>lorem ipsum seo</p>
            <p className='content-footer'>seo keywords</p>
            <p className='content-footer'>human development</p>
            <p className='content-footer'>enviromental development</p>
            <p className='content-footer'>community & society</p>
          </div>
          <div>
            <p className='title-footer'>factory</p>
            <p className='content-icon-left'>Hoa Lac Hi-Tech, Thach hoa, Thach That, ha noi, vietnam</p>
            <p className='content-icon-left'>+842 433 685 980</p>
            <p className='content-icon-left'>+842 433 687 095</p>
            <p className='content-icon-left'>+Support@phenikaa.com</p>
          </div>
          <div>
            <p className='title-footer'>office</p>
            <p className='content-icon-left'>167 hoang ngan, trung hoa, cau giay, ha noi, vietnam</p>
            <p className='title-footer'>language</p>
            <p className='content-icon-left'>english - usa</p>
            <p className='title-footer'>social media</p>
            <div className='row p-0 m-0'>
              <img style={{ width: "40px", height: "40px" }} src={require("./assets/fb.png")} alt=''></img>
              <img style={{ width: "40px", height: "40px" }} src={require("./assets/twitter.png")} alt=''></img>
              <img style={{ width: "40px", height: "40px" }} src={require("./assets/instagram.png")} alt=''></img>
              <img style={{ width: "40px", height: "40px" }} src={require("./assets/pinterest.png")} alt=''></img>
              <img style={{ width: "40px", height: "40px" }} src={require("./assets/dropbox.png")} alt=''></img>
            </div>
          </div>
        </>
      )
    }

    let custom = this.state.languages === 1 ? classes.customFlexContainer : ""

    return (
      <div className={classes.contentFooter}>
        <div className={["container", classes.cusT].join(" ")}>
          <img className={classes.footerBackground} src={require("./assets/background.svg")} alt='' />
          <div className={classes.sectionFooter}>
            <div className={[classes.flexContainerFooter, custom].join(" ")}>{content}</div>
            {contentLang}
          </div>
        </div>
      </div>
    )
  }
}

export default Footer
