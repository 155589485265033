import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import classes from "./ModalNego.module.css";
import "./TableNego.css";

class ModalSuccess extends Component {
  render() {
    return (
      <Modal
        size={"sm"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.show}
        onHide={this.props.close}
      >
        <div className={classes.modalContent}>
          {" "}
          <span style={{ fontWeight: "bold", color: "#28a745" }}>
            Salary Offer has been Added
          </span>
        </div>

        <div className={classes.contentAction2}>
          <div className={classes.cancel2} onClick={this.props.close}>
            <span>OK</span>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalSuccess;
