import React from "react";
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import { isMobile, isTablet } from "react-device-detect";
import Tablet from "./Tablet";

export default function Footer() {
  let content;

  if (isTablet) {
    content = <Tablet />;
  } else if (isMobile) {
    content = <Mobile />;
  } else {
    content = <Desktop />;
  }

  return <>{content}</>;
}
