import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Slide } from "react-awesome-reveal";
import dateFormat from "dateformat";
import slugify from 'react-slugify';
// import ReactHtmlParser from "react-html-parser";

import Navbar from "./Navbar";
import Card from "../Card/Card";
import ModalPDF from '../ModalPDF/ModalPDF';

import Api from "./../Api";
import classes from "./Mobile.module.css";

class MobileViews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentArticle: [],
            moreNews: "4",
            isLoaded: false,
            filtered: "",
            articleCategories: null,
            pagination: 6,
            articleID: null,
            displayStyle: "grid",
            loadedArticle: 6,
            idLoaded: 0,
            searchTextKey: "",
            page: 1,
            isLast: false,
            lang: "",

            // PDF preview
            isShowPdfPreview: false,
            pdfFile: "",
            pdfTitle: ""
        };
        this.getArticleDetail = this.getArticleDetail.bind(this);

        this.onFilterChange = this.onFilterChange.bind(this);
        this.getSearchedData = this.getSearchedData.bind(this);
        this.changeDisplay = this.changeDisplay.bind(this);
        this.loadMoreNews = this.loadMoreNews.bind(this);
        this.setDefaultLoadedArticle = this.setDefaultLoadedArticle.bind(this);
        this.insertArticleInArray = this.insertArticleInArray.bind(this);
        this.toggleHandlerEnter = this.toggleHandlerEnter.bind(this);
    }

    componentDidMount() {
        const { protocol, host, pathname } = window.location;
        const fullUrlWithoutParams = `${protocol}//${host}${pathname}`;

        const url = new URL(window.location.href);
        const paramValue = url.searchParams.get("filter");
        const paramMagazine = url.searchParams.get("title");
        const paramMagazineOrder = url.searchParams.get("order");

        this.setState({
            articleID: this.props.id ? this.props.id : "",
            articleCategories: this.props.category ? this.props.category : 2,
            fullUrlWithoutParams: fullUrlWithoutParams,
            paramValue: paramValue ? paramValue : false,
            filtered: paramValue ? paramValue : "",
            paramMagazine: paramMagazine,
            paramMagazineOrder: paramMagazineOrder,
            fileMagazine: this.props.fileMagazine
        });

        if (this.props.id) {
            if (paramValue) {
                this.getArticleDetail(
                    this.props.id,
                    paramValue,
                    this.state.loadedArticle,
                    this.props.valueSearch ? this.props.valueSearch : "",
                    this.state.page
                );
            } else {
                this.getArticleDetail(
                    this.props.id,
                    "",
                    this.state.loadedArticle,
                    this.props.valueSearch ? this.props.valueSearch : "",
                    this.state.page
                );
            }
        }

        this.setDefaultLoadedArticle();
        let is_language = localStorage.getItem("language");

        if (is_language) {
            this.setState({
                lang: true,
            });
        }

        if (paramMagazine && paramMagazineOrder && this.props.fileMagazine) {
            this.props.fileMagazine.forEach((item, idx) => {
                if (paramMagazine && parseInt(paramMagazineOrder) >= 0) {
                    if (paramMagazine === slugify(item[2]) && parseInt(paramMagazineOrder) === idx) {
                        this.showMagazine(item[1], item[2])
                    }
                }
            })
        }
    }

    setDefaultLoadedArticle(multiply = 1) {
        if (window.screen.width >= 2560) {
            this.setState({
                loadedArticle: 12 * multiply,
            });
        } else {
            this.setState({
                loadedArticle: 6 * multiply,
            });
        }
    }

    insertArticleInArray(array, articleToInsert) {
        var inserted = false;
        for (var i = 0; i < array.length; i++) {
            if (articleToInsert.date > array[i].date) {
                let startArray = array.slice(0, i);
                let endArray = array.slice(i, array.length);
                startArray.push(articleToInsert);
                array = startArray.concat(endArray);
                inserted = true;
                break;
            }
        }
        if (!inserted) {
            array.push(articleToInsert);
        }
        return array;
    }

    getArticleDetail(param, filter, size, search, page) {
        let count = 0;
        if (count === 0) {
            const apiData = new Api();

            var art = [...this.state.contentArticle];

            apiData
                .getArticleDetailByYearDescendingPaginationMultiWidget(
                    param,
                    filter,
                    size,
                    search,
                    page
                )
                .then((result) => {
                    const articles = result.data;

                    if (!articles.next) {
                        this.setState({ isLast: true });
                    }
                    let newArts = [];

                    articles.results.forEach((a) => {
                        const assets = [];

                        let typeVid = [
                            "webm",
                            "mkv",
                            "flv",
                            "gif",
                            "avi",
                            "mov",
                            "wmv",
                            "mp4",
                            "3gp",
                        ];
                        let typeImg = [
                            "jpg",
                            "jpeg",
                            "png",
                            "svg",
                            "webp",
                            "bmp",
                            "tiff",
                            "exif",
                        ];

                        a.article_assets.forEach((ass, i) => {
                            if (i === 0) {
                                if (ass.file_small) {
                                    let extension = ass.file_small.substring(
                                        ass.file_small.lastIndexOf(".") + 1
                                    );

                                    if (typeVid.includes(extension)) {
                                        assets.push({ assetV: ass.file_small });
                                    } else if (typeImg.includes(extension)) {
                                        assets.push({
                                            asset: ass.file_small,
                                            description: ass.description,
                                        });
                                    }
                                } else {
                                    const vidId = this.getVidId(ass.asset_link);
                                    const ytThumb = vidId
                                        ? `https://img.youtube.com/vi/${vidId}/hqdefault.jpg`
                                        : null;
                                    const asset =
                                        ass.asset_thumbnail || ytThumb;
                                    if (asset) {
                                        assets.push({ asset: asset });
                                    } else {
                                        assets.push({ assetV: ass.asset_link });
                                    }
                                }
                            }
                        });

                        let newArticle = {
                            id: a.id,
                            category: a.category_names,
                            categoryID: a.categories,
                            name: a.name,
                            title: a.article_languages.title,
                            url_title: a.article_languages.slug_title,
                            summary: a.article_languages.summary,
                            content: a.article_languages.content,
                            assets: assets,
                            date: a.date,
                        };

                        newArts.push(newArticle);
                    });

                    art = newArts;
                    return art;
                })
                .then((art) => {
                    this.setState({
                        contentArticle: this.state.contentArticle.concat(art),
                        isLoaded: true,
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
            count = 1;
        }
    }

    getVidId(ytEmbedUrl) {
        if (!ytEmbedUrl) {
            return ytEmbedUrl;
        }

        const url = new URL(ytEmbedUrl);
        const path = url.pathname.split("/");
        const vidId =
            path[path.length - 1] === ""
                ? path[path.length - 2]
                : path[path.length - 1];
        return vidId;
    }

    onFilterChange(e) {
        if (e.target.value !== "x") {
            window.location.href = `${this.state.fullUrlWithoutParams}?filter=${e.target.value}`;
            // this.setState(
            //   {
            //     filtered: e.target.value,
            //     contentArticle: [],
            //     page: 1,
            //     isLast: false,
            //   },
            //   () => {
            //     this.getArticleDetail(
            //       this.state.articleID,
            //       this.state.filtered,
            //       this.state.loadedArticle,
            //       this.props.valueSearch ? this.props.valueSearch : "",
            //       this.state.page
            //     );
            //   }
            // );
        } else {
            this.setState({
                filtered: e.target.value,
                contentArticle: [],
                isLast: true,
            });
        }
    }

    loadMoreNews() {
        const url = new URL(window.location.href);
        const paramValue = url.searchParams.get("filter");
        this.setState(
            (prevState) => {
                return {
                    pagination: prevState.pagination + this.state.loadedArticle,
                    page: prevState.page + 1,
                };
            },
            () => {
                const f = paramValue ? paramValue : 2;

                this.getArticleDetail(
                    this.props.id,
                    f,
                    this.state.loadedArticle,
                    this.props.valueSearch ? this.props.valueSearch : "",
                    this.state.page
                );
            }
        );
    }

    toggleHandlerEnter(key) {
        this.setState({
            searchTextKey: key,
        });
    }

    getSearchedData(e) {
        if (e.keyCode === 13) {
            if (e.target.value !== "") {
                const value = e.target.value;
                this.setState(
                    {
                        contentArticle: [],
                        page: 1,
                        isLast: false,
                    },
                    () => {
                        this.getArticleDetail(
                            this.state.articleID,
                            "",
                            this.state.loadedArticle,
                            value,
                            this.state.page
                        );
                    }
                );
            }
        }
    }

    changeDisplay = (params) => {
        this.setState({ displayStyle: params });
    };

    imageOrVideo = (url, param, display, imageDescription) => {
        let content, imgStyle, vidStyle;
        if (display === "grid") {
            imgStyle = classes.imgGrid;
            vidStyle = classes.vidStyle;
        } else {
            imgStyle = classes.box_image;
            vidStyle = classes.boxVideo;
        }
        if (url !== "") {
            switch (param) {
                case "img":
                    content = (
                        <img
                            className={imgStyle}
                            src={
                                url
                                    ? url
                                    : require("./../assets/screenshot.png")
                            }
                            alt={imageDescription}
                        />
                    );
                    break;
                case "vid":
                    if (url) {
                        if (url.includes("phenikaa-assets.s3")) {
                            content = (
                                <video
                                    className={vidStyle}
                                    controls
                                    loop={false}
                                >
                                    <source src={url} type="video/mp4" />
                                </video>
                            );
                        } else {
                            content = (
                                <iframe
                                    src={url}
                                    className={vidStyle}
                                    title="Iframe Example"
                                ></iframe>
                            );
                        }
                    } else {
                        content = (
                            <img
                                className={imgStyle}
                                src={require("../assets/default-image.svg")}
                                alt="description"
                            />
                        );
                    }
                    break;
                default:
                    break;
            }
        } else {
            content = (
                <img
                    className={imgStyle}
                    src={require("./../assets/screenshot.png")}
                    alt="description"
                />
            );
        }
        return content;
    };

    onShareMagazine = (url) => {
        if (url) {
            navigator.clipboard.writeText(url)
        }
    }

    showMagazine = (file, title) => {
        this.setState({
            pdfFile: file,
            pdfTitle: title
        }, () => {
            this.setState({ isShowPdfPreview: true, })
        })
    }

    render() {
        const { contentArticle, pagination } = this.state;
        console.log(this.state.filtered)
        // SET CONTENT NEWS GRID MODE
        let apiContentGrid = null;
        if (this.state.isLoaded) {
            apiContentGrid = contentArticle.map((s, i) => {
                let category_id = s.categoryID ? s.categoryID[0] : "";
                let widget0, widget1;

                if (this.props.id) {
                    this.props.id.forEach((ele, i) => {
                        if (i === 0) {
                            widget0 = ele;
                        } else {
                            widget1 = ele;
                        }
                    });
                }

                let ids =
                    s.id + "-" + category_id + "-" + widget0 + "-" + widget1;

                const NewDate = dateFormat(s.date, "dd-mm-yyyy");

                let param = s.assets[0]
                    ? s.assets[0].asset
                        ? "img"
                        : "vid"
                    : "";

                let newAsset = this.imageOrVideo(
                    s.assets[0]
                        ? s.assets[0].asset
                            ? s.assets[0].asset
                            : s.assets[0].assetV
                        : "",
                    param,
                    "grid",
                    s.assets[0] ? s.assets[0].description : null
                );

                return (
                    <Slide triggerOnce direction={"top"}>
                        <div
                            className={[
                                classes.contentWrap,
                                classes.fadeInBottomm,
                            ].join(" ")}
                        >
                            <div className={classes.boxContent1}>
                                <div className={classes.box_behind}>
                                    <div className={classes.box1a}>
                                        {newAsset}
                                        {/* <img src={s.assets[0].asset} alt="" /> */}
                                    </div>
                                    <div className={classes.box2b}>
                                        <div className={classes.box2b01}>
                                            <div className={classes.box2b02}>
                                                <p
                                                    className={
                                                        classes.title_content1
                                                    }
                                                >
                                                    {s.title}
                                                </p>
                                            </div>
                                            <div className={classes.box2b0203}>
                                                <Link
                                                    to={`${this.props.url}/${s.url_title !== ""
                                                        ? s.url_title
                                                        : "-"
                                                        }/${s.id}`}
                                                >
                                                    <div
                                                        className={
                                                            classes.outerCircle2
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                classes.innerCircle2
                                                            }
                                                        ></div>
                                                    </div>
                                                    <p
                                                        className={
                                                            classes.readMore
                                                        }
                                                    >
                                                        {this.props.readMore}
                                                    </p>
                                                </Link>
                                                <p
                                                    className={
                                                        classes.date_right1
                                                    }
                                                >
                                                    {NewDate}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slide>
                );
            });
        }

        // SET NEWS CATEGORY
        const { articleCategories } = this.state;

        // SET CONTENT MAGAZINE
        let contentMagazine, displayMagazine;
        if (parseInt(this.state.paramValue) === 99 && parseInt(this.state.filtered) === 99) {
            contentMagazine = this.props.fileMagazine.map((item, idx) => {
                let custom = idx === 0 ? classes.boxFirst : "";
                return (
                    <Card
                        first={custom}
                        idx={idx}
                        lang={this.state.lang}
                        imgUrl={item[0]}
                        // url={item[1]}
                        text={item[2]}
                        onClick={() => {
                            this.showMagazine(item[1], item[2])

                            // this.setState({
                            //     pdfFile: item[1],
                            //     pdfTitle: item[2]
                            // }, () => {
                            //     this.setState({ isShowPdfPreview: true, })
                            // })
                        }}
                        shareLink={`${this.state.fullUrlWithoutParams}?filter=99&title=${slugify(item[2])}&order=${idx}`}
                        // onShare={() => this.onShareMagazine(`${this.state.fullUrlWithoutParams}?filter=99&title=${slugify(item[2])}&order=${idx}`)}
                        isMobile={true}
						onCopy={this.props.onCopy}
                    />
                );
            });
            displayMagazine = (
                <div className={classes.contentMagazine}>{contentMagazine}</div>
            );
        }

        // CHANGE LIST/GRID VIEW
        let viewOnchange,
            apiContentlist,
            nav = null;
        if (this.state.searchTextKey === "search") {
            viewOnchange = (
                <div className={classes.component}>{apiContentlist}</div>
            );
            nav = (
                <Navbar
                    sText={this.state.searchTextKey}
                    onClick={() => this.toggleHandlerEnter("search")}
                    onKeyDown={this.getSearchedData}
                    onChange={this.onFilterChange}
                    category={articleCategories}
                    title={this.props.title}
                    searchHere={this.props.textSearch}
                    selectedFilter={this.state.filtered}
                    filterMagazine={this.props.filterMagazine}
                    paramValue={this.state.paramValue}
                />
            );
        } else {
            viewOnchange = (
                <div className={classes.componentgrid}>
                    <div className={["row", classes.box_grid].join(" ")}>
                        {apiContentGrid}
                    </div>
                </div>
            );
            nav = (
                <Navbar
                    sText={this.state.searchTextKey}
                    onKeyDown={this.getSearchedData}
                    onChange={this.onFilterChange}
                    onClick={() => this.toggleHandlerEnter("search")}
                    category={articleCategories}
                    title={this.props.title}
                    searchHere={this.props.textSearch}
                    selectedFilter={this.state.filtered}
                    filterMagazine={this.props.filterMagazine}
                    paramValue={this.state.paramValue}
                />
            );
        }

        let loadMore;
        if (!this.state.isLast) {
            loadMore = (
                <div className={classes.older} onClick={this.loadMoreNews}>
                    <p>{this.props.newsButton}</p>
                </div>
            );
        }

        return (
            <>
                {nav}
                {displayMagazine}
                {viewOnchange}
                {loadMore}

                <ModalPDF
                    show={this.state.isShowPdfPreview}
                    onHide={() => {
                        this.setState({ isShowPdfPreview: false, pdfFile: "" })
                    }}
                    file={this.state.pdfFile}
                    title={this.state.pdfTitle}
                />
            </>
        );
    }
}

export default MobileViews;
