import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

import Api from "../Api";
import classes from "./Mobile.module.css";

export default class Mobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentSection: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
      activeImg: 3,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }

  componentDidMount() {
    this.getSectionDetail();
  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState({ contentSection: [] }, () => {
      apiData
        .getSectionDetail()
        .then((result) => {
          const sections = result.data;
          const widget = [];

          result.data.widgets.forEach((item) => {
            widget.push({
              id: item.id,
              index: item.index,
              category: item.category,
              name: item.name,
              sections: item,
            });
          });

          sections.page = widget;
          this.setState({
            contentSection: widget,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }

  toggleExtends = (i) => {
    this.setState({
      activeImg: i,
    });
  };

  render() {
    let companyCultureImages = null;

    let dummyCulture = null;

    let title, content, readmore, link;

    if (this.state.isLoaded) {
      this.state.contentSection.forEach((item, index) => {
        switch (index) {
          case 0:
              title = item.sections.widget_contents[0]
                  ? item.sections.widget_contents[0].content
                  : "Company Culture";
              break;
          case 1:
              content = item.sections.widget_contents[0]
                  ? item.sections.widget_contents[0].content
                  : "";
              break;
          case 2:
              readmore = item.sections.widget_contents[0]
                  ? item.sections.widget_contents[0].content
                  : "Read more";
              link = <Link to={item.sections.url !== '' ? item.sections.url : '/company-culture'} className={classes.contentLink}>
                  <div className={classes.outerCircle}>
                      <div className={classes.innerCircle}></div>
                  </div>
                  <p className={classes.readMore}>{readmore}</p>
              </Link>
              break;
          case 3:
            companyCultureImages = item.sections.widget_contents.map(
              (imageItem, i) => {
                let active =
                  this.state.activeImg === i ? classes.activeImg : "";
                return (
                  <div
                    className={[classes.boxGallery, active].join(" ")}
                    onClick={() => this.toggleExtends(i)}
                  >
                    <figure>
                      <img src={imageItem.file_medium} alt=""></img>
                    </figure>
                  </div>
                );
              }
            );
            break;
          default:
            break;
        }
      });
    }

    return (
      <>
        <div className={["container", classes.customContainer].join(" ")}>
          {/* <p className={classes.title}>{title}</p>
          <p className={classes.desc}>{ReactHtmlParser(content)}</p>
          {link} */}
          <div className={classes.section}>
            <div className={classes.cultureWrap}>
              {companyCultureImages ? companyCultureImages : dummyCulture}
            </div>
          </div>
        </div>
      </>
    );
  }
}
