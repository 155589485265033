import React from "react";
import classesD from "../MenuDetail.module.css";
import css from "./MenuPreview6.module.css";
import { Link } from "react-router-dom";
import classes from "../MenuDetail.module.css";
import Slider from "react-slick";
import { Card } from "react-bootstrap";
import dateFormat from "dateformat";

const menuPreview6 = (props) => {
  let isLogin = localStorage.getItem("isLoggedIn");
  let token = localStorage.getItem("token");

  let isLoginClass, isFilter;
  const sett = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    swipeToSlide: true,
  };
  let item,
    dataLength,
    titleP,
    jobV,
    urgentText,
    newJobText,
    imageSlider = null;

  let dataToken = token ? true : false;

  if (isLogin && dataToken) {
    isLoginClass = css.loginTrue;
    isFilter = css.isFilter;
  } else {
    isLoginClass = "";
    isFilter = "";
  }

  let required = (
    <div className={[css.boxSignIn, isLoginClass].join(" ")}>
      <div className={css.boxSign}>
        <Link
          onClick={() => {
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }}
          to={"/login"}
        >
          <p>Require Sign In</p>
        </Link>
      </div>
    </div>
  );

  if (props.data) {
    props.data.forEach((j, index) => {
      switch (index) {
        case 0:
          titleP = j.title;
          item = j.widget.widget_contents.map((ele) => {
            let date = dateFormat(ele.date, "dd-mm-yyyy");

            let srcData, imageCard;
            if (ele.assets.length === 0) {
              srcData = require("./assets/default-image.svg");
              imageCard = (
                <Card.Img
                  className={css.contentImgNull}
                  variant=""
                  src={srcData}
                />
              );
            } else {
              srcData = ele.assets[0].file_small
                ? ele.assets[0].file_small
                : ele.assets[0].file;
              imageCard = (
                <Card.Img className={css.contentImg} variant="" src={srcData} />
              );
            }
            let url = "/career/career-list/career-details-page-new/";

            let id = ele.job_poster.id;
            let urlfix = url + id;
            let titleJob = ele.title;
            return (
              <div className="m-3">
                <Link
                  onClick={() => {
                    setTimeout(() => {
                      window.location.reload();
                    }, 500);
                  }}
                  to={urlfix}
                  className={css.Link}
                >
                  <Card className={classesD.cardPreviewBody}>
                    {imageCard}
                    <span className={[css.textTitleNews, css.custom].join(" ")}>
                      {titleJob}
                    </span>
                    <p className={css.dateTitleNews}>{date}</p>
                  </Card>
                </Link>
              </div>
              // <div className="d-flex flex-column mt-3">
              //   <a
              //     href={urlfix}
              //     className={css.itemLink}
              //     target="_blank"
              //     rel="noopener noreferrer"
              //   >
              //     <p className={css.titleB}>{company}</p>
              //   </a>
              //   <p className={css.litleP}>{titleJob}</p>
              // </div>
            );
          });

          imageSlider = j.widget.widget_contents.map((g) => {
            let picture;
            if (g.assets.length === 0) {
              picture = (
                <img src={require("./assets/image-background.jpg")} alt=" " />
              );
            } else {
              let srcData = g.assets[0].file_mobile
                ? g.assets[0].file_mobile
                : g.assets[0].file;

              picture = <img src={srcData} alt=" " />;
            }

            return <div className={css.picBox}>{picture}</div>;
          });
          break;
        case 1:
          // jobV = j.title;
          // urgentText = j.children[0].title;
          // newJobText = j.children[1].title;
          break;
        default:
      }
    });
    dataLength = props.data[0].widget.widget_contents.length;
  }

  // let child = (
  //   <div id="preview3" className="row col-md-12">
  //     <p className={css.titleC}>{props.name}</p>
  //     <div className="d-flex flex-column">
  //       <div className={["d-flex flex-column", css.boxL].join(" ")}>
  //         <p className={css.titleB_2}>{titleP}</p>
  //         <Slider {...sett}>{imageSlider}</Slider>
  //         {/* <img src={require("./assets/image-background.jpg")} alt=" " /> */}
  //       </div>
  //     </div>

  //     <div className="d-flex flex-column p-0 ml-5 pr-4">
  //       <div className="d-flex flex-column">
  //         <a
  //           href={"/career-new"}
  //           className={css.itemLink}
  //           target="_blank"
  //           rel="noopener noreferrer"
  //         >
  //           <p className={classes.subTitleD}>{jobV}</p>
  //         </a>
  //         <p className={css.titleB_1}>{urgentText}</p>
  //         <p className={css.litleP}>
  //           {dataLength} {newJobText}
  //         </p>
  //       </div>
  //       {item}
  //     </div>
  //   </div>
  // );

  return (
    <div className={classesD.sectionPreview}>
      <div id="preview6" className={"d-flex flex-row"}>
        {item}
      </div>
    </div>
  );
};

export default menuPreview6;
