import React, { Component } from "react";
import Api from "./Api";
import classes from "./environment.module.css";
import ReactHtmlParser from "react-html-parser";

export default class Box extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentArticle: [],
      aboutPhenikaa: [],
      isLoaded: false,
      isApiLoaded: true,
      activeSidebar: 0,
      box1: null,
      box2: null,
    };
    this.getArticleDetail = this.getArticleDetail.bind(this);
  }

  componentDidMount() {
    if (this.props.id) {
      this.getArticleDetail(this.props.id);
    }
  }
  getHtmlParagraph(str) {
    if (str !== "") {
      return str.replace(/<\/?p>/gi, " ").replace(/<p>/gi, " ");
    }
  }

  getArticleDetail(param) {
    const apiData = new Api();
    this.setState({ contentArticle: [] }, () => {
      apiData
        .getArticleDetail(param)
        .then((result) => {
          const articles = result.data;
          const assets = [];
          articles.results.forEach((a, index) => {
            assets.push({
              title: a.article_languages.title,
              content: a.article_languages.content,
              asset: a.article_assets[0].asset_file,
            });
          });

          this.setState({
            contentArticle: assets,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }
  render() {
    let title,
      content = null;

    const { contentArticle, isLoaded, isApiLoaded } = this.state;
    if (isLoaded) {
      contentArticle.map((z) => {
        const splitStr = z.content.split("#");
        title = ReactHtmlParser(
          splitStr[0] ? this.getHtmlParagraph(splitStr[0]) : ""
        );
        content = ReactHtmlParser(
          splitStr[1] ? this.getHtmlParagraph(splitStr[1]) : ""
        );
      });
    } else if (isApiLoaded) {
      title = "Title?";
      content = "Content?";
    }
    return (
      <>
          <p className={classes.text}>{title}</p>
          <div className={classes.insideBox}>
            <p className={classes.text}>{content}</p>
          </div>
      </>
    );
  }
}
