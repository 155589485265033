import React, { Component } from "react";

import classes from "./Option2.module.css";

export default class Option2 extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return <div className={classes.contentImg}>{this.props.src ? <img src={this.props.src} alt="" loading="lazy" /> : <img src={require("../../assets/default-image.svg")} alt="" loading="lazy" />}</div>;
	}
}
