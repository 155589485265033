import React, { Component } from "react";
import Api from "../Api";
import Slider from "react-slick";
import classes from "./Mobile.module.css";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";
import "./Mobile.css";

export default class Baocao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentArticle: {
        widgets: [],
      },
      url: "",
      readMore: "Read More",
      isLoaded: false,
      isApiLoaded: true,
    };
    this.getArticleDetail = this.getArticleDetail.bind(this);
  }
  componentDidMount() {
    if (this.props.idf) {
      this.getArticleDetail(this.props.idf);
      this.setState({
        url: this.props.url,
        readMore: this.props.readMore,
      });
    }
  }

  getArticleDetail(param) {
    const apiData = new Api();
    this.setState({ contentArticle: [] }, () => {
      apiData
        .getArticleDetailByYearDescending(param, null, 12, null)
        .then((result) => {
          const articles = result.data;
          const art = [];
          articles.results.forEach((a) => {
            const assets = [];
            a.article_assets.forEach((ass, i) => {
              assets.push({
                asset: ass.file_small || ass.asset_file || ass.asset_link,
                asset_type: ass.asset_type,
              });
            });

            art.push({
              id: a.id,
              category: a.category_names,
              categoryID: a.categories,
              name: a.name,
              title: a.article_languages.title,
              url_title: a.article_languages.slug_title,
              content: a.article_languages.content,
              assets: assets,
              date: a.date,
            });
          });

          this.setState({
            contentArticle: art,
            isLoaded: true,
          });
        })
        .catch((error) => {
          this.setState({
            isApiLoaded: true,
          });
        });
    });
  }
  render() {
    /*slider settings */
    const settings = {
      className: "",
      infinite: false,
      centerPadding: "",
      variableWidth: true,
      slidesToShow: 1,
      swipeToSlide: true,
    };

    let content;
    if (this.state.isLoaded) {
      content = this.state.contentArticle.map((f, i) => {
        let category_id = f.categoryID ? f.categoryID[0] : "";
        let widget0, widget1;

        if (this.props.id) {
          this.props.id.forEach((ele, i) => {
            if (i === 0) {
              widget0 = ele;
            } else {
              widget1 = ele;
            }
          });
        }

        let ids = f.id;
        let isUrl = this.props.url + "/";
        let isTitle = f.title;
        let strYear = f.date;
        let dateFull = dateFormat(strYear, "dd-mm-yyyy");
        let firstClass = i === 0 ? classes.firstWrap : "";
        return (
          <div key={i} className={[classes.isSlide, firstClass].join(" ")}>
            <div className={classes.allC}>
              <h2 className={classes.isTitle2s}>{isTitle}</h2>
            </div>
            <div className={classes.allD}>
              <div className={classes.allD1}>
                <Link to={`${isUrl}${f.url_title !== "" ? f.url_title : '-'}/${ids}`}>
                  <p className={classes.readT}>
                    <div className={classes.outerCircle}>
                      <div className={classes.innerCircle}></div>
                    </div>
                    {this.props.rd}
                  </p>
                </Link>
              </div>
              <div className={classes.allD2}>
                <p className={classes.isTitle2}>{dateFull}</p>
              </div>
            </div>
          </div>
        );
      });
    }
    return (
      <div id="slider">
        <Slider {...settings}>{content}</Slider>
      </div>
    );
  }
}
