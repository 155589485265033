import React, { Component } from "react"

import { isMobile, isTablet } from "react-device-detect"

import Api from "./Api"
import NavigationBar from "./Navbar/Navbar"
import styled from "styled-components"

import classes from "./Menu.module.css"

const CustomDiv = styled.div`
  position: fixed;
  z-index: 98;
  top: 0;
  right: 0;
`

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentLogo: this.props.logoColor,
      menus: {
        items: [],
      },
      isLoaded: false,

      prevScrollpos: window.pageYOffset,
      visible: true,
      isHeader: 1,
    }

    this.listeners = {}

    this.getMenu = this.getMenu.bind(this)
    this.updateColor = this.updateColor.bind(this)
  }

  componentDidMount() {
    this.getMenu()
    // this.handleScroll(this, this.listeners)
    if (isTablet) {
      this.setState({
        isHeader: 2,
      })
      window.addEventListener("scroll", this.handleScroll)
      // window.addEventListener('scroll', this.listeners.scrolling)
    } else if (isMobile) {
      this.setState({
        isHeader: 3,
      })
      window.addEventListener("scroll", this.handleScroll)
      // window.addEventListener('scroll', this.listeners.scrolling)
    } else {
      this.setState({
        isHeader: 1,
      })
    }
  }

  getMenu() {
    const apiData = new Api()
    this.setState({ menus: [] }, () => {
      apiData
        .getMenuDetail(3)
        .then((result) => {
          const menu = result.data
          const item = []

          menu.items.forEach((i) => {
            item.push({
              index: i.index,
              id: i.id,
              name: i.title,
              url: i.url,
              children: i.children,
            })
          })
          menu.items = item
          this.setState({
            menus: menu,
            isLoaded: true,
          })
        })
        .catch((error) => {
          console.log(error)
          // window.location.assign(`/error/${error.response.status ? error.response.status : 404}`);
          // window.location.assign(`/error/${error ? (error.response.status ? error.response.status : 404) : 404}`);
        })
    })
  }

  componentWillUnmount() {
    if (isTablet || isMobile) {
      window.removeEventListener("scroll", this.handleScroll)
      // window.addEventListener('scroll', this.listeners.scrolling)
    }
  }

  handleScroll = () => {
    const { prevScrollpos } = this.state

    const currentScrollPos = window.pageYOffset
    let visible
    if (currentScrollPos > 0 && prevScrollpos > currentScrollPos) {
      visible = true
    } else if (currentScrollPos === 0){
      visible = true
    } else {
      visible = false
    }
    this.setState({
      prevScrollpos: currentScrollPos,
      visible: visible,
    })
  }

  // handleScroll = (self, listeners) => {
  //   let oldScroll = 0;
  //   function scrolling() {
  //     // print "false" if direction is down and "true" if up
  //     console.log(oldScroll)
  //     console.log(this.pageYOffset)

  //     let isScrollingUp = oldScroll > this.pageYOffset
  //     if (isScrollingUp !== self.state.visible) {
  //       self.setState({ visible: isScrollingUp });
  //     }
  //     oldScroll = this.scrollY;
  //   }
  //   listeners.scrolling = scrolling
  //   return scrolling
  // }

  updateColor() {
    //console.log("UpdateState - " + this.props.logoColor)
    this.setState({
      currentLogo: this.props.logoColor,
    })
  }

  render() {
    const { menus } = this.state
    let dataSection,
      Navbar = null

    if (this.state.currentLogo !== this.props.logoColor) {
      this.updateColor()
    }

    if (this.state.isLoaded) {
      dataSection = menus
      Navbar = <NavigationBar onPage={this.props.onPage} items={dataSection} logoColor={this.state.currentLogo} />
    } else {
      Navbar = <NavigationBar onPage={this.props.onPage} items={this.state.isLoaded} logoColor={this.state.currentLogo} />
    }

    let hidden
    if (isTablet) {
      hidden = !this.state.visible ? classes.navbarHidden : ""
    } else if (isMobile) {
      hidden = !this.state.visible ? classes.navbarHidden : ""
    } else {
      hidden = ""
    }

    return <CustomDiv className={[classes.customMenu, hidden].join(" ")}>{Navbar}</CustomDiv>
  }
}

export default Header
