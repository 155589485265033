import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import UserContext from "../../../Context";
import Api from "../../../../Api";

import classes from "./Modal.module.css";
export default class ModalLinks extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      file: [],
      link: [],
      linkActive: 0,
      isAttachment: false,
      isLink: false,
      errorLink: "null",
      acceptPDF: "",
      cancelText: "",
      saveText: "",
      data: [],
    };
    this.uploadPortofolio = this.uploadPortofolio.bind(this);
    this.resetFile = this.resetFile.bind(this);
    this.addMoreLink = this.addMoreLink.bind(this);
  }

  componentDidMount() {
    const contextValue = this.context;

    this.setState({
      id: contextValue.idApplicant,
      file: [],
      link: [],
      data: contextValue.contentSection[11].sections.widget_contents,
    });
  }

  isValidate(links) {
    if (this.state.file.length > 0 || links.length > 0) {
      this.setState({
        isAttachment: false,
      });
      return true;
    } else {
      this.setState({
        isAttachment: true,
      });
      return false;
    }
  }

  uploadPortofolio() {
    const resume = new Api();
    const contextValue = this.context;
    let allLink = document.getElementsByName("link");
    let links = [];
    for (let index = 0; index < allLink.length; index++) {
      if (allLink[index].value !== "") {
        links.push(allLink[index].value);
      }
    }

    if (this.isValidate(links)) {
      resume
        .uploadPortofolio(this.state.id, this.state.file, links)
        .then((res) => {
          this.props.close();
          contextValue.getNewDataApplicant();
          contextValue.showNotification(
            "Your portofolio has been successfully added."
          );
        })
        .catch((err) => {
          if (err.response.data) {
            err.response.data.forEach((ele, index) => {
              if (ele.attachment_link) {
                this.setState({
                  isLink: true,
                  errorLink: "Please enter a valid URL.",
                });
              }
            });
          }
        });
    }
  }

  handleChangeFile(e) {
    for (let index = 0; index < e.target.files.length; index++) {
      let newFile = e.target.files[index];
      this.setState((prevState) => {
        const file = prevState.file.concat(newFile);
        return { file };
      });
    }
  }

  removeFileUploader(i) {
    this.setState((state) => {
      const file = state.file.filter((item, j) => i !== j);
      return {
        file,
      };
    });
  }

  resetFile() {
    this.setState({
      file: [],
    });
  }

  addMoreLink() {
    this.setState((prevState) => {
      const link = prevState.linkActive + 1;
      // const aLink = prevState.linkActive.concat(link + 1)

      return {
        linkActive: link,
      };
    });
  }

  render() {
    let title, optional, upload, info, cancel, save;
    this.state.data.forEach((x, i) => {
      switch (i) {
        case 1:
          info = x.content;
          break;
        case 2:
          optional = x.content;
          break;
        case 3:
          upload = x.content;
          break;
        case 13:
          title = x.content;
          break;
        case 19:
          cancel = x.content;
          break;
        case 20:
          save = x.content;
          break;
        default:
      }
    });
    const { file } = this.state;

    let contentUpload, appendLink;
    if (file) {
      contentUpload = file.map((f, i) => {
        return (
          <div className={classes.boxFile}>
            <span>{f.name}</span>
            <FontAwesomeIcon
              color="#000000"
              className={classes.iconClose}
              icon={faTimes}
              size="md"
              onClick={() => this.removeFileUploader(i)}
            />
          </div>
        );
      });
    }

    let links = [];
    for (let index = 1; index <= this.state.linkActive; index++) {
      links.push(index);
    }

    appendLink = links.map((l, i) => {
      return (
        <div className={classes.boxLink}>
          <input type={"text"} name="link" />
        </div>
      );
    });

    let isError = this.state.isAttachment ? classes.errorVisible : "";
    let isError1 = this.state.isLink ? classes.errorVisible2 : "";

    return (
      <Modal
        size={"md"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.show}
        onHide={() => {
          this.props.close();
          this.resetFile();
        }}
      >
        <Modal.Header
          className={classes.modalHeader}
          onClick={this.props.close}
          closeButton
        >
          <div className="d-flex justify-content-between">
            <span className={classes.modalTitle}>{title}</span>
            <span className={classes.option}>({optional})</span>
          </div>
        </Modal.Header>

        <UserContext.Consumer>
          {(context) => (
            <div className={classes.modalContent}>
              <div className={classes.contentPortofolio}>
                <span className={classes.titleContent}>Link</span>
                <div className={classes.contentLink}>
                  <div className={classes.boxLink}>
                    <input type={"text"} name="link" />
                    <div
                      className={classes.circleLink}
                      onClick={this.addMoreLink}
                    >
                      <img src={require("../../assets/add.svg")} alt="" />
                    </div>
                  </div>
                  {appendLink}
                </div>
                <span className={[classes.contentError, isError1].join(" ")}>
                  {this.state.errorLink}
                </span>

                <span className={classes.titleContent}>File</span>
                <div className={classes.contentFile}>
                  <div className={classes.contentUpload}>{contentUpload}</div>

                  <input
                    type="file"
                    id="btn-upload"
                    onChange={(e) => this.handleChangeFile(e)}
                    multiple
                    hidden
                  />
                  <label htmlFor="btn-upload" className={classes.btnFullAction}>
                    <div className={classes.contentBtn}>
                      <div className={classes.circle}>
                        <img src={require("../../assets/add.svg")} alt="" />
                      </div>
                      <span>{upload}</span>
                    </div>
                  </label>
                  <span className={classes.note}>{info}</span>
                </div>
                <span
                  className={[classes.contentError, isError, "mb-3"].join(" ")}
                >
                  Please at least add one file/link.
                </span>
              </div>

              <div className={classes.modalAction}>
                <div className={classes.cancel} onClick={this.props.close}>
                  <span>{cancel}</span>
                </div>
                <div
                  className={classes.save}
                  onClick={() => {
                    this.uploadPortofolio();
                  }}
                >
                  <span>{save}</span>
                </div>
              </div>
            </div>
          )}
        </UserContext.Consumer>
      </Modal>
    );
  }
}
