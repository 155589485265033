import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Api from "../Api";
import Views from '../Views/Views';
import classes from '../GS_Section1.module.css'

export default class GS_Section1 extends Component {
    constructor() {
        super();
        this.state = {
            currentPage: null,

            tabs: [
                {
                    id: 1,
                    title: 'All news & Events',
                    component: <div key={1} />,
                    logo: <img
                        className={classes.button_tab}
                        src={require('../assets/Polygon7.svg')}
                        alt="description" />
                }
            ],
            sections: {
                widgets: []
            },
            isLoaded: false,
            article_categories: [],
            isApiLoaded: false,
            views: null,
            activePage: <div></div>,
            valueQuery: '',
            articleID: null,
            sectionName: "",
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
        this.getAllCategories = this.getAllCategories.bind(this)
    }

    componentDidMount() {
        this.getSectionDetail();
        this.getAllCategories();

        if (this.props.param) {
            this.setState({
                valueQuery: this.props.param
            })
        }
    }

    getAllCategories() {

        const apiData = new Api()

        this.setState({
            article_Categories: [],
        }, () => {

            apiData.getAllCategories().then(result => {
                const values = result.data
                const articleCategories = []
                values.results.forEach((item) => {
                    articleCategories.push(
                        {
                            'id': item.id,
                            'category': item.category
                        }
                    )
                })

                this.setState({
                    article_categories: articleCategories,
                })
            })

        })

    }

    getSectionDetail() {
        const apiData = new Api();
        this.setState({
            sections: []
        }, () => {
            apiData
                .getSectionDetail()
                .then(result => {
                    this.setState({ isApiLoaded: true })
                    const sections = result.data

                    const widget = []
                    result
                        .data
                        .widgets
                        .forEach((item) => {
                            widget.push({ id: item.id, index: item.index, category: item.category, name: item.name, sections: item })
                        })

                    sections.page = widget
                    this.setState({
                        sectionName: result.data.name,
                        sections: widget,
                        isLoaded: true,
                        articleID: widget[0].id
                    })
                })

                .catch(error => {
                    this.setState({ isApiLoaded: true })
                });
        })
    }

    render() {
        const { sections } = this.state
        let placeholderSearch, textFilter, TextFound, TextError, LoadMore, ReadMore
        let allUrl = []

        let viewOnChange = null;

        if (this.state.isLoaded) {
            sections.forEach(item => {
                switch (item.index) {
                    case 1:
                        placeholderSearch = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "type article name, news, events, blog";
                        break;
                    case 2:
                        textFilter = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "All";
                        break;
                    case 3:
                        TextFound = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "results found";
                        break;
                    case 4:
                        TextError = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "";
                        break;
                    case 5:
                        LoadMore = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Load more";
                        break;
                    case 6:
                        ReadMore = item.sections.widget_contents[0] ? item.sections.widget_contents[0].content : "Load more";
                        break;
                    case 7:
                        allUrl.push(item.sections.url)
                        break;
                    case 8:
                        allUrl.push(item.sections.url)
                        break;
                    case 9:
                        allUrl.push(item.sections.url)
                        break;
                    case 10:
                        allUrl.push(item.sections.url)
                        break;
                    case 11:
                        allUrl.push(item.sections.url)
                        break;
                    default:
                }
            })
            viewOnChange = <Views view={"grid"} category={this.state.article_categories} title={this.state.sectionName} searchHere={this.props.param} textSearch={placeholderSearch} textFilter={textFilter} textFound={TextFound} textError={TextError} loadMore={LoadMore} readMore={ReadMore} allUrl={allUrl} />

        } else if (this.state.isApiLoaded) {
            
        }

        return (
            <div className={classes.contentSection}>
                <div className={['container', classes.customContainer].join(' ')}>
                    {viewOnChange}
                </div>
            </div>
        )
    }
}
