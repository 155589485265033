import React from 'react'
import Sliders from "react-slick";
import { isMobile, isTablet } from "react-device-detect";

import classes from '../Section5.module.css'

export default function Slider(props) {
    const Action = () => {
        let slideToShow = isMobile ? 1 : 1
        return {
            className: "",
            infinite: false,
            centerPadding: "",
            variableWidth: true,
            slidesToShow: slideToShow,
            swipeToSlide: true,
        }
    }

    let settings
    if (isTablet) {
        settings = Action(props.setting)
    } else if (isMobile){
        settings = {
            // dots: true,
            centerMode: false,
            infinite: false,
            centerPadding: "",
            variableWidth: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500,
            swipe: true,
            appendDots: dots => (
                <div className={classes.customDots}>
                    <ul style={{ margin: "0px" }}> {dots} </ul>
                </div>
            ),
            customPaging: i => (
                <div className={classes.customPaggination} >
                </div>
            )
        };
    } else {
        settings = Action(props.setting)
    }
    return (
        <Sliders {...settings}>
            {props.content}
        </Sliders>
    )
}
