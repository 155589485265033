import React from "react";
import Desktop from "./Desktop/Desktop";
import Mobile from "./Mobile/Mobile";
import { isMobile, isTablet } from "react-device-detect";
import { useMediaQuery } from "react-responsive";

export default function SD_Section4() {
  let content;

  const isM = useMediaQuery({
    query: "(min-device-width: 411px) and (max-device-width: 479px)",
  });

  let orientation = (window.screen.orientation || {}).type ||window.screen.mozOrientation || window.screen.msOrientation;

  if (isTablet) {
    content = <Desktop />;
  } else if (isMobile || isM) {
    content = <Mobile />;
  } else if ((isMobile & window.screen.width <= 900 && orientation === 'landscape-primary')){
    content = <Mobile />;
  } else {
    content = <Desktop />;
  }


  return <>{content}</>;
}
