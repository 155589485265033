import React, { Component } from "react";
import { Link } from "react-router-dom";

import Api from "../Api";
import classes from "./Section3.module.css";

import CustomAccordion from "../components/Accordion/AccordionMobile";
import BlogView from "../components/BlogView/BlogViewMobile";
import Button from "../components/Button/Button";

export default class Section3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentSection: [],
            contentArticle: {},
            dataUrl: null,
            dataIndex: null,
            isLoaded: false,
            isApiLoaded: false,
            isArticleLoaded: {},
        };
        this.getSectionDetail = this.getSectionDetail.bind(this);
        this.getArticleDetailPagination =
            this.getArticleDetailPagination.bind(this);
    }

    componentDidMount() {
        this.getSectionDetail();
    }

    getSectionDetail() {
        const apiData = new Api();
        apiData
            .getSectionDetail()
            .then((result) => {
                const sections = result.data;
                const widget = [];
                const dataParamApi = [];

                dataParamApi.push({
                    url: sections.widgets[1].url,
                    index: sections.widgets[1].widget_contents[0].id,
                });

                result.data.widgets.forEach((item) => {
                    widget.push({
                        id: item.id,
                        index: item.index,
                        category: item.category,
                        is_internal: item.is_internal,
                        name: item.name,
                        sections: item,
                    });
                });

                sections.page = widget;
                this.setState({
                    contentSection: widget,
                    isLoaded: true,
                    dataIndex: dataParamApi[0].index,
                    dataUrl: dataParamApi[0].url,
                });
            })
            .catch((error) => {
                this.setState({
                    isApiLoaded: true,
                });
            });
    }

    getArticleDetailPagination(param, size, page) {
        const apiData = new Api();
        apiData
            .getArticleDetailPagination(param, size, page)
            .then((result) => {
                const articles = result.data;
                const art = [];
                articles.results.forEach((a) => {
                    const assets = [];
                    a.article_assets.forEach((ass, i) => {
                        if (i === 0) {
                            assets.push({
                                asset: ass.file_medium,
                            });
                        }
                    });

                    art.push({
                        id: a.id,
                        category: a.category_name,
                        name: a.name,
                        author_name: a.author_name,
                        author_avatar: a.author_avatar,
                        title: a.article_languages.title,
                        summary: a.article_languages.summary,
                        content: a.article_languages.content,
                        assets: assets,
                        date: a.date,
                    });
                });

                let isArticleLoaded = { ...this.state.isArticleLoaded };
                isArticleLoaded[param] = true;

                let contentArticle = { ...this.state.contentArticle };
                contentArticle[param] = art;

                this.setState({
                    contentArticle: contentArticle,
                    isArticleLoaded: isArticleLoaded,
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    isApiLoaded: true,
                });
            });
    }

    render() {
        const { contentSection } = this.state;
        let title,
            article,
            title2,
            url,
            blogComponent,
            readMore,
            readMoreLink,
            sidebarText,
            viewAllBtn;

        if (this.state.isLoaded) {
            let count = 0,
                widget = {},
                readMoreBlog = {};

            contentSection.forEach((item) => {
                count = count + 1;

                if (
                    count === 1 &&
                    item.index === 1 &&
                    item.category === "Text"
                ) {
                    item.sections.widget_contents.forEach((s) => {
                        if (s.content !== "") {
                            title = (
                                <h2 className={classes.title}>{s.content}</h2>
                            );
                        }
                    });
                }

                if (
                    count === 2 &&
                    item.index === 2 &&
                    item.category === "Text"
                ) {
                    const url = item.sections.url;
                    item.sections.widget_contents.forEach((s) => {
                        if (s.content !== "") {
                            readMore = s.content;
                            readMoreLink = url;
                        } else {
                            readMore = "Read More";
                        }
                    });
                }

                if (
                    count === 3 &&
                    item.index === 3 &&
                    item.category === "Article"
                ) {
                    url = item.sections.url;
                    const id = item.id;
                    const articles = this.state.contentArticle[id];

                    if (!this.state.isArticleLoaded[id]) {
                        this.getArticleDetailPagination(id, 5);
                    }

                    if (articles) {
                        article = articles.map((s) => {
                            return {
                                ...s,
                                url: item.sections.url,
                            };
                        });
                    }
                }
            });
        }

        return (
            <div className={classes.contentSection}>
                <div className={["container", classes.boxC].join(" ")}>
                    {title}
                    <CustomAccordion
                        articles={article}
                        readMore={readMore}
                        readMoreLink={readMoreLink}
                    />
                </div>
            </div>
        );
    }
}
