import React, { Component } from "react";
import HorizontalScroll from "react-scroll-horizontal";
import dateFormat from "dateformat";
import ReactHtmlParser from "react-html-parser";
import { prominent } from "color.js";
import Api from "./Api";
import classes from "./History.module.css";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

class historysample extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dummy: [
        {
          id: 2,
          name: "Articles",
          articles: [
            {
              name: "Articles1",
              title: "",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
            {
              name: "Articles2",
              title: "",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
            {
              name: "Articles3",
              title: "",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
            {
              name: "Articles4",
              title: "",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
            {
              name: "Articles4",
              title: "",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
            {
              name: "Articles4",
              title: "",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
            {
              name: "Articles4",
              title: "",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
            {
              name: "Articles4",
              title: "",
              year: "2020",
              content:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book",
              assets: require("./asset/default-image.svg"),
            },
          ],
        },
      ],
      content: <div></div>,
      contentDetail: false,
      currentPage: null,
      contentArticle: {
        widgets: [],
      },
      contentArticle1: {
        widgets: [],
      },
      isLoaded: false,
      isApiLoaded: false,
      indexYear: false,
      disabledNext: true,
      disabledPrev: true,
      currentIndex: 0,
      currentArt: [],
      urlBack: null,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
    this.getArticleGroupbyYear = this.getArticleGroupbyYear.bind(this);
    this.toggleHandlerEnter = this.toggleHandlerEnter.bind(this);
    this.toggleNext = this.toggleNext.bind(this);
    this.togglePrev = this.togglePrev.bind(this);
    this.toggleNextButton = this.toggleNextButton.bind(this);
    this.togglePrevButton = this.togglePrevButton.bind(this);
  }

  toggleHandlerBack = () => {
    this.setState({
      contentDetail: false,
    });
  };

  toggleHandlerEnter = (i) => {
    let contentArt
    this.state.contentArticle.forEach((ele, index) => {
      if (i === index) {
        contentArt = ([...ele.art].reverse())
        if (contentArt[0].article_assets[0].set_logo_opacity) {
          this.props.updateLogo(contentArt[0].article_assets[0].logo_opacity === 'Dark' ? false : true);
        } else {
          prominent(contentArt[0].article_assets[0].asset_file, {
            format: "hex",
            amount: 10,
            group: 20,
            sample: 10,
          }).then((color) => setTimeout(this.lightOrDark(color[3]), 1000));
        }
      }
    })

    this.setState({
      indexYear: i + 1,
      contentDetail: true,
      currentIndex: 0,
      currentArt: contentArt
    });

    this.togglePrevButton(true);
    if (this.state.contentArticle[i].art.length === 1) {
      this.toggleNextButton(true);
    } else {
      this.toggleNextButton(false);
    }

  };



  toggleExit = () => {
    this.setState({
      indexYear: false,
      currentIndex: 0,
    });
    this.toggleNextButton(true);
  };

  changeHighlight(key) {
    this.setState({ activeYear: key });
  }

  changeBox = () => {
    this.setState({
      content: <div></div>,
    });
  };

  componentDidMount() {
    let id = this.props.id;

    this.getSectionDetail();
    if (id !== "dummy") {
      this.getArticleGroupbyYear(id);
    } else {
      this.setState({
        isApiLoaded: true,
      });
    }
  }

  toggleNextButton(onOrOff) {
    this.setState({
      disabledNext: onOrOff,
    });
  }

  togglePrevButton(onOrOff) {
    this.setState({
      disabledPrev: onOrOff,
    });
  }

  togglePrev() {
    var k = this.state.currentIndex;

    if (k > 0) {
      this.setState(
        {
          currentIndex: k - 1,
        },
        () => {
          if (k - 1 === 0) {
            this.togglePrevButton(true);
          }
          if (
            k <
            this.state.contentArticle[this.state.indexYear - 1].art.length &&
            this.state.contentArticle[this.state.indexYear - 1].art.length > 1
          ) {
            this.toggleNextButton(false);
          }
        }
      );
    }

    let art = this.state.currentArt

    if (art[k - 1].article_assets[0].set_logo_opacity) {
      this.props.updateLogo(art[k - 1].article_assets[0].logo_opacity === 'Dark' ? false : true);
    } else {
      prominent(art[k - 1].article_assets[0].asset_file, {
        format: "hex",
        amount: 10,
        group: 20,
        sample: 10,
      }).then((color) => this.lightOrDark(color[3]));
    }
  }

  toggleNext() {
    var k = this.state.currentIndex;
    if (k + 2 === this.state.contentArticle[this.state.indexYear - 1].art.length) {
      this.toggleNextButton(true);
    }

    if (k + 1 < this.state.contentArticle[this.state.indexYear - 1].art.length) {
      this.setState({
        currentIndex: k + 1,
      });
      this.togglePrevButton(false);
    }

    let art = this.state.currentArt
    if (art[k + 1].article_assets[0].set_logo_opacity) {
      this.props.updateLogo(art[k + 1].article_assets[0].logo_opacity === 'Dark' ? false : true);
    } else {
      prominent(art[k + 1].article_assets[0].asset_file, {
        format: "hex",
        amount: 10,
        group: 20,
        sample: 10,
      }).then((color) => this.lightOrDark(color[3]));
    }

  }

  getSectionDetail() {
    const apiData = new Api();
    this.setState(
      {
        sections: [],
      },
      () => {
        apiData
          .getSectionDetail()
          .then((result) => {
            let url;
            result.data.widgets.forEach((ele, index) => {
              switch (index) {
                case 0:
                  url = ele.url;
                  break;
                case 1:
                  break;
                default:
              }
            });

            this.setState({
              urlBack: url,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  }

  getArticleGroupbyYear(param) {
    const apiData = new Api();
    this.setState(
      {
        sections: [],
      },
      () => {
        apiData
          .getArticleGroupbyYear(param)
          .then((result) => {
            this.setState({
              isApiLoaded: true,
            });
            const widget = [];
            const years = [];
            result.data.forEach((element) => {
              years.push(element.year);

              widget.push({
                year: element.year,
                art: element.articles,
              });
            });

            this.setState(
              {
                contentArticle: widget,
                contentArticle1: widget,
                years: years,
                isLoaded: true,
              },
              () => { }
            );
          })
          .catch((error) => {
            this.setState({
              isApiLoaded: true,
            });
          });
      }
    );
  }

  lightOrDark(color) {
    let r, g, b, hsp;
    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(
        /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
      );

      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +(
        "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
      );

      r = color >> 16;
      g = (color >> 8) & 255;
      b = color & 255;
    }

    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using the HSP value, determine whether the color is light or dark
    const darkSection = this.props.darkSection;
    if (hsp > 127.5) {
      if (darkSection) {
        this.props.updateLogo(false);
      }
    } else {
      if (!darkSection) {
        this.props.updateLogo(true);
      }
    }
  }

  render() {
    const { contentArticle, isLoaded, dummy } = this.state;

    let year,
      date,
      content,
      titleContent,
      contentSample,
      isImage,
      title,
      img,
      boxYear,
      activeImg,
      buttonNext,
      buttonNext1,
      buttonNext2,
      buttonPrev = null;
    // let prevLink = localStorage.getItem("prevLink");

    if (isLoaded) {
      boxYear = this.state.years.map((item, index) => {
        let textZoom = null;
        if (this.state.indexYear - 1 === index) {
          textZoom = classes.textZoom;
          // highlight = classes.highlight;
        }
        return (
          <div
            className={[classes.box_main, textZoom].join(" ")}
            onClick={() => this.toggleHandlerEnter(index)}
          >
            <div className={classes._flexD}>
              <p className={classes.box_year}>{item}</p>
            </div>
          </div>
        );
      });

      contentSample = contentArticle.map((ele, index) => {
        var arrayLength = ele.art.length;
        let nextButton = null;
        if (arrayLength === 1) {
          buttonNext = buttonNext2;
        } else {
          nextButton = classes.disableNext;
          buttonNext = buttonNext1;
        }

        buttonNext1 = (
          <div onClick={this.toggleNext}>
            <img
              className={classes.arrowRight}
              src={require("./asset/right.svg")}
              alt=""
            />
          </div>
        );
        buttonNext2 = (
          <div onClick={this.toggleNext}>
            <img
              className={[classes.arrowRight, nextButton].join(" ")}
              src={require("./asset/rightorg.svg")}
              alt=""
            />
          </div>
        );
        buttonPrev = (
          <div onClick={this.togglePrev}>
            <img
              className={classes.arrowLeft}
              src={require("./asset/leftorg.svg")}
              alt=""
            />
          </div>
        );
        [...ele.art].reverse().forEach((dat, i) => {
          activeImg = this.state.indexYear
            ? this.state.indexYear - 1 === index
              ? classes.activeImg
              : classes.inActiveImg
            : "";

          if (this.state.currentIndex >= 0 && this.state.currentIndex === i) {
            date = dat.date;
            content = dat.article_languages.content;
            title = dat.article_languages.title;
            year = dateFormat(date, "yyyy");
            titleContent = title;
            isImage = dat.article_assets[0] ? (
              <img
                className={classes.image_box}
                src={dat.article_assets[0].file_small}
                alt={dat.article_assets[0].description}
              />
            ) : (
                <img
                  className={classes.image_box_default}
                  src={require("./asset/default-image.svg")}
                  alt=""
                />
              );
          }
        });

        let customStyle = !this.state.indexYear
          ? classes.colorbox
          : classes.color1;

        return (
          <>
            <div
              className={[classes.box, activeImg].join(" ")}
              onClick={() => this.toggleHandlerEnter(index)}
            >
              {this.state.indexYear - 1 === index ? (
                <div className={classes.p_mid}>
                  <p className={classes.text_fake}>{year}</p>
                  <h1 className={classes.title_mid}>{titleContent}</h1>
                  <p className={classes.p_text}>{ReactHtmlParser(content)}</p>
                </div>
              ) : null}
              <div className={customStyle}>
                <h1 className={classes.title_mid2}>{titleContent}</h1>
                <img
                  className={classes.btn_hover}
                  src={require("./asset/plus.svg")}
                  alt=""
                />
              </div>
              <figure className={classes.figureimg}>{isImage}</figure>
            </div>
          </>
        );
      });
    } else if (this.state.isApiLoaded) {
      dummy.forEach((s) => {
        contentSample = s.articles.forEach((ele) => {
          let customStyle = classes.colorbox;
          if (s.id === 2) {
            year = <p>{ele.year}</p>;
            titleContent = <p>{ele.title}</p>;

            isImage = (
              <img className={classes.image_box} src={ele.assets} alt="" />
            );
            
            return (
              <>
                <div className={[classes.box, activeImg].join(" ")}>
                  <div className={classes.p_mid}>
                    <p className={classes.text_fake}></p>
                    <span className={classes.title_mid}></span>
                    <p className={classes.p_text}></p>
                  </div>
                  <div className={customStyle}>
                    <span className={classes.title_mid2}></span>
                    <img
                      className={classes.btn_hover}
                      src={require("./asset/plus.svg")}
                      alt=""
                    />
                  </div>
                  <figure className={classes.figureimg}>{isImage}</figure>
                </div>
              </>
            );
          }
        });
      });
    }

    let activeBtn = this.state.indexYear ? classes.activeMinus : "";
    let activeNav = this.state.indexYear ? classes.activeNav : "";

    let renderButton = (
      <div className={[classes.toggleNav, activeNav].join(" ")}>
        {this.state.disabledPrev ? null : buttonPrev}
        {this.state.disabledNext ? null : buttonNext}
      </div>
    );

    return (
      <div>
        <div className={classes.wrapp}>
          <HorizontalScroll
            reverseScroll={true}
            config={{ stiffness: 160, damping: 110 }}
          >
            {contentSample}
          </HorizontalScroll>
          <img
            onClick={this.toggleExit}
            className={[classes.toggleHide, activeBtn].join(" ")}
            src={require("./asset/minus.svg")}
            alt=""
          />

          <div className={classes.navBack}>
            <a href={this.state.urlBack} className={classes.backLink}>
              {/* <FontAwesomeIcon
                className={classes.backIcon}
                icon={faArrowLeft}
              />
              <div>{this.props.backBtn}</div> */}
              <div className={"d-flex"}>
                <i
                  className={[
                    classes.arrow,
                    classes.left,
                    classes.customMargin,
                  ].join(" ")}
                ></i>
              </div>
              <div className={classes.addMargin}>{this.props.backBtn}</div>
            </a>

            <div className={classes._boxOne}>{boxYear}</div>
          </div>

          {renderButton}

          {this.state.content}
        </div>
      </div>
    );
  }
}

export default historysample;
