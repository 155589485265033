import React from "react";
import { isMobile } from "react-device-detect";
import Desktop from "./Desktop";
import Mobile from "./Mobile";
import { useMediaQuery } from "react-responsive";

export default function MegaMenuItems(props) {
  const isM = useMediaQuery({
    query: "(min-device-width: 411px) and (max-device-width: 479px)",
  });

  let content;

  if (isMobile || isM) {
    content = <Mobile items={props.items} />;
  } else {
    content = <Desktop items={props.items} />;
  }

  

  return <>{content}</>;
}
