import React, { Component } from 'react'
import ReactHtmlParser from "react-html-parser";

import classes from "./Option3.module.css";
import "./Option3.css"

export default class Option3 extends Component {
    constructor(props) {
		super(props);
		this.state = {};
	}
    render() {
        
        return (
            <div id="advanced-article">
                <div className="container">
                    {ReactHtmlParser(this.props.content)}
                </div>
            </div>
        )
    }
}
