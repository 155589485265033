import React, { Component, createRef } from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom'
import {Helmet} from 'react-helmet';
import SectionList from './SectionList';
import { ParallaxProvider } from 'react-scroll-parallax';
import { isMobile  } from 'react-device-detect';
import LOGO_685 from '../../sections/Logo_685/Logo';
import MENU_686 from '../../sections/Menu_686/Menu';
import CC_BANNER_687 from '../../sections/CC_Banner_687/CC_Banner';
import CC_SECTION1_688 from '../../sections/CC_Section1_688/CC_Section1';
import CC_SECTION2_689 from '../../sections/CC_Section2_689/CC_Section2';
import CC_SECTION3_690 from '../../sections/CC_Section3_690/CC_Section3';
import CC_SECTION4_691 from '../../sections/CC_Section4_691/CC_Section4';
import FOOTER_692 from '../../sections/Footer_692/Footer';
import SectionShortcut from './SectionShortcut/SectionShortcut';
import HomeSectionShortcut from './SectionShortcut/HomeSectionShortcut';
import styled from 'styled-components';

const MainClass = styled.div`
     min-height: 100vh;
     background: white;
     position: relative;
     z-index: 1;
     box-shadow: 0px 3px 6px #00000029;
`;

class project1 extends Component {
   constructor(props) {
       super(props);
       this.state = {
           currentPage: null,
           currentSection: null,
           override: true,
           darkSection: true,
          section: [
               { id: 1, title: localStorage.getItem('language') === "English"? 'Banner' : 'Ảnh Bìa', slug: localStorage.getItem('language') === "English"? 'banner' : 'anh-bia', has_shortcut: true},
               { id: 2, title: localStorage.getItem('language') === "English"? 'Core Value' : 'Giá Trị Cốt Lõi', slug: localStorage.getItem('language') === "English"? 'core-value' : 'gia-tri-cot-loi', has_shortcut: true},
               { id: 3, title: localStorage.getItem('language') === "English"? 'Characteristic Culture' : 'Nét Đặc Trưng Về Vhdn', slug: localStorage.getItem('language') === "English"? 'characteristic-culture' : 'net-dac-trung-ve-vhdn', has_shortcut: true},
               { id: 4, title: localStorage.getItem('language') === "English"? 'Stakeholders' : 'Các Bên Liên Quan', slug: localStorage.getItem('language') === "English"? 'stakeholders' : 'cac-ben-lien-quan', has_shortcut: true},
               { id: 5, title: localStorage.getItem('language') === "English"? 'Three Focuses' : '3 Trong Tâm Vndn', slug: localStorage.getItem('language') === "English"? 'three-focuses' : '3-trong-tam-vndn', has_shortcut: true},
          ],
       activeSection: 'Section1',
       };
       this.updateLogo = this.updateLogo.bind(this)
       this.override1 = this.override1.bind(this)
       this.override2 = this.override2.bind(this)
       this.override3 = this.override3.bind(this)
       this.override4 = this.override4.bind(this)
       this.override5 = this.override5.bind(this)
        this.scrollToSection = this.scrollToSection.bind(this)
        this.scrollingHandler = this.scrollingHandler.bind(this)
        this.isStateChanging = false
   }
   componentDidMount() {
       ReactGA.initialize('G-R7TGZTN26B')
       ReactGA.pageview(window.location.pathname + window.location.search);
        window.addEventListener('scroll', this.scrollingHandler, true);
          this.scrollToSection()
   }
   componentWillUnmount(){
       window.removeEventListener('scroll', this.scrollingHandler, true);
   }
     section1 = createRef()
     section2 = createRef()
     section3 = createRef()
     section4 = createRef()
     section5 = createRef()
   updateLogo(value) {
       this.setState({
           darkSection: value,
       })
   }
   override1(value) {
       this.setState({
       override: value,
       })
   }

   override2(value) {
       this.setState({
       override: value,
       })
   }

   override3(value) {
       this.setState({
       override: value,
       })
   }

   override4(value) {
       this.setState({
       override: value,
       })
   }

   override5(value) {
       this.setState({
       override: value,
       })
   }

     scrolltoSectionHandler = (key) => {
       if (key === 'section1') {
           this.section1.current.scrollIntoView({'behavior': 'smooth'});
       } else if (key === 'section2') {
           this.section2.current.scrollIntoView({'behavior': 'smooth'});
       } else if (key === 'section3') {
           this.section3.current.scrollIntoView({'behavior': 'smooth'});
       } else if (key === 'section4') {
           this.section4.current.scrollIntoView({'behavior': 'smooth'});
       } else if (key === 'section5') {
           this.section5.current.scrollIntoView({'behavior': 'smooth'});
      }
   };
    scrollingHandler() {
        const sectionsName = ['section1', 'section2', 'section3', 'section4', 'section5'];
        const isSectionsDarkLogo = [
       {
           name: 'Section1',
           darkSection: true
       },
       {
           name: 'Section2',
           darkSection: false
       },
       {
           name: 'Section3',
           darkSection: false
       },
       {
           name: 'Section4',
           darkSection: false
       },
       {
           name: 'Section5',
           darkSection: false
       },
        ]
       isSectionsDarkLogo.forEach((sec, i) => {
           let section = this[sectionsName[i]]?.current?.getBoundingClientRect();
           if (section && section.bottom < section.height && section.bottom > -1 && !this.isStateChanging) {
               this.setState({
                   activeSection: sec.name
               })
               if (this.state.darkSection !== sec.darkSection && this.state.currentSection !== sec.name) {
                   this.isStateChanging = true
                   this.setState({
                       currentSection: sec.name,
                       darkSection: sec.darkSection,
                   }, () => {
                       this.isStateChanging = false
                   })
               }
           }
       })
    }
   scrollToSection() {
       let currentLocation = window.location.href
       const hasSectionAnchor = currentLocation.includes('/#')
       const hasTabAnchor = currentLocation.includes('*')
       if (hasSectionAnchor) {
           let anchorSectionId = `${currentLocation.substring(currentLocation.indexOf('#') + 1)}`
           if (hasTabAnchor) {
                const tabSectionId = `${anchorSectionId.substring(anchorSectionId.indexOf('*') + 1)}`
                anchorSectionId = anchorSectionId.substring(0, anchorSectionId.length - 2)
                setTimeout(() => { this.scrollAction(anchorSectionId) }, 1000)
                this.setState({ tabsActive: tabSectionId })
            } else {
                setTimeout(() => { this.scrollAction(anchorSectionId) }, 1000)
            }
        } else {
           setTimeout(() => {window.scrollTo(0, 0)}, 1000)
           this.setState({
               activeSection: 'Section1',
               currentSection: null,
           })
        }
   }
    scrollAction(anchorSectionId) {
       const uri_dec = decodeURIComponent(anchorSectionId)
       const key = this.state.section.filter(x => x.slug == uri_dec).map(obj => `section${obj.id}`)
       if (key) {
                if (key[0] === 'section1') {
                    setTimeout(() => {this.section1.current.scrollIntoView({'behavior': 'smooth'})}, 1000)
                } else if (key[0] === 'section2') {
                    setTimeout(() => {this.section2.current.scrollIntoView({'behavior': 'smooth'})}, 1000)
                } else if (key[0] === 'section3') {
                    setTimeout(() => {this.section3.current.scrollIntoView({'behavior': 'smooth'})}, 1000)
                } else if (key[0] === 'section4') {
                    setTimeout(() => {this.section4.current.scrollIntoView({'behavior': 'smooth'})}, 1000)
                } else if (key[0] === 'section5') {
                    setTimeout(() => {this.section5.current.scrollIntoView({'behavior': 'smooth'})}, 1000)
                }
       }
   }
   render() {
       let AllSections = [];
       if (this.state.section) {
           this.state.section.map((s, i) => {
               let section;
               switch (i) {
                   case 0:
                       section = (
                           <div ref={this.section1}>
                               <CC_BANNER_687
                                   updateLogo={this.updateLogo}
                                   override={this.override1}
                                   darkSection={this.state.darkSection}
                               />
                           </div>
                       );
                       break;
                   case 1:
                       section = (
                           <div ref={this.section2}>
                               <CC_SECTION1_688
                                   updateLogo={this.updateLogo}
                                   override={this.override2}
                                   darkSection={this.state.darkSection}
                               />
                           </div>
                       );
                       break;
                   case 2:
                       section = (
                           <div ref={this.section3}>
                               <CC_SECTION2_689
                                   updateLogo={this.updateLogo}
                                   override={this.override3}
                                   darkSection={this.state.darkSection}
                               />
                           </div>
                       );
                       break;
                   case 3:
                       section = (
                           <div ref={this.section4}>
                               <CC_SECTION3_690
                                   updateLogo={this.updateLogo}
                                   override={this.override4}
                                   darkSection={this.state.darkSection}
                               />
                           </div>
                       );
                       break;
                   case 4:
                       section = (
                           <div ref={this.section5}>
                               <CC_SECTION4_691
                                   updateLogo={this.updateLogo}
                                   override={this.override5}
                                   darkSection={this.state.darkSection}
                               />
                           </div>
                       );
                       break;
                       default:
                           break;
                   }
           AllSections.push(section);
           });
       }
       let sec = this.state.section.filter(sec => sec.has_shortcut === true).map((s, i) => {
           let activeDot = this.state.activeSection === 'Section' + (i) ? true : false
           let ref = 'section' + s.id
           return (
               <SectionShortcut
                   key={s.id}
                   title={s.title}
                   activeDot={activeDot}
                   onClick={() => this.scrolltoSectionHandler(ref)}
               />
           );
       });
       let homeSectionShortcut = !isMobile? <HomeSectionShortcut section={sec}  /> : null 
         let helmet = localStorage.getItem('language') === "English" ? <title> Corporate culture | Phenikaa</title> : <title> Văn hóa Doanh nghiệp | Phenikaa</title>
         let description = localStorage.getItem('language') === "English" ? <meta name="description" content="None" /> : <meta name="description" content="None" />
         let keywords = localStorage.getItem('language') === "English" ? <meta name="keywords" content="" /> : <meta name="keywords" content="" />
       return (
           <div style={{}}>
               <Helmet>
                   {helmet}
                   {description}
                   {keywords}
               </Helmet>
               <LOGO_685 logoColor={this.state.darkSection}/>
               <MENU_686 logoColor={this.state.darkSection}/>
               <MainClass>
                   <ParallaxProvider >
                       <React.Fragment>
                               {AllSections.length > 0 ? (
                                   <SectionList sections={AllSections} />
                               ) : (
                                   <></>
                               )}
                       </React.Fragment>
                   </ParallaxProvider>
               </MainClass>
               <FOOTER_692/>
               {homeSectionShortcut}
           </div>
       );
   }
}
export default withRouter(project1);