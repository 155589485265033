import React from "react"
import Desktop from "./Desktop/Desktop"
import Mobile from "./Mobile/Mobile"
import { isMobile, isTablet } from "react-device-detect"

export default function Job_Details_New(props) {
  let content

  if (isTablet) {
    content = <Desktop param={props.param} updateLogo={props.updateLogo} override={props.override} darkSection={props.darkSection} />
  } else if (isMobile) {
    content = <Mobile param={props.param} updateLogo={props.updateLogo} override={props.override} darkSection={props.darkSection} />
  } else {
    content = <Desktop param={props.param} updateLogo={props.updateLogo} override={props.override} darkSection={props.darkSection} />
  }

  return <>{content}</>
}
