import React, { Component } from "react";
import classes from "./AD_Section1.module.css";
import Api from "./Api";
import { Link } from "react-router-dom";

export default class Box extends Component {
  constructor(props) {
    super(props);
    this.state = {
      article: {
        widgets: [],
      },
      url: "",
      readMore: "",
      isLoaded: false,
      isApiLoaded: true,
    };
    this.getSectionDetail = this.getSectionDetail.bind(this);
  }
  componentDidMount() {
    if (this.props.id) {
      let param = this.props.id;
      this.getSectionDetail(param);
      this.setState({ url: this.props.url });
    }
  }

  getSectionDetail(param) {
    const apiData = new Api();
    this.setState(
      {
        contentArticle: [],
      },
      () => {
        apiData
          .getSectionDetail(param)
          .then((result) => {
            const articles = result.data;
            const y = [];
         

            y.push({
              back: articles.widgets[0].widget_contents[0].content,
              url: articles.widgets[0].url,
              share: articles.widgets[1].widget_contents[0].content,
            });

            this.setState({ article: y, isLoaded: true });
          })
          .catch((error) => {
            this.setState({ isApiLoaded: true });
          });
      }
    );
  }
  render() {
    const { article } = this.state;

    let articleContent,
      returnBackElement,
      shareText = null;

    if (this.state.isLoaded) {
      let backText = article[0].back ? article[0].back : "";
      let backUrl = article[0].url ? article[0].url : "/news/";
      shareText = article[0].share ? article[0].share : "";

      if (this.props.history) {
        returnBackElement = (
          <div
            onClick={() => this.props.history.goBack()}
            className={classes.images_goback}
          >
            <img src={require("./assets/Path235.svg")} alt="" />
            &nbsp; {backText}
          </div>
        );
      } else {
        returnBackElement = (
          <Link to={backUrl} className={classes.link}>
            <div className={classes.images_goback}>
              <img src={require("./assets/Path235.svg")} alt="" />
              &nbsp; {backText}
            </div>
          </Link>
        );
      }
    }
    return <div className={classes.wrappbtnback}>{returnBackElement}</div>;
  }
}
