import React from "react";
import { useMediaQuery } from "react-responsive";
import classes from "../Desktop/Desktop.module.css";
import { Link } from "react-router-dom";
import { Slide } from "react-awesome-reveal";

export default function Tablet(props) {
  const isTablet = useMediaQuery({
    query: "(max-device-width: 1024px)",
  });

  
  let dataContent, isDesk, isTabs;

  isDesk = (
    <>
      <Slide triggerOnce direction="top">
        <div className={classes.boxChild}>
          <p className={classes.title}>{props.title}</p>
          <p className={classes.contentt2}>{props.content}</p>
          <div className={classes.boxLinks}>
            <div className={classes.outerCircle2}>
              <div className={classes.innerCircle2}></div>
            </div>
            <Link to={props.url} className={classes.link1}>
              <p className={classes.readMore}>{props.rd}</p>
            </Link>
          </div>
        </div>
      </Slide>
    </>
  );

  isTabs = (
    <>
      <Slide triggerOnce direction="top">
        <div className={classes.boxChild}>
          <p className={classes.title}>{props.title}</p>
          <p className={classes.contentt2}>{props.content}</p>
          <div className={classes.boxLinks}>
            <div className={classes.outerCircle3}>
              <div className={classes.innerCircle3}></div>
            </div>
            <Link to={props.url} className={classes.link1}>
              <p className={classes.readMore}>{props.rd}</p>
            </Link>
          </div>
        </div>
      </Slide>
    </>
  );

  dataContent = !isTablet ? isDesk : isTabs;

  return <div className={classes.css}>{dataContent}</div>;
}
