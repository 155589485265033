import React from 'react';
import classes from './NavBack.module.css'
import { Link } from 'react-router-dom'

function NavBack(props) {
    const {url, className, children, ...otherProps} = props;
    return (
        <div className={[classes.navBack, props.className].join(' ')}>
            <Link {...otherProps} to={props.url} className={classes.customLink}>
                <div className={"d-flex"}>
                    <div className={"d-flex"}>
                        <i
                            className={[
                                classes.arrow,
                                classes.left,
                                classes.customMargin,
                            ].join(" ")}
                        ></i>
                    </div>
                    <span className={classes.textBack}>{props.children}</span>
                </div>
            </Link>
        </div>
    );
}

export default NavBack;