import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import Api from "../Api";
import classes from "./ModalRegisterStep2.module.css";
import UserContext from "../Context";
import { store } from "react-notifications-component";

import { faTimes, faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FilePreviewerThumbnail } from "react-file-previewer";
import { result } from "lodash";

class ModalRegisterStep2 extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      file: [],
      idApplicant: 0,
      inputLink: "",
      teks: [],
    };
  }

  componentDidMount() {
    const contextValue = this.context;
    this.setState(
      {
        idApplicant: contextValue.dataApplicant.id,
      },
      () => {
        contextValue.getNewDataApplicant(this.state.idApplicant);
      }
    );
  }

  componentWillReceiveProps(newProps) {
    // console.log("compwillreceive", newProps);
    if (newProps.skills) {
      this.setState({
        teks: newProps.skills,
      });
    }
  }

  addLinkPortfolio = (e) => {
    const portfolio = new Api();
    const contextValue = this.context;
    let linkToUpload = this.state.inputLink;

    if (linkToUpload === "") {
      linkToUpload = null;
    }

    portfolio
      .uploadPortofolio(contextValue.dataApplicant.id, "", [linkToUpload])
      .then((res) => {
        contextValue.getNewDataApplicant(contextValue.dataApplicant.id);
        this.setState({
          inputLink: "",
        });
        store.addNotification({
          title: "Success!",
          message: "Link has been successfully added!",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      })
      .catch((err) => {
        store.addNotification({
          title: "Error!",
          message: "Failed to add link, try to use https://",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      });
  };

  deleteResume = async (param) => {
    const resume = new Api();

    return await resume
      .deleteResume(param)
      .then((res) => {})
      .catch((err) => {
        console.log("error =>", err);
      });
  };

  handleChangeFileResume = (e) => {
    let fileToUpload = [];

    for (let index = 0; index < e.target.files.length; index++) {
      fileToUpload.push(e.target.files[index]);
    }

    const resume = new Api();
    const contextValue = this.context;

    resume
      .uploadResume(contextValue.dataApplicant.id, fileToUpload)
      .then((res) => {
        contextValue.getNewDataApplicant(contextValue.dataApplicant.id);
        store.addNotification({
          title: "Success!",
          message: "Resume has been successfully uploaded!",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      })
      .catch((err) => {
        console.log("error =>", err);
        store.addNotification({
          title: "Error!",
          message: "Failed to upload resume.",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      });
  };

  handleChangeFilePortfolio = (e) => {
    const portfolio = new Api();
    const contextValue = this.context;

    let fileToUpload = [];

    for (let index = 0; index < e.target.files.length; index++) {
      fileToUpload.push(e.target.files[index]);
    }

    portfolio
      .uploadPortofolio(contextValue.dataApplicant.id, fileToUpload, "")
      .then((res) => {
        contextValue.getNewDataApplicant(contextValue.dataApplicant.id);
        store.addNotification({
          title: "Success!",
          message: "Portfolio has been successfully uploaded!",
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      })
      .catch((err) => {
        console.log("error =>", err);
        store.addNotification({
          title: "Error!",
          message: "Failed to upload portfolio.",
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000,
            onScreen: true,
          },
        });
      });
  };

  handleTextField = (e) => {
    this.setState({
      inputLink: e.target.value,
    });
  };

  setThumbnail = (str) => {
    let arr = ["jpg", "png", "svg", "ico"];
    let file = str.split("/").pop();
    let ext = file.split(".").pop();
    let thumbnail = arr.includes(ext) ? (
      <FilePreviewerThumbnail
        file={{
          url: str,
        }}
      />
    ) : (
      <span>{ext}</span>
    );

    return thumbnail;
  };

  render() {
    let titles, resume, portofolio, submit, net, accepted, select, upload_file;

    this.state.teks.forEach((ele, i) => {
      switch (i) {
        case 0:
          titles = ele.content;
          // code block
          break;
        case 1:
          resume = ele.content;
          // code block
          break;
        case 2:
          accepted = ele.content;
          break;
        case 3:
          portofolio = ele.content;
          break;
        case 4:
          net = ele.content;
          break;
        case 5:
          select = ele.content;
          break;
        case 6:
          submit = ele.content;
          break;
        case 7:
          upload_file = ele.content;
          break;
        default:
        // code block
      }
    });
    return (
      <Modal
        size={"lg"}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={this.props.show}
        backdrop={false}
        onHide={this.props.toggleModalRegisterStep2}
      >
        <Modal.Header closeButton>
          <div className="d-flex justify-content-center flex-column">
            <span className={classes.modalTitle}>{titles}</span>
          </div>
        </Modal.Header>
        <div className={classes.modalContent}>
          <div className={classes.content}>
            <div className={classes.contentWrapp}>
              <div className={classes.boxContent}>
                <span className={classes.titleContent}>{resume}</span>
                <UserContext.Consumer>
                  {(context) =>
                    context.dataApplicant.attachments
                      ? context.dataApplicant.attachments.map((c) => {
                          let arr = ["jpg", "png", "svg", "ico"];
                          if (c.attachment_type === "Resume") {
                            let str = c.attachment_file;
                            let file = str.split("/").pop();
                            let ext = file.split(".").pop();
                            let thumbnail = arr.includes(ext) ? (
                              <FilePreviewerThumbnail
                                file={{
                                  url: str,
                                }}
                              />
                            ) : (
                              <span>{ext}</span>
                            );
                            return (
                              <div className={classes.boxResume}>
                                <div className={classes.boxPreview}>
                                  {thumbnail}
                                </div>
                                <div className={classes.boxDetailPreview}>
                                  <span>{file}</span>
                                  <div className="d-flex">
                                    <a
                                      className={classes.customLink}
                                      href={str}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <span>View resume</span>
                                    </a>
                                    <img
                                      src={require("./assets/arrowR.svg")}
                                      alt="arrow"
                                    />
                                  </div>
                                </div>
                                <div
                                  className={classes.boxRemove}
                                  onClick={() => {
                                    this.deleteResume(c.id).then(() => {
                                      context.getNewDataApplicant(
                                        context.dataApplicant.id
                                      );
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon
                                    color="#000000"
                                    className={classes.iconClose}
                                    icon={faTimes}
                                    size="md"
                                  />
                                </div>
                              </div>
                            );
                          }
                        })
                      : ""
                  }
                </UserContext.Consumer>
                <input
                  id="resume-upload"
                  className={classes.inputModal}
                  type={"file"}
                  onChange={(e) => this.handleChangeFileResume(e)}
                  multiple
                  hidden
                />
                <label
                  htmlFor="resume-upload"
                  className={classes.btnFullAction}
                >
                  <div className={classes.contentBtn}>
                    <div className={classes.circle}>
                      <img src={require("./assets/add.svg")} alt="add" />
                    </div>
                    <span>{upload_file}</span>
                  </div>
                </label>
                <span className={classes.note}>{accepted}</span>
              </div>
              <div className={classes.boxContent}>
                <span className={classes.titleContent}>{portofolio}</span>
                <div className={classes.boxPortofolio}>
                  <UserContext.Consumer>
                    {(context) =>
                      context.dataApplicant.attachments
                        ? context.dataApplicant.attachments.map((c) => {
                            if (c.attachment_type === "Portfolio") {
                              let content;

                              // let file = str.split('/').pop()
                              if (c.attachment_file) {
                                let str = c.attachment_file;

                                let thumb = this.setThumbnail(str);
                                content = (
                                  <div className={classes.boxPreview}>
                                    <a
                                      className={classes.customLink}
                                      href={str}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {thumb}
                                    </a>
                                    <div
                                      className={classes.boxRemovePortofolio}
                                      onClick={() => {
                                        this.deleteResume(c.id).then(() => {
                                          context.getNewDataApplicant(
                                            context.dataApplicant.id
                                          );
                                        });
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        color="#000000"
                                        className={classes.iconClose}
                                        icon={faTimes}
                                        size="md"
                                      />
                                    </div>
                                  </div>
                                );
                              } else if (
                                c.attachment_link ||
                                c.attachment_link === ""
                              ) {
                                let str = c.attachment_link;
                                content = (
                                  <div className={classes.boxPreview}>
                                    <a
                                      className={classes.customLink}
                                      href={str}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <FontAwesomeIcon
                                        className={classes.iconLink}
                                        icon={faLink}
                                        size="lg"
                                      />
                                    </a>
                                    <div
                                      className={classes.boxRemovePortofolio}
                                      onClick={() => {
                                        this.deleteResume(c.id).then(() => {
                                          context.getNewDataApplicant(
                                            context.dataApplicant.id
                                          );
                                        });
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        color="#000000"
                                        className={classes.iconClose}
                                        icon={faTimes}
                                        size="md"
                                      />
                                    </div>
                                  </div>
                                );
                              }
                              return <>{content}</>;
                            }
                          })
                        : ""
                    }
                  </UserContext.Consumer>
                </div>
                <div className={classes.contentLink}>
                  <div className={classes.boxLink}>
                    <input
                      type={"text"}
                      placeholder={"Add your portfolio link here..."}
                      value={this.state.inputLink}
                      onChange={(e) => this.handleTextField(e)}
                    />
                    <div
                      className={classes.circleLink}
                      onClick={this.addLinkPortfolio}
                    >
                      <img src={require("./assets/add.svg")} alt="add" />
                    </div>
                  </div>
                </div>
                <input
                  id="portfolio-upload"
                  className={classes.inputModal}
                  type={"file"}
                  onChange={(e) => this.handleChangeFilePortfolio(e)}
                  multiple
                  hidden
                />
                <label
                  htmlFor="portfolio-upload"
                  className={classes.btnFullAction}
                >
                  <div className={classes.contentBtn}>
                    <div className={classes.circle}>
                      <img src={require("./assets/add.svg")} alt="add" />
                    </div>
                    <span>Upload File</span>
                  </div>
                </label>
              </div>
            </div>

            <span className={["mt-4", classes.option].join(" ")}>
              Phenikaa companies may keep me informed with personalized emails
              about products and services. See our Privacy Policy for more
              details or to opt-out at any time.
            </span>
          </div>
          <div className={classes.modalAction}>
            <div
              className={[classes.boxAction, classes.boxRight].join(" ")}
              onClick={this.props.continueClicked}
            >
              <span>{net}</span>
            </div>
          </div>
          <hr />
          <span className={classes.option}>
            Protected by reCAPTCHA and subject to the Google Privacy Policy and
            Terms of Service.
          </span>
        </div>
      </Modal>
    );
  }
}

export default ModalRegisterStep2;
