import React, { useContext } from "react";

import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import { AccordionContext } from "react-bootstrap";

import {
    faPlus,
    faMinus,
    faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./Accordion.module.css";

export default function Accordion({ children, eventKey, callback, data }) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey)
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    let icon;
    if (data.children) {
        if (data.children[0].widget) {
            icon = <FontAwesomeIcon color="#f16b26" className="mt-auto mb-auto ml-auto " icon={faArrowRight} size="lg" />
        } else {
            icon = isCurrentEventKey ?
                <FontAwesomeIcon color="white" className="mt-auto mb-auto ml-auto " icon={faMinus} size="lg" />
                :
                <FontAwesomeIcon color="#f16b26" className="mt-auto mb-auto ml-auto " icon={faPlus} size="lg" />
        }
    } else {
        icon = (
            <FontAwesomeIcon color="#f16b26" className="mt-auto mb-auto ml-auto " icon={faArrowRight} size="lg" />
        );
    }

    return (
        <div onClick={decoratedOnClick} className={["d-flex relative", classes.mobileMenu].join(' ')}>
            <p className={classes.titleAccordion}>{children}</p>
            {icon}
        </div>
    );
}
